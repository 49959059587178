import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/Information/FaqViewApi";

export class FaqViewService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getFaqs", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getFaq", null, id);
  }
}
