var _ = require("lodash");

export function createDeepProxy(target, handler) {
  const preproxy = new WeakMap();

  function isValidForProxy(value) {
    return (
      value != null &&
      typeof value === "object" &&
      value.__isProxy !== true &&
      !(value instanceof Date)
    );
  }
  function makeHandler(path) {
    return {
      set(target, key, value, receiver) {
        if (isValidForProxy(value)) {
          value = proxify(value, [...path, key]);
        }
        target[key] = value;

        if (handler.set) {
          handler.set(target, [...path, key], value, receiver);
        }
        return true;
      },
      get: function (target, prop, receiver) {
        if (prop === "__isProxy") {
          return true;
        }
        return Reflect.get(...arguments);
      },
      deleteProperty(target, key) {
        if (Reflect.has(target, key)) {
          unproxy(target, key);
          let deleted = Reflect.deleteProperty(target, key);
          if (deleted && handler.deleteProperty) {
            handler.deleteProperty(target, [...path, key]);
          }
          return deleted;
        }
        return false;
      },
    };
  }

  function unproxy(obj, key) {
    if (preproxy.has(obj[key])) {
      obj[key] = preproxy.get(obj[key]);
      preproxy.delete(obj[key]);
    }

    for (let k of Object.keys(obj[key])) {
      if (isValidForProxy(obj[key][k])) {
        unproxy(obj[key], k);
      }
    }
  }

  function proxify(obj, path) {
    for (let key of Object.keys(obj)) {
      if (isValidForProxy(obj[key])) {
        obj[key] = proxify(obj[key], [...path, key]);
      }
    }
    let p = new Proxy(obj, makeHandler(path));
    preproxy.set(p, obj);
    return p;
  }

  return proxify(target, []);
}
export function getArgs(args) {
  return args === null ? "" : args;
}
export function onlyKeys(obj, keys) {
  if (!keys) return obj;
  return keys.reduce((a, key) => ((a[key] = obj[key]), a), {});
}

export async function findAsync(arr, id) {
  return Promise.resolve(arr.find((item) => item.id == id));
}
