import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/JobTriggerApi";
import store from "../../../../Frontends/Common/store"
export class JobTriggerService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getJobTriggers", args);
  }
  async getPanel(id) {
    return await this.transformer.getGridPanel(this.Api, "getJobTrigger", null, id);
  }
  async get(id) {
    return await this.transformer.getItem(this.Api, "getJobTrigger", id);
  }
  async create(item) {
    return await this.transformer.createItem(this.Api, "createJobTrigger", item);
  }
  async update(item) {
    return await this.transformer.editItem(this.Api, "updateJobTrigger", item);
  }
  async delete(item) {
    return await this.transformer.deleteItem(this.Api, "deleteJobTrigger", item);
  }

  getRepeatText(trigger) {
    let settingsText = [];
    let settings = JSON.parse(trigger.triggerSettings || trigger.TriggerSettings);
    if (!settings) return "";
    const getDate = store.getters["userinfoStore/getFormatDate"]
    Object.keys(settings).forEach(key => {
      if(settings[key] !== null && settings[key] !== undefined) {
        if(Array.isArray(settings[key]) && settings[key].length > 0) {
          let arrayText = settings[key].join(', ');
          settingsText.push(`${key} ${arrayText} `);
        } else if(!Array.isArray(settings[key])) {
          let value = settings[key]
          if (key === 'StartAt' || key === 'EndAt') {
            value = getDate(value)
          }
          settingsText.push(key != 'repeat' ? `${key} ${value}` : `${value}`);
        }
      }
    });

    return settingsText.join(', ');
  }

  setNewGridData(grid) {
    this.transformer.setNewGridData(grid)
  }
  getGrid() {
    return this.transformer.getGridData()
  }
}
