<template>
  <ProSpaceModal
    v-model="showModal"
    width="550"
    :title="showModal.taskName"
    applyTxt=""
    :icon="iconModal"
    :icon-width="16"
    :icon-height="16"
    typeIcon="default"
    coloredIcon
    :cancelTxt="$t('closeTxt')"
  >
    <ProSpaceGeoLayout
      isHideRight
      isHideLeft
      isHideBottom
      class="task-modal__layout"
    >
      <template #top>
        <ProSpaceVLayout :gap="10" alignItems="stretch">
          <!--horizontal align with other blocks-->
          <ProSpaceHLayout :gap="40">
            <ProSpaceVLayout :gap="10" alignItems="stretch">
              <ProSpacePanelBlockItem
                :title="$t('startedImport')"
                :text="formatStartedDate"
              />
            </ProSpaceVLayout>
            <ProSpaceVLayout :gap="10" alignItems="stretch">
              <ProSpacePanelBlockItem
                :title="$t('finishedImport')"
                :text="formatFinishedDate"
              />
            </ProSpaceVLayout>
          </ProSpaceHLayout>
          <ProSpaceDivider type="dotted" />
          <ProSpaceHLayout v-if="parametersBlockAccess" :gap="40">
            <ProSpaceVLayout :gap="10" alignItems="stretch">
              <ProSpacePanelBlockItem :title="$t('parameters')" class="task-modal__block">
                <template #content>
                  <ProSpaceHLayout justifyContent="space-between">
                    <ProSpaceVLayout :gap="10" alignItems="stretch">
                      <ProSpaceAction @click="showParameter" class="text-size-12">
                    <span
                      class="task-modal__action task-modal__action-parameters"
                    >{{ $t("jobParameters_showModalParameters") }}</span
                    >
                      </ProSpaceAction>
                    </ProSpaceVLayout>
                  </ProSpaceHLayout>
                </template>
              </ProSpacePanelBlockItem>
            </ProSpaceVLayout>
            <ProSpaceVLayout :gap="10" alignItems="stretch" justifyContent="flex-end">
              <ProSpaceAction
                v-if="showModalParameters.GFID"
                icon="download"
                class="text-size-12"
                @click="downloadFile(showModalParameters.GFID)"
              >
                    <span class="task-modal__action">{{
                        $t("jobParameters_ImportFile")
                      }}</span>
              </ProSpaceAction>
            </ProSpaceVLayout>
          </ProSpaceHLayout>
          <ProSpaceDivider v-if="parametersBlockAccess" type="dotted" />
        </ProSpaceVLayout>
      </template>
      <template #center>
        <ProSpaceGeoLayout
          isHideRight
          isHideLeft
          isHideBottom
          class="task-modal__results"
        >
          <template #top>
            <ProSpaceLabel bold :text="$t('runResult')" class="text-size-13" />
          </template>
          <template #center>
            <ProSpaceVLayout> <!-- To avoid verical scroll on default height -->
              <ProSpaceHLayout
                justifyContent="space-between"
                :gap="40"
              >
                <ProSpaceVLayout style="padding-top: 7px;" :gap="6" justifyContent="flex-start" alignItems="flex-start">
                  <ProSpaceLabel
                    v-for="textResult in getTextResults"
                    class="text-size-12"
                    :text="textResult"
                  />
                  <ProSpaceAction
                    v-if="
                      showModalResults.some((x) => x?.ResultType === 'ErrorFile')
                    "
                    @click="showTaskErrorsGrid"
                  >
                    <span
                      class="task-modal__action task-modal__action-parameters"
                      >{{ $t("jobResult_ShowErrors") }}</span
                    >
                  </ProSpaceAction>
                </ProSpaceVLayout>
                <ProSpaceVLayout :gap="4" justifyContent="flex-start" alignItems="flex-start">
                  <ProSpaceAction
                    v-if="logResult"
                    class="text-size-12"
                    icon="download"
                    :text="$t('downloadLog')"
                    @click="downloadFile(logResult.GFID)"
                  />
                  <ProSpaceAction
                    v-for="fileResult in getFileResults"
                    :text="textResult"
                    v-if="showModalResults.some((x) => x?.ResultType === 'File')"
                    icon="download"
                    @click="downloadFile(fileResult.GFID)"
                  >
                    <span class="task-modal__action">{{
                      fileResult.label
                    }}</span>
                  </ProSpaceAction>
                </ProSpaceVLayout>
              </ProSpaceHLayout>
            </ProSpaceVLayout>
          </template>
        </ProSpaceGeoLayout>
      </template>
    </ProSpaceGeoLayout>
  </ProSpaceModal>
  <ProSpaceErrorModal
    ref="taskErrorsGrid"
    :dateFormat="userinfo.dateFormat"
    :timeFormat="userinfo.timeFormat"
    :formattedErrors="formattedErrors"
    :translates="modalTranslates"
    :title="$t('errors')"
    :showDetailsLabel="$t('showErrorDetails')"
    :cancelText="$t('closeTxt')"
  >
  </ProSpaceErrorModal>
</template>

<script>
import {
  ProSpaceModal,
  ProSpaceVLayout,
  ProSpaceHLayout,
  ProSpacePanelBlockList,
  ProSpacePanelBlockItem,
  ProSpaceGeoLayout,
  ProSpaceLabel,
  ProSpaceAction,
  ProSpaceIcon,
  ProSpaceDivider,
  ProSpaceErrorModal,
} from "prospace-components-library";
import { getAMorPM } from "prospace-components-library/src/lib-assets/mixins/date";
import { FileService } from "../../../../../Frontends/ProSpace/services/FileService";
import { mapGetters } from "vuex";
export default {
  components: {
    ProSpaceModal,
    ProSpaceVLayout,
    ProSpaceHLayout,
    ProSpacePanelBlockList,
    ProSpacePanelBlockItem,
    ProSpaceGeoLayout,
    ProSpaceLabel,
    ProSpaceAction,
    ProSpaceIcon,
    ProSpaceDivider,
    FileService,
    ProSpaceErrorModal,
  },
  props: {
    checkAccess: {
      type: Function,
    }
  },
  data() {
    return {
      showModalParameters: null,
      showModalResults: [],
      showModal: false,
      modalIcon: "",
      logResult: null,
      taskData: {},
      fileService: new FileService(),
      showErrorWindow: false,
      formattedErrors: null,
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime"
    }),
    iconModal() {
      const statusDefault = this.showModal?.status?.toLowerCase();
      let status;
      switch (statusDefault) {
        case "new":
          status = "status-accepted";
          break;
        case "error":
          status = "status-error";
          break;
        case "complete":
          status = "status-complete";
          break;
        case "completed":
          status = "status-complete";
          break;
        default:
          status = "status-accepted";
      }
      return status;
    },
    getFileResults() {
      return this.showModalResults
        .filter((x) => x?.ResultType?.toLowerCase() === "file")
        .map((x) => ({ GFID: x.GFID, label: this.$t(x.Label?? 'jobResult_UnknownFile') }));
    },
    getTextResults() {
      return this.showModalResults
        .filter((x) => x?.ResultType?.toLowerCase() === "text")
        .map((x) => `${x.Value} ${this.$t(x.Label?? 'jobResult_UnknownText')}`);
    },
    parametersBlockAccess() {
      return this.checkAccess ? this.checkAccess("parameters-block") : true;
    },
    formatFinishedDate() {
      const finishAt = this.showModal.finishedAt;
      return `${this.getFormatDateTime(finishAt)} ${getAMorPM(finishAt, this.userinfo.timeFormat)}`;
    },
    formatStartedDate() {
      const startedAt = this.showModal.startedAt;
      return `${this.getFormatDateTime(startedAt)} ${getAMorPM(startedAt, this.userinfo.timeFormat)}`;
    },
    modalTranslates() {
      return {
        type: this.$t('type'),
        date: this.$t('date'),
        description: this.$t('description'),
      }
    }
  },
  methods: {
    show(taskData) {
      let me = this;
      me.showModal = taskData;
      me.showModalParameters = JSON.parse(taskData.context);
      me.formattedErrors = null;
      if (taskData.result) {
        let parsed = JSON.parse(taskData.result);
        me.showModalResults = Array.isArray(parsed)
          ? parsed
          : [parsed];
        me.logResult = me.showModalResults.find(
          (x) => x?.ResultType === "LogFile"
        );
      }
    },
    showParameter() {
      this.$emit("action", this.showModal);
    },

    showErrors() {
      this.$emit("action", this.showModal);
    },

    showModalForm(name, args) {
      this.$refs[name].show(args);
    },
    //don't download twice
    showTaskErrorsGrid() {
      let me = this;
      if (!me.formattedErrors) {
        me.downloadErrorFile();
      } else {
        me.showModalForm("taskErrorsGrid");
      }
    },
    setDetails(data) {
      this.formattedErrors = data.map((i) => {
        if (i.parameters) {
          let parameters = [];
          for (let prop in i.parameters) {
            const obj = {
              label: prop,
              value: i.parameters[prop],
              type: i.type,
            };
            parameters.push(obj);
          }
          i.parameters = parameters;
        }
        return i;
      });
      this.showModalForm("taskErrorsGrid");
    },

    downloadFile(gfid) {
      //TODO: Loader!!!
      //Первый запрос получает токен необходимый для скачивания файла.
      this.fileService.getFileToken(gfid).then((response) => {
        if (response != null) {
          //второй запрос скачивает файл
          this.fileService
            .downloadFile(response.data)
            .then((fileResponse) => {
              //TODO: check error
              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(fileResponse.data);
              let name = fileResponse.fileName;
              link.download = decodeURI(name).replace("%2b", "+");
              link.click();
            });
        } else {
          //TODO: ERROR
        }
      });
    },
    downloadErrorFile() {
      let me = this;
      //TODO: Loader!!!
      //Первый запрос получает токен необходимый для скачивания файла.
      me.fileService
        .getFileToken(
          me.showModalResults.find((x) => x?.ResultType === "ErrorFile").GFID
        )
        .then((response) => {
          if (response != null) {
            //второй запрос скачивает файл
            me.fileService
              .downloadFile(response.data)
              .then((fileResponse) => {
                if(!fileResponse)
                  return;

                let fr = new FileReader();
                fr.onload = function () {
                  me.setDetails(JSON.parse(this.result));
                };
                fr.readAsText(fileResponse.data);
              });
          } else {
            //TODO: ERROR
          }
        });
    },
  },
};
</script>

<style lang="scss">
.task-modal {

  &__layout {
    & > .prospace-geo-layout__center {
      padding-top: 20px;
      max-height: 200px;
    }

    .prospace-panel-block-item__content_text {
      font-size: 12px;
    }
  }

  &__action {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 320px;
    font-size: 12px;
  }

  &__action-parameters {
    max-width: 365px;
  }

  &__block {
    padding-top: 7px;
  }
}
</style>
