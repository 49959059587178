import systemSetting from "../../../../Services/SystemSetting/ProSpace.SystemSetting.Frontend/views/SystemSetting/SystemSetting";

export const systemSettingRoutes = [
  {
    path: "/systemsetting/systemsetting",
    name: "systemsetting",
    component: systemSetting,
  },
];

export default systemSettingRoutes;
