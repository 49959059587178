import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/Integration/IntegrationLogApi";

export class IntegrationLogService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(this.Api, "editIntegrationLog", fields);
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editIntegrationLog");
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getIntegrationLogs", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getIntegrationLog", null, id);
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createIntegrationLog", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteIntegrationLog", model);
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "editIntegrationLog", model);
  }
}
