import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/MasterData/AuthorizedContactPersonApi";
import store from "../../../../Frontends/Common/store";
import i18n from "../../../../Frontends/Common/utils/i18n.js";

export class AuthorizedContactPersonService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(this.Api, "editAuthorizedContactPerson", fields);
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editAuthorizedContactPerson");
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getAuthorizedContactPersons", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getAuthorizedContactPerson", null, id);
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createAuthorizedContactPerson", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteAuthorizedContactPerson", model);
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "editAuthorizedContactPerson", model);
  }
  async export(filter, format) {
    const res = this.Api.export(filter, format);
    await store.dispatch('notifications/handleResponse', {
      promiseResponse: res,
    })
  }
  getIsRegisteredValues() {
    return Promise.resolve({ data: {
        records: [{
          label: i18n.global.t('yes'),
          value: true
        }, {
          label: i18n.global.t('no'),
          value: false
        }]   
      }
    })
  }
}
