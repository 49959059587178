import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/DocumentService/DocumentApi";
import store from "../../../../Frontends/Common/store";
export class DocumentService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(this.Api, "editDocument", fields);
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editDocument");
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createDocument", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteDocument", model);
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "editDocument", model);
  }
  async validateUsersExists(model) {
    return await this.transformer.getItem(this.Api, "validateUsersExists", model);
  }
  async export(filter, format, assignOptions) {
    let role = JSON.parse(localStorage.getItem("userRole")).systemName;
    const res = this.Api.export(filter, role, format, assignOptions);
    await store.dispatch('notifications/handleResponse', {
      promiseResponse: res,
    })
  }
  async getStatuses() {
    const docTypes = [
      { label: "Черновик", value: "Draft" },
      { label: "Публикация", value: "Publication" },
    ]
    const data = {records: docTypes}
    return Promise.resolve( { data })
  }
}
