<template>
  <ProSpaceModal
    v-model="localModelValue"
    ref="fileUploadForm"
    class="modal-import"
    @apply="applyImportForm"
    @close="closeModal"
    icon="download"
    width="470"
    :disabledApply="!filesCount"
    :title="$t('import')"
    :cancelTxt="$t('cancelTxt')"
    :applyTxt="$t('applyTxt')"
  >
    <template v-slot:default>
      <ProSpaceGeoLayout>
        <template #center>
          <ProSpaceFile
            ref="fileUploadField"
            name="tst"
            :fileLimit="1"
            @files-count="filesCountHandler"
            :accept="accept"
          >
            <template #header>
              <ProSpaceAction
                icon="download"
                iconWidth="20px"
                iconHeight="20px"
                @click="actionImportScaffold"
              >
                <span>{{ $t('importTemplate') }}</span>
              </ProSpaceAction>
            </template>
          </ProSpaceFile>
        </template>
      </ProSpaceGeoLayout>
    </template>
  </ProSpaceModal>
</template>

<script>
import {
  ProSpaceModal,
  ProSpaceFile,
  ProSpaceGeoLayout,
  ProSpaceIconButton,
  ProSpaceButton,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceLabel,
  ProSpaceAction,
  ProSpaceIcon,
} from "prospace-components-library";
import {faFileUpload} from "@fortawesome/free-solid-svg-icons";
import {FileService} from "../../../../../../Frontends/ProSpace/services/FileService.js";
import { CompetenceService } from "../../../services/CompetenceService.js";
import { DistributorService } from "../../../../../MasterData/MasterData.Frontend/services/DistributorService.js";
import { DistributorStructureService } from "../../../../../MasterData/MasterData.Frontend/services/DistributorStructureService.js";


export default {
  name: "ModalImport",
  components: {
    ProSpaceModal,
    ProSpaceFile,
    ProSpaceGeoLayout,
    ProSpaceIconButton,
    ProSpaceButton,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceLabel,
    ProSpaceAction,
    ProSpaceIcon,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    accept: {
      type: String,
      default: () => {
        return null
      }
    }
  },
  data() {
    return {
      filesCount: 0,
      fileService: new FileService(),
      competenceService: new CompetenceService(),
distributorService: new DistributorService(),
distributorStructureService: new DistributorStructureService(),

    };
  },
  methods: {
    filesCountHandler(evt) {
      this.filesCount = evt;
    },
    applyImportForm() {
      this.$emit("loading", true);
      let formData = new FormData();
      formData.append("", this.$refs["fileUploadField"].files[0]);
      this.fileService.upload(formData).then((result) => {
        if (result && result.data && result.data.success) {
          this.competenceService.import(result.data.data[0].gfid).then((importResult) => {
            if (
              importResult &&
              importResult.data &&
              importResult.data.success
            ) {
              this.$emit("loading", false);
            } else {
              //TODO: error
            }
          });
        } else {
          //TODO: show error
          this.$emit("loading", false);
        }
      });
      //TODO: Show message(import task created)
    },
    actionImportScaffold() {
      this.competenceService.getImportScaffold().then((response, status, xhr) => {
        this.$emit("loading", true);
        if (response != null) {
          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          let name = response.headers["content-disposition"]
            .split(";")
            .find((n) => n.includes("filename="))
            .replace("filename=", "")
            .trim();
          link.download = decodeURI(name).replace("%2b", "+");
          link.click();
        }
        this.$emit("loading", false);
      });
    },
    closeModal() {
      this.$emit("update:modelValue", false);
    },
    formatSize(bytes) {
      if (bytes === 0) {
        return "0 B";
      }

      let k = 1000,
        dm = 1,
        sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
  },
  computed: {
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        this.$emit("update:modelValue", newVal);
      },
    },
    icon() {
      return {faFileUpload};
    },
  },
};
</script>

<style lang="scss">
.modal-import {
  & .p-dialog-content {
    padding: 20px;
  }

  .file-upload {
    max-height: 240px;
    font-weight: 400;
    line-height: 20px;
    font-size: 13px;

    &__wrapper {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      border: 1px solid #e0e7f4;
      border-radius: 5px;
      margin: 5px;
    }

    &__empty {
      padding: 20px;
    }

    &__filename {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 294px;
      width: 294px;
      color: #687284;
    }

    &__filesize {
      color: #b0bec5;
    }
  }
}
</style>
