<template>
  <ProSpaceGeoLayout class="systemdirectory" v-if="model">
    <template #top>
      <ProSpaceTopPanel :header="layoutName" back @back="backHandler">
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout
        :is-templated="true"
        :template-center="true"
        :template-right="true"
      >
        <template #center>
          <ProSpaceTabs
            :tabs="overviewTabs"
            :loading="loading"
            :refLoading="mixinLoadingAccess"
            :withoutMarginBottom="true"
            @select-tab="handlerSelectTab"
          >
            <ProSpaceAction
              ref="action-save"
              icon="check"
              :disabled="v$.model.$invalid"
              :text="$t('save')"
              @click="savePart(saveFields.Overview)"
            />
            <template v-slot:tab-1>
              <ProSpaceVLayout :gap="10">
                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('systemDirectory')"
                      :bottom="$t('systemDirectoryInfo')"
                      :gap="10"
                      break-word
                      type="third"
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceHLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="10"
                    >
                      <ProSpaceInputText
                        ref="name-ProSpaceInputText"
                        v-model="model.name"
                        :label="$t('systemDirectoryName')"
                        :placeholder="$t('systemDirectoryName')"
                        :invalid="v$.model.name.$error"
                        :invalid-messages="v$.model.name.$errors"
                        @blur="v$.model.name.$touch"
                      />
                    </ProSpaceHLayout>
                  </template>
                  <template v-if="v$.model.name.$error" v-slot:right>
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>
              </ProSpaceVLayout>
            </template>
          </ProSpaceTabs>
        </template>
      </ProSpaceGeoLayout>
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import {
  ProSpaceTopPanel,
  ProSpaceInputText,
  ProSpaceAction,
  ProSpaceRadioButtonSecondary,
  ProSpaceGeoLayout,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpaceTextBlock,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceDropdown,
  ProSpaceInputCalendar,
  ProSpaceInputNumber,
  ProSpaceMultiSelect,
  ProSpaceCustomFilterPanel,
  ProSpaceIcon,
  ProSpaceButton,
  ProSpaceWarning,
} from "prospace-components-library";
import { SystemDirectoryService } from "../../services/SystemDirectoryService.js";

import {
  required,
  maxLength,
  helpers,
  minValue,
  maxValue,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {
  useStatistic
} from "@composes"
export default {
  mixins: [accessMixin],
  components: {
    ProSpaceTopPanel,
    ProSpaceGeoLayout,
    ProSpaceInputText,
    ProSpaceAction,
    ProSpaceRadioButtonSecondary,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpaceTextBlock,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceDropdown,
    ProSpaceInputCalendar,
    ProSpaceInputNumber,
    ProSpaceMultiSelect,
    ProSpaceCustomFilterPanel,
    ProSpaceIcon,
    ProSpaceButton,
    ProSpaceWarning,
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    return { v$: useVuelidate(), setStatistic, distActions };
  },
  data() {
    this.tabs = [{ label: "Overview", value: "Overview", systemName: "overview" }];
    return {
      // Tabs
      modelTab: this.tabs[0], // Первое предустановленное значение

      // Common
      layoutName: "",
      model: null,
      loading: false,

      // Service
      systemDirectoryService: new SystemDirectoryService(),

      getMethod: "get",
      masterId: null,

      // SaveFields
      saveFields: {
        Overview: ["name"],
      },
    };
  },
  validations() {
    return {
      model: {
        name: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '150'}),
            maxLength(150)
          ),
        },
      },
    };
  },
  mounted() {
    this.mountAccess();
    this.getData();
  },
  methods: {
    handlerSelectTab({ selectedTab }) {
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: selectedTab.systemName
      })
    },
    gridLoaded() {},
    async getData() {
      this.masterId = this.$route.params.id;
      if (this.masterId === undefined) {
        // redirect to 404
        return;
      }
      const result = await this.systemDirectoryService[this.getMethod](
        this.masterId
      );
      this.model = result.data;
      this.layoutName = `${this.model.name}`;
    },
    saveAll() {
      this.loading = true;
      this.systemDirectoryService.set().then(() => {
        this.getData();
        this.loading = false;
      });
    },
    savePart(fields) {
      this.loading = true;
      this.systemDirectoryService.setPart(fields).then(() => {
        this.getData();
        this.loading = false;
      });
    },
    submitFilter(nameRef = "grid") {
      if (!this.$refs[nameRef]) return;
      this.$refs[nameRef].getRecords(true);
    },
    backHandler() {
      this.$router.go(-1);
    },
  },
  computed: {
    refsForCheckInMixin() {
      let refs = [
        "action-save",
        "name-ProSpaceInputText",
      ];

      return refs;
    },
    overviewTabs() {
      return [{ name: this.$t("overview"), systemName: "overview" }];
    },
  },
};
</script>
<style lang="scss"></style>
