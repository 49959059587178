<template>
  <ProSpaceGeoLayout class="taskassignmenttemplate" v-if="model">
    <template #top>
      <ProSpaceTopPanel :header="layoutName" back @back="backHandler">
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout
        :is-templated="true"
        :template-center="true"
        :template-right="true"
      >
        <template #center>
          <ProSpaceTabs
            v-model="modelTab"
            :tabs="overviewTabs"
            :loading="loading"
            :refLoading="mixinLoadingAccess"
            without-margin-bottom
            @select-tab="handlerSelectTab"
          >
            <ProSpaceAction
              ref="action-save"
              :disabled="v$.model.$invalid"
              icon="check"
              :text="$t('save')"
              @click="setModelAndSave()"
            />
            <template v-slot:tab-1>
              <ProSpaceVLayout :gap="20">
                <ProSpaceGeoLayout class="geo-row geo-row--leftlly">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('taskAssignmentTemplateTemplateNameInfo')"
                      :bottom="
                        $t('taskAssignmentTemplateTemplateNameInfoAdditional')
                      "
                      :gap="10"
                      break-word
                      type="third"
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceHLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="60"
                    >
                      <ProSpaceInputText
                        ref="templateName-ProSpaceInputText"
                        v-model="model.templateName"
                        :label="$t('taskAssignmentTemplateTemplateName')"
                        :placeholder="$t('taskAssignmentTemplateTemplateName')"
                        :invalid="v$.model.templateName.$error"
                        :invalid-messages="v$.model.templateName.$errors"
                        @blur="v$.model.templateName.$touch"
                      />
                      <ProSpaceVLayout
                        v-if="!model.disabled"
                        justify-content="flex-end"
                        align-items="flex-end"
                        is-inline-w
                      >
                        <div style="min-width: 190px; margin-bottom: 5px">
                          {{ $t("taskAssignmentTemplateFieldsEditingLable") }}
                        </div>
                      </ProSpaceVLayout>
                    </ProSpaceHLayout>
                  </template>
                </ProSpaceGeoLayout>
                <ProSpaceGeoLayout class="geo-row geo-row--leftlly">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('taskAssignmentTemplateTypeInfo')"
                      :bottom="$t('taskAssignmentTemplateTypeInfoAdditional')"
                      :gap="10"
                      break-word
                      type="third"
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceHLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="60"
                    >
                      <ProSpaceDropdown
                        v-model="model.type"
                        ref="type-ProSpaceDropdown"
                        :label="$t('taskAssignmentTemplateType')"
                        :placeholder="$t('taskAssignmentTemplateType')"
                        :invalid="v$.model.type.$error"
                        :invalid-messages="v$.model.type.$errors"
                        @blur="v$.model.type.$touch"
                        :service="baseTypeService"
                        getMethod="grid"
                        propValue="code"
                        propLabel="name"
                        argMethod="$filter=(systemDirectoryCode eq 'Task type' and disabled eq false)&$orderby=name asc"
                        @change="(item) => (model.type = item?.code)"
                      />
                      <ProSpaceDropdown
                        ref="editFieldModel-action-type-ProSpaceDropdown"
                        v-model="editFieldModel.type"
                        :service="templateEditableService"
                        :label="' '"
                        getMethod="get"
                        propValue="value"
                        propLabel="text"
                        style="max-width: 190px"
                      />
                    </ProSpaceHLayout>
                  </template>
                </ProSpaceGeoLayout>
                <ProSpaceGeoLayout class="geo-row geo-row--leftlly">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('taskAssignmentTemplateTaskInfo')"
                      :bottom="$t('taskAssignmentTemplateTaskInfoAdditional')"
                      :gap="10"
                      type="third"
                      break-word
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceVLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="10"
                    >
                      <ProSpaceHLayout
                        justify-content="space-between"
                        align-items="flex-start"
                        :gap="60"
                      >
                        <ProSpaceInputText
                          v-model="model.name"
                          ref="name-ProSpaceInputText"
                          :label="$t('taskAssignmentTemplateName')"
                          :placeholder="$t('taskAssignmentTemplateName')"
                          :invalid="v$.model.name.$error"
                          :invalid-messages="v$.model.name.$errors"
                          @blur="v$.model.name.$touch"
                        />
                        <ProSpaceDropdown
                          ref="editFieldModel-action-name-ProSpaceDropdown"
                          v-model="editFieldModel.name"
                          :service="templateEditableService"
                          getMethod="get"
                          propValue="value"
                          propLabel="text"
                          style="max-width: 190px; margin-top: 20px"
                        />
                      </ProSpaceHLayout>
                      <ProSpaceHLayout
                        justify-content="space-between"
                        align-items="flex-start"
                        :gap="60"
                      >
                        <ProSpaceExtendedTextArea
                          v-model="model.description"
                          ref="description-ProSpaceExtendedTextArea"
                          :label="$t('taskAssignmentTemplateDescription')"
                          :placeholder="$t('taskAssignmentTemplateDescription')"
                          :invalid="v$.model.description.$error"
                          :invalid-messages="v$.model.description.$errors"
                          :t="$t"
                          :locale="$i18n.locale"
                          @blur="v$.model.description.$touch"
                        >
                        </ProSpaceExtendedTextArea>
                        <ProSpaceDropdown
                          ref="editFieldModel-action-description-ProSpaceDropdown"
                          v-model="editFieldModel.description"
                          :service="templateEditableService"
                          getMethod="get"
                          propValue="value"
                          propLabel="text"
                          style="max-width: 190px; margin-top: 20px"
                        />
                      </ProSpaceHLayout>

                      <ProSpaceHLayout
                          justify-content="space-between"
                          align-items="flex-end"
                          :gap="60"
                        >
                          <ProSpaceHLayout
                            justify-content="flex-start"
                            align-items="center"
                            :gap="5"
                          >
                          <ProSpaceInputSwitch
                            ref="isPositionsRestricted-ProSpaceInputSwitch"
                            v-model="model.isPositionsRestricted"
                            :label="$t('taskAssignmentSendByPositions')"
                            :disabled="model.confirmationByAuthorizedPerson"
                            @update:modelValue="handleIsSendByPositionsUpdate"
                          />
                          <ProSpaceIcon
                            v-hint="$t('taskAssignmentSendByPositionsHint')"
                            icon="question"
                          />
                        </ProSpaceHLayout>
                        <ProSpaceDropdown
                          ref="editFieldModel-action-isPositionsRestricted-ProSpaceDropdown"
                          v-model="editFieldModel.isPositionsRestricted"
                          :service="templateEditableService"
                          getMethod="get"
                          propValue="value"
                          propLabel="text"
                          style="max-width: 190px"
                        />
                      </ProSpaceHLayout>

                      <ProSpaceHLayout
                        justify-content="space-between"
                        align-items="flex-end"
                        :gap="60"
                      >
                        <ProSpaceHLayout
                          justify-content="flex-start"
                          align-items="center"
                          :gap="5"
                        >
                          <ProSpaceInputSwitch
                            ref="isDistributorStructureRestricted-ProSpaceInputSwitch"
                            v-model="model.isDistributorStructureRestricted"
                            :label="$t('taskAssignmentSendByDistributorStructures')"
                            :disabled="model.confirmationByAuthorizedPerson"
                            @update:modelValue="handleIsDistributorStructureRestrictedUpdate"
                          />
                          <ProSpaceIcon
                            v-hint="$t('taskAssignmentSendByDistributorStructuresHint')"
                            icon="question"
                          />
                        </ProSpaceHLayout>

                        <ProSpaceDropdown
                          ref="editFieldModel-action-isDistributorStructureRestricted-ProSpaceDropdown"
                          v-model="editFieldModel.isDistributorStructureRestricted"
                          :service="templateEditableService"
                          getMethod="get"
                          propValue="value"
                          propLabel="text"
                          style="max-width: 190px"
                        />
                      </ProSpaceHLayout>

                      <ProSpaceHLayout
                        justify-content="space-between"
                        align-items="flex-end"
                        :gap="60"
                      >
                        <ProSpaceHLayout
                          justify-content="flex-start"
                          align-items="center"
                          :gap="5"
                        >
                          <ProSpaceInputSwitch
                            ref="confirmationByAuthorizedPerson-ProSpaceInputSwitch"
                            v-model="model.confirmationByAuthorizedPerson"
                            :label="$t('taskAssignmentConfirmationByAuthorizedPerson')"
                            :disabled="model.isDistributorStructureRestricted || model.isPositionsRestricted"
                            @update:modelValue="handleConfirmationByAuthorizedPersonUpdate"
                          />
                          <ProSpaceIcon
                            v-hint="$t('taskAssignmentConfirmationByAuthorizedPersonHint')"
                            icon="question"
                          />
                        </ProSpaceHLayout>

                        <ProSpaceDropdown
                          ref="editFieldModel-action-confirmationByAuthorizedPerson-ProSpaceDropdown"
                          v-model="editFieldModel.confirmationByAuthorizedPerson"
                          :service="templateEditableService"
                          getMethod="get"
                          propValue="value"
                          propLabel="text"
                          style="max-width: 190px"
                        />
                      </ProSpaceHLayout>

                      <ProSpaceHLayout
                        justify-content="space-between"
                        align-items="flex-end"
                        :gap="60"
                      >
                        <ProSpaceHLayout
                          justify-content="flex-start"
                          align-items="center"
                          :gap="5"
                        >
                          <ProSpaceInputSwitch
                            ref="marsValidation-ProSpaceInputSwitch"
                            v-model="model.marsValidation"
                            :label="$t('taskAssignmentTemplateMarsValidation')"
                          />
                          <ProSpaceIcon
                            v-hint="$t('taskAssignmentMarsValidationHint')"
                            icon="question"
                          />
                        </ProSpaceHLayout>

                        <ProSpaceDropdown
                          ref="editFieldModel-action-marsValidation-ProSpaceDropdown"
                          v-model="editFieldModel.marsValidation"
                          :service="templateEditableService"
                          getMethod="get"
                          propValue="value"
                          propLabel="text"
                          style="max-width: 190px"
                        />
                      </ProSpaceHLayout>
                    </ProSpaceVLayout>
                  </template>
                </ProSpaceGeoLayout>
                <ProSpaceGeoLayout class="geo-row geo-row--leftlly">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('taskAssignmentTemplateTagsInfo')"
                      :bottom="$t('taskAssignmentTemplateTagsInfoAdditional')"
                      :gap="10"
                      break-word
                      type="third"
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceHLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="60"
                    >
                      <ProSpaceInputChips
                        v-model="tags"
                        ref="tags-ProSpaceInputChips"
                        placeholder="+"
                        :label="$t('taskAssignmentTemplateTags')"
                        :invalid="v$.model.tags.$error"
                        :invalid-messages="v$.model.tags.$errors"
                        @blur="v$.model.tags.$touch"
                      />
                      <ProSpaceDropdown
                          ref="editFieldModel-action-tags-ProSpaceDropdown"
                        v-model="editFieldModel.tags"
                        :service="templateEditableService"
                        :label="' '"
                        getMethod="get"
                        propValue="value"
                        propLabel="text"
                        style="max-width: 190px"
                      />
                    </ProSpaceHLayout>
                  </template>
                </ProSpaceGeoLayout>
                <ProSpaceGeoLayout class="geo-row geo-row--leftlly">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('taskAssignmentTemplateAttachmentsInfo')"
                      :bottom="
                        $t('taskAssignmentTemplateAttachmentsInfoAdditional')
                      "
                      :gap="10"
                      break-word
                      type="third"
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceHLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="60"
                    >
                      <ProSpaceFile
                        v-model="attachments"
                        ref="attachments-ProSpaceFile"
                        accept=".doc, .docx, .docm, .rtf, .txt, .csv, .xml, .pdf, .png, .gif, .jpg, .jpeg, .ppt, .pptx, .potx, .potm, .pptm, .ppsx, .ppsm, .xls, .xlsx, .xlsm, .xlsb, .zip, .7z, .rar, .mp4, .avi, .mkv, .wmv"
                        :service="fileService"
                        :fileLimit="1000"
                        :maxFileSize="209715200"
                        :txtBtnUpload="$t('uploadFile')"
                        :txtEmptyFiles="$t('uploadFileDragAndDropText')"
                        :txtErrorStatus="$t('uploadStatusError')"
                        :invalidFileLimitMessage="$t('invalidFileLimitMessage')"
                        :invalidFileSizeMessage="$t('invalidFileSizeMessage')"
                        :invalidFileTypeMessage="$t('invalidFileTypeMessage')"
                      />
                      <ProSpaceDropdown
                          ref="editFieldModel-action-attachments-ProSpaceDropdown"
                        v-model="editFieldModel.attachments"
                        :service="templateEditableService"
                        getMethod="get"
                        propValue="value"
                        propLabel="text"
                        style="max-width: 190px"
                      />
                    </ProSpaceHLayout>
                  </template>
                </ProSpaceGeoLayout>
              </ProSpaceVLayout>
            </template>
            <template v-slot:tab-2>
              <ProSpaceGeoLayout class="geo-row">
                <template v-slot:left>
                  <ProSpaceTextBlock
                    :top="$t('positions')"
                    :bottom="$t('taskPositionsInfo')"
                    :gap="10"
                    type="third"
                    break-word
                  />
                </template>
                <template v-slot:center>
                  <ProSpaceGeoLayout>
                    <template v-slot:center>
                      <ProSpaceHLayout
                        justify-content="space-between"
                        align-items="flex-start"
                        :gap="60"
                      >
                        <PositionSelectTree
                          ref="tree"
                          expandAll
                          :positionService="positionOptions.positionService"
                          :positionGetMethod="positionOptions.positionGetMethod"
                          :positionSelectionMode="positionOptions.positionSelectionMode"
                          :positionFilter="positionOptions.positionFilter"
                          :propLabel="positionOptions.propLabel"
                          :checkedPositions="positions"
                          @changed="
                            (newPositions) => {
                              this.positions = [...newPositions];
                            }
                          "
                        />
                        <ProSpaceDropdown
                          v-model="editFieldModel.positions"
                          :service="templateEditableService"
                          getMethod="get"
                          propValue="value"
                          propLabel="text"
                          style="max-width: 190px"
                        />
                      </ProSpaceHLayout>
                    </template>
                  </ProSpaceGeoLayout>
                </template>
              </ProSpaceGeoLayout>
            </template>
          </ProSpaceTabs>
        </template>
      </ProSpaceGeoLayout>
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import {
  ProSpaceTopPanel,
  ProSpaceInputText,
  ProSpaceAction,
  ProSpaceRadioButtonSecondary,
  ProSpaceGeoLayout,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpaceTextBlock,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceDropdown,
  ProSpaceFile,
  ProSpaceInputCalendar,
  ProSpaceInputNumber,
  ProSpaceSearchfield,
  ProSpaceInputSwitch,
  ProSpaceMultiSelect,
  ProSpaceCustomFilterPanel,
  ProSpaceIcon,
  ProSpaceExtendedTextArea,
  ProSpaceInputChips,
  ProSpaceDatePeriod,
  Hint,
} from "prospace-components-library";
import { TaskAssignmentTemplateService } from "../../services/TaskAssignmentTemplateService.js";
import { TaskAssignmentTemplateAttachmentService } from "../../services/TaskAssignmentTemplateAttachmentService.js";
import { TaskAssignmentService } from "../../services/TaskAssignmentService.js";
import { TemplateEditableService } from "../../../../../../Application/Frontends/ProSpace/services/TemplateEditableService.js";
import { BaseTypeService } from "../../../../BaseType/BaseTypeService.Frontend/services/BaseTypeService";
import { FileService } from "../../../../../Frontends/ProSpace/services/FileService.js";
import { DistributorOrgService } from "@masterdata/services/DistributorOrgService";
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import PositionSelectTree from "@trees/PositionSelectTree";

import {
  required,
  maxLength,
  helpers,
  minValue,
  maxValue,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { mapGetters } from "vuex";
import {
  useStatistic
} from "@composes"
export default {
  mixins: [accessMixin],
  components: {
    ProSpaceTopPanel,
    ProSpaceGeoLayout,
    ProSpaceInputText,
    ProSpaceAction,
    ProSpaceRadioButtonSecondary,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpaceTextBlock,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceDropdown,
    ProSpaceFile,
    ProSpaceInputCalendar,
    ProSpaceInputNumber,
    ProSpaceSearchfield,
    ProSpaceInputSwitch,
    ProSpaceMultiSelect,
    ProSpaceCustomFilterPanel,
    ProSpaceIcon,
    ProSpaceExtendedTextArea,
    ProSpaceInputChips,
    ProSpaceDatePeriod,
    PositionSelectTree
  },
  directives: {
    hint: Hint,
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    return { v$: useVuelidate(), setStatistic, distActions };
  },
  data() {
    return {
      // Tabs
      modelTab: {
        name: this.$t("parameters"),
        isActive: true,
        systemName: "parameters",
      },

      // Common
      model: null,
      editFieldModel: {
        name: true,
        type: true,
        description: true,
        isPositionsRestricted: true,
        isDistributorStructureRestricted: true,
        marsValidation: true,
        confirmationByAuthorizedPerson: true,
        repeatConfig: true,
        tags: true,
        attachments: true,
        positions: true,
      },
      loading: false,
      mixinLoadingAccess: false,
      attachments: [],
      attachmentsToCreate: [],
      tags: [],
      positions: [],

      // Service
      taskAssignmentTemplateService: new TaskAssignmentTemplateService(),
      taskAssignmentTemplateAttachmentService: new TaskAssignmentTemplateAttachmentService(),
      taskAssignmentService: new TaskAssignmentService(),
      templateEditableService: new TemplateEditableService(),
      baseTypeService: new BaseTypeService(),
      fileService: new FileService(),

      getMethod: "get",
      masterId: null,

      prefixTop: "",
      prefixBottom: "",
    };
  },
  validations() {
    return {
      model: {
        templateName: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '100'}),
            maxLength(100)
          ),
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        name: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '100'}),
            maxLength(100)
          ),
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        type: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '255'}),
            maxLength(255)
          ),
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        description: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        isPositionsRestricted: {},
        isDistributorStructureRestricted: {},
        marsValidation: {},
        confirmationByAuthorizedPerson: {},
        repeatConfig: {},
        tags: {},
      },
    };
  },
  mounted() {
    this.masterId = this.$route.params.id;
    this.mountAccess();
    this.loadRoleAccess();
    this.getData();
  },
  methods: {
    handleIsSendByPositionsUpdate(value) {
      if (!value) {
        this.positions = [];
        this.model.positions = [];
      }else {
        this.model.confirmationByAuthorizedPerson = false;
      }
    },
    handleIsDistributorStructureRestrictedUpdate(value) {
      if (!value) {
        this.model.distributorStructures = [];
      }else {
        this.model.confirmationByAuthorizedPerson = false;
      }
    },
    handleConfirmationByAuthorizedPersonUpdate(value) {
      if (value) {
        this.model.isPositionsRestricted = false;
        this.model.isDistributorStructureRestricted = false;
      }
    },
    handlerSelectTab({ selectedTab }) {
      this.$nextTick(() => {
        this.mountAccess();
      });

      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: selectedTab.systemName
      })
    },
    gridLoaded() {},
    async getData() {
      let fromTask = false;
      if (this.masterId !== undefined) {
        const result = await this.taskAssignmentTemplateService[this.getMethod](
          this.masterId
        );
        this.model = result.data;
        this.positions = [...this.model.positions];
      } else if (this.$route.query && this.$route.query.taskId) {
        //создание шаблона из таска по кнопке 'Создать шаблон'
        fromTask = true;
        const result = await this.taskAssignmentService.getFull(
          this.$route.query.taskId,
          JSON.parse(localStorage.getItem("userRole")).systemName
        );
        this.model = result.data;
        this.positions = [...this.model.positions];
      } else {
        this.model = {
          type:
            this.$route.query &&
            this.$route.query.code &&
            this.$route.query.code != "all"
              ? this.$route.query.code
              : null,
          attachments: [],
          confirmationByAuthorizedPerson: true,
        };
        return;
      }

      if (this.model) {
        this.tags = this.model.tags
          ? this.model.tags.split("#").filter((t) => !!t)
          : [];
        try {
          if (!fromTask && this.model.editFieldsSetting) {
            this.editFieldModel = Object.assign(this.editFieldModel, JSON.parse(this.model.editFieldsSetting));
          }
        } catch {}
      }
      if (this.model.attachments)
        this.attachments = this.model.attachments.map((f) => {
          let r = new File([], f.fileName);
          r.gfid = f.gfid;
          r.fileLength = f.fileLength;
          r.statusFlag = f.statusFlag;
          return r;
        });
    },
    setModelAndSave() {
      this.loading = true;
      this.setModel().then((response) => {
        let newFiles = this.setNewAttachments(response.data.data);
        this.save(newFiles);
      });
    },
    setModel() {
      this.model.tags = this.tags.join("#");
      this.model.editFieldsSetting = JSON.stringify(this.editFieldModel);

      if (this.model.isPositionsRestricted) {
        this.positions.forEach((x) => {
          x["taskAssignmentTemplateId"] = this.model.id;
          x["leaf"] = !this.positions.some(
            (p) => p.parentId === x.distributorOrgId
          );
        });
        this.model.positions = this.positions;
      } else {
        this.model.positions = [];
      }

      this.attachmentsToCreate = this.attachments.filter((f) => !f.gfid);
      const actualFiles = this.attachments
        .filter((f) => f.gfid)
        .map((f) => f.gfid);
      if (this.model.attachments)
        this.model.attachments = this.model.attachments.filter((f) =>
          actualFiles.includes(f.gfid)
        );
      if (this.attachmentsToCreate.length > 0) {
        let fileInfos = this.attachmentsToCreate
          .filter((f) => !f.id)
          .map((f) => {
            return {
              fileName: f.name,
              fileVisibleFlag: 0,
            };
          });
        return this.fileService.createFilesRoot(fileInfos);
      }
      return new Promise((resolve, reject) => {
        resolve({ data: { data: [] } });
      });
    },
    setNewAttachments(attachments) {
      let newFiles = attachments.map((f, i) => {
        return {
          fileName: f.fileName,
          gfid: f.gfid,
          fileLength: this.attachmentsToCreate[i].size,
        };
      });
      this.model.attachments = Array.isArray(this.model.attachments)
        ? this.model.attachments.concat(newFiles)
        : newFiles;
      return newFiles;
    },
    save(newFiles) {
      const isCreate = !this.masterId;
      let saveMethod = isCreate ? "create" : "update";
      this.taskAssignmentTemplateService[saveMethod](this.model).then(
        (result) => {
          this.model.id = result.data[0].id;
          if (newFiles.length > 0) {
            this.upload(newFiles).then(() => {
              this.afterSave(result, isCreate);
            });
          } else {
            this.afterSave(result, isCreate);
          }
        }
      );
    },
    afterSave(result, isCreate) {
      if (isCreate) {
        this.masterId = result.data[0].id;
      }
      this.getData();
      this.loading = false;
    },
    async upload(newFiles) {
      await this.fileService.uploadChunks(newFiles, this.attachmentsToCreate, this.uploadSuccess, this.uploadError);
      this.attachmentsToCreate = [];
    },
    async uploadSuccess(gfids) {
      await this.taskAssignmentTemplateAttachmentService.acceptFiles(gfids, this.model.id);
    },
    async uploadError(gfids) {
      await this.taskAssignmentTemplateAttachmentService.errorFiles(gfids, this.model.id);
    },
    submitFilter(nameRef = "grid") {
      if (!this.$refs[nameRef]) return;
      this.$refs[nameRef].getRecords(true);
    },
    backHandler() {
      this.$router.go(-1);
    },
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      access: "securityRoleAccess/access",
    }),
    refsForCheckInMixin() {
      let refs = [];

      if (this.modelTab.systemName === "parameters")
        refs.push(
          "templateName-ProSpaceInputText",
          "editFieldModel-action-name-ProSpaceDropdown",
          "editFieldModel-action-type-ProSpaceDropdown",
          "editFieldModel-action-description-ProSpaceDropdown",
          "editFieldModel-action-isPositionsRestricted-ProSpaceDropdown",
          "editFieldModel-action-isDistributorStructureRestricted-ProSpaceDropdown",
          "editFieldModel-action-marsValidation-ProSpaceDropdown",
          "editFieldModel-action-confirmationByAuthorizedPerson-ProSpaceDropdown",
          "editFieldModel-action-tags-ProSpaceDropdown",
          "editFieldModel-action-attachments-ProSpaceDropdown",
          "action-save",
          "type-ProSpaceDropdown",
          "name-ProSpaceInputText",
          "description-ProSpaceExtendedTextArea",
          "isPositionsRestricted-ProSpaceInputSwitch",
          "isDistributorStructureRestricted-ProSpaceInputSwitch",
          "marsValidation-ProSpaceInputSwitch",
          "confirmationByAuthorizedPerson-ProSpaceInputSwitch",
          "tags-ProSpaceInputChips",
          "attachments-ProSpaceFile"
        );

      return refs;
    },
    overviewTabs() {
      return [
        {
          name: this.$t("parameters"),
          systemName: "parameters",
          isActive: this.modelTab.systemName === "parameters",
        },
        {
          name: this.$t("positions"),
          systemName: "positions",
          isHidden: !this.model.isPositionsRestricted,
          isActive: this.modelTab.systemName === "positions",
        },
      ];
    },
    layoutName() {
      return this.masterId
        ? this.model.templateName
        : this.$t("newTxt") + " " + this.$t("taskAssignmentTemplate");
    },
    positionOptions() {
      let options = {
        positionService: null,
        positionGetMethod: null,
        positionSelectionMode: null,
        positionFilter: null,
        propLabel: null,
      };

      if (this.checkAccess("position-PositionSelectTree", this.model)) {
        options.positionService = new DistributorOrgService();
        options.positionGetMethod = "allTree";
        options.positionSelectionMode = "checkbox";
        options.propLabel = "name";
      } else {
        let positionService = new TaskAssignmentTemplateService();
        positionService.taskAssignmentTemplateId = this.model.id;
        options.positionService = positionService;
        options.positionGetMethod = "getPositions";
        options.positionSelectionMode = "single";
        options.propLabel = "distributorOrgName";
        options.positionFilter = {
          disabled: { and: [false] },
          taskAssignmentTemplateId: this.model.id,
        };
      }

      return options;
    }
  }
};
</script>
