<template>
  <ProSpaceRightPanel
    class="notification-panel"
    :header="header"
    :loading="loading"
    :has-error="hasError"
    @edit="editPanel"
    @delete="deletePanel"
    @clear="clearPanel"
    @show-history="showHistoryPanel"
  >
    <template v-slot:center>
      <ProSpaceTabs :tabs="tabs" v-if="notification" @select-tab="handlerSelectTab">
        <template v-slot:tab-1>
          <ProSpacePanelBlock :header="headerOverview">
            <template v-slot:header>
              <ProSpaceAction
                :text="$t('editItemTxt')"
                @click="onClickEditOverview"
              />
            </template>
            <template v-slot>
              <ProSpacePanelBlockItem
                :title="$t('description')"
                :text="notification.description"
              />
              <ProSpacePanelBlockItem
                :title="$t('activityPeriod')"
                class="margin-top-10 padding-top-10"
                :topline="true"
              >
                <template #content>
                  <ProSpaceDatesColumn
                    :start-date="notification.startDate"
                    :end-date="notification.endDate"
                    :format="userinfo.dateFormat"
                  />
                </template>
              </ProSpacePanelBlockItem>
              <ProSpacePanelBlockItem
                :title="$t('events')"
                class="margin-top-10 padding-top-10"
                :topline="true"
              >
                <template #content>
                  <div class="events">
                    {{
                      notification.events
                        .map((i) => i.notifyEventName)
                        .join(", ")
                    }}
                  </div>
                </template>
              </ProSpacePanelBlockItem>
            </template>
          </ProSpacePanelBlock>
        </template>
        <template v-slot:tab-2>
          <ProSpacePanelBlock :header="headerRecipients">
            <template v-slot:header>
              <!-- <ProSpaceAction
                :text="$t('editItemTxt')"
                @click="onClickEditRecipients"
              /> -->
            </template>
            <template v-slot>
              <RecipientTree
                v-if="notification"
                :recipientFilter="JSON.parse(notification.recipientFilter)"
                :recipientTreeService="secondService"
                :needSearch="false"
                @updated="updateRecipintFilter($event, notification)"
              />
            </template>
          </ProSpacePanelBlock>
        </template>
      </ProSpaceTabs>
    </template>
    <template v-slot:bottom>
      <ProSpacePanelBlock :header="headerBottom">
        <template v-slot>
          <ProSpacePanelBlockList :list="contentBottom" />
        </template>
      </ProSpacePanelBlock>
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceRightPanel,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceAction,
  ProSpacePanelBlockHeader,
  ProSpacePanelBlockItem,
  ProSpaceTabs,
  ProSpaceDatesColumn
} from "prospace-components-library";
import RecipientTree from "../RecipientTree.vue";
import { mapGetters } from "vuex";
import {
  useStatistic
} from "@composes"
import {
  useMultiSelectRightPanel,
} from "@composes/multiselect"
import PanelBlockSelectedItems from "@multiselect/PanelBlockSelectedItems"
export default {
  name: "NotificationPanel",
  components: {
    ProSpaceRightPanel,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceAction,
    ProSpacePanelBlockHeader,
    ProSpacePanelBlockItem,
    ProSpaceTabs,
    RecipientTree,
    ProSpaceDatesColumn,
    PanelBlockSelectedItems
  },
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    service: {
      type: Object,
      required: true,
    },
    secondService: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    const { selectedData } = useMultiSelectRightPanel()
    return {
      setStatistic, distActions,
      selectedData
    }
  },
  data() {
    return {
      notification: null,
      claims: null,
      header: {},
      loading: false,
      hasError: false,
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime",
    }),
    headerOverview() {
      return {
        title: this.$t("overview"),
        isExpand: false,
      };
    },
    headerRecipients() {
      return {
        title: this.$t("recipients"),
        isExpand: false,
      };
    },
    headerBottom() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    tabs() {
      return [
        { name: this.$t("overview"), isActive: true, systemName: "overview" },
        { name: this.$t("recipients"), systemName: "recipients" },
      ];
    },
    header() {
      if (this.notification) {
        return {
          title: this.notification.name,
          id: this.notification.id,
          status: this.getStatus(this.notification.isActive),
          showCounter: false,
          options: this.options,
        };
      }
      return {
        title: this.$t("itemsSelected"),
        showCounter: false,
      };
    },
    contentBottom() {
      if (this.notification) {
        return [
          [
            {
              title: this.$t("createdByTxt"),
              text: this.notification.createdBy,
            },
            {
              title: this.$t("updatedByTxt"),
              text: this.notification.updatedBy,
            },
          ],
          [
            {
              title: this.$t("createdAtTxt"),
              text: this.getFormatDateTime(this.notification.createdAt),
            },
            {
              title: this.$t("lastUpdatedTxt"),
              text: this.getFormatDateTime(this.notification.lastUpdated),
            },
          ],
        ];
      }
      return [];
    },
  },
  mounted() {
    this.update();
  },
  watch: {
    selectedData() {
      this.update()
    }
  },
  methods: {
    handlerSelectTab({ selectedTab }) {
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: selectedTab.systemName
      })
    },
    getStatus(status) {
      if (status) {
        return {
          text: "Active",
          type: "success",
        };
      } else {
        return {
          text: "Not active",
          type: "default",
        };
      }
    },
    editPanel() {
      this.$emit("edit", this.notification);
    },
    deletePanel() {
      this.$emit("delete-item", this.notification);
    },
    clearPanel() {
      this.$emit("clear");
    },
    showHistoryPanel() {
      this.$emit("history-item", this.notification);
    },
    async update() {
      this.hasError = false;
      try {
        this.notification = await this.getNotification();
        let recipientFilter = null;
        try {
          recipientFilter = JSON.parse(this.notification.recipientFilter);
        } catch {}
        if (!recipientFilter)
        this.notification.recipientFilter = JSON.stringify(this.secondService.emptyData);
      } catch (e) {
        this.hasError = true;
      }
    },
    async getNotification() {
      if (this.selectedData) {
        this.loading = true;
        const res = await this.service.get(this.selectedData.id);
        this.loading = false;
        return res.data;
      }
      return null;
    },
    updateRecipintFilter(data, model) {
      model.recipientFilter = JSON.stringify(data);
      this.service.setModel(model);
    },
    onClickEditOverview() {
      this.$emit("overview-edit", this.notification);
    },
    onClickEditRecipients() {
      this.$emit("recipients-edit");
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-panel {
  .events {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--prospace-ui-main);
  }
}
</style>
