export class StatusService {
  constructor() {
    this.dataStatuses = [
      { text: "Active", status: "Active" },
      { text: "Inactive", status: "Inactive" },
      { text: "Blocked", status: "Blocked" },
      { text: "New", status: "New" },
    ];
    this.roleDataStatuses = [
      { text: "Active", status: "Active" },
      { text: "Inactive", status: "Inactive" },
    ];
  }
  search(value) {
    return this.dataStatuses.filter((elem) => elem.text.indexOf(value) !== -1);
  }
  async get() {
    return Promise.resolve({ data: { records: this.dataStatuses } });
  }
  async getRole() {
    return Promise.resolve({ data: { records: this.roleDataStatuses } });
  }
}
