<template>
  <ProSpaceGeoLayout>
    <template #top>
      <ProSpaceTopPanel :header="this.$t('baseTypes')">
        <template #right>
          <ProSpaceIconButton
            icon="update"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
            @click="actionUpdate"
          />
          <ProSpaceButton
            v-if="checkAccess('add-button')"
            style="margin-left: 10px"
            ref="add-button"
            type="primary"
            icon="plus"
            :label="this.$t('newTxt')"
            @click="showChangeModal"
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #left>
      <ProSpaceLeftPanel
        class="basetype-tree__left-panel"
        :panelName="$t('topics')"
        searchable
        :searchPlaceholder="$t('searchTxt')"
        @search="handlerSearch"
      >
        <template #center>
          <ProSpaceTree
            ref="tree"
            :service="systemdirectoryService"
            searchName="name"
            getNodeMethod="tree"
            propKey="id"
            propLabel="name"
            :default-filter="defaultFilters.disabled"
            :prop-parent-id="null"
            defaultSortField="name"
            @select="nodeSelect"
          />
        </template>
      </ProSpaceLeftPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpaceFilterPanel
            :closeTxt="$t('closeTxt')"
            :searchPlaceholder="$t('searchTxt')"
            :txtResetFilters="$t('txtResetFilters')"
            :modelFastFilter="modelFastFilter"
            :noMountedRequest="true"
            :fastFilters="fastFilters"
            :unicKeyStorage="basetypeService.constructor.name + 'grid'"
            @apply="submitFilter"
            @update:modelFastFilter="
              (val) => {
                modelFastFilter = val;
                selectedMultipleClear()
              }
            "
            ref="filterPanel"
            :filter-model="['name', 'description']"
            :ignore-clear-keys="['systemDirectoryId']"
          >
            <template #hint>
              {{ this.$t("filterBy") }}
            </template>
            <template #filters="{ filter }">
              <ProSpaceFilterContainsChips
                v-model="filter.name"
                :placeholder-value="$t('name')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                @apply="emitFilter"
              />
              <ProSpaceCustomFilterPanel
                :customTxt="$t('custom')"
                :customFilterTxt="$t('customFilterTxt')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                :services="customServices"
                :unicKeyStorage="basetypeService.constructor.name + 'grid'"
                @apply="(model) => customFilterApply(filter, model)"
              />
            </template>
          </ProSpaceFilterPanel>
        </template>
        <template #center>
          <ProSpaceMainGrid
            ref="grid"
            getMethod="grid"
            selectionMode="single"
            defaultSortField="createdAt"
            :defaultSortOrder="-1"
            :service="basetypeService"
            :noMountedRequest="true"
            :default-filter="defaultFilters.disabled"
            @selected-change="selectedHandler"
            @db-click="toItem"
          >
            <template #columns>
              <Column
                field="name"
                :header="this.$t('name')"
                sortable
                headerClass="move-sort-head"
              >
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    v-if="field.data"
                    :id="field.data.id"
                    :status="getStatus(field.data.status)"
                    :title="field.data.name"
                    :icon="field.data.icon"
                    :noHover="!checkFormAccess(field.data)"
                    @click-on-title="toItem(field.data)"
                  />
                </template>
              </Column>
              <Column field="description" :header="$t('description')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    {{ field.data.description }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column field="createdAt" sortable :header="$t('createdDate')">
                <template #body="field">
                  <ProSpaceDateTimeColumn
                    :date="field.data.createdAt"
                    :dateFormat="userinfo.dateFormat"
                    :timeFormat="userinfo.timeFormat"
                  />
                </template>
              </Column>
              <Column field="actions" class="column-end column-end-30">
                <template #body="field">
                  <ProSpaceDots
                    :options="wrapperCheckRoles(dotsOptions, field.data)"
                    @action="(action) => handleDots(action, field.data)"
                  ></ProSpaceDots>
                </template>
              </Column>
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                icon="error-message"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
              />
            </template>
          </ProSpaceMainGrid>
        </template>
      </ProSpaceGeoLayout>
      <ProSpaceModal
        :key="`validate-modal-${showModal}`"
        v-model="showModal"
        width="410"
        :disabledApply="false"
        :title="isEdit ? $t('editItemTxt') : $t('createItemTxt')"
        :icon="isEdit ? 'edit' : 'plus'"
        @apply="changeItem"
        :cancelTxt="$t('cancelInf')"
        :applyTxt="$t('applyTxt')"
        :validate-local="$t"
        :validate-model="modalModel"
        :validate-config="{
          name: { required },
        }"
      >
        <template #default="{ v }">
          <ProSpaceVLayout :gap="20">
            <ProSpaceDropdown
              v-model="modalModel.systemDirectoryId"
              propValue="id"
              propLabel="name"
              getMethod="grid"
              argMethod="$filter=(disabled eq false)&$orderby=name asc"
              :label="$t('systemDirectory')"
              :service="systemdirectoryService"
              :placeholder="$t('systemDirectoryName')"
            />
            <ProSpaceInputText
              v-model="modalModel.name"
              :label="$t('name')"
              :placeholder="$t('name')"
              :invalid="v.name.$error"
              :invalid-messages="v.name.$errors"
              @blur="v.name.$touch"
            />
            <ProSpaceDropdown
              v-model="modalModel.icon"
              getMethod="getIcons"
              :label="$t('icon')"
              :service="basetypeService"
            >
              <template #value="slotProps">
                <ProSpaceHLayout :gap="5">
                  <ProSpaceIcon :icon="slotProps.value" />
                  {{
                    slotProps.value == "note-sticky" ? "default" : slotProps.value
                  }}
                </ProSpaceHLayout>
              </template>
              <template #option="slotProps">
                <ProSpaceHLayout :gap="5">
                  <ProSpaceIcon :icon="slotProps.option.value" />
                  <span class="text-size-12">{{ slotProps.option.label }}</span>
                </ProSpaceHLayout>
              </template>
            </ProSpaceDropdown>
            <ProSpaceInputText
              v-model="modalModel.description"
              :label="$t('description')"
              :placeholder="$t('description')"
            />
          </ProSpaceVLayout>
        </template>
      </ProSpaceModal>
      <ProSpaceHistoryModal
        ref="history"
        v-model="showHistoryModal"
        modelName="BaseTypeApiModel"
        @close="closeHistory"
      />
    </template>
    <template #right>
      <BaseTypePanel
        v-if="hasSelect"
        ref="right-panel"
        :service="basetypeService"
        :options="dotsOptions"
        :filterAccessDots="wrapperCheckRoles"
        :checkEditAccess="checkFormAccess"
        :checkAccess="checkAccess"
        @edit="editItem"
        @delete="deleteItem"
        @history="showHistory"
      />
    </template>
  </ProSpaceGeoLayout>
  <ProSpaceFlashMessage
    v-if="showDeleteModal"
    v-model="showDeleteModal"
    type="default"
    icon="flash-warning"
    :title="$t('deletingContactUser')"
    :applyTxt="$t('confirmTxt')"
    :cancelTxt="$t('reject')"
    @apply="applyDelete"
  >
    <ProSpaceVLayout :gap="10">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
        v-html="$t('deleteBaseType', { name:  deletingModel.name })"
      ></div>
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
</template>
<script>
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import {
  ProSpaceFilterPanel,
  ProSpaceCustomFilterPanel,
  ProSpaceMultiSelect,
  ProSpaceMainGrid,
  ProSpaceDots,
  ProSpaceModal,
  ProSpaceInputText,
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceIconButton,
  ProSpaceIcon,
  ProSpaceButton,
  ProSpaceTableHeaderCell,
  ProSpaceEmptyGrid,
  ProSpaceLeftPanel,
  ProSpaceTree,
  ProSpaceVLayout,
  ProSpaceDropdown,
  ProSpaceHLayout,
  Filters,
  ProSpaceFilterContainsChips,
  ProSpaceDateTimeColumn,
  ProSpaceFlashMessage
} from "prospace-components-library";
import Column from "primevue/column";
import { BaseTypeService } from "../../services/BaseTypeService.js";
import { SystemDirectoryService } from "../../services/SystemDirectoryService.js";
import BaseTypePanel from "./components/BaseTypePanel";
import ProSpaceHistoryModal from "@modals/ProSpaceHistoryModal.vue";
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {
  useMultiSelectGrid,
} from "@composes/multiselect";
import { ref } from "vue"
const _ = require("lodash");
import {
  useStatistic
} from "@composes"
export default {
  mixins: [accessMixin],
  components: {
    ProSpaceFilterPanel,
    ProSpaceCustomFilterPanel,
    ProSpaceMultiSelect,
    ProSpaceMainGrid,
    ProSpaceDots,
    ProSpaceModal,
    Column,
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceIconButton,
    ProSpaceIcon,
    ProSpaceButton,
    ProSpaceTableHeaderCell,
    ProSpaceEmptyGrid,
    BaseTypePanel,
    ProSpaceHistoryModal,
    ProSpaceLeftPanel,
    ProSpaceTree,
    ProSpaceInputText,
    ProSpaceVLayout,
    ProSpaceDropdown,
    ProSpaceHLayout,
    ProSpaceFilterContainsChips,
    ProSpaceDateTimeColumn,
    ProSpaceFlashMessage
  },
  setup() {
    const grid = ref(null)
    const filterPanel = ref(null)
    const { setStatistic, distActions } = useStatistic()
    const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      hasSelect,
    } = useMultiSelectGrid(grid, filterPanel)
    return {
      v$: useVuelidate(),
      grid, filterPanel,
      setStatistic, distActions,
      selectedMultipleGridHandler,
      hasSelect, selectedMultipleClear,
    }
  },
  data() {
    return {
      showModal: false,
      showHistoryModal: false,

      gridGetMethod: null,
      // Service
      basetypeService: new BaseTypeService(),
      systemdirectoryService: new SystemDirectoryService(),

      //InlinePanel
      masterId: null,
      isEdit: false,
      isCreate: false,
      modalModel: {
        icon: "note-sticky",
      },
      selectedNode: null,

      // Multi filter
      modelFastFilter: {
        label: this.$t("allTxt"),
        field: "disabled",
        value: false,
      },
      defaultFilters: Filters.getDefaultsFilters(),

      deletingModel: null,
      showDeleteModal: false,
    };
  },
  validations() {
    return {
      modalModel: {
        name: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      access: "securityRoleAccess/access",
    }),
    dotsOptions() {
      let res = [
        {
          name: "edit",
          text: this.$t("editItemTxt"),
          icon: "edit",
          method: this.editItem,
          liteRef: "edit-option",
        },
        {
          name: "delete",
          text: this.$t("delete"),
          icon: "delete",
          method: this.deleteItem,
          liteRef: "delete-option",
        },
        {
          name: "showHistory",
          text: this.$t("history"),
          icon: "history",
          method: this.showHistory,
          liteRef: "history-option",
        },
      ];
      if (this.modelFastFilter.label === this.$t("deleted")) {
        res = res.filter(
          (option) => option.name !== "edit" && option.name !== "delete"
        );
      }
      return res;
    },
    fastFilters() {
      let filters = [
        { label: this.$t("allTxt"), field: "disabled", value: false },
      ];

      if(this.checkDeleteAccess())
        filters.push({ label: this.$t("deleted"), field: "disabled", value: true });

      return filters;
    },
    customServices() {
      let filters = [
        {
          type: "number",
          model: "id",
          label: this.$t("id"),
          selected: {},
        }
      ]

      if(this.checkAccess("metainfo-by-block"))
        filters.push(
          {
            type: "chips",
            model: "createdBy",
            label: this.$t("createdByTxt"),
            selected: {},
          },
          {
            type: "chips",
            model: "updatedBy",
            label: this.$t("updatedByTxt"),
            selected: {},
          }
        );

      filters.push(
        {
          type: "dates-range-time",
          model: "createdAt",
          label: this.$t("createDate"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range-time",
          model: "lastUpdated",
          label: this.$t("lastUpdatedTxt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        }
      );

      return filters;
    },
  },
  mounted() {
    this.loadRoleAccess();
  },
  methods: {
    handlerSearch(value) {
      this.$refs.tree.handlerSearch(value);
    },
    closeHistory() {
      this.showHistoryModal = false;
    },
    showHistory(record) {
      this.$refs.history.tabs[0].masterId = record.id;
      this.$refs.history.masterName = record.name;
      this.$nextTick(() => {
        this.showHistoryModal = true;
      });
    },
    toItem(data) {
      if (!data || !this.checkFormAccess(data)) return;
      const url = `/basetype/basetype/${data.id}`
      this.setStatistic({
        actionTemp: this.distActions.form,
        requestUrl: url
      })
      this.$router.push(url);
    },
    submitFilter({ filter, search }) {
      this.grid.submitFilter(filter, search);
    },
    emitFilter() {
      this.filterPanel.submitFilter();
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.filterPanel.submitFilter();
    },
    actionUpdate() {
      this.grid.getRecords(true);
      if (this.$refs["right-panel"]) {
        this.$refs["right-panel"].update();
      }
    },
    selectedHandler(val) {
      this.selectedMultipleGridHandler(val)
    },
    showChangeModal() {
      this.isEdit = false;
      this.isCreate = true;
      this.modalModel = this.selectedNode
        ? {
            systemDirectoryId: this.selectedNode.id,
            systemDirectoryName: this.selectedNode.name,
            systemDirectoryCode: this.selectedNode.code,
            icon: "note-sticky",
          }
        : {
            icon: "note-sticky",
          };
      this.showModal = true;
    },
    editItem(model) {
      if (!model) return;
      this.isEdit = true;
      this.isCreate = false;
      this.modalModel = _.cloneDeep(model);
      this.showModal = true;
    },
    changeItem() {
      let method;
      if (this.isCreate) {
        method = "create";
      }
      if (this.isEdit) {
        method = "update";
      }
      this.basetypeService[method](this.modalModel).then(() => {
        this.actionUpdate();
      });
    },
    deleteItem(model) {
      if (!model) return;
      this.deletingModel = model;
      this.showDeleteModal = true;
    },
    applyDelete() {
      this.basetypeService.delete(this.deletingModel).then(() => {
        this.actionUpdate();
        this.selectedMultipleClear()
        this.deletingModel = null;
      });
    },
    getStatus(status) {
      if (!status) return;
      let type = "default";
      return {
        text: status[0].toUpperCase() + status.toLowerCase().slice(1),
        type,
      };
    },
    handleDots(action, item) {
      var option = this.dotsOptions.find((o) => o.name === action);
      option.method(item);
    },
    nodeSelect(value) {
      if (this.selectedNode && this.selectedNode.key === value.key) return;
      this.selectedNode = _.cloneDeep(value);
      this.selectedMultipleClear()
      this.filterPanel.filter["systemDirectoryId"] = value.id === 0 
      ? { and: [] } 
      : { and: [value.id], };
      
      this.filterPanel.submitFilter();
    },
  },
}
</script>
<style lang="scss">
@import "../../assets/scss/main.scss";
.basetype-tree {
  .radio-tabs {
    margin-top: 1px;
    .radio-tab:nth-child(2) {
      display: none !important;
    }
    &-body {
      margin: 10px;
    }
  }

  &__left-panel {
    border: var(--prospace-ui-border);
  }
}
</style>
