import { Transformer } from "../../../Transformer/index";
import Api from "../../../Api/HistoryApi";
import i18n from '../../Common/utils/i18n.js'

export class HistoryService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
    this.modelName = "";
    this.masterModelInfo = {};
    this.modelInfo = {};
  }
  async grid(filter) {
    const res = await this.transformer.getGrid(this.Api, "get", {
      filter,
      modelName: this.modelName,
    });
    res.data.records = res.data.records.map(i => {
      const localKey = `${i.operationType.toLowerCase()}Operation`
      i.operationType = i18n.global.t(localKey)
      return i
    })
    return res
  }

  // описание ModelInfo смотерть в HistoryModelinfo.cs
  async loadModelInfo() {
    if (
      !this.isObjectEmpty(this.masterModelInfo) &&
      this.masterModelInfo.modelName === this.modelName
    ) {
      this.modelInfo = { ...this.masterModelInfo };
      return this.modelInfo;
    }

    this.modelInfo = this.tryFindLocal(this.masterModelInfo);
    if (
      !this.isObjectEmpty(this.modelInfo) &&
      this.modelInfo.modelName === this.modelName
    )
      return this.modelInfo;

    this.masterModelInfo = (
      await this.transformer.getItem(this.Api, "getModelInfo", {
        modelName: this.modelName,
      })
    ).data;
    this.modelInfo = { ...this.masterModelInfo };
    return this.masterModelInfo;
  }
  tryFindLocal(masterModel) {
    if (
      !this.isObjectEmpty(masterModel) &&
      !this.isObjectEmpty(masterModel.referenceFrom)
    ) {
      for (const r of masterModel.referenceFrom) {
        if (r.modelName === this.modelName) return { ...r };
        if (!this.isObjectEmpty(r.referenceFrom)) return this.tryFindLocal(r);
      }
    }
    return {};
  }
  isObjectEmpty(obj) {
    return obj === null || obj === undefined || Object.keys(obj).length === 0;
  }
  referenceFieldConfs() {
    const arr = this.modelInfo.references;
    if (arr === null || arr === undefined || arr.length === 0) return {};
    let result = {};
    for (const r of arr) {
      for (const fieldKey in r.properties) {
        let { isKey, referenceName, type } = r.properties[fieldKey];
        result[referenceName] = {
          originalName: fieldKey,
          isKey,
          type,
          modelName: r.modelName,
        };
      }
    }
    return result;
  }
}
