<template>
  <ProSpaceModal
    v-model="showModal"
    :title="$t('downloadLog')"
    width="450"
    applyTxt=""
    :cancelTxt="$t('cancelInf')"
  >
    <ProSpaceVLayout>
      <ProSpaceHLayout>
        <div>
          {{ $t("downloadLog") }}
        </div>
        <!-- <ProSpaceAction :text="task.logFile" /> -->
      </ProSpaceHLayout>
    </ProSpaceVLayout>
  </ProSpaceModal>
</template>

<script>
import {
  ProSpaceModal,
  ProSpaceVLayout,
  ProSpaceHLayout,
  ProSpaceAction,
} from "prospace-components-library";
export default {
  components: {
    ProSpaceModal,
    ProSpaceVLayout,
    ProSpaceHLayout,
    ProSpaceAction,
  },
  data() {
    return {
      showModal: false,
      task: null,
    };
  },
  methods: {
    show(task) {
      this.task = task;
      this.showModal = true;
    },
  },
};
</script>

<style></style>
