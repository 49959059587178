import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/integration/integrationlog/";

export default class IntegrationLogApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getIntegrationLogs(params) {
    return await request.post(`${rootRoute}getIntegrationLogs`, {
      filter: params,
      parameters: {},
    });
  }
  async getIntegrationLog(id) {
    return await request.post(`${rootRoute}getIntegrationLogs`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createIntegrationLog(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createIntegrationLog`, { data });
  }
  async deleteIntegrationLog(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteIntegrationLog`, { data });
  }
  async editIntegrationLog(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateIntegrationLog`, { data });
  }
}
