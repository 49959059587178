import notifications from "../../../../Services/Notification/ProSpace.Notification.Frontend/views/Notification/Notifications";
import notification from "../../../../Services/Notification/ProSpace.Notification.Frontend/views/Notification/Notification";
import notificationlogs from "../../../../Services/Notification/ProSpace.Notification.Frontend/views/NotificationLogs/NotificationLogs";
import notificationlog from "../../../../Services/Notification/ProSpace.Notification.Frontend/views/NotificationLogs/NotificationLog";

export const notificationRoutes = [
  {
    path: "/notifications/notifications",
    name: "notifications",
    component: notifications,
  },
  {
    path: "/notifications/notification/:id",
    name: "notification",
    component: notification,
    meta: {
      menuitem: "/notifications/notifications",
    },
  },
  {
    path: "/notifications/notificationlogs",
    name: "notificationlogs",
    component: notificationlogs,
  },
  {
    path: "/notifications/notificationlog/:id",
    name: "notificationlog",
    component: notificationlog,
    meta: {
      menuitem: "/notifications/notificationlogs",
    },
  },
];

export default notificationRoutes;
