<template>
  <ProSpaceRightPanel
    v-if="id"
    :header="header"
    :loading="loading"
    :has-error="hasError"
    no-top-border
    @editTrigger="actionHandler('editTrigger')"
    @taskResult="actionHandler('taskResult')"
    @taskLog="actionHandler('taskLog')"
    @deleteTrigger="actionHandler('deleteTrigger')"
    @pauseTrigger="actionHandler('pauseTrigger')"
  >
    <template v-slot:center>
      <ProSpaceTabs :tabs="tabs" withoutMarginBottom class="system-trigger-panel__tabs" @select-tab="handlerSelectTab">
        <template v-slot:tab-1>
          <ProSpacePanelBlock :header="headerRepeat">
            <template #header>
              <ProSpaceAction :text="$t('editItemTxt')" @click="onClickEdit"/>
            </template>
            <ProSpacePanelBlockList :list="repeatContent" />
          </ProSpacePanelBlock>
        </template>
        <template v-slot:tab-2>
          <ProSpacePanelBlock :header="headerParameters">
            <template #header>
              <ProSpaceAction :text="$t('editItemTxt')" @click="onClickEdit"/>
            </template>
            <ProSpacePanelBlockList :list="parametersContent" />
          </ProSpacePanelBlock>
        </template>
      </ProSpaceTabs>
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceAction
} from "prospace-components-library"

import { JobTriggerService } from "../../../services/JobTriggerService.js";
import { mapGetters } from "vuex";
import {
  useStatistic
} from "@composes"
export default {
  name: "SystemTriggerPanel",
  components: {
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceAction
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    options: {
      type: Array,
      required: true
    }
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    return {
      setStatistic, distActions
    }
  },
  data() {
    return {
      trigger: null,
      jobTriggerService: new JobTriggerService(),
      loading: false,
      hasError: false
    }
  },
  computed: {
    ...mapGetters({
      getFormatDate: "userinfoStore/getFormatDate"
    }),
    headerRepeat() {
      return {
        title: this.$t("repeatOptions"),
        isExpand: false
      }
    },
    headerParameters() {
      return {
        title: this.$t("parameters"),
        isExpand: false
      }
    },
    header() {
      if (this.trigger) {
        return {
          title: this.trigger.name,
          id: this.id,
          status: this.getStatus(this.trigger.status),
          options: this.options,
          showCounter: false
        }
      }
      return {}
    },
    repeatContent() {
      if (this.trigger) {
        let options = JSON.parse(this.trigger.triggerSettings);
        return [
          [
            {
              title: this.$t('repeat'),
              text: this.getRepeat(options.TriggerPeriodic)
            }
          ], [
            {
              title: this.$t('every'),
              text: options.Every
            },
          ], [
            {
              title: this.$t('time'),
              text: options.PeriodicTime
            },
          ], [
            {
              title: this.$t('onTheDays'),
              text: this.getDays(options.DaysOfWeek)
            },
          ], [
            {
              title: this.$t('startRepeat'),
              text: this.getFormatDate(options.StartAt)
            },
          ], [
            {
              title: this.$t('endRepeat'),
              text: this.getFormatDate(options.EndAt)
            }
          ],
        ]
      }
      return []
    },
    parametersContent() {
      if (this.trigger) {
        return []
      }
      return []
    },
    tabs() {
      return [
        { name: this.$t("scheduling"), isActive: true, systemName: "scheduling" },
        { name: this.$t("parameters"), systemName: "parameters" },
      ]
    }
  },
  mounted() {
    this.update()
  },
  methods: {
    handlerSelectTab({ selectedTab }) {
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: selectedTab.systemName
      })
    },
    onClickEdit() {
      this.$emit("editClick", this.trigger)
    },
    update() {
      this.hasError = false
      try {
        this.getTrigger()
      } catch (e) {
        this.hasError = true
      }
    },
    getTrigger() {
      if (this.id) {
        this.loading = true;
        this.jobTriggerService.get(this.id).then((res) => {
          this.trigger = res.data[0]
          this.loading = false;
        })
      } else {
        this.trigger = null
      }
    },
    actionHandler(action) {
      this.$emit('action', action, this.trigger);
    },
    getDays(daysOfWeek) {
      const days = {
        0x40: this.$t('sun'),
        0x01: this.$t('mon'),
        0x02: this.$t('tue'),
        0x04: this.$t('wed'),
        0x08: this.$t('thu'),
        0x10: this.$t('fri'),
        0x20: this.$t('sat')
      };

      return daysOfWeek.map(d => days[d]).join(", ")
    },
    getRepeat(triggerPeriodic) {
      const options = [
        this.$t('once'),
        this.$t('second'),
        this.$t('minute'),
        this.$t('hour'),
        this.$t('day'),
        this.$t('week'),
        this.$t('month')
      ];

      return options[triggerPeriodic]
    },
    getStatus(status) {
      if (!status) return;
      let type = "default";
      switch (status.toLowerCase()) {
        case "complete":
          type = "success";
          break;
        case "process":
          type = "warning";
          break;
        case "error":
          type = "danger";
          break;
        default:
          type = "default";
      }
      return {
        text: status[0].toUpperCase() + status.toLowerCase().slice(1),
        type,
      };
    }
  },
  watch: {
    id() {
      this.update()
    }
  }
}
</script>

<style lang="scss">
.system-trigger-panel__tabs {
  row-gap: 10px !important;
}
</style>
