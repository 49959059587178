import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/UseCaseRoleApi";

export class UseCaseRoleService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
    this.item = null
  }
  setItem(item) {
    this.item = item
  }
  async grid(args, id) {
    return await this.transformer.getGrid(this.Api, "getUseCasesGrid", args, id);
  }
  async get(args) {
    return await this.transformer.getGrid(this.Api, "getUseCase", args);
  }
  async create(models) {
    const createModels = models.map(m => {
      return {
        securityRoleDisplayName: this.item.displayName,
        securityRoleId: Number(this.item.masterId),
        securityRoleSystemName: this.item.systemName,
        securityUseCaseDescription: m.description,
        securityUseCaseId: m.id,
        securityUseCaseTitle: m.title
      }
    })
    return await this.transformer.createItem(this.Api, "createUseCases", createModels)
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "updateUseCase", model)
  }
  async delete(models) {
    return await this.transformer.deleteItem(this.Api, "deleteUseCase", models)
  }
}
