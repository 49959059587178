import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/NewsService/NewsItemAttachmentApi";

export class NewsItemAttachmentService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(this.Api, "editNewsItemAttachment", fields);
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editNewsItemAttachment");
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getNewsItemAttachments", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getNewsItemAttachment", null, id);
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createNewsItemAttachment", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteNewsItemAttachment", model);
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "editNewsItemAttachment", model);
  }
  async acceptFiles(gfids, newsItemId) {
    return this.setStatus(gfids, newsItemId, 0);
  }
  async errorFiles(gfids, newsItemId) {
    return await this.setStatus(gfids, newsItemId, 255);
  }
  async setStatus(gfids, newsItemId, status) {
    var models = gfids.map(x => {
      return {
        id: 0,
        fileName: "",
        gfid: x,
        newsItemId: newsItemId,
        statusFlag: status,
        disabled: false
      }
    })
    return await this.transformer.editItem(this.Api, "setStatusNewsItemAttachment", models);
  }
}
