import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/UseCasesApi";

export class UseCasesService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getUseCases", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(
      this.Api,
      "getUseCase",
      null,
      id
    );
  }
  async set() {
    return await this.transformer.setForm(this.Api, "updateUseCase");
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(
      this.Api,
      "updateUseCase",
      fields
    );
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createUseCase", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteUseCase", model);
  }
  async edit(model) {
    return await this.transformer.editItem(this.Api, "updateUseCase", model);
  }
}
