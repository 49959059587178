import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/MenuItemUserApi";

export class MenuItemUserService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async grid(filter, id) {
    return await this.transformer.getGrid(this.Api, "grid", { filter, id });
  }
}
