import request from "../Frontends/Common/utils/request/requestMain";
const rootRoute = "securityadmin/securityclaim/";

export default class ClaimApi {
  async getClaims(params) {
    return await request.post(`${rootRoute}getsecurityclaims`, {
      filter: params,
    });
  }
  async getClaim(id) {
    return await request.post(`${rootRoute}getsecurityclaims`, {
      filter: `$filter=id eq ${id}`,
      parameters: {},
    });
  }
}
