<template>
  <ProSpaceRightPanel
    :header="header"
    :loading="loading"
    :has-error="hasError"
    @edit="editPanel"
    @delete="deletePanel"
  >
    <template v-slot:center>
      <ProSpacePanelBlock :header="headerOverview">
        <template v-slot:header> </template>
        <template v-slot>
          <ProSpacePanelBlockList :list="content" />
        </template>
      </ProSpacePanelBlock>
    </template>
    <template v-slot:bottom>
      <ProSpacePanelBlock :header="headerBottom">
        <template v-slot>
          <ProSpacePanelBlockList :list="contentBottom" />
        </template>
      </ProSpacePanelBlock>
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
} from "prospace-components-library";
import { getAMorPM } from "prospace-components-library/src/lib-assets/mixins/date";
import { UserService } from "../../../../../SecurityAdmin/ProSpace.SecurityAdmin.Frontend/services/UserService";
import { MailingGroupService } from "../../../services/MailingGroupService";
import { mapGetters } from "vuex";
import {
  useMultiSelectRightPanel,
} from "@composes/multiselect"
export default {
  name: "MarsDistributorOrgUsersViewPanel",
  components: {
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
  },
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    filterAccessDots: {
      type: Function,
    },
    checkAccess: {
      type: Function,
    }
  },
  data() {
    return {
      masterRecord: null,

      userService: new UserService(),
      mailingGroupService: new MailingGroupService(),

      loading: false,
      hasError: false,
    };
  },
  setup() {
    const { selectedData } = useMultiSelectRightPanel()
    return {
      selectedData
    }
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime",
    }),
    headerOverview() {
      return {
        title: this.$t("detailTxt"),
        isExpand: true,
      };
    },
    headerBottom() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    tabs() {
      return [{ name: this.$t("overview"), isActive: true }];
    },
    header() {
      let title = this.selectedData.lastName
        ? `${this.selectedData.firstName} ${this.selectedData.lastName}`
        : this.selectedData.firstName;
      return {
        title: title,
        id: this.selectedData.id,
        status: this.getStatus(this.selectedData),
        options:
          this.selectedData.recordType == "MailingGroup"
            ? this.getAccessDots(this.options)
            : [],
        showCounter: false,
      };
    },
    content() {
      if (!this.masterRecord) return [];

      let marsInfo = [
        [
          {
            title: this.$t("position"),
            text:
              this.masterRecord.orgLinkMarsOrgName ||
              this.masterRecord.marsOrgName,
          },
        ],
      ];
      let distrInfo = [
        [
          {
            title: this.$t("distributor"),
            text:
              this.masterRecord.orgLinkDistributorName ||
              this.masterRecord.distributorName,
          },
        ],
        [
          {
            title: this.$t("position"),
            text:
              this.masterRecord.orgLinkDistributorOrgName ||
              this.masterRecord.distributorOrgName,
          },
        ],
      ];
      let emailInfo = [
        [
          {
            title: this.$t("email"),
            text: this.masterRecord.email,
          },
        ],
      ];
      let commentInfo = [
        [
          {
            title: this.$t("comments"),
            text: this.masterRecord.comments,
            isFullText: true,
          },
        ],
      ];
      if (this.selectedData.type === "Mars") return marsInfo.concat(emailInfo, commentInfo);

      return distrInfo.concat(emailInfo);
    },
    contentBottom() {
      let metaInfo = [];
      if (this.masterRecord) {
        if(this.checkAccess("metainfo-by-block"))
          metaInfo.push([
            {
              title: this.$t("createdByTxt"),
              text: this.masterRecord.createdBy,
            },
            {
              title: this.$t("updatedByTxt"),
              text: this.masterRecord.updatedBy,
            },
          ]);

        metaInfo.push([
          {
            title: this.$t("createdAtTxt"),
            text: this.getFormatDateTime(this.masterRecord.createdAt) + ' ' + getAMorPM(this.masterRecord.createdAt, this.userinfo.timeFormat),
          },
          {
            title: this.$t("lastUpdatedTxt"),
            text: this.getFormatDateTime(this.masterRecord.lastUpdated) + ' ' + getAMorPM(this.masterRecord.lastUpdated, this.userinfo.timeFormat),
          },
        ]);
      }
      return metaInfo;
    },
  },
  mounted() {
    this.update();
  },
  methods: {
    editPanel() {
      this.$emit("edit-item", this.masterRecord);
    },
    deletePanel() {
      this.$emit("delete-item", this.masterRecord);
    },
    getAccessDots(options) {
      return this.filterAccessDots
        ? this.filterAccessDots(options, this.masterRecord)
        : options;
    },
    update() {
      this.hasError = false;
      try {
        this.getMarsDistributorOrgUsersView();
      } catch (e) {
        this.hasError = true;
      }
    },
    getMarsDistributorOrgUsersView() {
      let service = this.userService;
      if (this.selectedData.recordType === "MailingGroup")
        service = this.mailingGroupService;

      this.loading = true;
      service.get(this.selectedData.id).then((res) => {
        this.masterRecord = res.data;
        this.loading = false;
      });
    },
    getStatus(record) {
      let type = "default";
      return {
        text: this.$t(record.recordType),
        type,
      };
    },
  },
  watch: {
    selectedData() {
      this.update();
    },
  },
};
</script>

<style scoped></style>
