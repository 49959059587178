import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/TaskTypeApi";

export class TaskTypeService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async get() {
    let result = await this.transformer.getGrid(this.Api, "get", {});
    return {
      data: {
        records: result.data.records.map(r => { return { label: r, value: r } })
      }
    };
  }
}
