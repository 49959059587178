<template>
  <BaseLayout>
    <router-view />
  </BaseLayout>
</template>
<script>
import BaseLayout from "../../../Frontends/ProSpace/components/BaseLayout.vue";
export default {
  components: {
    BaseLayout,
  },
};
</script>
