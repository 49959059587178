import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/ReserveService/ReserveApi";

export class ReserveService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getReserves", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getReserve", null, id);
  }
  async getShipTo(filter) {
    return await this.transformer.getGridPanel(this.Api, "getShipTo", filter);
  }
  async getShipToFilter(filter) {
    return await this.transformer.getGrid(this.Api, "getShipTo", filter);
  }
  async getShipToTree(args) {
    let result = await this.transformer.getTree(this.Api, "getShipTo", args);
    const data = result.data.map((i) => {
      i.leaf = true;
      return i;
    });
    return Promise.resolve({ data });
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createReserve", model);
  }
}