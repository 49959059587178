import { inject } from 'vue';

const useMultiSelectModalBlockFilters = () => {
    const model = inject('MultiSelectModalBlockFiltersModel')
    const onApply = inject('MultiSelectModalBlockFiltersApply')
    const onCancel = inject('MultiSelectModalBlockFiltersCancel')
    return {
        model,
        onApply,
        onCancel,
    }
}

export default useMultiSelectModalBlockFilters
