import bbdproducts from "../../../../Services/ReserveService/ReserveService.Frontend/views/BBDProducts/BBDProducts";

import reservecreation from "../../../../Services/ReserveService/ReserveService.Frontend/views/Reserves/ReserveCreation";
import reserves from "../../../../Services/ReserveService/ReserveService.Frontend/views/Reserves/Reserves";
import reserve from "../../../../Services/ReserveService/ReserveService.Frontend/views/Reserves/Reserve";

export const reserveserviceRoutes = [
  {
    path: "/reserveservice/bbdproducts",
    name: "bbdproducts",
    component: bbdproducts,
  },

  {
    path: "/reserveservice/reservecreation",
    name: "reservecreation",
    component: reservecreation,
  },
  {
    path: "/reserveservice/reserves",
    name: "reserves",
    component: reserves,
  },
  {
    path: "/reserveservice/reserve/:id",
    name: "reserve",
    component: reserve,
    meta: {
      menuitem: "/reserveservice/reserves",
    },
  }
];

export default reserveserviceRoutes;
