import store from "../../Common/store";
import Api from "../../../Api/StatisticApi"

const useStatistic = () => {
    const tempStr = "${0}"
    const distActions = {
        menuitem: `menu-${tempStr}-click`,
        tab: `tab-${tempStr}-click`,
        form: "form-click"
    }
    const api = new Api()
    const setStatistic = ({actionTemp, actionStr, requestUrl}) => {
        if (!actionTemp) {
            throw new Error("Action can`t be undefined")
        }
        if (actionTemp.includes(tempStr) && !actionStr) {
            throw new Error("Need string for template action")
        }

        const operation = actionStr ? actionTemp.replace(tempStr, actionStr.toLowerCase()) : actionTemp;
        const user = store.getters["userinfoStore/getUserinfo"];
        const role = JSON.parse(localStorage.getItem("userRole"));
        const parameters = JSON.stringify({ operation })
        const statistic = [{
            timeStamp: new Date(),
            userId: user.id,
            role: role.systemName,
            requestType: "User action",
            requestUrl: requestUrl || window.location.href,
            parameters: parameters
        }]
        api.createStatisticRaw(statistic)
    }
    return {
        setStatistic,
        distActions
    }
}

export default useStatistic