import request from "../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "securityadmin/securityrole/";

export default class RoleApi {
  async getRoles(params) {
    return await request.post(`${rootRoute}getsecurityroles`, {
      filter: params,
      parameters: {},
    });
  }
  async getRole(id) {
    return await request.post(`${rootRoute}getsecurityroles`, {
      filter: `$filter=id eq ${id}`,
      parameters: {},
    });
  }
  async createRole(model) {
    const data = [_.merge(this.defaultModel, model)];
    return await request.post(`${rootRoute}createsecurityrole`, { data });
  }
  async deleteRole(model) {
    const data = [model];
    return await request.post(`${rootRoute}deletesecurityrole`, { data });
  }
  async updateRole(model) {
    const data = [_.merge(this.defaultModel, model)];
    return await request.post(`${rootRoute}updatesecurityrole`, { data });
  }
  async import(gfid) {
    return await request.post(`${rootRoute}importsecurityrole`, {
      gfid: gfid
    });
  }
  async getImportScaffold() {
    return await request.post(`${rootRoute}getsecurityroleimporttemplate`);
  }
  async export(filter, format) {
    return await request.post(`${rootRoute}exportsecurityrole`, {
      filter: filter,
      format: format
    });
  }
}
