import basetypes from "../../../../Services/BaseType/BaseTypeService.Frontend/views/BaseTypes/BaseTypes";
import systemdirectories from "../../../../Services/BaseType/BaseTypeService.Frontend/views/SystemDirectories/SystemDirectories";
import basetype from "../../../../Services/BaseType/BaseTypeService.Frontend/views/BaseTypes/BaseType";
import systemdirectory from "../../../../Services/BaseType/BaseTypeService.Frontend/views/SystemDirectories/SystemDirectory";

export const basetypeRoutes = [
  {
    path: "/basetype/basetypes",
    name: "basetypes",
    component: basetypes,
  },
  {
    path: "/basetype/systemdirectories",
    name: "systemdirectories",
    component: systemdirectories,
  },
  {
    path: "/basetype/basetype/:id",
    name: "basetype",
    component: basetype,
    meta: {
      menuitem: "/basetype/basetypes",
    },
  },
  {
    path: "/basetype/systemdirectory/:id",
    name: "systemdirectory",
    component: systemdirectory,
    meta: {
      menuitem: "/basetype/systemdirectories",
    },
  },
];

export default basetypeRoutes;
