export class StatusService {
  constructor() {
    this.dataStatuses = [
      { text: "Active", status: "ACTIVE" },
      { text: "Draft", status: "DRAFT" },
      { text: "Inactive", status: "INACTIVE" },
    ];
  }
  search(value) {
    return this.dataStatuses.filter((elem) => elem.text.indexOf(value) !== -1);
  }
  async get() {
    return Promise.resolve({ data: { records: this.dataStatuses } });
  }
}
