<template>
  <ProSpaceGeoLayout class="restriction">
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpaceFilterPanel :closeTxt="$t('closeTxt')" :searchPlaceholder="$t('searchTxt')" :txtResetFilters="$t('txtResetFilters')" />
        </template>
        <template #center>
          <ProSpaceMainGrid
            ref="restrictions"
            type="expand"
            :id="id"
            :key="id"
            :service="restrictionService"
            :fnSetRecords="fnSetRestrictionsRecords"
            expandProp="fields"
            :needCheckDataLoaded="false"
            :isHeadHide="true"
            dataKey="name"
            getMethod="getRestrictions"
            @selected-change="changeActiveModelRestriction"
            @expand-change="changeActiveModelRestriction"
            style="height: 100%"
          >
            <template #columns>
              <Column style="min-width: 78%">
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    v-if="field.data"
                    :status="getStatus(getApiName(field.data.typeModel))"
                    :title="field.data.name"
                    :description="`${field.data.description || ''}`"
                  />
                </template>
              </Column>
              <Column>
                <template #body="field">
                  <ProSpaceHLayout justify-content="flex-end">
                    <ProSpaceIconButton
                      icon="circle-close"
                      @click.stop="removeRestriction(field)"
                    />
                  </ProSpaceHLayout>
                </template>
              </Column>
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                style="heigth: calc(100% - 50px)"
                icon="empty-grid"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
                :need-btn="true"
                @button-event="showModal"
              />
            </template>
            <template #expansion>
              <Column style="min-width: 70%">
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    v-if="field.data"
                    :status="getStatus(field.data.type)"
                    :title="field.data.name"
                    :description="`${field.data.description || ''}`"
                  />
                </template>
              </Column>
              <Column>
                <template #body="field">
                  <ProSpaceHLayout justify-content="flex-end">
                    <ProSpaceIconButton
                      icon="circle-close"
                      @click.stop="removeField(field.data)"
                    />
                  </ProSpaceHLayout>
                </template>
              </Column>
            </template>
          </ProSpaceMainGrid>
        </template>
      </ProSpaceGeoLayout>
    </template>
    <template #right>
      <ProSpaceRightPanel class="restriction-value" :need-padding="false">
        <template #center>
          <ProSpaceBlock :height="40" :margin="0">
            <ProSpaceHLayout justify-content="space-between">
              <ProSpaceLabel
                :text="
                  activeModelRestriction === null
                    ? $t('fieldValue')
                    : activeModelRestriction.name
                "
                color="gray"
              />
              <ProSpaceAction
                :text="$t('addValue')"
                @click="addValueRestriction"
                :disabled="activeModelRestriction === null"
              />
            </ProSpaceHLayout>
          </ProSpaceBlock>
          <ProSpaceDivider />
          <ProSpaceBlock
            height="calc(100% - 40px)"
            color="gray"
            :shadow="false"
            :margin="0"
            :padding="5"
          >
            <ProSpaceVLayout :gap="10" v-if="activeModelRestriction">
              <ProSpaceBlock
                v-for="(item, i) in activeModelRestriction.values"
                :key="i"
                :margin="0"
                :padding="5"
              >
                <ProSpaceHLayout justify-content="space-between">
                  <template
                    v-if="
                      activeModelRestriction.type === 'Int64' ||
                      activeModelRestriction.type === 'Int32' ||
                      activeModelRestriction.type === 'Double'
                    "
                  >
                    <ProSpaceInputNumber v-model="item.value" />
                  </template>
                  <template
                    v-if="
                      activeModelRestriction.type === 'String' ||
                      activeModelRestriction.type === null
                    "
                  >
                    <ProSpaceInputText type="text" v-model="item.value" />
                  </template>
                  <template
                    v-if="
                      activeModelRestriction.type === 'DateTime' ||
                      activeModelRestriction.type === 'DateTimeOffset'
                    "
                  >
                    <ProSpaceInputCalendar
                      :dateFormat="userinfo.dateFormat"
                      v-model="item.value"
                    />
                  </template>
                  <template v-if="activeModelRestriction.type === 'Boolean'">
                    <ProSpaceInputSwitch v-model="item.value" />
                  </template>
                  <ProSpaceIconButton
                    icon="circle-close"
                    @click="removeValue(i)"
                  />
                </ProSpaceHLayout>
              </ProSpaceBlock>
            </ProSpaceVLayout>
          </ProSpaceBlock>
        </template>
      </ProSpaceRightPanel>
    </template>
  </ProSpaceGeoLayout>
  <ProSpaceModal
    v-model="modal"
    class="methadata"
    :title="`${$t('add')} ${$t('field')}`"
    :icon="icon"
    :applyTxt="$t('applyTxt')"
    :cancelTxt="$t('cancelInf')"
    @apply="onApplyMethadata"
    @cancel="onCancelMethada"
    @show="onShowMethadata"
    :withoutPadding="true"
    :contentStyle="modalContentStyle"
  >
    <ProSpaceGeoLayout ref="container-methada">
      <template #top>
        <ProSpaceFilterPanel
          :closeTxt="$t('closeTxt')"
          :txtResetFilters="$t('txtResetFilters')"
          :fastFilters="fastFilters"
          :modelFastFilter="modelFastFilter"
          @update:modelFastFilter="
            (val) => {
              modelFastFilter = val;
              clear();
            }
          "
          :searchPlaceholder="$t('searchTxt')"
          :unicKeyStorage="methadataService.constructor.name + 'grid'"
          @apply="submitFilter"
          ref="filter-panel"
          filter-model="firstName"
        >
          <template #hint> {{ $t("filterBy") }}</template>
          <template #filters="{ filter }">
            <ProSpaceMultiSelect
              :localization="$t"
              v-model="filter.field"
              :service="fieldService"
              getMethod="get"
              optionLabel="label"
              optionValue="value"
              :selectAllTxt="$t('selectAllTxt')"
              :closeTxt="$t('closeTxt')"
              :placeholderValue="$t('field')"
              :applyTxt="$t('applyTxt')"
              :clearFilterTxt="$t('clearFilterTxt')"
              :searchPlaceholder="$t('searchTxt')"
              @apply="emitFilter"
            />
            <ProSpaceCustomFilterPanel
              :customTxt="$t('custom')"
              :customFilterTxt="$t('customFilterTxt')"
              :clearFilterTxt="$t('clearFilterTxt')"
              :selectAllTxt="$t('selectAllTxt')"
              :closeTxt="$t('closeTxt')"
              :applyTxt="$t('applyTxt')"
              :fromTxt="$t('fromTxt')"
              :toTxt="$t('toTxt')"
              :services="customServices"
              :unicKeyStorage="methadataService.constructor.name + 'grid'"
              @apply="(model) => customFilterApply(filter, model)"
            />
          </template>
        </ProSpaceFilterPanel>
      </template>
      <template #center>
        <ProSpaceMainGrid
          ref="methadata-grid"
          type="expand"
          expandProp="fields"
          :id="id"
          :key="id"
          :service="methadataService"
          :fnSetRecords="fnSetMethadataRecords"
          :needCheckDataLoaded="false"
          @selected-change="selectedHandlerMethadata"
          :noMountedRequest="true"
          @expand-change="selectedHandlerMethadata"
          dataKey="name"
          getMethod="getGrid"
        >
          <template #columns>
            <Column header="Name" sortable style="min-width: 40%">
              <template #body="field">
                <ProSpaceTableHeaderCell
                  v-if="field.data"
                  :status="getStatus(getApiName(field.data.typeModel))"
                  :title="field.data.name"
                  :description="`${field.data.description || ''}`"
                />
              </template>
            </Column>
            <Column header="System name" sortable>
              <template #body="field">
                <ProSpaceDefaultTextColumn>
                  {{ field.data.name }}
                </ProSpaceDefaultTextColumn>
              </template>
            </Column>
            <Column header="Parent model" sortable>
              <template #body="field">
                <ProSpaceDefaultTextColumn>
                  {{ field.data.parentModelName }}
                </ProSpaceDefaultTextColumn>
              </template>
            </Column>
          </template>
          <template #empty>
            <ProSpaceEmptyGrid
              icon="empty-grid"
              :title="$t('noDataTitle')"
              :text="$t('noDataTxt')"
              :textBtn="$t('add')"
            />
          </template>
          <template #expansion>
            <Column style="min-width: 40%">
              <template #body="field">
                <ProSpaceTableHeaderCell
                  v-if="field.data"
                  :status="getStatus(field.data.type)"
                  :title="field.data.name"
                  :description="`${field.data.description || ''}`"
                />
              </template>
            </Column>
            <Column style="min-width: 28%">
              <template #body="field">
                <ProSpaceTextBlock
                  top="System name"
                  :bottom="field.data.name"
                />
              </template>
            </Column>
            <Column>
              <template #body="field">
                <ProSpaceTextBlock
                  top="Parent Field"
                  :bottom="`${field.data.parentModelName}.${field.data.parentModelFieldName}`"
                />
              </template>
            </Column>
          </template>
        </ProSpaceMainGrid>
      </template>
      <template #right>
        <ProSpaceRightPanel
          v-if="activeModelMethadata"
          :key="activeModelMethadata"
          :header="{ title: activeModelMethadata.name }"
        >
          <template #center>
            <ProSpacePanelBlock :header="{ title: $t('overview') }">
              <ProSpacePanelBlockList :list="rightPanelContent" />
            </ProSpacePanelBlock>
          </template>
        </ProSpaceRightPanel>
      </template>
    </ProSpaceGeoLayout>
  </ProSpaceModal>
</template>

<script>
import {
  ProSpaceGeoLayout,
  ProSpaceMainGrid,
  ProSpaceTableHeaderCell,
  ProSpaceModal,
  ProSpaceRadioButtonSecondary,
  ProSpaceRightPanel,
  ProSpacePanelBlock,
  ProSpacePanelBlockItem,
  ProSpaceVLayout,
  ProSpaceHLayout,
  ProSpaceBlock,
  ProSpaceLabel,
  ProSpaceAction,
  ProSpaceDivider,
  ProSpaceIconButton,
  ProSpaceFilterPanel,
  ProSpaceTextBlock,
  ProSpaceEmptyGrid,
  ProSpaceMultiSelect,
  ProSpaceCustomFilterPanel,
  ProSpacePanelBlockList,
  ProSpaceInputText,
  ProSpaceInputNumber,
  ProSpaceInputSwitch,
  ProSpaceInputCalendar,
  ProSpaceDefaultTextColumn,
} from "prospace-components-library";
import Column from "primevue/column";
import { FieldService } from "../../../services/FieldService.js";
import { mapGetters } from "vuex";
export default {
  name: "UserRestriction",
  components: {
    ProSpaceGeoLayout,
    ProSpaceMainGrid,
    ProSpaceTableHeaderCell,
    ProSpaceModal,
    ProSpaceRadioButtonSecondary,
    ProSpaceRightPanel,
    ProSpacePanelBlock,
    ProSpacePanelBlockItem,
    ProSpaceVLayout,
    ProSpaceHLayout,
    ProSpaceEmptyGrid,
    ProSpaceBlock,
    ProSpaceLabel,
    ProSpaceAction,
    ProSpaceDivider,
    Column,
    ProSpaceIconButton,
    ProSpaceFilterPanel,
    ProSpaceTextBlock,
    ProSpaceMultiSelect,
    ProSpaceCustomFilterPanel,
    ProSpacePanelBlockList,
    ProSpaceInputText,
    ProSpaceInputNumber,
    ProSpaceInputSwitch,
    ProSpaceInputCalendar,
    ProSpaceDefaultTextColumn,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    restrictionService: {
      type: Object,
      required: true,
    },
    methadataService: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modal: false,
      //restriction
      restriction: [],
      spKeyRestriction: "restrictions",
      activeModelRestriction: null,
      restrictionRightPanel: {
        header: {
          title: "",
        },
      },
      //methadata
      activeModelMethadata: null,
      methadataFilter: "all",
      modelRestriction: "All",
      fieldService: new FieldService(),
      tabsMethadata: [
        { name: "All" },
        { name: "Api models" },
        { name: "Domain Models" },
      ],
      modalContentStyle: {
        overflow: "hidden",
        height: "calc(95vh - 180px)",
      },
    };
  },
  methods: {
    getStatus(status) {
      if (!status) return;
      let type = "default";
      switch (status.toLowerCase()) {
        case "complete":
          type = "success";
          break;
        case "process":
          type = "warning";
          break;
        case "error":
          type = "danger";
          break;
        default:
          type = "default";
      }
      return {
        text: status[0].toUpperCase() + status.toLowerCase().slice(1),
        type,
      };
    },
    emitFilter() {
      this.$refs["filter-panel"].submitFilter();
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.$refs["filter-panel"].submitFilter();
    },
    submitFilter({ filter, search }) {
      this.$refs["methadata-grid"].submitFilter(filter, search);
    },
    getRecords() {
      this.$refs.restrictions.getRecords();
    },
    showModal() {
      this.modal = true;
    },
    getApiName(typeModel) {
      if (typeModel) {
        return typeModel.replace(/Model/, "");
      } else {
        return "None";
      }
    },
    selectedHandlerMethadata(val) {
      this.activeModelMethadata = val;
    },
    fnSetMethadataRecords(response) {
      const arr = [];
      if (!response) {
        return arr;
      }
      const data = response.data;
      const keys = {
        apiModels: "ApiModel",
        domainModels: "DomainModel",
      };
      for (let key in data) {
        if (!(key === "domainModels")) {
          continue;
        }
        const model = data[key];
        if (model) {
          const addingArr = model.map((m) => {
            m.typeModel = keys[key];
            m.fields = m.fields.map((f) => {
              f.parentModelName = m.name;
              return f;
            });
            return m;
          });
          arr.push(...addingArr);
        }
      }

      this.methadataService.setNewGridData(arr);
      return arr;
    },
    changeActiveModelRestriction({ data }) {
      if (Array.isArray(data.values)) {
        this.activeModelRestriction = val;
      } else {
        this.activeModelRestriction = null;
      }
    },
    onUpdateTabsMethadata({ name }) {
      const filterGrid = this.methadataService.filterTypeModel(name);
      this.$refs["methadata-grid"].setRecords(filterGrid);
    },
    fnSetRestrictionsRecords(response) {
      let records = response.data.records;
      this.restrictionService.setRecords(records);
      const grid = [];
      records.forEach((record) => {
        const gridRecord = grid.find((d) => d.name === record.modelName);
        if (gridRecord) {
          const field = gridRecord.fields.find(
            (f) => f.name === record.fieldName
          );
          if (field) {
            field.values.push({
              id: record.id,
              code: record.code,
              stamp: record.stamp,
              value: this.checkRestrictionValue(
                record.fieldValue,
                record.fieldType
              ),
            });
          } else {
            const item = {
              name: record.fieldName,
              type: record.fieldType,
              parentModelName: record.modelName,
              values: [
                {
                  id: record.id,
                  code: record.code,
                  stamp: record.stamp,
                  value: this.checkRestrictionValue(
                    record.fieldValue,
                    record.fieldType
                  ),
                },
              ],
            };
            gridRecord.fields.push(item);
          }
        } else {
          const item = {
            name: record.modelName,
            typeModel: record.modelType,
            parentModelName: record.parentModelName,
            objectCode: record.objectCode,
            fields: [
              {
                name: record.fieldName,
                type: record.fieldType,
                parentModelName: record.modelName,
                values: [
                  {
                    id: record.id,
                    code: record.code,
                    stamp: record.stamp,
                    value: this.checkRestrictionValue(
                      record.fieldValue,
                      record.fieldType
                    ),
                  },
                ],
              },
            ],
          };
          grid.push(item);
        }
      });
      this.restrictionService.setNewGridData(grid);
      return grid;
    },
    checkRestrictionValue(value, type) {
      if (type === "Boolean") {
        return value === "true" ? true : false;
      } else if (type === "Int64" || type === "Int32" || type === "Double") {
        return Number(value);
      } else if (type === "DateTime" || type === "DateTimeOffset") {
        return new Date(value);
      }
      return value;
    },
    onApplyMethadata() {
      const item = this.methadataService.findItem(this.activeModelMethadata);
      this.restrictionService.addToGrid(item);
      const grid = this.restrictionService.getGrid();
      this.$refs["restrictions"].setRecords(grid);
    },
    onShowMethadata() {
      this.activeModelMethadata = null;
    },
    onCancelMethadata() {},
    removeValue(i) {
      this.restrictionService.removeValue(this.activeModelRestriction, i);
    },
    removeField(field) {
      this.activeModelRestriction = null;
      this.restrictionService.removeField(field);
    },
    removeRestriction({ data }) {
      this.activeModelRestriction = null;
      this.restrictionService.removeRestriction(data);
    },
    addValueRestriction() {
      const { type } = this.activeModelRestriction;
      const addingValue = type === "Boolean" ? false : null;
      this.activeModelRestriction.values.unshift({ value: addingValue });
    },
    submitMethadataFilter() {
      const filterGrid = this.methadataService.filterTypeField(
        this.methadataFilter.or
      );
      this.$refs["methadata-grid"].setRecords(filterGrid);
    },
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
    }),
    icon() {
      return "plus";
    },
    spVisibleMethadata() {
      return Boolean(
        this.activeModelMethadata &&
          Object.keys(this.activeModelMethadata).length > 0
      );
    },
    fastFilters() {
      return [{ label: this.$t("allTxt") }];
    },
    spVisibleRestriction() {
      return Boolean(
        this.activeModelRestriction &&
          Object.keys(this.activeModelRestriction).length > 0
      );
    },
    customServices() {
      return [
        {
          type: "chips",
          model: "createdBy",
          label: this.$t("createdByTxt"),
          selected: {},
        },
        {
          type: "chips",
          model: "updatedBy",
          label: this.$t("updatedByTxt"),
          selected: {},
        },
        {
          type: "dates-range",
          model: "createdAt",
          label: this.$t("createDate"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range",
          model: "lastUpdated",
          label: this.$t("lastUpdatedTxt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
      ];
    },
    rightPanelContent() {
      if (this.activeModelMethadata) {
        const type = this.activeModelMethadata.type
          ? this.activeModelMethadata.type
          : this.activeModelMethadata.typeModel;
        const systemName = this.activeModelMethadata.parentModelFieldName
          ? this.activeModelMethadata.parentModelFieldName
          : this.activeModelMethadata.name;
        return [
          [
            {
              title: this.$t("fieldName"),
              text: this.activeModelMethadata.name,
            },
          ],
          [
            {
              title: this.$t("taskType"),
              text: type,
            },
          ],
          [
            {
              title: this.$t("systemName"),
              text: systemName,
            },
          ],
          [
            {
              title: this.$t("parentField"),
              text: this.activeModelMethadata.parentModelName,
            },
          ],
        ];
      }
      return [];
    },
  },
};
</script>

<style lang="scss">
.restriction {
  border: var(--prospace-ui-border);
  border-radius: 5px;
  .prospace-right-panel {
    border-top: none;
    padding-bottom: 0;
  }
  .prospace-geo-layout {
    &__center {
      border-radius: 5px 0 0 5px;
    }
    &__right {
      border-radius: 0 5px 5px 0;
    }
  }
}
</style>
