import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/TaskService/TaskAssignmentAttachmentsApi";

export class TaskAssignmentAttachmentsService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(this.Api, "editTaskAssignmentAttachments", fields);
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editTaskAssignmentAttachments");
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getTaskAssignmentAttachmentss", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getTaskAssignmentAttachments", null, id);
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createTaskAssignmentAttachments", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteTaskAssignmentAttachments", model);
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "editTaskAssignmentAttachments", model);
  }
  async acceptFiles(gfids, taskAssignmentId) {
    return this.setStatus(gfids, taskAssignmentId, 0);
  }
  async errorFiles(gfids, taskAssignmentId) {
    return await this.setStatus(gfids, taskAssignmentId, 255);
  }
  async setStatus(gfids, taskAssignmentId, status) {
    var models = gfids.map(x => {
      return {
        id: 0,
        fileName: "",
        gfid: x,
        taskAssignmentId: taskAssignmentId,
        statusFlag: status,
        disabled: false
      }
    })
    return await this.transformer.editItem(this.Api, "setStatusTaskAssignmentAttachments", models);
  }
}
