import request from "../Frontends/Common/utils/request/requestMain";
const rootRoute = "scheduler/jobexecutionlog/";

export default class TaskApi {
  constructor() {
    this.defaultModel = {
      id: 0
    };
  }
  async getSystemLogs(params) {
    return await request.post(`${rootRoute}getjobexecutionlogs`, {
      filter: params,
      parameters: {},
    });
  }
  async getSystemLog(id) {
    return await request.post(`${rootRoute}getjobexecutionlogs`, {
      filter: `$filter=id eq ${id}`,
      parameters: {},
    });
  }
}
