import documents from "../../../../Services/DocumentService/DocumentService.Frontend/views/Documents/Documents";
import document from "../../../../Services/DocumentService/DocumentService.Frontend/views/Documents/Document";

export const documentserviceRoutes = [
  {
    path: "/documentservice/documents",
    name: "documents",
    component: documents,
  },
  {
    path: "/documentservice/document",
    name: "document-create",
    component: document,
    meta: {
      menuitem: "/documentservice/documents",
    },
  },
  {
    path: "/documentservice/document/:id",
    name: "document",
    component: document,
    meta: {
      menuitem: "/documentservice/documents",
    },
  },
];

export default documentserviceRoutes;
