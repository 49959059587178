<template>
  <LoginLayout
    :showFooter="showFooter"
    v-model:modelMessageError="showMessageError"
    :error="error"
  >
    <div class="security-login">
      <div class="block-login">
        <div class="block-login__header">{{ $t("loginWith") }}</div>
        <div class="block-login__logins">
          <ProSpaceButton
            class="block-login__logins__item password"
            @click="onLogin('Cookies')"
            type="dark"
            :loading="loadingBtnProspace"
          >
            <template #label>
              <ProSpaceHLayout :gap="15" justify-content="space-between">
                <svg-icon
                  name="logo-mdp"
                  :width="40"
                  :height="33"
                />
                {{ $t('password') }}
              </ProSpaceHLayout>
            </template>
          </ProSpaceButton>
          <ProSpaceButton
            class="block-login__logins__item mars"
            @click="onLogin('Microsoft')"
            icon="microsoft"
            label="Марс"
            coloredIcon
            iconWidth="40px"
            iconHeight="40px"
            justify-content="flex-start"
            container-size="40px"
            icon-margin="15px"
            :loading="loadingBtnMicrosoft"
          />
        </div>
      </div>
      <div class="block-login-line" v-if="false"></div>
      <div class="block-login" v-if="false">
        <div class="block-login__header"> {{ $t('or') }} </div>
        <div class="block-login__logins">
          <ProSpaceIconButton
            class="block-login__logins__item item-small"
            icon="gmail"
            type="dark"
            coloredIcon
          />
          <ProSpaceIconButton
            class="block-login__logins__item item-small"
            icon="yandex"
            type="dark"
            coloredIcon
          />
          <ProSpaceIconButton
            class="block-login__logins__item item-small"
            icon="apple"
            type="dark"
            coloredIcon
          />
          <ProSpaceIconButton
            class="block-login__logins__item item-small"
            icon="vk"
            type="dark"
            coloredIcon
          />
          <ProSpaceIconButton
            class="block-login__logins__item item-small"
            icon="mail"
            type="dark"
            coloredIcon
          />
          <ProSpaceIconButton
            class="block-login__logins__item item-small"
            icon="gosuslugi"
            type="dark"
            coloredIcon
          />
        </div>
      </div>
    </div>
  </LoginLayout>
</template>

<script>
import {
  ProSpaceButton,
  ProSpaceAction,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceIconButton,
} from "prospace-components-library";
import LoginLayout from "./components/LoginLayout.vue";
import UserService from "../../services/UserService";
import {
  setRedirectUrl,
  getToken,
} from "../../../../../Frontends/Common/utils/auth";

export default {
  components: {
    LoginLayout,
    ProSpaceAction,
    ProSpaceButton,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceIconButton,
  },
  data() {
    return {
      redirect: null,
      nextUrl: "information/home",
      userService: new UserService(),
      showMessageError: false,
      error: null,
      loadingBtnProspace: false,
      loadingBtnMicrosoft: false,
      showFooter: false,
    };
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    this.redirect = this.nextUrl + "" || urlParams.get("redirect");
    this.getErrors();
    const token = getToken();
    if (token) {
      window.location.href = "/" + this.nextUrl;
    }
  },
  methods: {
    getErrors() {
      let errorText = document.cookie
        .split("; ")
        .find((row) => row.startsWith("ProSpaceException="))
        ?.split("=")[1];
      if (errorText && errorText.length > 0) {
        this.showFooter = true;
        this.showMessageError = true;
        this.authInfoErrorObj = JSON.parse(decodeURIComponent(errorText));
        this.error = this.authInfoErrorObj.errors[0];
      } else {
        this.showFooter = false;
      }
    },
    onLogin(loginType) {
      setRedirectUrl({
        baseUrl: location.pathname,
        redirect: this.redirect || "/",
      });
      this.setLoading(loginType);
      this.userService.authorize(loginType);
    },
    onLogout() {
      this.userService.logout();
    },
    setLoading(loginType, load=true) {
      if (loginType === "Microsoft") {
        this.loadingBtnMicrosoft = load;
        setTimeout(() => {
          this.loadingBtnMicrosoft = false;
        }, 3000);
      } else if (loginType === "Cookies") {
        this.loadingBtnProspace = load;
        setTimeout(() => {
          this.loadingBtnProspace = false;
        }, 3000);
      }
    },
  },
};
</script>

<style lang="scss" scope>
.security-login {
  .block-login {
    margin: 20px auto;
    text-align: center;
    &__header {
      font-weight: 400;
      font-size: 18px;
      line-height: 125%;
      color: var(--prospace-ui-select-light);
    }
    &__logins {
      display: flex;
      gap: 10px;
      margin: auto;
      margin-top: 20px;
      justify-content: center;
      &__item {
        display: flex;
        align-items: center;
        padding: 12px 15px;
        font-weight: 400;
        font-size: 18px;
        line-height: 125%;
        background: #525f71;
        border: 1px solid #616c7d;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        width: 175px;
        height: 60px;
        .first-action-button__label {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &.item-small {
          width: 40px;
          height: 40px;
          padding: 0;
          .img {
            width: 20px;
            height: 20px;
            margin: auto;
          }
        }
        &.mars {
          & > .prospace-h-layout {
            width: auto;
            justify-content: flex-start;
            gap: 10px !important;
          }
        }
        &.password {
          & > .prospace-h-layout {
            justify-content: space-between !important;
          }
        }
        .img {
          width: 40px;
          height: 40px;
          margin-right: 20px;
        }
        .full-img {
          width: 145px;
          height: 36px;
        }
      }
    }
    &-line {
      height: 1px;
      background: #616c7d;
      border-radius: 3px;
      width: 358px;
      margin: auto;
    }
  }
}
</style>