import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/MasterData/DistributorApi";
import store from "../../../../Frontends/Common/store";
export class DistributorService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(this.Api, "editDistributor", fields);
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editDistributor");
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getDistributors", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getDistributor", null, id);
  }
  async getByMultiple(multipleModel) {
    return await this.transformer.getGrid(this.Api, "getByMultiple", multipleModel);
  }
  async getByCode(code) {
    return await this.transformer.getGridPanel(this.Api, "getDistributorByCode", null, code);
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createDistributor", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteDistributor", model);
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "editDistributor", model);
  }
  async export(filter, format) {
    const res = this.Api.export(filter, format);
    await store.dispatch('notifications/handleResponse', {
      promiseResponse: res,
    })
  }
  async getEDOs() {
    const docTypes = [
      { label: "ЗАО «ПФ «СКБ Контур»»", value: "ЗАО «ПФ «СКБ Контур»»" },
      { label: "ООО «КОРУС Консалтинг СНГ»", value: "ООО «КОРУС Консалтинг СНГ»" },
    ]
    const data = {records: docTypes}
    return Promise.resolve( { data })
  }
}
