<template>
  <ProSpaceTabs
    v-model="recipientTabPanel"
    :tabs="recipientTabs"
    class="recipient-tree"
  >
    <template v-slot:tab-1>
      <ProSpaceTree
        :searchPlaceholder="$t('searchTxt')"
        ref="tree-to"
        :service="recipientTreeService"
        searchName="label"
        getNodeMethod="getTo"
        :searchable="needSearch"
        :noOdata="true"
        isNested
      >
        <template #action="{ node }">
          <ProSpaceIconButton
            class="recipient-tree__add-btn"
            v-if="node.children"
            icon="plus"
            type="primary"
            @click="openAddRecipientModal(node)"
          />
          <ProSpaceIconButton
            v-else
            icon="circle-close"
            @click="removeNode(node)"
          />
        </template>
      </ProSpaceTree>
    </template>

    <template v-slot:tab-2>
      <ProSpaceTree
        :searchPlaceholder="$t('searchTxt')"
        ref="tree-copy"
        :service="recipientTreeService"
        searchName="label"
        getNodeMethod="getCC"
        :searchable="needSearch"
        :noOdata="true"
      >
        <template #action="{ node }">
          <ProSpaceIconButton
            v-if="node.children"
            icon="plus"
            type="primary"
            @click="openAddRecipientModal(node)"
          />
          <ProSpaceIconButton
            v-else
            icon="circle-close"
            @click="removeNode(node)"
          />
        </template>
      </ProSpaceTree>
    </template>

    <template v-slot:tab-3>
      <ProSpaceTree
        :searchPlaceholder="$t('searchTxt')"
        ref="tree-bcc"
        :service="recipientTreeService"
        searchName="label"
        getNodeMethod="getBCC"
        :searchable="needSearch"
        :noOdata="true"
      >
        <template #action="{ node }">
          <ProSpaceIconButton
            v-if="node.children"
            icon="plus"
            type="primary"
            @click="openAddRecipientModal(node)"
          />
          <ProSpaceIconButton
            v-else
            icon="circle-close"
            @click="removeNode(node)"
          />
        </template>
      </ProSpaceTree>
    </template>
  </ProSpaceTabs>
  <ProSpaceModal
    v-model="showModal"
    :title="`${recipientNode?.label}`"
    :style="{
      width:
        recipientNode && !(recipientNode.key).includes('Custom') ? '90%' : '641px',
      oveflow: 'hidden',
    }"
    @apply="changeItem"
    class="recipient-modal"
    :disabledApply="
      v$.customValue.$invalid &&
      recipientNode &&
      (recipientNode.key).includes('Custom')
    "
    :cancelTxt="$t('cancelInf')"
    :applyTxt="$t('applyTxt')"
  >
    <NotificationUsers
      v-if="(recipientNode.key).includes('Users')"
      ref="notificationUsers"
    />
    <NotificationRoles
      v-else-if="(recipientNode.key).includes('Roles')"
      ref="notificationRoles"
    />
    <div v-else style="margin: 10px; padding-bottom: 80px">
      <ProSpaceVLayout :gap="30">
        <ProSpaceInputText
          :label="$t('customContacts')"
          v-model="customValue"
          placeholder=""
        ></ProSpaceInputText>
        <ProSpaceSelectButton
          :label="$t('notificationType')"
          :items="notificationTargets"
          v-model="selectedNotifyTarget"
          :disabled="selectedNotifyTarget.length <= 1"
          multiple
        />
      </ProSpaceVLayout>
    </div>
  </ProSpaceModal>
</template>
<script>
import {
  ProSpaceInputText,
  ProSpaceTabs,
  ProSpaceModal,
  ProSpaceTree,
  ProSpaceIconButton,
  ProSpaceSelectButton,
  ProSpaceVLayout,
} from "prospace-components-library";
import NotificationUsers from "./NotificationUsers.vue";
import NotificationRoles from "./NotificationRoles.vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import i18n from "../../../../../Frontends/Common/utils/i18n";
export default {
  components: {
    NotificationUsers,
    NotificationRoles,
    ProSpaceInputText,
    ProSpaceTabs,
    ProSpaceModal,
    ProSpaceTree,
    ProSpaceIconButton,
    ProSpaceSelectButton,
    ProSpaceVLayout,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      customValue: { required },
    };
  },
  data() {
    return {
      showModal: false,
      recipientTabPanel: { name: this.$t("to") },

      recipientNode: null,
      customValue: null,

      //help data
      notificationTargets: [
        { value: "Mail", label: "E-mail", icon: "email" },
        // { value: "SMS", label: "SMS", icon: "sms" },
        // { value: "Telegram", label: "Telegram", icon: "circle-telegram" },
      ],
      selectedNotifyTarget: ["Mail"],
    };
  },
  props: {
    recipientFilter: {
      type: Object,
      required: true,
    },
    recipientTreeService: {
      type: Object,
      required: true,
    },
    needSearch: {
      type: Boolean,
      default: () => true,
    },
  },
  watch: {
    recipientTreeService: {
      handler: function(val) {
        this.translateLabels(val);
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    if (this.recipientTreeService.data === null)
      this.recipientTreeService.set(this.recipientFilter);
  },
  methods: {
    refresh() {
      if(this.recipientTabPanel.name === this.$t("to"))
        this.$refs["tree-to"].getData();

      if(this.recipientTabPanel.name === this.$t("copy"))
      this.$refs["tree-copy"].getData();

      if(this.recipientTabPanel.name === this.$t("BCC"))
      this.$refs["tree-bcc"].getData();
    },
    changeTarget(target) {
      if (
        Object.values({ ...this.selectedNotifyTarget }).includes(target.value)
      )
        this.selectedNotifyTarget = this.selectedNotifyTarget.filter(
          (e) => e !== target.value
        );
      else this.selectedNotifyTarget.push(target.value);
    },
    openAddRecipientModal(node) {
      let parsedKey = node.key.split("-");
      this.recipientNode = this.recipientTreeService.tree[parsedKey[0]].find(
        (e) => e.key === `${parsedKey[0]}-${parsedKey[1]}`
      );
      this.showModal = true;
    },
    removeNode(node) {
      let parsedKey = node.key.split("-");
      let parentNode = this.recipientTreeService.tree[parsedKey[0]].find(
        (e) => e.key === `${parsedKey[0]}-${parsedKey[1]}`
      );
      parentNode.children = parentNode.children.filter(
        (e) => e.key != node.key
      );
      parentNode.counter = parentNode.children.length;
      this.$emit("updated", this.recipientTreeService.parseTree());
      this.refresh();
    },
    changeItem() {
      if (["Roles", "Users"].includes(this.recipientNode.label)) {
        let selected =
          this.$refs[`notification${this.recipientNode.label}`].selectedValues;
        let labelField =
          this.recipientNode.label === "Roles"
            ? (e) => (!!e.displayName ? e.displayName : e.systemName)
            : (e) => e.login;

        if (!Array.isArray(selected)) selected = [selected];
        let maxI = this.recipientNode.children.length;
        let childNodes = selected.map((e, i) => {
          return {
            key: `${this.recipientNode.key}-${maxI + i}`,
            label: labelField(e),
            icon: "file",
            data: e.id,
          };
        });
        this.recipientNode.children =
          this.recipientNode.children.concat(childNodes);
        this.recipientNode.counter = this.recipientNode.children.length;
      } else {
        let maxI = this.recipientNode.children.length;
        let child = {
          key: `${this.recipientNode.key}-${maxI}`,
          label: this.customValue,
          data: this.customValue,
          targets: this.selectedNotifyTarget,
        };
        this.recipientNode.children.push(child);
        this.recipientNode.counter = this.recipientNode.children.length;
        this.selectedNotifyTarget = [];
      }

      this.$emit("updated", this.recipientTreeService.parseTree());
      this.refresh();
      this.recipientNode = null;
      this.customValue = null;
    },
    getLength(arr) {
      if (!arr) return 0;

      return arr.length;
    },
    translateLabels(val) {
      const lang = i18n.global.locale.value
      if (val?.tree) {
        const obj = val.tree;
        for (const arr in obj) {
          obj[arr].forEach(el => {
            if ('label' in el) {
              let toTranslate = (el['label']).toLowerCase();
              if (toTranslate === 'custom') {
                toTranslate = 'Other';
              }
              const label = lang === 'en' && toTranslate === 'Other' ? 'custom' : this.$t(toTranslate);
              el["label"] = _.startCase(_.toLower(label));
            }
          });
        }
      }
    }
  },
  computed: {
    recipientTabs() {
      return [
        {
          name: this.$t("to"),
          isActive: this.recipientTabPanel.name === this.$t("to"),
          counter: this.recipientFilter.To
            ? this.getLength(this.recipientFilter.To.Custom) +
              this.getLength(this.recipientFilter.To.Roles) +
              this.getLength(this.recipientFilter.To.Users)
            : null,
        },
        {
          name: this.$t("copy"),
          isActive: this.recipientTabPanel.name === this.$t("copy"),
          counter: this.recipientFilter.CC
            ? this.getLength(this.recipientFilter.CC.Custom) +
              this.getLength(this.recipientFilter.CC.Roles) +
              this.getLength(this.recipientFilter.CC.Users)
            : null,
        },
        {
          name: this.$t("BCC"),
          isActive: this.recipientTabPanel.name === this.$t("BCC"),
          counter: this.recipientFilter.BCC
            ? this.getLength(this.recipientFilter.BCC.Custom) +
              this.getLength(this.recipientFilter.BCC.Roles) +
              this.getLength(this.recipientFilter.BCC.Users)
            : null,
        },
      ];
    },
  },
};
</script>
<style lang="scss">
.recipient-tree {
  gap: 0 !important;
  .prospace-tabs__content {
    height: calc(100% - 10px);
  }
  .prospace-tree {
    width: 100%;
    height: auto;
    .p-treenode-label {
      width: 100%;
    }
    .recipient-tree__add-btn {
      position: absolute;
      right: 0;
    }
  }
}
</style>
