<template>
  <ProSpaceGeoLayout
    :is-templated="true"
    :template-center="true">
    <template #top>
      <ProSpaceTopPanel :header="layoutName" back @back="backHandler"/>
    </template>
    <template #center>
      <ProSpaceGeoLayout isHideRight isHideLeft>
        <template #top>
          <ProSpaceTabs :tabs="generalInfoTabs">
            <template #tab-1>
              <ProSpaceGeoLayout class="geo-row">
                <template #left>
                  <ProSpaceTextBlock
                    style="width: 220px;"
                    :top="$t('reserveGeneralInfo')"
                    :bottom="$t('reserveBaseInfoDesc')"
                    :gap="10"
                    type="third"
                    break-word
                  />
                </template>
                <template #center>
                  <ProSpaceHLayout :gap="20" style="width: 460px; flex-wrap: wrap; margin-left: 60px;">
                    <ProSpaceTextBlock
                      v-for="(b, i) in baseInfoBlocks"
                      :key="'txtBlock' + i"
                      ref="author-ProSpaceTextBlock"
                      :top="b.title"
                      :bottom="b.text"
                      :gap="10"
                      style="height: 42px; width: 220px;"
                      break-word
                    />
                  </ProSpaceHLayout>
                </template>
              </ProSpaceGeoLayout>

            </template>
          </ProSpaceTabs>
        </template>
        <template #center>
          <ProSpaceTabs :tabs="productsTabs" >
            <template #tab-1>
              <div class="product-table">
                <ProSpaceMainGrid
                  ref="grid"
                  :service="reserveProductService"
                  getMethod="grid"
                  dataKey="id"
                  :id="model.id"
                  selectionMode="single"
                  :defaultFilter="{
                      reserveId: { and: [+masterId] },
                      disabled: { and: [false] },
                    }"
                  @loaded="setCounterProducts"
                >
                  <template #columns>
                    <Column field="grd" :header="$t('bbdProductGRD')" sortable>
                      <template #body="field">
                        <ProSpaceTag
                          type="gray-light"
                          bordered
                          :text="field.data.grd"
                        />
                      </template>
                    </Column>
                    <Column field="description" :header="$t('reserveProduct')" sortable>
                      <template #body="field">
                        <ProSpaceTableHeaderCell
                          :title="field.data.description"
                        />
                      </template>
                    </Column>
                    <Column
                      field="bbd"
                      sortable
                      :header="$t('reserveExpiredDate')"
                      style="min-width: 100px"
                    >
                      <template #body="field">
                        <ProSpaceDateTimeColumn
                          :date="field.data.bbd.split('T')[0].split('-').reverse().join('.')"
                        />
                      </template>
                    </Column>
                    <Column
                      field="discount"
                      sortable
                      :header="$t('bbdProductDiscount')"
                      style="min-width: 100px"
                    >
                      <template #body="field">
                      <span class="text-discount">
                       {{ field.data.discount }}%
                      </span>
                      </template>
                    </Column>
                    <Column
                      field="reserveQuantity"
                      sortable
                      :header="$t('reserveCount')"
                      style="min-width: 100px"
                    >
                      <template #body="field">
                        <ProSpaceDefaultTextColumn :title="field.data.reserveQuantity">
                          {{ field.data.reserveQuantity }}
                        </ProSpaceDefaultTextColumn>
                      </template>
                    </Column>
                  </template>
                </ProSpaceMainGrid>
              </div>
            </template>
          </ProSpaceTabs>
        </template>
      </ProSpaceGeoLayout>
    </template>
  </ProSpaceGeoLayout>
</template>

<script>
import {
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceTopPanel,
  ProSpaceMainGrid,
  ProSpaceTabs,
  ProSpaceGeoLayout,
  ProSpaceTextBlock,
  ProSpaceTag,
  ProSpaceDefaultTextColumn,
  ProSpaceTableHeaderCell,
  ProSpaceDateTimeColumn
} from "prospace-components-library";
import Column from "primevue/column";
import { mapGetters } from "vuex";
import { getAMorPM } from "prospace-components-library/src/lib-assets/mixins/date";
import { ReserveProductService } from "./../../services/ReserveProductService";
import { ReserveService } from "./../../services/ReserveService";
export default {
  name: 'Reserve',
  components: {
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceTopPanel,
    ProSpaceMainGrid,
    ProSpaceTabs,
    ProSpaceGeoLayout,
    ProSpaceTextBlock,
    ProSpaceTag,
    ProSpaceDefaultTextColumn,
    ProSpaceTableHeaderCell,
    ProSpaceDateTimeColumn,
    Column
  },
  data() {
    return {
      masterId: null,
      reserveProductService: new ReserveProductService(),
      reserveService: new ReserveService(),
      getMethod: 'get',
      model: {
        title: 'ООО "Фуд-Трейд"',
        shipStock: 'RU36',
        deliveryStock: 'ООО "Фуд-Трейд" Самара',
        grd: 'ООО "Фуд-Трейд" Самара',
        shipTo: '10397339',
        createdAt: '2023-01-21T03:22:21.464Z'
      },
      generalInfoModelTabs: '',
      productCounter: 0,
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime",
      getFormatDate: "userinfoStore/getFormatDate",
    }),
    layoutName() {
      return this.masterId
        ? this.$t("reserveTxt") + " № " + this.masterId
        : this.$t("reserveTxt");
    },
    generalInfoTabs() {
      return [
        { name: this.$t("reserveBaseInfoTxt"), systemName: "reserveBaseInfoTxt", isActive: true },
        { name: this.$t("reserveBaseInfoTxt"), systemName: "reserveBaseInfoTxt", isActive: false, isHidden: true },
      ];
    },
    productsTabs() {
      return [
        { name: this.$t("reserveProducts"), systemName: "reserveProducts", isActive: true, counter: this.productCounter },
        { name: this.$t("reserveProducts"), systemName: "reserveProducts", isActive: true, isHidden: true },
      ];
    },
    baseInfoBlocks() {
      return [
        { title: this.$t("distributor"), text: this.model.distributorName },
        { title: this.$t("reserveGrdDistrCode"), text: this.model.distributorGRDCode },
        { title: this.$t("reserveDelivery"), text: this.model.shipToName },
        { title: this.$t("reserveShipTo"), text: this.model.shipToCode },
        { title: this.$t("reserveShipping"), text: this.model.shipFrom },
        {
          title: this.$t("reserveDate"),
          text: this.getFormatDateTime(this.model.createdAt) + ' ' + getAMorPM(this.model.createdAt, this.userinfo.timeFormat)
        },
      ];
    }
  },
  methods: {
    async getData() {
      const result = await this.reserveService[this.getMethod](
        this.masterId
      );
      this.model = result.data;
    },
    backHandler() {
      this.$router.go(-1);
    },
    setCounterProducts() {
      this.productCounter = this.$refs.grid.records?.length || 0;
    }
  },
  created() {
    this.masterId = this.$route.params.id;
  },
  mounted() {
    this.getData();
  }
};
</script>

<style lang="scss" scoped>
.text-discount {
  font-size: 14px;
  font-weight: 700;
}
.product-table {
  overflow: hidden;
  height: 100%;
  margin: 20px -5px 0;
  border: 1px solid var(--prospace-ui-border-color);
  border-top: none;
  border-radius: 5px;

  :deep(.p-datatable .p-datatable-tbody),
  :deep(.p-datatable-wrapper) {
    background-color: transparent;
  }
}
:deep(.product-table .main-grid__loading-mode .p-datatable .disabled-block) {
  display: none;
}
</style>
