<template>
  <ProSpaceGeoLayout>
    <template #top>
      <ProSpaceTopPanel :header="this.$t('bbdProducts')">
        <template #right>
          <ProSpaceIconButton
            icon="update"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
            @click="actionUpdate"
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #left>
      <ProSpaceLeftPanel
        class="bbdProduct-tree__left-panel"
        searchable
        :panelName="$t('bbdProductShips')"
        :searchPlaceholder="$t('searchTxt')"
        @search="handlerSearch"
      >
        <template #center>
          <ProSpaceTree
            ref="tree"
            searchName="shipFrom"
            getNodeMethod="getShipFromTree"
            propKey="shipFrom"
            defaultSortField="shipFrom"
            :service="bbdProductService"
            :prop-parent-id="null"
            :errorMessage="$t('noData')"
            @select="nodeSelect"
          >
            <template #prefix="{ node }">
              <ProSpaceTag :text="$t('reserveShipping')" type="teal" bordered />
              <ProSpaceTag :text="node.shipFrom" type="gray-light" bordered />
            </template>
            <template #empty>
              <div></div>
            </template>
          </ProSpaceTree>
        </template>
      </ProSpaceLeftPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpaceFilterPanel
            ref="filterPanel"
            :closeTxt="$t('closeTxt')"
            :searchPlaceholder="$t('searchTxt')"
            :txtResetFilters="$t('txtResetFilters')"
            :noMountedRequest="true"
            :unicKeyStorage="bbdProductService.constructor.name + 'grid'"
            :filter-model="['description', 'grd']"
            :ignore-clear-keys="['shipFrom']"
            @apply="submitFilter"
          >
            <template #hint>
              {{ this.$t("filterBy") }}
            </template>
            <template #filters="{ filter }">
              <ProSpaceFilterDatesRange
                v-model="filter.bbd"
                :placeholder-value="$t('bbdProductBBD')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                @apply="emitFilter"
              />
              <ProSpaceFilterNumberRange
                v-model="filter.discount"
                :placeholder-value="placeholderDiscount"
                :placeholder-filter="$t('bbdProductDiscount')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                @apply="emitFilter"
              />
              <ProSpaceCustomFilterPanel
                :customTxt="$t('custom')"
                :customFilterTxt="$t('customFilterTxt')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                :services="customServices"
                :unicKeyStorage="bbdProductService.constructor.name + 'grid'"
                @apply="(model) => customFilterApply(filter, model)"
              />
            </template>
          </ProSpaceFilterPanel>
        </template>
        <template #center>
          <ProSpaceMainGrid
            ref="grid"
            getMethod="grid"
            selectionMode="single"
            defaultSortField="createdAt"
            :noMountedRequest="true"
            :defaultSortOrder="-1"
            :service="bbdProductService"
            defaultColumnWidths="100,400"
            @selected-change="selectedHandler"
          >
            <template #columns>
              <Column
                field="grd"
                sortable
                headerClass="move-sort-head"
                :header="this.$t('bbdProductGRD')"
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    <ProSpaceTag
                      :text="field.data.grd"
                      type="gray-light"
                      bordered
                    />
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column
                field="description"
                sortable
                :header="$t('bbdProductDescription')"
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    <span class="text-bolder">{{
                      field.data.description
                    }}</span>
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column field="bbd" sortable :header="$t('bbdProductBBD')">
                <template #body="field">
                  <ProSpaceDateColumn
                    :date="field.data.bbd"
                    :format="userinfo.dateFormat"
                  />
                </template>
              </Column>
              <Column
                field="shipFrom"
                :header="$t('bbdProductShipFrom')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    {{ field.data.shipFrom }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column
                field="discount"
                :header="$t('bbdProductDiscount')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    <span style="font-size: 14px" class="text-bolder">{{ field.data.discount }}%</span>
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column
                field="availableQuantity"
                :header="$t('bbdProductAvailableQuantity')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn style="min-height: 20px">
                    <ProSpaceTag
                      :text="field.data.availableQuantity"
                      type="secondary"
                      style="font-size: 14px; height: 20px"
                    />
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                icon="error-message"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
              />
            </template>
          </ProSpaceMainGrid>
        </template>
      </ProSpaceGeoLayout>
    </template>
    <template #right>
      <BBDProductPanel
        v-if="hasSelect"
        ref="right-panel"
        @clear="selectedMultipleClear"
      />
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import {
  ProSpaceFilterPanel,
  ProSpaceCustomFilterPanel,
  ProSpaceMainGrid,
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceIconButton,
  ProSpaceEmptyGrid,
  ProSpaceLeftPanel,
  ProSpaceTree,
  ProSpaceDefaultTextColumn,
  ProSpaceDateColumn,
  ProSpaceFilterDatesRange,
  ProSpaceFilterNumberRange,
  ProSpaceTag,
} from "prospace-components-library";
import Column from "primevue/column";
import { BBDProductService } from "../../services/BBDProductService.js";
import BBDProductPanel from "./components/BBDProductPanel";
import { mapGetters } from "vuex";
const _ = require("lodash");
import { useStatistic } from "@composes";
import {
  useMultiSelectGrid,
} from "@composes/multiselect";
import { ref } from "vue";
export default {
  components: {
    ProSpaceFilterPanel,
    ProSpaceCustomFilterPanel,
    ProSpaceMainGrid,
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceIconButton,
    ProSpaceEmptyGrid,
    ProSpaceLeftPanel,
    ProSpaceTree,
    ProSpaceDefaultTextColumn,
    ProSpaceDateColumn,
    ProSpaceFilterDatesRange,
    ProSpaceFilterNumberRange,
    ProSpaceTag,
    Column,
    BBDProductPanel,
  },
  setup() {
    const grid = ref(null)
    const filterPanel = ref(null)
    const { setStatistic, distActions } = useStatistic();
     const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      hasSelect,
    } = useMultiSelectGrid(grid, filterPanel)
    return {
      grid, filterPanel,
      setStatistic, distActions,
      selectedMultipleGridHandler,
      selectedMultipleClear,
      hasSelect,
    };
  },
  data() {
    return {
      bbdProductService: new BBDProductService(),
      selectedNode: null,
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
    }),
    placeholderDiscount() {
      return `${this.$t('bbdProductDiscount')}, %`
    },
    customServices() {
      return [
        {
          type: "chips",
          model: "grd",
          label: this.$t("bbdProductGRD"),
          selected: {},
        },
        {
          type: "chips",
          model: "description",
          label: this.$t("bbdProductDescription"),
          selected: {},
        },
        {
          type: "range",
          model: "availableQuantity",
          label: this.$t("bbdProductAvailableQuantity"),
          min: 0,
          max: 99999999,
          selected: {},
        },
      ];
    },
  },
  methods: {
    handlerSearch(value) {
      this.$refs.tree.handlerSearch(value);
    },
    submitFilter({ filter, search }) {
      this.grid.submitFilter(filter, search);
    },
    emitFilter() {
      this.filterPanel.submitFilter();
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.filterPanel.submitFilter();
    },
    actionUpdate() {
      this.grid.getRecords(true);
      if (this.$refs["right-panel"]) {
        this.$refs["right-panel"].update();
      }
    },
    selectedHandler(val) {
      this.selectedMultipleGridHandler(val)
    },
    nodeSelect(node) {
      if (!node || (this.selectedNode && this.selectedNode.key === node.key))
        return;
      this.selectedNode = _.cloneDeep(node);
      this.selectedMultipleClear();
      if (node.shipFrom !== 0) {
        this.filterPanel.filter["shipFrom"] = {
          and: [node.shipFrom],
        };
      }
      this.filterPanel.submitFilter();
    },
  },
};
</script>
<style lang="scss">
@import "../../assets/scss/main.scss";
.bbdProduct-tree {
  .radio-tabs {
    margin-top: 1px;
    .radio-tab:nth-child(2) {
      display: none !important;
    }
    &-body {
      margin: 10px;
    }
  }

  &__left-panel {
    border: var(--prospace-ui-border);
  }
}
</style>
