import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/documentservice/documentview/";

export default class DocumentViewApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getDocumentViews(params) {
    return await request.post(`${rootRoute}getDocumentViews`, {
      filter: params.args,
      role: params.role,
      data: params.assignOptions,
      parameters: {},
    });
  }
  async getDocumentView(data) {
    return await request.post(`${rootRoute}getDocumentView`, {
      filter: `$filter=Id eq ${data.id}`,
      role: data.role,
      parameters: {},
    });
  }
  async getDistributors(filter) {
    return await request.post(`${rootRoute}getDocumentDistributors`, {
      filter: filter,
      parameters: {},
    });
  }
  async getPositions(filter) {
    return await request.post(`${rootRoute}getDocumentPositions`, {
      filter: filter,
      parameters: {},
    });
  }
}
