<template>
  <ProSpaceRightPanel
    :header="header"
    :loading="loading"
    :has-error="hasError"
    @clear="clearPanel"
    @edit="editPanel"
    @delete="deletePanel"
    @show-history="showHistoryPanel"
  >
    <template v-slot:center>
      <ProSpacePanelBlock :header="headerFaqs">
        <template #header>
          <ProSpaceAction
            v-if="checkEditAccess(faq)"
            :text="$t('editItemTxt')"
            @click="editPanel"
            ref="panel-action-edit"
          />
        </template>
        <ProSpacePanelBlockList :list="content" />
      </ProSpacePanelBlock>
      <ProSpacePdfReader
        v-if="pdfjsLib && file && file.statusFlag === 0"
        title="Расширенный ответ"
        :file="file"
        :service="fileService"
        :pdfjsLib="pdfjsLib"
        :txt-error="$t('errorFileDisplay')"
        :cancelTxt="$t('cancelInf')"
      ></ProSpacePdfReader>
    </template>
    <template  v-slot:bottom>
      <ProSpacePanelBlock :header="headerBottom">
        <template v-slot>
          <ProSpacePanelBlockList :list="contentBottom" />
        </template>
      </ProSpacePanelBlock>
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceAction,
  ProSpacePdfReader,
} from "prospace-components-library";
import { getAMorPM } from "prospace-components-library/src/lib-assets/mixins/date";
import { FaqService } from "../../../services/FaqService";
import { FileService } from "../../../../../../Frontends/ProSpace/services/FileService.js";
import accessMixin from "../../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import { mapGetters } from "vuex";
import {
  useMultiSelectRightPanel,
} from "@composes/multiselect"
export default {
  mixins: [accessMixin],
  name: "FaqPanel",
  components: {
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceAction,
    ProSpacePdfReader,
  },
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    filterAccessDots: {
      type: Function,
    },
    checkEditAccess: {
      type: Function,
    },
    checkAccess: {
      type: Function,
    }
  },
  data() {
    return {
      faq: null,
      faqService: new FaqService(),
      fileService: new FileService(),
      loading: false,
      hasError: false,
      pdfjsLib: false,
      file: null,
    };
  },
  setup() {
    const { selectedData } = useMultiSelectRightPanel()
    return {
      selectedData
    }
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime",
    }),
    headerFaqs() {
      return {
        title: this.$t("faqAnswer"),
        isExpand: false,
      };
    },
    headerBottom() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    tabs() {
      return [{ name: this.$t("overview"), isActive: true }];
    },
    header() {
      if (this.faq) {
        return {
          title: this.faq.question,
          id: this.faq.id,
          status: this.getStatus(this.faq.status),
          options: this.getAccessDots(this.options),
          showCounter: false,
          icon: "question",
        };
      }
      return {
        title: this.$t("itemsSelected"),
      };
    },
    content() {
      if (this.faq) {
        return [
          [
            {
              title: this.$t("faqShortAnswer"),
              text: this.faq.shortAnswer,
              isFullText: true,
              isHtml: true
            },
          ],
        ];
      }
      return [];
    },
    contentBottom() {
      let metaInfo = [];
      if (this.faq) {
        if(this.checkAccess("metainfo-by-block"))
          metaInfo.push([
            {
              title: this.$t("createdByTxt"),
              text: this.faq.createdBy,
            },
            {
              title: this.$t("updatedByTxt"),
              text: this.faq.updatedBy,
            },
          ]);

        metaInfo.push([
          {
            title: this.$t("createdAtTxt"),
            text: this.getFormatDateTime(this.faq.createdAt) + ' ' + getAMorPM(this.faq.createdAt, this.userinfo.timeFormat),
          },
          {
            title: this.$t("lastUpdatedTxt"),
            text: this.getFormatDateTime(this.faq.lastUpdated) + ' ' + getAMorPM(this.faq.lastUpdated, this.userinfo.timeFormat),
          },
        ]);
      }
      return metaInfo;
    },
  },
  mounted() {
    this.loadPdfjs();
    this.update();
  },
  watch: {
    selectedData() {
      this.update();
    },
  },
  methods: {
    getAccessDots(options) {
      return this.filterAccessDots ? this.filterAccessDots(options, this.faq) : options;
    },
    loadPdfjs() {
      const me = this;
      return import("pdfjs-dist/webpack").then((pdfjsLib) => {
        me.pdfjsLib = pdfjsLib;
      });
    },
    update() {
      this.hasError = false;
      try {
        this.getFaq();
      } catch (e) {
        this.hasError = true;
      }
    },
    getFaq() {
      if (this.selectedData) {
        this.loading = true;
        this.faqService.get(this.selectedData.id).then((res) => {
          this.faq = res.data;
          this.loading = false;
          this.file = res.data.files[0];
        });
      }
      this.faq = null;
    },
    getStatus(status) {
      if (!status) return;
      return {
        type: status === "ACTIVE" ? "success" : "danger",
        text: status[0].toUpperCase() + status.toLowerCase().slice(1),
      };
    },
    clearPanel() {
      this.$emit("clear");
    },
    editPanel() {
      this.$emit("edit", this.faq);
    },
    deletePanel() {
      this.$emit("delete", this.faq);
    },
    showHistoryPanel() {
      this.$emit("history", this.faq);
    },
  },
};
</script>

<style scoped></style>
