import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/newsservice/newsitemtemplateattachment/";

export default class NewsItemTemplateAttachmentApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getNewsItemTemplateAttachments(params) {
    return await request.post(`${rootRoute}getNewsItemTemplateAttachments`, {
      filter: params,
      parameters: {},
    });
  }
  async getNewsItemTemplateAttachment(id) {
    return await request.post(`${rootRoute}getNewsItemTemplateAttachments`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createNewsItemTemplateAttachment(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createNewsItemTemplateAttachment`, { data });
  }
  async deleteNewsItemTemplateAttachment(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteNewsItemTemplateAttachment`, { data });
  }
  async editNewsItemTemplateAttachment(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateNewsItemTemplateAttachment`, { data });
  }
  async setStatusNewsItemTemplateAttachment(models) {
    const data = models;
    return await request.post(`${rootRoute}setStatusNewsItemTemplateAttachment`, { data });
  }
}
