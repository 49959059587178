import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/basetype/systemdirectory/";

export default class SystemDirectoryApi {
  constructor() {
  }
  async getSystemDirectories(params) {
    return await request.post(`${rootRoute}getSystemDirectories`, {
      filter: params,
      parameters: {},
    });
  }
  async getSystemDirectory(id) {
    const filter = id ? `$filter=Id eq ${id}` : ''
    return await request.post(`${rootRoute}getSystemDirectories`, {
      filter: filter,
      parameters: {},
    });
  }
  async createSystemDirectory(model) {
    const data = [model];
    return await request.post(`${rootRoute}createSystemDirectory`, { data });
  }
  async deleteSystemDirectory(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteSystemDirectory`, { data });
  }
  async editSystemDirectory(model) {
    const data = [model];
    return await request.post(`${rootRoute}updateSystemDirectory`, { data });
  }
}
