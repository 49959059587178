import integrationlogs from "../../../../Services/Integration/ProSpace.Integration.Frontend/views/IntegrationLogs/IntegrationLogs";

export const integrationRoutes = [
  {
    path: "/integration/integrationlogs",
    name: "integrationlogs",
    component: integrationlogs,
  },
];

export default integrationRoutes;
