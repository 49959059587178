import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/masterdata/distributor/";

export default class DistributorApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getDistributors(params) {
    return await request.post(`${rootRoute}getDistributors`, {
      filter: params,
      parameters: {},
    });
  }
  async getDistributor(id) {
    return await request.post(`${rootRoute}getDistributors`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async getDistributorByCode(code) {
    return await request.post(`${rootRoute}getDistributors`, {
      filter: `$filter=code eq '${code}'`,
      parameters: {},
    });
  }
  async getByMultiple(multipleModel) {
    return await request.post(`${rootRoute}getDistributorsByMultiple`, {
      data: multipleModel
    });
  }
  async createDistributor(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createDistributor`, { data });
  }
  async deleteDistributor(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteDistributor`, { data });
  }
  async editDistributor(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateDistributor`, { data });
  }
  async export(filter, format) {
    return await request.post(`${rootRoute}exportDistributor`, {
      filter: filter,
      format: format
    });
  }
}
