<template>
  <ProSpaceRightPanel
    :header="header"
    :loading="loading"
    :has-error="hasError"
    @clear="clearPanel"
  >
    <template v-slot:center>
      <ProSpaceTabs :tabs="tabs" @select-tab="handlerSelectTab">
        <template #tab-1>
          <ProSpacePanelBlock :header="headerUser">
            <ProSpacePanelBlockList :list="contentUser" :itemsPerPage="7" />
          </ProSpacePanelBlock>
        </template>
        <template #tab-2>
          <ProSpacePanelBlock :header="headerRequest">
            <ProSpacePanelBlockList :list="contentRequest" :itemsPerPage="7" />
          </ProSpacePanelBlock>
        </template>
      </ProSpaceTabs>
    </template>
    <template v-slot:bottom>
      <ProSpacePanelBlock :header="headerBottom">
        <template v-slot>
          <ProSpacePanelBlockList :list="contentBottom" />
        </template>
      </ProSpacePanelBlock>
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceAction,
  Validations
} from "prospace-components-library";
import { StatisticsReportService } from "../../../services/StatisticsReportService";
import { mapGetters } from "vuex";
import { useStatistic } from "@composes"
import { getAMorPM } from "prospace-components-library/src/lib-assets/mixins/date";
import { useMultiSelectRightPanel } from "@composes/multiselect"
export default {
  name: "StatisticsReportPanel",
  components: {
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceAction,
  },
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    const { selectedData } = useMultiSelectRightPanel()
    return {
      setStatistic, distActions,
      selectedData
    }
  },
  data() {
    return {
      statisticsReport: null,
      statisticsReportService: new StatisticsReportService(),
      loading: false,
      hasError: false,
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime"
    }),
    headerUser() {
      return {
        title: this.$t("statisticsReportUser"),
        isExpand: false,
      };
    },
    headerRequest() {
      return {
        title: this.$t("statisticsReportRequest"),
        isExpand: false,
      };
    },
    headerBottom() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    tabs() {
      return [
        { name: this.$t("statisticsReportUser"), isActive: true, systemName: "user" },
        { name: this.$t("statisticsReportRequest"), isActive: false, systemName: "request" }
      ];
    },
    header() {
      if (this.statisticsReport) {
        return {
          title: this.statisticsReport.userFullName,
          id: this.statisticsReport.id,
          showCounter: false,
        };
      }
      return {
        title: this.$t("itemsSelected"),
      };
    },
    contentUser() {
      if (this.statisticsReport) {
        return [
          [
            {
              title: this.$t("statisticsReportUserFullName"),
              text: this.statisticsReport.userFullName,
            },
          ],
          [
            {
              title: this.$t("statisticsReportUserLogin"),
              text: this.statisticsReport.userLogin,
            },
          ],
          [
            {
              title: this.$t("statisticsReportUserEmail"),
              text: this.statisticsReport.userEmail,
              link: Validations.isEmail(this.statisticsReport.userEmail) ? "mailto:" + this.statisticsReport.userEmail : null,
            },
          ],
          [
            {
              title: this.$t("statisticsReportUserType"),
              text: this.$t(this.statisticsReport.userType),
            },
          ],
          [
            {
              title: this.$t("statisticsReportUserRole"),
              text: this.$t(this.statisticsReport.userRole || ''),
            },
          ],
          [
            {
              title: this.$t("statisticsReportUserPosition"),
              text: this.statisticsReport.userPosition,
            },
          ],
          [
            {
              title: this.$t("statisticsReportUserDistributor"),
              text: this.statisticsReport.userDistributor,
            },
          ],
        ]
      }
      return [];
    },
    contentRequest() {
      if (this.statisticsReport) {
        return [
          [
            {
              title: this.$t("statisticsReportDate"),
              text: this.getFormatDateTime(this.statisticsReport.requestDate),
            },
          ],
          [
            {
              title: this.$t("statisticsReportRequestType"),
              text: this.statisticsReport.requestType,
            },
          ],
          [
            {
              title: this.$t("statisticsReportRequestUrl"),
              text: this.statisticsReport.requestUrl,
            },
          ],
          [
            {
              title: this.$t("statisticsReportModule"),
              text: this.statisticsReport.module,
            },
          ],
          [
            {
              title: this.$t("statisticsReportEntity"),
              text: this.statisticsReport.entity,
            },
          ],
          [
            {
              title: this.$t("statisticsReportEntityId"),
              text: this.statisticsReport.entityId,
            },
          ],
          [
            {
              title: this.$t("statisticsReportOperation"),
              text: this.statisticsReport.operation,
            },
          ],
        ]
      }
      return []
    },
    contentBottom() {
      if (this.statisticsReport) {
        return [
          [
            {
              title: this.$t("createdByTxt"),
              text: this.statisticsReport.createdBy,
            },
            {
              title: this.$t("createdAtTxt"),
              text: this.getFormatDateTime(this.statisticsReport.createdAt) + ' ' + getAMorPM(this.statisticsReport.createdAt, this.userinfo.timeFormat),
            },
          ],
        ];
      }
      return [];
    },
  },
  mounted() {
    this.update();
  },
  watch: {
    selectedData: {
      deep: true,
      handler() {
        this.update();
      }
    },
  },
  methods: {
    handlerSelectTab({ selectedTab }) {
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: selectedTab.systemName
      })
    },
    update() {
      this.hasError = false;
      try {
        this.getStatisticsReport();
      } catch (e) {
        this.hasError = true;
      }
    },
    getStatisticsReport() {
      if (this.selectedData) {
        this.loading = true;
        this.statisticsReportService.get(this.selectedData.id).then((res) => {
          this.statisticsReport = res.data;
          this.loading = false;
        });
      }
      this.statisticsReport = null;
    },
    clearPanel() {
      this.$emit("clear");
    },
  },
};
</script>

<style scoped></style>
