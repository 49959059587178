<template>
  <ProSpaceGeoLayout>
    <template #top>
      <ProSpaceFilterPanel
        :closeTxt="$t('closeTxt')"
        :searchPlaceholder="$t('searchTxt')"
        :txtResetFilters="$t('txtResetFilters')"
        :modelFastFilter="modelFastFilter"
        :fastFilters="fastFilters"
        :unicKeyStorage="roleService.constructor.name + 'grid'"
        @apply="submitFilter"
        ref="filter-panel"
        filter-model="displayName"
        @update:modelFastFilter="
          (val) => {
            modelFastFilter = val;
            selectedMultipleClear();
          }
        "
        :blocked-filters="needBlock"
        @block="selectedMultipleBlockFilterHandler"
      >
        <template #hint> {{ $t("filterBy") }}</template>
        <template #filters="{ filter }">
          <ProSpaceMultiSelect
            :localization="$t"
            v-model="filter.status"
            :service="statusService"
            getMethod="get"
            optionLabel="text"
            optionValue="status"
            :selectAllTxt="$t('selectAllTxt')"
            :closeTxt="$t('closeTxt')"
            :placeholderValue="$t('status')"
            :applyTxt="$t('applyTxt')"
            :clearFilterTxt="$t('clearFilterTxt')"
            :searchPlaceholder="$t('searchTxt')"
            @apply="emitFilter"
          />
          <ProSpaceCustomFilterPanel
            :customTxt="$t('custom')"
            :customFilterTxt="$t('customFilterTxt')"
            :clearFilterTxt="$t('clearFilterTxt')"
            :selectAllTxt="$t('selectAllTxt')"
            :closeTxt="$t('closeTxt')"
            :applyTxt="$t('applyTxt')"
            :fromTxt="$t('fromTxt')"
            :toTxt="$t('toTxt')"
            :services="customServices"
            :unicKeyStorage="roleService.constructor.name + 'grid'"
            @apply="(model) => customFilterApply(filter, model)"
          />
        </template>
      </ProSpaceFilterPanel>
    </template>
    <template #center>
      <ProSpaceMainGrid
        ref="grid"
        :service="roleService"
        @selected-change="selectedHandler"
        :noMountedRequest="true"
        getMethod="grid"
        selectionMode="multiple"
        style="height: calc(100vh - 50px)"
      >
        <template #columns>
          <Column field="name" header="Name" sortable>
            <template #body="field">
              <ProSpaceTableHeaderCell
                :id="field.data.id"
                :status="getStatus(field.data.status)"
                :title="field.data.systemName"
                :description="`${field.data.description || ''}`"
              />
            </template>
          </Column>
        </template>
        <template #empty>
          <ProSpaceEmptyGrid
            icon="error-message"
            :title="$t('noDataTitle')"
            :text="$t('noDataTxt')"
          />
        </template>
      </ProSpaceMainGrid>
    </template>
    <template #right>
      <NotificationRolesPanel
        v-if="hasSelect"
        :service="roleService"
        @clear="selectedMultipleReset"
      />
      <ModalBlockFilters />
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import {
  ProSpaceTableHeaderCell,
  ProSpaceCustomFilterPanel,
  ProSpaceMultiSelect,
  ProSpaceFilterPanel,
  ProSpaceInputText,
  ProSpaceGeoLayout,
  ModalForm,
  ProSpaceMainGrid,
  ProSpaceEmptyGrid,
} from "prospace-components-library";
import Column from "primevue/column";

import { RoleService } from "../../services/RoleService.js";
import { StatusService } from "../../services/StatusService.js";
import NotificationRolesPanel from "./components/NotificationRolesPanel.vue";
import { mapGetters } from "vuex";
import {
  useMultiSelectGrid,
} from "@composes/multiselect";
import ModalBlockFilters from "@multiselect/ModalBlockFilters"
import { ref } from "vue"
export default {
  components: {
    ProSpaceCustomFilterPanel,
    ProSpaceMultiSelect,
    ProSpaceFilterPanel,
    ProSpaceInputText,
    ProSpaceGeoLayout,
    ProSpaceTableHeaderCell,
    ModalForm,
    ProSpaceMainGrid,
    Column,
    ProSpaceEmptyGrid,
    NotificationRolesPanel,
    ModalBlockFilters
  },
  data() {
    return {
      // Grid Panel
      selectedValues: [],

      // Service
      roleService: new RoleService(),
      statusService: new StatusService(),

      // Common
      layoutName: "Roles",

      // Modal
      modalModel: {
        systemName: "",
      },
      isEdit: false,
      isCreate: false,

      // Filter Panel Model
      modelFastFilter: {
        label: this.$t("allTxt"),
        value: false,
        field: "disabled",
      },
    };
  },
  setup() {
    const grid = ref(null)
    const filterPanel = ref(null)
    const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      hasSelect,
      selectedMultipleBlockFilterHandler,
      needBlock, selectedMultipleReset
    } = useMultiSelectGrid(grid, filterPanel)
    return {
      grid, filterPanel,
      selectedMultipleGridHandler,
      hasSelect, selectedMultipleClear,
      selectedMultipleBlockFilterHandler,
      needBlock, selectedMultipleReset
    }
  },
  methods: {
    getStatus(status) {
      if (!status) return;
      let type = "default";
      switch (status.toLowerCase()) {
        case "complete":
          type = "success";
          break;
        case "process":
          type = "warning";
          break;
        case "error":
          type = "danger";
          break;
        default:
          type = "default";
      }
      return {
        text: status[0].toUpperCase() + status.toLowerCase().slice(1),
        type,
      };
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.filterPanel.submitFilter();
    },
    selectedHandler(val) {
      this.selectedMultipleGridHandler(val)
      this.selectedValues = this.grid.getSelectedModel();
    },
    submitFilter({ filter, search }) {
      this.grid.submitFilter(filter, search);
    },
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
    }),
    disabledModalRule() {
      return this.modalModel.systemName.length == 0;
    },
    fastFilters() {
      return [
        {
          label: this.$t("allTxt"),
          value: false,
          field: "disabled",
        },
        { label: this.$t("deleted"), field: "disabled", value: true },
      ];
    },
    customServices() {
      return [
        {
          type: "number",
          model: "id",
          label: this.$t("id"),
          selected: {},
        },
        {
          type: "chips",
          model: "createdBy",
          label: this.$t("createdByTxt"),
          selected: {},
        },
        {
          type: "chips",
          model: "updatedBy",
          label: this.$t("updatedByTxt"),
          selected: {},
        },
        {
          type: "dates-range",
          model: "createdAt",
          label: this.$t("createDate"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range",
          model: "lastUpdated",
          label: this.$t("lastUpdatedTxt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
      ];
    },
  },
};
</script>
