import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/newsservice/newsitemdistributor/";

export default class NewsItemDistributorApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getNewsItemDistributors(params) {
    return await request.post(`${rootRoute}getNewsItemDistributors`, {
      filter: params,
      parameters: {},
    });
  }
  async getNewsItemDistributor(id) {
    return await request.post(`${rootRoute}getNewsItemDistributors`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createNewsItemDistributor(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createNewsItemDistributor`, { data });
  }
  async deleteNewsItemDistributor(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteNewsItemDistributor`, { data });
  }
  async editNewsItemDistributor(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateNewsItemDistributor`, { data });
  }
}
