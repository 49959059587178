import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/information/faqview/";

export default class FaqApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getFaqs(params) {
    return await request.post(`${rootRoute}getFaqViews`, {
      filter: params,
      parameters: {},
    });
  }
  async getFaq(id) {
    return await request.post(`${rootRoute}getFaqViews`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
}
