import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/masterdata/distributorshiptoview/";

export default class DistributorShipToApi {
  constructor() {
  }
  async getDistributorShipTos(params) {
    return await request.post(`${rootRoute}getDistributorShipToViews`, {
      filter: params,
      parameters: {},
    });
  }
}
