import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/report/reportimportant/";

export default class ReportImportantApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getReportImportants(params) {
    return await request.post(`${rootRoute}getReportImportants`, {
      filter: params,
      parameters: {},
    });
  }
  async getReportImportant(id) {
    return await request.post(`${rootRoute}getReportImportants`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createReportImportant(model) {
    const data = Array.isArray(model)
      ? model
      : [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createReportImportant`, { data });
  }
  async deleteReportImportant(model) {
    const data = Array.isArray(model)
    ? model
    : [model];
    return await request.post(`${rootRoute}deleteReportImportant`, { data });
  }
  async editReportImportant(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateReportImportant`, { data });
  }
}
