<template>
  <ProSpaceModal
    v-model="localModelValue"
    :title="$t('support')"
    icon="info"
    width="500"
    :cancelTxt="$t('cancelInf')"
    :applyTxt="false"
    class="prospace-support-modal"
  >
    <ProSpacePanelBlockHeader
      :title="$t('supportHeader')"
      :isNeedControls="false"
    />
    <ProSpaceTodoList :list="todoList" />
  </ProSpaceModal>
</template>
<script>
import {
  ProSpacePanelBlockHeader,
  ProSpaceTodoList,
} from "prospace-components-library";
import { ProSpaceModal } from "prospace-components-library";
export default {
  components: {
    ProSpaceModal,
    ProSpaceTodoList,
    ProSpacePanelBlockHeader,
  },
  computed: {
    todoList() {
      return [
        {
          header: this.$t("regOnPortal"),
          desc: this.$t("ifNotRegOnPortal"),
          linkText: this.$t("linkOnRegPortal"),
          link: "https://drive.google.com/file/d/1AmKIwvE18ebQl3mt6SlzWhvApCnGEBVl/view",
        },
        {
          header: this.$t("createReportOfProblem"),
          desc: this.$t("createReportOfProblemDesc"),
          linkText: this.$t("linkOnPortal"),
          link: "https://mars.intradesk.ru",
        },
        {
          header: this.$t("expAnswer"),
          desc: this.$t("specSupport"),
        },
      ];
    },
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        this.$emit("update:modelValue", newVal);
      },
    },
  },
};
</script>
<style lang="scss">
.prospace-support-modal {
  .prospace-panel-block-header {
    padding: 15px 20px;
    &__title {
      white-space: normal;
      & + .prospace-h-layout {
        display: none;
      }
    }
  }
}
</style>
