import request from "../../../../Frontends/Common/utils/request/requestMain";
import { AUTHORIZATION_INFO } from "../../../../Frontends/Common/utils/consts";

export default class UserService {
  constructor() {
    this.AccessTokenKey = "Admin-Access-Token";
    this.RefreshTokenKey = "Admin-Refresh-Token";
    this.AutologinAvailableKey = "Admin-Autologin-Available";
    this.RedirectUrlKey = "Admin-Redirect-Url";
    this.LoginTypeKey = "Admin-Login-Type";
    this.StorageLocation = "localStorage"; // 'sessionStorage'
    this.Aik = "Aik";
  }

  async logout() {
    return await request.post(
      `authorization/logout/?redirectUrl=${window.location.origin}`,
      null,
      {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }
    );
  }
  async getToken(params) {
    var bodyFormData = new URLSearchParams();
    for (const property in params)
      bodyFormData.append(property, params[property]);

    const response = await request.post(`authorization/token`, bodyFormData, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    });

    return response;
  }
  authorize(loginType, authorizeUrl) {
    let url =
      `authorization/authorize?` +
      `provider=${loginType}` +
      `&response_type=code` +
      `&state=${AUTHORIZATION_INFO.state}` +
      `&client_id=${AUTHORIZATION_INFO.client_id}` +
      `&scope=${AUTHORIZATION_INFO.scope}` +
      `&redirect_uri=${AUTHORIZATION_INFO.redirect_uri}` +
      `&code_challenge=${AUTHORIZATION_INFO.code_challenge}` +
      `&code_challenge_method=${AUTHORIZATION_INFO.code_challenge_method}`;
    if (authorizeUrl) url = authorizeUrl;
    window.location.href =
      (process.env.VUE_APP_API_BASE_URL || config.VUE_APP_API_BASE_URL) + url;
  }
  async signIn(login, pass, provider) {
    return await request.post(
      `authorization/signin`,
      { login, pass, provider },
      {
        headers: { "content-type": "application/json" },
      }
    );
  }
  moveToLogin() {
    window.location.href = "/security/login"
  }
}
