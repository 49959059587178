<template>
  <ProSpaceGeoLayout class="notificationlog" v-if="model">
    <template #top>
      <ProSpaceTopPanel :header="layoutName" back @back="backHandler">
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceRadioButtonSecondary
        v-if="model"
        :tabs="secondaryTabs"
        v-model="modelTab"
        @select="handlerSelectTab"
      >
        <template #overview>
          <ProSpaceGeoLayout :template-center="true">
            <template #center>
              <ProSpaceTabs :tabs="overviewTabs" :loading="loading" @select-tab="handlerSelectTab">
                <template v-slot:tab-1>
                  <ProSpaceVLayout :gap="20">
                    <ProSpaceGeoLayout class="geo-row">
                      <template v-slot:left>
                        <ProSpaceTextBlock
                          :top="$t('mainInfo')"
                          :bottom="$t('notificationLogInfoAboutMessage')"
                          :gap="10"
                          break-word
                          type="third"
                        />
                      </template>
                      <template v-slot:center>
                        <ProSpaceVLayout
                          justify-content="space-between"
                          align-items="flex-start"
                          :gap="20"
                        >
                          <ProSpaceTextBlock
                            :top="$t('notificationLogNotificationName')"
                            :bottom="model.eventName"
                            break-word
                          />
                          <ProSpaceTextBlock
                            :top="$t('notificationLogNotificationMessage')"
                            :bottom="model.body"
                            break-word
                          />
                        </ProSpaceVLayout>
                      </template>
                      <template
                        v-if="v$.model.notificationName.$error"
                        v-slot:right
                      >
                        <ProSpaceWarning />
                      </template>
                    </ProSpaceGeoLayout>
                    <ProSpaceGeoLayout class="geo-row">
                      <template v-slot:left>
                        <ProSpaceTextBlock
                          :top="$t('createDate')"
                          :bottom="$t('notificationLogCreateDateMessage')"
                          :gap="10"
                          break-word
                          type="third"
                        />
                      </template>
                      <template v-slot:center>
                        <ProSpaceHLayout
                          justify-content="space-between"
                          align-items="flex-start"
                          :gap="10"
                        >
                          <ProSpaceTextBlock
                            :top="$t('createDate')"
                            :bottom="getFormatDateTime(model.createdAt)"
                            break-word
                          />
                        </ProSpaceHLayout>
                      </template>
                      <template
                        v-if="v$.model.notificationDescription.$error"
                        v-slot:right
                      >
                        <ProSpaceWarning />
                      </template>
                    </ProSpaceGeoLayout>
                    <ProSpaceGeoLayout class="geo-row">
                      <template v-slot:left>
                        <ProSpaceTextBlock
                          :top="$t('type')"
                          :bottom="$t('notificationLogTypeMessage')"
                          :gap="10"
                          break-word
                          type="third"
                        />
                      </template>
                      <template v-slot:center>
                        <ProSpaceHLayout
                          justify-content="space-between"
                          align-items="flex-start"
                          :gap="10"
                        >
                          <ProSpaceSelectButton
                            style="pointer-events: none"
                            :label="$t('notificationLogTypeMessage')"
                            :items="notificationTargets"
                            v-model="model.notifyTarget"
                          />
                        </ProSpaceHLayout>
                      </template>
                      <template
                        v-if="v$.model.notificationDescription.$error"
                        v-slot:right
                      >
                        <ProSpaceWarning />
                      </template>
                    </ProSpaceGeoLayout>
                  </ProSpaceVLayout>
                </template>
              </ProSpaceTabs>
            </template>
          </ProSpaceGeoLayout>
        </template>
        <template #recipients>
          <ProSpaceGeoLayout :template-center="true">
            <template #center>
              <ProSpaceTabs :tabs="overviewTabs" :loading="loading" @select-tab="handlerSelectTab">
                <template #tab-1>
                  <ProSpaceGeoLayout class="geo-row">
                    <template v-slot:left>
                      <ProSpaceTextBlock
                        :top="$t('type')"
                        :bottom="$t('notificationLogTypeMessage')"
                        :gap="10"
                        break-word
                        type="third"
                      />
                    </template>
                    <template v-slot:center>
                      <ProSpaceSimpleGrid
                        ref="recipientGrid"
                        :headers="recipientGridHeaders"
                        :items="getFilterRecipients(model.recipients)"
                        :limit="null"
                      />
                    </template>
                  </ProSpaceGeoLayout>
                </template>
              </ProSpaceTabs>
            </template>
          </ProSpaceGeoLayout>
        </template>
      </ProSpaceRadioButtonSecondary>
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import {
  ProSpaceTopPanel,
  ProSpaceInputText,
  ProSpaceAction,
  ProSpaceRadioButtonSecondary,
  ProSpaceGeoLayout,
  ProSpaceTabs,
  ProSpaceTextBlock,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceDropdown,
  ProSpaceFile,
  ProSpaceInputCalendar,
  ProSpaceInputNumber,
  ProSpaceSearchfield,
  ProSpaceMultiSelect,
  ProSpaceCustomFilterPanel,
  ProSpaceIcon,
  ProSpaceWarning,
  ProSpaceSelectButton,
  ProSpaceSimpleGrid
} from "prospace-components-library";
import { NotificationLogService } from "../../services/NotificationLogService.js";
import { mapGetters } from "vuex";

import {
  required,
  maxLength,
  helpers,
  minValue,
  maxValue,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {
  useStatistic
} from "@composes"
export default {
  components: {
    ProSpaceTopPanel,
    ProSpaceGeoLayout,
    ProSpaceInputText,
    ProSpaceAction,
    ProSpaceRadioButtonSecondary,
    ProSpaceTabs,
    ProSpaceTextBlock,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceDropdown,
    ProSpaceFile,
    ProSpaceInputCalendar,
    ProSpaceInputNumber,
    ProSpaceSearchfield,
    ProSpaceMultiSelect,
    ProSpaceCustomFilterPanel,
    ProSpaceIcon,
    ProSpaceWarning,
    ProSpaceSelectButton,
    ProSpaceSimpleGrid
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    return { v$: useVuelidate(), setStatistic, distActions };
  },
  created() {
    this.modelTab = this.secondaryTabs[0];
  },
  data() {
    this.tabs = [{ label: "Overview", value: "Overview" }];
    return {
      // Tabs
      modelTab: null, // Первое предустановленное значение

      // Common
      layoutName: "",
      model: {}, // null
      loading: false,

      // Service
      notificationLogService: new NotificationLogService(),

      getMethod: "get",
      masterId: null,

      // SaveFields
      saveFields: {
        Overview: [
          "notificationName",
          "notificationDescription",
          "status",
          "recipients",
          "notificationType",
          "sendAt",
          "errorCode",
          "tryCount",
          "errorCount",
          "logFileGFID",
          "logFileName",
          "errorMessage",
        ],
      },
      recipientGridHeaders: [
        //{ name: this.$t("type"), value: "recordTypeLocale" },
        {
          name: this.$t("login"),
          value: "login",
          f: (item) => item,
        },
        {
          name: this.$t("email"),
          value: "email",
          f: (item) => item,
        },
      ]
    };
  },
  validations() {
    return {
      model: {
        notificationName: {},
        notificationDescription: {},
        status: {},
        recipients: {},
        notificationType: {},
        sendAt: {},
        errorCode: {},
        tryCount: {},
        errorCount: {},
        logFileGFID: {},
        logFileName: {},
        errorMessage: {},
      },
    };
  },
  mounted() {
    this.masterId = this.$route.params.id;
    this.getData();
  },
  methods: {
    handlerSelectTab(value) {
      const tab = value.selectedTab || value
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: tab.systemName
      })
    },
    handlerMessage(msg) {
      const regexDescription = /(<h3)(.*?)(?=<\/table>)/g;
      const arrDescriptionRgx = msg.match(regexDescription);
      const regexAction = /(<span)(.*?)(<\/span>)/g;
      const arrActionRgx = msg.match(regexAction);
      const regexDeleteColor = /(background-color|line-height|color|font-family)\:\#?\d.\w+(\;)?/g;
      if (arrActionRgx[0].includes('Необходимое')) {
        const formatAction = [`<h3 style="font-weight: 600;">${arrActionRgx[0]}</h3>`, `<p style="padding: 0; line-height: 1.3; margin-bottom: 10px;">${arrActionRgx[1]}</p>`];
        return formatAction.concat(arrDescriptionRgx).join(' ').replaceAll(regexDeleteColor, '');
      }
      return arrDescriptionRgx.join('').replaceAll(regexDeleteColor, '');
    },
    gridLoaded() {},
    getFilterRecipients(recipients) {
      if (!recipients) {
        []
      }
      return recipients.filter(i => i.parameters)
    },
    async getData() {
      if (this.masterId === undefined) {
        // redirect to 404
        return;
      }
      const result = await this.notificationLogService[this.getMethod](
        this.masterId
      );
      this.model = result.data;
      this.model.body = this.handlerMessage(result.data.body);
      this.layoutName = `${this.model.eventName}`;
    },
    saveAll() {
      this.loading = true;
      this.notificationLogService.set().then(() => {
        this.getData();
        this.loading = false;
      });
    },
    savePart(fields) {
      this.loading = true;
      this.notificationLogService.setPart(fields).then(() => {
        this.getData();
        this.loading = false;
      });
    },
    submitFilter(nameRef = "grid") {
      if (!this.$refs[nameRef]) return;
      this.$refs[nameRef].getRecords(true);
    },
    backHandler() {
      this.$router.go(-1);
    }
  },
  computed: {
    ...mapGetters({
      getFormatDateTime: "userinfoStore/getFormatDateTime",
    }),
    notificationTargets() {
      return [{ value: "Mail", label: this.$t("email"), icon: "message" }];
    },
    overviewTabs() {
      return [{ name: this.$t("overview"), systemName: "overview" }];
    },
    secondaryTabs() {
      return [
        { label: this.$t("overview"), value: "overview", systemName: "overview" },
        { label: this.$t("recipients"), value: "recipients", systemName: "recipients" },
      ];
    },
  },
};
</script>
<style lang="scss"></style>
