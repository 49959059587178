import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/masterdata/mailinggroup/";

export default class MailingGroupApi {
  constructor() {
  }
  async getMailingGroups(params) {
    return await request.post(`${rootRoute}getMailingGroups`, {
      filter: params,
      parameters: {},
    });
  }
  async getMailingGroup(id) {
    return await request.post(`${rootRoute}getMailingGroups`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createMailingGroup(model) {
    const data = [model];
    return await request.post(`${rootRoute}createMailingGroup`, { data });
  }
  async deleteMailingGroup(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteMailingGroup`, { data });
  }
  async editMailingGroup(model) {
    const data = [model];
    return await request.post(`${rootRoute}updateMailingGroup`, { data });
  }
}
