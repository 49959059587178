import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/documentservice/importantdocumentuser/";

export default class ImportantDocumentUserApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getImportantDocumentUsers(params) {
    return await request.post(`${rootRoute}getImportantDocumentUsers`, {
      filter: params,
      parameters: {},
    });
  }
  async getImportantDocumentUser(id) {
    return await request.post(`${rootRoute}getImportantDocumentUsers`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createImportantDocumentUser(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createImportantDocumentUser`, { data });
  }
  async createImportantDocumentUsers(model) {
    const data = model;
    return await request.post(`${rootRoute}createImportantDocumentUser`, { data });
  }
  async deleteImportantDocumentUser(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteImportantDocumentUser`, { data });
  }
  async deleteImportantDocumentUsers(model) {
    const data = model;
    return await request.post(`${rootRoute}deleteImportantDocumentUser`, { data });
  }
  async editImportantDocumentUser(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateImportantDocumentUser`, { data });
  }
  async editImportantDocumentUsers(model) {
    const data = model;
    return await request.post(`${rootRoute}updateImportantDocumentUser`, { data });
  }
}
