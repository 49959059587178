import i18n from "../../../../Frontends/Common/utils/i18n.js";

export class RecordTypeService {
  constructor() {
    this.types = [
      { text: i18n.global.t("MailingGroup"), recordType: "MailingGroup" },
      { text: i18n.global.t("mars"), recordType: "User" },
      { text: i18n.global.t("Distributor"), recordType: "Distributor" },
    ];
  }
  search(value) {
    return this.types.filter((elem) => elem.text.indexOf(value) !== -1);
  }
  async get() {
    return Promise.resolve({ data: { records: this.types } });
  }
  async getShort() {
    return Promise.resolve({ data: { records: this.types.filter(x => x.recordType !== "Distributor") } });
  }
}
