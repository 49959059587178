import i18n from "../../../../Frontends/Common/utils/i18n";
export class TaskStatusService {
  constructor() {
    this.statuses = [
      { status: i18n.global.t("taskStatus_new"), text: "new" },
      { status: i18n.global.t("taskStatus_complete"), text: "complete" },
      { status: i18n.global.t("taskStatus_error"), text: "error" },
      { status: i18n.global.t("taskStatus_accepted"), text: "accepted" },
      { status: i18n.global.t("taskStatus_partialcomplete"), text: "partial complete" },
    ];
  }
  search(value) {
    return this.statuses.find(x=>x.text == value.toLowerCase())?.status ?? "";
  }
  async get() {
    return Promise.resolve({ data: { records: this.statuses } });
  }
}
