<template>
  <ProSpaceGeoLayout class="geo-row">
    <template v-slot:left v-if="titleTxt && descriptionTxt">
      <ProSpaceTextBlock
        :top="titleTxt"
        :bottom="descriptionTxt"
        :gap="10"
        type="third"
        break-word
        :hint="hint"
      />
    </template>
    <template v-slot:center>
      <ProSpaceGeoLayout>
        <template v-slot:top v-if="!(localReadonly || readOnly)">
          <ProSpaceButton
            type="secondary"
            icon="edit"
            class="margin-bottom-30"
            :label="this.$t('editItemTxt')"
            @click="showSelectModal"
          />
          <DistributorHierarchySelectModal
            v-model="selectModalVisible"
            needFilterValues
            :titleModal="titleModal"
            :service="service"
            :getMethod="getMethod"
            :selectionMode="selectionMode"
            :defaultFilter="defaultFilter"
            :propLabel="propLabel"
            :checkedRows="modelValue"
            :expandAll="expandAll"
            :needValidate="needValidate"
            :isOneDistr="isOneDistr"
            :hideRightPanel="hideRightPanel"
            :isOneFilial="isOneFilial"
            :isFilial="isFilial"
            @apply="applySelect"
          />
        </template>
        <template v-slot:center>
          <ProSpaceGeoLayout>
            <template v-slot:center>
              <DistributorHierarchySelectTree
                ref="readOnlyTree"
                v-if="modelValue.length > 0"
                readOnly
                expandAll
                :service="service"
                :getMethod="methodForView"
                :checkedRows="modelValue"
                :isFilial="isFilial"
              />
            </template>
          </ProSpaceGeoLayout>
        </template>
      </ProSpaceGeoLayout>
    </template>
  </ProSpaceGeoLayout>
</template>

<script>
import {
  ProSpaceGeoLayout,
  ProSpaceTextBlock,
  ProSpaceButton,
} from "prospace-components-library";
import DistributorHierarchySelectModal from "@modals/DistributorHierarchySelectModal"
import DistributorHierarchySelectTree from "@trees/DistributorHierarchySelectTree"
import { DistributorStructureService } from "@masterdata/services/DistributorStructureService.js";
const _ = require("lodash");
export default {
  components: {
    ProSpaceGeoLayout,
    ProSpaceTextBlock,
    ProSpaceButton,
    DistributorHierarchySelectModal,
    DistributorHierarchySelectTree,
  },
  props: {
    modelValue: {
      type: Array,
      required: true
    },
    titleTxt: {
      type: String,
    },
    descriptionTxt: {
      type: String,
    },
    selectedUnits: {
      type: Array,
      default: [],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    service: {
      type: Object,
      default: new DistributorStructureService()
    },
    getMethod: {
      type: String,
      default: "allTree"
    },
    viewMethod: {
      type: String,
      default: "getViewTree",
    },
    checkViewMethod: {
      type: String,
      default: "getViewTree",
    },
    selectionMode: {
      type: String,
      default: "checkbox",
    },
    searchName: {
      type: String,
      default: "name"
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    defaultFilter: {
      type: Object,
    },
    propLabel: {
      type: String,
      default: "name",
    },
    expandAll: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: ""
    },
    needValidate: {
      type: Boolean,
      default: false
    },
    isOneDistr: {
      type: Boolean,
      default: false,
    },
    afterEdit: {
      type: Boolean,
      default: false
    },
    titleModal: {
      type: String,
      default: ""
    },
    hideRightPanel: {
      type: Boolean,
      default: false
    },
    isOneFilial: {
      type: Boolean,
      default: false
    },
    isFilial: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      selectModalVisible: false,
      localReadonly: false
    };
  },
  computed: {
    methodForView() {
      return this.afterEdit ? this.checkViewMethod : this.viewMethod;
    }
  },
  methods: {
    showSelectModal() {
      this.selectModalVisible = true;
    },
    applySelect(units) {
      units = units.map(i => {
        return {
          distributorStructureViewId: i.distributorStructureViewId,
          distributorStructureViewName: i.distributorStructureViewName || "",
          distributorStructureViewCode: i.distributorStructureViewCode,
          distributorStructureViewType: i.distributorStructureViewType || "",
          distributorStructureViewDistributorId: i.distributorStructureViewDistributorId,
          distributorStructureViewDistributorName: i.distributorStructureViewDistributorName
        }
      })
      this.$emit("apply", _.cloneDeep(units));
      this.$emit('update:modelValue', units)
      if (this.service.setNodeViewIds) {
        this.setDistributorStructure(units)
      }
      this.refreshViewTree();
    },
    refreshViewTree() {
      this.$nextTick(() => {
        if(this.$refs.readOnlyTree)
          this.$refs.readOnlyTree.refresh();
      });
    },
    setDistributorStructure(units) {
      this.service.setNodeViewIds(units.map(x => x.distributorStructureViewId));
    },
  },
};
</script>

<style lang="scss">
</style>
