import request from "../Frontends/Common/utils/request/requestMain";
const rootRoute = "securityadmin/securityusermenuitem/";

export default class MenuItemUserApi {
  async grid(data) {
    return await request.post(`${rootRoute}getsecurityusermenuitems`, {
      filter: data.filter,
    });
  }
  async getMenuItemUser(params) {
    return await request.post(`${rootRoute}getsecurityusermenuitems`, {
      filter: params,
    });
  }
}
