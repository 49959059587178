import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/masterdata/distributororg/";

export default class DistributorOrgApi {
  constructor() {
  }
  async getDistributorOrgs(params) {
    return await request.post(`${rootRoute}getDistributorOrgs`, {
      filter: params,
      parameters: {},
    });
  }
  async getDistributorOrg(id) {
    return await request.post(`${rootRoute}getDistributorOrgs`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createDistributorOrg(model) {
    const data = [model];
    return await request.post(`${rootRoute}createDistributorOrg`, { data });
  }
  async deleteDistributorOrg(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteDistributorOrg`, { data });
  }
  async editDistributorOrg(model) {
    const data = [model];
    return await request.post(`${rootRoute}updateDistributorOrg`, { data });
  }

  async get(filter) {
    return await request.post(`${rootRoute}getDistributorOrgs`, {
      filter
    });
  }
}
