import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/newsservice/newsitem/";

export default class NewsItemApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getNewsItems(params) {
    return await request.post(`${rootRoute}getNewsItems`, {
      data: params.assignOptions,
      filter: params.args,
      role: params.role,
      parameters: {},
    });
  }
  async getNewsItem(params) {
    return await request.post(`${rootRoute}getNewsItem`, {
      filter: `$filter=Id eq ${params.id}`,
      role: params.role,
      parameters: {},
    });
  }
  async createNewsItem(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createNewsItem`, { data });
  }
  async deleteNewsItem(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteNewsItem`, { data });
  }
  async editNewsItem(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateNewsItem`, { data });
  }
  async getDistributors(filter) {
    return await request.post(`${rootRoute}getNewsDistributors`, {
      filter: filter,
      parameters: {},
    });
  }
  async getPositions(filter) {
    return await request.post(`${rootRoute}getNewsItemPositions`, {
      filter: filter,
      parameters: {},
    });
  }
  async nextStep(model) {
    const data = [model];
    return await request.post(`${rootRoute}nextStep`, { data });
  }
  async rejectStep(model) {
    const data = [model];
    return await request.post(`${rootRoute}rejectStep`, { data });
  }
  async validateUsersExists(model) {
    const data = [model];
    return await request.post(`${rootRoute}validateUsersExists`, { data });
  }
  async validateAuthorizedPersonsExists(model) {
    const data = [model];
    return await request.post(`${rootRoute}validateAuthorizedPersonsExists`, { data });
  }
  async addComment(model) {
    const data = [model];
    return await request.post(`${rootRoute}addComment`, { data });
  }
  async export(filter, role, format, assignOptions) {
    return await request.post(`${rootRoute}exportnewsitem`, {
      data: assignOptions,
      filter: filter,
      role: role,
      format: format
    });
  }
}
