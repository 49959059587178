import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/TaskService/TaskAssignmentApi";
import store from "../../../../Frontends/Common/store";
export class TaskAssignmentService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
    this.statuses = [
      { text: "Черновик", value: "taskDraft", type: "default" },
      { text: "На модерации", value: "taskModer", type: "draft" },
      { text: "Ожидает публикации", value: "taskWaitPublish", type: "brown" },
      { text: "Не выполнено", value: "taskPublished", type: "info" },
      { text: "На подтверждении у Марс", value: "taskApproving", type: "orange" },
      { text: "Выполнено", value: "taskComplete", type: "success" },
      { text: "Отменено", value: "taskCanceled", type: "default" },
    ];
    this.taskAssignmentId = null;
    this.assignOptions = {
      onPosition: false,
      onSlave: false,
      onFilial: false,
      onDistributor: false
    }
  }
  async setPart(fields, mode) {
    let method = mode === "create" ? "createTaskAssignment" : "editTaskAssignment";
    return await this.transformer.setFormPart(this.Api, method, fields);
  }
  async set(mode) {
    let method = mode === "create" ? "createTaskAssignment" : "editTaskAssignment";
    return await this.transformer.setForm(this.Api, method);
  }
  async grid(args) {
    let role = JSON.parse(localStorage.getItem("userRole")).systemName;
    const res = await this.transformer.getGrid(this.Api, "getTaskAssignments", { args, role, assignOptions: this.assignOptions });
    res.data.records = res.data.records.map(i => {
      i.objStatus = this.statuses.find(s => s.value === i.status);
      return i
    })
    return res
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getTaskAssignment", null, id);
  }
  async getFull(id, role) {
    return await this.transformer.getGridPanel(this.Api, "getTaskAssignmentFull", null, {id, role});
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createTaskAssignment", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteTaskAssignment", model);
  }
  async deleteSeries(model) {
    return await this.transformer.deleteItem(this.Api, "deleteTaskAssignmentSeries", model);
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "editTaskAssignment", model);
  }
  async updateSeries(model) {
    return await this.transformer.editItem(this.Api, "editTaskAssignmentSeries", model);
  }
  async getDistributors(filter) {
    return await this.transformer.getGrid(this.Api, "getDistributors", filter);
  }
  async nextStep(model) {
    return await this.transformer.createItem(this.Api, "nextStep", model);
  }
  async rejectStep(model) {
    return await this.transformer.createItem(this.Api, "rejectStep", model);
  }
  async validateUsersExists(model) {
    return await this.transformer.getItem(this.Api, "validateUsersExists", model);
  }
  async validateAuthorizedPersonsExists(model) {
    return await this.transformer.getItem(this.Api, "validateAuthorizedPersonsExists", model);
  }
  async addComment(model) {
    return await this.transformer.createItem(this.Api, "addComment", model);
  }
  async getStatuses() {
    const data = { records: this.statuses }
    return Promise.resolve( { data })
  }
  async export(filter, format, assignOptions) {
    let role = JSON.parse(localStorage.getItem("userRole")).systemName;
    const res = this.Api.export(filter, role, format, assignOptions);
    await store.dispatch('notifications/handleResponse', {
      promiseResponse: res,
    })
  }
  getStatusByValue(status) {
    return this.statuses.find(s => s.value == status)
  }
  async getPositions() {
    return await this.transformer.toTree(this.Api, "getPositions", `$filter=taskAssignmentId eq ${this.taskAssignmentId} and disabled eq false`, "distributorOrgId");
  }
  async getAssignOptions() {
    const options = [
      { label: "На мою должность", value: "onPosition" },
      { label: "На подчиненных", value: "onSlave" },
      { label: "На филиал", value: "onFilial" },
      { label: "На Дистрибьютора", value: "onDistributor" },
    ]
    const data = {records: options}
    return Promise.resolve( { data })
  }
  setAssignOptions(options) {
    for (let key of Object.keys(this.assignOptions)) {
      if (options.includes(key)) 
        this.assignOptions[key] = true;
      else
        this.assignOptions[key] = false;
    }
  }
  clearAssignOption() {
    for (let key of Object.keys(this.assignOptions))
      this.assignOptions[key] = false;
  }
}
