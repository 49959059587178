import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/competence/competencedirectory/";

export default class CompetenceDirectoryApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getCompetenceDirectories(params) {
    return await request.post(`${rootRoute}getCompetenceDirectories`, {
      filter: params,
      parameters: {},
    });
  }
  async getCompetenceDirectory(id) {
    return await request.post(`${rootRoute}getCompetenceDirectories`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createCompetenceDirectory(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createCompetenceDirectory`, { data });
  }
  async deleteCompetenceDirectory(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteCompetenceDirectory`, { data });
  }
  async editCompetenceDirectory(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateCompetenceDirectory`, { data });
  }
  async export(filter, role, format) {
    return await request.post(`${rootRoute}exportcompetencedirectory`, {
      filter: filter,
      role: role,
      format: format
    });
  }
}
