<template>
  <ProSpaceRightPanel
    :header="header"
    :loading="loading"
    :has-error="hasError"
    @clear="clearPanel"
    @edit="editPanel"
    @delete="deletePanel"
    @show-history="showHistoryPanel"
  >
    <template v-slot:center>
      <ProSpaceTabs :tabs="tabs" @select-tab="handlerSelectTab">
        <template v-slot:tab-1>
          <ProSpacePanelBlock v-if="newsItemTemplate" :header="headerOverview">
            <template v-slot>
              <div class="prospace-panel-block-list">
                <div class="prospace-panel-block-list__row">
                  <ProSpacePanelBlockItem
                    v-if="newsItemTemplate.typeName"
                    class="prospace-panel-block-list__item"
                    :title="$t('type')"
                    :text="newsItemTemplate.typeName"
                  />
                </div>
                <div class="prospace-panel-block-list__row">
                  <ProSpacePanelBlockItem
                    v-if="newsItemTemplate.message"
                    class="prospace-panel-block-list__item"
                    :title="$t('newsItemTemplateMessage')"
                    :text="
                      newsItemTemplate.message.replace(/(<([^>]+)>)/gi, ' ')
                    "
                  />
                </div>
                <div class="prospace-panel-block-list__row">
                  <ProSpacePanelBlockItem
                    v-if="newsItemTemplate.publishDate"
                    class="prospace-panel-block-list__item"
                    :title="$t('newsItemTemplatePublishDate')"
                    :text="new Date(newsItemTemplate.publishDate)"
                    :date-format="`${userinfo.dateFormat}`"
                    :time-format="`${userinfo.timeFormat}`"
                  />
                </div>
                <div class="prospace-panel-block-list__row">
                  <ProSpacePanelBlockItem
                    class="prospace-panel-block-list__item"
                    :title="$t('documentAttachment')"
                  >
                    <template #content>
                      <ProSpaceAction
                        v-for="(attachment, i) in successedAttachments"
                        icon-width="14px"
                        icon-height="14px"
                        icon="paperclip"
                        :key="i"
                        :text="attachment.fileName"
                        @click="downloadAttachment(attachment)"
                      />
                    </template>
                  </ProSpacePanelBlockItem>
                </div>
                <div class="prospace-panel-block-list__row">
                  <ProSpacePanelBlockItem
                    v-if="newsItemTemplate.tags"
                    class="prospace-panel-block-list__item"
                    :title="$t('tags')"
                  >
                    <template #content>
                      <ProSpaceTagsColumn :tags="tags" :limit="2" />
                    </template>
                  </ProSpacePanelBlockItem>
                </div>
              </div>
            </template>
          </ProSpacePanelBlock>
        </template>
      </ProSpaceTabs>
    </template>
    <template v-slot:bottom>
      <ProSpacePanelBlock :header="headerBottom">
        <template v-slot>
          <ProSpacePanelBlockList :list="contentBottom" />
        </template>
      </ProSpacePanelBlock>
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceAction,
  ProSpacePanelBlockItem,
  ProSpaceTagsColumn,
} from "prospace-components-library";
import { getAMorPM } from "prospace-components-library/src/lib-assets/mixins/date";
import { NewsItemTemplateService } from "../../../services/NewsItemTemplateService";
import { FileService } from "../../../../../../Frontends/ProSpace/services/FileService.js";
import { mapGetters } from "vuex";
import {
  useStatistic
} from "@composes"
import {
  useMultiSelectRightPanel,
} from "@composes/multiselect"
export default {
  name: "NewsItemTemplatePanel",
  components: {
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceAction,
    ProSpacePanelBlockItem,
    ProSpaceTagsColumn,
  },
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    checkAccess: {
      type: Function,
    }
  },
  setup() {
    const { selectedData } = useMultiSelectRightPanel()
    const { setStatistic, distActions } = useStatistic()
    return {
      setStatistic, distActions, selectedData
    }
  },
  data() {
    return {
      newsItemTemplate: null,
      newsItemTemplateService: new NewsItemTemplateService(),
      fileService: new FileService(),
      loading: false,
      hasError: false,
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime",
    }),
    successedAttachments() {
      return this.attachments.filter(x => x.statusFlag === 0);
    },
    attachments() {
      return this.newsItemTemplate.attachments ?? [];
    },
    headerOverview() {
      return {
        title: this.$t("newsItemTemplate"),
        isExpand: true,
      };
    },
    headerNewsItemTemplates() {
      return {
        title: this.$t("overview"),
        isExpand: false,
      };
    },
    headerBottom() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    tabs() {
      return [{ name: this.$t("overview"), isActive: true, systemName: "overview" }];
    },
    tags() {
      return this.newsItemTemplate.tags.split('#');
    },
    header() {
      if (this.newsItemTemplate) {
        return {
          title: this.newsItemTemplate.templateName,
          id: this.newsItemTemplate.id,
          status: this.getStatus(this.newsItemTemplate.status),
          options: this.options,
          iconHint: this.$t("exclamationHint"),
          showCounter: false,
        };
      }
      return {
        title: this.$t("itemsSelected"),
        showCounter: false,
      };
    },
    contentBottom() {
      let metaInfo = [];
      if (this.newsItemTemplate) {
        if(this.checkAccess("metainfo-by-block"))
          metaInfo.push([
            {
              title: this.$t("createdByTxt"),
              text: this.newsItemTemplate.createdBy,
            },
            {
              title: this.$t("updatedByTxt"),
              text: this.newsItemTemplate.updatedBy,
            },
          ]);

        metaInfo.push([
          {
            title: this.$t("createdAtTxt"),
            text: this.getFormatDateTime(this.newsItemTemplate.createdAt) + ' ' + getAMorPM(this.newsItemTemplate.createdAt, this.userinfo.timeFormat),
          },
          {
            title: this.$t("lastUpdatedTxt"),
            text: this.getFormatDateTime(this.newsItemTemplate.lastUpdated) + ' ' + getAMorPM(this.newsItemTemplate.lastUpdated, this.userinfo.timeFormat),
          },
        ]);
      }
      return metaInfo;
    },
  },
  mounted() {
    this.update();
  },
  watch: {
    selectedData() {
      this.update();
    },
  },
  methods: {
    handlerSelectTab({ selectedTab }) {
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: selectedTab.systemName
      })
    },
    update() {
      this.hasError = false;
      try {
        this.getNewsItemTemplate();
      } catch (e) {
        this.hasError = true;
      }
    },
    getNewsItemTemplate() {
      if (this.selectedData) {
        this.loading = true;
        this.newsItemTemplateService.get(this.selectedData.id).then((res) => {
          this.newsItemTemplate = res.data;
          this.loading = false;
        });
      }
      this.newsItemTemplate = null;
    },
    getStatus(status) {
      if (!status) return;
      return {
        type: status === "ACTIVE" ? "success" : "danger",
        text: status[0].toUpperCase() + status.toLowerCase().slice(1),
      };
    },
    clearPanel() {
      this.$emit("clear");
    },
    editPanel() {
      this.$emit("edit", this.newsItemTemplate);
    },
    deletePanel() {
      this.$emit("delete", this.newsItemTemplate);
    },
    showHistoryPanel() {
      this.$emit("history", this.newsItemTemplate);
    },
    downloadAttachment(attachment) {
      const gfid = attachment.gfid;

      this.fileService.getFileToken(gfid).then((response) => {
        if (response != null) {
          this.fileService
              .downloadFile(response.data)
              .then((fileResponse) => {
                if(!fileResponse)
                  return;

                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(fileResponse.data);
                let name = fileResponse.fileName;
                link.download = decodeURI(name).replace("%2b", "+");
                link.click();
              });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.prospace-panel-block-list {
  &__row {
    display: flex;
    column-gap: 10px;
    //border-bottom: 1px dashed var(--libBorder);

    &:last-child {
      border-bottom: none;
    }
    &:not(:first-child) {
      .prospace-panel-block-item {
        padding: 10px 0;
      }
    }
    &:first-child {
      .prospace-panel-block-item {
        padding: 0 0 10px;
      }
    }
  }
}
</style>
