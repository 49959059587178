import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/taskservice/taskassignmenttemplateattachment/";

export default class TaskAssignmentTemplateAttachmentApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getTaskAssignmentTemplateAttachments(params) {
    return await request.post(`${rootRoute}getTaskAssignmentTemplateAttachments`, {
      filter: params,
      parameters: {},
    });
  }
  async getTaskAssignmentTemplateAttachment(id) {
    return await request.post(`${rootRoute}getTaskAssignmentTemplateAttachments`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createTaskAssignmentTemplateAttachment(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createTaskAssignmentTemplateAttachment`, { data });
  }
  async deleteTaskAssignmentTemplateAttachment(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteTaskAssignmentTemplateAttachment`, { data });
  }
  async editTaskAssignmentTemplateAttachment(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateTaskAssignmentTemplateAttachment`, { data });
  }
  async setStatusTaskAssignmentTemplateAttachment(models) {
    const data = models;
    return await request.post(`${rootRoute}setStatusTaskAssignmentTemplateAttachment`, { data });
  }
}
