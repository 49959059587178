import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/information/usefullink/";

export default class UsefulLinkApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getUsefulLinks(params) {
    return await request.post(`${rootRoute}getUsefulLinks`, {
      filter: params,
      parameters: {},
    });
  }
  async getUsefulLinkBaseTypeViews(params) {
    return await request.post(`${rootRoute}getUsefulLinkBaseTypeViews`, {
      filter: params,
      parameters: {},
    });
  }
  async getUsefulLink(id) {
    return await request.post(`${rootRoute}getUsefulLinks`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createUsefulLink(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createUsefulLink`, { data });
  }
  async deleteUsefulLink(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteUsefulLink`, { data });
  }
  async editUsefulLink(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateUsefulLink`, { data });
  }
}
