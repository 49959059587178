import request from "../Frontends/Common/utils/request/requestMain";
const rootRoute = "securityadmin/restriction/";

export default class RestrictionApi {
  async getRestrictions(params) {
    return await request.post(`${rootRoute}getrestrictions`, {
        filter: params, 
        parameters: {}
    });
  }
  async createRestrictions(models) {
    return await request.post(`${rootRoute}createrestriction`, {
        data: models
    });
  }
  async deleteRestrictions(models) {
    return await request.post(`${rootRoute}deleterestriction`, {
      data: models
    });
  }
  async updateRestrictions(models) {
    return await request.post(`${rootRoute}updaterestriction`, {
        data: models
    });
  }
}
