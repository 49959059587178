import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/masterdata/anothercontactperson/";

export default class AnotherContactPersonApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getAnotherContactPersons(params) {
    return await request.post(`${rootRoute}getAnotherContactPersons`, {
      filter: params,
      parameters: {},
    });
  }
  async getAnotherContactPerson(id) {
    return await request.post(`${rootRoute}getAnotherContactPersons`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createAnotherContactPerson(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createAnotherContactPerson`, { data });
  }
  async deleteAnotherContactPerson(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteAnotherContactPerson`, { data });
  }
  async editAnotherContactPerson(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateAnotherContactPerson`, { data });
  }
  async export(filter, format) {
    return await request.post(`${rootRoute}exportAnotherContactPerson`, {
      filter: filter,
      format: format
    });
  }
}
