import localLogin from "../../../../Services/Security/Security.Frontend/views/Login/LocalLogin.vue";
import login from "../../../../Services/Security/Security.Frontend/views/Login/Login.vue";
import auth from "../../../../Services/Security/Security.Frontend/views/Auth/Auth.vue";

export const securityRoutes = [
  {
    path: "/",
    redirect: { name: "login" },
  },
  { path: "/login", redirect: { name: "login" } },
  {
    path: "/security/login",
    name: "login",
    component: login,
  },
  {
    path: "/security/auth",
    name: "auth",
    component: auth,
  },
  {
    path: "/security/locallogin",
    name: "locallogin",
    component: localLogin,
  },
];

export default securityRoutes;
