import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/UserInfoApi";
import store from "../../../../Frontends/Common/store"

export class UserInfoService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async get() {
    const res = await this.transformer.getItem(this.Api, "get");
    store.dispatch("userinfoStore/setUserinfo", res.data)
  }
}
