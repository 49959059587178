export class StatusService {
    constructor() {
      this.dataStatuses = [
        { text: "New", status: "NEW", type: "blue" },
        { text: "Complete", status: "COMPLETE", type: "success" },
        { text: "Skip", status: "SKIP", type: "default" },
        { text: "Error", status: "ERROR", type: "error" },
      ];
    }
    search(value) {
      return this.dataStatuses.filter((elem) => elem.status.indexOf(value) !== -1);
    }
    find(value) {
      var status = this.dataStatuses.find((elem) => elem.status === value)
      return status;
    }
    async get() {
      return Promise.resolve({ data: { records: this.dataStatuses } });
    }
  }
  