<template>
  <ProSpaceRightPanel
    :header="header"
    :loading="loading"
    :has-error="hasError"
    @clear="clearPanel"
    @edit="editPanel"
    @delete="deletePanel"
    @show-history="showHistoryPanel"
  >
    <template v-slot:center>
      <ProSpacePanelBlock :header="headerOverview">
        <template v-slot:header>
          <ProSpaceAction v-if="checkEditAccess(systemDirectory)" :text="$t('editItemTxt')" @click="onClickEdit"/>
        </template>
        <template v-slot>
          <ProSpacePanelBlockList :list="content" />
        </template>
      </ProSpacePanelBlock>
    </template>
    <template v-slot:bottom>
      <ProSpacePanelBlock :header="headerBottom">
        <template v-slot>
          <ProSpacePanelBlockList :list="contentBottom" />
        </template>
      </ProSpacePanelBlock>
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceAction
} from "prospace-components-library";
import { getAMorPM } from "prospace-components-library/src/lib-assets/mixins/date";
import { SystemDirectoryService } from "../../../services/SystemDirectoryService";
import { mapGetters } from "vuex";
import {
  useMultiSelectRightPanel,
} from "@composes/multiselect"
export default {
  name: "SystemDirectoryPanel",
  components: {
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceAction
  },
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    filterAccessDots: {
      type: Function,
    },
    checkEditAccess: {
      type: Function,
    },
    checkAccess: {
      type: Function,
    }
  },
  setup() {
    const { selectedData } = useMultiSelectRightPanel()
    return {
      selectedData
    }
  },
  data() {
    return {
      systemdirectory: null,
      systemdirectoryService: new SystemDirectoryService(),
      loading: false,
      hasError: false,
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime",
    }),
    headerOverview() {
      return {
        title: this.$t("parameters"),
        isExpand: false,
      };
    },
    headerBottom() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    header() {
      if (this.systemdirectory) {
        return {
          title: this.systemdirectory.name,
          id: this.systemdirectory.id,
          options: this.getAccessDots(this.options),
          showCounter: false,
        };
      }
      return {
        title: this.$t("itemsSelected"),
        showCounter: false,
      };
    },
    content() {
      if (this.systemdirectory) {
        return [
          [
            {
              title: this.$t("name"),
              text: this.systemdirectory.name,
            },
          ],
        ];
      }
      return [];
    },
    contentBottom() {
      let metaInfo = [];
      if (this.systemdirectory) {
        if(this.checkAccess("metainfo-by-block"))
          metaInfo.push([
            {
              title: this.$t("createdByTxt"),
              text: this.systemdirectory.createdBy,
            },
            {
              title: this.$t("updatedByTxt"),
              text: this.systemdirectory.updatedBy,
            },
          ]);

        metaInfo.push([
          {
            title: this.$t("createdAtTxt"),
            text: this.getFormatDateTime(this.systemdirectory.createdAt) + ' ' + getAMorPM(this.systemdirectory.createdAt, this.userinfo.timeFormat),
          },
          {
            title: this.$t("lastUpdatedTxt"),
            text: this.getFormatDateTime(this.systemdirectory.lastUpdated) + ' ' + getAMorPM(this.systemdirectory.lastUpdated, this.userinfo.timeFormat),
          },
        ]);
      }
      return metaInfo;
    },
  },
  mounted() {
    this.update();
  },
  methods: {
    getAccessDots(options) {
      return this.filterAccessDots ? this.filterAccessDots(options, this.systemdirectory) : options;
    },
    onClickEdit() {
      this.$emit("edit", this.systemdirectory)
    },
    update() {
      this.hasError = false;
      try {
        this.getSystemDirectory();
      } catch (e) {
        this.hasError = true;
      }
    },
    getSystemDirectory() {
      if (this.selectedData) {
        this.loading = true;
        this.systemdirectoryService.get(this.selectedData.id).then((res) => {
          this.systemdirectory = res.data;
          this.loading = false;
        });
      }
      this.systemdirectory = null;
    },
    clearPanel() {
      this.$emit("clear");
    },
    editPanel() {
      this.$emit("edit", this.systemdirectory);
    },
    deletePanel() {
      this.$emit("delete", this.systemdirectory);
    },
    showHistoryPanel() {
      this.$emit("history", this.systemdirectory);
    },
  },
  watch: {
    selectedData: {
      handler() {
        this.update();
      },
      deep: true
    },
  },
};
</script>

<style scoped></style>
