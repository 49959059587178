import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/TaskApi";
import FileApi from "../../../../Api/FileApi";

export class UserTaskService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
    this.FileApi = new FileApi();
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getUserTasks", args);
  }
  async allGrid(args) {
    return await this.transformer.getGrid(this.Api, "getSystemTasks", args);
  }
  async getPanel(id) {
    return await this.transformer.getGridPanel(this.Api, "getUserTask", null, id);
  }
  async get(id) {
    return await this.transformer.getItem(this.Api, "getUserTask", id);
  }
  async getAll(id) {
    return await this.transformer.getItem(this.Api, "getSystemTask", id);
  }
  async create(task) {
    return await this.transformer.createItem(this.Api, "createTask", task);
  }
  async runAgain(task) {
    return await this.transformer.editItem(this.Api, "runAgainTask", task);
  }
}
