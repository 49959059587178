<template>
  <ProSpaceRightPanel
    :header="header"
    :loading="loading"
    :has-error="hasError"
    @clear="clearPanel"
    @edit="editPanel"
    @delete="deletePanel"
    @show-history="showHistoryPanel"
  >
    <template v-slot:center>
      <ProSpacePanelBlock v-if="taskAssignmentTemplate" :header="headerTaskAssignmentTemplates">
        <template #header>
          <ProSpaceAction v-if="!taskAssignmentTemplate.disabled" :text="$t('editItemTxt')" @click="editPanel" />
        </template>
        <template v-slot>
          <div class="prospace-panel-block-list">
            <div class="prospace-panel-block-list__row">
              <ProSpacePanelBlockItem
                class="prospace-panel-block-list__item"
                :title="$t('taskAssignmentTemplateTemplateName')"
                :text="taskAssignmentTemplate.templateName"
              />
            </div>
            <div class="prospace-panel-block-list__row">
              <ProSpacePanelBlockItem
                class="prospace-panel-block-list__item"
                :title="$t('taskAssignmentTemplateName')"
                :text="taskAssignmentTemplate.name"
              />
            </div>
            <div class="prospace-panel-block-list__row">
              <ProSpacePanelBlockItem
                class="prospace-panel-block-list__item"
                :title="$t('taskAssignmentTemplateType')"
                :text="taskAssignmentTemplate.typeName"
              />
            </div>
            <div class="prospace-panel-block-list__row">
              <ProSpacePanelBlockItem
                class="prospace-panel-block-list__item"
                :title="$t('taskAssignmentTemplateStartAt')"
                :text="this.taskAssignmentTemplate.startAt ? new Date(this.taskAssignmentTemplate.startAt) : null"
                :date-format="`${userinfo.dateFormat}`"
              />
            </div>
            <div class="prospace-panel-block-list__row">
              <ProSpacePanelBlockItem
                class="prospace-panel-block-list__item"
                :title="$t('taskAssignmentTemplateEndAt')"
                :text="this.taskAssignmentTemplate.endAt ? new Date(this.taskAssignmentTemplate.endAt) : null"
                :date-format="`${userinfo.dateFormat}`"
              />
            </div>
            <div class="prospace-panel-block-list__row">
              <ProSpacePanelBlockItem
                class="prospace-panel-block-list__item"
                :title="$t('documentAttachment')"
              >
                <template #content>
                  <ProSpaceAction
                    v-for="(attachment, i) in successedAttachments"
                    icon-width="14px"
                    icon-height="14px"
                    icon="paperclip"
                    :key="i"
                    :text="attachment.fileName"
                    @click="downloadAttachment(attachment)"
                  />
                </template>
              </ProSpacePanelBlockItem>
            </div>
            <div class="prospace-panel-block-list__row">
              <ProSpacePanelBlockItem
                class="prospace-panel-block-list__item"
                :title="$t('tags')"
              >
                <template #content>
                  <ProSpaceTagsColumn :tags="tags" :limit="2" />
                </template>
              </ProSpacePanelBlockItem>
            </div>
          </div>
        </template>
      </ProSpacePanelBlock>
    </template>
    <template v-slot:bottom>
      <ProSpacePanelBlock :header="headerBottom">
        <template v-slot>
          <ProSpacePanelBlockList :list="contentBottom" />
        </template>
      </ProSpacePanelBlock>
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpacePanelBlockItem,
  ProSpaceTagsColumn,
  ProSpaceAction,
} from "prospace-components-library";
import { getAMorPM } from "prospace-components-library/src/lib-assets/mixins/date";
import { TaskAssignmentTemplateService } from "../../../services/TaskAssignmentTemplateService";
import { FileService } from "../../../../../../Frontends/ProSpace/services/FileService.js";
import { mapGetters } from "vuex";
import {
  useMultiSelectRightPanel,
} from "@composes/multiselect"
export default {
  name: "TaskAssignmentTemplatePanel",
  components: {
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpacePanelBlockItem,
    ProSpaceTagsColumn,
    ProSpaceAction,
  },
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    checkAccess: {
      type: Function,
    }
  },
  data() {
    return {
      taskAssignmentTemplate: null,
      taskAssignmentTemplateService: new TaskAssignmentTemplateService(),
      fileService: new FileService(),
      loading: false,
      hasError: false,
    };
  },
  setup() {
    const { selectedData } = useMultiSelectRightPanel()
    return {
      selectedData
    }
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime"
    }),
    successedAttachments() {
      return this.attachments.filter(x => x.statusFlag === 0);
    },
    tags() {
      return this.taskAssignmentTemplate.tags
        ? this.taskAssignmentTemplate.tags.split("#")
        : [];
    },
    attachments() {
      return this.taskAssignmentTemplate.attachments ?? [];
    },
    headerTaskAssignmentTemplates() {
      return {
        title: this.$t("overview"),
        isExpand: false,
      };
    },
    headerBottom() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    tabs() {
      return [{ name: this.$t("overview"), isActive: true }];
    },
    header() {
      if (this.taskAssignmentTemplate) {
        return {
          title: this.taskAssignmentTemplate.templateName,
          id: this.taskAssignmentTemplate.id,
          status: this.getStatus(this.taskAssignmentTemplate.status),
          iconHint: this.$t("repeatHint"),
          options: this.options,
          showCounter: false,
        };
      }
      return {
        title: this.$t("itemsSelected"),
        showCounter: false,
      };
    },
    content() {
      if (this.taskAssignmentTemplate) {
        return [
          [
            {
              title: this.$t("taskAssignmentTemplateTemplateName"),
              text: this.taskAssignmentTemplate.templateName,
            },
          ],
          [
            {
              title: this.$t("taskAssignmentTemplateName"),
              text: this.taskAssignmentTemplate.name,
            },
          ],
          [
            {
              title: this.$t("taskAssignmentTemplateType"),
              text: this.taskAssignmentTemplate.typeName,
            },
          ],
          [
            {
              title: this.$t("taskAssignmentTemplateStartAt"),
              text: this.taskAssignmentTemplate.startAt ? new Date(this.taskAssignmentTemplate.startAt) : null,
              dateFormat: this.userinfo.dateFormat,
            },
          ],
          [
            {
              title: this.$t("taskAssignmentTemplateEndAt"),
              text: this.taskAssignmentTemplate.endAt ? new Date(this.taskAssignmentTemplate.endAt) : null,
              dateFormat: this.userinfo.dateFormat,
            },
          ],
          [
            {
              title: this.$t("taskAssignmentTemplateTags"),
              text: this.taskAssignmentTemplate.tags,
            },
          ],
        ];
      }
      return [];
    },
    itemsPerPage() {
      return this.content ? this.content.length : 1;
    },
    contentBottom() {
      let metaInfo = [];
      if (this.taskAssignmentTemplate) {
        if(this.checkAccess("metainfo-by-block"))
          metaInfo.push([
            {
              title: this.$t("createdByTxt"),
              text: this.taskAssignmentTemplate.createdBy,
            },
            {
              title: this.$t("updatedByTxt"),
              text: this.taskAssignmentTemplate.updatedBy,
            },
          ]);

        metaInfo.push([
          {
            title: this.$t("createdAtTxt"),
            text: this.getFormatDateTime(this.taskAssignmentTemplate.createdAt) + ' ' + getAMorPM(this.taskAssignmentTemplate.createdAt, this.userinfo.timeFormat),
          },
          {
            title: this.$t("lastUpdatedTxt"),
            text: this.getFormatDateTime(this.taskAssignmentTemplate.lastUpdated) + ' ' + getAMorPM(this.taskAssignmentTemplate.lastUpdated, this.userinfo.timeFormat),
          },
        ]);
      }
      return metaInfo;
    },
  },
  mounted() {
    this.update();
  },
  watch: {
    selectedData() {
      this.update();
    },
  },
  methods: {
    downloadAttachment(attachment) {
      const gfid = attachment.gfid;

      this.fileService.getFileToken(gfid).then((response) => {
        if (response != null) {
          this.fileService
            .downloadFile(response.data)
            .then((fileResponse) => {
              if(!fileResponse)
                return;

              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(fileResponse.data);
              let name = fileResponse.fileName;
              link.download = decodeURI(name).replace("%2b", "+");
              link.click();
            });
        }
      });
    },
    update() {
      this.hasError = false;
      try {
        this.getTaskAssignmentTemplate();
      } catch (e) {
        this.hasError = true;
      }
    },
    getTaskAssignmentTemplate() {
      if (this.selectedData) {
        this.loading = true;
        this.taskAssignmentTemplateService.get(this.selectedData.id).then((res) => {
          this.taskAssignmentTemplate = res.data;
          this.loading = false;
        });
      }
      this.taskAssignmentTemplate = null;
    },
    getStatus(status) {
      if (!status) return;
      return {
        type: status === "ACTIVE" ? "success" : "danger",
        text: status[0].toUpperCase() + status.toLowerCase().slice(1),
      };
    },
    clearPanel() {
      this.$emit("clear");
    },
    editPanel() {
      this.$emit("edit", this.taskAssignmentTemplate);
    },
    deletePanel() {
      this.$emit("delete", this.taskAssignmentTemplate);
    },
    showHistoryPanel() {
      this.$emit("history", this.taskAssignmentTemplate);
    },
  },
};
</script>

<style lang="scss" scoped>
.prospace-panel-block-list {
  &__row {
    display: flex;
    column-gap: 10px;
    //border-bottom: 1px dashed var(--libBorder);

    &:last-child {
      border-bottom: none;
    }
    &:not(:first-child) {
      .prospace-panel-block-item {
        padding: 10px 0;
      }
    }
    &:first-child {
      .prospace-panel-block-item {
        padding: 0 0 10px;
      }
    }
  }
}
</style>
