import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/information/faq/";

export default class FaqApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getFaqs(params) {
    return await request.post(`${rootRoute}getFaqs`, {
      filter: params,
      parameters: {},
    });
  }
  async getFaqBaseTypeViews(params) {
    return await request.post(`${rootRoute}getFaqBaseTypeViews`, {
      filter: params,
      parameters: {},
    });
  }
  async getFaq(id) {
    return await request.post(`${rootRoute}getFaqs`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createFaq(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createFaq`, { data });
  }
  async deleteFaq(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteFaq`, { data });
  }
  async editFaq(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateFaq`, { data });
  }
  async export(filter, format) {
    return await request.post(`${rootRoute}exportFaq`, {
      filter: filter,
      format: format
    });
  }
  async setStatusFaqAttachment(models) {
    const data = models;
    return await request.post(`${rootRoute}setStatusFaqAttachment`, { data });
  }
}
