import request from "../Frontends/Common/utils/request/requestMain";
const rootRoute = "securityadmin/securityusecaseclaim/";

export default class ApiSecurityUseCaseClaim {
  async getClaim(id) {
    return await request.post(`${rootRoute}getsecurityusecaseclaims`, {
      filter: `$filter=securityusecaseId eq ${id} and disabled eq false`,
      parameters: {},
    });
  }
  async getClaimGrid(params, id) {
    return await request.post(`${rootRoute}getsecurityusecaseclaims`, {
      filter: `$filter=securityusecaseId eq ${id} and disabled eq false`,
      parameters: {},
    });
  }
  async deleteClaim(model) {
    return await request.post(`${rootRoute}deletesecurityusecaseclaim`, {
      data: model,
    });
  }
  async createClaim(model) {
    const data = model;
    return await request.post(`${rootRoute}createsecurityusecaseclaim`, {
      data,
    });
  }
}
