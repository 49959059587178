export class StatusService {
  constructor() {
    this.dataStatuses = [
      { text: "Active", isActive: true },
      { text: "Not active", isActive: false },
    ];
  }
  search(value) {
    return this.dataStatuses.filter((elem) => elem.text.indexOf(value) !== -1);
  }
  async get() {
    return Promise.resolve({ data: { records: this.dataStatuses } });
  }
}
