import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/reserveservice/bbdproduct/";

export default class BBDProductApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getBBDProducts(params) {
    return await request.post(`${rootRoute}getBBDProducts`, {
      filter: params,
      parameters: {},
    });
  }
  async getBBDProduct(id) {
    return await request.post(`${rootRoute}getBBDProducts`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async getShipFrom(filter) {
    return await request.post(`${rootRoute}getShipFrom`, {
      filter: filter,
      parameters: {},
    });
  }
  async createBBDProduct(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createBBDProduct`, { data });
  }
  async deleteBBDProduct(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteBBDProduct`, { data });
  }
  async editBBDProduct(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateBBDProduct`, { data });
  }
}
