import faqs from "../../../../Services/Information/InformationService.Frontend/views/Faqs/Faqs";
import faq from "../../../../Services/Information/InformationService.Frontend/views/Faqs/Faq";

import usefullinks from "../../../../Services/Information/InformationService.Frontend/views/UsefulLinks/UsefulLinks";
import usefullink from "../../../../Services/Information/InformationService.Frontend/views/UsefulLinks/UsefulLink";

import home from "../../../../Services/Information/InformationService.Frontend/views/Home/Home";

import comingSoon from "../../components/ComingSoon.vue";

export const informationRoutes = [
  {
    path: "/information/faqs",
    name: "faqs",
    component: faqs,
  },
  {
    path: "/information/faq",
    name: "faq-create",
    component: faq,
    meta: {
      menuitem: "/information/faqs",
    },
  },
  {
    path: "/information/faq/:id",
    name: "faq",
    component: faq,
    meta: {
      menuitem: "/information/faqs",
    },
  },
  {
    path: "/information/usefullinks",
    name: "usefullinks",
    component: usefullinks,
  },
  {
    path: "/information/usefullink",
    name: "usefullink-create",
    component: usefullink,
    meta: {
      menuitem: "/information/usefullinks",
    },
  },
  {
    path: "/information/usefullink/:id",
    name: "usefullink",
    component: usefullink,
    meta: {
      code: "usefullink",
      menuitem: "/information/usefullinks",
    },
  },
  {
    path: "/information/home",
    name: "home",
    component: home,
  },
  {
    path: "/information/coming-soon",
    name: "comingsoon",
    component: comingSoon,
  },
];

export default informationRoutes;
