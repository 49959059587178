import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/MasterData/FilialStructureUsersViewApi";
import store from "../../../../Frontends/Common/store";

export class FilialStructureUsersViewService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getFilialStructureUsersViews", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getFilialStructureUsersView", null, id);
  }
  async export(filter, format) {
    let role = JSON.parse(localStorage.getItem("userRole")).systemName;
    const res = this.Api.export(filter, role, format);
    await store.dispatch('notifications/handleResponse', {
      promiseResponse: res,
    })
    return res;
  }
}
