import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/NotificationApi";

export class NotificationService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(
      this.Api,
      "updateNotifications",
      fields
    );
  }
  async set() {
    return await this.transformer.setForm(this.Api, "updateNotifications"); 
  }
  async setModel(model) {
    let old = {...this.transformer.model};
    this.transformer.model = model;
    let r = await this.transformer.setForm(this.Api, "updateNotifications"); 
    this.transformer.model = old;
    return r;
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getNotifications", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getNotification", null, id);
  }
  async localGet(id) {
    return await this.transformer.localGetById(id);
  }
  async edit(model) {
    return await this.transformer.editItem(this.Api, "updateNotifications", model);
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createNotifications", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteNotifications", model);
  }
  async getEvents(args) {
    return await this.transformer.getGrid(this.Api, "getNotifyEvents", args);
  }
}
