import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/newsservice/newsitemtemplate/";

export default class NewsItemTemplateApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getNewsItemTemplates(params) {
    return await request.post(`${rootRoute}getNewsItemTemplates`, {
      filter: params,
      parameters: {},
    });
  }
  async getNewsItemTemplate(id) {
    return await request.post(`${rootRoute}getNewsItemTemplates`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createNewsItemTemplate(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createNewsItemTemplate`, { data });
  }
  async deleteNewsItemTemplate(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteNewsItemTemplate`, { data });
  }
  async editNewsItemTemplate(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateNewsItemTemplate`, { data });
  }
  async getPositions(filter) {
    return await request.post(`${rootRoute}getNewsItemTemplatePositions`, {
      filter: filter,
      parameters: {},
    });
  }
}
