<template>
  <ProSpaceRightPanel
    ref="panel"
    :header="header"
    v-if="items.length > 0 || product"
    :loading="loading"
  >
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpacePanelBlock :header="headerBlockProduct" v-if="items.length > 0">
            <template v-slot:header>
              <ProSpaceAction
                :text="$t('clear')"
                @click="handlerClear"
              />
            </template>
          </ProSpacePanelBlock>
        </template>
        <template #center>
          <ProSpacePanelBlock :header="emptyHeader" v-if="items.length > 0">
            <ProSpaceVLayout :gap="20">
              <ProSpaceSimpleGrid
                ref="grid"
                :headers="headerGrid"
                :items="items"
                :limit="10000"
              />
            </ProSpaceVLayout>
            <ProSpaceButton
              v-if="!showFixedCreateButton"
              style="width: 100%"
              icon="cart-shop"
              type="secondary"
              :label="$t('reserveCreatingReserve')"
              :disabled="disabledCreateReserve"
              @click="actionCreateReserve"
            />
          </ProSpacePanelBlock>
          <ProSpacePanelBlock :header="headerBlockProduct" v-if="product && items.length === 0">
            <template v-slot>
              <ProSpacePanelBlockList
                :list="contentGeneralInfo"
                :showViewMore="false"
              />
            </template>
          </ProSpacePanelBlock>
        </template>
        <template #bottom>
          <ProSpaceButton
            v-if="items.length > 0 && showFixedCreateButton"
            style="width: 100%"
            icon="cart-shop"
            type="secondary"
            :label="$t('reserveCreatingReserve')"
            :disabled="disabledCreateReserve"
            @click="actionCreateReserve"
          />
        </template>
      </ProSpaceGeoLayout>

    </template>
    <template #bottom>
      <ProSpacePanelBlock :header="headerBottom" v-if="items.length === 0">
        <template v-slot>
          <ProSpacePanelBlockList :list="contentBottom" />
        </template>
      </ProSpacePanelBlock>
    </template>
  </ProSpaceRightPanel>
</template>
<script>
import {
  ProSpaceRightPanel,
  ProSpacePanelBlock,
  ProSpacePanelBlockItem,
  ProSpaceSimpleGrid,
  ProSpaceVLayout,
  ProSpaceButton,
  ProSpaceAction,
  ProSpacePanelBlockList,
  ProSpaceGeoLayout,
} from "prospace-components-library";
import { mapGetters } from "vuex";
import { getAMorPM } from "prospace-components-library/src/lib-assets/mixins/date";
export default {
  components: {
    ProSpaceRightPanel,
    ProSpacePanelBlock,
    ProSpacePanelBlockItem,
    ProSpaceSimpleGrid,
    ProSpaceVLayout,
    ProSpaceButton,
    ProSpaceAction,
    ProSpacePanelBlockList,
    ProSpaceGeoLayout,
  },
  name: "ReserveCreationRightPanel",
  props: {
    items: {
      type: Array,
      required: true
    },
    selectedProduct: {
      type: Object,
      default: () => {}
    },
    countValidationError: {
      type: Boolean
    },
    loading: {
      type: Boolean
    }
  },
  data() {
    return {
      product: null,
      showFixedCreateButton: false
    };
  },
  watch: {
    selectedProduct(val) {
      this.product = val?.id ? val : null;
    },
    items(val) {
      if (val && val.length > 0) {
        this.checkTableHeight();
      }
    }
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDate: "userinfoStore/getFormatDate",
      getFormatDateTime: "userinfoStore/getFormatDateTime",
    }),
    header() {
      if (this.items.length) {
        return {
          title: this.$t("itemsSelected"),
          counter: this.items.length,
          showCounter: true,
        };
      } else {
        return {
          title: this.product.description,
          id: this.product.grd
        };
      }
    },
    emptyHeader() {
      return {}
    },
    headerBlockProduct() {
      if (this.items.length) {
        return {
          title: this.$t("reserveReservingProducts"),
          counter: false,
          isExpand: false,
        }
      } else {
        return {
          title: this.$t("reserveBaseInfo"),
          counter: false,
          isExpand: false,
        };
      }
    },
    headerGrid() {
      return [
        {name: this.$t('bbdProductGRD'), value: 'grd', f: v => v, width: '65px'},
        {name: this.$t('bbdProductDescription'), value: 'description', f: v => v, width: '120px'},
        {name: this.$t('reserveCountShort'), value: 'count', f: v => v, width: '40px'},
      ];
    },
    disabledCreateReserve() {
      return this.items.some(i => i.count > i.availableQuantity)
    },
    contentGeneralInfo() {
      if ('id' in this.product) {
        return [
          [{
            title: this.$t("reserveProduct"),
            text: this.product.description
          }],
          [{
            title: this.$t("reserveExpiredDate"),
            text: this.getFormatDate(this.product.bbd)
          }],
          [{
            title: this.$t("bbdProductDiscount"),
            text: `${this.product.discount}%`
          }],
          [{
            title: this.$t("bbdProductAvailableQuantity"),
            text: `${this.product.availableQuantity}`
          }],
          [{
            title: this.$t("bbdProductAnimotechLink"),
            text: "https://mars.animotech.ru/products",
            link: "https://mars.animotech.ru/products",
            headerOverflow: false
          }],
        ]
      }
      return [];
    },
    headerBottom() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    contentBottom() {
      let metaInfo = [];
      if (this.product) {
        metaInfo.push(
          [
            {
              title: this.$t("createdAtTxt"),
              text: this.getFormatDateTime(this.product.createdAt) + ' ' + getAMorPM(this.product.createdAt, this.userinfo.timeFormat),
            },
            {
              title: this.$t("lastUpdatedTxt"),
              text: this.getFormatDateTime(this.product.lastUpdated) + ' ' + getAMorPM(this.product.lastUpdated, this.userinfo.timeFormat),
            },
          ]
        );
      }
      return metaInfo;
    },
  },
  methods: {
    handlerClear() {
      this.$emit('clear');
    },
    actionCreateReserve() {
      this.$emit('create');
    },
    checkTableHeight() {
      const panelBottom = (
        this.$refs.panel
          ?.$el
          ?.querySelector('.prospace-geo-layout__center')
          ?.getBoundingClientRect().bottom
      )
        ?.toFixed();
      const gridBottom= (
        this.$refs.grid
        ?.$parent
        ?.$el
        ?.getBoundingClientRect().bottom
      )
        ?.toFixed();
      const threshold = 75;

      if ((Number(panelBottom) - Number(gridBottom)) <= threshold) {
        this.showFixedCreateButton = true;
      } else {
        this.showFixedCreateButton = false;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/main.scss";

.prospace-panel-block-list {
  @include right-panel-block-list;
}
:deep(.prospace-list-items-table .p-datatable-tbody > tr:not(.p-datatable-row-expansion) > td:last-child) {
  padding-top: 10px !important;
}
:deep(.p-datatable-thead > tr > th .p-column-title) {
  max-width: 100%;
}
</style>
