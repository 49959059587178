<template>
    <ProSpaceVLayout class="home-dashboard-item" :gap="10">
        <div class="text-size-21 text-bolder" :class="`text-color-${classColorText}`">
            {{ count }}
        </div>
        <div class="text-size-13 text-color-gray text-ellipsis" style="width: 100%;">
            {{ text }}
        </div>
        <div class="home-dashboard-item__icon-wrapper">
            <ProSpaceIcon :icon="icon" v-hint="iconHint" :class="`home-dashboard-item__icon--${classColorIcon}`" />
        </div>
    </ProSpaceVLayout>
</template>

<script>
import {
  ProSpaceVLayout,
  ProSpaceHLayout,
  ProSpaceIcon,
  Hint
} from "prospace-components-library";
export default {
    name: "HomeDashboardItem",
    components: {
        ProSpaceVLayout,
        ProSpaceHLayout,
        ProSpaceIcon,
    },
    directives: {
      hint: Hint
    },
    props: {
        count: {
            type: Number,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true
        },
        iconColor: {
            type: String,
            default: "blue",
            validator: (value) => {
                return ["blue", "red"].includes(value)
            },
        },
        iconHint: {
          type: String,
          default: ''
        }
    },
    computed: {
        classColorIcon() {
            return this.count === 0 ? 'gray' : this.iconColor
        },
        classColorText() {
            return this.count === 0 ? 'icon-gray' : 'main'
        }
    },
}
</script>

<style lang="scss">
.home-dashboard-item {
    position: relative;
    background: var(--prospace-ui-card);
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    &__icon {
        &-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 10px;
            top: 10px;
            width: 20px;
            height: 20px;
            background: var(--prospace-ui-gray-card-bg);
            padding: 3px;
            border-radius: 100px;
            .prospace-icon {
                min-width: 20px;
            }
        }
        &--blue {
            background: var(--prospace-chart-fact);
        }
        &--red {
            background: var(--prospace-ui-red);
        }
        &--gray {
            background: var(--prospace-ui-icon-gray);
        }
    }
}
</style>
