import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/BaseType/BaseTypeApi";

const icon_arr = [
  "burger",
  "dashboard",
  "promo",
  "marketing",
  "dots",
  "close",
  "plus",
  "chevron-left",
  "chevron-right",
  "chevron-down",
  "arrow-back",
  "lock",
  "bell",
  "search",
  "profile",
  "angle",
  "folder",
  "copy",
  "message-question",
  "message-warning",
  "message-close",
  "avatar",
  "avatar-small",
  "arrow-down",
  "arrow-up",
  "expand",
  "custom-filter",
  "info",
  "sort",
  "sort-up",
  "sort-down",
  "check",
  "double-check",
  "checkbox",
  "filter",
  "update",
  "upload",
  "info",
  "edit",
  "copy",
  "delete",
  "minus",
  "dot-loader",
  "circle-close",
  "line-and-point",
  "arrow-right",
  "sun",
  "moon",
  "chart",
  "gears",
  "boxes",
  "user",
  "save",
  "save-all",
  "circle-xmark",
  "circle-chevron-left",
  "circle-chevron-right",
  "again",
  "history",
  "message-success",
  "email",
  "sms",
  "file",
  "calendar",
  "download",
  "status-complete",
  "status-error",
  "status-accepted",
  "parameters",
  "upload-cloud",
  "warning",
  "warning-small",
  "dot",
  "pen",
  "message",
  "boxes",
  "coins",
  "cube",
  "gear",
  "house",
  "inbox",
  "list-check",
  "newspaper",
  "circle-exclamation",
  "paperclip",
  "bookmark-regular",
  "bookmark-solid",
  "ban",
  "support",
  "layer-group",
  "folder-tree",
  "paste",
  "info-outline",
  "question",
  "note-sticky",
  "dots-horizontal",
  "chains",
  "plus-loop",
  "minus-loop",
  "download-file",
  "print-file",
  "rotate-right-file",
  "rotate-left-file",
  "eye-close",
  "eye-open",
  "link-icon",
  "file-pdf",
  "flash-default",
  "flash-error",
  "flash-success",
  "flash-warning",
  "timeline-check",
  "timeline-info",
  "timeline-reject",
  "timeline-double-check",
  "user-plus",
];
export class BaseTypeService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(this.Api, "editBaseType", fields);
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editBaseType");
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getBaseTypes", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(
      this.Api,
      "getBaseType",
      null,
      id
    );
  }
  async tree(args) {
    let result = await this.transformer.getTree(this.Api, "getBaseTypes", args);
    const data = result.data.map((i) => {
      i.leaf = true;
      return i;
    });
    return Promise.resolve({ data });
  }
  async treeWithAll(args) {
    let result = await this.tree(args);
    if (result.data) {
      let all = [
        {
          id: 0,
          leaf: true,
          name: "Все",
          code: "all",
          unreadCounter: 0,
          icon: "note-sticky",
        },
      ];
      result.data = all.concat(result.data);
    }

    return result;
  }
  async treeWithAllWithoutIcon(args) {
    let result = await this.tree(args);
    if (result.data) {
      let all = [
        {
          id: 0,
          leaf: true,
          name: "Все",
          code: "all",
          unreadCounter: 0
        },
      ];
      result.data = all.concat(result.data);
    }

    return result;
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createBaseType", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteBaseType", model);
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "editBaseType", model);
  }
  async getIcons() {
    let icons = icon_arr.map((icon) => {
      return { label: icon, value: icon };
    });
    icons.splice(0, 0, { label: "default", value: "note-sticky" });
    const data = {
      records: icons,
    };
    return Promise.resolve({ data });
  }
}
