<template>
  <ProSpaceGeoLayout class="home">
    <template #top>
      <ProSpaceTopPanel :header="this.$t('portalDistr')" />
    </template>
    <template #center>
      <ProSpaceGeoLayout class="padding-10" style="min-width: 600px;" :class="{'home-breakpoint--1000': isBreakpoint1000}">
        <template #top>
          <ProSpaceHLayout :gap="10">
            <ProSpaceBlock
              :height="isBreakpoint1000 ? 140 : 80"
              width="calc(100% * (2 / 3) - 10px)"
              color="card"
              shadow
              radius
              margin="0"
              class="home__block-user-info"
            >
              <template v-if="loading.user">
                <ProSpaceHLayout align-items="flex-start"
                  :gap="15"
                  :class="{'padding-10': isBreakpoint1000}"
                >
                  <Skeleton shape="circle" width="60px" height="60px" />
                  <ProSpaceVLayout :gap="10" style="width: calc(100% - 80px); height: 60px;" justify-content="center">
                    <Skeleton height="15px" width="100%" style="max-width: 300px" />
                    <Skeleton height="15px" width="150px" />
                  </ProSpaceVLayout>
                </ProSpaceHLayout>
              </template>
              <template v-else>
                <ProSpaceSidebarFooter
                  class="home__user-info"
                  theme-txt=""
                  language-txt=""
                  :is-row="true"
                  :user="user"
                  :current-locale="currentLocale"
                  :current-theme="currentTheme"
                  :locale-options="localeOptions"
                  @changeLocale="changeLocale"
                  @changeTheme="changeTheme"
                  @logout="logout"
                  @clear-storage="handlerClearStorage"
                  :logout-txt="$t('sideBarLogoutTxt')"
                  :storage-txt="$t('sideBarStorageTxt')"
                >
                  <ProSpaceVLayout :gap="5" justify-content="center" :style="`width: ${ isBreakpoint1000 ? '100%' : 'calc(100% - 200px)'}`">
                    <div class="text-bold text-color-main text-size-18 text-ellipsis" style="width: 100%">
                      {{ user.orgLinkDistributorName || "ООО Марс" }}
                    </div>
                    <div class="text-color-gray text-size-16 text-ellipsis" style="width: 100%">
                      {{ user.orgLinkDistributorOrgName || user.orgLinkMarsOrgName }}
                    </div>
                  </ProSpaceVLayout>
                </ProSpaceSidebarFooter>
              </template>
            </ProSpaceBlock>
            <ProSpaceBlock class="home__dashboard-btns"
              :height="isBreakpoint1000 ? 140 : 80"
              width="calc(100% / 3)"
              color="card"
              shadow
              radius
              :margin="0"
            >
              <template v-if="loading.user">
                <ProSpaceHLayout :gap="15" justify-content="space-between"
                  :class="{'padding-10 padding-top-20 padding-bottom-20': isBreakpoint1000}"
                >
                  <Skeleton height="15px" />
                  <Skeleton height="15px" />
                </ProSpaceHLayout>
              </template>
              <template v-else>
                <ProSpaceHLayout :gap="10">
                  <HomeDashboardButton
                    style="min-width: calc(50% - 10px)"
                    icon="question"
                    :label="$t('instructions')"
                    @click="moveTo('faqs')"
                  />
                  <HomeDashboardButton
                    style="min-width: calc(50% - 10px)"
                    icon="info"
                    :label="$t('usefulLinksShort')"
                    @click="moveTo('usefullinks')"
                  />
                </ProSpaceHLayout>
              </template>
            </ProSpaceBlock>
          </ProSpaceHLayout>
        </template>
        <template #center>
          <ProSpaceHLayout :gap="10" align-items="flex-start" class="padding-top-10">
            <ProSpaceBlock
              width="calc(100% / 3 - 10px)"
              height="100%"
              color="card"
              overflow="hidden"
              shadow
              radius
              :margin="0"
            >
              <HomeDashboard
                :title="getTitleDashboard('news')"
                :count="menuitemsInfo.news?.notificationCount"
                :icon="menuitemsInfo.news?.icon"
                :hint="$options.HINTS.news"
                :loading="loading.dashboards || loading.menuitems"
              >
                <ProSpaceHLayout :gap="10">
                  <HomeDashboardItem
                    :count="dashboardInfo.highImportNewsCount"
                    :text="$t('highImportants')"
                    icon="circle-exclamation"
                    icon-color="red"
                    :icon-hint="$t('exclamationHint')"
                    @click="
                      moveTo('newsitems', { fastFilter: $t('highImportants'), leftTree: JSON.stringify(allNode) })
                    "
                  />
                  <HomeDashboardItem
                    :count="dashboardInfo.userImportNewsCount"
                    :text="$t('checkeds')"
                    icon="bookmark-solid"
                    icon-color="red"
                    :icon-hint="$t('bookmarkHint')"
                    @click="moveTo('newsitems', { fastFilter: $t('checkeds'), leftTree: JSON.stringify(allNode) })"
                  />
                </ProSpaceHLayout>
              </HomeDashboard>
              <ProSpaceBlock :padding="0" margin="10px 0" :height="`calc(100% - ${isBreakpoint1000 ? '230px': '150px'});`">
                <ProSpaceTree
                  ref="newsTree"
                  :service="baseTypeService"
                  searchName="name"
                  getNodeMethod="tree"
                  propKey="id"
                  propLabel="name"
                  counterField="unreadCounter"
                  :prop-parent-id="null"
                  :needLocalSearch="true"
                  :noMountedSelectNode="true"
                  :defaultFilter="{
                    systemDirectoryCode: { and: ['News Type'] },
                    disabled: { and: [false] },
                  }"
                  @loaded="updateUnreaded(unreadNewsNotifications, 'newsTree')"
                  @select="
                    (node) =>
                      moveTo('newsitems', { leftTree: JSON.stringify(node), fastFilter: $t('allTxt') })
                  "
                />
              </ProSpaceBlock>
            </ProSpaceBlock>
            <ProSpaceBlock
              width="calc(100% / 3 - 10px)"
              height="100%"
              color="card"
              overflow="hidden"
              shadow
              radius
              :margin="0"
            >
              <HomeDashboard
                :title="getTitleDashboard('tasks')"
                :count="menuitemsInfo.tasks?.notificationCount"
                :icon="menuitemsInfo.tasks?.icon"
                :hint="$options.HINTS.tasks"
                :loading="loading.dashboards || loading.menuitems"
              >
                <ProSpaceHLayout :gap="10">
                  <HomeDashboardItem
                    :count="dashboardInfo.publishedTasksCount"
                    :text="$t('unacted')"
                    icon="circle-exclamation"
                    icon-color="blue"
                    :icon-hint="$t('unfulfilledHint')"
                    @click="
                      moveTo('taskassignments', {
                        fastFilter: $t('taskPublishedTxt'), leftTree: JSON.stringify(allNode)
                      })
                    "
                  />
                  <HomeDashboardItem
                    :count="dashboardInfo.expiredTasksCount"
                    :text="$t('overdue')"
                    icon="due"
                    icon-color="red"
                    :icon-hint="$t('overdueHint')"
                    @click="
                      moveTo('taskassignments', {
                        fastFilter: $t('isExpiredTxt'), leftTree: JSON.stringify(allNode)
                      })
                    "
                  />
                </ProSpaceHLayout>
              </HomeDashboard>
              <ProSpaceBlock :padding="0" margin="10px 0" :height="`calc(100% - ${isBreakpoint1000 ? '230px': '150px'});`">
                <ProSpaceTree
                  ref="tasksTree"
                  :service="baseTypeService"
                  searchName="name"
                  getNodeMethod="tree"
                  propKey="id"
                  propLabel="name"
                  counterField="unreadCounter"
                  :prop-parent-id="null"
                  :needLocalSearch="true"
                  :noMountedSelectNode="true"
                  :defaultFilter="{
                    systemDirectoryCode: { and: ['Task type'] },
                    disabled: { and: [false] },
                  }"
                  @loaded="
                    updateUnreaded(unreadTasksNotifications, 'tasksTree')
                  "
                  @select="
                    (node) =>
                      moveTo('taskassignments', {
                        leftTree: JSON.stringify(node),
                        fastFilter: $t('allTxt')
                      })
                  "
                />
              </ProSpaceBlock>
            </ProSpaceBlock>
            <ProSpaceBlock
              width="calc(100% / 3)"
              height="100%"
              color="card"
              overflow="hidden"
              shadow
              radius
              :margin="0"
            >
              <HomeDashboard
                :title="getTitleDashboard('documents')"
                :count="menuitemsInfo.documents?.notificationCount"
                :icon="menuitemsInfo.documents?.icon"
                :hint="$options.HINTS.documents"
                :loading="loading.dashboards || loading.menuitems"
              >
                <ProSpaceHLayout :gap="10">
                  <HomeDashboardItem
                    :count="getDocumentsUnreaded()"
                    :text="$t('unreaded')"
                    icon="circle-exclamation"
                    icon-color="blue"
                    :icon-hint="$t('unreadHint')"
                    @click="
                      moveTo('documents', { fastFilter: $t('allTxt'), leftTree: JSON.stringify(allNode) })
                    "
                  />
                  <HomeDashboardItem
                    :count="dashboardInfo.userImportantDocumentsCount"
                    :text="$t('checkeds')"
                    icon="bookmark-solid"
                    icon-color="red"
                    :icon-hint="$t('bookmarkHint')"
                    @click="moveTo('documents', { fastFilter: $t('checkeds'), leftTree: JSON.stringify(allNode) })"
                  />
                </ProSpaceHLayout>
              </HomeDashboard>
              <ProSpaceBlock :padding="0" margin="10px 0" :height="`calc(100% - ${isBreakpoint1000 ? '230px': '150px'});`">
                <ProSpaceTree
                  ref="docsTree"
                  :service="baseTypeService"
                  searchName="name"
                  getNodeMethod="tree"
                  propKey="id"
                  propLabel="name"
                  counterField="unreadCounter"
                  :prop-parent-id="null"
                  :needLocalSearch="true"
                  :noMountedSelectNode="true"
                  :defaultFilter="{
                    systemDirectoryCode: { and: ['Types of documents'] },
                    disabled: { and: [false] },
                  }"
                  @loaded="updateUnreaded(unreadDocsNotifications, 'docsTree')"
                  @select="
                    (node) =>
                      moveTo('documents', { leftTree: JSON.stringify(node), fastFilter: $t('allTxt') })
                  "
                />
              </ProSpaceBlock>
            </ProSpaceBlock>
          </ProSpaceHLayout>
        </template>
      </ProSpaceGeoLayout>
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import {
  ProSpaceFilterPanel,
  ProSpaceHLayout,
  ProSpaceCustomFilterPanel,
  ProSpaceMultiSelect,
  ProSpaceMainGrid,
  ProSpaceDots,
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceIconButton,
  ProSpaceButton,
  ProSpaceTableHeaderCell,
  ProSpaceDefaultTextColumn,
  ProSpaceEmptyGrid,
  ProSpaceLeftPanel,
  ProSpaceTree,
  ProSpaceVLayout,
  ProSpaceFilterContainsChips,
  ProSpaceFilterDatesRange,
  ProSpaceDropdown,
  ProSpaceInputText,
  ProSpaceHaveFileColumn,
  ProSpaceSidebarFooter,
  ProSpaceBlock,
  ProSpaceTag,
} from "prospace-components-library";
import { mapGetters } from "vuex";
import { HomeService } from "../../services/HomeService.js";
import { BaseTypeService } from "../../../../BaseType/BaseTypeService.Frontend/services/BaseTypeService";
import UserService from "../../../../Security/Security.Frontend/services/UserService";
import MenuService from "../../../../../Frontends/ProSpace/services/MenuService";
import LocalesApi from "../../../../../Api/LocalesApi";
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import HomeDashboard from "./components/HomeDashboard";
import HomeDashboardItem from "./components/HomeDashboardItem";
import HomeDashboardButton from "./components/HomeDashboardButton";
import Skeleton from 'primevue/skeleton';
import {
  removeToken,
  removeRefreshToken,
  removeRedirectUrl,
} from "../../../../../Frontends/Common/utils/auth";
export default {
  mixins: [accessMixin],
  components: {
    ProSpaceFilterPanel,
    ProSpaceHLayout,
    ProSpaceCustomFilterPanel,
    ProSpaceMultiSelect,
    ProSpaceMainGrid,
    ProSpaceDots,
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceIconButton,
    ProSpaceButton,
    ProSpaceTableHeaderCell,
    ProSpaceDefaultTextColumn,
    ProSpaceEmptyGrid,
    ProSpaceLeftPanel,
    ProSpaceTree,
    ProSpaceVLayout,
    ProSpaceFilterContainsChips,
    ProSpaceFilterDatesRange,
    ProSpaceDropdown,
    ProSpaceInputText,
    ProSpaceHaveFileColumn,
    ProSpaceSidebarFooter,
    ProSpaceBlock,
    ProSpaceTag,
    HomeDashboard,
    HomeDashboardItem,
    HomeDashboardButton,
    Skeleton,
  },
  data() {
    return {
      homeService: new HomeService(),
      baseTypeService: new BaseTypeService(),
      menuService: new MenuService(),
      localesApi: new LocalesApi(),
      userService: new UserService(),

      dashboardInfo: {},
      menuitemsInfo: {},

      ro: null,
      loading: {
        user: true,
        dashboards: true,
        menuitems: true,
        newsTree: true,
        tasksTree: true,
        docsTree: true
      },
      breakpoint: null,
      user: {},
      currentLocale: "",
      localeOptions: [],
      allNode: {
        id: 0,
        key: 0,
        leaf: true,
        name: "Все",
        code: "all",
        unreadCounter: 0,
        icon: "note-sticky",
      },
      i18nLocale:
        localStorage.getItem("locale") != null
          ? localStorage.getItem("locale").toUpperCase()
          : this.$i18n.locale.toUpperCase(),
    };
  },
  HINTS: {
    news: 'Новость представляет собой отдельное сообщение с актуальной и важной информацией для Дистрибьютора. Публикуется ответственными лицами компании Марс. Все новости классифицируются по темам и располагаются в соответствующих разделах. В разделе "Все" доступны все новости',
    tasks:
      'Задача представляет собой отдельное задание, требующее ответных действий со стороны Дистрибьютора. Создается ответственными лицами компании Марс. Все задачи классифицируются по темам и располагаются в соответствующих разделах. В разделе "Все" доступны все задачи',
    documents:
      'Документ представляет собой официальную информацию, оформленную в установленном организацией порядке. Создается ответственными лицами компании Марс. Все документы классифицируются по темам и располагаются в соответствующих разделах. В разделе "Все" доступны все документы',
  },
  mounted() {
    this.getData();
    this.bindResizeObserver();
  },
  unmounted() {
    this.unbindResizeObserver();
  },
  watch: {
    lastChangedNotification: {
      handler(val) {
        if (val && val.messageData && val.messageData.Title === "News")
          this.updateUnreaded(this.unreadNewsNotifications, "newsTree");
        else if (val && val.messageData && val.messageData.Title === "Tasks")
          this.updateUnreaded(this.unreadTasksNotifications, "tasksTree");
        else if (
          val &&
          val.messageData &&
          val.messageData.Title === "Documents"
        )
          this.updateUnreaded(this.unreadDocsNotifications, "docsTree");
      },
    },
    "$i18n.locale": {
      handler(val) {
        this.currentLocale = val.toUpperCase()
      },
    },
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      currentTheme: "userinfoStore/getTheme",
      lastChangedNotification: "notifications/lastChanged",
      notificationMessages: "notifications/notificationMessages",
      getCounter: "notifications/getCounter",
    }),
    unreadNewsNotifications() {
      let newsNotifications = this.notificationMessages.filter(
        (n) => n.messageData.Title === "News"
      );
      return newsNotifications.filter((n) => n.readed === false);
    },
    unreadTasksNotifications() {
      let tasksNotifications = this.notificationMessages.filter(
        (n) => n.messageData.Title === "Tasks"
      );
      return tasksNotifications.filter((n) => n.readed === false);
    },
    unreadDocsNotifications() {
      let docsNotifications = this.notificationMessages.filter(
        (n) => n.messageData.Title === "Documents"
      );
      return docsNotifications.filter((n) => n.readed === false);
    },
    isBreakpoint1000() {
      return this.breakpoint <= 1000
    }
  },
  methods: {
    async getData() {
      this.loading = {
        user: true,
        dashboards: true,
        menuitems: true
      }
      this.getUser();
      await this.getLocales();
      this.loading.user = false
      await this.getDashboard();
      this.loading.dashboards = false
      await this.getMenuitems();
      this.loading.menuitems = false
    },
    getTitleDashboard(nameMenuitem) {
      const menuitem = this.menuitemsInfo[nameMenuitem];
      if (!menuitem) {
        return;
      }
      const languages = JSON.parse(menuitem.language);
      return languages[this.currentLocale];
    },
    getUser() {
      this.user = this.userinfo;
      this.currentLocale = this.i18nLocale;
    },
    async getLocales() {
      const res = await this.localesApi.get()
      this.localeOptions = res.data
    },
    async getDashboard() {
      const res = await this.homeService.getDashboard(-(new Date().getTimezoneOffset() / 60))
      this.dashboardInfo = res.data;
    },
    async getMenuitems() {
      const includesMenuitems = {
        news: "/newsservice",
        tasks: "/taskservice",
        documents: "/documentservice",
      };
      const res = await this.menuService.getMenu(this.user);
      if (!res) {
        return;
      }
      const data = res.data;
      const menuitems = {};
      Object.entries(includesMenuitems).forEach(([key, value]) => {
        const menuitem = data.find((i) => i.path.startsWith(value));
        if (menuitem) {
          menuitems[key] = menuitem;
        }
      });
      this.menuitemsInfo = menuitems;
    },
    bindResizeObserver() {
      this.ro = new ResizeObserver(this.handlerResize).observe(this.$el);
    },
    unbindResizeObserver() {
      if (!this.ro) {
        return
      }
      this.ro.unobserve(this.$el);
    },
    handlerResize() {
      this.breakpoint = this.$el.offsetWidth
    },
    handlerClearStorage() {
      const ignoredKeys = ['SecurityAdmin-Refresh-Token', 'SecurityAdmin-Redirect-Url', 'SecurityAdmin-Access-Token']
      Object.keys(localStorage)
        .filter(key => !ignoredKeys.includes(key))
        .forEach(key => localStorage.removeItem(key))
      window.location.reload()
    },
    changeLocale(locale) {
      this.$i18n.locale = locale.toLowerCase();
      this.currentLocale = locale;
    },
    changeTheme(theme) {
      this.$store.dispatch("userinfoStore/setTheme", theme);
    },
    logout() {
      this.userService.logout().then((res) => {
        removeToken();
        removeRefreshToken();
        removeRedirectUrl();
        if (res.data) window.location.href = res.data;
        else location.href = "/security/login";
      });
    },
    getDocumentsUnreaded() {
      return this.getCounter(this.unreadDocsNotifications)
    },
    updateUnreaded(unreadNotifications, tree) {
      this.loading[tree] = false

      let allNode = this.getAllNode(tree);
      if (allNode) allNode.unreadCounter = this.getCounter(unreadNotifications);
      let nodes = this.getRealNodes(tree);
      if (nodes)
        nodes.forEach((n) => {
          var c = unreadNotifications.filter(
            (un) => un.messageData.Record.Type === n.code
          ).length;
          unreadNotifications
            .filter((un) => un.messageData.UnreadInfo)
            .forEach((un) => {
              let nui = un.messageData.UnreadInfo.find(
                (ui) => ui.Type === n.code
              );
              if (nui) c += nui.Ids.length;
            });
          n.unreadCounter = c;
        });
    },
    getAllNode(tree) {
      if (this.$refs[tree] && this.$refs[tree].nodes)
        return this.$refs[tree].nodes.find((n) => n.id === 0);
    },
    getRealNodes(tree) {
      if (this.$refs[tree] && this.$refs[tree].nodes)
        return this.$refs[tree].nodes.filter((n) => n.id !== 0);
    },
    moveTo(route, params = null) {
      if (typeof params === "object")
        this.$router.push({ name: route, params: params });
      else this.$router.push({ name: route });
    },
  },
};
</script>
<style lang="scss">
.home {
  .prospace-tree .prospace-tree__right-counter {
    right: 0;
  }
  &__block-user-info {
    display: flex;
    align-items: center;
  }
  &__user-info {
    .prospace-avatar__img {
      width: 60px;
      height: 60px;
    }
    .prospace-sidebar-footer__info > .prospace-v-layout {
      flex-direction: row;
      width: calc(100% - 64px);
      align-items: center !important;
    }
    .prospace-switch-theme--dark.is-active {
      background: #fff !important;

      .prospace-switch-theme__icon {
        background-color: #cfd6e2 !important;
      }
    }
    .prospace-switch-theme--dark {
      background: inherit !important;

      .prospace-switch-theme__icon {
        background-color: #5b7692;
      }
    }

    .prospace-switch-theme--light {
      .prospace-switch-theme__icon {
        background-color: #b0bec5;
      }
    }

    .prospace-switch-theme--light.is-active {
      background-color: #2c3e50;

      .prospace-switch-theme__icon {
        background-color: #526b83 !important;
      }
    }
  }
  &-breakpoint {
    &--1000 {
      .home {
        &__user-info {
          .prospace-sidebar-footer__info > .prospace-v-layout {
            flex-direction: column !important;
            align-items: flex-start !important;
            gap: 20px !important;
          }
        }
        &__dashboard-btns {
          & > .prospace-h-layout {
            flex-direction: column !important;
            .home-dashboard-button {
              max-height: calc(50% - 5px);
            }
          }
        }
        &-dashboard {
          & > .prospace-h-layout:last-child {
            flex-direction: column !important;
          }
        }
      }
    }
  }
}
</style>
