import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/Information/UsefulLinkApi";

export class UsefulLinkService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(
      this.Api,
      "editUsefulLink",
      fields
    );
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editUsefulLink");
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getUsefulLinks", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(
      this.Api,
      "getUsefulLink",
      null,
      id
    );
  }
  async tree(args) {
    let result = await this.transformer.getTree(
      this.Api,
      "getUsefulLinkBaseTypeViews",
      args
    );
    const all = [{
      id: 0,
      name: "Все",
      code: "Все",
      icon: 'info',
      usefulLinkCount: result.data.reduce(function (acc, obj) {
        return acc + obj.usefulLinkCount;
      }, 0),
    }];
    result.data = all.concat(result.data);
    const data = result.data.map((i) => {
      i.leaf = true;
      return i;
    });
    return Promise.resolve({ data });
  }
  async create(model) {
    return await this.transformer.createItem(
      this.Api,
      "createUsefulLink",
      model
    );
  }
  async delete(model) {
    return await this.transformer.deleteItem(
      this.Api,
      "deleteUsefulLink",
      model
    );
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "editUsefulLink", model);
  }
}
