import { clearTokens } from "../auth";

export function getUrl(obj) {
  let url = "";
  if (obj) {
    if (obj.request && obj.request.responseURL) {
      url = obj.request.responseURL;
    } else if (obj.config && obj.config.url) {
      url = obj.config.url;
    }
  }
  return url;
}

export function handleRefreshTokensError(response, message) {
  clearTokens();
  let url = window.location.origin  + "/security/login";
  window.location.href = url;
  // TODO FIX INF RELOAD
  // let url =
  //   window.location.origin + window.location.pathname + "/security/login";
  // if (window.location.hash) {
  //   let redirectUrl = window.location.hash.split("#")[1];
  //   url += "?redirect=" + redirectUrl;
  // }
  // window.location.href = url;
  return Promise.resolve({});
}
