import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/taskservice/taskassignmenttemplate/";

export default class TaskAssignmentTemplateApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getTaskAssignmentTemplates(params) {
    return await request.post(`${rootRoute}getTaskAssignmentTemplates`, {
      filter: params,
      parameters: {},
    });
  }
  async getTaskAssignmentTemplate(id) {
    return await request.post(`${rootRoute}getTaskAssignmentTemplates`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createTaskAssignmentTemplate(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createTaskAssignmentTemplate`, { data });
  }
  async deleteTaskAssignmentTemplate(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteTaskAssignmentTemplate`, { data });
  }
  async editTaskAssignmentTemplate(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateTaskAssignmentTemplate`, { data });
  }
  async getPositions(filter) {
    return await request.post(`${rootRoute}getTaskAssignmentTemplatePositions`, {
      filter: filter,
      parameters: {},
    });
  }
}
