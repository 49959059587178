<template>
  <ProSpaceGeoLayout>
    <template #center>
      <ProSpaceMainGrid
        ref="grid"
        getMethod="grid"
        defaultSortField="id"
        :defaultSortOrder="-1"
        :service="jobTriggerService"
        :isHeadHide="true"
        :defaultFilter="defaultFilter"
        @selected-change="selectionChanged"
      >
        <template #columns>
          <Column style="min-width: 78%">
            <template #body="field">
              <ProSpaceTableHeaderCell
                :title="$t('repeat')"
                :description="getRepeatText(field.data)"
              />
            </template>
          </Column>
          <Column class="column-end column-end-30">
            <template #body="field">
              <ProSpaceVLayout align-items="flex-end" justify-content="center">
                <ProSpaceDots
                  :options="options"
                  @action="action => handleDots(options, action, field.data)"
                />
              </ProSpaceVLayout>
            </template>
          </Column>
        </template>
        <template #empty>
          <ProSpaceEmptyGrid
            icon="empty-grid"
            :title="$t('noDataTitle')"
            :text="$t('noDataTxt')"
          />
        </template>
      </ProSpaceMainGrid>
      <TriggerEditor
        ref="triggerEditor"
        :isJobWindow="true"
        @saved="addOrUpdateTrigger"
      />
      <TaskResult
        ref="taskResult"
      />
      <TaskLog
        ref="taskLog"
      />
    </template>
    <template #right>
      <SystemTriggerPanel
        v-if="selectedModel"
        ref="right-panel"
        :service="jobTriggerService"
        :id="selectedModel"
        class="task-editor-triggers__panel"
        @action="(action, trigger) => handleDots(options, action, trigger)"
        @editClick="(trigger) => showTriggerEditor(trigger)"
      />
    </template>
  </ProSpaceGeoLayout>
</template>

<script>
import {
  ProSpaceGeoLayout,
  ProSpaceMainGrid,
  ProSpaceTableHeaderCell,
  ProSpaceDots,
  ProSpaceEmptyGrid,
  ProSpaceVLayout
} from "prospace-components-library";
import { JobTriggerService } from "../../../../services/JobTriggerService.js";
import Column from "primevue/column";
import SystemTriggerPanel from "../SystemTriggerPanel";
import TriggerEditor from "../TriggerEditor";
import TaskResult from "../../../components/TaskResult";
import TaskLog from "../../../components/TaskLog";

export default {
  components: {
    ProSpaceGeoLayout,
    ProSpaceMainGrid,
    ProSpaceTableHeaderCell,
    ProSpaceDots,
    Column,
    SystemTriggerPanel,
    TriggerEditor,
    TaskResult,
    TaskLog,
    ProSpaceEmptyGrid,
    ProSpaceVLayout
  },
  props: {
    task: {
      type: Object,
      required: true,
      default: {}
    },
    isCreate: {
      type: Boolean,
      default: true
    },
    localTriggers: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      jobTriggerService: new JobTriggerService(),

      selectedModel: null,
    }
  },
  computed: {
    defaultFilter() {
      return {
        disabled: {and: [false]},
        parentId: { and: [this.task.id] },
        itemType: { and: ['Trigger'] }
      }
    },
    options() {
      return [
        { name: 'editTrigger', text: this.$t('editItemTxt'), icon: 'edit', method: this.showTriggerEditor },
        { name: 'deleteTrigger', text: this.$t('delete') + ' ' + this.$t('trigger'), icon: 'edit', method: this.delete }
      ]
    }
  },
  methods: {
    handleDots(dotsOptions, action, item) {
      var option = dotsOptions.find((o) => o.name === action);
      option.method(item);
    },
    // Add new trigger to grid, apply localId for update/delete operations
    addOrUpdateTrigger(model) {
      let me = this;
      const filterId = model.id ? "id" : "localId";
      let index = me.$refs.grid.records.findIndex(
        (i) => i[filterId] === model[filterId]
      );
      if (index != -1){
        me.$refs.grid.records[index] = model;
        if (me.selectedModel == model[filterId] ) {
          me.$refs["right-panel"].update();
        }
      } else {
        me.$refs.grid.records.push(model);
      }
      me.$refs.triggerEditor.close();
    },
    // Delete record from grid (delete not saved records by localId, old records by id)
    delete(model) {
      var me = this;
      const filterId = model.id ? "id" : "localId";
      me.$refs.grid.records = me.$refs.grid.records.filter(
        (i) => i[filterId] !== model[filterId]
      );
      if (me.selectedModel != null ) {
        if (me.selectedModel == model[filterId] )
          me.selectionChanged(null);
        else
          me.$refs["right-panel"].update();
      }
    },
    getRepeatText(trigger) {
      return this.jobTriggerService.getRepeatText(trigger);
    },
    selectionChanged(val) {
      this.selectedModel = val ? val.id ?? val.localId : null;
      this.$emit("selectTrigger", val);
    },
    showModalForm(name, item) {
      this.$refs[name].show(item);
    },
    showTriggerEditor(trigger) {
      let model = trigger
      ? _.cloneDeep(trigger)
      : {
        name: "Trigger",
        itemType: 'Trigger',
        parentId: this.task.id
       };

      this.showModalForm("triggerEditor", model);
    }
  }
};
</script>

<style lang="scss">
.task-editor-triggers__panel {
  margin-right: 10px;
  border-right: 1px solid #E0E7F4;
}
</style>
