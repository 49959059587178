import request from "../Frontends/Common/utils/request/requestMain";
const rootRoute = "securityadmin/securityuserrole/";

export default class SecurityUserRoleApi {
  async getRole(id) {
    return await request.post(`${rootRoute}getsecurityuserroles`, {
      filter: `$filter=securityUserId eq ${id} and disabled eq false`,
      parameters: {},
    });
  }
  async getGrid(params) {
    return await request.post(`${rootRoute}getsecurityuserroles`, {
      filter: params,
      parameters: {},
    });
  }
  async deleteRole(model) {
    const data = model;
    return await request.post(`${rootRoute}deletesecurityuserrole`, { data });
  }
  async createRole(model) {
    const data = model;
    return await request.post(`${rootRoute}createsecurityuserrole`, { data });
  }
}
