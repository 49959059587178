import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/documentservice/documentrecipient/";

export default class DocumentRecipientApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getDocuments(params) {
    return await request.post(`${rootRoute}getdocumentrecipients`, {
      filter: params,
    });
  }
  async getDocumentsFromView(params) {
    return await request.post(`${rootRoute}getdocumentrecipientsfromview`, {
      filter: params,
    });
  }
}
