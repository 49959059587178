<template>
  <ProSpaceRightPanel
    :header="header"
    :loading="loading"
    :has-error="hasError"
    @edit="editPanel"
    @resetPassword="resetPasswordPanel"
    @delete="deletePanel"
    @show-history="showHistoryPanel"
  >
    <template v-if="selectedCount === 1" v-slot:center>
      <ProSpaceTabs v-model="modelTab" :tabs="tabs" @select-tab="handlerSelectTab">
        <template v-slot:tab-1>
          <ProSpacePanelBlock :header="headerOverview">
            <template v-slot:header> </template>
            <template v-slot>
              <ProSpacePanelBlockList :list="content" :showViewMore="false" />
            </template>
          </ProSpacePanelBlock>
        </template>
        <template v-slot:tab-2>
          <ProSpacePanelBlock :header="headerDistributorStructure">
            <template v-slot:header>
            </template>
            <template v-slot>
              <ProSpaceSimpleGrid
                ref="simple-grid-distributor-structure"
                :items="distributorStructuresView"
                :headers="listHeadersDistributorStructure"
                :bordered="true"
                :defaultModal="false"
                :btnText="$t('viewMore')"
                @openModal="showDistrStructureModal"
              />
              <DistributorHierarchySelectModal
                v-model="visibleDistrStructureModal"
                getMethod="getUserViewTree"
                readOnly
                expandAll
                :titleModal="$t('securityUserDistributorStructureInfo')"
                :service="distributorStructureService"
                :checkedRows="user.distributorStructures"
              />
            </template>
          </ProSpacePanelBlock>
        </template>
      </ProSpaceTabs>
    </template>
    <template v-if="selectedCount === 1" v-slot:bottom>
      <ProSpacePanelBlock :header="headerBottom">
        <template v-slot>
          <ProSpacePanelBlockList :list="contentBottom" />
        </template>
      </ProSpacePanelBlock>
    </template>
    <template v-if="isMultipleView()" v-slot:center>
      <ProSpaceVLayout :gap="10">
        <PanelBlockSelectedItems :items="[
            { name: $t('code'), value: 'code', f: (v) => v },
            { name: $t('fio'), value: 'fullName', f: (v) => v },
          ]"
        />
        <ProSpacePanelBlock :header="headerMultiple">
          <ProSpaceVLayout :gap="15">
            <ProSpaceAction :text="$t('resetPasswordB')" @click="resetPasswordPanel" />
            <ProSpaceAction :text="$t('delete')" @click="deletePanel" />
          </ProSpaceVLayout>
        </ProSpacePanelBlock>
      </ProSpaceVLayout>
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceAction,
  ProSpaceSimpleGrid,
  ProSpaceVLayout,
} from "prospace-components-library";
import DistributorHierarchySelectModal from "@modals/DistributorHierarchySelectModal"
import { getAMorPM } from "prospace-components-library/src/lib-assets/mixins/date";
import { FilialStructureUsersViewService } from "../../../services/FilialStructureUsersViewService";
import { SecurityUserRole } from "../../../../../SecurityAdmin/ProSpace.SecurityAdmin.Frontend/services/SecurityUserRole";
import { DistributorStructureService } from "@masterdata/services/DistributorStructureService.js";
import { mapGetters } from "vuex";
import { useStatistic } from "@composes"
import {
  useMultiSelectRightPanel,
} from "@composes/multiselect"
import PanelBlockSelectedItems from "@multiselect/PanelBlockSelectedItems"
const _ = require("lodash");
export default {
  name: "UserPanel",
  components: {
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceAction,
    ProSpaceSimpleGrid,
    ProSpaceVLayout,
    DistributorHierarchySelectModal,
    PanelBlockSelectedItems
  },
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    counterRoles: {
      type: [String, Number],
      default: null
    },
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    const { selectedData, selectedClude, selectedCount, isMultipleView } = useMultiSelectRightPanel()
    return {
      setStatistic, distActions,
      selectedData, selectedClude, selectedCount, isMultipleView
    }
  },
  data() {
    return {
      user: null,
      filialStructureUsersViewService: new FilialStructureUsersViewService(),
      securityUserRole: new SecurityUserRole(),
      distributorStructureService: new DistributorStructureService(),
      loading: false,
      hasError: false,
      modelTab: { name: this.$t("mainTab"), isActive: true, systemName: "mainTab" },
      visibleDistrStructureModal: false
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime",
    }),
    headerMultiple() {
      return {
        title: this.$t("actions"),
        isExpand: false,
      };
    },
    headerOverview() {
      return {
        title: this.$t("user"),
        isExpand: false,
      };
    },
    headerRoles() {
      return {
        title: this.$t("roles"),
        isExpand: false,
      };
    },
    headerDistributorStructure() {
      return {
        title: this.$t("listBranches"),
        isExpand: false,
      };
    },
    listHeadersRoles() {
      return [
        { name: this.$t("name"), value: "securityRoleSystemName" },
        { name: this.$t("group"), value: "securityRoleDisplayName" },
      ];
    },
    listHeadersDistributorStructure() {
      return [
        { name: this.$t("type"), value: "distributorStructureViewType", f: v => v },
        { name: this.$t("nameL"), value: "distributorStructureViewName", f: v => v },
      ];
    },
    headerBottom() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    tabs() {
      const tabs = [
        {
          name: this.$t("mainTab"),
          isActive: this.modelTab.systemName === "mainTab",
          systemName: "mainTab"
        },
        {
          name: this.$t("distributorStructureBinding"),
          systemName: "distributorStructure",
          // isHidden: this.user && this.user.orgLinkType !== "Distributor",
          isActive: this.modelTab.systemName === "distributorStructure"
        },
      ]

      return tabs;
    },
    header() {
      if (this.user) {
        return {
          title: this.user.fullName,
          id: this.user.securityUserId,
          status: this.getStatus(this.user.status),
          options: this.options,
          showCounter: false,
        };
      }
      let options = this.options.filter((o) => o.name === "resetPassword");
      return {
        title: this.$t("itemsSelected"),
        counter: this.selectedCount,
        showCounter: true,
        options: options.length > 0 ? options : null,
      };
    },
    content() {
      let field = [];
      if (this.user) {
        field = [
          [
            {
              title: this.$t("distributor"),
              text: `${this.user.disctibutorName || ""}`,
            },
          ],
          [
            {
              title: this.$t("type"),
              text: this.user.filialType,
            },
          ],
          [
            {
              title: this.$t("filialName"),
              text: `${this.user.filialName || ""}`,
            },
          ],
          [
            {
              title: this.$t("positionD"),
              text: `${this.user.position || ""}`,
            },
          ],
          [
            {
              title: this.$t("emailD"),
              text: this.user.email,
              link: "mailto:" + this.user.email,
            },
          ],
        ]
      }
      return field;
    },
    contentBottom() {
      if (this.user) {
        return [
          [
            {
              title: this.$t("author"),
              text: this.user.createdBy,
            }
          ],
          [
            {
              title: this.$t("createdAtTxt"),
              text: this.getFormatDateTime(this.user.createdAt) + ' ' + getAMorPM(this.user.createdAt, this.userinfo.timeFormat),
            },
            {
              title: this.$t("lastUpdatedTxt"),
              text: this.getFormatDateTime(this.user.lastUpdated) + ' ' + getAMorPM(this.user.lastUpdated, this.userinfo.timeFormat),
            },
          ],
        ];
      }
      return [];
    },
    distributorStructuresView() {
      if(!this.user.distributorStructures || this.user.distributorStructures.length === 0)
        return [];

      const result = this.user.distributorStructures.map(x => {
        let ds = _.cloneDeep(x);
        ds.distributorStructureViewType = this.distributorStructureService.dsTypes[ds.distributorStructureViewType.toLowerCase()];
        return ds;
      });

      return result;
    }
  },
  mounted() {
    this.update();
  },
  methods: {
    handlerSelectTab({ selectedTab }) {
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: selectedTab.systemName
      })
    },
    editPanel() {
      this.$emit("edit", this.user);
    },
    resetPasswordPanel() {
      this.$emit("resetPassword");
    },
    deletePanel() {
      this.$emit("delete-item", this.user);
    },
    showHistoryPanel() {
      this.$emit("history-item", this.user);
    },
    async update() {
      this.hasError = false;
      this.user = await this.getUser();
      if (this.selectedCount === 1 && this.user) {
        this.distributorStructureService.setUserId(this.user.securityUserId);
        await this.refreshRoleList();
      }
    },
    async refreshRoleList() {
      await this.$refs["simple-grid"]?.getServerData();
    },
    async getUser() {
      if (this.selectedCount === 1 && this.selectedData) {
        this.loading = true;
        let res = await this.filialStructureUsersViewService.get(this.selectedData.id);
        this.loading = false;
        return res.data;
      }
      return null;
    },
    getStatus(status) {
      if (!status) return;
      let type = "default";
      status = status.toLowerCase()
      switch (status) {
        case "active":
          type = "success";
          break;
        case "new":
          type = "info";
          break;
        default:
          type = "danger";
      }
      const capitalizeStatus = status[0].toUpperCase() + status.toLowerCase().slice(1)
      return {
        text: this.$t(`status${capitalizeStatus}`),
        type,
      };
    },
    async deleteRole(role) {
      await this.securityUserRole.delete(role);
      this.update();
    },
    addRole() {
      this.$emit("addRole", this.$refs["simple-grid"].records);
    },
    showDistrStructureModal() {
      this.visibleDistrStructureModal = true;
    }
  },
  watch: {
    selectedData() {
      this.update();
    },
    selectedCount() {
      this.update()
    }
  },
};
</script>

<style lang="scss">
.main-grid__modal-style {
  height: 300px;
}
</style>
