<template>
  <ProSpaceRightPanel
    :header="header"
    :loading="loading"
    :has-error="hasError"
    @edit="() => handleDotAction('edit')"
    @showHistory="() => handleDotAction('showHistory')"
  >
    <template v-slot:center>
      <ProSpaceTabs v-model="modelTab" :tabs="tabs" @select-tab="handlerSelectTab">
        <template v-slot:tab-1>
          <ProSpacePanelBlock v-if="competenceTask" :header="headerMainInformation">
            <ProSpacePanelBlockList :list="mainInformationContent" />
          </ProSpacePanelBlock>
        </template>
        <template v-slot:tab-2>
          <ProSpaceVLayout :gap="20">
            <ProSpacePanelBlock v-if="competenceTask" :header="!isSubtaskEditing ? headerSubtasks : {}">
                <template v-slot:header>
                    <ProSpaceAction
                      v-if="checkStatusAccess('subtaskCreate-action', this.competenceTask)"
                      :text="$t('editItemTxt')"
                      @click="handleEditSubtaskClick"
                    />
                </template>
              <ProSpaceVLayout :gap="10" is-inline-h v-if="subtasks.length > 0" style="margin-top: 15px;">
                <div
                  v-for="(subtask) in subtasks"
                  :key="subtask.text"
                >
                  <ProSpaceCheckbox
                    class="w-100 competence-task-panel__checkbox"
                    v-model="subtaskCheckbox[subtask.text]"
                    :changeObj="subtask"
                    :disabled="true"
                    :fiveLinesLabel="true"
                  />
                </div>
              </ProSpaceVLayout>
              <ProSpaceEmptyGrid
                  v-else
                  icon="error-message"
                  :title="$t('noDataTitle')"
                  :text="$t('noDataTxt')"
                />
            </ProSpacePanelBlock>
          </ProSpaceVLayout>
        </template>
        <template v-slot:tab-3>
          <ProSpaceVLayout :gap="20">
            <ProSpaceTimeLineCondition
              :elems="comments"
              :commentTile="$t('comment')"
              :title="$t('comments')"
              :titleAction="$t('addComment')"
              :dateTimeFormatter="formatTimelineDate"
              :showMarker="false"
              @add="handleAddCommentClick">
              <template #empty>
                <ProSpaceEmptyGrid v-if="comments.length === 0"
                  icon="error-message"
                  :title="$t('noDataTitle')"
                  :text="$t('noDataTxt')"
                />
              </template>
            </ProSpaceTimeLineCondition>
          </ProSpaceVLayout>
        </template>
        <template v-slot:tab-4>
          <ProSpaceVLayout :gap="20">
            <ProSpacePanelBlock v-if="competenceTask" :header="headerAttachments">
              <template v-if="competenceTask.attachments.length > 0">
                <div
                  v-for="(attachment, i) in competenceTask.attachments"
                  :key="i"
                  style="border: none;"
                  class="prospace-panel-block-list__row">
                    <ProSpacePanelBlockItem
                      class="prospace-panel-block-list__item"
                    >
                      <template #content>
                        <ProSpaceAction
                          :gap="5"
                          icon="paperclip"
                          :text="attachment.fileName"
                          @click="downloadAttachment(attachment)"
                        />
                      </template>
                    </ProSpacePanelBlockItem>
                </div>
              </template>
              <ProSpaceEmptyGrid
                v-else
                icon="error-message"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
              />
            </ProSpacePanelBlock>
          </ProSpaceVLayout>
        </template>
      </ProSpaceTabs>
    </template>
    <template v-slot:bottom>
      <ProSpacePanelBlock :header="headerMetaInfo">
        <template v-slot>
          <ProSpacePanelBlockList :list="metaInfoContent" />
        </template>
      </ProSpacePanelBlock>
    </template>
  </ProSpaceRightPanel>
  <ProSpaceModal
      v-model="showAddCommentModal"
      v-if="showAddCommentModal"
      width="410"
      :disabledApply="false"
      :title="$t('addComment')"
      icon="plus"
      @apply="handleApplyAddComment"
      :cancelTxt="$t('reject')"
      :applyTxt="$t('send')"
    >
      <ProSpaceInputText
        isTextarea
        v-model="newCommentText"
        :label="$t('comment')"
        :placeholder="$t('competenceTaskCommentText')"
      />
  </ProSpaceModal>
</template>

<script>
import {
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpacePanelBlockItem,
  ProSpaceAction,
  ProSpaceEmptyGrid,
  ProSpaceVLayout,
  ProSpaceHLayout,
  ProSpaceCheckbox,
  ProSpaceTag,
  ProSpaceInputText,
  ProSpaceButton,
  ProSpaceIconButton,
  ProSpaceModal,
  ProSpaceTimeLineCondition
} from "prospace-components-library";
import { CompetenceTaskService } from "../../../services/CompetenceTaskService";
import { CompetenceTaskCommentService } from "../../../services/CompetenceTaskCommentService";
import { FileService } from "../../../../../../Frontends/ProSpace/services/FileService";
import { mapGetters } from "vuex";
import { useStatistic } from "@composes"
import { useMultiSelectRightPanel } from "@composes/multiselect"
import { getAMorPM } from "prospace-components-library/src/lib-assets/mixins/date";
export default {
  name: "CompetenceTaskPanel",
  components: {
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpacePanelBlockItem,
    ProSpaceAction,
    ProSpaceEmptyGrid,
    ProSpaceVLayout,
    ProSpaceHLayout,
    ProSpaceCheckbox,
    ProSpaceTag,
    ProSpaceInputText,
    ProSpaceButton,
    ProSpaceIconButton,
    ProSpaceModal,
    ProSpaceTimeLineCondition
  },
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    checkAccess: {
      type: Function,
    },
    checkStatusAccess: {
      type: Function,
    },
    filterAccessDots: {
      type: Function,
    },
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    const { selectedData } = useMultiSelectRightPanel()
    return {
      setStatistic, distActions,
      selectedData
    }
  },
  data() {
    return {
      competenceTask: {},
      modelTab: {
        name: this.$t("parameters"),
        isActive: true,
        systemName: "parameters",
      },
      competenceTaskService: new CompetenceTaskService(),
      competenceTaskCommentService: new CompetenceTaskCommentService(),
      fileService: new FileService(),
      loading: false,
      hasError: false,
      subtasks: [],
      subtaskCheckbox: {},
      showAddCommentModal: false,
      comments: [],
      newCommentText: "",
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime",
      getFormatDate: "userinfoStore/getFormatDate",
    }),
    headerMainInformation() {
      return {
        title: this.$t("mainInformation"),
        isExpand: false,
      };
    },
    headerSubtasks() {
      return {
        title: this.$t("competenceTaskSubtasks"),
        isExpand: false,
      };
    },
    headerComments() {
      return {
        title: this.$t("comments"),
        isExpand: false,
      };
    },
    headerAttachments() {
      return {
        title: this.$t("competenceAttachments"),
        isExpand: false,
      };
    },
    headerMetaInfo() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    tabs() {
      if (!this.competenceTask) return [];
      const tabs = [
        {
          name: this.$t("parameters"),
          systemName: "parameters",
          isActive: this.modelTab.systemName === "parameters",
        },
        {
          name: this.$t("competenceTaskSubtasks"),
          systemName: "subtasks",
          isActive: this.modelTab.systemName === "subtasks",
        },
        {
          name: this.$t("comments"),
          systemName: "comments",
          isActive: this.modelTab.systemName === "comments",
        },
        {
          name: this.$t("competenceAttachments"),
          systemName: "attachments",
          isActive: this.modelTab.systemName === "attachments",
        },
      ];
      if (!tabs.some(i => i.isActive)) {
        tabs[0].isActive = true
        this.modelTab = tabs[0]
      }
      return tabs
    },
    header() {
      if (this.competenceTask) {
        return {
          title: this.competenceTask.name,
          id: this.competenceTask.id,
          status: this.mapExpandStatuses().get(this.competenceTask.status) || {type: 'draft', text: this.competenceTask.status},
          options: this.getAccessDots(this.options),
          showCounter: false,
        };
      }
      return {
        title: this.$t("itemsSelected"),
        showCounter: false,
        cleareble: true,
      };
    },
    mainInformationContent() {
      if (this.competenceTask) {
        const items = [
          [
            {
              "title": this.$t("competenceTaskName"),
              "text": this.competenceTask.name,
              "isHtml": true
            }
          ],
          [
            {
              "title": this.$t("competenceTaskPriorityTaskTxt"),
              "text": this.competenceTask.priority !== null && this.competenceTask.priority !== undefined ? String(this.competenceTask.priority) : null
            }
          ],
          [
            {
              "title": this.$t("competenceTaskActionPlan"),
              "text": this.competenceTask.actionPlan,
              "isHtml": true
            }
          ]
        ]
        if (this.competenceTask.assignmentTo) {
          items.splice(1, 0, [
            {
              "title": this.$t("competenceTaskAssignmentTo"),
              "text": this.$t(this.competenceTask.assignmentTo)
            }
          ]);
        }
        return items;
      }
      return [];
    },
    commentsContent() {
      if (this.competenceTask) {
        return [
        ];
      }
      return [];
    },
    attachmentsContent() {
      if (this.competenceTask) {
        return [
        ];
      }
      return [];
    },
    metaInfoContent() {
      let metaInfo = [];
      if (this.competenceTask) {
        if (this.checkAccess("metainfo-by-block"))
          metaInfo.push([
            {
              title: this.$t("createdByTxt"),
              text: this.competenceTask.createdBy,
            },
            {
              title: this.$t("updatedByTxt"),
              text: this.competenceTask.updatedBy,
            },
          ]);

        metaInfo.push([
          {
            title: this.$t("createdAtTxt"),
            text: this.getFormatDateTime(this.competenceTask.createdAt) + ' ' + getAMorPM(this.competenceTask.createdAt, this.userinfo.timeFormat),
          },
          {
            title: this.$t("lastUpdatedTxt"),
            text: this.getFormatDateTime(this.competenceTask.lastUpdated) + ' ' + getAMorPM(this.competenceTask.lastUpdated, this.userinfo.timeFormat),
          },
        ]);
      }
      return metaInfo;
    },
  },
  mounted() {
    this.update();
  },
  watch: {
    selectedData: {
      deep: true,
      handler() {
        this.update();
      }
    },
    showAddCommentModal: {
      handler(value) {
        if (!value)
          this.newCommentText = null;
      }
    }
  },
  methods: {
    formatTimelineDate(date) {
      return this.getFormatDateTime(date);
    },
    getAccessDots(options) {
      return this.filterAccessDots
        ? this.filterAccessDots(options, this.competenceTask)
        : options;
    },
    handlerSelectTab({ selectedTab }) {
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: selectedTab.systemName
      })
    },
    onClickEdit() {
      this.$emit("editClick", this.task);
    },
    update() {
      this.hasError = false;
      try {
        this.getCompetenceTask();
      } catch (e) {
        this.hasError = true;
      }
    },
    getCompetenceTask() {
      if (this.selectedData) {
        this.loading = true;
        this.competenceTaskService.get(this.selectedData.id).then((res) => {
          this.competenceTask = res.data;
          this.subtasks = JSON.parse(this.competenceTask.subtasks);
          this.subtasks.forEach(
                (s) => (this.subtaskCheckbox[s.text] = [{ label: s.text, field: "checked" }])
              );
          this.competenceTaskCommentService
                .grid(`$filter=CompetenceTaskId eq ${this.selectedData.id}`)
                .then((commentReponse) => {
                  this.comments = commentReponse.data.records.map((value) => {
                    return {
                      comment: value.comment,
                      confirmDate: value.createdAt,
                      securityUserFirstName: value.securityUserFullName,
                      securityUserType: this.$t(value.securityUserType)
                    }
                  });
                });
          this.loading = false;
        });
      } else {
        this.competenceTask = null;
        this.subtasks = [];
        this.subtaskCheckbox = {};
        this.comments = [];
      }
    },
    handleDotAction(actionName) {
      this.options.find(o => o.name === actionName).method(this.competenceTask)
    },
    mapExpandStatuses() {
      return new Map([
        ['New', {type: 'blue', text: this.$t('competencetaskstatusnew'), borderStyle: true}],
        ['Approving', {type: 'teal', text: this.$t('competencetaskstatusapproving'), borderStyle: true}],
        ['RejectedByDistributor', {type: 'error', text: this.$t('competencetaskstatusrejectedbydistributor'), borderStyle: true}],
        ['Planning', {type: 'purple', text: this.$t('competencetaskstatusplanning'), borderStyle: true}],
        ['InProgress', {type: 'warning', text: this.$t('competencetaskstatusinprogress'), borderStyle: true}],
        ['CompleteApproving', {type: 'teal', text: this.$t('competencetaskstatuscompleteapproving'), borderStyle: true}],
        ['RejectedByMars', {type: 'error', text: this.$t('competencetaskstatusrejectedbymars'), borderStyle: true}],
        ['Completed', {type: 'success', text: this.$t('competencetaskstatuscompleted'), borderStyle: true}],
        ['Canceled', {type: 'draft', text: this.$t('competencetaskstatuscanceled'), borderStyle: true}],
      ])
    },
    handleEditSubtaskClick() {
      this.editPanel();
    },
    handleAddCommentClick() {
      this.showAddCommentModal = true;
    },
    async handleApplyAddComment() {
      const comment = (await this.competenceTaskCommentService
      .create({
        comment: this.newCommentText,
        createdAt: new Date(),
        competenceTaskId: this.competenceTask.id ?? null,
        securityUserFullName: "",
        securityUserType: ""
      })).data[0];

      comment.securityUserFirstName = comment.securityUserFullName;
      comment.securityUserType = this.$t(comment.securityUserType);
      comment.confirmDate = comment.createdAt;

      this.comments.push(comment)
      this.newCommentText = ""
    },
    downloadAttachment(attachment) {
      const gfid = attachment.gfid;

      this.fileService.getFileToken(gfid).then((response) => {
        if (response != null) {
          this.fileService
            .downloadFile(response.data)
            .then((fileResponse) => {
              if(!fileResponse)
                return;

              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(fileResponse.data);
              let name = fileResponse.fileName;
              link.download = decodeURI(name).replace("%2b", "+");
              link.click();
            });
        }
      });
    },
    editPanel() {
      this.$emit("edit", this.competenceTask);
    },
  }
};
</script>

<style lang="scss">
.competence-task-panel__checkbox {
  .prospace-checkbox__label {
    white-space: normal !important;
  }
}
</style>
