<template>
  <ProSpaceGeoLayout class="retail-contact-row">
    <template v-slot:left>
      <ProSpaceTextBlock
        :top="$t('retailOutlets')"
        :bottom="$t('retailOutletsInfo')"
        :gap="10"
        type="third"
        break-word
        class="retail-contact-text-block"
      />
    </template>
    <template v-slot:center>
      <ProSpaceGeoLayout>
        <template v-slot:top>
          <ProSpaceSearchfield
            ref="distributor"
            :searchPlaceholder="$t('searchTxt')"
            :cancelTxt="$t('cancelInf')"
            :applyTxt="$t('applyTxt')"
            :fastFilters="[]"
          />
        </template>
        <template v-slot:center>
          <ProSpaceMainGrid
            ref="grid"
            getMethod="grid"
            type="single"
            isHeadHide
            isSelectedPanelHide
            :service="retailOutletsService"
            :defaultFilter="{ disabled: { and: [false] } }"
          >
            <Column
              field="name"
            />
          </ProSpaceMainGrid>
        </template>
      </ProSpaceGeoLayout>
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import {
  ProSpaceGeoLayout,
  ProSpaceTextBlock,
  ProSpaceMainGrid,
  ProSpaceSearchfield
} from "prospace-components-library";
import Column from "primevue/column";
import { RetailOutletsService } from "../../../services/RetailOutletsService.js";

export default {
  components: {
    ProSpaceGeoLayout,
    ProSpaceTextBlock,
    ProSpaceSearchfield,
    ProSpaceMainGrid,
    Column
  },
  props: {
    distributorId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      retailOutletsService: new RetailOutletsService()
    };
  },
  methods: {
    submitFilter({ filter, search }) {
      this.$refs.grid.submitFilter(filter, search);
    },
  },
  computed: {

  },
};
</script>
<style lang="scss">
.retail-contact-text-block {
  padding-right: 40px;
  .prospace-text-block__bottom {
    white-space: pre-wrap;
    color: var(--prospace-ui-icon-gray);
  }
}

.retail-contact-row {
  grid-template-columns: 260px 1fr !important;
  padding: 20px 0;
  font-size: 12px;
}
</style>
