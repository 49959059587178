import { Transformer } from "../../../../Transformer/index.js";

export class RecipientTreeService {
  constructor() {
    this.transformer = new Transformer();
    this.data = null;
    this.tree = {};
    this.currentData = "To"
    this.currentQuery = {}
    this.emptyData = {
      To: {
          Custom: [],
          Roles: [],
          Users: []
      },
      CC: {
          Custom: [],
          Roles: [],
          Users: []
      },
      BCC: {
          Custom: [],
          Roles: [],
          Users: []
      }
    }
  }

  set(data) {
    this.data = data;
    for (const [rootKey, rootValue] of Object.entries(this.emptyData)) {
        this.tree[rootKey] = [];
        for (const key of Object.keys(rootValue)) {
            let value;
            if (!!data[rootKey] && !!data[rootKey][key])
              value = data[rootKey][key];

            let node = {
                key: `${rootKey}-${key}`,
                label: key,
                icon: 'folder',
                counter: !value ? null : value.length,
                children: !value ? [] : value.map((e, i) => {
                    return {
                        key: `${rootKey}-${key}-${i}`,
                        label: e.DisplayName || e.Value,
                        icon: 'file',
                        data: e.Value
                    }
                })
            }
            this.tree[rootKey].push(node);
        }
    }
  }

  parseTree() {
    let data = {};
    for (const [rootKey, rootValue] of Object.entries(this.tree)) {
      data[rootKey] = {};
      rootValue.forEach(value => {
        data[rootKey][value.label] = value.children.map(e => {
          let res = {
            Value: e.data,
            DisplayName: e.label
          };
          if (e.targets)
            res.Targets = e.targets;

          return res; 
        })
      });
    }
    this.data = data;
    return this.data;
  }

  async getTo(t) {
    this.currentData = "To"
    return await this.transformer.getTree(this, "search", t);
  }
  async getCC(t) {
    this.currentData = "CC"
    return await this.transformer.getTree(this, "search", t);
  }
  async getBCC(t) {
    this.currentData = "BCC"
    return await this.transformer.getTree(this, "search", t);
  }
  getLocal(field) {
    const data = { data: this.tree[field] }
    return Promise.resolve({ data })
  }
  search(query) {
    if (query) {
      this.currentQuery = query;
    } else {
      query = this.currentQuery;
    }

    let labelObject = query.filter.and.find(
      (elem) => Object.keys(elem)[0] == "label"
    );
    const parseQuery = labelObject ? labelObject.label.contains : "";
    let filterTree = [];
    const tree = this.tree[this.currentData];
    if (parseQuery.length > 0) {
      tree.forEach((node) => {
        filterTree.push(this.filterTree(node, parseQuery));
      });
      filterTree = filterTree.filter((elem) => elem);
    } else {
      filterTree = tree;
    }
    return Promise.resolve({ data: {data:filterTree} });
  }
  filterTree(node, value) {
    let filteredNode
    if (node.label.toLowerCase().includes(value.toLowerCase())) {
      filteredNode = node;
    }
    if (filteredNode && filteredNode.children && filteredNode.children.length > 0) {
      let filterChildren = [];
      filteredNode.children.forEach((child) => {
        filterChildren.push(this.filterTree(child, value));
      });
      filteredNode.children = filterChildren.filter((elem) => elem);
    }
    return filteredNode;
  }
}
