<template>
  <ProSpaceBlock :margin="0">
    <ProSpaceBlock
      color="gray"
      radius
      :margin="0"
      v-if="breadcrumbs.length > 0"
    >
      <ProSpaceHLayout is-inline-h justify-content="space-between">
        <ProSpaceBreadcrumb :items="breadcrumbs" />
        <ProSpaceAction
          icon="check"
          :text="$t('save')"
          v-if="id"
          @click="saveNodeItems"
        />
      </ProSpaceHLayout>
    </ProSpaceBlock>

    <ProSpaceBlock :margin="0">
      <template v-for="(elem, i) in model" :key="i">
        <ProSpaceBlock padding="15px 0" margin="0">
          <ProSpaceHLayout is-inline-h :gap="10">
            <ProSpaceTextBlock
              :top="elem.key"
              :bottom="elem.description"
              style="width: 300px"
              break-word
            />
            <component
              style="width: 180px"
              :is="names[elem.type]"
              v-model="elem.value"
            />
          </ProSpaceHLayout>
        </ProSpaceBlock>
        <ProSpaceDivider v-if="checkLast(i, model)" type="dashed" />
      </template>
    </ProSpaceBlock>
  </ProSpaceBlock>
</template>
<script>
import { SystemSettingService } from "../../services/SystemSettingService";
import {
  ProSpaceAction,
  ProSpaceDivider,
  ProSpaceHLayout,
  ProSpaceTextBlock,
  ProSpaceInputText,
  ProSpaceInputNumber,
  ProSpaceInputSwitch,
  ProSpaceBlock,
  ProSpaceBreadcrumb,
} from "prospace-components-library";
export default {
  name: "PanelSettings",
  components: {
    ProSpaceAction,
    ProSpaceDivider,
    ProSpaceHLayout,
    ProSpaceTextBlock,
    ProSpaceInputText,
    ProSpaceInputNumber,
    ProSpaceInputSwitch,
    ProSpaceBlock,
    ProSpaceBreadcrumb,
  },
  props: {
    id: {
      type: [Number, String, null],
      required: true,
    },
  },
  data() {
    return {
      systemSettingService: new SystemSettingService(),
      model: [],
      names: {
        string: "ProSpaceInputText",
        bool: "ProSpaceInputSwitch",
        number: "ProSpaceInputNumber",
      },
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    breadcrumbs() {
      if (this.id) {
        return this.id.split(">");
      }
      return [];
    },
  },
  methods: {
    getData() {
      var me = this;
      me.systemSettingService.getItems(me.id).then((response) => {
        this.model = response.data;
      });
    },
    saveNodeItems() {
      this.$emit("save");
    },
    checkLast(item, model) {
      return model.length - 1 > item;
    },
  },
};
</script>

