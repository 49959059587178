<template>
  <ProSpaceGeoLayout class="report" v-if="model">
    <template #top>
      <ProSpaceTopPanel :header="layoutName" back @back="backHandler">
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout
        :is-templated="true"
        :template-center="true"
        :template-right="true"
      >
        <template #center>
          <ProSpaceTabs
            v-model="modelTab"
            :tabs="overviewTabs"
            :loading="loading"
            :refLoading="refLoading"
            without-margin-bottom
            @select-tab="handlerSelectTab"
          >
            <ProSpaceHLayout :gap="10">
              <ProSpaceAction
                ref="action-save"
                :disabled="v$.model.$invalid"
                icon="check"
                :text="masterId ? $t('save') : $t('publish')"
                @click="save()"
              />
            </ProSpaceHLayout>
            <template v-slot:tab-1>
              <ProSpaceVLayout>
                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('reportGeneralInfo')"
                      :bottom="$t('reportGeneralInfoAdditional')"
                      :gap="10"
                      type="third"
                      break-word
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceVLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="20"
                    >
                      <ProSpaceInputText style="width: calc(50% - 5px);"
                        ref="name-ProSpaceInputText"
                        v-model="model.name"
                        :label="$t('reportName')"
                        :placeholder="$t('reportName')"
                        :invalid="v$.model.name.$error"
                        :invalid-messages="v$.model.name.$errors"
                        @blur="v$.model.name.$touch"
                      />
                      <ProSpaceInputText
                        ref="description-ProSpaceInputText"
                        v-model="model.description"
                        :label="$t('reportDescription')"
                        :placeholder="$t('reportDescription')"
                        :isTextarea="true"
                        :auto-resize="false"
                        :invalid="v$.model.description.$error"
                        :invalid-messages="v$.model.description.$errors"
                        @blur="v$.model.description.$touch"
                      />
                      <ProSpaceInputText
                        ref="powerBIHref-ProSpaceInputText"
                        v-model="model.powerBIHref"
                        :label="$t('reportPowerBIHrefRightPanel')"
                        :placeholder="$t('reportPowerBIHrefRightPanel')"
                        :invalid="v$.model.powerBIHref.$error"
                        :invalid-messages="v$.model.powerBIHref.$errors"
                        @blur="v$.model.powerBIHref.$touch"
                      />
                    </ProSpaceVLayout>
                  </template>
                  <template v-if="v$.model.name.$error || v$.model.description.$error || v$.model.powerBIHref.$error" v-slot:right>
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>
                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('reportPicInfo')"
                      :bottom="$t('reportPicInfoAdditional')"
                      :gap="10"
                      break-word
                      type="third"
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceHLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="10"
                    >
                    <ProSpaceFile
                        ref="previewFile-ProSpaceFile"
                        v-model="picFile"
                        accept=".jpg, .png, .jpeg"
                        :maxFileSize="102400"
                        :fileLimit="1"
                        :service="fileService"
                        :txtBtnUpload="$t('uploadFile')"
                        :txtEmptyFiles="$t('uploadFileDragAndDropText')"
                        :txtErrorStatus="$t('uploadStatusError')"
                        :invalidFileLimitMessage="$t('invalidFileLimitMessage')"
                        :invalidFileSizeMessage="$t('invalidFileSizeMessage')"
                        :invalidFileTypeMessage="$t('invalidFileTypeMessage')"
                      />
                    </ProSpaceHLayout>
                  </template>
                </ProSpaceGeoLayout>
                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('reportInstructionInfo')"
                      :bottom="$t('reportInstructionInfoAdditional')"
                      :gap="10"
                      break-word
                      type="third"
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceHLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="20"
                    >
                    <ProSpaceFile
                        ref="instructionFile-ProSpaceFile"
                        v-model="instructionFile"
                        :maxFileSize="209715200"
                        :fileLimit="1"
                        accept=".pdf"
                        :service="fileService"
                        :txtBtnUpload="$t('uploadFile')"
                        :txtEmptyFiles="$t('uploadFileDragAndDropText')"
                        :txtErrorStatus="$t('uploadStatusError')"
                        :invalidFileLimitMessage="$t('invalidFileLimitMessage')"
                        :invalidFileSizeMessage="$t('invalidFileSizeMessage')"
                        :invalidFileTypeMessage="$t('invalidFileTypeMessage')"
                        @file-remove="instructionRemoved"
                      />
                    </ProSpaceHLayout>
                  </template>
                </ProSpaceGeoLayout>
              </ProSpaceVLayout>
            </template>
          </ProSpaceTabs>
        </template>
      </ProSpaceGeoLayout>
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import {
  ProSpaceTopPanel,
  ProSpaceInputText,
  ProSpaceAction,
  ProSpaceRadioButtonSecondary,
  ProSpaceGeoLayout,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpaceTextBlock,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceDropdown,
  ProSpaceFile,
  ProSpaceInputCalendar,
  ProSpaceInputNumber,
  ProSpaceSearchfield,
  ProSpaceMultiSelect,
  ProSpaceCustomFilterPanel,
  ProSpaceIcon,
  ProSpaceWarning,
} from "prospace-components-library";
import { ReportService } from "../../services/ReportService.js";
import { FileService } from "../../../../../Frontends/ProSpace/services/FileService.js";
import {
  required,
  maxLength,
  helpers
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useStatistic } from "@composes";
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
export default {
  mixins: [accessMixin],
  components: {
    ProSpaceTopPanel,
    ProSpaceGeoLayout,
    ProSpaceInputText,
    ProSpaceAction,
    ProSpaceRadioButtonSecondary,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpaceTextBlock,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceDropdown,
    ProSpaceFile,
    ProSpaceInputCalendar,
    ProSpaceInputNumber,
    ProSpaceSearchfield,
    ProSpaceMultiSelect,
    ProSpaceCustomFilterPanel,
    ProSpaceIcon,
    ProSpaceWarning,
  },
  setup() {
    const { setStatistic, distActions } = useStatistic();
    return { v$: useVuelidate(), setStatistic, distActions };
  },
  data() {
    return {
      // Tabs
      modelTab: {
        name: this.$t("parameters"),
        isActive: true,
        systemName: "parameters",
      },

      // Common
      model: null,
      loading: false,

      // Service
      reportService: new ReportService(),
      fileService: new FileService(),

      getMethod: "get",
      masterId: null,
      picFile: null,
      instructionFile: [],
    };
  },
  watch: {
    picFile: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.fileChanged(newValue);
      },
    },
  },
  validations() {
    return {
      model: {
        name: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage"),
            maxLength(100)
          ),
        },
        description: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        powerBIHref: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        picFileContent: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        }
      }
    };
  },
  mounted() {
    this.mountAccess();
    this.masterId = this.$route.params.id;
    this.getData();
  },
  methods: {
    gridLoaded() {},
    handlerSelectTab({ selectedTab }) {
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: selectedTab.systemName,
      });
    },
    async getData() {
      if (this.masterId === undefined) {
        this.model = {
          name: null,
          description: null,
          powerBIHref: null,
          picFileContent: null,
          picFileName: null,
          instructionGFID: null,
        };
        return;
      }
      const result = await this.reportService[this.getMethod](this.masterId);
      this.model = result.data;

      if (this.model) {
        this.model.needFilter = false;
        try {
          let filter = JSON.parse(this.model.filter);
          this.nodeFilter = filter.nodeFilter;
          this.gridFilter = filter.gridFilter;
          this.feelNeedFilter();
        } catch {}
        try {
          this.selectedTreeNodes = JSON.parse(this.model.nodeSelection);
        } catch {}
      }

      if (this.model.instructionGFID) {
        const responseToken = await this.fileService.getFileToken(this.model.instructionGFID)
        let file = new File([], responseToken.data.fileName);
        file.gfid = this.model.instructionGFID.gfid;
        file.fileLength = responseToken.data.fileLength;
        file.statusFlag = 0;
        this.instructionFile = [ file ]
      };

      this.picFile = [
        this.dataURLtoFile(result.data.picFileContent, result.data.picFileName),
      ];
    },
    save() {
      this.loading = true;
      const me = this;
      const toCreate = me.instructionFile.filter((f) => !f.gfid);
      if (toCreate.length > 0) {
        let fileInfos = toCreate.filter((f) => !f.id).map((f) => { return { fileName: f.name, fileVisibleFlag: 0 }; });
        me.fileService.createFilesRoot(fileInfos).then((response) => {
          let newFiles = response.data.data.map((f, i) => {
            return {
              fileName: f.fileName,
              gfid: f.gfid,
              fileLength: toCreate[i].size,
            }
          })
          me.model.instructionGFID = newFiles[0].gfid;
          if (this.masterId) {
            me.reportService.set().then(() => {
              me.upload(newFiles, toCreate).then(() => {
                me.getData();
                this.loading = false;
              })
            })
          } else {
            me.reportService["create"](this.model).then((result) => {
              me.model.id = result.data[0].id;
              let func = () => {
                this.masterId = result.data[0].id;
                this.getData();
                this.loading = false;
              };
              if (Array.isArray(newFiles) && newFiles.length > 0) {
                me.upload(newFiles, toCreate).then(() => {
                  func();
                });
              } else func();
            });
          }
        });
      } else {
        if (this.masterId) {
          me.reportService.set().then(() => {
            me.getData();
            this.loading = false;
          });
        } else {
          me.reportService["create"](this.model).then((result) => {
            this.masterId = result.data[0].id;
            this.getData();
            this.loading = false;
          });
        }
      }
    },
    async upload(newFiles, toCreate) {
      await this.fileService.uploadChunks(newFiles, toCreate, null, null);
    },
    backHandler() {
      this.$router.go(-1);
    },
    dataURLtoFile(dataurl, filename = 'preview.jpg') {
      if (!dataurl || !filename) return null;
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      let file = new File([u8arr], filename, { type: mime });
      file.statusFlag = 0;

      return file;
    },
    fileChanged(files) {
      if (Array.isArray(files) && files[0]) {
        this.setFileString(files[0]);
      } else if (this.model) {
        this.model.picFileContent = null;
      }
    },
    setFileString(file) {
      const me = this;
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        me.model.picFileContent = base64String;
      };
      reader.readAsDataURL(file);
      me.model.picFileName = file.name;
    },
    instructionRemoved(files) {
      const me = this;
      this.model.instructionGFID = null;
    }
  },
  computed: {
    layoutName() {
      return this.masterId
        ? this.model.name
        : this.$t("reportNewLayout");
    },
    overviewTabs() {
      return [
        {
          name: this.$t("parameters"),
          systemName: "parameters",
          isActive: this.modelTab.systemName === "parameters",
        }
      ]
    },
    refsForCheckInMixin() {
      let refs = [
        "action-save",
        "name-ProSpaceInputText",
        "description-ProSpaceInputText",
        "powerBIHref-ProSpaceInputText",
        "previewFile-ProSpaceFile",
        "instructionFile-ProSpaceFile"
       ];

      return refs;
    },
    refLoading() {
      return (
        this.mixinLoadingAccess
      );
    },
  }
};
</script>
<style lang="scss"></style>
