import request from "../Frontends/Common/utils/request/requestMain";
const rootRoute = "securityadmin/securityusecaserole/";

export default class ApiSecurityUseCaseClaim {
  async getUseCases(id) {
    return await request.post(`${rootRoute}getsecurityusecaseroles`, {
      filter: `$filter=securityRoleId eq ${id} and disabled eq false`,
      parameters: {},
    });
  }
  async getUseCasesGrid(params, id) {
    params = params.replace("&$top=100&$skip=0", "")
    return await request.post(`${rootRoute}getsecurityusecaseroles`, {
      filter: `$filter=((((securityRoleId eq ${id}))) and (((disabled eq false))) and (((securityUseCaseDisabled eq false))))&${params}`,
      parameters: {},
    });
  }
  async createUseCases(models) {
    return await request.post(`${rootRoute}createsecurityusecaserole`, {
      data: models,
    });
  }
  async updateUseCase(model) {
    return await request.post(`${rootRoute}updatesecurityusecaserole`, {
      data: model
    })
  }
  async deleteUseCase(model) {
    return await request.post(`${rootRoute}deletesecurityusecaserole`, {
      data: model,
    });
  }
}
