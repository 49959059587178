import i18n from "../../../../Frontends/Common/utils/i18n.js";

export class LogStatusService {
  constructor() {
    this.dataStatuses = [
      { text: i18n.global.t("notificationStatusCreated"), value: 0 },
      { text: i18n.global.t("notificationStatusSending"), value: 1 },
      { text: i18n.global.t("notificationStatusSent"), value: 2 },
      { text: i18n.global.t("notificationStatusError"), value: 3 },
      { text: i18n.global.t("notificationStatusSkipped"), value: 4 },
    ];
  }
  async get() {
    return Promise.resolve({ data: { records: this.dataStatuses } });
  }
}
