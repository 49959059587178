import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/RestrictionApi.js";

export class UserDistributorRestrictionService {
    constructor() {
      this.transformer = new Transformer();
      this.Api = new Api();
    }
  
    async save(newModels, deletedModels) {
      if (newModels.length > 0) {
        await this.createRestrictions(newModels)
      }
      if (deletedModels.length > 0) {
        await this.deleteRestrictions(deletedModels)
      }
      return Promise.resolve()
    }
  
    async getRestrictions(args) {
      return await this.transformer.getGrid(this.Api, "getRestrictions", args);
    }
    
    async createRestrictions(models) {
      return await this.transformer.createItem(this.Api, "createRestrictions", models);
    }
  
    async deleteRestrictions(models) {
      return await this.transformer.deleteItem(this.Api, "deleteRestrictions", models);
    }
}  