<template>
  <ProSpaceGeoLayout>
    <template #top>
      <ProSpaceTopPanel :header="this.$t('orgStructureFilial')">
        <template #right>
          <ProSpaceIconButton
            icon="update"
            @click="actionUpdate(false)"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
          />
          <ProSpaceDots
            style="margin-left: 10px"
            :options="dotsHeaderOptions"
            :isSquare="true"
            @action="(action) => handleHeaderDots(action)"
          />
          <ProSpaceButton
            v-if="checkAccess('add-button')"
            style="margin-left: 15px"
            ref="add-button"
            type="primary"
            icon="plus"
            :label="this.$t('MailingGroup')"
            :disabled="canCreateMailingGroup"
            @click="showChangeModal"
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #left>
      <ProSpaceLeftPanel
        class="marsdistributororgusersview-tree__left-panel"
        :searchPlaceholder="$t('searchTxt')"
        panelName=""
        searchable
        @search="handlerSearch"
        @collapse-panel="handlerCollpaseLeftPanel"
      >
        <template #center>
          <ProSpaceTree
            ref="tree"
            :service="distributorStructureService"
            searchName="name"
            getNodeMethod="allFilialStructureTree"
            propKey="id"
            propLabel="name"
            selectionMode="checkbox"
            needLocalSearch
            :needAdd="needAdd"
            :needEdit="needEdit"
            :needDelete="needDelete"
            :searchFilterModel="['name', 'code']"
            :isAll="true"
            :isNested="true"
            :labelOverflow="true"
            :fnCheckHandler="checkHandler"
            @treeLoaded="handlerLoadedTree"
            @select="nodeSelect"
            @check="handlerCheckUnCheck"
            @uncheck="handlerCheckUnCheck"
            @node-expand="nodeExpand"
            @addEvent="openAddMarsStructure"
            @deleteEvent="showFlashMessageDelete"
            @editEvent="openEdit"
          >
            <template #prefix="{ node }">
              <ProSpaceTag v-bind="getTagInfo(node.type)" />
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                icon="error-message"
                :title="$t('noDataTitle')"
              />
            </template>
          </ProSpaceTree>
        </template>
      </ProSpaceLeftPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpaceFilterPanel
            :closeTxt="$t('closeTxt')"
            :searchPlaceholder="$t('searchTxt')"
            :txtResetFilters="$t('txtResetFilters')"
            :noMountedRequest="true"
            :unicKeyStorage="
              filialstructureusersviewService.constructor.name + 'grid'
            "
            @apply="submitFilter"
            class="js-prospace-filter-panel__filters-container"
            ref="filterPanel"
            :filter-model="[
              'fullName',
              'email',
              'position',
            ]"
          >
            <template #hint>
              {{ this.$t("filterBy") }}
            </template>
            <template #filters="{ filter }">
              <ProSpaceMultiSelect
                :key="positionFilterKey"
                v-model="filter.position"
                :service="distributorStructureService"
                :isAddSearch="true"
                :panelStyle="{
                  maxWidth: '240px'
                }"
                get-method="positions"
                option-label="name"
                option-value="name"
                :placeholder-value="$t('positionD')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :searchPlaceholder="$t('searchTxt')"
                @apply="(model) => customFilterApply(filter, model)"
              />
              <ProSpaceCustomFilterPanel
                :customTxt="$t('custom')"
                :customFilterTxt="$t('customFilterTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                :services="customServices"
                :unicKeyStorage="
                  filialstructureusersviewService.constructor.name + 'grid'
                "
                @apply="(model) => customFilterApply(filter, model)"
              />
            </template>
          </ProSpaceFilterPanel>
        </template>
        <template #center>
          <ProSpaceMainGrid
            ref="grid"
            :isHaveFilter="true"
            :noMountedRequest="true"
            :service="filialstructureusersviewService"
            @selected-change="selectedHandler"
            getMethod="grid"
            selectionMode="single"
            dataKey="id"
            :defaultFilter="crutchFilter"
          >
            <template #columns>
              <Column
                field="fullName"
                :header="$t('user')"
                sortable
              >
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    v-if="field.data"
                    :title="field.data.fullName"
                    is-inline-w
                  />
                </template>
              </Column>
              <Column field="position" :header="$t('positionD')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="field.data.position">
                    {{ field.data.position }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column field="filialType" :header="$t('filialType')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="field.data.filialType">
                    {{ field.data.filialType }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column field="filialName" :header="$t('filialName')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="field.data.filialName">
                    {{ field.data.filialName }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                icon="error-message"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
              />
            </template>
          </ProSpaceMainGrid>
        </template>
      </ProSpaceGeoLayout>
    </template>
    <template #right>
      <FilialStructureUsersViewPanel
        v-if="hasSelect"
        ref="right-panel"
        :options="dotsOptions"
        :filterAccessDots="wrapperCheckRoles"
        :checkAccess="checkAccess"
        @edit-item="editMailingGroup"
        @delete-item="deleteMailingGroup"
      />
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import {
  ProSpaceFilterPanel,
  ProSpaceCustomFilterPanel,
  ProSpaceMultiSelect,
  ProSpaceMainGrid,
  ProSpaceDots,
  ProSpaceTag,
  ProSpaceModal,
  ProSpaceInputText,
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceIconButton,
  ProSpaceButton,
  ProSpaceTableHeaderCell,
  ProSpaceEmptyGrid,
  ProSpaceLeftPanel,
  ProSpaceTree,
  ProSpacePanelBlockList,
  ProSpacePanelBlock,
  ProSpaceSearchfield,
  ProSpaceVLayout,
  Validations,
  ProSpaceFlashMessage,
  ProSpaceHLayout,
  ProSpaceDefaultTextColumn
} from "prospace-components-library";
import Column from "primevue/column";
import { FilialStructureUsersViewService } from "../../services/FilialStructureUsersViewService.js";
import { DistributorStructureService } from "../../services/DistributorStructureService.js";
import FilialStructureUsersViewPanel from "./components/FilialStructureUsersViewPanel";
import { DistributorOrgService } from "../../services/DistributorOrgService";
import { FilialStructureService } from "../../services/FilialStructureService";
import { MailingGroupService } from "../../services/MailingGroupService";
import { RecordTypeService } from "../../services/RecordTypeService";
import { mapGetters } from "vuex";
import {nextTick} from "vue"
const _ = require("lodash");
import {
  useMultiSelectGrid,
} from "@composes/multiselect";
import { ref } from "vue"
export default {
  mixins: [accessMixin],
  components: {
    ProSpaceTag,
    ProSpaceFilterPanel,
    ProSpaceCustomFilterPanel,
    ProSpaceMultiSelect,
    ProSpaceMainGrid,
    ProSpaceDots,
    ProSpaceModal,
    ProSpaceInputText,
    Column,
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceIconButton,
    ProSpaceButton,
    ProSpaceTableHeaderCell,
    ProSpaceEmptyGrid,
    FilialStructureUsersViewPanel,
    ProSpaceLeftPanel,
    ProSpaceTree,
    ProSpacePanelBlockList,
    ProSpacePanelBlock,
    ProSpaceSearchfield,
    ProSpaceVLayout,
    ProSpaceFlashMessage,
    ProSpaceHLayout,
    ProSpaceDefaultTextColumn
  },
  data() {
    return {
      selectedUnits: [],
      checkedRows: [],
      crutchFilter: {},

      Validations,

      positionFilterKey: 0,
      selectedNode: {},

      currentNode: {},
      modalModelMars: { name: null },
      showChangeMarsModal: false,
      isMarsEdit: true,

      flashMessageDelete: false,
      deletingNode: null,

      userFilter: null,
      showModal: false,
      showHistoryModal: false,

      checkedValues: [],
      nodeFilter: {
        add: {},
        remove: {},
      },
      gridFilter: {
        add: {},
        remove: {},
      },
      selectedTreeNodes: {},

      // Service
      filialstructureusersviewService:
        new FilialStructureUsersViewService(),
      distributorStructureService: new DistributorStructureService(),
      distributorOrgService: new DistributorOrgService(),
      filialStructureService: new FilialStructureService(),
      mailingGroupService: new MailingGroupService(),
      recordTypeService: new RecordTypeService(),

      //InlinePanel
      masterId: null,
      isEdit: false,
      isCreate: false,
      modalModel: {},

      // Multi filter
      customServices: [
        {
          type: "chips",
          model: "username",
          label: this.$t('username'),
          selected: {},
        },
        {
          type: "chips",
          model: "email",
          label: this.$t('emailD'),
          selected: {},
        },
      ],

      checkedNodes: {},
      hasCheckedNodes: false
    };
  },
  setup() {
    const grid = ref(null)
    const filterPanel = ref(null)
    const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      hasSelect,
    } = useMultiSelectGrid(grid, filterPanel)
    return {
      grid, filterPanel,selectedMultipleGridHandler,
      hasSelect, selectedMultipleClear,
    }
  },
  mounted() {
    this.loadRoleAccess();
  },
  computed: {
    ...mapGetters({
      access: "securityRoleAccess/access",
    }),
    dotsOptions() {
      let res = [
      ];
      return res;
    },
    dotsHeaderOptions() {
      return [
        { name: "export_csv", text: this.$t("export_csv"), icon: "upload" },
        { name: "export_xlsx", text: this.$t("export_xlsx"), icon: "upload" },
      ];
    },
    canCreateMailingGroup() {
      return !(
        this.selectedNode.parentId &&
        this.selectedNode.type != "RealDistributor"
      );
    },
    getPositionService() {
      return this.filialStructureService;
    },
  },
  methods: {
    async handlerCollpaseLeftPanel(collapsed) {
      if (collapsed) {
        this.checkedNodes = _.cloneDeep(this.$refs.tree.checkedNodes) 
      } else {
        await nextTick()
        await nextTick()
        this.$refs.tree.checkedNodes = _.cloneDeep(this.checkedNodes)
      }
    },
    nodeExpand(node) {
      this.$refs.tree.expandNode(node);
    },
    handlerLoadedTree(records) {
      this.selectedUnits = [];
      if (this.needFilterValues)
        this.selectedUnits = this.selectedUnits.filter(x => records.some(r => r.id === (x.distributorStructureViewId ?? x.id)));

      this.$nextTick(() => {
        this.loaded = true;
      })
    },
    handleHeaderDots(action) {
      if (action === "export_csv") {
        this.actionExport("CSV");
      } else if (action === "export_xlsx") {
        this.actionExport("XLSX");
      }
    },
    async actionExport(format) {
      this.loadingHandler(true);
      await this.filialstructureusersviewService.export(
        this.$refs.grid.getRecords(false, true),
        format
      );
      this.loadingHandler(false);
    },
    loadingHandler(val) {
      this.grid.loading = val;
    },
    getTagInfo(type) {
      type = type.toLowerCase()
      const dist = {
        "billto": {type: "blue", text: "Bill-To"},
        "soldto": {type: "light-blue", text: "GRD"},
        "virtualgrd": {type: "light-blue", text: "Virtual GRD"},
        "filial": {type: "purple", text: "Filial"},
        "office": {type: "teal", text: "Office"},
        "shipto": {type: "teal", text: "Склад доставки"}
      }
      return dist[type]
    },
    handleDots(action, item) {
      var option = this.dotsOptions.find((o) => o.name === action);
      option.method(item);
    },
    deleteMailingGroup(record) {
      if (!record) return;
      const grid = this.grid;
      grid.setLoading(true);
      let toDelete = {
        id: record.id,
        name: record.firstName || record.name,
        email: record.email,
      };
      this.mailingGroupService.delete(toDelete).then(() => {
        this.actionUpdate();
        this.selectedModel = {};
      });
    },
    editMailingGroup(record) {
      this.isEdit = true;
      this.isCreate = false;
      this.modalModel = {
        id: record.id,
        name: record.firstName,
        email: record.email,
      };
      this.showModal = true;
    },
    openEdit(node) {
      if (node.type === "Distributor") {
        this.openDistStructure();
        return;
      }
      this.currentNode = node;
      this.modalModelMars = {
        id: node.id,
        name: node.name,
      };
      this.isMarsEdit = true;
      this.showChangeMarsModal = true;
    },
    handlerSearch(value) {
      this.$refs.tree.handlerSearch(value);
    },
    updateModelField(data, field) {
      this.modalModel[
        `orgLink${field[0].toUpperCase() + field.toLowerCase().slice(1)}Name`
      ] = this.$refs[field].selectedItem.name;
    },
    getDistrOrgPanelInfo(record) {
      const pos = [
        {
          title: this.$t("position"),
          text: record.name,
        },
      ];
      const parentPos = [
        {
          title: this.$t("parentPosition"),
          text: record.parentName,
        },
      ];
      return record.parentName ? [parentPos, pos] : [pos];
    },
    addMarsStructureUnit() {
      let method = this.isMarsEdit ? "update" : "create";
      this.filialStructureService[method](this.modalModelMars).then((res) => {
        if (!this.isMarsEdit) this.$refs.tree.getData(this.currentNode);
        else this.currentNode.name = this.modalModelMars.name;
        this.modalModelMars = { name: null };
        this.currentNode = {};
        this.showChangeMarsModal = false;
      });
    },
    updateDistrOrg() {
      this.$refs.tree.getData(
        this.$refs.tree.findNode(
          this.$refs.tree.nodes,
          "-9223372036854775000-D"
        )
      );
    },
    openDistStructure() {
      this.$refs.distrOrgModal.changed = false;
      this.$refs.distrOrgModal.show();
    },
    showFlashMessageDelete(node) {
      this.deletingNode = node;
      this.flashMessageDelete = true;
    },
    // handlerCancelFlashMessageDelete() {
    //   this.deletingNode = null;
    //   this.flashMessageDelete = false;
    // },
    deleteMarsStructure() {
      // this.flashMessageDelete = false;
      const node = this.deletingNode;
      const me = this;
      const toDelete = {
        id: node.id,
        name: node.name,
        code: node.code,
        stamp: node.stamp,
        leaf: node.leaf,
      };
      this.filialStructureService.delete(toDelete).then((res) => {
        if (res && res.success) {
          let parent = me.$refs.tree.findNode(
            me.$refs.tree.nodes,
            node.parentId
          );
          this.$refs.tree.getData(parent);
        }
      });
    },
    openAddMarsStructure(node) {
      this.currentNode = node;
      const parentId = node.id === 9223372036854775000 ? null : node.id;
      this.modalModelMars = {
        parentId,
        name: null,
      };
      this.isMarsEdit = false;
      this.showChangeMarsModal = true;
    },
    submitFilter({ filter, search }) {
      const tree = this.$refs.tree;
      const keys = [];
      for (let key in tree.checkedNodes) {
        keys.push(key);
      }

      if (keys.length > 0) {
        filter.filialId = {
          or: keys
        };
        this.grid.filter.filialId = filter.filialId;
        this.hasCheckedNodes = true;
      } else {
        delete filter.filialId;
        delete this.grid.filter.filialId;
        this.hasCheckedNodes = false;
        if (this.selectedNode && this.selectedNode?.id) {
          keys.push(this.selectedNode.id);
          filter.filialId = {
            or: keys
          };
          this.grid.filter.filialId = filter.filialId;
        }
      }

      this.grid.submitFilter(filter, search);
      this.crutchFilter = this.grid.filter;
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.filterPanel.submitFilter();
    },
    actionUpdate(partUpdate = false) {
      if (partUpdate === false) {
        this.$refs.tree.getData();
      }
      this.grid.submitDefaultFilter();
      this.crutchFilter = this.grid.filter;
      if (this.$refs["right-panel"]) {
        this.$refs["right-panel"].update();
      }
    },
    selectedHandler(val) {
      this.selectedMultipleGridHandler(val)
    },
    showChangeModal() {
      this.isEdit = false;
      this.isCreate = true;
      this.modalModel = {
        type: this.selectedNode.type,
        distributorId: this.selectedNode.distributorId,
      };
      if (this.selectedNode.type === "Mars") {
        this.modalModel.marsOrgId = this.selectedNode.id;
        this.modalModel.marsOrgName = this.selectedNode.name;
      } else {
        this.modalModel.distributorOrgId = this.selectedNode.id;
        this.modalModel.distributorOrgName = this.selectedNode.name;
      }
      this.showModal = true;
    },
    changeItem() {
      let method;
      if (this.isCreate) {
        method = "create";
      }
      if (this.isEdit) {
        method = "update";
      }
      this.mailingGroupService[method](this.modalModel).then(() => {
        this.actionUpdate(true);
      });
    },
    getStatus(record) {
      let type = "default";
      return {
        text: this.$t(record.recordType),
        type,
      };
    },
    nodeSelect(value) {
      if (this.selectedNode && this.selectedNode.key === value.key) {
        return;
      }
      this.selectedNode = value;

      if (this.hasCheckedNodes) {
        return;
      }

      this.grid.sumbitMergeFilter({
        filialId: value.id === 0 ? { and: [] } : { or: [value.id] }
      });
      this.crutchFilter = this.grid.filter;
    },
    filterParentNode(parent, child) {
      return (
        (child.path?.startsWith(parent.path) || parent.key === "all") &&
        child.path !== parent.path
      );
    },
    filterGridRecord(record, node) {
      return record.fullPath.startsWith(node.path) || node.key === "all";
    },
    setCheckedRows() {
      const selected = [];
      // // select nodes
      // const nodeRemove = Object.values(this.nodeFilter.remove);
      // const nodeAdd = Object.values(this.nodeFilter.add);
      // const gridIdsRemove = Object.keys(this.gridFilter.remove);
      // const gridIdsAdd = Object.keys(this.gridFilter.add);
      // const filterGridRecord = (r, add, remove) => {
      //   return (
      //     add.some((n) => this.filterGridRecord(r, n)) &&
      //     !remove.some((n) => this.filterGridRecord(r, n))
      //   );
      // };
      // let filteredRecords = this.$refs.grid.records.filter(
      //   (r) =>
      //     (gridIdsAdd.includes(`${r.id}|${r.recordType}`) ||
      //       filterGridRecord(r, nodeAdd, nodeRemove)) &&
      //     !gridIdsRemove.includes(`${r.id}|${r.recordType}`)
      // );
      // selected.push(...filteredRecords);
      this.$refs.grid.selectedModel = selected;
    },
    checkHandler() {
      let units = _.cloneDeep(this.selectedUnits);
      this.$emit("changed", units);
      this.$emit('update:selectedUnits', units);
    },
    handlerCheckUnCheck() {
      this.filterPanel.submitFilter();
    },
    updateFilterStateAndCheckedNodes(needUpdateRows = true) {
      if (
        Object.keys(this.nodeFilter.add).length === 0 &&
        Object.keys(this.gridFilter.add).length === 0
      ) {
        this.$refs.tree.checkedNodes = {};
        this.nodeFilter.remove = {};
        this.gridFilter.remove = {};
        return;
      }
      this.updateFilterState();
      this.updateCheckedNodes();
      if (needUpdateRows) {
        this.setCheckedRows();
      }
    },
    updateFilterState() {
      // обновляет значения nodeFilter и gridFilter

      // common fn
      const getAntiMode = (mode) => {
        return mode === "add" ? "remove" : "add";
      };

      // init fns for gridFilter
      const getSelectNode = (record, mode = "add") => {
        return Object.values(this.nodeFilter[mode]).find((n) =>
          this.filterGridRecord(record, n)
        );
      };
      const deleteRecordsFromFilter = (records, mode = "add") => {
        if (!Array.isArray(records)) {
          delete this.gridFilter[mode][this.getKeyRecord(records)];
          return;
        }
        records.forEach(
          (r) => delete this.gridFilter[mode][this.getKeyRecord(r)]
        );
      };

      // init fns for nodeFilter
      const getNodeRecords = (node, mode = "add") => {
        return Object.values(this.gridFilter[mode]).filter((r) =>
          this.filterGridRecord(r, node)
        );
      };
      const getSelectParent = (child, mode) => {
        return Object.values(this.nodeFilter[mode]).find((n) =>
          this.filterParentNode(n, child)
        );
      };
      // const getSelectChildren = (parent, mode) => {
      //   return Object.values(this.nodeFilter[mode]).filter((n) =>
      //     this.filterParentNode(parent, n)
      //   );
      // };
      const deleteNodeFromFilter = (node, mode = "add") => {
        delete this.nodeFilter[mode][node.key];
      };
      const addNodeInFilter = (node, mode = "add") => {
        const antiMode = getAntiMode(mode);
        if (this.nodeFilter[antiMode][node.key]) {
          deleteNodeFromFilter(node, antiMode);
        }
        this.nodeFilter[mode][node.key] = node;
      };

      // gridFilter all records / children nodeFilter -> add or remove nodeFilter node
      const checkRecordsForAddingNodeInFilter = (node, mode) => {
        const records = getNodeRecords(node, mode);
        if (node.count > 0 && node.count === records.length) {
          addNodeInFilter(node, mode);
        }
      };
      const checkChildrenNodesForAddingParentInFilter = (node, mode) => {
        // const children = getSelectChildren(node, mode);
        if (!node.children) {
          return;
        }
        const childrenKeys = node.children.map((n) => n.key);
        const children = Object.values(this.nodeFilter[mode]).filter((n) =>
          childrenKeys.includes(n.key)
        );
        const childrenCount = children.reduce((prev, curr) => {
          return (prev += curr.count);
        }, 0);
        if (node.count > 0 && node.count === childrenCount) {
          addNodeInFilter(node, mode);
        }
      };
      Object.values(this.$refs.tree.list).forEach((node) => {
        checkRecordsForAddingNodeInFilter(node, "add");
        checkRecordsForAddingNodeInFilter(node, "remove");
      });
      Object.values(this.$refs.tree.list).forEach((node) => {
        checkChildrenNodesForAddingParentInFilter(node, "add");
        checkChildrenNodesForAddingParentInFilter(node, "remove");
      });
      // check hierarchy
      // delete nodes check parent node
      Object.values(this.nodeFilter.add).forEach((n) => {
        const mode = "add";
        const antiMode = getAntiMode(mode);
        const node = getSelectParent(n, mode);
        const antiNode = getSelectParent(n, antiMode);
        if ((node && !antiNode) || n.count === 0) {
          deleteNodeFromFilter(n, mode);
        }
      });
      Object.values(this.nodeFilter.remove).forEach((n) => {
        const mode = "remove";
        const antiMode = getAntiMode(mode);
        const node = getSelectParent(n, mode);
        const antiNode = getSelectParent(n, antiMode);
        if (node || !antiNode) {
          deleteNodeFromFilter(n, mode);
        }
      });

      // delete records has parent node in nodeFilter
      Object.values(this.gridFilter.add).forEach((r) => {
        const mode = "add";
        const antiMode = getAntiMode(mode);
        const node = getSelectNode(r, mode);
        const antiNode = getSelectNode(r, antiMode);
        if (node && !antiNode) {
          deleteRecordsFromFilter(r, mode);
        }
      });
      Object.values(this.gridFilter.remove).forEach((r) => {
        const mode = "remove";
        const antiMode = getAntiMode(mode);
        const node = getSelectNode(r, mode);
        const antiNode = getSelectNode(r, antiMode);
        if (node || !antiNode) {
          deleteRecordsFromFilter(r, mode);
        }
      });

      // has filter(add) -> delete parent nodeFilter(add/remove), add children nodeFilter(add)
      Object.values(this.gridFilter.add).forEach((r) => {
        const parentAdd = getSelectNode(r, "add");
        const parentRemove = getSelectNode(r, "remove");
        if (!parentAdd || !parentRemove) {
          return;
        }
        deleteNodeFromFilter(parentAdd, "add");
        deleteNodeFromFilter(parentRemove, "remove");
        if (parentAdd.children) {
          const addingChildren = parentAdd.children.filter(
            (n) => n.count > 0 && n.key !== parentRemove.key
          );
          addingChildren.forEach((n) => {
            addNodeInFilter(n, "add");
          });
          const node = getSelectNode(r, "add");
          if (node) {
            deleteRecordsFromFilter(r, "add");
          }
        }
      });
      Object.values(this.nodeFilter.add).forEach((n) => {
        const parentAdd = getSelectParent(n, "add");
        const parentRemove = getSelectParent(n, "remove");
        if (!parentAdd || !parentRemove) {
          return;
        }
        deleteNodeFromFilter(parentAdd, "add");
        deleteNodeFromFilter(parentRemove, "remove");
        if (parentAdd.children) {
          const addingChildren = parentAdd.children.filter(
            (n) => n.count > 0 && n.key !== parentRemove.key
          );
          addingChildren.forEach((n) => {
            addNodeInFilter(n, "add");
          });
        }
      });
    },
    updateCheckedNodes() {
      // обновляются значения checked и partialChecked у всех узлов
      // по nodeFilter(add/remove) и gridFilter(add/remove)

      const updateCheck = (key, checked, partialChecked) => {
        if (!checked && !partialChecked) {
          delete listTreeNodes[key];
          return;
        }
        if (!listTreeNodes[key]) {
          const node = this.$refs.tree.list[key];
          listTreeNodes[key] = {
            key: node.key,
            parentKey: node.parentId,
          };
        }
        listTreeNodes[key].checked = checked;
        listTreeNodes[key].partialChecked = partialChecked;
      };

      const listTreeNodes = {
        all: {
          checked: false,
          partialChecked: true,
        },
      };

      const initListTreeNodes = (item) => {
        if (!item.fullPath && !item.path) {
          return;
        }
        const keys = item.fullPath
          ? item.fullPath.split("|")
          : item.path.split("|");
        keys.forEach((key, index, arr) => {
          if (!listTreeNodes[key]) {
            listTreeNodes[key] = {
              checked: false,
              partialChecked: true,
              key: key,
              parentKey: index > 0 ? arr[--index] : "all",
            };
          }
        });
      };

      Object.values(this.gridFilter.add).forEach(initListTreeNodes);
      Object.values(this.gridFilter.remove).forEach(initListTreeNodes);
      Object.values(this.nodeFilter.add).forEach(initListTreeNodes);
      Object.values(this.nodeFilter.remove).forEach(initListTreeNodes);

      // init fns
      const updateChildren = (parentKey, mode = true) => {
        const parent = this.$refs.tree.list[parentKey];
        if (!parent) {
          return;
        }
        const children = Object.values(this.$refs.tree.list)
          .filter((n) => this.filterParentNode(parent, n) && n.count > 0)
          .map((n) => n.key);
        if (children.length === 0) {
          return;
        }
        children.forEach((key) => {
          updateCheck(key, mode, false);
          updateChildren(key, mode);
        });
      };

      const updateParent = (childKey) => {
        const child = this.$refs.tree.list[childKey];
        if (!child) {
          return;
        }
        const parents = Object.values(this.$refs.tree.list)
          .filter((n) => this.filterParentNode(n, child))
          .map((n) => n.key);
        if (parents.length === 0) {
          return;
        }
        parents.forEach((key) => {
          updateCheck(key, false, true);
          updateParent(key, false, true);
        });
      };

      const getNodeFromRecord = (record) => {
        const keys = record.fullPath.split("|").reverse();
        const parentKey = keys.find(
          (k) => listTreeNodes[k] && this.$refs.tree.list[k]
        );
        return parentKey;
      };

      // checked parent node and children
      Object.keys(this.nodeFilter.add).forEach((key) => {
        updateCheck(key, true, false);
        updateChildren(key);
      });

      // uncheck nodes
      Object.keys(this.nodeFilter.remove).forEach((key) => {
        updateCheck(key, false, false);
        updateChildren(key, false, false);
        updateParent(key);
      });

      // partialChecked nodes
      Object.values(this.gridFilter.remove).forEach((r) => {
        const nodeKey = getNodeFromRecord(r);
        updateCheck(nodeKey, false, true);
        updateParent(nodeKey);
      });

      this.$refs.tree.checkedNodes = listTreeNodes;
    },
    getKeyRecord(record) {
      return `${record.id}|${record.recordType}`;
    },
    getStatus(record) {
      let type = "default";
      return {
        text: this.$t(record.recordType),
        type,
      };
    },
    checkNodeFilterAdd(node) {
      const getSelectParent = (child, mode) => {
        return Object.values(this.nodeFilter[mode]).find((n) =>
          this.filterParentNode(n, child)
        );
      };
      const getAntiMode = (mode) => {
        return mode === "add" ? "remove" : "add";
      };
      const deleteNodeFromFilter = (node, mode = "add") => {
        delete this.nodeFilter[mode][node.key];
      };
      const addNodeInFilter = (node, mode = "add") => {
        const antiMode = getAntiMode(mode);
        if (this.nodeFilter[antiMode][node.key]) {
          deleteNodeFromFilter(node, antiMode);
        }
        this.nodeFilter[mode][node.key] = node;
      };

      const parentAdd = getSelectParent(node, "add");
      const parentRemove = getSelectParent(node, "remove");
      if (!parentAdd || !parentRemove) {
        return;
      }
      deleteNodeFromFilter(parentAdd, "add");
      deleteNodeFromFilter(parentRemove, "remove");
      if (parentAdd.children) {
        const removesKeys = Object.values(this.nodeFilter.remove).map(
          (n) => n.key
        );
        const addingChildren = parentAdd.children.filter(
          (n) =>
            n.count > 0 &&
            n.key !== parentRemove.key &&
            !removesKeys.includes(n.key)
        );
        addingChildren.forEach((n) => {
          addNodeInFilter(n, "add");
        });
      }
    },
    needAdd(node) {
      return this.checkAccess("action-addNode", node) && node.type === "Mars";
    },
    needEdit(node) {
      return (
        this.checkAccess("action-editNode", node) &&
        ((node.type === "Mars" && node.parentId !== null) ||
          (node.type === "Distributor" && node.parentId === null))
      );
    },
    needDelete(node) {
      return (
        this.checkAccess("action-deleteNode", node) &&
        node.type === "Mars" &&
        node.parentId
      );
    },
  },
};
</script>
<style lang="scss">
@import "../../assets/scss/main.scss";
.marsdistributororgusersview-tree {
  .radio-tabs {
    margin-top: 1px;
    .radio-tab:nth-child(2) {
      display: none !important;
    }
    &-body {
      margin: 10px;
    }
  }

  &__left-panel {
    border: var(--prospace-ui-border);
  }
}
.js-prospace-filter-panel__filters-container .prospace-filter-panel__filters-container > .prospace-h-layout > .prospace-h-layout {
  display: none;
}
</style>
