import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/MasterData/DistributorOrgApi";

export class DistributorOrgService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createDistributorOrg", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteDistributorOrg", model);
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "editDistributorOrg", model);
  }

  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getDistributorOrgs", "$filter=disabled eq false");
  }
  async allTree() {
    return await this.transformer.toTree(this.Api, "getDistributorOrgs", "$filter=disabled eq false", "id");
  }
  async getNode(id) {
    var result =  await this.transformer.getTree(this.Api, "get", id);
    this.prepareTree(result.data);
    return result;
  }
  prepareTree(tree) {
    for (let e of tree) {

      e.key = e.id;
      e.label = e.name;
    }
  }
}
