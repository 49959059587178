import { lib, SHA256, enc } from "crypto-js";

console.log("VUE_APP_VERSION in consts", process.env.VUE_APP_VERSION);
const version = process.env.VUE_APP_VERSION;

const apiModules = {
  auth: { name: "auth", version: version },
  securityadmin: { name: "securityadmin", version: version },
  promo: { name: "promo", version: version },
  subscription: { name: "subscription", version: version },
};

export function getApiPrefix(moduleName) {
  let api = apiModules[moduleName];
  return `/api/${api.name}/v${api.version}/`;
}

export const ERROR_CODES = {
  CODE403: ["0.6.0"],
};

export const LOG_LEVEL = {
  Trace: "Trace",
  Debug: "Debug",
  Information: "Information",
  Warning: "Warning",
  Error: "Error",
  Critical: "Critical",
  None: "None",
};

export const MESSAGES = {
  UPDATE_VERSION: "New version avaliable. Please refresh page",
  DeployStart:
    "New application version is deploying. Data change operations are blocked",
  DeployStop: "Application has been deployed",
};

export const MAIN_URL = "/main";

function random() {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (var i = 0; i < 50; i++)
    result += characters.charAt(Math.floor(Math.random() * characters.length));

  return result;
}

let verifier = "TSVqldjCu6xeXImM0cG52TCgc3KoecfskqfcIfPcQCyIfq7v5C";
export const AUTHORIZATION_INFO = {
  state: random(),
  code_verifier: verifier,
  code_challenge: SHA256(verifier).toString(enc.Base64url),
  code_challenge_method: "S256",
  client_id: "jsapp",
  redirect_uri: process.env.VUE_APP_API_REDIRECT_URL || config.VUE_APP_API_REDIRECT_URL,
  scope: "offline_access",
}

console.log("VUE_APP_API_BASE_URL in consts", process.env.VUE_APP_API_BASE_URL || config.VUE_APP_API_BASE_URL);
console.log("VUE_APP_API_REDIRECT_URL in consts", process.env.VUE_APP_API_REDIRECT_URL || config.VUE_APP_API_REDIRECT_URL);