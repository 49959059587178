import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/SecurityUserRoleApi";

export class SecurityUserRole {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async get(id) {
    return await this.transformer.getItem(this.Api, "getRole", id);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteRole", model);
  }
  async create(models, mappingModel) {
    return await this.transformer.userRoleUpdate(
      this.Api,
      "createRole",
      models,
      mappingModel
    );
  }
}
