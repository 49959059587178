<template>
  <ProSpaceRightPanel
    class="workflow-panel"
    :header="header"
    :loading="loading"
    :has-error="hasError"
    @edit="editPanel"
    @resetPassword="resetPasswordPanel"
    @delete="deletePanel"
    @show-history="showHistoryPanel"
  >
    <template v-slot:center>
      <ProSpacePanelBlock :header="headerNotification">
        <ProSpacePanelBlockList :list="content" />
        <ProSpaceSimpleGrid
          :headers="listHeaders"
          :service="secondService"
          getMethod="getByWorkflowId"
          propId="workflowSettingId"
          defaultSortField="stepNo"
          :id="id"
          :key="id"
          :btnText="$t('viewMore')"
          class="workflow-panel__grid"
          applyTxt=""
        />
      </ProSpacePanelBlock>
    </template>
    <template v-slot:bottom>
      <ProSpacePanelBlock :header="headerBottom">
        <template v-slot>
          <ProSpacePanelBlockList :list="contentBottom" />
        </template>
      </ProSpacePanelBlock>
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceRightPanel,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceAction,
  ProSpacePanelBlockHeader,
  ProSpacePanelBlockItem,
  ProSpaceSimpleGrid,
} from "prospace-components-library";
import { mapGetters } from "vuex";
import {
  useMultiSelectRightPanel,
} from "@composes/multiselect"
export default {
  name: "WorkflowPanel",
  components: {
    ProSpaceRightPanel,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceAction,
    ProSpacePanelBlockHeader,
    ProSpacePanelBlockItem,
    ProSpaceSimpleGrid,
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
    secondService: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      workflow: null,
      header: {},
      options: this.options,
      loading: false,
      hasError: false,
    };
  },
  setup() {
    const { selectedData } = useMultiSelectRightPanel()
    return {
      selectedData
    }
  },
  computed: {
    ...mapGetters({
      getFormatDateTime: "userinfoStore/getFormatDateTime"
    }),
    headerNotification() {
      return {
        title: this.$t("overview"),
        isExpand: false,
      };
    },
    headerBottom() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    listHeaders() {
      return [
        {
          name: "Step",
          value: "stepNo",
          tagTitle: true,
          f: (item) => {
            return `Step ${Number(item)}`;
          },
        },
        { name: "Name", value: "name" },
      ];
    },
    header() {
      if (this.workflow) {
        return {
          title: this.workflow.name,
          id: this.workflow.id,
          status: this.getStatus(this.workflow.status),
          showCounter: false,
          options: this.options,
        };
      }
      return {
        title: this.$t("itemsSelected"),
        showCounter: false,
        options: this.options,
      };
    },
    content() {
      if (this.workflow) {
        return [
          [
            {
              title: this.$t("description"),
              text: this.workflow.description,
            },
          ],
          [
            {
              title: this.$t("version"),
              text: this.workflow.version,
            },
          ],
        ];
      }
      return [];
    },
    contentBottom() {
      if (this.workflow) {
        return [
          [
            {
              title: this.$t("createdByTxt"),
              text: this.workflow.createdBy,
            },
            {
              title: this.$t("updatedByTxt"),
              text: this.workflow.updatedBy,
            },
          ],
          [
            {
              title: this.$t("createdAtTxt"),
              text: this.getFormatDateTime(this.workflow.createdAt),
            },
            {
              title: this.$t("lastUpdatedTxt"),
              text: this.getFormatDateTime(this.workflow.lastUpdated),
            },
          ],
        ];
      }
      return [];
    },
  },
  mounted() {
    this.update();
  },
  watch: {
    selectedData: {
      deep: true,
      handler() {
        this.update();
      }
    },
  },
  methods: {
    async update() {
      this.hasError = false;
      try {
        this.workflow = await this.getWorkflow();
      } catch (e) {
        this.hasError = true;
      }
    },
    async getWorkflow() {
      if (this.selectedData) {
        this.loading = true;
        const res = await this.service.get(this.selectedData.id);
        this.loading = false;
        return res.data;
      }
      return null;
    },
    getStatus(status) {
      if (!status) return;
      let type = "default";
      switch (status.toLowerCase()) {
        case "complete":
          type = "success";
          break;
        case "active":
          type = "success";
          break;
        case "draft":
          type = "info";
          break;
        case "process":
          type = "warning";
          break;
        case "error":
          type = "danger";
          break;
        default:
          type = "default";
      }
      return {
        text: status[0].toUpperCase() + status.toLowerCase().slice(1),
        type,
      };
    },
    editPanel() {
      this.$emit("edit", this.workflow);
    },
    deletePanel() {
      this.$emit("delete-item", this.workflow);
    },
    showHistoryPanel() {
      this.$emit("history-item", this.workflow);
    },
  },
};
</script>

<style lang="scss">
.workflow-panel {
  .prospace-list-items-table .p-datatable-tbody > tr:not(.p-datatable-row-expansion) > td {
    vertical-align: baseline;
  }
}
</style>
