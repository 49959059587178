export class DirectionService {
  constructor() {
    this.dataDirections = [
      { text: "Outcoming", direction: 1 },
      { text: "Incoming", direction: 0 },
    ];
  }
  search(value) {
    return this.dataDirections.filter((elem) => elem.text.indexOf(value) !== -1);
  }
  find(value) {
    var direction = this.dataDirections.find((elem) => elem.direction === value)
    return direction ? direction.text : '';
  }
  async get() {
    return Promise.resolve({ data: { records: this.dataDirections } });
  }
}
