import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/MasterData/DistributorShipToApi";

export class DistributorShipToService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
    this.billToCodes = [];
    this.rawTreeRecords = [];
    this.dsTypes = {
      billto: "Bill to",
      soldto: "GRD",
      shipTo: "Склад доставки"
    }
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getDistributorShipTos", "$filter=disabled eq false");
  }
  async allTree() {
    let filter = this.billToCodes && this.billToCodes.length > 0 
      ? `$filter=(${this.billToCodes.map(c => `billToCode eq '${c}'`).join(" or ")}) and disabled eq false` 
      : `$filter=disabled eq false` ;

    let res = await this.transformer.toTree(this.Api, "getDistributorShipTos", filter, "id", true);
    this.rawTreeRecords = res.rawData;
    return res.data;
  }
  async getShipTos(filter) {
    return await this.transformer.getGrid(this.Api, "getDistributorShipTos", filter);
  }
  async getNode(id) {
    let result =  await this.transformer.getTree(this.Api, "get", id);
    this.prepareTree(result.data);
    return result;
  }
  prepareTree(tree) {
    for (let e of tree) {

      e.key = e.id;
      e.label = e.name;
    }
  }
  setBillToCodes(codes) {
    this.billToCodes = [...codes];
  }
}
