import request from "../Frontends/Common/utils/request/requestMain";
const rootRoute = "/securityadmin/errors/";

export default class ErrorListApi {
  constructor() {
  }
  async get(language) {
    return await request.post(`${rootRoute}geterrors`, {
      language: language,
    });
  }
}
