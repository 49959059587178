<template>
  <ProSpaceRightPanel
    :header="header"
    :loading="loading"
    :has-error="hasError"
    class="user-task-right-panel"
    @result="actionHandler('result')"
    @log="actionHandler('log')"
    @again="actionHandler('again')"
    @show-history="actionHandler('showHistory')"
  >
    <template v-slot:center>
      <ProSpacePanelBlock :header="headerOverview">
        <template v-slot:header> </template>
        <template v-slot>
          <ProSpacePanelBlockList :list="overviewContent" />
        </template>
      </ProSpacePanelBlock>
      <ProSpacePanelBlock v-if="checkAccess('parameters-block')" :header="headerParameters">
        <template v-slot:header> </template>
        <template v-slot>
          <ProSpacePanelBlockItem
            class="user-task-right-panel__text"
            :isJSON="true"
            :action="showParameter"
            :text="parametersContent.text"
          />
        </template>
      </ProSpacePanelBlock>
    </template>
    <template v-slot:bottom>
      <ProSpacePanelBlock :header="headerMetaInfo">
        <template v-slot>
          <ProSpacePanelBlockList :list="contentMetaInfo" />
        </template>
      </ProSpacePanelBlock>
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpacePanelBlockItem,
  ProSpaceAction,
} from "prospace-components-library";
import { getAMorPM } from "prospace-components-library/src/lib-assets/mixins/date";
import { UserTaskService } from "../../../services/UserTaskService.js";
import { mapGetters } from "vuex";
import {
  useMultiSelectRightPanel,
} from "@composes/multiselect"
export default {
  name: "UserTaskPanel",
  components: {
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpacePanelBlockItem,
    ProSpaceAction,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    checkAccess: {
      type: Function,
    },
    getMethod: {
      type: String,
      default: "get"
    }
  },
  data() {
    return {
      task: null,
      userTaskService: new UserTaskService(),
      loading: false,
      hasError: false,
    };
  },
  setup() {
    const { selectedData } = useMultiSelectRightPanel()
    return {
      selectedData
    }
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime",
    }),
    headerOverview() {
      return {
        title: this.$t("overview"),
        isExpand: false,
      };
    },
    headerParameters() {
      return {
        title: this.$t("parameters"),
        isExpand: false,
      };
    },
    headerMetaInfo() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    header() {
      if (this.task) {
        return {
          title: this.task.taskName,
          id: this.selectedData.id,
          status: this.getStatus(this.task.status),
          options: this.options,
          showCounter: false,
        };
      }
      return {};
    },
    overviewContent() {
      if (this.task) {
        return [
          [
            {
              title: this.$t("taskType"),
              text: this.task.taskType
            }
          ], [
            {
              title: this.$t("taskName"),
              text: this.task.taskName
            },
          ], [
            {
              title: this.$t("execution"),
              text: this.task.execution
            }
          ]
        ];
      }
      return [];
    },
    parametersContent() {
      if (this.task) {
        return {
          title: this.$t("parameter"),
          text: this.task.context,
        };
      }
      return {};
    },
    contentMetaInfo() {
      let metaInfo = [];
      if (this.task) {
        if(this.checkAccess("metainfo-by-block"))
          metaInfo.push([
            {
              title: this.$t("createdByTxt"),
              text: this.task.createdBy,
            }
          ]);

        metaInfo.push([
          {
            title: this.$t("createdAtTxt"),
            text: this.getFormatDateTime(this.task.createdAt) + ' ' + getAMorPM(this.task.createdAt, this.userinfo.timeFormat),
          },
          {
            title: this.$t("lastUpdatedTxt"),
            text: this.getFormatDateTime(this.task.lastUpdated) + ' ' + getAMorPM(this.task.lastUpdated, this.userinfo.timeFormat),
          },
        ]);
      }
      return metaInfo;
    },
  },
  mounted() {
    this.update();
  },
  methods: {
    update() {
      this.hasError = false;
      try {
        this.getTask();
      } catch (e) {
        this.hasError = true;
      }
    },
    getTask() {
      if (this.selectedData) {
        this.loading = true;
        this.userTaskService[this.getMethod](this.selectedData.id).then((res) => {
          this.task = res.data[0];
          this.loading = false;
        });
      } else {
        this.task = null;
      }
    },
    actionHandler(action) {
      this.$emit("action", action, this.task);
    },
    showParameter() {
      this.actionHandler("parameter");
    },
    getStatus(status) {
      if (!status) return;
      let type = "default";
      switch (status.toLowerCase()) {
        case "complete":
          type = "success";
          break;
        case "process":
          type = "warning";
          break;
        case "error":
          type = "danger";
          break;
        default:
          type = "default";
      }
      return {
        text: status[0].toUpperCase() + status.toLowerCase().slice(1),
        type,
      };
    },
  },
  watch: {
    selectedData: {
      handler() {
        this.update();
      },
      deep: true
    },
  },
};
</script>
<style lang="scss">
.user-task {
  &__panel-action {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 270px;
  }
}
.user-task-right-panel {
  &__text
    .prospace-action > div {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 255px;
  }
}
</style>
