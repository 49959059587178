import { FileService } from "@prospace/services/FileService.js";

export function formatBytes(bytes, decimals = 0) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export async function downloadFile(file) {
    const fileService = new FileService();
    const responseToken = await fileService.getFileToken(file.gfid);
    const fileResponse = await fileService.downloadFile(responseToken.data);
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(fileResponse.data);
    const name = fileResponse.fileName;
    link.download = decodeURI(name).replace("%2b", "+");
    link.click();
}