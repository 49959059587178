import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/MasterData/FilialStructureApi";

export class FilialStructureService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(this.Api, "editMarsOrg", fields);
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editMarsOrg");
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getFilialStructureOrgs", "$filter=disabled eq false");
  }
  async get(params) {
    return await this.transformer.getGridPanel(this.Api, "getMarsOrg", null, params);
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createMarsOrg", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteMarsOrg", model);
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "editMarsOrg", model);
  }
  
  async getNode(id) {
    var result =  await this.transformer.getTree(this.Api, "get", id);
    this.prepareTree(result.data);
    return result;
  }
  prepareTree(tree) {
    for (let e of tree) {

      e.key = e.id;
      e.label = e.name;
    }
  }
}
