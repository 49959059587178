import { Transformer } from "../../../Transformer/index.js";
import Api from "../../../Api/FileApi";
import FileApi from "../../../Api/FileApi";
import store from "../../Common/store";
import i18n from '../../Common/utils/i18n.js'

export class FileService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
    this.FileApi = new FileApi();
  }
  async upload(formData) {
    return await this.Api.upload(formData);
  }
  async uploadGFID(formData) {
    return await this.Api.uploadGFID(formData);
  }
  async createFilesRoot(fileInfos) {
    return await this.Api.createFilesRoot(fileInfos);
  }
  async getFileToken(gfid) {
    return await this.FileApi.getDownloadUrl(gfid);
  }
  async downloadFile(dataInfo, showDownloadPopUp = true) {
    const id = btoa(`loadfile${new Date().getTime()}`)
    let title = "";

    if(showDownloadPopUp) {
      title = i18n.global.t('loadFileProgress') + '...'
      store.dispatch('notifications/addFrontNotification', {
        id, title, type: "load-inprog"
      })
    }
    
    let batchInfos = [];
    let startPoint = 0;
    const chunkLength = 4194304;
    const maxBatchChunks = 10;
    const allParts = Math.ceil(dataInfo.fileLength/chunkLength);
    const batchParts = Math.ceil(allParts/maxBatchChunks);
    for (let i = 0; i < batchParts; i++) {
      batchInfos.push([]);
      const remainingParts = allParts - (maxBatchChunks * i);
      const parts = maxBatchChunks <= remainingParts ? maxBatchChunks : remainingParts;
      for (let j = 0; j < parts; j++) {
        startPoint = chunkLength * (j + maxBatchChunks * i);
        batchInfos[i].push({ startPoint: startPoint, chunkLength: chunkLength });
      }
    }

    let tryDownload = async (token, batchInfo) => {
      let tasks = [];
      for(let chunk of batchInfo) {
        tasks.push(this.FileApi.downloadFile(token, chunk));
      }

      return await Promise.all(tasks).then((chunks) => {
        return new Blob(chunks.map((c => c.data)));
      })
      .catch(() => {
        return null;
      })
    }

    let res = { fileName: dataInfo.fileName, data: new Blob() };
    for(let batchInfo of batchInfos) {
      let filePart = await tryDownload(dataInfo.token, batchInfo);

      if(!filePart) {
        res = null;
        break;
      }

      res.data = new Blob([res.data, filePart]);
    }

    if(showDownloadPopUp) {
      if (res) {
        title = i18n.global.t('loadFileDone')
        store.dispatch('notifications/updateFrontNotification', {
          id, title, type: "load-done"
        })
      } else {
        title = i18n.global.t('loadFileError')
        store.dispatch('notifications/updateFrontNotification', {
          id, title, type: "load-error"
        })
      }
    }
    
    return res;
  }
  async uploadChunks(fileInfos, files, successCallback, errorCallback) {
    let results = [];
    let gfids = [];
    let errorGfids = [];

    gfids = fileInfos.map(fi => fi.gfid);
    let chunkInfos = this.getChunks(fileInfos, files);
    chunkInfos = chunkInfos.filter(c => c.chunks.length > 0);
    let batches = this.getBatches(chunkInfos);
    let tempResults = [];
    if(batches.length > 0) {  
      for(let b of batches) {
        if(errorGfids.includes(b.gfid))
          continue;

        let tempRes = await this.uploadGFIDChunks(b.gfid, b.chunks);

        if(tempRes.some(r => r.success === false))
          errorGfids.push(b.gfid);

        tempResults = tempResults.concat(tempRes);
      }

      gfids.forEach(gfid => {
        results.push({ gfid: gfid, success: tempResults.filter(r => r.gfid == gfid).every(r => r.success === true) })
      })
    }
    
    await this.finalizeUploadFileByChunks(results, successCallback, errorCallback);
  }
  getChunks(fileInfos, files) { 
    let chunkInfos = [];
    const chunkLength = 4194304;
    for (let i = 0; i < fileInfos.length; i++) {
      let chunks = [];
      const gfid = fileInfos[i].gfid;
      const file = files[i];
      const parts = Math.ceil(file.size/chunkLength);
      for (let j = 0; j < parts; j++) {
        let formData = new FormData();
        const startPoint = chunkLength * j;
        const endPoint = startPoint + chunkLength;
        const chunk = file.slice(startPoint, endPoint);
        formData.append(gfid, chunk);
        chunks.push({ data: formData, startPoint: startPoint, fullLenght: file.size });
      }
      chunkInfos.push({ gfid: gfid, chunks: chunks });
    }
    return chunkInfos;
  }
  getBatches(chunkInfos) {
    let batches = [];
    let maxChunksInBatch = 10;
    for(let ci of chunkInfos) {
      let chunkParts = Math.ceil(ci.chunks.length/maxChunksInBatch);
      for (let i = 0; i < chunkParts; i++) {
        const fromChunk = i * maxChunksInBatch;
        const toChunk = fromChunk + maxChunksInBatch;
        let batchChunks = ci.chunks.slice(fromChunk, toChunk);
        batches.push({ gfid: ci.gfid, chunks: batchChunks });
      }
    };
    return batches;
  }
  uploadGFIDChunks(gfid, chunks) {
    let tasks = [];
    const maxTry = 5; // 5 retries
    let tryUploadFunc = async (chunk, currentTry = 0) => {
      let success = await this.Api.uploadChunkGFID(chunk).then(async (res) => {
        if(!res.data.success) {
          if(currentTry < maxTry) {
            let res = await tryUploadFunc(chunk, currentTry + 1);
            return res.success;
          }
          else
            return false;
        }
        return true;
      })
      .catch(async () => {
        if(currentTry < maxTry) {
          let res = await tryUploadFunc(chunk, currentTry + 1);
          return res.success;
        }
        else
          return false;
      });
      return { gfid: gfid, success: success };
    } 

    chunks.forEach(chunk => {
      tasks.push(tryUploadFunc(chunk));
    })

    return Promise.all(tasks);
  }
  async finalizeUploadFileByChunks(results, successCallback, errorCallback) {
    let successFiles = [];
    let errorFiles = [];
    let tasks = [];

    
    results.forEach(async (r) => {
      if(r.success) {
        successFiles.push(r.gfid);   
        tasks.push(this.Api.acceptChunkFileUpload(r.gfid));
      }
      else {
        errorFiles.push(r.gfid);
        tasks.push(this.Api.errorChunkFileUpload(r.gfid));
      }
    });

    if(successFiles.length > 0 && successCallback && typeof successCallback === 'function') {
      tasks.push(successCallback(successFiles));
    }

    if(errorFiles.length > 0 && errorCallback && typeof errorCallback === 'function') {
      tasks.push(errorCallback(errorFiles));
    }

    await Promise.all(tasks);
  }
}
