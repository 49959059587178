<template>
  <ProSpaceModal
    v-model="localModelValue"
    :title="`${masterName} — ${$t('changeHistory')}`"
    icon="history"
    width="1200"
    without-padding
    :content-style="{ height: '80vh' }"
    @close="closeModal"
    :cancelTxt="$t('closeTxt')"
    applyTxt=""
  >
    <template v-slot:default>
      <ProSpaceGeoLayout>
        <template #center>
          <ProSpaceRadioButtonSecondary ref="tabs"
            v-model="activeTab"
            :tabs="tabs"
            @update:modelValue="tabChanged"
            @update:tabs="tabsUpdated"
          >
            <template v-for="t in tabs" :key="t.value" v-slot:[t.value]>
              <component
                :class="t.modelName"
                :is="`${t.modelName}History`"
                :ref="`${t.value}-grid`"
                :historyService="historyService"
                :historyCustomServices="customServices"
                :defaultFilter="defaultFilter"
                :name="`grid-${t.modelName}`"
                @selectedHandler="selectedHandler"
                @openFromReference="openFromReference"
              >
              </component>
            </template>
          </ProSpaceRadioButtonSecondary>
        </template>
        <template #right>
          <HistoryPanel
            v-if="spShow"
            ref="right-panel"
            :historyService="historyService"
            :record="selectedModel"
            :getActualName="$refs[`${activeTab.value}-grid`].getActualName"
            :getActualId="$refs[`${activeTab.value}-grid`].getActualId"
            :getActualStatus="$refs[`${activeTab.value}-grid`].getActualStatus"
            @showReferenceChanges="showReferenceChanges"
            @showReferenceChangesActual="showReferenceChangesActual"
            v-bind="optionsPanel"
          />
        </template>
      </ProSpaceGeoLayout>
    </template>
  </ProSpaceModal>
</template>
<script>
import {
  ProSpaceModal,
  ProSpaceMainGrid,
  ProSpaceGeoLayout,
  ProSpaceRadioButtonSecondary,
} from "prospace-components-library";
import { HistoryService } from "../../services/HistoryService";
import { OperationService } from "../../services/OperationService";
import HistoryPanel from "./HistoryPanel";
/*ImportHistoryGridsStart*/
import NewsItemApiModelHistory from "../../../../Services/NewsService/NewsService.Frontend/views/NewsItems/components/NewsItemApiModelHistory.vue";
import NewsItemTemplateApiModelHistory from "../../../../Services/NewsService/NewsService.Frontend/views/NewsItemTemplates/components/NewsItemTemplateApiModelHistory.vue";
import TaskAssignmentTemplateApiModelHistory from "../../../../Services/TaskService/TaskService.Frontend/views/TaskAssignmentTemplates/components/TaskAssignmentTemplateApiModelHistory.vue";
import DocumentApiModelHistory from "../../../../Services/DocumentService/DocumentService.Frontend/views/Documents/components/DocumentApiModelHistory.vue";
import TaskAssignmentApiModelHistory from "../../../../Services/TaskService/TaskService.Frontend/views/TaskAssignments/components/TaskAssignmentApiModelHistory.vue";
import FaqApiModelHistory from "../../../../Services/Information/InformationService.Frontend/views/Faqs/components/FaqApiModelHistory.vue";
import UsefulLinkApiModelHistory from "../../../../Services/Information/InformationService.Frontend/views/UsefulLinks/components/UsefulLinkApiModelHistory.vue";
import BaseTypeApiModelHistory from "../../../../Services/BaseType/BaseTypeService.Frontend/views/BaseTypes/components/BaseTypeApiModelHistory.vue";
import SystemDirectoryApiModelHistory from "../../../../Services/BaseType/BaseTypeService.Frontend/views/SystemDirectories/components/SystemDirectoryApiModelHistory.vue";
import SecurityUserApiModelHistory from "../../../../Services/SecurityAdmin/ProSpace.SecurityAdmin.Frontend/views/Users/components/SecurityUserApiModelHistory.vue";
import SecurityUserRoleApiModelHistory from "../../../../Services/SecurityAdmin/ProSpace.SecurityAdmin.Frontend/views/Users/components/SecurityUserRoleApiModelHistory.vue";
import SecurityRoleApiModelHistory from "../../../../Services/SecurityAdmin/ProSpace.SecurityAdmin.Frontend/views/Roles/components/SecurityRoleApiModelHistory.vue";
import SecurityUseCaseApiModelHistory from "../../../../Services/SecurityAdmin/ProSpace.SecurityAdmin.Frontend/views/UseCases/components/SecurityUseCaseApiModelHistory.vue";
import SecurityUseCaseClaimApiModelHistory from "../../../../Services/SecurityAdmin/ProSpace.SecurityAdmin.Frontend/views/UseCases/components/SecurityUseCaseClaimApiModelHistory.vue";
import MenuItemApiModelHistory from "../../../../Services/SecurityAdmin/ProSpace.SecurityAdmin.Frontend/views/MenuItems/components/MenuItemApiModelHistory.vue";
import NotificationApiModelHistory from "../../../../Services/Notification/ProSpace.Notification.Frontend/views/Notification/components/NotificationApiModelHistory.vue";
import JobTriggerConfigurationApiModelHistory from "../../../../Services/Scheduler/ProSpace.Scheduler.Frontend/views/SystemTasks/components/JobTriggerConfigurationApiModelHistory.vue";
import TaskQueueItemApiModelHistory from "../../../../Services/Scheduler/ProSpace.Scheduler.Frontend/views/UserTasks/components/TaskQueueItemApiModelHistory.vue";
import DistributorApiModelHistory from "../../../../Services/MasterData/MasterData.Frontend/views/Distributors/components/DistributorApiModelHistory.vue";
import AnotherContactPersonApiModelHistory from "../../../../Services/MasterData/MasterData.Frontend/views/AnotherContactPersons/components/AnotherContactPersonApiModelHistory.vue";
import AuthorizedContactPersonApiModelHistory from "../../../../Services/MasterData/MasterData.Frontend/views/AuthorizedContactPersons/components/AuthorizedContactPersonApiModelHistory.vue";
import EmpoweredContactPersonApiModelHistory from "../../../../Services/MasterData/MasterData.Frontend/views/EmpoweredContactPersons/components/EmpoweredContactPersonApiModelHistory.vue";
import MarsContactPersonApiModelHistory from "../../../../Services/MasterData/MasterData.Frontend/views/MarsContactPersons/components/MarsContactPersonApiModelHistory.vue";
import WorkflowSettingApiModelHistory from "../../../../Services/Workflow/ProSpace.Workflow.Frontend/views/workflow/components/WorkflowSettingApiModelHistory.vue";
import WorkflowSettingItemApiModelHistory from "../../../../Services/Workflow/ProSpace.Workflow.Frontend/views/workflow/components/WorkflowSettingItemApiModelHistory.vue";
import NotificationLogApiModelHistory from "../../../../Services/Notification/ProSpace.Notification.Frontend/views/NotificationLogs/components/NotificationLogApiModelHistory.vue";
import OrgLinkApiModelHistory from "../../../../Services/SecurityAdmin/ProSpace.SecurityAdmin.Frontend/views/Users/components/OrgLinkApiModelHistory.vue";
import CompetenceDirectoryApiModelHistory from "../../../../Services/Competence/CompetenceService.Frontend/views/CompetenceDirectories/components/CompetenceDirectoryApiModelHistory.vue";
import CompetenceApiModelHistory from "../../../../Services/Competence/CompetenceService.Frontend/views/Competences/components/CompetenceApiModelHistory.vue";
import CompetenceTaskApiModelHistory from "../../../../Services/Competence/CompetenceService.Frontend/views/CompetenceTasks/components/CompetenceTaskApiModelHistory.vue";
import CompetenceTaskAttachmentApiModelHistory from "../../../../Services/Competence/CompetenceService.Frontend/views/CompetenceTasks/components/CompetenceTaskAttachmentApiModelHistory.vue";
import CompetenceRecipientApiModelHistory from "../../../../Services/Competence/CompetenceService.Frontend/views/Competences/components/CompetenceRecipientApiModelHistory.vue";
/*ImportHistoryGridsEnd*/
import { mapGetters } from "vuex";
import _ from "lodash";
export default {
  name: "ProSpaceHistoryModal",
  components: {
    ProSpaceModal,
    ProSpaceMainGrid,
    ProSpaceGeoLayout,
    HistoryPanel,
    ProSpaceRadioButtonSecondary,
    /*ComponentsHistoryGridsStart*/
    TaskAssignmentTemplateApiModelHistory,
    DocumentApiModelHistory,
    TaskAssignmentApiModelHistory,
    FaqApiModelHistory,
    UsefulLinkApiModelHistory,
    BaseTypeApiModelHistory,
    SystemDirectoryApiModelHistory,
    SecurityUserApiModelHistory,
    SecurityUserRoleApiModelHistory,
    SecurityRoleApiModelHistory,
    SecurityUseCaseApiModelHistory,
    SecurityUseCaseClaimApiModelHistory,
    MenuItemApiModelHistory,
    NotificationApiModelHistory,
    JobTriggerConfigurationApiModelHistory,
    TaskQueueItemApiModelHistory,
    NewsItemApiModelHistory,
    NewsItemTemplateApiModelHistory,
    DistributorApiModelHistory,
    AnotherContactPersonApiModelHistory,
    AuthorizedContactPersonApiModelHistory,
    EmpoweredContactPersonApiModelHistory,
    MarsContactPersonApiModelHistory,
    WorkflowSettingApiModelHistory,
    WorkflowSettingItemApiModelHistory,
    NotificationLogApiModelHistory,
    OrgLinkApiModelHistory,
    CompetenceDirectoryApiModelHistory,
    CompetenceApiModelHistory,
    CompetenceTaskApiModelHistory,
    CompetenceTaskAttachmentApiModelHistory,
    CompetenceRecipientApiModelHistory
    /*ComponentsHistoryGridsEnd*/
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    modelName: {
      type: String,
      required: true,
    },
    optionsPanel: {
      type: Object,
      default: null
    }
  },
  watch: {
    modelValue(val) {
      if (!val) {
        this.selectedModel = {};
        this.tabs = [
          {
            value: "tab-1",
            label: this.$t(`${this.modelName}Changes`) ?? this.$t('historyMain'),
            modelName: this.modelName,
            isActive: true,
            masterId: null,
          },
        ];
        this.activeTabName = "tab-1";
      } else {
        this.historyService.modelName = this.modelName;
        this.historyService.loadModelInfo();
      }
      this.$emit("update:modelValue", val);
    },
  },
  data() {
    return {
      // Grid Panel
      selectedModel: {},
      spHide: false,
      panelModel: {},
      panelModels: [],

      historyService: new HistoryService(),
      //InlinePanel
      masterName: "",
      activeTabName: "tab-1",
      tabs: [
        {
          value: "tab-1",
          label: this.$t(`${this.modelName}Changes`) ?? this.$t('historyMain'),
          modelName: this.modelName,
          masterId: null,
        },
      ],
    };
  },
  methods: {
    openFromReference(record, fieldName) {
      const conf = this.referenceFieldConfs[fieldName];
      let keyField = Object.keys(this.referenceFieldConfs).find(
        (key) =>
          this.referenceFieldConfs[key].modelName === conf.modelName &&
          this.referenceFieldConfs[key].isKey === true
      );
      let tab = this.tabs.find(
        (t) =>
          t.masterId === record.actualModel[keyField] &&
          t.modelName === conf.modelName
      );
      
      if (tab) {
        this.selectTab(tab)
        return;
      }

      const lastTabNum = this.tabs
        .map(t => Number(t.value.split('-')[1]))
        .sort(function (a, b) { return b - a; })[0];

      this.tabs.push({
        value: `tab-${lastTabNum + 1}`,
        label: this.$t(`${conf.modelName}Changes`),
        modelName: conf.modelName,
        isReference: false,
        masterId: record.actualModel[keyField],
        deleted: true,
      });

      this.selectTab(_.last(this.tabs))
    },
    showReferenceChanges(modelName, groupId) {
      let tab = this.tabs.find(
        (t) => t.groupId === groupId && t.modelName === modelName
      );
      if (tab) {
        this.selectTab(tab)
        return;
      }

      const lastTabNum = this.tabs
        .map(t => Number(t.value.split('-')[1]))
        .sort(function (a, b) { return b - a; })[0];

      this.tabs.push({
        value: `tab-${lastTabNum + 1}`,
        label: this.$t(`${modelName}Changes`),
        modelName: modelName,
        groupId: groupId,
        isReference: true,
        deleted: true,
      });

      this.selectTab(_.last(this.tabs))
    },
    showReferenceChangesActual(modelName, filterField, filterValue) {
      let tab = this.tabs.find(
        (t) => t.filterField === filterField && t.filterValue === filterValue && t.modelName === modelName
      );
      if (tab) {
        this.selectTab(tab)
        return;
      }

      const lastTabNum = this.tabs
        .map(t => Number(t.value.split('-')[1]))
        .sort(function (a, b) { return b - a; })[0];

      this.tabs.push({
        value: `tab-${lastTabNum + 1}`,
        label: this.$t(`${modelName}Changes`),
        modelName: modelName,
        filterField: filterField,
        filterValue: filterValue,
        isReferenceActual: true,
        deleted: true,
      });

      this.selectTab(_.last(this.tabs))
    },
    tabChanged(tabInfo) {
      this.activeTabName = tabInfo.value;
      this.selectedModel = {};
      if (tabInfo.modelName) this.historyService.modelName = tabInfo.modelName;
      this.historyService.loadModelInfo();
    },
    tabsUpdated(tabs) {
      this.tabs = tabs;
    },
    selectedHandler({data}) {
      this.selectedModel = data
    },
    closeModal() {
      this.$emit("update:modelValue", false);
    },
    getColumnValue(config, record) {
      if (config.value && typeof config.value === "function")
        return config.value(record);

      return record[config.field];
    },
    selectTab(tab) {
      this.$refs.tabs.handlerSelect(tab);
    },
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
    }),
    customServices() {
      return [
        {
          type: "checkboxes",
          service: new OperationService(),
          getMethod: "get",
          model: "operationType",
          label: this.$t("operation"),
          optionValue: "operationType",
          optionLabel: "text",
          selected: {},
        },
        {
          type: "dates-range-time",
          model: "editDate",
          label: this.$t("editDate"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
      ];
    },
    referenceFieldConfs() {
      return this.historyService.referenceFieldConfs();
    },
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        this.$emit("update:modelValue", newVal);
      },
    },
    defaultFilter() {
      let filter = {};
      if (this.activeTab.isReference) {
        filter["GroupIds"] = {
          any: [{ any: this.activeTab.groupId }],
        };
      } else if (this.activeTab.isReferenceActual) {
        filter[`ActualModel/${this.activeTab.filterField}`] = { and: [this.activeTab.filterValue] };
      } else {
        filter["ActualModel/Id"] = { and: [this.activeTab.masterId] };
      }
      return filter;
    },
    spShow() {
      return !!this.selectedModel && Object.keys(this.selectedModel).length > 0;
    },
    activeTab() {
      return this.tabs.find((t) => t.value === this.activeTabName);
    },
  },
};
</script>
