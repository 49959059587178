<template>
  <ProSpaceTree
    ref="tree"
    class="position-select-tree"
    propKey="id"
    isAll
    bigPaddingChildren
    noExpandCheckChildren
    noMountedSelectNode
    :searchable="false"
    :service="positionService"
    :getNodeMethod="positionGetMethod"
    :selectionMode="positionSelectionMode"
    :defaultFilter="positionFilter"
    :propLabel="propLabel"
    :fnCheckHandler="positionCheckHandler"
    :fnDisabledCheckbox="positionsDisabled"
    :noData="$t('noData')"
    @loaded="handlerLoaded"
    @select="$emit('selectedItem', $event)"
    :class="{'position-select-tree--last-select-mode': selectLastNodes}"
  >
    <template #empty>
      <ProSpaceEmptyGrid style="min-height: 283px;"
        icon="error-message"
        :title="$t('noDataTitle')"
        :text="$t('noDataTxt')"
      />
    </template>
  </ProSpaceTree>
</template>

<script>
import {
  ProSpaceTree,
  ProSpaceEmptyGrid
} from "prospace-components-library";
export default {
  components: {
    ProSpaceTree,
    ProSpaceEmptyGrid
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    positionService: {
      type: Object
    },
    positionGetMethod: {
      type: String
    },
    positionSelectionMode: {
      type: String,
      default: "checkbox"
    },
    positionFilter: {
      type: String
    },
    propLabel: {
      type: String,
      default: "name"
    },
    checkedPositions: {
      type: Array,
      default: []
    },
    expandAll: {
      type: Boolean,
      default: false
    },
    selectLastNodes: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      positions: [],
      localReadonly: false
    }
  },
  methods: {
    refresh() {
      this.$refs.tree.getData();
    },
    getNodesList() {
      return this.$refs.tree.list
    },
    handlerLoaded() {
      this.setCheckedPositions()
      if (this.expandAll) {
        this.expandAllNodes()
      }
      if (this.selectLastNodes) {
        this.selectedLastNodes()
      }
    },
    expandAllNodes() {
      const tree = this.$refs.tree
      tree.expandAll()
    },
    selectedLastNodes() {
      const tree = this.$refs.tree
      const selectedKey = {}

      const selectNode = (node) => {
        if (node.children && node.children.length) {
          for (let child of node.children) {
            selectNode(child);
          }
        } else {
          selectedKey[node.key] = true
        }
      }

      for (let node of tree.nodes) {
        selectNode(node);
      }

      tree.selectedKey = { ...selectedKey };
    },
    setCheckedPositions() {
      if(this.positionSelectionMode === "")
        return;

      if(this.positions.length === 0 && this.checkedPositions && this.checkedPositions.length > 0)
        this.positions = [...this.checkedPositions];

      let checkedNodes = this.$refs.tree.checkedNodes;
      this.positions.forEach(x => {
        if(!checkedNodes[x.distributorOrgId])
          checkedNodes[x.distributorOrgId] = { checked: true, partialChecked: false };
      });
    },
    positionCheckHandler(node, checkedNodes, list, action) {
      if(action === "uncheck") {
        this.positions = this.positions.filter(x => x.distributorOrgId !== node.id);

        let childNodes = this.positions.filter(x => x.parentId === node.id);
        if(childNodes && childNodes.length > 0) {
          let newParentId = null;
          let tempParentId = node.parentId;
          while(tempParentId && !newParentId) {
            let parentNode = Object.values(list).find(x => x.id === tempParentId);
            let positionParentNode = this.positions.find(x => x.distributorOrgId === parentNode.id);
            if(positionParentNode) {
              newParentId = positionParentNode.distributorOrgId;
            } else {
              tempParentId = parentNode.parentId
            }
          }

          childNodes.forEach(childNode => {
            this.positions.find(x => x.distributorOrgId === childNode.distributorOrgId).parentId = newParentId;
          });
        }
      } else {
        this.positions.push({
            distributorOrgId: node.id,
            parentId: node.parentId
          });

        this.updateChildNodes(node);
        this.checkParentPosition(list, node, checkedNodes, node);
      }

      this.$emit("changed", this.positions);
    },
    checkParentPosition(list, node, checkedNodes, checkedNode) {
      let parent = list[node["parentId"]];
      if (!parent) {
        return;
      }
      if (!checkedNodes[parent.key]) {
        this.positions.push({
          distributorOrgId: parent.id,
          parentId: parent.parentId
        });
        this.updateChildNodes(parent);
        checkedNodes[parent.key] = {
          checked: true,
          partialChecked: false,
        };
      }
      this.checkParentPosition(list, parent, checkedNodes, checkedNode);
    },
    updateChildNodes(node) {
      let checkChildNodes = node.children;
      while(checkChildNodes && checkChildNodes.length > 0) {
        checkChildNodes.forEach(x => {
          let positionNode = this.positions.find(p => p.distributorOrgId === x.id);
          if(positionNode) {
            positionNode.parentId = node.id;
          } else {
            if(x.children && x.children.length > 0) {
              checkChildNodes = checkChildNodes.concat(x.children)
            }
          }
          checkChildNodes = checkChildNodes.filter(c => x.id !== c.id);
        });
      }
    },
    positionsDisabled(node) {
      return this.readOnly || this.localReadonly;
    }
  }
};
</script>

<style lang="scss">
.position-select-tree {
  &--last-select-mode {
    .p-tree .p-tree-container .p-treenode {
      pointer-events: none;
      .p-tree-toggler {
        pointer-events: all;
      }
      .p-treenode-content.p-highlight {
        background: transparent;
      }
    }
  }
}
</style>
