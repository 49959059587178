<template>
  <ProSpaceGeoLayout class="faq" v-if="model">
    <template #top>
      <ProSpaceTopPanel
        :header="
          model.question
            ? model.question
            : $t('newTxt') + ' ' + $t('faqQuestion')
        "
        back
        @back="backHandler"
      >
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout :is-templated="true" :template-center="true">
        <template #center>
          <ProSpaceTabs
            :tabs="overviewTabs"
            :loading="loading"
            :refLoading="mixinLoadingAccess"
            :withoutMarginBottom="true"
            @select-tab="handlerSelectTab"
          >
            <ProSpaceAction
              :disabled="v$.model.$invalid"
              icon="check"
              :text="$t('save')"
              ref="action-save"
              @click="save()"
            />
            <template v-slot:tab-1>
              <ProSpaceVLayout>
                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('faqMainInfo')"
                      :bottom="$t('faqMainInfoAdditional')"
                      :gap="10"
                      break-word
                      type="third"
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceVLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="20"
                    >
                      <ProSpaceDropdown
                        v-model="model.faqTypeCode"
                        ref="faqTypeCode-ProSpaceDropdown"
                        :label="$t('faqType')"
                        :service="baseTypeService"
                        :placeholder="$t('faqType')"
                        propValue="code"
                        propLabel="name"
                        getMethod="grid"
                        argMethod="$filter=(systemDirectoryCode eq 'FAQ types' and disabled eq false)&$orderby=name asc"
                        :invalid="v$.model.faqTypeCode.$error"
                        :invalid-messages="v$.model.faqTypeCode.$errors"
                        @blur="v$.model.faqTypeCode.$touch"
                      />
                      <ProSpaceInputText
                        v-model="model.question"
                        ref="question-ProSpaceInputText"
                        :label="$t('faqQuestion')"
                        :placeholder="$t('faqQuestion')"
                        :invalid="v$.model.question.$error"
                        :invalid-messages="v$.model.question.$errors"
                        @blur="v$.model.question.$touch"
                      />
                      <ProSpaceInputText
                        v-model="model.shortAnswer"
                        ref="shortAnswer-ProSpaceInputText"
                        :label="$t('faqShortAnswer')"
                        :placeholder="$t('faqShortAnswer')"
                        :invalid="v$.model.shortAnswer.$error"
                        :invalid-messages="v$.model.shortAnswer.$errors"
                        :isTextarea="true"
                        :autoResize="false"
                        @blur="v$.model.shortAnswer.$touch"
                      />
                    </ProSpaceVLayout>
                  </template>
                  <template
                    v-if="
                      v$.model.faqTypeCode.$error ||
                      v$.model.question.$error ||
                      v$.model.shortAnswer.$error
                    "
                    v-slot:right
                  >
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>
                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('faqFileInfo')"
                      :bottom="$t('faqFileInfoAdditional')"
                      :gap="10"
                      break-word
                      type="third"
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceHLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="20"
                    >
                      <ProSpaceFile
                        ref="file-ProSpaceFile"
                        v-model="files"
                        :maxFileSize="209715200"
                        :fileLimit="1"
                        accept=".pdf"
                        :service="fileService"
                        :txtBtnUpload="$t('uploadFile')"
                        :txtEmptyFiles="$t('uploadFileDragAndDropText')"
                        :txtErrorStatus="$t('uploadStatusError')"
                        :invalidFileLimitMessage="$t('invalidFileLimitMessage')"
                        :invalidFileSizeMessage="$t('invalidFileSizeMessage')"
                        :invalidFileTypeMessage="$t('invalidFileTypeMessage')"
                      />
                    </ProSpaceHLayout>
                  </template>
                </ProSpaceGeoLayout>
              </ProSpaceVLayout>
            </template>
          </ProSpaceTabs>
        </template>
      </ProSpaceGeoLayout>
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import {
  ProSpaceTopPanel,
  ProSpaceInputText,
  ProSpaceAction,
  ProSpaceRadioButtonSecondary,
  ProSpaceGeoLayout,
  ProSpacePanelBlock,
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpaceTextBlock,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceDropdown,
  ProSpaceFile,
  ProSpaceInputCalendar,
  ProSpaceInputNumber,
  ProSpaceSearchfield,
  ProSpaceMultiSelect,
  ProSpaceCustomFilterPanel,
  ProSpaceIcon,
  ProSpaceWarning,
} from "prospace-components-library";
import { FaqService } from "../../services/FaqService.js";
import { FileService } from "../../../../../Frontends/ProSpace/services/FileService.js";
import {
  required,
  maxLength,
  helpers,
  minValue,
  maxValue,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { BaseTypeService } from "../../../../BaseType/BaseTypeService.Frontend/services/BaseTypeService";
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import {
  useStatistic
} from "@composes"
export default {
  mixins: [accessMixin],
  components: {
    ProSpaceTopPanel,
    ProSpaceGeoLayout,
    ProSpaceInputText,
    ProSpaceAction,
    ProSpaceRadioButtonSecondary,
    ProSpacePanelBlock,
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpaceTextBlock,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceDropdown,
    ProSpaceFile,
    ProSpaceInputCalendar,
    ProSpaceInputNumber,
    ProSpaceSearchfield,
    ProSpaceMultiSelect,
    ProSpaceCustomFilterPanel,
    ProSpaceIcon,
    ProSpaceWarning,
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    return { v$: useVuelidate(), setStatistic, distActions };
  },
  data() {
    this.tabs = [{ label: "Overview", value: "Overview" }];
    return {
      files: [],

      // Tabs
      modelTab: this.tabs[0], // Первое предустановленное значение

      // Common
      layoutName: "",
      model: null,
      loading: false,

      // Service
      faqService: new FaqService(),
      fileService: new FileService(),
      baseTypeService: new BaseTypeService(),

      getMethod: "get",
      masterId: null,

      // SaveFields
      saveFields: {
        Overview: ["faqTypeCode", "question", "shortAnswer", "files"],
      },

      pdfjsLib: false,
    };
  },
  validations() {
    return {
      model: {
        faqTypeCode: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        question: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        shortAnswer: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
      },
    };
  },
  mounted() {
    this.mountAccess();
    this.masterId = this.$route.params.id;
    this.getData();
  },
  methods: {
    handlerSelectTab({ selectedTab }) {
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: selectedTab.systemName
      })
    },
    async getData() {
      if (this.masterId === undefined) {
        this.model = {
          faqTypeCode:
            this.$route.query && this.$route.query.code
              ? this.$route.query.code
              : null,
          question: "",
          shortAnswer: "",
        };
        return;
      }
      const result = await this.faqService[this.getMethod](this.masterId);
      this.model = result.data;

      if (this.model.files)
        this.files = this.model.files.map((f) => {
          let r = new File([], f.fileName);
          r.gfid = f.gfid;
          r.fileLength = f.fileLength;
          r.statusFlag = f.statusFlag;
          return r;
        });
      if (this.masterId === undefined) {
        this.model.faqTypeCode = null;
      }
      this.layoutName = `${this.model.name}`;
    },
    save() {
      this.loading = true;
      const me = this;
      const toCreate = me.files.filter((f) => !f.gfid);
      const actualFiles = me.files.filter((f) => f.gfid).map((f) => f.gfid);
      if (me.model.files)
        me.model.files = me.model.files.filter((f) =>
          actualFiles.includes(f.gfid)
        );
      if (toCreate.length > 0) {
        let fileInfos = toCreate
          .filter((f) => !f.id)
          .map((f) => {
            return {
              fileName: f.name,
              fileVisibleFlag: 0,
            };
          });
        me.fileService.createFilesRoot(fileInfos).then((response) => {
          let newFiles = response.data.data.map((f, i) => {
            return {
              fileName: f.fileName,
              gfid: f.gfid,
              fileLength: toCreate[i].size,
            };
          });
          me.model.files = me.model.files
            ? me.model.files.concat(newFiles)
            : newFiles;

          if (this.masterId) {
            me.faqService.set().then(() => {
              me.upload(newFiles, toCreate).then(() => {
                //TODO: update тоже возвращает модель, зачем делать getData?
                me.getData();
                this.loading = false;
              });
            });
          } else {
            me.faqService["create"](this.model).then((result) => {
              me.model.id = result.data[0].id;
              //TODO: понять почему для редактирования сразу после создания не подходит модель result.data[0]
              // this.model = result.data[0];
              let c = () => {
                this.masterId = result.data[0].id;
                this.getData();
                this.loading = false;
              };
              if (Array.isArray(newFiles) && newFiles.length > 0) {
                me.upload(newFiles, toCreate).then(() => {
                  c();
                });
              } else c();
            });
          }
        });
      } else {
        if (this.masterId) {
          me.faqService.set().then(() => {
            //TODO: update тоже возвращает модель, зачем делать getData?
            me.getData();
            this.loading = false;
          });
        } else {
          me.faqService["create"](this.model).then((result) => {
            //TODO: понять почему для редактирования сразу после создания не подходит модель result.data[0]
            // this.model = result.data[0];
            this.masterId = result.data[0].id;
            this.getData();
            this.loading = false;
          });
        }
      }
    },
    async upload(newFiles, toCreate) {
      await this.fileService.uploadChunks(newFiles, toCreate, this.uploadSuccess, this.uploadError);
    },
    async uploadSuccess(gfids) {
      await this.faqService.acceptFiles(gfids, this.model.id);
    },
    async uploadError(gfids) {
      await this.faqService.errorFiles(gfids, this.model.id);
    },
    submitFilter(nameRef = "grid") {
      if (!this.$refs[nameRef]) return;
      this.$refs[nameRef].getRecords(true);
    },
    backHandler() {
      this.$router.go(-1);
    },
  },
  computed: {
    refsForCheckInMixin() {
      let refs = [
        "faqTypeCode-ProSpaceDropdown",
        "question-ProSpaceInputText",
        "shortAnswer-ProSpaceInputText",
        "file-ProSpaceFile",
        "action-save",
      ];

      return refs;
    },
    overviewTabs() {
      return [{ name: this.$t("overview"), systemName: "overview" }];
    },
  },
};
</script>
<style lang="scss"></style>
