import { Transformer } from "../../../../Transformer/index.js";
import { TIME_ZONE_INFO } from "../../../../Frontends/Common/utils/consts.js";
import i18n from "../../../../Frontends/Common/utils/i18n.js";

export class DateHelperService {
  constructor() {
    this.transformer = new Transformer();
  }
  async getTimeZone(args) {
    // return await this.transformer.getGrid(this, "getLocalTimeZone", args);
    return Promise.resolve(this.getLocalTimeZone())
  }
  async getDateFormats(args) {
    //return await this.transformer.getGrid(this, "getLocalDateFormats", args);
    return Promise.resolve(this.getLocalDateFormats())
  }
  async getTimeFormats(args) {
    //return await this.transformer.getGrid(this, "getLocalTimeFormats", args);
    return Promise.resolve(this.getLocalTimeFormats())
  }

  getLocalDateFormats(args) {
    return {
      data: {
        records: [
          {label: i18n.global.t("dateFormatDDMMYYYY"), value: "DD.MM.YYYY"},
          {label: i18n.global.t("dateFormatMMDDYYYY"), value: "MM.DD.YYYY"},
          {label: i18n.global.t("dateFormatYYYYMMDD"), value: "YYYY.MM.DD"},
        ]
      }
    };
  }
  getLocalTimeFormats(args) {
    return {
      data: {
        records: [
          {label: i18n.global.t("timeFormat12"), value: "hh:mm:ss"},
          {label: i18n.global.t("timeFormat24"), value: "HH:mm:ss"}
        ]
      }
    };
  }
  getLocalTimeZone(args) {
    return {
      data: {
        records: [
          { label: "UTC-12:00", value: "Dateline Standard Time" },
          { label: "UTC-11:00", value: "UTC-11" },
          { label: "UTC-10:00", value: "Hawaiian Standard Time" },
          { label: "UTC-09:30", value: "Marquesas Standard Time" },
          { label: "UTC-09:00", value: "UTC-09" },
          { label: "UTC-08:00", value: "UTC-08" },
          { label: "UTC-07:00", value: "US Mountain Standard Time" },
          { label: "UTC-06:00", value: "Central America Standard Time" },
          { label: "UTC-05:00", value: "SA Pacific Standard Time" },
          { label: "UTC-04:00", value: "SA Western Standard Time" },
          // { label: "UTC-03:30", value: "Newfoundland Standard Time" },
          { label: "UTC-03:00", value: "Tocantins Standard Time" },
          { label: "UTC-02:00", value: "UTC-02" },
          // { label: "UTC-02:00", value: "Mid-Atlantic Standard Time" },
          { label: "UTC-01:00", value: "Cape Verde Standard Time" },
          { label: "UTC+00:00", value: "Greenwich Standard Time" },
          { label: "UTC+01:00", value: "W. Central Africa Standard Time" },
          { label: "UTC+02:00", value: "Kaliningrad Standard Time" },
          { label: "UTC+03:00", value: "Russian Standard Time" },
          { label: "UTC+03:30", value: "Iran Standard Time" },
          { label: "UTC+04:00", value: "Astrakhan Standard Time" },
          { label: "UTC+04:30", value: "Afghanistan Standard Time" },
          { label: "UTC+05:00", value: "Ekaterinburg Standard Time" },
          { label: "UTC+05:30", value: "India Standard Time" },
          { label: "UTC+05:45", value: "Nepal Standard Time" },
          { label: "UTC+06:00", value: "Omsk Standard Time" },
          { label: "UTC+06:30", value: "Myanmar Standard Time" },
          { label: "UTC+07:00", value: "North Asia Standard Time" },
          { label: "UTC+08:00", value: "North Asia East Standard Time" },
          { label: "UTC+08:45", value: "Aus Central W. Standard Time" },
          { label: "UTC+09:00", value: "Transbaikal Standard Time" },
          { label: "UTC+09:30", value: "AUS Central Standard Time" },
          { label: "UTC+10:00", value: "Vladivostok Standard Time" },
          { label: "UTC+10:30", value: "Lord Howe Standard Time" },
          { label: "UTC+11:00", value: "Magadan Standard Time" },
          { label: "UTC+12:00", value: "Russia Time Zone 11" },
          { label: "UTC+12:45", value: "Chatham Islands Standard Time" },
          { label: "UTC+13:00", value: "UTC+13" },
          { label: "UTC+14:00", value: "Line Islands Standard Time" }
        ]
      }
    };
  }
}
