import newsitems from "../../../../Services/NewsService/NewsService.Frontend/views/NewsItems/NewsItems";
import newsitem from "../../../../Services/NewsService/NewsService.Frontend/views/NewsItems/NewsItem";

import newsitemtemplates from "../../../../Services/NewsService/NewsService.Frontend/views/NewsItemTemplates/NewsItemTemplates";
import newsitemtemplate from "../../../../Services/NewsService/NewsService.Frontend/views/NewsItemTemplates/NewsItemTemplate";

export const newsserviceRoutes = [
  {
    path: "/newsservice/newsitems",
    name: "newsitems",
    component: newsitems,
  },
  {
    path: "/newsservice/newsitem",
    name: "newsitem-create",
    component: newsitem,
    meta: {
      menuitem: "/newsservice/newsitems",
    },
  },
  {
    path: "/newsservice/newsitem/:id",
    name: "newsitem",
    component: newsitem,
    meta: {
      menuitem: "/newsservice/newsitems",
    },
  },
  {
    path: "/newsservice/newsitemtemplates",
    name: "newsitemtemplates",
    component: newsitemtemplates,
  },
  {
    path: "/newsservice/newsitemtemplate",
    name: "newsitemtemplate-create",
    component: newsitemtemplate,
    meta: {
      menuitem: "/newsservice/newsitemtemplates",
    },
  },
  {
    path: "/newsservice/newsitemtemplate/:id",
    name: "newsitemtemplate",
    component: newsitemtemplate,
    meta: {
      menuitem: "/newsservice/newsitemtemplates",
    },
  },
];

export default newsserviceRoutes;
