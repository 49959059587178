<template>
  <ProSpaceGeoLayout class="newsitem" v-if="model">
    <template #top>
      <ProSpaceTopPanel :header="layoutName" back @back="backHandler">
        <template #right> </template>
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout
        :is-templated="true"
        :template-center="true"
        :template-right="true"
      >
        <template #center>
          <ProSpaceTabs
            v-model="modelTab"
            :tabs="overviewTabs"
            :loading="loading"
            :refLoading="refLoading"
            without-margin-bottom
            @select-tab="handlerSelectTab"
          >
            <ProSpaceAction
              v-if="
                !model.status ||
                model.status === 'newsDraft' ||
                model.status === 'none'
              "
              ref="action-saveDraft"
              :disabled="disabledSave"
              icon="check"
              :text="$t('saveDraft')"
              @click="setModelAndSave(false)"
            />
            <ProSpaceAction
              v-if="masterId"
              ref="action-createTemplate"
              icon="check"
              :text="$t('newsItemCreateTemplate')"
              @click="createTemplate()"
            />
            <ProSpaceAction
              v-if="model.status === 'newsDraft'"
              ref="action-publish"
              :disabled="disabledPublished"
              icon="check"
              :text="$t('publish')"
              @click="confirmPublish()"
            />
            <ProSpaceAction
              v-if="model.status === 'newsPublished'"
              ref="action-save"
              :disabled="disabledPublished"
              icon="check"
              :text="$t('save')"
              @click="setModelAndSave(true)"
            />
            <template v-slot:tab-1>
              <ProSpaceHLayout
                isInlineH
                alignItems="flex-start"
              >
                <ProSpaceButton
                  type="tertiary"
                  class="important-button margin-top-20"
                  iconWidth="18px"
                  iconHeight="18px"
                  coloredIcon
                  :icon="
                    model.isImportant ? 'bookmark-solid' : 'bookmark-regular'
                  "
                  :label="$t('documentIsImportant')"
                  :disabled="isCreate"
                  :loading="importantLoading"
                  @click="saveIsImportant"
                />
                <ProSpaceHLayout
                  align-items="flex-start"
                  justifyContent="flex-end"
                  class="margin-top-20"
                  isInlineH
                  :gap="5"
                >
                  <ProSpaceButton
                    v-if="model.status === 'newsModer'"
                    ref="rejectPublish-button"
                    type="tertiary"
                    icon="ban"
                    :label="$t('reject')"
                    @click="
                      showReasonModal(
                        rejectStep,
                        'reject',
                        $t('rejectPublish')
                      )
                    "
                  />
                  <ProSpaceButton
                    v-if="model.status === 'newsModer'"
                    ref="acceptPublish-button"
                    :disabled="disabledPublished"
                    type="primary"
                    icon="check"
                    :label="$t('acceptPublish')"
                    @click="moderationConfirm()"
                  />
                  <ProSpaceVLayout
                    v-if="userinfo.orgLinkType !== 'Mars'
                      && model.readConfirmationRequired
                      && checkStatusAccess('approveTask-ProSpaceVLayout', model)
                      && checkAccess('approveTask-ProSpaceVLayout', model)"
                    align-items="flex-end"
                    justifyContent="flex-start"
                    :gap="10"
                  >
                    <ProSpaceButton
                      type="gray"
                      customIconColor="#27AE60"
                      disabled
                      :hint="$t('newsTaskHint')"
                      :coloredIcon="!model.taskApproved"
                      :icon="model.taskApproved ? 'check' : 'warning'"
                      :label="model.taskApproved ? $t('newsTaskApproved') : $t('newsTaskNotApproved')"
                      style="opacity: 1 !important; font-weight: 500; cursor: default; pointer-events: auto;"
                    />
                    <ProSpaceVLayout
                      v-if="model.taskApproved"
                      align-items="flex-end"
                      justifyContent="flex-start"
                      :gap="5"
                    >
                      <ProSpaceTextBlock
                        class="taskApproved-ProSpaceTextBlock"
                        :bottom="model.taskApprovedBy + '<br />' + formatDate(model.taskApprovedDate)"
                        type="third"
                      />
                    </ProSpaceVLayout>
                  </ProSpaceVLayout>
                  <ProSpaceButton
                    ref="approveTask-ProSpaceButton"
                    v-if="userinfo.orgLinkType !== 'Mars'
                      && model.readConfirmationRequired
                      && !model.taskApproved
                      && model.canApproveTask
                      && checkStatusAccess('approveTask-ProSpaceButton', model)
                      && checkAccess('approveTask-ProSpaceButton', model)"
                    type="primary"
                    icon="check"
                    :label="$t('accept')"
                    @click="showReasonModal(taskNextStep, null, $t('performTask'), true)"
                  />
                </ProSpaceHLayout>
              </ProSpaceHLayout>
              <ProSpaceGeoLayout class="geo-row" v-if="!masterId">
                <template v-slot:left>
                  <ProSpaceTextBlock
                    :top="$t('newsItemTemplateNameInfo')"
                    :bottom="$t('newsItemTemplateNameInfoAdditional')"
                    :gap="10"
                    type="third"
                    break-word
                  />
                </template>
                <template v-slot:center>
                  <ProSpaceHLayout
                    justify-content="space-between"
                    align-items="flex-start"
                    :gap="10"
                  >
                    <ProSpaceDropdown
                      ref="newsItemTemplateId-ProSpaceDropdown"
                      v-model="model.templateId"
                      :label="$t('newsItemTemplateTemplateName')"
                      :placeholder="$t('newsItemTemplateTemplateName')"
                      :invalid="v$.model.templateId.$error"
                      :invalid-messages="v$.model.templateId.$errors"
                      @blur="v$.model.templateId.$touch"
                      :service="newsItemTemplateService"
                      getMethod="grid"
                      propValue="id"
                      propLabel="templateName"
                      :argMethod="getTemplateFilter()"
                      @change="onTemplateChange"
                      style="width: calc(50% - 5px)"
                    />
                  </ProSpaceHLayout>
                </template>
                <template v-if="v$.model.templateId.$error" v-slot:right>
                  <ProSpaceWarning />
                </template>
              </ProSpaceGeoLayout>
              <ProSpaceGeoLayout class="geo-row">
                <template v-slot:left>
                  <ProSpaceTextBlock
                    :top="$t('newsItemTypeInfo')"
                    :bottom="$t('newsItemTypeInfoAdditional')"
                    :gap="10"
                    type="third"
                    break-word
                  />
                </template>
                <template v-slot:center>
                  <ProSpaceHLayout
                    justify-content="space-between"
                    align-items="flex-start"
                    :gap="10"
                  >
                    <ProSpaceDropdown
                      ref="type-ProSpaceDropdown"
                      v-model="model.type"
                      :label="$t('newsItemType')"
                      :placeholder="$t('newsItemType')"
                      :invalid="v$.model.type.$error"
                      :invalid-messages="v$.model.type.$errors"
                      @blur="v$.model.type.$touch"
                      :service="baseTypeService"
                      getMethod="grid"
                      propValue="code"
                      propLabel="name"
                      argMethod="$filter=(systemDirectoryCode eq 'News Type' and disabled eq false)&$orderby=name asc"
                      @change="(item) => onTypeChange(item)"
                      style="width: calc(50% - 5px)"
                    />
                  </ProSpaceHLayout>
                </template>
                <template v-if="v$.model.type.$error" v-slot:right>
                  <ProSpaceWarning />
                </template>
              </ProSpaceGeoLayout>
              <ProSpaceGeoLayout class="geo-row">
                <template v-slot:left>
                  <ProSpaceTextBlock
                    :top="$t('newsItemInfo')"
                    :bottom="$t('newsItemInfoAdditional')"
                    :gap="10"
                    type="third"
                    break-word
                  />
                </template>
                <template v-slot:center>
                  <ProSpaceVLayout
                    justify-content="space-between"
                    align-items="flex-start"
                    :gap="20"
                  >
                    <ProSpaceInputText
                      ref="name-ProSpaceInputText"
                      v-model="model.name"
                      :label="$t('newsItemName')"
                      :placeholder="$t('newsItemName')"
                      :invalid="v$.model.name.$error"
                      :invalid-messages="v$.model.name.$errors"
                      @blur="v$.model.name.$touch"
                    />
                    <ProSpaceExtendedTextArea
                      ref="message-ProSpaceInputText"
                      v-model="model.message"
                      :label="$t('newsItemMessage')"
                      :placeholder="$t('newsItemMessage')"
                      :invalid="v$.model.message.$error"
                      :invalid-messages="v$.model.message.$errors"
                      :t="$t"
                      :locale="$i18n.locale"
                      @blur="v$.model.message.$touch"
                    />
                    <ProSpaceInputCalendar
                      ref="publishDate-ProSpaceInputText"
                      v-model="model.publishDate"
                      :date-format="userinfo.dateFormat"
                      showTime="true"
                      showSeconds="true"
                      :minDate="new Date()"
                      :label="$t('newsItemPublishDate')"
                      :placeholder="$t('newsItemPublishDate')"
                      :invalid="v$.model.publishDate.$error"
                      :invalid-messages="v$.model.publishDate.$errors"
                      @blur="v$.model.publishDate.$touch"
                      @update:modelValue="publishDateUpdate()"
                      style="width: calc(50% - 5px)"
                    />
                  </ProSpaceVLayout>
                </template>
                <template
                  v-if="
                    v$.model.name.$error ||
                    v$.model.message.$error ||
                    v$.model.publishDate.$error
                  "
                  v-slot:right
                >
                  <ProSpaceWarning />
                </template>
              </ProSpaceGeoLayout>
              <ProSpaceGeoLayout class="geo-row">
                <template v-slot:left>
                  <ProSpaceTextBlock
                    :top="$t('newsItemExtraInfo')"
                    :bottom="$t('newsItemExtraInfoAdditional')"
                    :gap="10"
                    type="third"
                    break-word
                  />
                </template>
                <template v-slot:center>
                  <ProSpaceVLayout
                    justify-content="space-between"
                    align-items="flex-start"
                    :gap="20"
                  >
                    <ProSpaceInputChips
                      v-model="tags"
                      ref="tags-ProSpaceInputChips"
                      placeholder="+"
                      :label="$t('newsItemTags')"
                      :invalid="v$.model.tags.$error"
                      :invalid-messages="v$.model.tags.$errors"
                      @blur="v$.model.tags.$touch"
                    />
                    <ProSpaceHLayout
                      justify-content="flex-start"
                      align-items="center"
                      :gap="5"
                    >
                      <ProSpaceInputSwitch
                        ref="highImportant-ProSpaceInputSwitch"
                        v-model="model.highImportant"
                        :label="$t('newsItemHighImportant')"
                      />
                      <ProSpaceIcon
                        v-hint="$t('newsItemExtraInfoAdditionalImportantHint')"
                        icon="question"
                      />
                    </ProSpaceHLayout>
                    <ProSpaceHLayout
                      justify-content="flex-start"
                      align-items="center"
                      :gap="5"
                    >
                      <ProSpaceInputSwitch
                        ref="isPersonal-ProSpaceInputSwitch"
                        v-model="model.isPersonal"
                        :label="$t('newsItemIsPersonal')"
                      />
                      <ProSpaceIcon
                        v-hint="$t('newsItemExtraInfoAdditionalPersonalHint')"
                        icon="question"
                      />
                    </ProSpaceHLayout>
                    <ProSpaceHLayout
                      justify-content="flex-start"
                      align-items="center"
                      :gap="5"
                    >
                      <ProSpaceInputSwitch
                        ref="isDistributorStructureRestricted-ProSpaceInputSwitch"
                        v-model="model.isDistributorStructureRestricted"
                        :label="$t('newsItemIsDistributorStructureRestricted')"
                        @update:modelValue="isDistributorStructureRestrictedChange"
                      />
                      <ProSpaceIcon
                        v-hint="$t('newsItemIsDistributorStructureRestrictedHint')"
                        icon="question"
                      />
                    </ProSpaceHLayout>
                    <ProSpaceHLayout
                      justify-content="flex-start"
                      align-items="center"
                      :gap="5"
                    >
                      <ProSpaceInputSwitch
                        ref="readConfirmationRequired-ProSpaceInputSwitch"
                        v-model="model.readConfirmationRequired"
                        :label="$t('newsItemReadConfirmationRequired')"
                      />
                      <ProSpaceIcon
                        v-hint="
                          $t('newsItemReadConfirmationRequiredHint')
                        "
                        icon="question"
                      />
                    </ProSpaceHLayout>
                    <ProSpaceHLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="10"
                    >
                      <ProSpaceInputNumber
                        ref="daysToDueDate-ProSpaceInputNumber"
                        v-model="model.daysToDueDate"
                        :max="10000"
                        :label="$t('newsItemDaysToDueDate')"
                        @input="(value) => setDueDate(value)"
                        textOverflow
                        style="width: calc(50% - 5px)"
                      />
                      <ProSpaceInputCalendar
                        ref="dueDate-ProSpaceInputCalendar"
                        v-model="model.dueDate"
                        :date-format="userinfo.dateFormat"
                        :label="$t('newsItemDueDate')"
                        :placeholder="$t('newsItemDueDate')"
                        :invalid="v$.model.dueDate.$error"
                        :invalid-messages="v$.model.dueDate.$errors"
                        @blur="v$.model.dueDate.$touch"
                        style="
                          display: flex;
                          flex-direction: column;
                          justify-content: space-between;
                          width: calc(50% - 5px);
                        "
                      />
                    </ProSpaceHLayout>
                  </ProSpaceVLayout>
                </template>
                <template
                  v-if="
                    v$.model.tags.$error ||
                    v$.model.highImportant.$error ||
                    v$.model.readConfirmationRequired.$error ||
                    v$.model.dueDate.$error
                  "
                  v-slot:right
                >
                  <ProSpaceWarning />
                </template>
              </ProSpaceGeoLayout>
              <ProSpaceGeoLayout
                class="geo-row"
                :class="{ 'geo-row--no-border': model.status !== 'taskModer' }"
              >
                <template v-slot:left>
                  <ProSpaceTextBlock
                    :top="$t('newsItemAttachmentsInfo')"
                    :bottom="$t('newsItemAttachmentsInfoAdditional')"
                    :gap="10"
                    type="third"
                    break-word
                  />
                </template>
                <template v-slot:center>
                  <ProSpaceHLayout
                    justify-content="space-between"
                    align-items="flex-start"
                    :gap="10"
                  >
                    <ProSpaceFile
                      v-model="attachments"
                      ref="attachments-ProSpaceFile"
                      accept=".doc, .docx, .docm, .rtf, .txt, .csv, .xml, .pdf, .png, .gif, .jpg, .jpeg, .ppt, .pptx, .potx, .potm, .pptm, .ppsx, .ppsm, .xls, .xlsx, .xlsm, .xlsb, .zip, .7z, .rar, .mp4, .avi, .mkv, .wmv"
                      :service="fileService"
                      :fileLimit="1000"
                      :maxFileSize="209715200"
                      :txtBtnUpload="$t('uploadFile')"
                      :txtEmptyFiles="$t('uploadFileDragAndDropText')"
                        :txtErrorStatus="$t('uploadStatusError')"
                      :invalidFileLimitMessage="$t('invalidFileLimitMessage')"
                      :invalidFileSizeMessage="$t('invalidFileSizeMessage')"
                      :invalidFileTypeMessage="$t('invalidFileTypeMessage')"
                    />
                  </ProSpaceHLayout>
                </template>
                <template v-if="v$.model.attachments.$error" v-slot:right>
                  <ProSpaceWarning />
                </template>
              </ProSpaceGeoLayout>
            </template>
            <template #tab-2 v-if="isFilial">
              <DistributorHierarchySelectForm
                ref="distributorStructures-DistributorHierarchySelectForm"
                v-model="model.distributorStructures"
                expandAll
                searchable
                readOnly
                :service="distributorStructureService"
                :titleTxt="$t('branches')"
                :descriptionTxt="$t('restrictingAccessBranchesNews')"
              />
            </template>
            <template #tab-2 v-else-if="model.isDistributorStructureRestricted">
              <DistributorHierarchySelectForm
                ref="distributorStructures-DistributorHierarchySelectForm"
                v-model="model.distributorStructures"
                viewMethod="getNewsViewTree"
                expandAll
                searchable
                :service="distributorStructureService"
                :afterEdit="afterDistributorStructureEdit"
                :titleTxt="$t('distributors')"
                :descriptionTxt="$t('restrictingAccessDistrsNews')"
                :hint="$t('restrictingAccessHintAll')"
                @apply="applyDistributorStructure"
              />
            </template>
            <template v-slot:tab-2 v-else>
              <ProSpaceGeoLayout class="geo-row">
                <template v-slot:left>
                  <ProSpaceTextBlock
                    :top="$t('distributors')"
                    :bottom="$t('newsItemAllHint')"
                    :gap="10"
                    type="third"
                    break-word
                  />
                </template>
                <template v-slot:center>
                  <ProSpaceGeoLayout>
                    <template v-slot:top>
                      <ProSpaceVLayout :gap="20">
                        <ProSpaceInputSwitch
                          ref="isAllDistributors-ProSpaceInputSwitch"
                          v-model="model.isAllDistributors"
                          :label="$t('allTxt')"
                          @update:modelValue="isAllDistributorsChanged"
                        />
                        <ProSpaceButton
                          ref="addRecipient-button"
                          type="secondary"
                          icon="edit"
                          class="margin-bottom-30"
                          :label="$t('editItemTxt')"
                          :disabled="model.isAllDistributors"
                          @click="showNewsDistributor"
                        />
                      </ProSpaceVLayout>
                    </template>
                    <template v-slot:center>
                      <ProSpaceSimpleGrid
                        v-if="
                          model.distributorIds &&
                          model.distributorIds.length > 0
                        "
                        ref="distributorGrid"
                        getMethod="grid"
                        :headers="distributorGridHeaders"
                        :service="distributorService"
                        :limit="50"
                        :btnText="$t('viewMore')"
                        :deletable="
                          checkAccess(
                            'action-delete-ProSpaceSimpleGrid',
                            model
                          ) &&
                          checkStatusAccess(
                            'action-delete-ProSpaceSimpleGrid',
                            model
                          )
                        "
                        :deleteMethod="null"
                        :defaultFilter="distributorFilter"
                        deletingFieldName="name"
                        :afterConfirmText="$t('questionDeletingList')"
                        @delete="deleteFromDistributors"
                        :defaultModal="false"
                        :confirmDelete="true"
                        confirmIcon="flash-success"
                        :confirmTitle="$t('deletingContactUser')"
                        :confirmText="$t('questionDeleting')"
                        @openModal="showEditDistributorsModalReadOnly"
                      />
                      <DistributorSelectModal
                        v-model="readDistributorModal"
                        :modalTitle="$t('listDistributors')"
                        :selectedIds="model.distributorIds"
                        :needAllQuestion="false"
                        :readOnly="true"
                        :selectionMode="'single'"
                        :isAll="model.isAllDistributors"
                        :defaultFilter="distributorFilter"
                      />
                    </template>
                  </ProSpaceGeoLayout>
                </template>
              </ProSpaceGeoLayout>
              <DistributorSelectModal
                v-model="showDistributorSelectModal"
                needAllQuestion
                :modalTitle="$t('selectDistributors')"
                :selectedIds="model.distributorIds"
                :allSelectionText="$t('isAllDistributorsSelectionNews')"
                @applyDistributors="applyDistributorSelectModal"
              />
              <ProSpaceFlashMessage
                v-if="isAllChangedMessage"
                v-model="isAllChangedMessage"
                type="default"
                icon="flash-success"
                :title="$t('isAllDistributorsTitle')"
                :applyTxt="$t('accept')"
                :cancelTxt="$t('cancelInf')"
                @apply="applyIsAllChangedMessage"
                @update:modelValue="cancelIsAllChangedMessage"
              >
                {{ $t("isAllDistributorsNewsText") }}
              </ProSpaceFlashMessage>
            </template>
            <template v-slot:tab-3>
              <ProSpaceGeoLayout class="geo-row">
                <template v-slot:left>
                  <ProSpaceTextBlock
                    :top="$t('positions')"
                    :bottom="$t('newsPositionsInfo')"
                    :gap="10"
                    type="third"
                    break-word
                  />
                </template>
                <template v-slot:center>
                  <ProSpaceGeoLayout>
                    <template v-slot:center>
                      <PositionSelectTree
                        ref="positions-PositionSelectTree"
                        expandAll
                        :positionService="positionOptions.positionService"
                        :positionGetMethod="positionOptions.positionGetMethod"
                        :positionSelectionMode="positionOptions.positionSelectionMode"
                        :positionFilter="positionOptions.positionFilter"
                        :propLabel="positionOptions.propLabel"
                        :checkedPositions="positions"
                        @changed="
                          (newPositions) => {
                            positions = [...newPositions];
                          }
                        "
                      />
                    </template>
                  </ProSpaceGeoLayout>
                </template>
              </ProSpaceGeoLayout>
            </template>
            <template v-slot:tab-4>
              <ProSpaceGeoLayout class="geo-row">
                <template v-slot:left>
                  <ProSpaceTextBlock
                    :top="$t('newsItemRecipientsMars')"
                    :bottom="$t('newsRecipientsInfo')"
                    :gap="10"
                    type="third"
                    break-word
                  />
                </template>
                <template v-slot:center>
                  <ProSpaceGeoLayout>
                    <template v-slot:top>
                      <ProSpaceButton
                        ref="addRecipient-button"
                        type="secondary"
                        icon="edit"
                        class="margin-bottom-30"
                        :label="$t('editItemTxt')"
                        @click="showOrgStructureSelect"
                      />
                    </template>
                    <template v-slot:center>
                      <ProSpaceSimpleGrid
                        ref="recipientGrid"
                        getMethod="simple"
                        :headers="recipientGridHeaders"
                        :service="recipientService"
                        :limit="50"
                        :btnText="$t('viewMore')"
                        :deletable="
                          checkAccess(
                            'action-delete-ProSpaceSimpleGrid',
                            model
                          ) &&
                          checkStatusAccess(
                            'action-delete-ProSpaceSimpleGrid',
                            model
                          )
                        "
                        deletingFieldName="email"
                        :afterConfirmText="$t('questionDeletingList')"
                        @delete="deleteFromFilter"
                      />
                    </template>
                  </ProSpaceGeoLayout>
                </template>
              </ProSpaceGeoLayout>
              <OrgStructureSelectModal
                ref="filterModal"
                v-model="showOrgStructureSelectModal"
                node-method="onlyMars"
                @close="closeOrgStructureSelectModal"
              />
            </template>
          </ProSpaceTabs>
          <ProSpaceFlashMessage
            v-if="isNonExistsUsers"
            v-model="isNonExistsUsers"
            type="error"
            icon="flash-error"
            :title="$t('nonExistsUsersTitle')"
            :applyTxt="null"
            :cancelTxt="null"
          >
            {{ $t('nonExistsUsersText') }}
          </ProSpaceFlashMessage>
          <ProSpaceFlashMessage
            v-if="isNonExistsAuthorizedPersons"
            v-model="isNonExistsAuthorizedPersons"
            type="error"
            icon="flash-error"
            :title="$t('nonExistsAuthorizedPersonsTitle')"
            :applyTxt="null"
            :cancelTxt="$t('closeTxt')"
          >
            {{ nonExistsAuthorizedPersonsTextP1 }}
              <ProSpaceAction
                :text="nonExistsAuthorizedPersonsTextAction"
                @click="() => showNonExistsAuthorizedPersonsDistributors = true"
              />
            {{ $t('nonExistsDistributorsAuthorizedPersonsTextP2') }}
          </ProSpaceFlashMessage>
          <DistributorSelectModal
            v-model="showNonExistsAuthorizedPersonsDistributors"
            selectionMode="single"
            readOnly
            :modalTitle="$t('listDistributors')"
            :defaultFilter="nonExistsAuthorizedPersonsDefaultFilter"
          />
        </template>
        <template #right v-if="showTimeLine()">
          <ProSpaceRightPanel no-border :header="statusHeader">
            <template #center>
              <ProSpaceTimeLineCondition
                :fileService="fileService"
                :elems="model.steps"
                :commentTile="$t('comment')"
                :title="$t('comments')"
                :titleAction="$t('addComment')"
                :dateTimeFormatter="formatTimelineDate"
                @add="showReasonModal(addComment, 'info', $t('addComment'))"
              >
              </ProSpaceTimeLineCondition>
            </template>
          </ProSpaceRightPanel>
        </template>
      </ProSpaceGeoLayout>
    </template>
  </ProSpaceGeoLayout>
  <ProSpaceModal
    v-model="reasonModalShow"
    icon="list-check"
    :title="reasonTitle"
    :width="530"
    :cancelTxt="$t('cancelInf')"
    :applyTxt="$t('send')"
    :validate-local="$t"
    :validate-model="modalModel"
    :validate-config="{
      comment: {
        required,
      },
    }"
    @apply="saveStep"
  >
    <template #default="{ v }">
      <ProSpaceVLayout :gap="20">
        <ProSpaceInputText
          :label="$t('comment')"
          v-model="modalModel.comment"
          :placeholder="$t('comment')"
          :invalid="v.comment.$error"
          :invalid-messages="v.comment.$errors"
          @blur="v.comment.$touch"
        />
        <ProSpaceFile
          v-if="modalModel.needStepFile"
          v-model="taskStepAttachments"
          accept=".doc, .docx, .docm, .rtf, .txt, .csv, .xml, .pdf, .png, .gif, .jpg, .jpeg, .ppt, .pptx, .potx, .potm, .pptm, .ppsx, .ppsm, .xls, .xlsx, .xlsm, .xlsb, .zip, .7z, .rar, .mp4, .avi, .mkv, .wmv"
          :service="fileService"
          :fileLimit="1000"
          :maxFileSize="209715200"
          :txtBtnUpload="$t('uploadFile')"
          :txtEmptyFiles="$t('uploadFileDragAndDropText')"
          :txtErrorStatus="$t('uploadStatusError')"
          :invalidFileLimitMessage="$t('invalidFileLimitMessage')"
          :invalidFileSizeMessage="$t('invalidFileSizeMessage')"
          :invalidFileTypeMessage="$t('invalidFileTypeMessage')"
        />
      </ProSpaceVLayout>
    </template>
  </ProSpaceModal>
  <ProSpaceFlashMessage
    v-if="showConfirmModel"
    v-model="showConfirmModel"
    :type="confirmType"
    :icon="confirmIcon"
    :title="confirmTitle"
    :applyTxt="confirmApplyTxt"
    :cancelTxt="confirmCancelTxt"
    @apply="applyConfirm"
  >
    <div v-html="confirmText"></div>
  </ProSpaceFlashMessage>
</template>
<script>
import {
  ProSpaceTopPanel,
  ProSpaceInputText,
  ProSpaceAction,
  ProSpaceGeoLayout,
  ProSpaceTabs,
  ProSpaceTextBlock,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceDropdown,
  ProSpaceFile,
  ProSpaceInputCalendar,
  ProSpaceIcon,
  ProSpaceInputChips,
  ProSpaceSimpleGrid,
  ProSpaceExtendedTextArea,
  ProSpaceWarning,
  ProSpaceInputSwitch,
  ProSpaceButton,
  ProSpaceInputNumber,
  ProSpaceFlashMessage,
  ProSpaceModal,
  ProSpaceRightPanel,
  ProSpaceTimeLineCondition,
  ProSpaceTag,
  ProSpaceLabel,
  Hint,
} from "prospace-components-library";
import moment from "moment";
import OrgStructureSelectModal from "@modals/OrgStructureSelectModal";
import DistributorSelectModal from "@modals/DistributorSelectModal";
import PositionSelectTree from "@trees/PositionSelectTree";
import { NewsItemService } from "../../services/NewsItemService.js";
import { TaskAssignmentService } from "../../../../TaskService/TaskService.Frontend/services/TaskAssignmentService.js";
import { NewsItemAttachmentService } from "../../services/NewsItemAttachmentService.js";
import { BaseTypeService } from "../../../../BaseType/BaseTypeService.Frontend/services/BaseTypeService";
import { NewsItemTemplateService } from "../../services/NewsItemTemplateService.js";
import { NewsItemRecipientService } from "../../services/NewsItemRecipientService";
import { NewsItemUserImportantService } from "../../services/NewsItemUserImportantService.js";
import { DistributorService } from "@masterdata/services/DistributorService.js";
import { DistributorOrgService } from "@masterdata/services/DistributorOrgService";
import { DistributorStructureService } from "@masterdata/services/DistributorStructureService.js";
import { FileService } from "../../../../../Frontends/ProSpace/services/FileService.js";
import { getAMorPM } from "prospace-components-library/src/lib-assets/mixins/date";
import editFieldMixin from "../../../../../Frontends/Common/utils/elementAccess/editFieldMixin";
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import statusAccessMixin from "../../../../../Frontends/Common/utils/elementAccess/statusAccessMixin";
import {
  setAlive,
  setDisable,
  setNone,
} from "../../../../../Frontends/Common/utils/elementAccess/elementAccessFunctions";
import {
  required,
  maxLength,
  helpers,
  minValue,
  maxValue,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { mapGetters } from "vuex";
const _ = require("lodash");
import DistributorHierarchySelectForm from '@forms/DistributorHierarchySelectForm.vue'
import {
  useStatistic
} from "@composes"
export default {
  mixins: [editFieldMixin, accessMixin, statusAccessMixin],
  components: {
    ProSpaceTopPanel,
    ProSpaceGeoLayout,
    ProSpaceInputText,
    ProSpaceAction,
    ProSpaceTabs,
    ProSpaceTextBlock,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceDropdown,
    ProSpaceFile,
    ProSpaceInputCalendar,
    ProSpaceInputSwitch,
    ProSpaceIcon,
    ProSpaceInputChips,
    ProSpaceExtendedTextArea,
    ProSpaceSimpleGrid,
    ProSpaceWarning,
    ProSpaceButton,
    ProSpaceInputNumber,
    DistributorSelectModal,
    OrgStructureSelectModal,
    ProSpaceFlashMessage,
    ProSpaceModal,
    ProSpaceRightPanel,
    ProSpaceTimeLineCondition,
    ProSpaceTag,
    ProSpaceLabel,
    PositionSelectTree,
    DistributorHierarchySelectForm
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    return { v$: useVuelidate(), setStatistic, distActions };
  },
  directives: {
    hint: Hint,
  },
  data() {
    let statusAccessConfig = {
      "Distributor user": "READONLY",
      "Distributor manager": "READONLY",
      "Mars user": "READONLY",
      "Mars manager": "READONLY",
      "Content manager": {
        none: "ALIVE",
        newsDraft: "CREATOR_OR_READONLY",
        other: "READONLY",
      },
      "Support administrator": {
        other: "ALIVE",
      },
      "Technical Support": {
        other: "ALIVE",
      },
      "Content manager plus": {
        none: "ALIVE",
        newsDraft: "ALIVE",
        newsModer: "ALIVE",
        other: "READONLY",
      },
    };

    let recipientGridStatusConfig = {
      "Distributor user": "NONE",
      "Distributor manager": "NONE",
      "Mars user": "NONE",
      "Mars manager": "NONE",
      "Content manager": {
        newsDraft: "CREATOR_OR_NONE",
        other: "NONE",
      },
      "Support administrator": {
        other: "ALIVE",
      },
      "Technical Support": {
        other: "ALIVE",
      },
      "Content manager plus": {
        newsDraft: "ALIVE",
        newsModer: "ALIVE",
        other: "NONE",
      },
    };

    let approveTaskStatusConfig = {
      "Distributor user": {
        newsPublished: "ALIVE",
        other: "NONE",
      },
      "Distributor manager": {
        newsPublished: "ALIVE",
        other: "NONE",
      },
      "Mars user": "NONE",
      "Mars manager": "NONE",
      "Content manager": "NONE",
      "Support administrator": "NONE",
      "Technical Support": "NONE",
      "Content manager plus": "NONE",
    };

    return {
      // Tabs
      modelTab: {
        name: this.$t("parameters"),
        isActive: true,
        systemName: "parameters",
      },

      // Common
      model: null,
      startModel: null,
      loading: false,
      importantLoading: false,
      isCreate: false,
      taskStepAttachments: [],
      attachments: [],
      attachmentsToCreate: [],
      tags: [],
      positions: [],
      readDistributorModal: false,

      // Service
      newsItemService: new NewsItemService(),
      taskAssignmentService: new TaskAssignmentService(),
      newsItemAttachmentService: new NewsItemAttachmentService(),
      baseTypeService: new BaseTypeService(),
      newsItemTemplateService: new NewsItemTemplateService(),
      recipientService: new NewsItemRecipientService(),
      distributorService: new DistributorService(),
      distributorStructureService: new DistributorStructureService(),
      fileService: new FileService(),
      newsItemUserImportantService: new NewsItemUserImportantService(),

      getMethod: "get",
      masterId: null,

      editFieldModel: {},

      //Recipients
      showOrgStructureSelectModal: false,
      showDistributorSelectModal: false,
      showNonExistsAuthorizedPersonsDistributors: false,
      isAllChangedMessage: false,
      isNonExistsUsers: false,
      isNonExistsAuthorizedPersons: false,
      needCancelIsAll: true,
      afterDistributorStructureEdit: false,
      nodeFilter: {
        add: {},
        remove: {},
      },
      gridFilter: {
        add: {},
        remove: {},
      },
      oDataStringFilter: "",
      selectedTreeNodes: {},
      recipientGridHeaders: [
        { name: this.$t("type"), value: "recordTypeLocale" },
        {
          name: this.$t("name"),
          value: "name",
          f: (item) => item,
        },
        {
          name: this.$t("email"),
          value: "email",
          f: (item) => item,
        },
      ],
      distributorGridHeaders: [
        {
          name: this.$t("code"),
          value: "code",
          f: (item) => item,
        },
        {
          name: this.$t("name"),
          value: "name",
          f: (item) => item,
        },
      ],

      // to switch between view data and persist
      persistRecipients: true,

      showConfirmModel: false,
      confirmIcon: "",
      confirmType: "",
      confirmTitle: "",
      confirmText: "",
      confirmApplyTxt: "",
      confirmCancelTxt: "",

      nonExistsAuthorizedPersonsTextP1: "",
      nonExistsAuthorizedPersonsTextAction: "",
      nonExistsAuthorizedPersonsDefaultFilter: {},

      reasonTitle: "",
      reasonCallback: null,
      reasonModalShow: false,
      modalModel: {
        comment: "",
        stepType: "",
        attachments: [],
        needStepFile: false,
      },

      statusAccessConfig: {
        "newsItemTemplateId-ProSpaceDropdown": statusAccessConfig,
        "type-ProSpaceDropdown": statusAccessConfig,
        "name-ProSpaceInputText": statusAccessConfig,
        "message-ProSpaceInputText": statusAccessConfig,
        "publishDate-ProSpaceInputText": statusAccessConfig,
        "highImportant-ProSpaceInputSwitch": statusAccessConfig,
        "isPersonal-ProSpaceInputSwitch": statusAccessConfig,
        "isDistributorStructureRestricted-ProSpaceInputSwitch": statusAccessConfig,
        "readConfirmationRequired-ProSpaceInputSwitch": statusAccessConfig,
        "tags-ProSpaceInputChips": statusAccessConfig,
        "attachments-ProSpaceFile": statusAccessConfig,
        "dueDate-ProSpaceInputCalendar": statusAccessConfig,
        "daysToDueDate-ProSpaceInputNumber": statusAccessConfig,
        "action-delete-ProSpaceSimpleGrid": recipientGridStatusConfig,
        "addRecipient-button": recipientGridStatusConfig,
        "isAllDistributors-ProSpaceInputSwitch": statusAccessConfig,
        "distributorStructures-DistributorHierarchySelectForm": statusAccessConfig,
        "position-PositionSelectTree": statusAccessConfig,
        "approveTask-ProSpaceVLayout": approveTaskStatusConfig,
        "approveTask-ProSpaceButton": approveTaskStatusConfig,
      },
      showHint: {},
    };
  },
  validations() {
    return {
      model: {
        templateId: {},
        type: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        name: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '255'}),
            maxLength(255)
          ),
        },
        message: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        publishDate: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        tags: {},
        highImportant: {},
        readConfirmationRequired: {},
        dueDate: {},
        attachments: {},
      },
    };
  },
  mounted() {
    this.loadRoleAccess();
    this.masterId = this.$route.params.id;
    this.getData().then(() => {
      this.setAllAccess();
    });
    this.recipientService.filter = this.recipientGridFilter;
    if (this.masterId)
      this.$store.dispatch("notifications/updateReadedRecord", {
        title: "News",
        id: Number(this.masterId),
      });
  },
  watch: {
    "model.readConfirmationRequired"(value) {
      this.updateDueDateFieldsState(value);
    },
    refLoading() {
      if (this.model)
        this.updateDueDateFieldsState(this.model.readConfirmationRequired);
    },
  },
  methods: {
    formatTimelineDate(date) {
      return this.getFormatDateTime(date);
    },
    handlerSelectTab({ selectedTab }) {
      this.setAllAccess()
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: selectedTab.systemName
      })
    },
    showEditDistributorsModalReadOnly() {
      this.readDistributorModal = true;
    },
    showReasonModal(callback, stepType, title, needAttachments = false) {
      this.modalModel.needStepFile = needAttachments;
      this.modalModel.stepType = stepType;
      this.reasonCallback = async () => {
        await callback();
        this.reasonCallback = null;
        this.afterSave();
      };
      this.reasonTitle = title;
      this.reasonModalShow = true;
    },
    moderationConfirm() {
      this.confirmType = "default";
      this.confirmIcon = "flash-success";
      this.confirmTitle = this.$t("publishNews");
      this.confirmText = this.$t("publishNewsTxt");
      this.confirmApplyTxt = this.$t("publish");
      this.confirmCancelTxt = this.$t("reject");
      this.applyConfirm = () => {
        this.setModelAndSave(true, false).then((res) => {
          if (res && res.success) {
            this.modalModel.stepType = "accept";
            this.nextStep().then(() => {
              this.afterSave();
              this.confirmType = "default";
              this.confirmIcon = "flash-success";
              this.confirmTitle = this.$t("publishedNews");
              this.confirmText = this.$t("publishedNewsTxt");
              this.confirmApplyTxt = "";
              this.confirmCancelTxt = "";
              this.showConfirmModel = true;
            });
          } else this.loading = false;
        });
      };
      this.showConfirmModel = true;
    },
    confirmPublish() {
      let needModeration = !this.model.templateId;
      this.confirmType = "default";
      this.confirmIcon = needModeration ? "flash-warning" : "flash-success";
      this.confirmTitle = needModeration
        ? this.$t("needModeration")
        : this.$t("publishNews");
      this.confirmText = needModeration
        ? this.$t("needModerationNewsTxt")
        : this.$t("publishNewsTxt");
      this.confirmApplyTxt = needModeration
        ? this.$t("send")
        : this.$t("publish");
      this.confirmCancelTxt = this.$t("reject");
      this.applyConfirm = () => {
        this.setModelAndSave(true, false).then((res) => {
          if (res && res.success) {
            let next = () => {
              this.nextStep().then(() => {
                this.afterSave();
              });
            };
            if (needModeration) {
              this.modalModel.stepType = "reject";
              this.rejectStep().then((rjres) => {
                if (rjres && rjres.success) {
                  if (rjres.data.length > 0)
                    this.model.currentStepNo = rjres.data[0].stepNo;
                  this.modalModel.stepType = "accept-info";
                  next();
                } else this.loading = false;
              });
            } else {
              this.modalModel.stepType = "accept";
              next();
            }
          } else this.loading = false;
        });
      };
      this.showConfirmModel = true;
    },
    saveStep() {
      this.mixinLoadingAccess = true
      let fileInfos = this.taskStepAttachments
        .filter((f) => !f.id)
        .map((f) => {
          return {
            fileName: f.name,
            fileVisibleFlag: 0,
          };
        });
      if (fileInfos.length > 0) {
        this.fileService.createFilesRoot(fileInfos).then((res) => {
          this.modalModel.attachments = res.data.data.map((f, i) => {
            this.taskStepAttachments[i].gfid = f.gfid;
            return {
              fileName: f.fileName,
              gfid: f.gfid,
              fileLength: this.taskStepAttachments[i].size,
            };
          });
          this.upload(res.data.data, this.taskStepAttachments, null, this.uploadStepError).then(() => {
              if(!this.isUploadError) {
                this.modalModel.attachments = this.modalModel.attachments.map(x => Object.assign(x, { statusFlag: 0 }))
                this.reasonCallback();
                this.taskStepAttachments = [];
              } else {
                this.mixinLoadingAccess = false
                this.reasonModalShow = true;
              }

              this.isUploadError = false;
            })
            .catch(() => {
              this.mixinLoadingAccess = false
              this.isUploadError = false;
              this.reasonModalShow = true;
            })
          }
        );
      } else {
        this.reasonCallback()
      }
    },
    async uploadStepError(gfids) {
      this.isUploadError = true;
      this.stepAttachments = this.stepAttachments
        .filter(f => gfids.includes(f.gfid))
        .map(x => Object.assign(x, { statusFlag: 255 }))
    },
    nextStep() {
      return this.newsItemService.nextStep(this.stepItem).then((result) => {
        this.modalModel = {
          comment: "",
          stepType: "",
          attachments: [],
          needStepFile: false,
        };
        return result;
      });
    },
    taskNextStep() {
      let taskItem = {
        workflowEntityId: this.model.approvingTaskWorkflowEntityId,
        stepNo: this.model.approvingTaskStepNo,
        status: this.model.approvingTaskStatus,
        comment: this.modalModel.comment || "",
        stepType: "done",
        stepTypeMessage: this.model.approvingTaskStatus ? this.model.approvingTaskStatus + "Step" : "",
        attachments: this.modalModel.attachments,
        taskAssignmentId: this.model.approvingTaskId,
        securityUserId: this.userinfo.id,
        securityUserFirstName: this.userinfo.firstName,
        securityUserLastName: this.userinfo.lastName,
        securityUserType: this.userinfo.orgLinkType,
        confirmDate: new Date(),
      };
      return this.taskAssignmentService.nextStep(taskItem)
        .then(() => {
          this.modalModel = {
            comment: "",
            stepType: "",
            attachments: [],
            needStepFile: false,
          };
          this.confirmType = "default";
          this.confirmIcon = "flash-success";
          this.confirmTitle = this.$t("newsTaskConfirmTitle");
          this.confirmText = this.$t("newsTaskConfirmText");
          this.confirmApplyTxt = "";
          this.confirmCancelTxt = this.$t("closeTxt");
          this.showConfirmModel = true;
        });
    },
    rejectStep() {
      return this.newsItemService.rejectStep(this.stepItem).then((result) => {
        this.modalModel = {
          comment: "",
          stepType: "",
          attachments: [],
          needStepFile: false,
        };
        return result;
      });
    },
    addComment() {
      let stepItem = this.stepItem;
      stepItem.stepTypeMessage = "";
      stepItem.afterPublish = ![
        "newsModer",
        "newsDraft",
        "newsWaiting",
      ].includes(this.model.status);
      return this.newsItemService.addComment(stepItem).then((result) => {
        this.modalModel = {
          comment: "",
          stepType: "",
          attachments: [],
          needStepFile: false,
        };
        return result;
      });
    },
    setAllAccess(clear = false) {
      this.$nextTick(() => {
        if (clear) this.clearAllCondition();
        this.mountAccess();
        this.mountEditFieldAccess();
        this.mountStatusAccess();
      });
    },
    gridLoaded() {},
    async getData() {
      if (this.masterId === undefined) {
        let daysToDueDate = 5;
        this.model = {
          type:
            this.$route.query &&
            this.$route.query.code &&
            this.$route.query.code != "all"
              ? this.$route.query.code
              : null,
          name: "",
          message: "",
          publishDate: new Date(),
          tags: [],
          highImportant: false,
          isPersonal: false,
          isDistributorStructureRestricted: false,
          readConfirmationRequired: false,
          daysToDueDate: daysToDueDate,
          dueDate: moment(new Date()).add(daysToDueDate, "days"),
          attachments: [],
          distributorIds: [],
          distributorStructures: [],
          distributorRequestModel: {
            filter: "",
            records: []
          },
          status: "none",
          createdBy: this.userinfo.code
        };
        this.isCreate = true;
        return;
      }
      this.isCreate = false;
      const result = await this.newsItemService[this.getMethod](this.masterId);
      this.model = result.data;
      this.positions = [...this.model.positions];
      this.distributorStructureService.setNewsId(this.model.id);
      this.distributorStructureService.setNodeViewIds(this.model.distributorStructures.map(x => x.distributorStructureViewId));
      // this.startModel = _.cloneDeep(this.model);
      if (this.model) {
        this.$store.dispatch("notifications/updateReadedRecord", {
          title: "News",
          id: "newsPublishStaticId",
          realId: Number(this.model.id),
          type: this.model.type,
          groupUpdate: true,
        });
        this.$store.dispatch("notifications/updateReadedRecord", {
          title: "News",
          id: "newsNewStaticId",
          realId: Number(this.model.id),
          type: this.model.type,
          groupUpdate: true,
        });
        this.model.steps = this.model.steps.map((s) => {
          s.stepTypeMessage = this.$t(s.stepTypeMessage);
          return s;
        });
        this.tags = this.model.tags
          ? this.model.tags.split("#").filter((t) => !!t)
          : [];
        try {
          if (this.model.editFieldsSetting) {
            this.editFieldModel = JSON.parse(this.model.editFieldsSetting);
          }
        } catch {}

        this.model.needFilter = false;
        try {
          let filter = JSON.parse(this.model.filter);
          this.nodeFilter = filter.nodeFilter;
          this.gridFilter = filter.gridFilter;
          this.feelNeedFilter();
        } catch {}
        try {
          this.selectedTreeNodes = JSON.parse(this.model.nodeSelection);
        } catch {}

        if (this.model.attachments)
          this.attachments = this.model.attachments.map((f) => {
            let r = new File([], f.fileName);
            r.gfid = f.gfid;
            r.fileLength = f.fileLength;
            r.statusFlag = f.statusFlag;
            return r;
          });
      }
    },
    async setModelAndSave(needCheckUsers, loadModel = true) {
      this.loading = true;

      if (needCheckUsers) {
        if(this.model.readConfirmationRequired) {
          this.positions.forEach(x => {
            x["documentId"] = this.model.id;
            x["leaf"] = !this.positions.some(p => p.parentId === x.distributorOrgId);
          });
          this.model.positions = this.positions;

          let authorizedPersonsValidationRes = await this.newsItemService.validateAuthorizedPersonsExists(this.model)
            .catch(() => {
              this.loading = false;
              return;
            });

          if(!authorizedPersonsValidationRes) {
            this.loading = false;
            return Promise.resolve(false);
          }

          let nonExistsAPDistributors = authorizedPersonsValidationRes.data;

          if (nonExistsAPDistributors && nonExistsAPDistributors.length > 0) {
            if(nonExistsAPDistributors.length > 1) {
              this.nonExistsAuthorizedPersonsTextP1 = this.$t('nonExistsDistributorsAuthorizedPersonsTextP1');
              this.nonExistsAuthorizedPersonsTextAction = this.$t('nonExistsDistributorsAuthorizedPersonsTextAction');
            } else {
              this.nonExistsAuthorizedPersonsTextP1 = this.$t('nonExistsDistributorAuthorizedPersonsTextP1');
              this.nonExistsAuthorizedPersonsTextAction = nonExistsAPDistributors[0].name;
            }

            this.nonExistsAuthorizedPersonsDefaultFilter = { id: { or: nonExistsAPDistributors.map(x => x.id) } }

            this.$nextTick(() => {
              this.isNonExistsAuthorizedPersons = true;
            })

            this.loading = false;
            return Promise.resolve(false);
          } else if (authorizedPersonsValidationRes.errors) {
            this.loading = false;
            return Promise.resolve(false);
          }
        } else if (this.model.isPersonal && this.model.isDistributorStructureRestricted) {
          this.positions.forEach(x => {
            x["documentId"] = this.model.id;
            x["leaf"] = !this.positions.some(p => p.parentId === x.distributorOrgId);
          });
          this.model.positions = this.positions;

          var usersValidationRes = await this.newsItemService.validateUsersExists(this.model);

          if (!usersValidationRes || usersValidationRes.data === false) {
            this.isNonExistsUsers = true;
            this.loading = false;
            return Promise.resolve(false);
          } else if (!usersValidationRes || usersValidationRes.errors) {
            this.loading = false;
            return Promise.resolve(false);
          }
        }
      }

      return this.setModel().then((response) => {
        let newFiles = this.setNewAttachments(response.data.data);
        return this.save(newFiles, loadModel);
      });
    },
    setModel() {
      this.model.tags = this.tags.join("#");
      this.model.editFieldsSetting = JSON.stringify(this.editFieldModel);
      this.model.filter = JSON.stringify({
        nodeFilter: this.nodeFilter,
        gridFilter: this.gridFilter,
      });
      this.model.nodeSelection = JSON.stringify(this.selectedTreeNodes);
      this.model.distributorIds = !this.model.isAllDistributors
        ? this.model.distributorIds
        : [];

      if (this.model.isPersonal) {
        this.positions.forEach((x) => {
          x["newsItemId"] = this.model.id;
          x["leaf"] = !this.positions.some(
            (p) => p.parentId === x.distributorOrgId
          );
        });
        this.model.positions = this.positions;
      } else {
        this.model.positions = [];
      }

      this.attachmentsToCreate = this.attachments.filter((f) => !f.gfid);
      const actualFiles = this.attachments
        .filter((f) => f.gfid)
        .map((f) => f.gfid);
      if (this.model.attachments)
        this.model.attachments = this.model.attachments.filter((f) =>
          actualFiles.includes(f.gfid)
        );
      if (this.attachmentsToCreate.length > 0) {
        let fileInfos = this.attachmentsToCreate
          .filter((f) => !f.id)
          .map((f) => {
            return {
              fileName: f.name,
              fileVisibleFlag: 0,
            };
          });
        return this.fileService.createFilesRoot(fileInfos);
      }
      return new Promise((resolve, reject) => {
        resolve({ data: { data: [] } });
      });
    },
    saveIsImportant() {
      this.importantLoading = true;
      let isSet = !this.model.isImportant;
      let method = isSet ? "create" : "delete";

      let importantModel = {
        newsItemId: this.model.id,
        securityUserCode: this.userinfo.code,
      };

      this.newsItemUserImportantService[method](importantModel)
        .then((response) => {
          this.model.isImportant = isSet;
        })
        .finally(() => {
          this.importantLoading = false;
        });
    },
    setNewAttachments(attachments) {
      let newFiles = attachments.map((f, i) => {
        return {
          fileName: f.fileName,
          gfid: f.gfid,
          fileLength: this.attachmentsToCreate[i].size,
        };
      });
      if (!this.model.attachments) {
        this.model.attachments = [];
      }
      this.model.attachments = this.model.attachments.concat(newFiles);
      return newFiles;
    },
    save(newFiles, loadModel) {
      const isCreate = !this.masterId;
      let saveMethod = isCreate ? "create" : "update";
      return this.newsItemService[saveMethod](this.model)
        .then((result) => {
          this.model.id = result.data[0].id;
          if (newFiles.length > 0) {
            this.upload(newFiles, this.attachmentsToCreate, this.uploadSuccess, this.uploadError).then(() => {
                if (loadModel) this.afterSave(result, isCreate);
              })
              .catch(() => {
                this.loading = false;
              });
          } else if (loadModel) {
            this.afterSave(result, isCreate);
          }
          return result;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async upload(newFiles, toCreate, successCallback, errorCallback) {
      await this.fileService.uploadChunks(newFiles, toCreate, successCallback, errorCallback);
      this.attachmentsToCreate = [];
    },
    async uploadSuccess(gfids) {
      await this.newsItemAttachmentService.acceptFiles(gfids, this.model.id);
    },
    async uploadError(gfids) {
      await this.newsItemAttachmentService.errorFiles(gfids, this.model.id);
    },
    afterSave(result, isCreate) {
      if (isCreate) {
        this.masterId = result.data[0].id;
      }
      this.getData().then(() => {
        this.setAllAccess();
      });
      this.persistRecipients = true;
      this.afterDistributorStructureEdit = false;
      this.recipientService.fromView = false;
      this.recipientService.filter = this.recipientGridFilter;
      this.loading = false;
    },
    backHandler() {
      this.$router.go(-1);
    },
    isDistributorStructureRestrictedChange(val) {
      if(val) {
        this.model.isAllDistributors = false;
        this.model.distributorIds = [];
      } else {
        this.model.distributorStructures = [];
      }
    },
    onTemplateChange(item) {
      if (item !== undefined) {
        try {
          if (item.editFieldsSetting) {
            this.editFieldModel = JSON.parse(item.editFieldsSetting);
            this.setAllAccess(true);
          }

          this.model.templateId = item.id;
          this.model.type = item.type;
          this.model.name = item.name;
          this.model.message = item.message;
          this.model.publishDate = this.model.publishDate;
          this.model.highImportant = item.highImportant;
          this.model.isPersonal = item.isPersonal;
          this.model.isDistributorStructureRestricted = item.isDistributorStructureRestricted;
          this.model.readConfirmationRequired = item.readConfirmationRequired;
          this.model.dueDate = moment(this.model.publishDate).add(item.daysToDueDate, "days");
          this.model.daysToDueDate = item.daysToDueDate;
          this.model.editFieldsSetting = item.editFieldsSetting;
          this.positions = [...item.positions];
          this.tags = item.tags ? item.tags.split("#").filter((t) => !!t) : [];
          this.attachments = item.attachments.map((f) => {
            let r = new File([], f.fileName);
            r.gfid = f.gfid;
            r.fileLength = f.fileLength;
            return r;
          });
          this.model.attachments = item.attachments.map((f) => {
            let r = {};
            r.gfid = f.gfid;
            r.fileName = f.fileName;
            r.fileLength = f.fileLength;
            r.statusFlag = f.statusFlag;
            return r;
          });
        } catch {}
      }
    },
    createTemplate() {
      this.$router.push({
        path: `/newsservice/newsitemtemplate`,
        query: {
          newsItemId: this.masterId,
        },
      });
    },
    getDate(val) {
      return moment(val).format(
        `${this.userinfo.dateFormat} ${this.userinfo.timeFormat}`
      );
    },
    getRecipientName(record) {
      return (
        record.mailingGroupName ||
        "" +
          `${record.securityUserFirstName || ""} ${
            record.securityUserLastName || ""
          }`
      );
    },
    getRecipientEmail(record) {
      return record.mailingGroupEmail || "" + record.securityUserEmail || "";
    },
    showOrgStructureSelect() {
      this.updateSelectedTreeNodes();
      this.$refs.filterModal.nodeFilter = { ...this.nodeFilter };
      this.$refs.filterModal.gridFilter = { ...this.gridFilter };
      this.$refs.filterModal.selectedTreeNodes = { ...this.selectedTreeNodes };
      this.$nextTick(() => {
        this.showOrgStructureSelectModal = true;
      });
    },
    showNewsDistributor() {
      this.showDistributorSelectModal = true;
    },
    showIsAllChangedMessage() {
      this.isAllChangedMessage = true;
    },
    applyIsAllChangedMessage() {
      this.needCancelIsAll = false;
      this.model.distributorIds = [];
      this.updateDistributorGrid(true);
    },
    cancelIsAllChangedMessage(val) {
      if (!(val || this.needCancelIsAll)) {
        this.needCancelIsAll = true;
        return;
      }

      this.model.isAllDistributors = false;
    },
    closeOrgStructureSelectModal() {
      // баг с применением
      this.nodeFilter = { ...this.$refs.filterModal.nodeFilter };
      this.gridFilter = { ...this.$refs.filterModal.gridFilter };
      this.selectedTreeNodes = {
        ...this.$refs.filterModal.$refs.tree.checkedNodes,
      };
      this.oDataStringFilter = this.convertFilterToOdata();
      Object.values(this.nodeFilter).forEach((o) => delete o.children);
      this.model.filter = JSON.stringify({
        nodeFilter: this.nodeFilter,
        gridFilter: this.gridFilter,
      });
      this.model.nodeSelection = JSON.stringify(this.selectedTreeNodes);
      this.model.oDataFilter = this.oDataStringFilter;
      this.persistRecipients = false;
      this.recipientService.fromView = true;
      this.recipientService.filter = this.recipientGridFilter;
      this.$refs.recipientGrid.records = [];
      this.feelNeedFilter();
      if (this.model.needFilter) this.$refs.recipientGrid.getData();
      this.showOrgStructureSelectModal = false;
    }, 
    async applyDistributorSelectModal({items, requestModel}, isAll) {
      this.model.isAllDistributors = isAll;
      if (!isAll) {
        this.model.distributorRequestModel = requestModel;
        if (requestModel && requestModel.filter) {
          let distributors = await this.distributorService.getByMultiple(requestModel);
          this.model.distributorIds = distributors.data.records.map(x => x.id);
        } else {
          this.model.distributorIds = items;
        }
      } else {
        this.model.distributorIds = [];
      }
      this.$nextTick(() => {
        this.updateDistributorGrid(isAll);
      });
    },
    applyDistributorStructure() {
      this.afterDistributorStructureEdit = true;
    },
    isAllDistributorsChanged(val) {
      if (!val) this.updateDistributorGrid(val);
      else this.showIsAllChangedMessage();
    },
    updateDistributorGrid(isAll) {
      if (!this.$refs.distributorGrid) return;

      this.$refs.distributorGrid.records = [];
      if (!isAll) this.$refs.distributorGrid.getData();
    },
    convertFilterToOdata() {
      // ( ((orgId in included nodes) && (orgId not in excluded nodes)) || (userid in included users) ) && (userId not in excluded users)
      const nodeAdd = Object.values(this.nodeFilter.add);
      const usersAdd = Object.values(this.gridFilter.add);
      const nodeRemove = Object.values(this.nodeFilter.remove);
      const usersRemove = Object.values(this.gridFilter.remove);
      let nodeAddStrings = "";
      let usersAddStrings = usersAdd
        .map((e) => `(recordTypeId eq '${e.recordType + "-" + e.id}')`)
        .join(" or ");
      let usersRemoveStrings = usersRemove
        .map((e) => `(recordTypeId ne '${e.recordType + "-" + e.id}')`)
        .join(" and ");
      let getIdFilter = (n, o) => {
        if (n.type === "RealDistributor")
          return `and distributorId ${o} ${n.id}`;
        if ([9223372036854775000, -9223372036854775000].includes(n.id))
          return `and orgId ${o} null`;
        return `and orgId ${o} ${n.id}`;
      };
      let nodeRemoveStrings = nodeRemove
        .map((e) => `(type eq '${e.type}' ${getIdFilter(e, "ne")})`)
        .join(" and ");
      if (!nodeAdd.find((e) => e.key === "all"))
        nodeAddStrings = nodeAdd
          .map((e) => `(type eq '${e.realType}' ${getIdFilter(e, "eq")})`)
          .join(" or ");

      let filter = "";
      if (nodeAddStrings || nodeRemoveStrings)
        filter =
          "(" +
          [nodeAddStrings, nodeRemoveStrings]
            .filter((e) => e)
            .map((e) => `(${e})`)
            .join(" and ") +
          ")";

      if (usersAddStrings) {
        if (filter) filter = `${filter} or `;
        filter = `${filter} (${usersAddStrings})`;
      }

      if (usersRemoveStrings) {
        if (filter) filter = `(${filter}) and `;
        filter = `${filter} (${usersRemoveStrings})`;
      }
      if (filter) filter = "$filter=" + filter;
      return filter;
    },
    deleteFromFilter(item) {
      this.$refs.recipientGrid.records =
        this.$refs.recipientGrid.records.filter(
          (r) => !(r.id === item.id && r.recordType === item.recordType)
        );
      if (this.$refs.recipientGrid.records.length === 0) {
        this.nodeFilter = {
          add: {},
          remove: {},
        };
        this.gridFilter = {
          add: {},
          remove: {},
        };
      } else {
        let key = `${item.id}|${item.recordType}`;
        if (Object.keys(this.gridFilter.add).includes(key)) {
          delete this.gridFilter.add[key];
        } else {
          this.gridFilter.remove[key] = item;
        }
      }
      this.oDataStringFilter = this.convertFilterToOdata();
      this.model.oDataFilter = this.oDataStringFilter;
      this.persistRecipients = false;
      this.recipientService.fromView = true;
      this.recipientService.filter = this.recipientGridFilter;
      this.feelNeedFilter();
    },
    deleteFromDistributors(item) {
      this.$refs.distributorGrid.records =
        this.$refs.distributorGrid.records.filter((r) => r.id !== item.id);

      this.model.distributorIds = this.model.distributorIds.filter(
        (r) => r !== item.id
      );
    },
    updateSelectedTreeNodes() {
      if (
        Object.keys(this.nodeFilter.add).length === 0 &&
        Object.keys(this.gridFilter.add).length === 0
      ) {
        if (this.selectedTreeNodes) {
          delete this.selectedTreeNodes["all"];
        }
        return;
      }
      const fullRecords = this.$refs.recipientGrid.records;
      const listTreeNodes = {
        all: {
          checked: false,
          partialChecked: true,
        },
      };

      fullRecords.forEach((r) => {
        const keys = r.fullPath.split("|");
        keys.forEach((key, index, arr) => {
          if (!listTreeNodes[key]) {
            listTreeNodes[key] = {
              checked: false,
              partialChecked: true,
              parentKey: index > 0 ? arr[--index] : "all",
            };
          }
        });
      });
      Object.values(this.nodeFilter.add).forEach((n) => {
        const keys = n.path.split("|");
        keys.forEach((key, index, arr) => {
          if (!listTreeNodes[key]) {
            listTreeNodes[key] = {
              checked: false,
              partialChecked: true,
              parentKey: index > 0 ? arr[--index] : "all",
            };
          }
        });
      });
      // check=true all nodeFilter.add and children
      // баг если дочерние check, parent uncheck, см свой чат
      // listTreeNodes добавлять из через this.nodeFilter.add (нет некоторых узлов)
      const checkChildren = (parentKey) => {
        const children = Object.entries(listTreeNodes)
          .filter(([k, v]) => v.parentKey === parentKey)
          .map(([k]) => k);
        if (children.length === 0) {
          return;
        }
        children.forEach((k) => {
          checkChildren(k);
          listTreeNodes[k] = {
            checked: true,
            partialChecked: false,
            parentKey: parentKey,
          };
        });
        return;
      };
      Object.entries(this.nodeFilter.add).forEach(([key, value]) => {
        listTreeNodes[key] = {
          checked: true,
          partialChecked: false,
          parentKey: value.parentId,
        };
        checkChildren(key);
      });

      // partialChecked=true if parent has removed child
      const removesParentKeys = [];
      Object.values(this.gridFilter.remove).forEach((val) => {
        const arr = val.fullPath.split("|");
        const parentKey = arr[arr.length - 2];
        if (!removesParentKeys.includes(parentKey)) {
          removesParentKeys.push(parentKey);
        }
      });
      const uncheckParent = (childKey) => {
        const child = listTreeNodes[childKey];
        if (!child) {
          return;
        }
        if (child.checked) {
          child.checked = false;
          child.partialChecked = true;
        }
        const parent = listTreeNodes[child.parentKey];
        if (!parent) {
          return;
        }
        if (parent.checked) {
          parent.checked = false;
          parent.partialChecked = true;
          uncheckParent(parent.parentKey);
          return;
        }
        return;
      };
      removesParentKeys.forEach(uncheckParent);

      this.selectedTreeNodes = listTreeNodes;
    },
    feelNeedFilter() {
      this.model.needFilter =
        Object.keys(this.nodeFilter.add).length > 0 ||
        Object.keys(this.nodeFilter.remove).length > 0 ||
        Object.keys(this.gridFilter.add).length > 0 ||
        Object.keys(this.gridFilter.remove).length > 0;
      this.recipientService.needFilter = this.model.needFilter;
    },
    setDueDate(value) {
      this.model.dueDate = moment(this.model.publishDate).add(value, "days");
      this.$refs["dueDate-ProSpaceInputCalendar"].value = this.model.dueDate;
    },
    publishDateUpdate() {
      if (this.model.publishDate !== null) {
        this.model.dueDate = moment(this.model.publishDate).add(
          this.model.daysToDueDate,
          "days"
        );
        this.$refs["dueDate-ProSpaceInputCalendar"].value = this.model.dueDate;
      } else {
        this.model.dueDate = null;
      }
    },
    updateDueDateFieldsState(readConfirmationRequiredValue) {
      if (this.$refs["daysToDueDate-ProSpaceInputNumber"]) {
        if (!readConfirmationRequiredValue) {
          setDisable(this.$refs["daysToDueDate-ProSpaceInputNumber"]);
          setNone(this.$refs["dueDate-ProSpaceInputCalendar"]);
        } else {
          if (
            this.checkAccess("daysToDueDate-ProSpaceInputNumber", this.model) &&
            this.checkStatusAccess(
              "daysToDueDate-ProSpaceInputNumber",
              this.model
            )
          )
            setAlive(this.$refs["daysToDueDate-ProSpaceInputNumber"]);
          var el = this.$refs["dueDate-ProSpaceInputCalendar"].$el;
          if (el.style["display"] == "none") el.style["display"] = "flex";
          setDisable(this.$refs["dueDate-ProSpaceInputCalendar"]);
        }
      }
    },
    showTimeLine() {
      let roleAllowed = false;
      let item = JSON.parse(localStorage.getItem("userRole"));
      let role = item && item.systemName ? item.systemName : null;
      let allowedRoles = [
        "Content manager",
        "Support administrator",
        "Technical Support",
        "Content manager plus",
      ];
      if (allowedRoles.includes(role)) roleAllowed = true;
      return this.model.steps && this.model.steps.length > 0 && roleAllowed;
    },
    getTemplateFilter() {
      return this.model.type !== null
        ? "$filter=(type eq '" +
            this.model.type +
            "' and disabled eq false)&$orderby=templateName asc"
        : "$filter=(disabled eq false)&$orderby=templateName asc";
    },
    onTypeChange(item) {
      this.model.type = item?.code;
      this.$nextTick(() => {
        this.$refs["newsItemTemplateId-ProSpaceDropdown"].getData();
      });
    },
    formatDate(date) {
      return (this.getFormatDateTime(date) + ' ' + getAMorPM(date, this.userinfo.timeFormat)).trim()
    }
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      access: "securityRoleAccess/access",
      getFormatDateTime: "userinfoStore/getFormatDateTime",
    }),
    isFilial() {
      return this.model.isDistributorStructureRestricted && !this.checkAccess("isFilial-Expression", this.model);
    },
    disabledSave() {
      return this.v$.model.type.$invalid || this.v$.model.name.$invalid || this.v$.model.message.$invalid; // || _.isEqual(this.model, this.startModel);
    },
    disabledPublished() {
      let distributorsNotChosen = true;
      if(this.model.isDistributorStructureRestricted)
        distributorsNotChosen = this.model.distributorStructures.length === 0;
      else
        distributorsNotChosen = !this.model.isAllDistributors && this.model.distributorIds.length === 0;
      const positionsNotChosen = this.model.isPersonal && !(this.positions && this.positions.length > 0);
      return this.v$.model.$invalid || distributorsNotChosen || positionsNotChosen;
    },
    refLoading() {
      return (
        this.mixinLoadingAccess ||
        this.statusMixinLoadingAccess ||
        this.editFieldMixinLoadingAccess
      );
    },
    refsForCheckInMixin() {
      let refs = [];

      if (this.modelTab.systemName === "parameters")
        refs.push(
          "type-ProSpaceDropdown",
          "name-ProSpaceInputText",
          "message-ProSpaceInputText",
          "publishDate-ProSpaceInputText",
          "tags-ProSpaceInputChips",
          "highImportant-ProSpaceInputSwitch",
          "isPersonal-ProSpaceInputSwitch",
          "isDistributorStructureRestricted-ProSpaceInputSwitch",
          "readConfirmationRequired-ProSpaceInputSwitch",
          "daysToDueDate-ProSpaceInputNumber",
          "dueDate-ProSpaceInputCalendar",
          "attachments-ProSpaceFile"
        );

      if (this.masterId) refs.push("action-createTemplate");

      if (this.model && this.model.status === "newsDraft")
        refs.push("action-publish");

      if (this.model && this.model.status === "newsPublished")
        refs.push("action-save");

      if (
        !this.model ||
        !this.model.status ||
        this.model.status === "newsDraft"
      )
        refs.push("action-saveDraft");

      if (this.modelTab.systemName === "distributors") {
        if(this.model && this.model.isDistributorStructureRestricted)
          refs.push("distributorStructures-DistributorHierarchySelectForm");
        else
          refs.push(
            "isAllDistributors-ProSpaceInputSwitch",
            "addRecipient-button"
          );
      }

      if (this.modelTab.systemName === "positions")
        refs.push("positions-PositionSelectTree");

      if (this.modelTab.systemName === "notifications")
        refs.push("addRecipient-button");

      if (!this.masterId && this.modelTab.systemName === "parameters")
        refs.push("newsItemTemplateId-ProSpaceDropdown");

      return refs;
    },
    recipientGridFilter() {
      if (this.persistRecipients)
        return `$filter=NewsItemId eq ${Number(
          this.masterId
        )} and disabled eq false`;
      return this.oDataStringFilter;
    },
    distributorFilter() {
      return { id: { or: this.model.distributorIds } };
    },
    overviewTabs() {
      return [
        {
          name: this.$t("parameters"),
          systemName: "parameters",
          isActive: this.modelTab.systemName === "parameters",
        },
        {
          name: this.isFilial || this.model.isDistributorStructureRestricted ? this.$t('branches') : this.$t("distributors"),
          systemName: "distributors",
          isDisabled: this.isCreate,
          isHidden: !this.isFilial && !this.checkAccess("tab-distributors-ProSpaceTabs", this.model),
          isActive: this.modelTab.systemName === "distributors",
        },
        {
          name: this.$t("positions"),
          systemName: "positions",
          isHidden: !this.model.isPersonal,
          isActive: this.modelTab.systemName === "positions",
        },
        {
          name: this.$t("newsItemNotificationsMars"),
          systemName: "notifications",
          isDisabled: this.isCreate,
          isHidden: !this.checkAccess(
            "tab-notifications-ProSpaceTabs",
            this.model
          ),
          isActive: this.modelTab.systemName === "notifications",
        },
      ];
    },
    layoutName() {
      return this.masterId
        ? this.model.name
        : this.$t("newTxtF") + " " + this.$t("newsItem");
    },
    statusHeader() {
      return {
        title: this.model.name,
        status: this.newsItemService.getStatusByValue(this.model.status),
      };
    },
    stepItem() {
      return {
        workflowEntityId: this.model.workflowEntityId,
        stepNo: this.model.currentStepNo,
        comment: this.modalModel.comment || "",
        stepType: this.modalModel.stepType || "",
        newsItemId: this.model.id,
        securityUserId: this.userinfo.id,
        securityUserFirstName: this.userinfo.firstName,
        securityUserLastName: this.userinfo.lastName,
        securityUserType: this.userinfo.orgLinkType,
        confirmDate: new Date(),
      };
    },
    positionOptions() {
      let options = {
        positionService: null,
        positionGetMethod: null,
        positionSelectionMode: null,
        positionFilter: null,
        propLabel: null,
      };

      if(this.checkAccess("position-PositionSelectTree", this.model)
        && this.checkStatusAccess("position-PositionSelectTree", this.model)) {
        options.positionService = new DistributorOrgService();
        options.positionGetMethod = "allTree";
        options.positionSelectionMode = "checkbox";
        options.propLabel = "name";
      } else {
        let positionService = new NewsItemService();
        positionService.newsItemId = this.model.id;
        options.positionService = positionService;
        options.positionGetMethod = "getPositions";
        options.positionSelectionMode = "single";
        options.propLabel = "distributorOrgName";
        options.positionFilter = {
          disabled: { and: [false] },
          newsItemId: this.model.id,
        };
      }

      return options;
    },
  },
};
</script>
<style lang="scss">
.newsitem,
.newsitemtemplate {
  .prospace-icon.icon-question {
    background: var(--prospace-text-light-gray);
    cursor: pointer;
  }

  .important-button {
    font-size: 13px;
    line-height: inherit;
  }
  .taskApproved-ProSpaceTextBlock {
    .prospace-text-block__bottom {
      text-align: end;
    }
  }
}
</style>
