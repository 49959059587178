<template>
  <ProSpaceModal
    v-model="visible"
    width="410"
    :title="
      (isCreate ? $t('addTrigger') : $t('editTrigger'))
    "
    :icon="isCreate ? 'plus' : 'edit'"
    :contentStyle="modalContentStyle"
    :cancelTxt="$t('cancelInf')"
    :applyTxt="$t('applyTxt')"
    @apply="save"
  >
    <ProSpaceTabs
      v-if="visible"
      ref="tabs"
      :tabs="tabs"
      @select-tab="tabSelected"
    >
      <template v-slot:tab-1>
        <TriggerEditorScheduling
          :triggerOptions="trigger.triggerSettings"
          v-model:endAt="trigger.triggerSettings.EndAt"
          v-model:startAt="trigger.triggerSettings.StartAt"
        />
      </template>
      <template v-slot:tab-2>
        <TriggerEditorParameters
          ref="triggerParameters"
          :context="trigger.context"
        />
      </template>
    </ProSpaceTabs>
    <template v-slot:footer>
      <ProSpaceHLayout :gap="5" justify-content="flex-end">
        <ProSpaceButton
          type="secondary"
          :label="$t('cancelInf')"
          @click="cancel"
        />
        <ProSpaceButton
          type="primary"
          :disabled="loading"
          :label="primaryBtnText"
          :icon="primaryBtnIcon"
          @click="primaryBtnHandler"
        />
      </ProSpaceHLayout>
    </template>
  </ProSpaceModal>
</template>

<script>
import {
  ProSpaceHLayout,
  ProSpaceModal,
  ProSpaceTabs,
  ProSpaceButton,
} from "prospace-components-library";

import TriggerEditorScheduling from "./components/TriggerEditorScheduling";
import TriggerEditorParameters from "./components/TriggerEditorParameters";
import { JobTriggerService } from "../../../services/JobTriggerService.js";
import moment from "moment";
import _ from "lodash";

export default {
  components: {
    ProSpaceHLayout,
    ProSpaceModal,
    ProSpaceTabs,
    ProSpaceButton,
    TriggerEditorScheduling,
    TriggerEditorParameters,
  },
    props: {
    isJobWindow: {
      type: Boolean,
      default: false
    },
    activeTab: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      trigger: null,
      visible: false,
      isCreation: false,
      loading: false,

      modelTabIndex: 0,
      modelTab: { name: this.$t("scheduling") },

      jobTriggerService: new JobTriggerService(),

      modalContentStyle: {
        overflow: 'hidden',
        height: '370px',
        paddingBottom: '0'
      },

      defaultTrigger: {
        triggerSettings: {
          TriggerPeriodic: 0,
          day: null,
          Every: null,
          EveryDay: null,
          DaysOfWeek: [],
          StartAt: null,
          EndAt: null,
          PeriodicTime: null,
        },
        context: {},
      },
    };
  },
  computed: {
    tabs() {
      return [
        { name: this.$t("scheduling"), isActive: !this.activeTab || this.activeTab === 'scheduling' },
        { name: this.$t("parameters"), isActive: this.activeTab === 'parameters' },
      ];
    },
    isCreate() {
      return this.isCreation;
    },
    icon() {
      return { faPlus, faPen };
    },
    primaryBtnText() {
      return this.modelTab.name == this.$t("scheduling")
        ? this.$t("next")
        : this.$t("save");
    },
    primaryBtnIcon() {
      return this.modelTab.name == this.$t("scheduling") ? null : "check";
    },
    primaryBtnHandler() {
      return this.modelTab.name == this.$t("scheduling")
        ? this.next
        : this.save;
    },
  },
  watch: {
    visible: {
      handler(visible) {
        if (!visible) {
          this.closed();
        }
      },
    },
  },
  methods: {
    show(trigger) {
      this.isCreation = !trigger.id;

      if (!this.isCreation) {
        this.trigger = _.cloneDeep(trigger);

        try {
          this.trigger.triggerSettings = JSON.parse(this.trigger.triggerSettings);
          this.trigger.context = JSON.parse(this.trigger.context);
        } catch (e) {

        }

        let dateAndTime = moment(this.trigger.triggerSettings.PeriodicTime, [moment.ISO_8601, 'HH:mm:ss']);
        this.trigger.triggerSettings.PeriodicTime = dateAndTime
      } else {
        this.trigger = Object.assign(trigger, _.cloneDeep(this.defaultTrigger));
      }

      this.visible = true;
    },
    close(){
      this.visible = false;
      this.closed();
    },
    closed() {
      this.trigger = null;
      this.isCreation = false;
      this.loading = false;
      this.$refs.tabs.setTabs(0);
      this.modelTab = { name: this.$t("scheduling") };
      this.modelTabIndex = 0;
    },
    save() {
      let me = this;
      me.$refs.triggerParameters.buildContext();
      let saveModel = _.cloneDeep(me.trigger);
      let saveMethod = me.isCreation ? "create" : "update";

      saveModel.triggerSettings.PeriodicTime = saveModel.triggerSettings
        .PeriodicTime
        ? moment(saveModel.triggerSettings.PeriodicTime).format(
            moment.HTML5_FMT.TIME_SECONDS
          )
        : null;

      saveModel.triggerSettings = JSON.stringify(saveModel.triggerSettings);
      saveModel.context = JSON.stringify(saveModel.context);
      // if opened from the job window, there is no need to send a request from here
      if (!me.isJobWindow){
        me.loading = true;
        me.jobTriggerService[saveMethod](saveModel).then((response) => {
          me.visible = false;
          me.$emit("saved", response.data[0]);
        })
        .catch(() => {
          me.loading = false;
        });
      } else {
        if (me.isCreation){
          saveModel.localId = new Date().getTime();
        }
        me.$emit("saved", saveModel)
      }
    },
    tabSelected(obj) {
      this.modelTab = obj.selectedTab;
      this.modelTabIndex = obj.index;
    },
    next() {
      this.$refs.tabs.select(
        { name: this.$t("parameters") },
        this.modelTabIndex + 1
      );
    },
    cancel() {
      this.visible = false;
    },
  },
};
</script>

<style></style>
