import request from "../Frontends/Common/utils/request/requestMain";
const rootRoute = "securityadmin/menuitem/";

export default class MenuItemsApi {
  async getMenuItems(params) {
    return await request.post(`${rootRoute}getmenuitems`, {
      filter: params,
    });
  }
  async getMenuItem(id) {
    return await request.post(`${rootRoute}getmenuitems`, {
      filter: `$filter=id eq ${id}`,
      parameters: {},
    });
  }
  async createMenuItem(model) {
    return await request.post(`${rootRoute}createmenuitem`, {
      data: model
    });
  }
  async updateMenuItem(model) {
    return await request.post(`${rootRoute}updatemenuitem`, {
      data: model
    });
  }
  async deleteMenuItem(model) {
    return await request.post(`${rootRoute}deletemenuitem`, {
      data: model
    });
  }
}
