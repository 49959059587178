import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/SystemSettingApi";

export class SystemSettingService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async get(id) {
    return await this.transformer.getTree(this.Api, "getSystemSetting", id);
  }
  async getItems(fullPath) {
    return await this.transformer.getNodeItems(this.Api, "getSystemSettingItems", fullPath);
  }
  async editItems(models) {
    return await this.transformer.editItem(this.Api, "updateSystemSettingItems", models);
  }
  async getNode(parentId) {
    let result = await this.transformer.getNodeItems(this.Api, "getSystemSettingNode", parentId)
    return Promise.resolve({ data: result.data });
  }
}
