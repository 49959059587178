import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/information/home/";

export default class HomeApi {
  constructor() { }
  async getDashboard(data) {
    return await request.post(`${rootRoute}getDashboard`, {
      role: data.role,
      timezone: data.tz,
      parameters: {},
    });
  }
}
