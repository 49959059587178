import { Transformer } from "../../../../Transformer/index.js";

export class WorkflowItemTypeService {
  constructor() {
    this.transformer = new Transformer();
    this.workflowItemTypes = [
      {label: "Condition", value: 10},
      {label: "Approving", value: 20},
      {label: "Rejected", value: 30},
      {label: "Approved", value: 40},
      {label: "Canceled", value: 0},
    ];
  
  }
  async getStepTypes(args) {
    return Promise.resolve({ data: { records: this.workflowItemTypes } });
  }
}
