import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/report/report/";

export default class ReportApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getReports(params) {
    return await request.post(`${rootRoute}getReports`, {
      filter: params,
      parameters: {},
    });
  }
  async getReportsView(params) {
    return await request.post(`${rootRoute}getreportsview`, {
      filter: params,
      parameters: {},
    });
  }
  async getReport(id) {
    return await request.post(`${rootRoute}getReports`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createReport(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createReport`, { data });
  }
  async deleteReport(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteReport`, { data });
  }
  async editReport(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateReport`, { data });
  }
}
