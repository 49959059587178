import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/NewsService/NewsItemTemplateApi";

export class NewsItemTemplateService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(this.Api, "editNewsItemTemplate", fields);
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editNewsItemTemplate");
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getNewsItemTemplates", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getNewsItemTemplate", null, id);
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createNewsItemTemplate", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteNewsItemTemplate", model);
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "editNewsItemTemplate", model);
  }
  async getPositions() {
    return await this.transformer.toTree(this.Api, "getPositions", `$filter=newsItemTemplateId eq ${this.newsItemTemplateId} and disabled eq false`, "distributorOrgId");
  }
}
