import request from "../Frontends/Common/utils/request/requestMain";
const rootRoute = "securityadmin/securityusecase/";
export default class UseCasesApi {
  async getUseCases(params) {
    return await request.post(`${rootRoute}getsecurityusecases`, {
      filter: params,
      parameters: {},
    });
  }
  async getUseCase(id) {
    return await request.post(`${rootRoute}getsecurityusecases`, {
      filter: `$filter=id eq ${id}`,
      parameters: {},
    });
  }
  async deleteUseCase(model) {
    const data = [model];
    return await request.post(`${rootRoute}deletesecurityusecase`, { data });
  }
  async updateUseCase(model) {
    const data = [model];
    return await request.post(`${rootRoute}updatesecurityusecase`, { data });
  }
  async createUseCase(model) {
    const data = [model];
    return await request.post(`${rootRoute}createsecurityusecase`, { data });
  }
}
