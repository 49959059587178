import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/DocumentService/DocumentViewApi";

export class DocumentViewService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
    this.documentId = null;
    this.assignOptions = {
      onPosition: false,
      onSlave: false,
      onFilial: false,
      onDistributor: false
    }
  }
  async grid(args) {
    let role = JSON.parse(localStorage.getItem("userRole")).systemName;
    return await this.transformer.getGrid(this.Api, "getDocumentViews", {args, role, assignOptions: this.assignOptions});
  }
  async get(id) {
    let role = JSON.parse(localStorage.getItem("userRole")).systemName;
    return await this.transformer.getGridPanel(this.Api, "getDocumentView", null, {id, role});
  }
  async getDistributors(filter) {
    return await this.transformer.getGrid(this.Api, "getDistributors", filter);
  }
  async allTree() {
    return await this.transformer.toTree(this.Api, "getDistributorOrgs", "");
  }
  async getPositions() {
    return await this.transformer.toTree(this.Api, "getPositions", `$filter=documentId eq ${this.documentId} and disabled eq false`, "distributorOrgId");
  }
  async getStatuses() {
    const docTypes = [
      { label: "Черновик", value: "Draft" },
      { label: "Публикация", value: "Publication" },
    ]
    const data = {records: docTypes}
    return Promise.resolve( { data })
  }
  async getAssignOptions() {
    const options = [
      { label: "На мою должность", value: "onPosition" },
      { label: "На подчиненных", value: "onSlave" },
      { label: "На филиал", value: "onFilial" },
      { label: "На Дистрибьютора", value: "onDistributor" },
    ]
    const data = {records: options}
    return Promise.resolve( { data })
  }
  setAssignOptions(options) {
    for (let key of Object.keys(this.assignOptions)) {
      if (options.includes(key)) 
        this.assignOptions[key] = true;
      else
        this.assignOptions[key] = false;
    }
  }
  clearAssignOption() {
    for (let key of Object.keys(this.assignOptions))
      this.assignOptions[key] = false;
  }
}
