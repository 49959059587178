import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/reserveservice/reserve/";

export default class ReserveApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getReserves(params) {
    return await request.post(`${rootRoute}getReserves`, {
      filter: params,
      parameters: {},
    });
  }
  async getReserve(id) {
    return await request.post(`${rootRoute}getReserves`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async getShipTo(filter) {
    return await request.post(`${rootRoute}getShipTo`, {
      filter: filter,
      parameters: {},
    });
  }
  async createReserve(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createReserve`, { data });
  }
  async deleteReserve(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteReserve`, { data });
  }
  async editReserve(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateReserve`, { data });
  }
}
