<template>
  <DistributorContactPersonGrid
    ref="cpGrid"
    masterField="distributorId"
    cpAbbreviation="acp"
    :model="distributor"
    :service="authorizedContactPersonService"
    :mountAccess="mountAccess"
    :checkAccess="checkAccess"
    :validationProps="validations"
    :fields="fields"
    :modalFields="modalFields"
    :modalHeader="$t('authorizedPerson')"
    :mainTxt="$t('authorizedContactPersonCreateInfo')"
    :infoTxt="$t('authorizedContactPersonCreateInfoAdditional')"
  />
</template>
<script>
import DistributorContactPersonGrid from "./DistributorContactPersonGrid";
import { AuthorizedContactPersonService } from "../../../services/AuthorizedContactPersonService.js";
import { UserService } from "../../../../../SecurityAdmin/ProSpace.SecurityAdmin.Frontend/services/UserService.js";
import { DistributorOrgService } from "../../../services/DistributorOrgService.js";
import {
  required,
  maxLength,
  helpers,
  email,
  minValue,
  maxValue,
} from "@vuelidate/validators";

export default {
  components: {
    DistributorContactPersonGrid,
  },
  props: {
    distributor: {
      type: Object,
      required: true,
    },
    mountAccess: {
      type: Function,
      required: true,
    },
    checkAccess: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      authorizedContactPersonService: new AuthorizedContactPersonService(),
      userService: new UserService(),
    };
  },
  computed: {
    validations() {
      const registeredRequired = (v) => {
        if (!this.$refs["cpGrid"]) return true;
        if (!this.$refs["cpGrid"].modalModel.isRegistered) return true;
        return !!v;
      };
      const registeredReverseRequired = (v) => {
        if (!this.$refs["cpGrid"]) return true;
        if (this.$refs["cpGrid"].modalModel.isRegistered) return true;
        return !!v;
      };
      return {
        distributorId: {},
        systemPosition: {},
        securityUserId: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            registeredRequired
          ),
        },
        isRegistered: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        fio: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            registeredReverseRequired
          ),
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '255'}),
            maxLength(255)
          ),
        },
        position: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '255'}),
            maxLength(255)
          ),
        },
        email: {
          email: helpers.withMessage(
            this.$t("notValidEmail"),
            email
          ),
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            registeredReverseRequired
          ),
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '100'}),
            maxLength(100)
          ),
        },
      };
    },
    modalFields() {
      return [
        [
          {
            name: "isRegistered",
            displayName: this.$t("isRegistered"),
            type: "dropdown",
            service: this.authorizedContactPersonService,
            serviceGetMethod: "getIsRegisteredValues",
            propValue: "value",
            propLabel: "label",
            update: (data, ref) => {
              let cpg = this.$refs.cpGrid;
              cpg.modalModel.fio = null;
              cpg.modalModel.email = null;
              cpg.modalModel.systemPosition = null;
              cpg.modalModel.securityUserId = null;
              let userField = cpg.$refs["securityUserId"][0];
              if (userField) userField.clear();
            },
          },
        ],
        [
          {
            name: "position",
            displayName: this.$t("positionFull"),
            placeholder: this.$t("authorizedContactPersonPosition")
          },
        ],
        [
          {
            name: "securityUserId",
            displayName: this.$t("fioFull"),
            type: "search",
            service: this.userService,
            serviceGetMethod: "grid",
            propValue: "id",
            propLabel: "fullName",
            defaultFilter: {
              disabled: { and: [false] },
              orgLinkType: { and: ["distributor"] },
              restrictionFieldValue: { and: [this.distributor.code] },
              restrictionModelName: { and: ["distributordomainmodel"] },
              restrictionFieldName: { and: ["code"] },
              restrictionDisabled: { and: [false] },
            },
            filterModel: [
              "firstName",
              "secondName",
              "lastName",
              "fullName",
              "login",
              "email",
            ],
            icon: "list-check",
            modalTitle: this.$t("selectTxt") + " " + this.$t("userCreateLower"),
            columns: [
              {
                name: "fullName",
                header: this.$t("user"),
              },
              {
                name: "orgLinkDistributorOrgName",
                header: this.$t("systemPosition"),
              },
              {
                name: "login",
                header: this.$t("login"),
              },
              {
                name: "email",
                header: this.$t("email"),
              },
            ],
            customServices: [
              {
                type: "number",
                model: "id",
                label: this.$t("id"),
                selected: {},
              },
              {
                type: "checkboxes",
                service: new DistributorOrgService(),
                getMethod: "grid",
                model: "orgLinkDistributorOrgId",
                label: this.$t("systemPosition"),
                optionValue: "id",
                optionLabel: "name",
                selected: {},
                defaultFilter: { disabled: false },
              },
              {
                type: "chips",
                model: "login",
                label: this.$t("login"),
                selected: {},
              },
              {
                type: "chips",
                model: "email",
                label: this.$t("email"),
                selected: {},
              },
            ],
            panelHeader: (user) => {
              return {
                title: user.fullName,
                id: user.id,
                showCounter: false,
              };
            },
            panelContent: (user) => {
              return [
                [
                  {
                    title: this.$t("user"),
                    text: user.fullName,
                  },
                ],
                [
                  {
                    title: this.$t("systemPosition"),
                    text: user.orgLinkDistributorOrgName,
                  },
                ],
                [
                  {
                    title: this.$t("login"),
                    text: user.login,
                  },
                ],
                [
                  {
                    title: this.$t("email"),
                    text: user.email,
                  },
                ],
              ];
            },
            isHide: (model) => !model.isRegistered,
            update: (data, ref) => {
              let cpg = this.$refs.cpGrid;
              let selectedItem = cpg.$refs[ref][0].selectedItem;
              if (selectedItem) {
                cpg.modalModel.fio = selectedItem.fullName;
                cpg.modalModel.email = selectedItem.email;
                cpg.modalModel.systemPosition =
                  selectedItem.orgLinkDistributorOrgName;
              }
            },
          },
        ],
        [
          {
            name: "fio",
            displayName: this.$t("fioFull"),
            placeholder: this.$t("anotherContactPersonFIO"),
            isHide: (model) => model.isRegistered,
            isDisabled: (model) => model.isRegistered === null || model.isRegistered === undefined,
          },
        ],
        [
          {
            name: "systemPosition",
            displayName: this.$t("systemPosition"),
            isHide: (model) => !model.isRegistered,
            isDisabled: (model) => model.isRegistered === null || model.isRegistered === undefined || model.isRegistered,
          },
        ],
        [
          {
            name: "email",
            displayName: this.$t("anotherContactPersonEmail"),
            placeholder: this.$t("anotherContactPersonEmail"),
            isDisabled: (model) => model.isRegistered === null || model.isRegistered === undefined || model.isRegistered,
          },
        ],
      ];
    },
    fields() {
      return [
        {
          value: "position",
          name: this.$t("positionFull"),
          f: (item) => item,
        },
        {
          value: "fio",
          name: this.$t("fioFull"),
          f: (item) => item,
        },
        {
          value: "isRegistered",
          name: this.$t("isRegistered"),
          f: (item) => (!!item ? this.$t("yes") : this.$t("no")),
        },
        {
          value: "email",
          name: this.$t("email"),
        },
      ];
    },
  },
};
</script>
<style lang="scss">
</style>
