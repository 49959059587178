import request from "../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/workflow/workflowsetting/";

export default class WorkflowApi {
  constructor() {
  }
  async get(params) {
    return await request.post(`${rootRoute}get`, {
      filter: params,
      parameters: {},
    });
  }
  async getById(id) {
    return await request.post(`${rootRoute}get`, {
      filter: `$filter=id eq ${id}`,
      parameters: {},
    });
  }
  async create(model) {
    const data = [model];
    return await request.post(`${rootRoute}create`, { data });
  }
  async delete(model) {
    const data = [model];
    return await request.post(`${rootRoute}delete`, { data });
  }
  async update(model) {
    const data = [model];
    return await request.post(`${rootRoute}update`, { data });
  }
  async setActive(model) {
    const data = [model];
    data.status = "ACTIVE";
    return await request.post(`${rootRoute}setactive`, { data });
  }
  async createDraft(model) {
    const data = [model];
    return await request.post(`${rootRoute}createdraft`, { data });
  }
}
