<template>
  <router-view />
</template>

<script>
import BaseLayout from "../../SystemSetting/ProSpace.SystemSetting.Frontend/App";
export default {
  components: { BaseLayout },
  beforeCreate() {
    //document.documentElement.setAttribute("data-theme", "ps-light-theme");
  },
};
</script>
