import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/masterdata/empoweredcontactperson/";

export default class EmpoweredContactPersonApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getEmpoweredContactPersons(params) {
    return await request.post(`${rootRoute}getEmpoweredContactPersons`, {
      filter: params,
      parameters: {},
    });
  }
  async getEmpoweredContactPerson(id) {
    return await request.post(`${rootRoute}getEmpoweredContactPersons`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createEmpoweredContactPerson(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createEmpoweredContactPerson`, { data });
  }
  async deleteEmpoweredContactPerson(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteEmpoweredContactPerson`, { data });
  }
  async editEmpoweredContactPerson(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateEmpoweredContactPerson`, { data });
  }
  async export(filter, format) {
    return await request.post(`${rootRoute}exportEmpoweredContactPerson`, {
      filter: filter,
      format: format
    });
  }
}
