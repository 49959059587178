import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/Competence/CompetenceApi";
import CommentApi from "../../../../Api/Competence/CompetenceCommentApi";
import i18n from "../../../../Frontends/Common/utils/i18n";
import store from "../../../../Frontends/Common/store";

export class CompetenceService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
    this.hasExpiredFilterTypes = [
      { text: i18n.global.t("yes"), value: true },
      { text: i18n.global.t("no"), value: false }
    ];
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(this.Api, "editCompetence", fields);
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editCompetence");
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getCompetences", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getCompetence", null, id);
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createCompetence", model);
  }
  async createcompetencewithtaskcopies(model, copyTaskCompetenceId) {
    return await this.transformer.createItem(this.Api, "createcompetencewithtaskcopies", { model, copyTaskCompetenceId });
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteCompetence", model);
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "editCompetence", model);
  }
  async getHasExpiredFilterTypes() {
    return Promise.resolve({ data: { records: this.hasExpiredFilterTypes } });
  }
  async addComment(model) {
    return await this.transformer.createItem(this.CommentApi, "createCompetenceComment", model);
  }
  async export(filter, additionalFilter, format) {
    let role = JSON.parse(localStorage.getItem("userRole")).systemName;
    const res = this.Api.export(filter, additionalFilter, role, format);
    await store.dispatch('notifications/handleResponse', {
      promiseResponse: res,
    })
  }
}
