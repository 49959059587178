import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/Information/FaqApi";
import FileApi from "../../../../Api/FileApi";
import store from "../../../../Frontends/Common/store";

export class FaqService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
    this.FileApi = new FileApi();
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(this.Api, "editFaq", fields);
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editFaq");
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getFaqs", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getFaq", null, id);
  }
  async tree(args) {
    let result = await this.transformer.getTree(
      this.Api,
      "getFaqBaseTypeViews",
      args
    );
    const all = [{
      id: 0,
      name: "Все",
      code: "Все",
      icon: "info",
      faqCount: result.data.reduce(function (acc, obj) {
        return acc + obj.faqCount;
      }, 0),
    }];
    result.data = all.concat(result.data);
    const data = result.data.map((i) => {
      i.leaf = true;
      return i;
    });
    return Promise.resolve({ data });
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createFaq", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteFaq", model);
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "editFaq", model);
  }
  async acceptFiles(gfids, faqId) {
    return this.setStatus(gfids, faqId, 0);
  }
  async errorFiles(gfids, faqId) {
    return await this.setStatus(gfids, faqId, 255);
  }
  async setStatus(gfids, faqId, status) {
    var models = gfids.map(x => {
      return {
        id: 0,
        fileName: "",
        gfid: x,
        faqId: faqId,
        statusFlag: status,
        disabled: false
      }
    })
    return await this.transformer.editItem(this.Api, "setStatusFaqAttachment", models);
  }
  async export(filter, format) {
    const res = this.Api.export(filter, format);
    await store.dispatch('notifications/handleResponse', {
      promiseResponse: res,
    })
  }
}
