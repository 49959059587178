// import Cookies from 'js-cookie'

const AccessTokenKey = "SecurityAdmin-Access-Token";
const RefreshTokenKey = "SecurityAdmin-Refresh-Token";
const AutologinAvailableKey = "SecurityAdmin-Autologin-Available";
const RedirectUrlKey = "SecurityAdmin-Redirect-Url";
const LoginTypeKey = "SecurityAdmin-Login-Type";
const StorageLocation = "localStorage"; // 'sessionStorage'
const Aik = "Aik";

export function setAik(aik) {
  window[StorageLocation].setItem(
    Aik,
    window.btoa(unescape(encodeURIComponent(aik)))
  );
}

export function getAik() {
  const value = window[StorageLocation].getItem(Aik);
  if (!value) {
    return null;
  } else {
    const encodedAik = escape(window.atob(value));
    const result = decodeURIComponent(encodedAik);
    return result;
  }
}

export function removeAik() {
  window[StorageLocation].removeItem(Aik);
}

export function getToken() {
  const result = window[StorageLocation].getItem(AccessTokenKey);
  return result;
}

export function setToken(token) {
  window[StorageLocation].setItem(AccessTokenKey, token);
}

export function removeToken() {
  window[StorageLocation].removeItem(AccessTokenKey);
}

export function getRefreshToken() {
  const result = window[StorageLocation].getItem(RefreshTokenKey);
  return result;
}

export function setRefreshToken(token) {
  window[StorageLocation].setItem(RefreshTokenKey, token);
}

export function removeRefreshToken() {
  window[StorageLocation].removeItem(RefreshTokenKey);
}

export function getAutologinAvailable() {
  const result =
    window[StorageLocation].getItem(AutologinAvailableKey) || "true";
  return result == true.toString();
}

export function setAutologinAvailable(value) {
  window[StorageLocation].setItem(AutologinAvailableKey, value);
}

export function removeAutologinAvailable() {
  window[StorageLocation].removeItem(AutologinAvailableKey);
}

export function getRedirectUrl() {
  const val = window[StorageLocation].getItem(RedirectUrlKey);
  let result = JSON.parse(val);
  return result;
}

export function setRedirectUrl(value) {
  let val = JSON.stringify(value);
  window[StorageLocation].setItem(RedirectUrlKey, val);
}

export function removeRedirectUrl() {
  window[StorageLocation].removeItem(RedirectUrlKey);
}

export function setLoginType(value) {
  window[StorageLocation].setItem(LoginTypeKey, value);
}

export function getLoginType() {
  const val = window[StorageLocation].getItem(LoginTypeKey);
  return val;
}

export function clearTokens() {
  removeToken();
  removeRefreshToken();
  removeAik();
}
