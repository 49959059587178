import request from "../Frontends/Common/utils/request/requestMain";
const rootRoute = "securityadmin/securityroleaccess/";

export default class SecurityRoleAccessApi {
  constructor() {
    this.defaultModel = {};
  }
  async getSecurityRoleAccess() {
    return await request.post(`${rootRoute}getSecurityRoleAccess`, {
      filter: "$top=100&$skip=0",
    });
  }
}
