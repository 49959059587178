import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/MasterData/DistributorStructureApi";

export class DistributorStructureService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
    this.billToCodes = [];
    this.nodeViewIds = [];
    this.rawTreeRecords = [];
    this.distributorId = null;
    this.docId = null;
    this.newsId = null;
    this.userId = null;
    this.taskAssignmentId = null;
    this.dsTypes = {
      billto: "Bill to",
      soldto: "GRD",
      virtualgrd: "Virtual GRD",
      filial: "Filial",
      office: "Office"
    }
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getDistributorStructures", "$filter=disabled eq false");
  }
  async positions(args) {
    return await this.transformer.getGrid(this.Api, "getDistributorStructuresPositions", "$filter=disabled eq false");
  }
  async allTree() {
    let filter = this.billToCodes && this.billToCodes.length > 0 
      ? `$filter=(${this.billToCodes.map(c => `billToCode eq '${c}'`).join(" or ")}) and disabled eq false` 
      : `$filter=disabled eq false` ;
    let res = await this.transformer.toTree(this.Api, "getDistributorStructures", filter, "id", true);
    this.rawTreeRecords = res.rawData;

    return res.data;
  }
  async allFilialStructureTree() {
    let filter = this.billToCodes && this.billToCodes.length > 0 
      ? `$filter=(${this.billToCodes.map(c => `billToCode eq '${c}'`).join(" or ")}) and disabled eq false and distributorDisabled eq false` 
      : `$filter=disabled eq false and distributorDisabled eq false` ;

    let res = await this.transformer.toTree(this.Api, "getFilialStructures", filter, "id", true);
    this.rawTreeRecords = res.rawData;
    return res.data;
  }
  async getStructure(filter) {
    return await this.transformer.getGrid(this.Api, "getDistributorStructures", filter);
  }
  async getViewTree() {
    return await this.transformer.toTree(this.Api, "getAssignedBranch", this.nodeViewIds, "id");
  }
  async getDocViewTree() {
    return await this.transformer.toTree(this.Api, "getAssignedBranchByDocId", this.docId, "id");
  }
  async getNewsViewTree() {
    return await this.transformer.toTree(this.Api, "getAssignedBranchByNewsId", this.newsId, "id");
  }
  async getUserViewTree() {
    return await this.transformer.toTree(this.Api, "getAssignedBranchByUserId", this.userId, "id");
  }
  async getTaskAssignmentViewTree() {
    return await this.transformer.toTree(this.Api, "getAssignedBranchByTaskAssignmentId", this.taskAssignmentId, "id");
  }
  async getView() {
    return await this.transformer.getGrid(this.Api, "getAssignedBranch", this.nodeViewIds);
  }
  async getDocView() {
    return await this.transformer.getGrid(this.Api, "getAssignedBranchByDocId", this.docId);
  }
  async getNewsView() {
    return await this.transformer.getGrid(this.Api, "getAssignedBranchByNewsId", this.newsId);
  }
  async getUserView() {
    return await this.transformer.getGrid(this.Api, "getAssignedBranchByUserId", this.userId);
  }
  async allFilial() {
    let filter = this.distributorId 
      ? `$filter=distributorId eq ${this.distributorId} and type ne 'Office' and disabled eq false`
      : `$filter=type ne 'Office' and disabled eq false`;
    let result = await this.transformer.getTree(this.Api, "getDistributorStructures", filter)

    const findNode = (treeId) => {
      return result.data.find(i => i["id"] === treeId);
    }
    const createTree = (item) => {
      if (item.parentId) {
        const node = findNode(item.parentId);
        if (!node) {
          return
        }
        if (!node.children) {
          node.children = []
        }
        node.children.push(item);
      } else {
        tree.push(item);
      }
    }
    
    result.data = result.data.map((i) => {
      if (i.type === 'Filial') {
        i.leaf = true;
      }
      return i;
    })

    this.rawTreeRecords = result.data;
    const tree = []
    result.data.forEach(createTree);
    result.data = tree;

    return { data: result.data } ;
  }
  async getNode(id) {
    let result =  await this.transformer.getTree(this.Api, "get", id);
    this.prepareTree(result.data);
    return result;
  }
  prepareTree(tree) {
    for (let e of tree) {

      e.key = e.id;
      e.label = e.name;
    }
  }
  setBillToCodes(codes) {
    this.billToCodes = [...codes];
  }
  setDistributorId(distributorId) {
    this.distributorId = distributorId;
  }
  setNodeViewIds(ids) {
    this.nodeViewIds = [...ids];
  }
  setDocId(id) {
    this.docId = id;
  }
  setNewsId(id) {
    this.newsId = id;
  }
  setUserId(id) {
    this.userId = id;
  }
  setTaskAssignmentId(id) {
    this.taskAssignmentId = id;
  }
}
