<template>
  <ProSpaceGeoLayout>
    <template #center>
      <ProSpaceFilterPanel
        :searchPlaceholder="$t('searchTxt')"
        :closeTxt="$t('closeTxt')"
        :txtResetFilters="$t('txtResetFilters')"
        :modelFastFilter="modelFastFilter"
        :fastFilters="fastFilters"
        :unicKeyStorage="systemLogService.constructor.name + 'grid'"
        @apply="submitFilter"
        ref="filterPanel"
        filter-model="name"
        @update:modelFastFilter="
          (val) => {
            modelFastFilter = val;
            selectedMultipleClear();
          }
        "
      >
        <template #hint> {{ $t("filterBy") }}</template>
        <template #filters="{ filter }">
          <ProSpaceCustomFilterPanel
            :customTxt="$t('custom')"
            :customFilterTxt="$t('customFilterTxt')"
            :clearFilterTxt="$t('clearFilterTxt')"
            :selectAllTxt="$t('selectAllTxt')"
            :closeTxt="$t('closeTxt')"
            :applyTxt="$t('applyTxt')"
            :fromTxt="$t('fromTxt')"
            :toTxt="$t('toTxt')"
            :services="customFilters"
            :unicKeyStorage="systemLogService.constructor.name + 'grid'"
            @apply="(model) => customFilterApply(filter, model)"
          />
        </template>
      </ProSpaceFilterPanel>
      <ProSpaceMainGrid style="height: calc(100% - 90px)"
        ref="grid"
        getMethod="grid"
        selection-mode="single"
        :service="systemLogService"
        :defaultFilter="defaultFilter"
        @selected-change="selectedHandler"
        :noMountedRequest="true"
        defaultSortField="id"
        :defaultSortOrder="2"
      >
        <template #columns>
          <Column field="name" :header="$t('taskName')" sortable>
            <template #body="field">
              <ProSpaceTableHeaderCell
                v-if="field.data"
                :id="field.data.id"
                :status="getStatus(field.data.status)"
                :title="field.data.name"
                :no-hover="true"
                :description="`${field.data.description || ''}`"
              />
            </template>
          </Column>
          <Column field="startedAt" :header="$t('startExecution')" sortable>
            <template #body="field">
              <ProSpaceDateTimeColumn
                :date="field.data.startedAt"
                :dateFormat="userinfo.dateFormat"
                :timeFormat="userinfo.timeFormat"
              />
            </template>
          </Column>
          <Column field="finishedAt" :header="$t('endExecution')" sortable>
            <template #body="field">
              <ProSpaceDateTimeColumn
                :date="field.data.finishedAt"
                :dateFormat="userinfo.dateFormat"
                :timeFormat="userinfo.timeFormat"
              />
            </template>
          </Column>
          <Column field="execution" :header="$t('executionTime')" sortable>
            <template #body="field">
              <ProSpaceDefaultTextColumn>
                {{ field.data.execution }}
              </ProSpaceDefaultTextColumn>
            </template>
          </Column>
          <Column field="actions" class="column-end column-end-30">
            <template #body="field">
              <ProSpaceDots
                :options="dotsOptions"
                @action="
                  (action) => handleDots(dotsOptions, action, field.data)
                "
              />
            </template>
          </Column>
        </template>
        <template #empty>
          <ProSpaceEmptyGrid
            icon="error-message"
            :title="$t('noDataTitle')"
            :text="$t('noDataTxt')"
          />
        </template>
      </ProSpaceMainGrid>
      <TaskResult ref="result" @action="showParameter" />
      <TaskLog ref="log" />
    </template>
    <template #right>
      <SystemLogPanel v-if="hasSelect"
        ref="right-panel"
        :service="systemLogService"
        :id="selectedData.id"
        :options="dotsOptions"
        @result="showResult"
      />
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import {
  ProSpaceGeoLayout,
  ProSpaceTableHeaderCell,
  ProSpaceMainGrid,
  ProSpaceCustomFilterPanel,
  ProSpaceFilterPanel,
  ProSpaceDots,
  ProSpaceEmptyGrid,
  ProSpaceModal,
  ProSpaceDateTimeColumn,
  ProSpaceDefaultTextColumn,
} from "prospace-components-library";
import { SystemLogService } from "../../../services/SystemLogService.js";
import Column from "primevue/column";
import SystemLogPanel from "./SystemLogPanel";
import TaskResult from "../../components/TaskResult";
import TaskLog from "../../components/TaskLog";
import { mapGetters } from "vuex";
const _ = require("lodash");
import {
  useMultiSelectGrid,
} from "@composes/multiselect";
import { ref } from "vue"
export default {
  components: {
    ProSpaceDefaultTextColumn,
    ProSpaceDateTimeColumn,
    ProSpaceGeoLayout,
    ProSpaceTableHeaderCell,
    ProSpaceMainGrid,
    ProSpaceCustomFilterPanel,
    ProSpaceFilterPanel,
    ProSpaceDots,
    ProSpaceEmptyGrid,
    ProSpaceModal,
    Column,
    SystemLogPanel,
    TaskResult,
    TaskLog,
  },
  props: {
    task: {
      type: Object,
    },
  },
  data() {
    return {
      systemLogService: new SystemLogService(),
    };
  },
  setup() {
    const grid = ref(null)
    const filterPanel = ref(null)
    const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      selectedData,
      hasSelect,
    } = useMultiSelectGrid(grid, filterPanel)
    return {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      selectedData,
      hasSelect,
    }
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime",
    }),
    dotsOptions() {
      return [
        {
          name: "result",
          text: this.$t("taskResult"),
          icon: "edit",
          method: this.showResult,
        },
      ];
    },
    defaultFilter() {
      if (!this.task) return {};

      return {
        jobId: { and: [this.task.id] },
      };
    },
    modelFastFilter() {
      return {
        label: this.$t("allTxt"),
      };
    },
    fastFilters() {
      return [
        {
          label: this.$t("allTxt"),
        },
        { label: this.$t("completeS"), field: "status", value: "completed" },
        { label: this.$t("error"), field: "status", value: "error" },
      ];
    },
    customFilters() {
      return [
        {
          type: "dates-range",
          model: "startedAt",
          label: this.$t("startExecution"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range",
          model: "finishedAt",
          label: this.$t("endExecution"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
      ];
    },
  },
  methods: {
    handleDots(dotsOptions, action, item) {
      if (action != "parameter") {
        var option = dotsOptions.find((o) => o.name === action);
        option.method(item);
      } else {
        this.showParameter(item);
      }
    },
    update() {
      this.$refs.grid.getRecords(true);
      if (this.$refs["right-panel"]) {
        this.$refs["right-panel"].update();
      }
    },
    submitFilter({ filter, search }) {
      this.$refs.grid.submitFilter(filter, search);
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.filterPanel.submitFilter();
      this.selectedMultipleClear();
    },
    selectedHandler(val) {
      this.selectedMultipleGridHandler(val)
    },
    showModalForm(name, task) {
      this.$refs[name].show(task);
    },
    getStatus(status) {
      if (!status) return;
      let type = "default";
      switch (status.toLowerCase()) {
        case "completed":
          type = "success";
          break;
        case "process":
          type = "warning";
          break;
        case "error":
          type = "danger";
          break;
        default:
          type = "default";
      }
      return {
        text: status[0].toUpperCase() + status.toLowerCase().slice(1),
        type,
      };
    },
    showResult(task) {
      if (!task) return;
      this.showModalForm("result", task);
    },
    showLog(task) {
      this.showModalForm("log", task);
    },
  },
};
</script>
<style lang="scss"></style>
