import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/Competence/CompetenceTaskApi";

export class CompetenceTaskService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(this.Api, "editCompetenceTask", fields);
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editCompetenceTask");
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getCompetenceTasks", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getCompetenceTask", null, id);
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createCompetenceTask", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteCompetenceTask", model);
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "editCompetenceTask", model);
  }
}
