import users from "../../../../Services/SecurityAdmin/ProSpace.SecurityAdmin.Frontend/views/Users/Users.vue";
import user from "../../../../Services/SecurityAdmin/ProSpace.SecurityAdmin.Frontend/views/Users/User.vue";

import roles from "../../../../Services/SecurityAdmin/ProSpace.SecurityAdmin.Frontend/views/Roles/Roles.vue";
import role from "../../../../Services/SecurityAdmin/ProSpace.SecurityAdmin.Frontend/views/Roles/Role.vue";

import usecases from "../../../../Services/SecurityAdmin/ProSpace.SecurityAdmin.Frontend/views/UseCases/UseCases";
import usecase from "../../../../Services/SecurityAdmin/ProSpace.SecurityAdmin.Frontend/views/UseCases/UseCase";

import menuitems from "../../../../Services/SecurityAdmin/ProSpace.SecurityAdmin.Frontend/views/MenuItems/MenuItems";
import menuitem from "../../../../Services/SecurityAdmin/ProSpace.SecurityAdmin.Frontend/views/MenuItems/MenuItem";

export const adminRoutes = [
  {
    path: "/securityadmin/users",
    name: "users",
    component: users,
  },
  {
    path: "/securityadmin/user/:id",
    name: "user",
    component: user,
    meta: {
      menuitem: "/securityadmin/users",
    },
  },
  {
    path: "/securityadmin/roles",
    name: "roles",
    component: roles,
  },
  {
    path: "/securityadmin/role/:id",
    name: "role",
    component: role,
    meta: {
      menuitem: "/securityadmin/roles",
    },
  },
  {
    path: "/securityadmin/usecases",
    name: "usecases",
    component: usecases,
  },
  {
    path: "/securityadmin/usecase/:id",
    name: "usecase",
    component: usecase,
    meta: {
      menuitem: "/securityadmin/usecases",
    },
  },
  {
    path: "/securityadmin/menuitems",
    name: "menuitems",
    component: menuitems,
  },
  {
    path: "/securityadmin/menuitem/:id",
    name: "menuitem",
    component: menuitem,
    meta: {
      menuitem: "/securityadmin/menuitems",
    },
  },
];

export default adminRoutes;
