<template>
  <ProSpaceGeoLayout>
    <template #top>
      <ProSpaceFilterPanel
        :closeTxt="$t('closeTxt')"
        :searchPlaceholder="$t('searchTxt')"
        :txtResetFilters="$t('txtResetFilters')"
        @apply="submitFilter"
        ref="filter-panel"
        filter-model="ActualModel/Name"
      >
        <template #hint> {{ $t("filterBy") }}</template>
        <template #filters="{ filter }">
          <ProSpaceCustomFilterPanel
            :customTxt="$t('custom')"
            :customFilterTxt="$t('customFilterTxt')"
            :clearFilterTxt="$t('clearFilterTxt')"
            :selectAllTxt="$t('selectAllTxt')"
            :closeTxt="$t('closeTxt')"
            :applyTxt="$t('applyTxt')"
            :fromTxt="$t('fromTxt')"
            :toTxt="$t('toTxt')"
            :services="allCustomFilters"
            @apply="(model) => customFilterApply(filter, model)"
          />
        </template>
      </ProSpaceFilterPanel>
    </template>
    <template #center>
      <ProSpaceMainGrid
        ref="grid"
        :service="historyService"
        @selected-change="selectedHandler"
        :noMountedRequest="true"
        getMethod="grid"
        selectionMode="single"
        defaultSortField="editDate"
        defaultSortOrder="-1"
        :defaultFilter="defaultFilter"
      >
        <template #columns>
          <Column
            field="ActualModel/Name"
            :header="this.$t('name')"
            sortable
            headerClass="move-sort-head"
          >
            <template #body="field">
              <ProSpaceTableHeaderCell
                isHtml
                :title="field.data.actualModel.FileName || `<span style='color: transparent;'>-</span>`"
              />
            </template>
          </Column>
          <Column field="operationType" :header="$t('operation')" sortable>
            <template #body="field">
              <ProSpaceDefaultTextColumn>
                {{ field.data.operationType }}
              </ProSpaceDefaultTextColumn>
            </template>
          </Column>
          <Column field="userFullName" :header="$t('user')" sortable>
            <template #body="field">
              <ProSpaceDefaultTextColumn>
                {{ field.data.userFullName }}
              </ProSpaceDefaultTextColumn>
            </template>
          </Column>
          <Column field="editDate" :header="$t('changeDateTime')" sortable>
            <template #body="field">
              <ProSpaceDateTimeColumn
                :date="field.data.editDate"
                :dateFormat="userinfo.dateFormat"
                :timeFormat="userinfo.timeFormat"
              />
            </template>
          </Column>
        </template>
      </ProSpaceMainGrid>
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import {
  ProSpaceMainGrid,
  ProSpaceGeoLayout,
  ProSpaceTableHeaderCell,
  ProSpaceFilterPanel,
  ProSpaceMultiSelect,
  ProSpaceCustomFilterPanel,
  ProSpaceDateTimeColumn,
  ProSpaceDefaultTextColumn,
} from "prospace-components-library";
import Column from "primevue/column";
import { CompetenceTaskAttachmentService } from "../../../services/CompetenceTaskAttachmentService";
const _ = require("lodash");
import { mapGetters } from "vuex";
export default {
  name: "CompetenceTaskAttachmentApiModelHistory",
  components: {
    ProSpaceMainGrid,
    Column,
    ProSpaceGeoLayout,
    ProSpaceTableHeaderCell,
    ProSpaceFilterPanel,
    ProSpaceMultiSelect,
    ProSpaceCustomFilterPanel,
    ProSpaceDateTimeColumn,
    ProSpaceDefaultTextColumn,
  },
  props: {
    historyService: {
      type: Object,
      required: true,
    },
    defaultFilter: {
      type: Object,
      required: true,
    },
    historyCustomServices: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modelFastFilter: {
        label: this.$t("allTxt"),
        value: false,
        field: "disabled",
      },
      competenceTaskAttachmentService: new CompetenceTaskAttachmentService(),
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
    }),
    customServices() {
      return [];
    },
    allCustomFilters() {
      return this.historyCustomServices.concat(this.customServices);
    },
    mapExpandStatuses() {
      return new Map([
        ["New", { type: "blue", text: this.$t("competencetaskstatusnew") }],
        ["Approving", { type: "teal", text: this.$t("competencetaskstatusapproving") }],
        [
          "RejectedByDistributor",
          { type: "error", text: this.$t("competencetaskstatusrejectedbydistributor") },
        ],
        ["Planning", { type: "purple", text: this.$t("competencetaskstatusplanning") }],
        [
          "InProgress",
          { type: "warning", text: this.$t("competencetaskstatusinprogress") },
        ],
        [
          "CompleteApproving",
          { type: "teal", text: this.$t("competencetaskstatuscompleteapproving") },
        ],
        [
          "RejectedByMars",
          { type: "error", text: this.$t("competencetaskstatusrejectedbymars") },
        ],
        [
          "Completed",
          { type: "success", text: this.$t("competencetaskstatuscompleted") },
        ],
        ["Canceled", { type: "draft", text: this.$t("competencetaskstatuscanceled") }],
      ]);
    },
  },
  methods: {
    getActualName(record) {
      return record.FileName;
    },
    getActualId(record) {
      return null;
    },
    getActualStatus(record) {
      return null;
    },
    openFromReference(record, fieldName) {
      this.$emit("openFromReference", record, fieldName);
    },
    emitFilter() {
      this.$refs["filter-panel"].submitFilter();
    },
    selectedHandler(val) {
      this.$emit("selectedHandler", val);
    },
    submitFilter({ filter, search }) {
      this.$refs.grid.submitFilter(filter, search);
    },
    getStatus(status) {
      return this.mapExpandStatuses.get(status) || { type: "draft", text: status };
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model, this.defaultFilter);
      this.$refs["filter-panel"].submitFilter();
    },
  },
};
</script>
<style lang="scss"></style>
