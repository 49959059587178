import authorizedcontactpersons from "../../../../Services/MasterData/MasterData.Frontend/views/AuthorizedContactPersons/AuthorizedContactPersons";
import empoweredcontactpersons from "../../../../Services/MasterData/MasterData.Frontend/views/EmpoweredContactPersons/EmpoweredContactPersons";
import anothercontactpersons from "../../../../Services/MasterData/MasterData.Frontend/views/AnotherContactPersons/AnotherContactPersons";
import marscontactpersons from "../../../../Services/MasterData/MasterData.Frontend/views/MarsContactPersons/MarsContactPersons";
import distributors from "../../../../Services/MasterData/MasterData.Frontend/views/Distributors/Distributors";
// import distributororgs from "../../../../Services/MasterData/MasterData.Frontend/views/DistributorOrgs/DistributorOrgs"; // Добавлено вручную
// import marsorgs from "../../../../Services/MasterData/MasterData.Frontend/views/MarsOrgs/MarsOrgs"; // Добавлено вручную
// import mailinggroups from "../../../../Services/MasterData/MasterData.Frontend/views/MailingGroups/MailingGroups"; // Добавлено вручную
import distributor from "../../../../Services/MasterData/MasterData.Frontend/views/Distributors/Distributor";
// import distributororg from "../../../../Services/MasterData/MasterData.Frontend/views/DistributorOrgs/DistributorOrg"; // Добавлено вручную
// import marsorg from "../../../../Services/MasterData/MasterData.Frontend/views/MarsOrgs/MarsOrg"; // Добавлено вручную
// import mailinggroup from "../../../../Services/MasterData/MasterData.Frontend/views/MailingGroups/MailingGroup"; // Добавлено вручную
import marsdistributororgusersviews from "../../../../Services/MasterData/MasterData.Frontend/views/MarsDistributorOrgUsersViews/MarsDistributorOrgUsersViews";
﻿import filialstructureusersviews from "../../../../Services/MasterData/MasterData.Frontend/views/FilialStructureUsersViews/FilialStructureUsersViews";
// import filialstructureusersview from "../../../../Services/MasterData/MasterData.Frontend/views/FilialStructureUsersViews/FilialStructureUsersView";


export const masterdataRoutes = [
  // Добавлено вручную (табуляция)
  {
    path: "/masterdata/authorizedcontacts",
    name: "authorizedcontacts",
    component: authorizedcontactpersons,
  },
  {
    path: "/masterdata/empoweredcontacts",
    name: "empoweredcontacts",
    component: empoweredcontactpersons,
  },
  {
    path: "/masterdata/anothercontacts",
    name: "anothercontacts",
    component: anothercontactpersons,
  },
  {
    path: "/masterdata/marscontacts",
    name: "marscontacts",
    component: marscontactpersons,
  },
  {
    path: "/masterdata/distributors",
    name: "distributors",
    component: distributors,
  },
  // { // Добавлено вручную
  //   path: "/masterdata/distributororgs",
  //   name: "distributororgs",
  //   component: distributororgs,
  // },
  // { path: "/masterdata/marsorgs", name: "marsorgs", component: marsorgs }, // Добавлено вручную
  // { // Добавлено вручную
  //   path: "/masterdata/mailinggroups",
  //   name: "mailinggroups",
  //   component: mailinggroups,
  // },
  {
    path: "/masterdata/distributor/:id",
    name: "distributor",
    component: distributor,
    meta: {
      menuitem: "/masterdata/distributors",
    },
  },
  // { // Добавлено вручную
  //   path: "/masterdata/distributororg/:id",
  //   name: "distributororg",
  //   component: distributororg,
  // },
  // { path: "/masterdata/marsorg/:id", name: "marsorg", component: marsorg },
  // {
  //   path: "/masterdata/mailinggroup/:id",
  //   name: "mailinggroup",
  //   component: mailinggroup,
  // },
  {
    path: "/masterdata/marsdistributororgusersviews",
    name: "marsdistributororgusersviews",
    component: marsdistributororgusersviews,
  },
  {
    path: "/masterdata/filialstructureusersviews",
    name: "filialstructureusersviews",
    component: filialstructureusersviews
  }
];

export default masterdataRoutes;
