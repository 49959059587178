import { ref, provide, onMounted, nextTick, watch } from 'vue';
import { useI18n } from "vue-i18n";
import _ from "lodash";

const useSelectMultipleGrid = (refGrid, refFilterPanel = null) => {
    const { locale, t } = useI18n({ useScope: 'global' })
    const selectedData = ref(null)
    const hasSelect = ref(null)
    const selectedClude = ref(null)
    const selectedCount = ref(null)
    const needBlock = ref(null)
    
    let prevHasSelect = false

    watch(locale, () => {
        if (typeof selectedCount.value === "string") {
            selectedCount.value = t('allTxtT')
        }
    })

    watch(() => refGrid.value?.records, (value) => {
        if (value && value.length === 0) {
            hasSelect.value = false
        } else {
            hasSelect.value = prevHasSelect
        }
    })

    const setCount = (clude) => {
        if (clude?.items.length && !clude?.isAll) {
            selectedCount.value = clude.items.length
        } else if (clude?.isAll) {
            selectedCount.value = t('allTxtT')
        } else {
            selectedCount.value = hasSelect.value ? 1 : 0
        }
    }

    const selectedMultipleGridHandler = ({data, clude, hasSelect: newHasSelect, type}) => {
        const prevData = _.cloneDeep(selectedData.value)
        const prevClude = _.cloneDeep(selectedClude.value)

        if (_.isEqual(selectedData.value, data) && !clude) {
            hasSelect.value = false
            selectedData.value = null
        } else {
            hasSelect.value = newHasSelect
            selectedData.value = data || null
        }

        prevHasSelect = hasSelect.value

        if (!clude) {
            setCount(clude)
            return
        }

        if (type === "head" && prevClude?.items.length > 0 && clude.isAll) {
            selectedData.value = prevData
            selectedMultipleBlockGrid({prev: prevClude, next: clude})
            return
        }
        setCount(clude)
        selectedClude.value = clude
        needBlock.value = clude.isAll && clude.items.length > 0
    }
    
    const setSelected = ({items=[], data=null, isAll=false}) => {
        selectedData.value = data || (items.length === 1 && items[0]) || null
        hasSelect.value = items.length > 0 || !!data
        selectedClude.value = {items, isAll}

        prevHasSelect = hasSelect.value

        setCount(selectedClude.value)
    }

    const selectedMultipleClear = () => {
        if (!refGrid.value) {
            return
        }
        if (selectedClude.value === null || !selectedClude.value.isAll) {
            selectedMultipleReset()
        } else {
            selectedData.value = null;
            hasSelect.value = true;
            needBlock.value = null;
            selectedClude.value = {isAll: true, items: []};
            refGrid.value.selectedModel = [];
            refGrid.value.isAllRowsSelected = true;
            refGrid.value.clickedItem = null;

            prevHasSelect = null;
        }
    }

    const selectedMultipleReset = () => {
        selectedData.value = null;
        hasSelect.value = null;
        needBlock.value = null;
        selectedClude.value = null;
        refGrid.value.selectedModel = [];
        refGrid.value.isAllRowsSelected = false;
        refGrid.value.clickedItem = null;
        prevHasSelect = null;
    }

    const modalBlockFiltersModel = ref(false)
    const modalBlockFilterApply = ref(() => {})
    const modalBlockFilterCancel = ref(() => {})

    const selectedMultipleBlockFilterHandler = ({ tab }) => {
        if (!refFilterPanel) {
            return;
        }
        modalBlockFiltersModel.value = true
        modalBlockFilterApply.value = async () => {
            selectedMultipleReset()
            if (tab) {
                refFilterPanel.value.fastFilter = tab
            }
            await nextTick() 
            if (tab) {
                refFilterPanel.value.submitFilter(true)
            } else {
                refFilterPanel.value.submitFilter()
            }
            modalBlockFiltersModel.value = false
        }
        modalBlockFilterCancel.value = () => {
            modalBlockFiltersModel.value = false
            refFilterPanel.value.filter = _.cloneDeep(refFilterPanel.value.prevFilter)
        }
    }

    const selectedMultipleBlockGrid = ({prev, next}) => {
        modalBlockFiltersModel.value = true
        refGrid.value.isAllRowsSelected = false
        refGrid.value.selectedModel = prev.items
        setCount(prev)
        modalBlockFilterApply.value = async () => {
            selectedMultipleReset()
            refGrid.value.isAllRowsSelected = true
            refGrid.value.selectedModel = []
            selectedClude.value = next
            hasSelect.value = true
            prevHasSelect = true
            setCount(next)
            modalBlockFiltersModel.value = false
        }
        modalBlockFilterCancel.value = () => {
            modalBlockFiltersModel.value = false
        }
    }

    const selectedClearSelectedClude = () => {
        if (selectedClude.value.isAll) {
            refGrid.value.selectedModel = []
            refGrid.value.isAllRowsSelected = true
            selectedClude.value.items = []
        } else {
            refGrid.value.selectedModel = []
            selectedClude.value.items = []
            selectedCount.value = 0
            hasSelect.value = false
            prevHasSelect = false
        }
    }

    const getRequestModel = (item) => {
        let records = []
        let filter = ""

        if (selectedClude.value?.isAll) {
            records = selectedClude.value.items,
            filter = refGrid.value.getRecords(false, true).split("&$")[0]
        } else if (selectedClude.value?.items.length > 0) {
            records = selectedClude.value.items
        } else {
            records = [selectedData.value || item]
        }
        
        return {records, filter}
    }
    provide('MultiSelectRightPanelSelectedClude', selectedClude)
    provide('MultiSelectRightPanelSelectedData', selectedData)
    provide('MultiSelectRightPanelSelectedCount', selectedCount)
    provide('MultiSelectRightPanelClear', selectedClearSelectedClude)
    provide('MultiSelectRightPanelReset', selectedMultipleReset)

    provide('MultiSelectModalBlockFiltersModel', modalBlockFiltersModel)
    provide('MultiSelectModalBlockFiltersApply', modalBlockFilterApply)
    provide('MultiSelectModalBlockFiltersCancel', modalBlockFilterCancel)

    onMounted(() => {
        if (!refGrid) {
            throw new Error("Need grid")
        }
    })

    return {
        selectedMultipleGridHandler,
        selectedMultipleBlockFilterHandler,
        selectedData,
        selectedCount,
        hasSelect,
        selectedMultipleClear,
        setSelected,
        needBlock,
        getRequestModel,
        selectedMultipleReset
    }
}

export default useSelectMultipleGrid