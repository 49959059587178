<template>
  <ProSpaceGeoLayout class="competencedirectory" v-if="model">
    <template #top>
      <ProSpaceTopPanel :header="layoutName" back @back="backHandler">
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout
        :is-templated="true"
        :template-center="true"
        :template-right="true"
      >
        <template #center>
          <ProSpaceTabs
            v-model="modelTab"
            :tabs="overviewTabs"
            :loading="loading">
            <ProSpaceHLayout :gap="5">
              <ProSpaceAction
                :disabled="v$.model.$invalid"
                icon="check"
                :text="$t('save')"
                @click="savePart(saveFields.Overview)"
              />
            </ProSpaceHLayout>
            <template v-slot:tab-1>
              <ProSpaceVLayout :gap="10">
                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('commonInformation')"
                      :bottom="$t('commonInformationAdditional')"
                      :gap="10"
                      type="third"
                      break-word
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceVLayout :gap="20">
                      <ProSpaceHLayout align-items="flex-start" :gap="10">
                        <ProSpaceInputText
                          v-model="model.name"
                          :label="$t('competenceName')"
                          :placeholder="$t('competenceName')"
                          :invalid="v$.model.name.$error"
                          :invalid-messages="v$.model.name.$errors"
                          @blur="v$.model.name.$touch"
                        />
                        <ProSpaceDropdown
                          v-model="model.type"
                          :label="$t('competenceDirectoryType')"
                          :placeholder="$t('competenceDirectoryType')"
                          :invalid="v$.model.type.$error"
                          :invalid-messages="v$.model.type.$errors"
                          @blur="v$.model.type.$touch"
                          :service="baseTypeService"
                          getMethod="grid"
                          propValue="code"
                          propLabel="name"
                          argMethod="$filter=systemDirectoryCode eq 'CompetenceType' and disabled eq false"
                          @change="
                            (item) =>
                              (model.type =
                                item && item.code ? item.code : null)
                          "
                        />
                      </ProSpaceHLayout>
                      <ProSpaceHLayout align-items="flex-start" :gap="10">
                        <ProSpaceDropdown
                          v-model="model.level"
                          :label="$t('competenceDirectoryLevel')"
                          :placeholder="$t('competenceDirectoryLevel')"
                          :invalid="v$.model.level.$error"
                          :invalid-messages="v$.model.level.$errors"
                          @blur="v$.model.level.$touch"
                          :service="baseTypeService"
                          getMethod="grid"
                          propValue="code"
                          propLabel="name"
                          argMethod="$filter=systemDirectoryCode eq 'LevelType' and disabled eq false"
                          @change="
                            (item) =>
                              (model.level =
                                item && item.code ? item.code : null)
                          "
                        />
                        <ProSpaceDropdown
                          v-model="model.block"
                          :label="$t('competenceDirectoryBlock')"
                          :placeholder="$t('competenceDirectoryBlock')"
                          :invalid="v$.model.block.$error"
                          :invalid-messages="v$.model.block.$errors"
                          @blur="v$.model.block.$touch"
                          :service="baseTypeService"
                          getMethod="grid"
                          propValue="code"
                          propLabel="name"
                          argMethod="$filter=systemDirectoryCode eq 'BlockType' and disabled eq false"
                          @change="
                            (item) =>
                              (model.block =
                                item && item.code ? item.code : null)
                          "
                        />
                      </ProSpaceHLayout>
                    </ProSpaceVLayout>
                  </template>
                  <template
                    v-if="
                      v$.model.name.$error ||
                      v$.model.type.$error ||
                      v$.model.level.$error ||
                      v$.model.block.$error
                    "
                    v-slot:right
                  >
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>
                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('additionalInfo')"
                      :bottom="$t('descriptionAndAdvancementRecommendations')"
                      :gap="10"
                      type="third"
                      break-word
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceVLayout :gap="20">
                    <ProSpaceHLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="10"
                    >
                      <ProSpaceExtendedTextArea
                        v-model="model.description"
                        :label="$t('competenceDirectoryDescription')"
                        :placeholder="$t('competenceDirectoryDescription')"
                        :invalid="v$.model.description.$error"
                        :invalid-messages="v$.model.description.$errors"
                        :t="$t"
                        :locale="$i18n.locale"
                        @blur="v$.model.description.$touch"
                      />
                    </ProSpaceHLayout>
                    <ProSpaceHLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="10"
                    >
                      <ProSpaceExtendedTextArea
                        v-model="model.advancementRecommendation"
                        :label="$t('competenceDirectoryAdvancementRecommendations')"
                        :placeholder="$t('competenceDirectoryAdvancementRecommendations')"
                        :invalid="v$.model.advancementRecommendation.$error"
                        :invalid-messages="v$.model.advancementRecommendation.$errors"
                        :t="$t"
                        :locale="$i18n.locale"
                        @blur="v$.model.advancementRecommendation.$touch"
                      />
                    </ProSpaceHLayout>
                  </ProSpaceVLayout>
                  </template>
                  <template
                    v-if="
                      v$.model.description.$error ||
                      v$.model.advancementRecommendation.$error
                      "
                    v-slot:right
                  >
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>
              </ProSpaceVLayout>
            </template>
          </ProSpaceTabs>
        </template>
      </ProSpaceGeoLayout>
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import {
  ProSpaceTopPanel,
  ProSpaceInputText,
  ProSpaceAction,
  ProSpaceRadioButtonSecondary,
  ProSpaceGeoLayout,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpaceTextBlock,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceDropdown,
  ProSpaceFile,
  ProSpaceInputCalendar,
  ProSpaceInputNumber,
  ProSpaceSearchfield,
  ProSpaceMultiSelect,
  ProSpaceCustomFilterPanel,
  ProSpaceIcon,
  ProSpaceExtendedTextArea,
  ProSpaceWarning,
} from "prospace-components-library";
import { CompetenceDirectoryService } from "../../services/CompetenceDirectoryService.js";

import {
  required,
  maxLength,
  helpers,
  minValue,
  maxValue,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { BaseTypeService } from "../../../../BaseType/BaseTypeService.Frontend/services/BaseTypeService.js";
export default {
  components: {
    ProSpaceTopPanel,
    ProSpaceGeoLayout,
    ProSpaceInputText,
    ProSpaceAction,
    ProSpaceRadioButtonSecondary,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpaceTextBlock,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceDropdown,
    ProSpaceFile,
    ProSpaceInputCalendar,
    ProSpaceInputNumber,
    ProSpaceSearchfield,
    ProSpaceMultiSelect,
    ProSpaceCustomFilterPanel,
    ProSpaceIcon,
    ProSpaceExtendedTextArea,
    ProSpaceWarning,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      // Tabs
      modelTab: {
        name: this.$t("parameters"),
        isActive: true,
        systemName: "parameters",
      },
      // Common
      layoutName: "",
      model: null,
      modelCopy: null,
      loading: false,

      // Service
      competenceDirectoryService: new CompetenceDirectoryService(),
      baseTypeService: new BaseTypeService(),

      getMethod: "get",
      masterId: null,

      // SaveFields
      saveFields: {
        Overview: [
          "name",
          "description",
          "advancementRecommendation",
          "type",
          "level",
          "block",
        ],
      },
    };
  },
  validations() {
    return {
      model: {
        name: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage"),
            maxLength(100)
          ),
        },
        description: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          )
        },
        advancementRecommendation: {
        },
        type: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage"),
            maxLength(50)
          ),
        },
        level: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage"),
            maxLength(50)
          ),
        },
        block: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage"),
            maxLength(50)
          ),
        },
      },
    };
  },
  mounted() {
    this.masterId = this.$route.params.id;
    this.getData();
  },
  methods: {
    gridLoaded() {},
    async getData() {
      if (this.masterId === undefined) {
        this.model = this.getEmptyModel();
        this.layoutName = this.$t('newCompetenceDirectory');
        return;
      }
      const result = await this.competenceDirectoryService[this.getMethod](
        this.masterId
      );
      this.model = result.data;
      this.modelCopy = _.cloneDeep(result.data);
      this.layoutName = `${this.model.name}`;
    },
    savePart(fields) {
      this.loading = true;
      const isCreate = !this.masterId;
      const saveMethod = isCreate ? "create" : "update";
      this.competenceDirectoryService[saveMethod](this.model).then((result) => {
        this.model.id = result.data[0].id;
        this.masterId = result.data[0].id;
        this.getData();
        this.loading = false;
      });
    },
    getEmptyModel() {
      return ({
          name: null,
          type: null,
          level: null,
          block: 
            this.$route.query &&
            this.$route.query.block &&
            this.$route.query.block != "all"
              ? this.$route.query.block
              : null,
          description: "",
          advancementRecommendation: ""
    })},
    submitFilter(nameRef = "grid") {
      if (!this.$refs[nameRef]) return;
      this.$refs[nameRef].getRecords(true);
    },
    backHandler() {
      this.$router.go(-1);
    },
  },
  computed: {
    ProSpacePanelBlockListComputed() {
      return [
        [
          {
            title: this.$t("createdByTxt"),
            text: this.model.createdBy,
          },
        ],
        [
          {
            title: this.$t("createdAtTxt"),
            text: new Date(this.model.createdAt),
          },
          {
            title: this.$t("lastUpdatedTxt"),
            text: new Date(this.model.lastUpdated),
          },
        ],
      ];
    },
    overviewTabs() {
      return [
        {
          name: this.$t("parameters"),
          systemName: "parameters",
          isActive: true
        }];
    },
    ProSpacePanelBlockHeader() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
  },
};
</script>
<style lang="scss"></style>
