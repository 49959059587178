import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/UserRoleApi";

export class UserRoleService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
    this.item = null
  }
  setItem(item) {
    this.item = item
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(
      this.Api,
      "editUserRole",
      fields
    );
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editUserRole"); 
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getUserRoles", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getUserRole", null, id);
  }
  async create(model) {
    if (!Array.isArray(model)) 
      model = [model];
    
    return await this.transformer.createItem(this.Api, "createUserRole", model);
  }
  async createWithMap(models) {
    const createModels = models.map(m => {
      return {
        securityRoleDisplayName: m.displayName,
        securityRoleId: m.id,
        securityRoleSystemName: m.systemName,
        securityUserEmail: this.item.email,
        securityUserFirstName: this.item.firstName,
        securityUserId: this.item.id,
        securityUserLastName: this.item.lastName,
        securityUserLogin: this.item.login,
        securityUserStatus: this.item.status,
        securityUserSecurityLoginAuthenticateProvider: this.item.securityLoginAuthenticateProvider,
      }
    })
    return await this.transformer.createItem(this.Api, "createUserRole", createModels)
  }
  async delete(model) {
    if (!Array.isArray(model)) 
      model = [model];
      
    return await this.transformer.deleteItem(this.Api, "deleteUserRole", model);
  }
  async edit(model) {
    if (!Array.isArray(model)) 
      model = [model];

    return await this.transformer.editItem(this.Api, "editUserRole", model);
  }
  async localGet(id) {
    return await this.transformer.localGetById(id);
  }
}
