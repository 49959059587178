import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/RoleApi";
import store from "../../../../Frontends/Common/store";
export class RoleService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(
      this.Api,
      "updateRole",
      fields
    );
  }
  async set() {
    return await this.transformer.setForm(this.Api, "updateRole"); 
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getRoles", args);
  }
  async filt() {
    return await this.transformer.getMultiSelect(this.Api, "getRoles");
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createRole", model);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getRole", null, id);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteRole", model);
  }
  async edit(model) {
    return await this.transformer.editItem(this.Api, "updateRole", model);
  }
  async localGet(id) {
    return await this.transformer.localGetById(id);
  }
  async import(gfid) {
    const res = this.Api.import(gfid);
    await store.dispatch('notifications/handleResponse', {
      promiseResponse: res,
    })
    return res;
  }
  async getImportScaffold() {
    return await this.Api.getImportScaffold();
  }
  async export(filter, format) {
    const res = this.Api.export(filter, format);
    await store.dispatch('notifications/handleResponse', {
      promiseResponse: res,
    })
    return res;
  }
}
