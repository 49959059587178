import { reject } from "lodash";
import { createDeepProxy, getArgs, onlyKeys, findAsync } from "./functions.js";
const _ = require("lodash");
export class Transformer {
  constructor() {
    this.model = {};
    this.apiModel = {};
    this.gridData = [];
  }

  getProxyModel(data, fields = null) {
    this.model = data;
    this.apiModel = _.cloneDeep(data);
    const me = this;
    return createDeepProxy(onlyKeys(this.model, fields), {
      set(target, path, value, receiver) {
        _.set(me.model, path, value);
        // console.log(`${path.join(".")} = ${value}`);
        return true;
      },
    });
  }
  async getGridFromRecord(service, method, field, args = null) {
    args = getArgs(args);
    const { data } = (await service[method](args)).data;
    this.model = data;
    return { data: { records: data[field], total: data[field].length } };
  }

  async getGrid(service, method, args = null, id = null) {
    args = getArgs(args);
    //const { data } = await service[method](args, id);
    const res = (await service[method](args, id)).data;
    if (!res) {
      return null
    }
    const data = res.data;
    if (Array.isArray(data)) {
      this.gridData = data;
      return { data: { records: data, total: data.length } };
    } else {
      this.gridData = data.hasOwnProperty('records') ? data.records : data.data;
      return { data };
    }
  }
  
  async getTree(service, method, model) {
    return (await service[method](model)).data;
  }

  async toTree(service, method, model, treeIdProp = "treeId", needRawData = false) {
    const findNode = (treeId) => {
      return res.data.find(i => i[treeIdProp] === treeId);
    }
    const createTree = (item) => {
      if (item.parentId) {
        const node = findNode(item.parentId);
        if (!node) {
          return
        }
        if (!node.children) {
          node.children = []
        }
        node.children.push(item);
      } else {
        tree.push(item);
      }
    }

    let rawRes = null;
    const res = (await service[method](model)).data;
    if (needRawData)
      rawRes = res.data;
    const tree = []
    res.data.forEach(createTree);
    res.data = tree;
    return needRawData ? { data: res, rawData: rawRes } : res;
  }

  async getNodeItems(service, method, model) {
    return (await service[method](model)).data;
  }

  async getForm(service, method, fields, args = null) {
    args = getArgs(args);
    const { data } = (await service[method](args)).data;
    return this.getProxyModel(data, fields);
  }

  async setForm(service, method) {
    const { data } = (await service[method](this.model)).data;
    return data;
  }

  async setFormPart(service, method, fields) {
    for (const field of fields) {
      _.set(this.apiModel, field, this.model[field]);
    }
    const { data } = (await service[method](this.apiModel)).data;
    return data;
  }

  async getMultiSelect(service, method) {
    const { data } = (await service[method](this.model)).data;
    return { data };
  }

  async getGridPanel(service, method, fields, args = null) {
    args = getArgs(args);
    const { data } = (await service[method](args)).data;
    return { data: this.getProxyModel(data[0], fields) };
  }
  async localGetById(id) {
    const data = await findAsync(this.gridData, id);
    return { data };
  }
  async getItem(service, method, model) {
    return (await service[method](model)).data;
  }
  async editItem(service, method, model) {
    return (await service[method](model)).data;
  }
  async createItem(service, method, model) {
    return (await service[method](model)).data;
  }
  async deleteItem(service, method, model) {
    const res = await service[method](model)
    if (!res) {
      return Promise.resolve()
    }
    return res.data;
  }
  async userRoleUpdate(service, method, model, mappingModel) {
    let resultModel = mappingModel.map((elem) => {
      const newItem = {
        id: 0,
        securityUserStatus: model.status,
        securityUserFirstName: model.firstName,
        securityUserLastName: model.lastName,
        securityUserEmail: model.email,
        securityUserSecurityLoginAuthenticateProvider: model.securityLoginAuthenticateProvider,
        securityUserId: model.id,
        securityRoleId: elem.id,
        securityRoleDisplayName: elem.displayName,
        securityRoleSystemName: elem.systemName,
        securityRoleStatus: elem.status,
      };
      return newItem;
    });
    return (await service[method](resultModel)).data;
  }
  async useCaseClaimUpdate(service, method, model, mappingModel) {
    let resultModel = mappingModel.map((elem) => {
      console.log(elem);
      console.log(model);

      const newItem = {
        id: 0,
        securityUseCaseId: 0,
        securityClaimId: 0,
        securityUseCaseTitle: "string",
        securityUseCaseDescription: "string",
        securityClaimServiceName: "string",
        securityClaimValue: "string",
        startDate: "2022-07-08T13:26:10.403Z",
        endDate: "2022-07-08T13:26:10.403Z",
        disabled: true,
        disableDate: "2022-07-08T13:26:10.403Z",
      };
      return newItem;
    });
    return (await service[method](resultModel)).data;
  }
  
  setNewGridData(newGridData) {
    this.gridData = newGridData
  }
  getGridData() {
    return this.gridData
  }
  addToGridData(item) {
    this.gridData.push(item)
  }
  updateToGridData(item, propId='id') {
    this.gridData = this.gridData.map(i => {
      if (i[propId] === item[propId]) {
        i = item
      }
      return i
    })
  }
  deleteToGridData(value, propId='id') {
    this.gridData = this.gridData.filter(i => i[propId] !== value)
  }
  findToGridData(fnFind) {
    return this.gridData.find(fnFind)
  }
}

