<template>
  <div class="patched-checkbox field-checkbox">
    <Checkbox name="checkboxActive" :binary="true" v-model="model" />
    <label for="checkboxActive"> {{ label }} </label>
  </div>
</template>
<script>
import Checkbox from "primevue/checkbox";

export default {
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  components: {
    Checkbox
  },
  data() {
    return {
      model: this.modelValue,
    };
  },
  watch: {
    modelValue: {
      handler(val) {
        this.model = val;
      },
      immediate: true,
    },
  },
  methods: {
    changeHandler() {
      this.$emit("update:modelValue", this.model);
    },
  },
};
</script>
