import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "../../../Frontends/ProSpace/scss/layout.scss";

import { createApp } from "vue";
import App from "./App.vue";
const app = createApp(App);

import router from "../../../Frontends/ProSpace/router/router";
app.use(router);

import SvgIcon from "../../../Frontends/Common/components/SvgIcon";
app.component("svg-icon", SvgIcon);

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
app.component("Fa", FontAwesomeIcon);

import PrimeVue from "primevue/config";
app.use(PrimeVue);

import i18n from "../../../Frontends/Common/utils/i18n.js";
app.use(i18n);

import store from "../../../Frontends/Common/store/index";
app.use(store);

app.mount("#app");
