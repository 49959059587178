import i18n from "../../../Frontends/Common/utils/i18n.js";

export class OperationService {
  constructor() {
    this.dataOperations = [
      { text: i18n.global.t("createdOperation"), operationType: "Created" },
      { text: i18n.global.t("updatedOperation"), operationType: "Updated" },
      { text: i18n.global.t("deletedOperation"), operationType: "Deleted" },
    ];
  }
  search(value) {
    return this.dataOperations.filter(
      (elem) => elem.text.indexOf(value) !== -1
    );
  }
  async get() {
    return Promise.resolve({ data: { records: this.dataOperations } });
  }
}
