<template>
  <ProSpaceRightPanel
    :header="header"
    :loading="loading"
    :has-error="hasError"
    @clear="clearPanel"
    @skip="skip"
    @restart="restart"
    @downloadLog="downloadLog"
    @downloadFile="downloadFile"
  >
    <template v-slot:center>
      <ProSpaceTabs :tabs="tabs" @select-tab="handlerSelectTab">
        <template v-slot:tab-1>
          <ProSpacePanelBlock :header="headerOverview">
            <template v-slot:header> </template>
            <template v-slot>
              <ProSpacePanelBlockList
                :list="content"
                :itemsPerPage="itemsPerPage"
                :dateFormat="userinfo.dateFormat"
                :timeFormat="userinfo.timeFormat"
              />
            </template>
          </ProSpacePanelBlock>
        </template>
      </ProSpaceTabs>
    </template>
    <template v-slot:bottom>
      <ProSpacePanelBlock :header="headerBottom">
        <template v-slot>
          <ProSpacePanelBlockList :list="contentBottom" />
        </template>
      </ProSpacePanelBlock>
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
} from "prospace-components-library";
import { IntegrationLogService } from "../../../services/IntegrationLogService";
import { DirectionService } from "../../../services/DirectionService.js";
import { StatusService } from "../../../services/StatusService.js";
import { mapGetters } from "vuex";
import { useStatistic } from "@composes"
import { getAMorPM } from "prospace-components-library/src/lib-assets/mixins/date";
import { useMultiSelectRightPanel } from "@composes/multiselect"
export default {
  name: "IntegrationLogPanel",
  components: {
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
  },
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    const { selectedData } = useMultiSelectRightPanel()
    return {
      setStatistic, distActions,
      selectedData
    }
  },
  data() {
    return {
      integrationLog: null,
      integrationLogService: new IntegrationLogService(),
      directionService: new DirectionService(),
      statusService: new StatusService(),
      loading: false,
      hasError: false,
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime",
    }),
    headerOverview() {
      return {
        title: this.$t("mainInformation"),
        isExpand: false,
      };
    },
    headerBottom() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    tabs() {
      return [
        { name: this.$t("overview"), isActive: true, systemName: "overview" },
      ];
    },
    header() {
      if (this.integrationLog) {
        return {
          title: this.integrationLog.fileName,
          id: this.integrationLog.id,
          status: this.getStatus(this.integrationLog.status),
          options: this.options,
          showCounter: false,
        };
      }
      return {
        title: this.$t("itemsSelected"),
        showCounter: false,
      };
    },
    content() {
      if (this.integrationLog) {
        return [
          [
            {
              title: this.$t("integrationLogInterfaceName"),
              text: this.integrationLog.interfaceName,
            },
          ],
          [
            {
              title: this.$t("integrationLogTypeName"),
              text: this.integrationLog.typeName,
            },
          ],
          [
            {
              title: this.$t("integrationLogDirection"),
              text: this.directionService.find(this.integrationLog.direction),
            },
          ],
          [
            {
              title: this.$t("integrationLogStartProcess"),
              text: this.integrationLog.startProcess ? new Date(this.integrationLog.startProcess) : null,
              dateFormat: this.userinfo.dateFormat,
              timeFormat: this.userinfo.timeFormat + ':ss',
            },
          ],
          [
            {
              title: this.$t("integrationLogEndProcess"),
              text: this.integrationLog.endProcess ? new Date(this.integrationLog.endProcess) : null,
              dateFormat: this.userinfo.dateFormat,
              timeFormat: this.userinfo.timeFormat + ':ss',
            },
          ],
          [
            {
              title: this.$t("integrationLogTryCount"),
              text: this.integrationLog.tryCount,
            },
          ],
          [
            {
              title: this.$t("integrationLogErrorCode"),
              text: this.integrationLog.errorCode,
            },
          ],
          [
            {
              title: this.$t("integrationLogErrorMessage"),
              text: this.integrationLog.errorMessage,
            },
          ],
          [
            {
              title: this.$t("integrationLogMessageId"),
              text: this.integrationLog.messageId,
            },
          ],
        ];
      }
      return [];
    },
    itemsPerPage() {
      return this.content ? this.content.length : 1;
    },
    contentBottom() {
      if (this.integrationLog) {
        return [
          [
            {
              title: this.$t("createdByTxt"),
              text: this.integrationLog.createdBy,
            },
            {
              title: this.$t("updatedByTxt"),
              text: this.integrationLog.updatedBy,
            },
          ],
          [
            {
              title: this.$t("createdAtTxt"),
              text: this.getFormatDateTime(this.integrationLog.createdAt) + ' ' + getAMorPM(this.integrationLog.createdAt, this.userinfo.timeFormat),
            },
            {
              title: this.$t("lastUpdatedTxt"),
              text: this.getFormatDateTime(this.integrationLog.lastUpdated) + ' ' + getAMorPM(this.integrationLog.lastUpdated, this.userinfo.timeFormat),
            },
          ],
        ];
      }
      return [];
    },
  },
  mounted() {
    this.update();
  },
  watch: {
    selectedData() {
      this.update()
    }
  },
  methods: {
    handlerSelectTab({ selectedTab }) {
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: selectedTab.systemName
      })
    },
    update() {
      this.hasError = false;
      try {
        this.getIntegrationLog();
      } catch (e) {
        this.hasError = true;
      }
    },
    getIntegrationLog() {
      if (this.selectedData) {
        this.loading = true;
        this.integrationLogService.get(this.selectedData.id).then((res) => {
          this.integrationLog = res.data;
          this.loading = false;
        });
      }
      this.integrationLog = null;
    },
    getStatus(status) {
      if (!status) return;
      let s = this.statusService.search(status);
      let result =
        JSON.parse(JSON.stringify(s)) &&
        JSON.parse(JSON.stringify(s)).length > 0
          ? JSON.parse(JSON.stringify(s))[0]
          : {
              text: status[0].toUpperCase() + status.toLowerCase().slice(1),
              type: "default",
            };
      return result;
    },
    clearPanel() {
      this.$emit("clear");
    },
    skip() {
      this.$emit("skip", this.integrationLog);
    },
    restart() {
      this.$emit("restart", this.integrationLog);
    },
    downloadLog() {
      this.$emit("downloadLog", this.integrationLog);
    },
    downloadFile() {
      this.$emit("downloadFile", this.integrationLog);
    },
  },
};
</script>

<style scoped></style>
