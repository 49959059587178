import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/Competence/CompetenceTaskCommentApi.js";
import store from "../../../../Frontends/Common/store/index.js";
export class CompetenceTaskCommentService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(this.Api, "editCompetenceTaskComment", fields);
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editCompetenceTaskComment");
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getCompetenceTaskComments", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getCompetenceTaskComment", null, id);
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createCompetenceTaskComment", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteCompetenceTaskComment", model);
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "editCompetenceTaskComment", model);
  }
  async export(filter, format) {
    let role = JSON.parse(localStorage.getItem("userRole")).systemName;
    const res = this.Api.export(filter, role, format);
    await store.dispatch('notifications/handleResponse', {
      promiseResponse: res,
    })
    return res;
  }
}
