<template>
  <ProSpaceGeoLayout class="prospace-menuitems">
    <template #top>
      <ProSpaceTopPanel :header="$t('menuItems')" boxShadow @back="backHandler">
        <template #right>
          <ProSpaceIconButton
            icon="update"
            @click="actionUpdate"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
          />
          <ProSpaceButton
            style="margin-left: 10px"
            type="primary"
            icon="plus"
            :label="$t('newTxt')"
            @click="() => showModal()"
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <MenuItemsGrid
        ref="grid"
        @click-row="onClickRow"
        @modal-edit="showModal"
        @select="handleSelect"
        @optionsUpdate="handlerOptions"
        @clear="clear"
      />
    </template>
    <template #right>
      <MenuItemsPanel
        ref="panel"
        :key="selectedId"
        :service="menuItemsService"
        :options="options"
        :id="selectedId"
        @edit="showModal"
        @delete="deleteItem"
        @show-history="showHistory"
      />
    </template>
  </ProSpaceGeoLayout>
  <MenuItemsModal ref="modal" v-model="visibleModal" @get-grid="getGrid" />
</template>

<script>
import {
  ProSpaceIconButton,
  ProSpaceButton,
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
} from "prospace-components-library";
import MenuItemsGrid from "./components/MenuItemsGrid.vue";
import MenuItemsModal from "./components/MenuItemsModal.vue";
import MenuItemsPanel from "./components/MenuItemsPanel.vue";
import { MenuItemsService } from "../../services/MenuItemsService.js";
export default {
  name: "MenuItems",
  components: {
    ProSpaceIconButton,
    ProSpaceButton,
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    MenuItemsGrid,
    MenuItemsModal,
    MenuItemsPanel,
  },
  data() {
    return {
      visibleModal: false,
      masterId: null,
      selectedId: null,
      menuItemsService: new MenuItemsService(),
      options: [],
    };
  },
  methods: {
    showModal(item) {
      this.$refs.modal.initModal(item);
    },
    closeModal() {
      this.visibleModal = false;
    },
    getGrid() {
      this.selectedId = null;
      this.$refs.grid.getRecords();
    },
    deleteItem() {
      this.selectedId = null;
      const model = this.$refs.panel.menuitem;
      this.$refs.grid.deleteItem(model);
    },
    showHistory() {
      const model = this.$refs.panel.menuitem;
      this.$refs.grid.showHistory(model);
    },
    handleSelect(data) {
      this.selectedId = data?.id;
    },
    clear() {
      this.selectedId = null;
      this.$refs.grid.selectedModel = [];
    },
    actionUpdate() {
      this.$refs.grid.getRecords(true);
      if (this.$refs["panel"]) {
        this.$refs["panel"].update();
      }
    },
    handlerOptions(options) {
      this.options = options;
    },
  },
};
</script>

<style lang="scss" scope>
.prospace-menuitems {
  height: 100vh !important;
}
</style>
