import * as auth from "./auth";
import * as signalr from "@microsoft/signalr";
import store from "../store";
import { LOG_LEVEL } from "./consts";

const DEBUG = process.env.VUE_APP_DEBUG || config.VUE_APP_DEBUG;
const SIGNALR_HUB_NAME = `${process.env.VUE_APP_API_BASE_URL || config.VUE_APP_API_BASE_URL}notificationHub`;
export const MESSAGES = {
  VersionUpdate: "New version avaliable. Please refresh page",
};
export const SIGNALR_SERVICE_MESSAGES = {
  VersionUpdate: "APPLICATION_VERSION_UPDATE",
};

export const NOTIFICATION_TYPE = {
  VersionUpdate: "update-version",
};

export const signalRConnection = (function () {
  var connection = new signalr.HubConnectionBuilder()
    .withUrl(SIGNALR_HUB_NAME, { accessTokenFactory: () => auth.getToken() })
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: (retryContext) => {
        if (retryContext.elapsedMilliseconds < 10 * 60000) {
          // If we've been reconnecting for less than 10 minutes so far,
          // wait between 0 and 10 seconds before the next reconnect attempt.
          return Math.random() * 10000;
        } else {
          // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
          return null;
        }
      },
    })
    .configureLogging(signalr.LogLevel.Warning) // signalr.LogLevel.Warning, signalr.LogLevel.Information
    .build();
  return connection;
})();

const messageIdStore = {
  idStore: [],
  checkMessageProcessed(messageId) {
    return this.idStore.includes(messageId);
  },
  addProcessedMessage(messageId) {
    this.idStore.push(messageId);
    setTimeout(() => {
      this.idStore.splice(this.idStore.indexOf(messageId), 1);
    }, 60000);
  },
};

// Message processing

let loading = false;
let runAfter = [];

export async function addUserNotification(mi) {
  await store.dispatch('notifications/addNotification', mi).then(async () => {
  })
}

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}
function unreadInfoFind(x, ui) {
  return (x.Type && ui.Type && x.Type === ui.Type);
}

export async function updateNotification(mi) {
  let notes = store.getters["notifications/notificationMessages"];
  let note = notes.find(a => a.messageData.Record.Id == mi.messageData.Record.Id && a.messageData.Title == mi.messageData.Title);

  if (note) {
    note.messageData.Record = mi.messageData.Record;
    note.messageData.Result = mi.messageData.Result;
    note.messageData.NotificationType = mi.messageData.NotificationType;
    if (mi.messageData.UnreadInfo) {
      let c = 0;
      if (Array.isArray(note.messageData.UnreadInfo)) {
        /*Если приходит групповая нотификация и в сторе имеется
        соответсующая ей нотификация то необходимо их смержить
        нельзя полностью переписывать старую нотификацию 
        из-за того что пользователь мог не прочитать все записи.
        Новая нотификация не гарантирует наличие всех возможных вариантов Ids
        она только досылает новую информацию не зная о той что уже была*/
        mi.messageData.UnreadInfo.forEach(ui2 => {
          let deletedUi2 = null;

          let ui = note.messageData.UnreadInfo.find(x => unreadInfoFind(x, ui2));

          // если нет типа в нотификации, создаем его
          if (!ui) {
            ui = { Type: ui2.Type, Ids: [] };
            note.messageData.UnreadInfo.push(ui);
          }

          // при наличии информации о удаленных записях они убираются из текущей нотификации
          if (Array.isArray(mi.messageData.DeletedInfo))
            deletedUi2 = mi.messageData.DeletedInfo.find(x => unreadInfoFind(x, ui2));

          if (ui) {
            if(ui.ReadedIds)
              ui2.Ids = ui2.Ids.filter(id => !ui.ReadedIds.includes(id))

            let existsNote = notes.find(n => !n.readed
              && n.messageData.Title === mi.messageData.Title 
              && n.messageData.Record.Type === ui2.Type
              && ui2.Ids.includes(n.messageData.Record.Id));

            if(existsNote)
              ui2.Ids = ui2.Ids.filter(id => id !== existsNote.messageData.Record.Id)

            ui.Ids = ui2.Ids.concat(ui.Ids).filter(onlyUnique);

            if (deletedUi2) 
              ui.Ids = ui.Ids.filter(x => !deletedUi2.Ids.includes(x));
          }
        });

        c = note.messageData.UnreadInfo.reduce((accumulator, current) => accumulator + current.Ids.length, c);
      } else if (note.messageData.UnreadInfo.Ids) {
        note.messageData.UnreadInfo.Ids = mi.messageData.UnreadInfo.Ids.concat(note.messageData.UnreadInfo.Ids).filter(onlyUnique);
        c = note.messageData.UnreadInfo.Ids.length;
      }
      note.messageData.UnreadCount = c;
      note.title = mi.title.replace("{counter}", note.messageData.UnreadCount);
    } else {
      note.title = mi.title;
    }
    note.logLevel = mi.logLevel;
    note.message = mi.message;
    note.messageId = mi.messageId;
    note.isClosed = false;
    note.date = new Date();

    return await store.dispatch('notifications/updateNotification', note);
  } else {
    if (mi.messageData.UnreadInfo) {
      mi.titleTemplate = mi.title;
      if (mi.messageData.UnreadCount) {
        mi.title = mi.title.replace("{counter}", mi.messageData.UnreadCount);
      }
    }
    return await addUserNotification(mi);
  }
}

export function handleApiChange(mi) {
  addUserNotification({
    logLevel: mi.logLevel,
    title: MESSAGES.VersionUpdate,
    type: NOTIFICATION_TYPE.VersionUpdate,
  });
}

async function processMessage(mi) {
  if (DEBUG)
    console.log("ReceiveNotification:", mi);

  if (mi.messageId && messageIdStore.checkMessageProcessed(mi.messageId)) {
    console.log(`message '${mi.messageId}' was skipped due to it's already processed`);
    return;
  } else if (mi.messageId) {
    messageIdStore.addProcessedMessage(mi.messageId);
  }
  mi.logLevel = getLogLevel(mi.level);
  var serviceMessageKey = Object.keys(SIGNALR_SERVICE_MESSAGES).find(
    (m) => SIGNALR_SERVICE_MESSAGES[m] == mi.message
  );
  if (serviceMessageKey) {
    if (processingType !== "silence") {
      let tmpMi = { ...mi };
      tmpMi.message = MESSAGES[serviceMessageKey];
      await addUserNotification(tmpMi);
    }

    if (mi.message == SIGNALR_SERVICE_MESSAGES.VersionUpdate) {
      handleApiChange(mi);
    }
  } else {
    if (mi.messageData && mi.messageData.Record.Id) {
      await updateNotification(mi)
    } else {
      await addUserNotification(mi);
    }
  }

}

// signalR handler
export function notificationsInit() {
  let loadPromise = store.dispatch('notifications/load');
  signalRConnection.on(
    "ReceiveNotification",
    function (processingType, messageId, level, message, messageData, notificationId, title, typeTarget, createDate) {
      processMessage({ processingType, messageId, level, message, messageData, notificationId, title, typeTarget, createDate });
    }
  );

  signalRConnection.on("ReceiveServiceSignal", function (type, params) {
    console.log("Service signal: " + type + " " + params);
  });

  window.onbeforeunload = function () {
    signalRConnection.stop();
  };

  signalRConnection.start().catch((err) => {
    if (DEBUG) console.error(err.toString());
  });

  return loadPromise;
}
export function loadNotifications() {
  return store.dispatch('notifications/load');
}
export function checkDisconnect() {
  return signalRConnection.state.toLowerCase() === "disconnected";
}
function getLogLevel(logLevel) {
  if (logLevel === 0) return LOG_LEVEL.Trace;
  else if (logLevel === 1) return LOG_LEVEL.Debug;
  else if (logLevel === 2) return LOG_LEVEL.Information;
  else if (logLevel === 3) return LOG_LEVEL.Warning;
  else if (logLevel === 4) return LOG_LEVEL.Error;
  else if (logLevel === 5) return LOG_LEVEL.Critical;
  else if (logLevel === 6) return LOG_LEVEL.None;
  return LOG_LEVEL.Information;
}
