import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/taskservice/taskassignmentattachments/";

export default class TaskAssignmentAttachmentsApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getTaskAssignmentAttachmentss(params) {
    return await request.post(`${rootRoute}getTaskAssignmentAttachmentss`, {
      filter: params,
      parameters: {},
    });
  }
  async getTaskAssignmentAttachments(id) {
    return await request.post(`${rootRoute}getTaskAssignmentAttachmentss`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createTaskAssignmentAttachments(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createTaskAssignmentAttachments`, { data });
  }
  async deleteTaskAssignmentAttachments(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteTaskAssignmentAttachments`, { data });
  }
  async editTaskAssignmentAttachments(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateTaskAssignmentAttachments`, { data });
  }
  async setStatusTaskAssignmentAttachments(models) {
    const data = models;
    return await request.post(`${rootRoute}setStatusTaskAssignmentAttachments`, { data });
  }
}
