/*
  Стор нотификаций синхронизирует данные с IndexedDB 
  и при запуске приложение данные из IndexedDB прогружаются в стор.
  Это необходимо чтобы при закрытии браузера данные не терялись
*/

import "indexeddb-getall-shim";
import _ from "lodash";

let loaded = false;
function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

async function put(mi) {
  const promise = new Promise((resolve, reject) => {
    let result;

    const lindexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
    const lopen = lindexedDB.open("ProSpaceNotification", 10);

    lopen.onsuccess = _ => {
      let db = lopen.result;
      let etx = db.transaction("Notifications", "readwrite");

      etx.oncomplete = _ => resolve(result);
      etx.onerror = event => reject(event.target.error);

      let eventStore = etx.objectStore("Notifications");

      const request = eventStore.put(mi);
      request.onsuccess = _ => {
        result = request.result;
        db.close();
      };
    }
  });

  return await promise;
}

const state = {
  notifications: [],
  lastChanged: {},
  getCounter: (notifications) => {
    let withCounter = notifications.filter((n) => n.messageData.UnreadCount);
    let counter = 0;
    withCounter.forEach((n) => (counter += n.messageData.UnreadCount));
    let withOutCounter = notifications.filter(
      (n) => !n.messageData.UnreadInfo
    ).length;
    return counter + withOutCounter;
  }
};

const mutations = {
  UPDATE_LASTCHANGED: (state, lastChanged) => {
    state.lastChanged = lastChanged;
  },
  ADD_NOTIFICATION: (state, messageInfo) => {
    state.notifications.push(messageInfo);
    let lastChanged = { ...messageInfo };
    lastChanged.counter = state.getCounter(state.notifications.filter(n => n.messageData.Title === messageInfo.messageData.Title && n.readed === false));
    state.lastChanged = JSON.parse(JSON.stringify(lastChanged));
  },
  ADD_NOTIFICATIONS: (state, messageInfos) => {
    state.notifications = messageInfos;
    let lastChanged = { ...messageInfos[0] };
    lastChanged.counter = state.getCounter(state.notifications.filter(n => n.messageData.Title === messageInfos[0].messageData.Title && n.readed === false));
    state.lastChanged = JSON.parse(JSON.stringify(lastChanged));
  },
  UPDATE_NOTIFICATION: (state, messageInfo) => {
    let i = state.notifications.findIndex(n => n.id === messageInfo.id);
    messageInfo.readed = false;
    state.notifications[i] = messageInfo;

    let lastChanged = { ...messageInfo };
    lastChanged.counter = state.getCounter(state.notifications.filter(n => n.messageData.Title === messageInfo.messageData.Title && n.readed === false));
    state.lastChanged = JSON.parse(JSON.stringify(lastChanged));
  },
  UPDATE_NOTIFICATION_READED: (state, info) => {
    let toUpdate = state.notifications.filter(n => n.messageData.Title === info.title && n.readed === false);
    toUpdate.forEach(n => {
      n.readed = true;
      if (n.messageData && n.messageData.UnreadCount) {
        n.messageData.UnreadCount = 0;
        n.messageData.UnreadInfo = [];
      }
    });

    if (toUpdate.length > 0) {
      let lastChanged = toUpdate[0];
      lastChanged.counter = 0;
      state.lastChanged = JSON.parse(JSON.stringify(lastChanged));
    }
  },
  UPDATE_NOTIFICATION_READED_RECORD: (state, info) => {
    let toUpdate = state.notifications.filter(n => n.readed === false && n.messageData.Title === info.title && n.messageData.Record.Id === info.id);
    const paramFilter = info.paramFilter || (x => x.Type === info.type);
    for (const n of toUpdate) {
      if (info.groupUpdate) {
        // необходимо пройтись по UnreadInfo и убрать id которые прочитаны
        if (Array.isArray(n.messageData.UnreadInfo)) {
          let ui = n.messageData.UnreadInfo.find(paramFilter);
          if (ui && Array.isArray(ui.Ids) 
              && (ui.Ids.includes(info.realId) 
                  || (info.realIds && ui.Ids.some(x => info.realIds.includes(x))))
          ) {
            let newIds = ui.Ids.filter(x => (info.realId && x !== info.realId) || (info.realIds && !info.realIds.includes(x)));
            let readedIds = ui.Ids.filter(x => !((info.realId && x !== info.realId) || (info.realIds && !info.realIds.includes(x))));
            if (newIds.length !== ui.Ids.length) {
              if (ui.Aditional) 
                ui.Aditional = ui.Aditional
                  .filter(x => (info.realId && x.Id !== info.realId) || (info.realIds && !info.realIds.includes(x.Id)));

              ui.Ids = newIds;
              ui.ReadedIds = ui.ReadedIds ? ui.ReadedIds.concat(readedIds).filter(onlyUnique) : readedIds;
              if (n.messageData.UnreadCount) n.messageData.UnreadCount--;
            }
          }
        } else {
          let newIds = n.messageData.UnreadInfo.Ids.filter(x => (info.realId && x !== info.realId) || (info.realIds && !info.realIds.includes(x)));
          if (newIds.length !== n.messageData.UnreadInfo.Ids.length) {
            if (n.messageData.UnreadInfo.Aditional) 
              n.messageData.UnreadInfo.Aditional = ui.Aditional.
                filter(x => (info.realId && x.Id !== info.realId) || (info.realIds && !info.realIds.includes(x.Id)));
                
            n.messageData.UnreadInfo.Ids = newIds;
            if (n.messageData.UnreadCount) n.messageData.UnreadCount--;
          }
        }
        n.title = n.titleTemplate.replace("{counter}", n.messageData.UnreadCount);
        if (!n.messageData.UnreadCount && !info.skipRead) {
          n.readed = true;
        }
      } else {
        n.readed = true;
      }
    }
    
    let counter = state.getCounter(state.notifications.filter(n => n.readed === false && n.messageData.Title === info.title));
    if (toUpdate.length > 0) {
      let lastChanged = toUpdate[0];
      lastChanged.counter = counter;
      state.lastChanged = JSON.parse(JSON.stringify(lastChanged));
    }
  },
  DELETE_NOTIFICATION: (state, info) => {
    state.notifications = state.notifications.filter(i => i.id !== info.id)
  }
};

const actions = {
  async load(context) {
    const promise = new Promise((resolve, reject) => {
      const indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
      const open = indexedDB.open("ProSpaceNotification", 10);
      open.onupgradeneeded = function () {
        let db = open.result;
        db.createObjectStore("Notifications", { keyPath: "id" });
      };
      open.onsuccess = function () {
        let db = open.result;
        let etx = db.transaction("Notifications", "readwrite");
        let eventStore = etx.objectStore("Notifications");
        let eventStoreRequest = eventStore.getAll();
        eventStoreRequest.onsuccess = function (event) {
          context.commit("ADD_NOTIFICATIONS", event.target.result);
          resolve(event.target.result);
        };
        loaded = true;
        context.state.loaded = true;
      };
    });

    return await promise;
  },
  async addNotification(context, messageInfo) {
    messageInfo.date = new Date();
    messageInfo.deleted = false;
    messageInfo.isClosed = false;
    messageInfo.readed = false;
    messageInfo.id = Math.random()
      .toString(36)
      .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15);

    await put(JSON.parse(JSON.stringify(messageInfo)));

    context.commit("ADD_NOTIFICATION", messageInfo);
    return messageInfo;
  },
  updateLastChanged(context, lastChanged) {
    context.commit("UPDATE_LASTCHANGED", lastChanged);
  },
  async updateNotification(context, messageInfo) {
    messageInfo.readed = false;
    await put(JSON.parse(JSON.stringify(messageInfo)));
    context.commit("UPDATE_NOTIFICATION", messageInfo);
  },
  async updateReaded(context, info) {
    let toUpdate = context.getters.notificationMessages.filter(n => n.messageData.Title === info.title && n.readed === false);
    context.commit("UPDATE_NOTIFICATION_READED", info);
    for (const n of toUpdate) {
      n.readed = true;
      await put(JSON.parse(JSON.stringify(n)));
    }
  },
  async updateReadedRecord(context, info) {
    let toUpdate = context.getters.notificationMessages.filter(n => n.readed === false && n.messageData.Title === info.title && n.messageData.Record.Id === info.id);
    context.commit("UPDATE_NOTIFICATION_READED_RECORD", info);
    for (const n of toUpdate) {
      await put(JSON.parse(JSON.stringify(n)));
    }
  },
  addFrontNotification(context, info) {
    if (!(info.title)) {
      throw new Error('Required field for notification: title')
    }
    const messageInfo = getFrontNotification(info)
    context.commit("ADD_NOTIFICATION", messageInfo);
    return messageInfo;
  },
  updateFrontNotification(context, info) {
    if (!(info.title)) {
      throw new Error('Required field for notification: title')
    }
    const messageInfo = getFrontNotification(info)
    context.commit("UPDATE_NOTIFICATION", messageInfo);
    return messageInfo;
  },
  deleteFrontNotification(context, info) {
    context.commit("DELETE_NOTIFICATION", info)
  },
  async handleResponse({ dispatch }, {promiseResponse, title, type}) {
    try {
      const response = (await promiseResponse).data
      await dispatch("addNotification", {
        title: `Task '${response.data[0].taskName}' is 'Created'`,
        messageData: {
          Record: {
            Id: response.data[0].id,
          },
          Title: title || "Background tasks",
          NotificationType: type || "backgroundTask"
        },
        logLevel: "Information"
      })
    } catch {
      dispatch('addFrontNotification', {
        title: "Task creation error",
        logLevel: "Error"
      })
    }
    return Promise.resolve()
  }
};

const getters = {
  notificationMessages: (state) => {
    return state.notifications;
  },
  lastChanged: (state) => {
    return state.lastChanged;
  },
  getCounter: (state) => {
    return state.getCounter;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};


function getFrontNotification(info) {
    const id = info.id || new Date().getTime()
    
    const noteProps = ['id', 'title', 'logLevel']
    const noteInfo = {}
    const addingInfo= {}
    
    Object.entries(info).forEach(([key, value]) => {
      if (noteProps.includes(key)) {
        noteInfo[key] = value
      } else {
        addingInfo[key] = value
      }
    })

    const note = {
      id: id,
      isFront: true,
      logLevel: noteInfo.logLevel || "Information",
      title: noteInfo.title || "Background tasks",
      messageData: {
        Title: noteInfo.title,
        Record: {Id: id}
      }
    }

    const messageInfo = _.merge(note, addingInfo);
    return messageInfo
}