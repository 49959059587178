import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/competence/competence/";

export default class CompetenceApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getCompetences(params) {
    return await request.post(`${rootRoute}getCompetences`, {
      filter: params,
      parameters: {},
    });
  }
  async getCompetence(id) {
    return await request.post(`${rootRoute}getCompetences`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createCompetence(params) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), params)];
    return await request.post(`${rootRoute}createCompetence`, { data });
  }
  async createcompetencewithtaskcopies(params) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), params.model)];
    return await request.post(`${rootRoute}createcompetencewithtaskcopies`, { data },
    {
      params: { 
        copyTaskCompetenceId: params.copyTaskCompetenceId 
      }
    });
  }
  async deleteCompetence(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteCompetence`, { data });
  }
  async editCompetence(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateCompetence`, { data });
  }
  async export(filter, additionalFilter, role, format) {
    return await request.post(`${rootRoute}exportCompetence`, {
      filter: filter,
      additionalFilter: additionalFilter,
      role: role,
      format: format
    });
  }
}
