import request from "../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "filestore/fileservice/";

export default class FileApi {
  async upload(formData) {
    return await request.post(`${rootRoute}uploadfile`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
  async uploadGFID(formData) {
    return await request.post(`${rootRoute}uploadgfidfile`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
  async uploadChunkGFID(chunk) {
    return await request.post(`${rootRoute}uploadchunkgfidfile`, chunk.data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Content-Info': `${chunk.startPoint}-${chunk.fullLenght}`
      }
    });
  }
  async acceptChunkFileUpload(gfid) {
    return await request.post(`${rootRoute}acceptchunksfileupload`, {
      gfid: gfid
    });
  }
  async errorChunkFileUpload(gfid) {
    return await request.post(`${rootRoute}errorchunksfileupload`, {
      gfid: gfid
    });
  }
  async getDownloadUrl(gfid) {
    return await request.post(`${rootRoute}getdownloadurl`, {
      gfid: gfid
    });
  }
  async downloadFile(token, chunkInfo) {
    return await request.post(`${rootRoute}downloadfile`, null, {
      headers: {
        'Authorization': "Bearer " + token,
        'Content-Info': `${chunkInfo.startPoint}-${chunkInfo.chunkLength}`
      },
      responseType: 'blob'
    });
  }
  async createFilesRoot(fileInfos) {
    return await request.post(`${rootRoute}createFilesRoot`, {
      data: fileInfos
    });
  }
}
