import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/taskservice/taskassignmentrecipient/";

export default class TaskAssignmentRecipientApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getTaskAssignments(params) {
    return await request.post(`${rootRoute}gettaskassignmentrecipients`, {
      filter: params,
    });
  }
  async getTaskAssignmentsFromView(params) {
    return await request.post(`${rootRoute}gettaskassignmentrecipientsfromview`, {
      filter: params,
    });
  }
}
