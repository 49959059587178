import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/competence/competencetaskcomment/";

export default class CompetenceTaskCommentApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getCompetenceTaskComments(params) {
    return await request.post(`${rootRoute}getCompetenceTaskComments`, {
      filter: params,
      parameters: {},
    });
  }
  async getCompetenceTaskComment(id) {
    return await request.post(`${rootRoute}getCompetenceTaskComments`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createCompetenceTaskComment(model) {
    const data = Array.isArray(model)
      ? model
      : [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createCompetenceTaskComment`, { data });
  }
  async deleteCompetenceTaskComment(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteCompetenceTaskComment`, { data });
  }
  async editCompetenceTaskComment(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateCompetenceTaskComment`, { data });
  }
}
