import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/masterdata/filialstructureorg/";

export default class FilialStructureApi {
  constructor() {
  }
  async getFilialStructureOrgs(params) {
    return await request.post(`${rootRoute}getFilialStructureOrgs`, {
      filter: params,
      parameters: {},
    });
  }
  async getMarsOrg(params) {
    return await request.post(`${rootRoute}getMarsOrgs`, {
      filter: params,
      parameters: {},
    });
  }
  async createMarsOrg(model) {
    const data = [model];
    return await request.post(`${rootRoute}createMarsOrg`, { data });
  }
  async deleteMarsOrg(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteMarsOrg`, { data });
  }
  async editMarsOrg(model) {
    const data = [model];
    return await request.post(`${rootRoute}updateMarsOrg`, { data });
  }

  async get(filter) {
    return await request.post(`${rootRoute}getMarsOrgs`, {
      filter
    });
  }
}
