import workflows from "../../../../Services/Workflow/ProSpace.Workflow.Frontend/views/workflow/Workflows";
import workflow from "../../../../Services/Workflow/ProSpace.Workflow.Frontend/views/workflow/Workflow";

export const workflowRoutes = [
  {
    path: "/workflow/workflowsetting",
    name: "workflows",
    component: workflows,
  },
  {
    path: "/workflow/workflowsetting/:id",
    name: "workflow",
    component: workflow,
    meta: {
      menuitem: "/workflow/workflowsetting",
    },
  },
];

export default workflowRoutes;
