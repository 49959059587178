<template>
  <ProSpaceRightPanel
    :header="header"
  >
    <template #center>
      <ProSpaceTabs v-model="modelTabs" :tabs="tabs" @select-tab="handlerSelectTab">
        <template #tab-1>
          <ProSpacePanelBlock :header="headerGeneralInfo">
            <template v-slot>
              <div class="prospace-panel-block-list">
                <div
                  class="prospace-panel-block-list__row"
                  v-for="(content, i) in contentGeneralInfo"
                >
                  <ProSpacePanelBlockItem
                    :key="content.id + i"
                    class="prospace-panel-block-list__item"
                    :title="content.title"
                    :text="content.text"
                    is-html
                  />
                </div>
              </div>
            </template>
          </ProSpacePanelBlock>
        </template>
        <template #tab-2>
          <ProSpacePanelBlock :header="headerProductInfo">
            <template v-slot>
<!--              :service="reserveProductService"-->
<!--              getMethod="grid"-->
<!--              propId="id"-->
<!--              :id="reserve.id"-->
              <ProSpaceSimpleGrid
                :items="products"
                :headers="productsHeaders"
                :limit="50"
                :btnText="$t('viewMore')"
                :deletable="false"
                :deleteMethod="null"
              />
            </template>
          </ProSpacePanelBlock>
        </template>
      </ProSpaceTabs>
    </template>
    <template #bottom>
      <ProSpacePanelBlock :header="headerBottom">
        <template v-slot>
          <ProSpacePanelBlockList :list="contentBottom" />
        </template>
      </ProSpacePanelBlock>
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpacePanelBlock,
  ProSpacePanelBlockItem,
  ProSpaceAction,
  ProSpaceVLayout,
  ProSpacePanelBlockList,
  ProSpaceSimpleGrid
} from "prospace-components-library";
import { getAMorPM } from "prospace-components-library/src/lib-assets/mixins/date";
import { mapGetters } from "vuex";
import { ReserveProductService } from "../../../services/ReserveProductService.js";

export default {
  name: "ReservesRightPanel",
  components: {
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpacePanelBlock,
    ProSpacePanelBlockItem,
    ProSpaceAction,
    ProSpaceVLayout,
    ProSpacePanelBlockList,
    ProSpaceSimpleGrid
  },
  props: {
    reserve: {
      type: Object,
      default: () => {}
    },
    status: {
      type: String,
      default: ''
    },
    checkAccess: {
      type: Function,
    }
  },
  data() {
    return {
      modelTabs: {
        name: this.$t("reserveBaseInfo"),
        systemName: "info",
        isActive: true,
      },
      products: [],
      reserveProductService: new ReserveProductService(),
    };
  },
  watch: {
    reserve: {
      handler: function(val) {
        this.getReserveProducts(val.id);
      },
      deep: true,
      immediate: true

    }
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime",
    }),
    header() {
      return {
        title: this.reserve.shipToName,
        id: this.reserve.code,
        status: this.status,
        options: null,
        showCounter: false,
      };
    },
    tabs() {
      return [
        {
          name: this.$t("reserveBaseInfo"),
          systemName: "info",
          isActive: this.modelTabs.systemName === "info",
        },
        {
          name: this.$t("reserveProducts"),
          systemName: "products",
          isActive: this.modelTabs.systemName === "products",
        }
      ]
    },
    headerBottom() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    contentBottom() {
      let metaInfo = [];
      if (this.reserve) {
        metaInfo.push(
          [
            {
              title: this.$t("createdByTxt"),
              text: this.reserve.createdBy,
            },
            {
              title: this.$t("updatedByTxt"),
              text: this.reserve.updatedBy,
            },
          ], [
            {
              title: this.$t("createdAtTxt"),
              text: this.getFormatDateTime(this.reserve.createdAt) + ' ' + getAMorPM(this.reserve.createdAt, this.userinfo.timeFormat),
            },
            {
              title: this.$t("lastUpdatedTxt"),
              text: this.getFormatDateTime(this.reserve.lastUpdated) + ' ' + getAMorPM(this.reserve.lastUpdated, this.userinfo.timeFormat),
            },
          ]
        );
      }
      return metaInfo;
    },
    headerGeneralInfo() {
      return {
        title: this.$t("reserveBaseInfo"),
        isExpand: false,
      };
    },
    headerProductInfo() {
      return {
        title: this.$t("reserveProducts"),
        isExpand: false
      }
    },
    contentGeneralInfo() {
      return [
        {
          title: this.$t("reserveDate"),
          text: this.getFormatDateTime(this.reserve.createdAt) + ' ' + getAMorPM(this.reserve.createdAt, this.userinfo.timeFormat)
        },
        { title: this.$t("reserveShipping"), text: this.reserve.shipFrom },
        { title: this.$t("reserveDelivery"), text: this.reserve.shipToName},
        { title: this.$t("reserveShipTo"), text: this.reserve.shipToCode },
      ]
    },
    productsHeaders() {
      return [
        { name: this.$t("bbdProductGRD"), value: "grd", width: '65px' },
        { name: this.$t("reserveProduct"), value: "description", width: '120px' },
        { name: this.$t("reserveCountShort"), value: "reserveQuantity", f: (item) => item, width: '40px' },
      ]
    },
    productsService() {
      return this.products;
    }
  },
  methods: {
    async getReserveProducts(id) {
      const filter = `$filter=(reserveId eq ${this.reserve.id} and disabled eq false)&$orderby=description asc`
      const res = await this.reserveProductService['grid'](
        filter
      );
      const products = res.data.records;
      this.products = products;
    },
    handlerSelectTab(tab) {
      // if (tab.selectedTab.systemName === 'products') {
      //   this.getReserveProducts();
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/main.scss";

.prospace-panel-block-list {
  @include right-panel-block-list;
}

:deep(.p-datatable .p-datatable-thead > tr > th) {
  .p-column-title {
    font-weight: 400;
  }
  &:last-child {
    .p-column-title {
      max-width: 100%;
    }
  }
}
:deep(.p-datatable .prospace-list-items-table .p-datatable-tbody > tr) {
  color: var(--prospace-text-main)
}
</style>
