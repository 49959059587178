import { Transformer } from "../../../../Transformer/index.js";

export class LoginTypeService {
  constructor() {
    this.transformer = new Transformer();
  }
  async get(args) {
    return await this.transformer.getGrid(this, "getLocal", args);
  }

  getLocal(args) {
    const data = {
      data: {
        records: [
          {label: "Microsoft", value: "Microsoft"},
          {label: "Cookies", value: "Cookies"},
        ]
      }
    }
    return { data };
  }
  
}
