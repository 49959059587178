import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/MasterData/EmpoweredContactPersonApi";
import store from "../../../../Frontends/Common/store";

export class EmpoweredContactPersonService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(this.Api, "editEmpoweredContactPerson", fields);
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editEmpoweredContactPerson");
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getEmpoweredContactPersons", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getEmpoweredContactPerson", null, id);
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createEmpoweredContactPerson", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteEmpoweredContactPerson", model);
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "editEmpoweredContactPerson", model);
  }
  async getDocTypes() {
    const docTypes = [
      { label: "Свидетельство", value: "Свидетельство" },
      { label: "Устав", value: "Устав" },
    ]
    const data = {records: docTypes}
    return Promise.resolve( { data })
  }
  async export(filter, format) {
    const res = this.Api.export(filter, format);
    await store.dispatch('notifications/handleResponse', {
      promiseResponse: res,
    })
  }
}
