import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/NotificationQueue/NotificationLogApi.js";
export class NotificationLogService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(this.Api, "editNotificationLog", fields);
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editNotificationLog");
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getNotificationLogs", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getNotificationLog", null, id);
  }
  async repeat(items) {
    return await this.transformer.editItem(this.Api, "repeat", items);
    // код для действия "Повторить"
  }
  async cancel(items) {
    // код для действия "Отменить"
  }
}
