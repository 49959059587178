<template>
  <ProSpaceRightPanel
    :header="header"
    :loading="loading"
    :has-error="hasError"
    @clear="clearPanel"
    @edit="editPanel"
    @delete="deletePanel"
    @show-history="showHistoryPanel"
    >
    <template v-if="report" v-slot:center>
      <ProSpacePanelBlock :header="headerReports">
        <template v-slot>
          <div class="prospace-panel-block-list">
            <div class="prospace-panel-block-list__row">
              <ProSpacePanelBlockItem
                class="prospace-panel-block-list__item"
                :title="$t('report')">
                <template #content>
                  <Image :src="report.picFileContent" width="260" height="155" @click="openLink" />
                </template>
              </ProSpacePanelBlockItem>
            </div>
            <div class="prospace-panel-block-list__row">
              <ProSpacePanelBlockItem
                class="prospace-panel-block-list__item"
                :title="$t('reportPowerBIHrefRightPanel')">
                <template #content>
                  <ProSpaceAction
                    :text="report.name"
                    @click="handleReportLinkClick" />
                </template>
              </ProSpacePanelBlockItem>
            </div>
            <div class="prospace-panel-block-list__row">
              <ProSpacePanelBlockItem
                class="prospace-panel-block-list__item"
                :title="$t('reportDescription')"
                :text="report.description"
                isFullText
              />
            </div>
            <div class="prospace-panel-block-list__row" v-if="report.instructionGFID">
              <ProSpacePanelBlockItem
                class="prospace-panel-block-list__item"
                :title="$t('instruction')"
              >
                <template #content>
                  <ProSpaceAction
                    icon="paperclip"
                    :gap="5"
                    :key="i"
                    :text="$t('downloadInstruction')"
                    @click="handleInstructionClick"
                  />
                </template>
              </ProSpacePanelBlockItem>
            </div>
          </div>
        </template>
      </ProSpacePanelBlock>
    </template>
    <template v-if="report" v-slot:bottom>
      <ProSpacePanelBlock :header="headerBottom">
        <template v-slot>
          <ProSpacePanelBlockList :list="contentBottom" />
        </template>
      </ProSpacePanelBlock>
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpacePanelBlockItem,
  ProSpaceAction,
} from "prospace-components-library";
import Image from 'primevue/image';
import { ReportService } from "../../../services/ReportService";
import { FileService } from "../../../../../../Frontends/ProSpace/services/FileService";
import { mapGetters } from "vuex";
import {
  useMultiSelectRightPanel,
} from "@composes/multiselect"
export default {
  name: "ReportPanel",
  components: {
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceAction,
    ProSpacePanelBlockItem,
    Image
  },
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    filterAccessDots: {
      type: Function,
    },
  },
  setup() {
    const { selectedData } = useMultiSelectRightPanel()
    return {
      selectedData
    }
  },
  data() {
    return {
      report: null,
      reportService: new ReportService(),
      fileService: new FileService(),
      loading: false,
      hasError: false,
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
    }),
    headerReports() {
      return {
        title: this.$t("mainInformation"),
        isExpand: false,
      };
    },
    headerBottom() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    tabs() {
      return [{ name: this.$t("overview"), isActive: true }];
    },
    header() {
      if (this.report) {
        return {
          title: this.report.name,
          id: this.report.id,
          status: this.getStatus(this.report.status),
          options: this.getAccessDots(this.options),
        };
      }
      return {
        title: this.$t("itemsSelected"),
        cleareble: true,
      };
    },
    contentBottom() {
      if (this.report) {
        return [
          [
            {
              title: this.$t("createdByTxt"),
              text: this.report.createdBy,
            },
            {
              title: this.$t("updatedByTxt"),
              text: this.report.updatedBy
            },
          ],
          [
            {
              title: this.$t("createdAtTxt"),
              text: new Date(this.report.createdAt),
              dateFormat: this.userinfo.dateFormat,
            },
            {
              title: this.$t("lastUpdatedTxt"),
              text: new Date(this.report.lastUpdated),
              dateFormat: this.userinfo.dateFormat,
            },
          ],
        ];
      }
      return [];
    },
  },
  mounted() {
    this.update();
  },
  watch: {
    selectedData: {
      deep: true,
      handler() {
        this.update();
      }
    },
  },
  methods: {
    openLink() {
      window.open(this.report.powerBIHref, '_blank');
    },
    update() {
      this.hasError = false;
      try {
        this.getReport();
      } catch (e) {
        this.hasError = true;
      }
    },
    getReport() {
      if (this.selectedData) {
        this.loading = true;
        this.reportService.get(this.selectedData.id).then((res) => {
          this.report = res.data;
          this.loading = false;
        });
      }
      this.report = null;
    },
    getStatus(status) {
      if (!status) return;
      return {
        type: status === "ACTIVE" ? "success" : "danger",
        text: status[0].toUpperCase() + status.toLowerCase().slice(1),
      };
    },
    clearPanel() {
      this.$emit("clear");
    },
    editPanel() {
      this.options.find(o => o.name === "edit").method(this.report);
    },
    deletePanel() {
      this.options.find(o => o.name === "delete").method(this.report);
    },
    showHistoryPanel() {
      this.$emit("history", this.report);
    },
    handleReportLinkClick() {
      window.open(this.report.powerBIHref, '_blank');
    },
    handleInstructionClick() {
      this.fileService.getFileToken(this.report.instructionGFID).then((response) => {
        if (response != null) {
          this.fileService
            .downloadFile(response.data)
            .then((fileResponse) => {
              if(!fileResponse)
                return;

              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(fileResponse.data);
              let name = fileResponse.fileName;
              link.download = decodeURI(name).replace("%2b", "+");
              link.click();
            });
        }
      });
    },
    getAccessDots(options) {
      return this.filterAccessDots
        ? this.filterAccessDots(options, this.report)
        : options;
    },
  },
};
</script>

<style lang="scss" scoped>
.prospace-panel-block-list {
  &__row {
    display: flex;
    column-gap: 10px;

    &:last-child {
      border-bottom: none;
    }
    &:not(:first-child) {
      .prospace-panel-block-item {
        padding: 10px 0;
      }
    }
    &:first-child {
      .prospace-panel-block-item {
        padding: 0 0 10px;
      }
    }
  }
}
</style>
