import { inject } from 'vue';
import i18n from '@common/utils/i18n.js';

const useMultiSelectRightPanel = () => {
    const selectedClude = inject('MultiSelectRightPanelSelectedClude')
    const selectedData = inject('MultiSelectRightPanelSelectedData')
    const selectedCount = inject('MultiSelectRightPanelSelectedCount')
    const selectedActionClear = inject('MultiSelectRightPanelClear')
    const selectedActionReset = inject('MultiSelectRightPanelReset')

    const isMultipleView = () => {
        if (typeof selectedCount.value === "number") {
            return selectedCount.value > 1
        } 
        return selectedCount.value === i18n.global.t('allTxtT')
    }
    return {
        selectedClude,
        selectedData,
        selectedCount,
        selectedActionClear,
        isMultipleView,
        selectedActionReset
    }
}

export default useMultiSelectRightPanel
