<template>
  <ProSpaceRightPanel
    class="newsItemPanel"
    :header="header"
    :loading="loading"
    :has-error="hasError"
    @clear="clearPanel"
    @edit="editPanel"
    @delete="deletePanel"
    @show-history="showHistoryPanel"
  >
    <template v-slot:center>
      <ProSpaceTabs v-model="modelTab" :tabs="tabs" @select-tab="handlerSelectTab">
        <template v-slot:tab-1>
          <ProSpacePanelBlock v-if="newsItem" :header="headerOverview">
            <template v-slot>
              <div class="prospace-panel-block-list">
                <div class="prospace-panel-block-list__row">
                  <ProSpacePanelBlockItem
                    class="prospace-panel-block-list__item"
                    :title="$t('type')"
                    :text="newsItem.typeName"
                  />
                </div>
                <div class="prospace-panel-block-list__row">
                  <ProSpacePanelBlockItem
                    class="prospace-panel-block-list__item"
                    :title="$t('newsItemMessage')"
                    :text="newsItem.message"
                    is-html
                  />
                </div>
                <div class="prospace-panel-block-list__row">
                  <ProSpacePanelBlockItem
                    class="prospace-panel-block-list__item"
                    :title="$t('newsItemPublishDate')"
                    :text="getFormatDateTime(newsItem.publishDate)"
                  />
                </div>
                <div class="prospace-panel-block-list__row">
                  <ProSpacePanelBlockItem
                    class="prospace-panel-block-list__item"
                    :title="$t('newsItemAttachment')"
                  >
                    <template #content>
                      <ProSpaceAction
                        v-for="(attachment, i) in successedAttachments"
                        icon-width="14px"
                        icon-height="14px"
                        icon="paperclip"
                        :key="i"
                        :text="attachment.fileName"
                        @click="downloadAttachment(attachment)"
                      />
                    </template>
                  </ProSpacePanelBlockItem>
                </div>
                <div class="prospace-panel-block-list__row">
                  <ProSpacePanelBlockItem
                    class="prospace-panel-block-list__item"
                    :title="$t('tags')"
                  >
                    <template #content>
                      <ProSpaceTagsColumn :tags="tags" :limit="2" />
                    </template>
                  </ProSpacePanelBlockItem>
                </div>
                <div class="prospace-panel-block-list__row"
                  v-if="userinfo.orgLinkType !== 'Mars'
                    && newsItem.readConfirmationRequired
                    && checkStatusAccess('approveTask-ProSpaceVLayout', newsItem)
                    && checkAccess('approveTask-ProSpaceVLayout', newsItem)"
                >
                  <ProSpacePanelBlockItem
                    class="prospace-panel-block-list__item"
                    :title="null"
                  >
                    <template #content>
                      <ProSpaceVLayout
                        :gap="10"
                      >
                        <ProSpaceButton
                          class="taskApproved-ProSpaceButton"
                          type="gray"
                          customIconColor="#27AE60"
                          disabled
                          :hint="$t('newsTaskHint')"
                          :coloredIcon="!newsItem.taskApproved"
                          :icon="newsItem.taskApproved ? 'check' : 'warning'"
                          :label="newsItem.taskApproved ? $t('newsTaskApproved') : $t('newsTaskNotApproved')"
                          style="opacity: 1 !important; font-weight: 500; cursor: default; pointer-events: auto;"
                        />
                        <ProSpaceVLayout
                          v-if="newsItem.taskApproved"
                          align-items="flex-end"
                          justifyContent="flex-start"
                          :gap="5"
                        >
                          <ProSpaceTextBlock
                            class="taskApproved-ProSpaceTextBlock"
                            :bottom="newsItem.taskApprovedBy + '<br />' + formatDate(newsItem.taskApprovedDate)"
                            type="third"
                          />
                        </ProSpaceVLayout>
                      </ProSpaceVLayout>
                    </template>
                  </ProSpacePanelBlockItem>
                </div>
              </div>
            </template>
          </ProSpacePanelBlock>
        </template>
        <template v-slot:tab-2>
          <ProSpaceVLayout :gap="20">
            <ProSpacePanelBlock v-if="isFilial || checkAccess('tab-distributors-ProSpaceTabs', newsItem)" :header="headerDistributors">
              <template v-slot:header>
                <ProSpaceAction
                  v-if="
                    !isFilial &&
                    !newsItem.disabled &&
                    checkStatusAccess(
                      'action-delete-ProSpaceSimpleGrid',
                      newsItem
                    )
                  "
                  :text="`${$t('editItemTxt')}`"
                  @click="showEditDistributorsModal"
                />
              </template>
              <template v-slot>
                <ProSpaceAction
                  v-if="!newsItem.isDistributorStructureRestricted && newsItem.isAllDistributors"
                  :text="`${$t('allTxt')} ${$t('distributors')}`"
                  @click="showEditDistributorsModalReadOnly"
                />
                <ProSpaceSimpleGrid
                  v-if="
                    !newsItem.isDistributorStructureRestricted
                    && !newsItem.isAllDistributors
                    && newsItem.distributorIds
                    && newsItem.distributorIds.length > 0
                  "
                  ref="distributorGrid"
                  getMethod="grid"
                  :headers="distributorGridHeaders"
                  :service="distributorService"
                  :limit="5"
                  :btnText="$t('viewMore')"
                  :defaultModal="false"
                  :defaultFilter="distributorGridFilter"
                  :deletable="
                    !newsItem.disabled &&
                    checkStatusAccess(
                      'action-delete-ProSpaceSimpleGrid',
                      newsItem
                    )
                  "
                  :deleteMethod="null"
                  :confirmDelete="true"
                  confirmIcon="flash-success"
                  :confirmTitle="$t('deletingContactUser')"
                  :confirmText="$t('questionDeleting')"
                  :afterConfirmText="$t('questionDeletingList')"
                  deletingFieldName="name"
                  @delete="deleteDistributor"
                  @openModal="showEditDistributorsModalReadOnly"
                />
                <ProSpaceSimpleGrid
                  v-if="newsItem.isDistributorStructureRestricted"
                  ref="distributorGrid"
                  :items="newsItem.distributorStructures"
                  :headers="distributorStructureHeaders"
                  :bordered="true"
                  :defaultModal="false"
                  :deletable="
                    !newsItem.disabled &&
                    checkStatusAccess(
                      'action-delete-ProSpaceSimpleGrid',
                      newsItem
                    )
                  "
                  :btnText="$t('viewMore')"
                  showViewAll
                  @delete="deleteNewsDistributorStructure"
                  @openModal="showDistrStructureModalReadOnly"
                />
                <DistributorSelectModal
                  v-model="editDistributorModal"
                  :modalTitle="distributorSelectTitle"
                  :selectedIds="newsItem.distributorIds"
                  :needAllQuestion="!readOnlyDistributorsModal"
                  :readOnly="readOnlyDistributorsModal"
                  :selectionMode="readOnlyDistributorsModal ? 'single' : 'multiple'"
                  :isAll="newsItem.isAllDistributors"
                  :defaultFilter="distributorGridFilter"
                  :allSelectionText="$t('isAllDistributorsSelectionNews')"
                  @applyDistributors="applyEditDistributor"
                />
                <DistributorHierarchySelectModal
                  v-if="newsItem.isDistributorStructureRestricted"
                  v-model="visibleDistrStructureModal"
                  expandAll
                  :getMethod="getMethodDistrStructureModal"
                  :readOnly="readOnlyDistrStructureModal"
                  :titleModal="$t('distributorStructure')"
                  :service="distributorStructureService"
                  :checkedRows="newsItem.distributorStructures"
                  @apply="applyDistributorStructure"
                />
              </template>
            </ProSpacePanelBlock>
            <ProSpacePanelBlock v-if="newsItem.isPersonal" :header="headerPositions">
              <template v-slot:header>
                <ProSpaceAction
                  v-if="!newsItem.disabled
                  && checkAccess('position-PositionSelectTree', newsItem)
                  && checkStatusAccess('position-PositionSelectTree', newsItem)
                  && checkEditFieldAccess('positions')
                "
                  :text="`${$t('editItemTxt')}`"
                  @click="showEditPositionsModal"
                />
              </template>
              <template v-slot>
                <PositionSelectTree
                  ref="positionTree"
                  positionGetMethod="getPositions"
                  positionSelectionMode="single"
                  propLabel="distributorOrgName"
                  expandAll
                  selectLastNodes
                  :positionService="newsItemService"
                  :positionFilter="{ disabled: { and: [false] }, newsItemId: newsItem.id }"
                />
                <ProSpaceModal
                  v-model="positionsModal"
                  icon="parameters"
                  ref="positionsModal"
                  width="720"
                  height="420"
                  :title="`${$t('performers')}`"
                  :cancelTxt="$t('closeTxt')"
                  :applyTxt="$t('applyTxt')"
                  @apply="applyEditPositions"
                  @cancel="cancelEditPositions"
                  smallPadding
                >
                  <ProSpaceGeoLayout>
                    <template #center>
                      <PositionSelectTree
                        positionGetMethod="allTree"
                        positionSelectionMode="checkbox"
                        propLabel="name"
                        expandAll
                        :positionService="distributorOrgService"
                        :checkedPositions="positions"
                        @changed="(newPositions) => { this.positions = [...newPositions] }"
                        @selectedItem="selectedHandlerTree"
                      />
                    </template>
                    <template #right>
                      <ProSpaceRightPanel style="border: var(--prospace-ui-border)">
                        <template #center>
                          <ProSpacePanelBlock
                            :header="{ title: $t('parameters'), isExpand: false }"
                          >
                            <ProSpacePanelBlockList :list="getPositionRightPanel" />
                          </ProSpacePanelBlock>
                        </template>
                      </ProSpaceRightPanel>
                    </template>
                  </ProSpaceGeoLayout>
                </ProSpaceModal>
              </template>
            </ProSpacePanelBlock>
          </ProSpaceVLayout>
        </template>
        <template v-slot:tab-3>
          <ProSpacePanelBlock :header="headerRecipients">
            <template v-slot:header>
              <ProSpaceAction
                v-if="
                  !newsItem.disabled
                  && checkAccess('edit-recipients', this.newsItem)
                  && checkStatusAccess(
                    'action-delete-ProSpaceSimpleGrid',
                    newsItem
                  )
                "
                :text="`${$t('editItemTxt')}`"
                @click="showEditRecipientsModal"
              />
            </template>
            <template v-slot>
              <ProSpaceSimpleGrid
                ref="recipientGrid"
                getMethod="simple"
                :headers="recipientGridHeaders"
                :service="recipientService"
                :limit="7"
                :btnText="$t('viewMore')"
                :deletable="
                  !newsItem.disabled
                  && checkAccess('edit-recipients', this.newsItem)
                  && checkStatusAccess(
                    'action-delete-ProSpaceSimpleGrid',
                    newsItem
                  )
                "
                :defaultModal="false"
                :confirmDelete="true"
                confirmIcon="flash-success"
                :confirmTitle="$t('deletingContactUser')"
                :confirmText="$t('questionDeleting')"
                :afterConfirmText="$t('questionDeletingList')"
                deletingFieldName="email"
                @delete="deleteFromFilter"
                @openModal="showRecipientsModal"
              />
              <OrgStructureSelectModal
                ref="filterModal"
                v-model="editRecipientModal"
                node-method="onlyMars"
                @close="applyEditRecipient"
              />
              <ProSpaceModal
                v-model="recipientModal"
                icon="message"
                ref="recipientModal"
                :title="`${$t('notifications')}`"
                :cancelTxt="$t('closeTxt')"
                :applyTxt="null"
                :contentStyle="modalContentStyle"
              >
                <ProSpaceGeoLayout>
                  <template #center>
                    <ProSpaceSimpleGrid
                      ref="recipientGrid"
                      getMethod="simple"
                      :headers="recipientGridModalHeaders"
                      :service="recipientService"
                      :limit="50"
                      :btnText="$t('viewMore')"
                      :deletable="false"
                      :deleteMethod="null"
                    />
                  </template>
                </ProSpaceGeoLayout>
              </ProSpaceModal>
            </template>
          </ProSpacePanelBlock>
        </template>
      </ProSpaceTabs>
    </template>
    <template v-slot:bottom>
      <ProSpacePanelBlock :header="headerBottom">
        <template v-slot>
          <ProSpacePanelBlockList :list="contentBottom" />
        </template>
      </ProSpacePanelBlock>
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceGeoLayout,
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceAction,
  ProSpacePanelBlockItem,
  ProSpaceTagsColumn,
  ProSpaceSimpleGrid,
  ProSpaceModal,
  ProSpaceVLayout,
  ProSpaceTextBlock,
  ProSpaceButton
} from "prospace-components-library";
import OrgStructureSelectModal from "@modals/OrgStructureSelectModal";
import DistributorSelectModal from "@modals/DistributorSelectModal";
import PositionSelectTree from "@trees/PositionSelectTree";
import DistributorHierarchySelectModal from "@modals/DistributorHierarchySelectModal";
import statusAccessMixin from "../../../../../../Frontends/Common/utils/elementAccess/statusAccessMixin";
import editFieldMixin from "../../../../../../Frontends/Common/utils/elementAccess/editFieldMixin";
import { NewsItemService } from "../../../services/NewsItemService";
import { NewsItemRecipientService } from "../../../services/NewsItemRecipientService";
import { DistributorService } from "@masterdata/services/DistributorService.js";
import { DistributorOrgService } from "@masterdata/services/DistributorOrgService";
import { DistributorStructureService } from "@masterdata/services/DistributorStructureService.js";
import { FileService } from "../../../../../../Frontends/ProSpace/services/FileService.js";
import { mapGetters } from "vuex";
import { getAMorPM } from "prospace-components-library/src/lib-assets/mixins/date";
import { useStatistic } from "@composes"
const _ = require("lodash");
import {
  useMultiSelectRightPanel,
} from "@composes/multiselect"
export default {
  mixins: [statusAccessMixin, editFieldMixin],
  name: "NewsItemPanel",
  components: {
    ProSpaceGeoLayout,
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceAction,
    ProSpacePanelBlockItem,
    ProSpaceTagsColumn,
    ProSpaceSimpleGrid,
    ProSpaceModal,
    OrgStructureSelectModal,
    DistributorSelectModal,
    DistributorSelectModal,
    PositionSelectTree,
    ProSpaceVLayout,
    ProSpaceTextBlock,
    ProSpaceButton,
    DistributorHierarchySelectModal
  },
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    filterAccessDots: {
      type: Function,
    },
    checkAccess: {
      type: Function,
    },
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    const { selectedData } = useMultiSelectRightPanel()
    return {
      setStatistic, distActions,
      selectedData
    }
  },
  data() {
    let recipientGridStatusConfig = {
      "Distributor user": "NONE",
      "Distributor manager": "NONE",
      "Mars user": "NONE",
      "Mars manager": "NONE",
      "Content manager": {
        newsDraft: "CREATOR_OR_NONE",
        other: "NONE",
      },
      "Support administrator": {
        other: "ALIVE",
      },
      "Technical Support": {
        other: "ALIVE",
      },
      "Content manager plus": {
        newsDraft: "ALIVE",
        newsModer: "ALIVE",
        other: "NONE",
      },
    };

    let approveTaskStatusConfig = {
      "Distributor user": {
        newsPublished: "ALIVE",
        other: "NONE",
      },
      "Distributor manager": {
        newsPublished: "ALIVE",
        other: "NONE",
      },
      "Mars user": "NONE",
      "Mars manager": "NONE",
      "Content manager": "NONE",
      "Support administrator": "NONE",
      "Technical Support": "NONE",
      "Content manager plus": "NONE",
    };

    return {
      newsItem: {},
      editFieldModel: {},
      selectedOrgPosition: null,
      modelTab: {
        name: this.$t("parameters"),
        isActive: true,
        systemName: "parameters",
      },
      newsItemService: new NewsItemService(),
      recipientService: new NewsItemRecipientService(),
      distributorService: new DistributorService(),
      distributorOrgService: new DistributorOrgService(),
      distributorStructureService: new DistributorStructureService(),
      fileService: new FileService(),
      loading: false,
      hasError: false,
      recipientModal: false,
      positionsModal: false,
      editRecipientModal: false,
      editDistributorModal: false,
      readOnlyDistributorsModal: false,
      visibleDistrStructureModal: false,
      readOnlyDistrStructureModal: true,
      distributorSelectTitle: "",
      getMethodDistrStructureModal: "getNewsViewTree",
      persistRecipients: true,
      positions: [],
      nodeFilter: {
        add: {},
        remove: {},
      },
      gridFilter: {
        add: {},
        remove: {},
      },
      oDataStringFilter: "",
      selectedTreeNodes: {},
      recipientGridHeaders: [
        {
          name: this.$t("email"),
          value: "email",
          f: (item) => item,
        },
      ],
      distributorGridHeaders: [
        {
          name: this.$t("code"),
          value: "code",
          width: 90,
          f: (item) => item,
        },
        {
          name: this.$t("name"),
          value: "name",
          f: (item) => item,
        },
      ],
      distributorStructureHeaders: [
        { name: this.$t("distributor"), value: "distributorStructureViewDistributorName" },
        { name: this.$t("branch"), value: "distributorStructureViewName" },
      ],
      recipientGridModalHeaders: [
        { name: this.$t("type"), value: "recordTypeLocale" },
        {
          name: this.$t("name"),
          value: "name",
          f: (item) => item,
        },
        {
          name: this.$t("email"),
          value: "email",
          f: (item) => item,
        },
      ],
      distributorGridModalHeaders: [
        {
          name: this.$t("code"),
          value: "code",
          f: (item) => item,
        },
        {
          name: this.$t("name"),
          value: "name",
          f: (item) => item,
        },
      ],
      modalContentStyle: {
        overflow: "hidden",
        height: "calc(95vh - 180px)",
        width: "95vw",
        minWidth: "1210px",
      },
      statusAccessConfig: {
        "action-delete-ProSpaceSimpleGrid": recipientGridStatusConfig,
        "addRecipient-button": recipientGridStatusConfig,
        "position-PositionSelectTree": recipientGridStatusConfig,
        "approveTask-ProSpaceVLayout": approveTaskStatusConfig,
      },
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime",
    }), 
    isFilial() {
      return this.newsItem.isDistributorStructureRestricted && !this.checkAccess("isFilial-Expression", this.newsItem);
    },
    successedAttachments() {
      return this.attachments.filter(x => x.statusFlag === 0);
    },
    recipientGridFilter() {
      if (this.persistRecipients)
        return `$filter=NewsItemId eq ${Number(
          this.newsItem.id
        )} and disabled eq false`;
      return this.oDataStringFilter;
    },
    distributorGridFilter() {
      return !this.newsItem.isAllDistributors ? { id: { or: this.newsItem.distributorIds } } : { disabled: { and: [false] } };
    },
    headerOverview() {
      return {
        title: this.$t("newsItem"),
        isExpand: true,
      };
    },
    headerDistributors() {
      return {
        title: this.newsItem.isDistributorStructureRestricted ? this.$t('branches') : this.$t("distributors"),
        isExpand: true,
      };
    },
    headerRecipients() {
      return {
        title: this.$t("recipients"),
        isExpand: true,
      };
    },
    headerPositions() {
      return {
        title: this.$t("performers"),
        isExpand: true,
      };
    },
    headerBottom() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    tabs() {
      if (!this.newsItem) return [];
      const tabs = [
        {
          name: this.$t("parameters"),
          systemName: "parameters",
          isActive: this.modelTab.systemName === "parameters",
        },
        {
          name: this.$t("recipients"),
          systemName: "recipients",
          isHidden: !this.isFilial && !(this.newsItem.isPersonal || this.checkAccess("tab-distributors-ProSpaceTabs", this.newsItem)),
          isActive: this.modelTab.systemName === "recipients",
        },
        {
          name: this.$t("notifications"),
          systemName: "notifications",
          isHidden: !this.checkAccess("tab-notifications-ProSpaceTabs", this.newsItem),
          isActive: this.modelTab.systemName === "notifications",
        },
      ];
      if (!tabs.some(i => i.isActive)) {
        tabs[0].isActive = true
        this.modelTab = tabs[0]
      }
      return tabs
    },
    tags() {
      return this.newsItem.tags ? this.newsItem.tags.split("#") : [];
    },
    attachments() {
      return this.newsItem.attachments ?? [];
    },
    header() {
      if (this.newsItem) {
        return {
          title: this.newsItem.name,
          id: this.newsItem.typeName,
          icon: this.getImportant(this.newsItem.highImportant),
          status: this.getStatus(this.newsItem.status),
          options: this.getAccessDots(this.options),
          iconColored: true,
          iconHint: this.$t("exclamationHint"),
          showCounter: false,
        };
      }
      return {
        title: this.$t("itemsSelected"),
      };
    },
    getPositionRightPanel() {
      if (this.selectedOrgPosition) {
        const pos = [
          {
            title: this.$t("position"),
            text: this.selectedOrgPosition.name,
          },
        ];
        const parentPos = [
          {
            title: this.$t("parentPosition"),
            text: this.selectedOrgPosition.parentName,
          },
        ];
        return this.selectedOrgPosition.parentName ? [parentPos, pos] : [pos];
      }
      return [];
    },
    contentBottom() {
      let metaInfo = [];
      if (this.newsItem) {
        if (this.checkAccess("metainfo-by-block"))
          metaInfo.push([
            {
              title: this.$t("createdByTxt"),
              text: this.newsItem.createdBy,
            },
            {
              title: this.$t("updatedByTxt"),
              text: this.newsItem.updatedBy,
            },
          ]);

        metaInfo.push([
          {
            title: this.$t("createdAtTxt"),
            text: this.getFormatDateTime(this.newsItem.createdAt) + ' ' + getAMorPM(this.newsItem.createdAt, this.userinfo.timeFormat),
          },
          {
            title: this.$t("lastUpdatedTxt"),
            text: this.getFormatDateTime(this.newsItem.lastUpdated) + ' ' + getAMorPM(this.newsItem.lastUpdated, this.userinfo.timeFormat),
          },
        ]);
      }
      return metaInfo;
    },
  },
  mounted() {
    this.update();
  },
  watch: {
    selectedData: {
      deep: true,
      handler() {
        this.update();  
      }
    },
  },
  methods: {
    handlerSelectTab({ selectedTab }) {
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: selectedTab.systemName
      })
    },
    selectedHandlerTree(item) {
      this.selectedOrgPosition = item;
    },
    getAccessDots(options) {
      return this.filterAccessDots
        ? this.filterAccessDots(options, this.newsItem)
        : options;
    },
    update() {
      this.hasError = false;
      try {
        this.getNewsItem();
      } catch (e) {
        this.hasError = true;
      }
    },
    showRecipientsModal() {
      this.recipientModal = true;
    },
    showEditRecipientsModal() {
      this.updateSelectedTreeNodes();
      this.$refs.filterModal.nodeFilter = { ...this.nodeFilter };
      this.$refs.filterModal.gridFilter = { ...this.gridFilter };
      this.$refs.filterModal.selectedTreeNodes = { ...this.selectedTreeNodes };
      this.$nextTick(() => {
        this.editRecipientModal = true;
      });
    },
    updateSelectedTreeNodes() {
      if (
        Object.keys(this.nodeFilter.add).length === 0 &&
        Object.keys(this.gridFilter.add).length === 0
      ) {
        if (this.selectedTreeNodes) {
          delete this.selectedTreeNodes["all"];
        }
        return;
      }
      const fullRecords = this.$refs.recipientGrid.records;
      const listTreeNodes = {
        all: {
          checked: false,
          partialChecked: true,
        },
      };

      fullRecords.forEach((r) => {
        const keys = r.fullPath.split("|");
        keys.forEach((key, index, arr) => {
          if (!listTreeNodes[key]) {
            listTreeNodes[key] = {
              checked: false,
              partialChecked: true,
              parentKey: index > 0 ? arr[--index] : "all",
            };
          }
        });
      });
      Object.values(this.nodeFilter.add).forEach((n) => {
        const keys = n.path.split("|");
        keys.forEach((key, index, arr) => {
          if (!listTreeNodes[key]) {
            listTreeNodes[key] = {
              checked: false,
              partialChecked: true,
              parentKey: index > 0 ? arr[--index] : "all",
            };
          }
        });
      });
      // check=true all nodeFilter.add and children
      // баг если дочерние check, parent uncheck, см свой чат
      // listTreeNodes добавлять из через this.nodeFilter.add (нет некоторых узлов)
      const checkChildren = (parentKey) => {
        const children = Object.entries(listTreeNodes)
          .filter(([k, v]) => v.parentKey === parentKey)
          .map(([k]) => k);
        if (children.length === 0) {
          return;
        }
        children.forEach((k) => {
          checkChildren(k);
          listTreeNodes[k] = {
            checked: true,
            partialChecked: false,
            parentKey: parentKey,
          };
        });
        return;
      };
      Object.entries(this.nodeFilter.add).forEach(([key, value]) => {
        listTreeNodes[key] = {
          checked: true,
          partialChecked: false,
          parentKey: value.parentId,
        };
        checkChildren(key);
      });

      // partialChecked=true if parent has removed child
      const removesParentKeys = [];
      Object.values(this.gridFilter.remove).forEach((val) => {
        const arr = val.fullPath.split("|");
        const parentKey = arr[arr.length - 2];
        if (!removesParentKeys.includes(parentKey)) {
          removesParentKeys.push(parentKey);
        }
      });
      const uncheckParent = (childKey) => {
        const child = listTreeNodes[childKey];
        if (!child) {
          return;
        }
        if (child.checked) {
          child.checked = false;
          child.partialChecked = true;
        }
        const parent = listTreeNodes[child.parentKey];
        if (!parent) {
          return;
        }
        if (parent.checked) {
          parent.checked = false;
          parent.partialChecked = true;
          uncheckParent(parent.parentKey);
          return;
        }
        return;
      };
      removesParentKeys.forEach(uncheckParent);

      this.selectedTreeNodes = listTreeNodes;
    },
    showEditPositionsModal() {
      this.positionsModal = true;
    },
    showEditDistributorsModal() {
      if(this.newsItem.isDistributorStructureRestricted) {
        this.readOnlyDistrStructureModal = false;
        this.getMethodDistrStructureModal = "allTree";
        this.$nextTick(() => {
          this.visibleDistrStructureModal = true;
        })
      } else {
        this.distributorSelectTitle = this.$t('selectDistributors');
        this.readOnlyDistributorsModal = false;
        this.$nextTick(() => {
          this.editDistributorModal = true;
        })
      }
    },
    showEditDistributorsModalReadOnly() {
      this.distributorSelectTitle = this.$t('listDistributors');
      this.readOnlyDistributorsModal = true;
      this.$nextTick(() => {
        this.editDistributorModal = true;
      })
    },
    showDistrStructureModalReadOnly() {
      this.readOnlyDistrStructureModal = true;
      this.getMethodDistrStructureModal = "getNewsViewTree";
      this.$nextTick(() => {
        this.visibleDistrStructureModal = true;
      })
    },
    applyEditRecipient() {
      this.nodeFilter = { ...this.$refs.filterModal.nodeFilter };
      this.gridFilter = { ...this.$refs.filterModal.gridFilter };
      this.selectedTreeNodes = {
        ...this.$refs.filterModal.$refs.tree.checkedNodes,
      };
      this.oDataStringFilter = this.convertFilterToOdata();
      Object.values(this.nodeFilter).forEach((o) => delete o.children);
      this.newsItem.filter = JSON.stringify({
        nodeFilter: this.nodeFilter,
        gridFilter: this.gridFilter,
      });
      this.newsItem.nodeSelection = JSON.stringify(this.selectedTreeNodes);
      this.newsItem.oDataFilter = this.oDataStringFilter;
      this.persistRecipients = false;
      this.recipientService.filter = this.recipientGridFilter;
      this.$refs.recipientGrid.records = [];
      this.feelNeedFilter();
      if (this.newsItem.needFilter) this.$refs.recipientGrid.getData();
      this.editRecipientModal = false;
      this.saveModel();
    },
    async applyEditDistributor({items, requestModel}, isAll) {
      this.newsItem.isAllDistributors = isAll;
      if (!isAll) {
        this.newsItem.distributorRequestModel = requestModel;
        if (requestModel && requestModel.filter) {
          let distributors = await this.distributorService.getByMultiple(requestModel);
          this.newsItem.distributorIds = distributors.data.records.map(x => x.id);
        } else {
          this.newsItem.distributorIds = Array.isArray(items) ? items : [];
        }
      }
      this.saveModel();
    },
    applyDistributorStructure(units) {
      this.newsItem.distributorStructures = units.map(x => {
        return {
          distributorStructureViewId: x.distributorStructureViewId,
          distributorStructureViewName: x.distributorStructureViewName || "",
          distributorStructureViewCode: x.distributorStructureViewCode,
          distributorStructureViewType: x.distributorStructureViewType,
          distributorStructureViewDistributorId: x.distributorStructureViewDistributorId,
          distributorStructureViewDistributorName: x.distributorStructureViewDistributorName
        }
      });
      this.saveModel();
    },
    downloadAttachment(attachment) {
      const gfid = attachment.gfid;

      this.fileService.getFileToken(gfid).then((response) => {
        if (response != null) {
          this.fileService
            .downloadFile(response.data)
            .then((fileResponse) => {
              if(!fileResponse)
                return;

              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(fileResponse.data);
              let name = fileResponse.fileName;
              link.download = decodeURI(name).replace("%2b", "+");
              link.click();
            });
        }
      });
    },
    deleteNewsDistributorStructure(newsDistrStructure) {
      this.newsItem.distributorStructures = this.newsItem.distributorStructures.filter(x => x.id !== newsDistrStructure.id);
      this.saveModel();
    },
    deleteFromFilter(item) {
      this.$refs.recipientGrid.records =
        this.$refs.recipientGrid.records.filter(
          (r) => !(r.id === item.id && r.recordType === item.recordType)
        );
      if (this.$refs.recipientGrid.records.length === 0) {
        this.nodeFilter = {
          add: {},
          remove: {},
        };
        this.gridFilter = {
          add: {},
          remove: {},
        };
      } else {
        let key = `${item.id}|${item.recordType}`;
        if (Object.keys(this.gridFilter.add).includes(key)) {
          delete this.gridFilter.add[key];
        } else {
          this.gridFilter.remove[key] = item;
        }
      }
      this.oDataStringFilter = this.convertFilterToOdata();
      this.newsItem.oDataFilter = this.oDataStringFilter;
      this.newsItem.filter = JSON.stringify({
        nodeFilter: this.nodeFilter,
        gridFilter: this.gridFilter,
      });
      this.persistRecipients = false;
      this.recipientService.filter = this.recipientGridFilter;
      this.feelNeedFilter();
      this.saveModel();
    },
    feelNeedFilter() {
      this.newsItem.needFilter =
        Object.keys(this.nodeFilter.add).length > 0 ||
        Object.keys(this.nodeFilter.remove).length > 0 ||
        Object.keys(this.gridFilter.add).length > 0 ||
        Object.keys(this.gridFilter.remove).length > 0;
    },
    convertFilterToOdata() {
      // ( ((orgId in included nodes) && (orgId not in excluded nodes)) || (userid in included users) ) && (userId not in excluded users)
      const nodeAdd = Object.values(this.nodeFilter.add);
      const usersAdd = Object.values(this.gridFilter.add);
      const nodeRemove = Object.values(this.nodeFilter.remove);
      const usersRemove = Object.values(this.gridFilter.remove);
      let nodeAddStrings = "";
      let usersAddStrings = usersAdd
        .map((e) => `(recordTypeId eq '${e.recordType + "-" + e.id}')`)
        .join(" or ");
      let usersRemoveStrings = usersRemove
        .map((e) => `(recordTypeId ne '${e.recordType + "-" + e.id}')`)
        .join(" and ");
      let getIdFilter = (n, o) => {
        if (n.type === "RealDistributor")
          return `and distributorId ${o} ${n.id}`;
        if ([9223372036854775000, -9223372036854775000].includes(n.id))
          return `and orgId ${o} null`;
        return `and orgId ${o} ${n.id}`;
      };
      let nodeRemoveStrings = nodeRemove
        .map((e) => `(type eq '${e.type}' ${getIdFilter(e, "ne")})`)
        .join(" and ");
      if (!nodeAdd.find((e) => e.key === "all"))
        nodeAddStrings = nodeAdd
          .map((e) => `(type eq '${e.realType}' ${getIdFilter(e, "eq")})`)
          .join(" or ");

      let filter = "";
      if (nodeAddStrings || nodeRemoveStrings)
        filter =
          "(" +
          [nodeAddStrings, nodeRemoveStrings]
            .filter((e) => e)
            .map((e) => `(${e})`)
            .join(" and ") +
          ")";

      if (usersAddStrings) {
        if (filter) filter = `${filter} or `;
        filter = `${filter} (${usersAddStrings})`;
      }

      if (usersRemoveStrings) {
        if (filter) filter = `(${filter}) and `;
        filter = `${filter} (${usersRemoveStrings})`;
      }
      if (filter) filter = "$filter=" + filter;
      return filter;
    },
    applyEditPositions() {
      this.positions.forEach(x => {
        x["newsItemId"] = this.newsItem.id;
        x["leaf"] = !this.positions.some(p => p.parentId === x.distributorOrgId);
      })
      this.newsItem.positions = [...this.positions];
      this.saveModel();
    },
    cancelEditPositions() {
      this.positions = [...this.newsItem.positions] || [];
    },
    saveModel() {
      this.newsItemService.update(this.newsItem).then((result) => {
        this.$emit("refresh");
      });
    },
    deleteDistributor(item) {
      this.$refs.distributorGrid.records =
        this.$refs.distributorGrid.records.filter((r) => r.id !== item.id);

      this.newsItem.distributorIds = this.newsItem.distributorIds.filter(
        (r) => r !== item.id
      );

      this.saveModel();
    },
    getNewsItem() {
      if (this.selectedData) {
        this.loading = true;
        this.$store.dispatch("notifications/updateReadedRecord", {
          title: "News",
          id: Number(this.selectedData.id),
        });
        this.newsItemService.get(this.selectedData.id).then((res) => {
          this.newsItem = res.data;
          this.newsItemService.newsItemId = this.newsItem.id;
          this.positions = [...this.newsItem.positions || []];
          this.distributorStructureService.setNewsId(this.newsItem.id);
          try {
            if (this.newsItem.editFieldsSetting) {
              this.editFieldModel = JSON.parse(this.newsItem.editFieldsSetting);
            }
          } catch {}

          if (!this.newsItem) return;
          this.$store.dispatch("notifications/updateReadedRecord", {
            title: "News",
            id: "newsPublishStaticId",
            realId: Number(this.selectedData.id),
            type: this.newsItem.type,
            groupUpdate: true,
          });
          this.$store.dispatch("notifications/updateReadedRecord", {
            title: "News",
            id: "newsNewStaticId",
            realId: Number(this.selectedData.id),
            type: this.newsItem.type,
            groupUpdate: true,
          });
          let filter = JSON.parse(this.newsItem.filter);
          if (filter) {
            this.nodeFilter = filter.nodeFilter;
            this.gridFilter = filter.gridFilter;
          }
          this.feelNeedFilter();

          this.$nextTick(() => {
            if (this.$refs.recipientGrid) this.$refs.recipientGrid.getData();
            if (this.$refs.distributorGrid) this.$refs.distributorGrid.getData();
            if (this.$refs.positionTree) this.$refs.positionTree.refresh();
          });

          this.persistRecipients = true;
          this.recipientService.filter = this.recipientGridFilter;

          this.loading = false;
        });
      }
    },
    getStatus(status) {
      if (!status) return;
      return this.newsItemService.getStatusByValue(status);
    },
    getImportant(important) {
      if (!important) return;
      return "circle-exclamation";
    },
    clearPanel() {
      this.$emit("clear");
    },
    editPanel() {
      this.$emit("edit", this.newsItem);
    },
    deletePanel() {
      this.$emit("delete", this.newsItem);
    },
    showHistoryPanel() {
      this.$emit("history", this.newsItem);
    },
    formatDate(date) {
      return (this.getFormatDateTime(date) + ' ' + getAMorPM(date, this.userinfo.timeFormat)).trim()
    }
  },
};
</script>

<style lang="scss">
.newsItemPanel {
  .prospace-panel-block-list {
    &__row {
      display: flex;
      column-gap: 10px;
      //border-bottom: 1px dashed var(--libBorder);

      &:last-child {
        border-bottom: none;
      }
      &:not(:first-child) {
        .prospace-panel-block-item {
          padding: 10px 0;
        }
      }
      &:first-child {
        .prospace-panel-block-item {
          padding: 0 0 10px;
        }
      }
    }
  }
  .taskApproved-ProSpaceButton {
    width: 100%;
    span {
      font-size: 13px;
      text-wrap: balance;
      text-align: start;
      display: block;
      margin-left: 10px;
      white-space: pre; // в тексте перевода должен быть перенос строки ('\n'), чтобы это свойство работало.
    }
  }
  .taskApproved-ProSpaceTextBlock {
    .prospace-text-block__bottom {
      text-align: end;
    }
  }
}
</style>
