import sha256 from 'crypto-js/sha256';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';

// такой же ключ на беке
const privateKey = "Gfs3Tb4i7hl0GI0v8NXNObo2LoNiWogh";

export function hashPassword(password) {
    const hashDigest = sha256(password);
    return Base64.stringify(hmacSHA512(hashDigest, privateKey));
}
