import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/MasterData/MarsDistributorOrgUsersViewApi";

export class MarsDistributorOrgUsersViewService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(this.Api, "editMarsDistributorOrgUsersView", fields);
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editMarsDistributorOrgUsersView");
  }
  async grid(args) {
    const res = await this.transformer.getGrid(this.Api, "getMarsDistributorOrgUsersViews", args);
    res.data.records = res.data.records.map(i => {
      i.unicId = `${i.distributorId}-${i.id}-${i.recordType}`
      return i
    })
    return res
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getMarsDistributorOrgUsersView", null, id);
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createMarsDistributorOrgUsersView", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteMarsDistributorOrgUsersView", model);
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "editMarsDistributorOrgUsersView", model);
  }
}
