import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/NewsService/NewsItemUserImportantApi";

export class NewsItemUserImportantService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(this.Api, "editNewsItemUserImportant", fields);
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editNewsItemUserImportant");
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getNewsItemUserImportants", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getNewsItemUserImportant", null, id);
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createNewsItemUserImportant", model);
  }
  async createMany(model) {
    return await this.transformer.createItem(this.Api, "createNewsItemUserImportants", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteNewsItemUserImportant", model);
  }
  async deleteMany(model) {
    return await this.transformer.deleteItem(this.Api, "deleteNewsItemUserImportants", model);
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "editNewsItemUserImportant", model);
  }
}
