import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/taskservice/taskassignment/";

export default class TaskAssignmentApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getTaskAssignments(params) {
    return await request.post(`${rootRoute}getTaskAssignments`, {
      data: params.assignOptions,
      filter: params.args,
      role: params.role,
      parameters: {},
    });
  }
  async getTaskAssignment(id) {
    return await request.post(`${rootRoute}getTaskAssignments`, {
      filter: `$filter=id eq ${id}`,
      parameters: {},
    });
  }
  async getTaskAssignmentFull(data) {
    return await request.post(`${rootRoute}gettaskassignmentsfull`, {
      filter: `$filter=id eq ${data.id}`,
      role: data.role,
      parameters: {},
    });
  }
  async createTaskAssignment(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createTaskAssignment`, { data });
  }
  async deleteTaskAssignment(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteTaskAssignment`, { data });
  }
  async deleteTaskAssignmentSeries(model) {
    const data = [model];
    return await request.post(`${rootRoute}deletetaskassignmentseries`, { data });
  }
  async editTaskAssignment(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateTaskAssignment`, { data });
  }
  async editTaskAssignmentSeries(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updatetaskassignmentseries`, { data });
  }
  async getDistributors(filter) {
    return await request.post(`${rootRoute}getTaskDistributors`, {
      filter: filter,
      parameters: {},
    });
  }
  async nextStep(model) {
    const data = [model];
    return await request.post(`${rootRoute}nextStep`, { data });
  }
  async rejectStep(model) {
    const data = [model];
    return await request.post(`${rootRoute}rejectStep`, { data });
  }
  async validateUsersExists(model) {
    const data = [model];
    return await request.post(`${rootRoute}validateUsersExists`, { data });
  }
  async validateAuthorizedPersonsExists(model) {
    const data = [model];
    return await request.post(`${rootRoute}validateAuthorizedPersonsExists`, { data });
  }
  async addComment(model) {
    const data = [model];
    return await request.post(`${rootRoute}addComment`, { data });
  }
  async export(filter, role, format, assignOptions) {
    return await request.post(`${rootRoute}export`, {
      data: assignOptions,
      filter: filter,
      role: role,
      format: format
    });
  }
  async getPositions(filter) {
    return await request.post(`${rootRoute}gettaskpositions`, {
      filter: filter,
      parameters: {},
    });
  }
}
