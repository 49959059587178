import request from "../Frontends/Common/utils/request/requestMain";
const rootRoute = "securityadmin/methadata/";

export default class RestrictionApi {
  async getModelhierarchy(params) {
    return await request.post(`${rootRoute}getmodelhierarchy`, {
        data: { filter: params, parameters: {} },
    });
  }
}
