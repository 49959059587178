import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/NewsService/NewsItemTemplateAttachmentApi";

export class NewsItemTemplateAttachmentService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(this.Api, "editNewsItemTemplateAttachment", fields);
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editNewsItemTemplateAttachment");
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getNewsItemTemplateAttachments", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getNewsItemTemplateAttachment", null, id);
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createNewsItemTemplateAttachment", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteNewsItemTemplateAttachment", model);
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "editNewsItemTemplateAttachment", model);
  }
  async acceptFiles(gfids, newsItemTemplateId) {
    return this.setStatus(gfids, newsItemTemplateId, 0);
  }
  async errorFiles(gfids, newsItemTemplateId) {
    return await this.setStatus(gfids, newsItemTemplateId, 255);
  }
  async setStatus(gfids, newsItemTemplateId, status) {
    var models = gfids.map(x => {
      return {
        id: 0,
        fileName: "",
        gfid: x,
        newsItemTemplateId: newsItemTemplateId,
        statusFlag: status,
        disabled: false
      }
    })
    return await this.transformer.editItem(this.Api, "setStatusNewsItemTemplateAttachment", models);
  }
}
