import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/UserApi";
import store from "../../../../Frontends/Common/store";

export class UserService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(this.Api, "editUser", fields);
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editUser");
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getUsers", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getUser", null, id);
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createUser", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteUser", { data: model });
  }
  async edit(model) {
    return await this.transformer.editItem(this.Api, "editUser", model);
  }
  async localGet(id) {
    return await this.transformer.localGetById(id);
  }
  async export(filter, format) {
    const res = this.Api.export(filter, format);
    await store.dispatch('notifications/handleResponse', {
      promiseResponse: res,
    })
    return res;
  }
  async changePassword(oldPassword, newPassword) {
    return await this.transformer.editItem(this.Api, "changePassword", {
      oldPassword,
      newPassword,
    });
  }
  async resetPasswordBackground(data) {
    const res = this.Api.resetPasswordBackground({
      data: data,
    });
    await store.dispatch('notifications/handleResponse', {
      promiseResponse: res,
    })
  }
  async resetPassword(data) {
    return await this.transformer.editItem(this.Api, "resetPassword", {
      data: data,
    });
  }
  async updateLocale(locale, userId) {
    return await this.transformer.editItem(this.Api, "updateLocale", {
      locale,
      userId,
    });
  }
  async updateDateTimeFormats(data) {
    return await this.transformer.editItem(this.Api, "updateDateTimeFormats", data);
  }
}
