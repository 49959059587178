import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/UserApi";

export class UserService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getUsers", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getUser", null, id);
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createUser", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteUser", model);
  }
  async edit(model) {
    return await this.transformer.editItem(this.Api, "editUser", model);
  }
  async localGet(model) {
    return await this.transformer.localGetById(model.id);
  }
}
