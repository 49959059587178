import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/ReserveService/ReserveProductApi";

export class ReserveProductService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getReserveProducts", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getReserveProduct", null, id);
  }
}
