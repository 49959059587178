import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/masterdata/marsdistributororgview/";

export default class MarsDistributorOrgViewApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getMarsDistributorOrgViews(params) {
    return await request.post(`${rootRoute}getMarsDistributorOrgViews`, {
      filter: params,
      parameters: {},
    });
  }
  async getMarsDistributorOrgCountViews(params) {
    return await request.post(`${rootRoute}getMarsDistributorOrgCountViews`, {
      filter: params,
      parameters: {},
    });
  }
  async getMarsDistributorOrgView(id) {
    return await request.post(`${rootRoute}getMarsDistributorOrgViews`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createMarsDistributorOrgView(model) {
    const data = [_.merge(this.defaultModel, model)];
    return await request.post(`${rootRoute}createMarsDistributorOrgView`, { data });
  }
  async deleteMarsDistributorOrgView(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteMarsDistributorOrgView`, { data });
  }
  async editMarsDistributorOrgView(model) {
    const data = [_.merge(this.defaultModel, model)];
    return await request.post(`${rootRoute}updateMarsDistributorOrgView`, { data });
  }
}
