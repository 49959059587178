import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/notification/notificationlog/";

export default class NotificationLogApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getNotificationLogs(params) {
    return await request.post(`${rootRoute}getNotificationLogs`, {
      filter: params,
      parameters: {},
    });
  }
  async getNotificationLog(id) {
    return await request.post(`${rootRoute}getNotificationLogs`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async repeat(data) {
      return await request.post(`${rootRoute}repeat`, { data });
  }
  
}
