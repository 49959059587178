<template>
  <ProSpaceRightPanel
    :header="header"
    :loading="loading"
    :has-error="hasError"
    @taskCreate="() => handleDotAction('taskCreate')"
    @edit="() => handleDotAction('edit')"
    @copy="() => handleDotAction('copy')"
    @showHistory="() => handleDotAction('showHistory')"
  >
    <template v-slot:center>
      <ProSpaceTabs v-model="modelTab" :tabs="tabs" @select-tab="handlerSelectTab">
        <template v-slot:tab-1>
          <ProSpacePanelBlock v-if="competence" :header="headerMainInformation">
            <ProSpacePanelBlockList :list="mainInformationContent" :itemsPerPage="10"/>
          </ProSpacePanelBlock>
        </template>
        <template v-slot:tab-2>
          <ProSpaceVLayout :gap="20">
            <ProSpacePanelBlock v-if="competence" :header="headerAdditionalInfo">
              <ProSpacePanelBlockList :list="additionaInfoContent" :itemsPerPage="10"/>
            </ProSpacePanelBlock>
          </ProSpaceVLayout>
        </template>
        <template v-slot:tab-3>
          <ProSpaceVLayout :gap="20">
            <ProSpaceTimeLineCondition
              :elems="competence.comments"
              :commentTile="$t('comment')"
              :title="$t('comments')"
              :titleAction="$t('addComment')"
              :dateTimeFormatter="formatTimelineDate"
              :showMarker="false"
              @add="showReasonModal(addComment, $t('addComment'))">
              <template #empty>
                <ProSpaceEmptyGrid v-if="!competence.comments || competence.comments.length == 0"
                   icon="error-message"
                   :title="$t('noDataTitle')"
                   :text="$t('noDataTxt')"
                  />
                </template>
            </ProSpaceTimeLineCondition>
          </ProSpaceVLayout>
          <ProSpaceModal :key="`validate-modal-comment-${modalShow}`"
              v-model="modalShow"
              icon="plus"
              :title="modalTitle"
              :width="530"
              :cancelTxt="$t('cancelInf')"
              :applyTxt="$t('send')"
              :validate-local="$t"
              :validate-model="modalModel"
              :validate-config="{
                comment: {
                  required
                }
              }"
              @apply="saveComment"
              @cancel="cancelComment"
            >
            <template #default="{ v }">
              <ProSpaceVLayout :gap="20">
                <ProSpaceInputText
                  :label="$t('comment')"
                  v-model="modalModel.comment"
                  :placeholder="$t('comment')"
                  :isTextarea="true"
                  :invalid="v.comment.$error"
                  :invalid-messages="v.comment.$errors"
                  @blur="v.comment.$touch"
                />
              </ProSpaceVLayout>
            </template>
          </ProSpaceModal>
        </template>
      </ProSpaceTabs>
    </template>
    <template v-slot:bottom>
      <ProSpacePanelBlock v-if="modelTab.systemName !== 'comments'" :header="headerMetaInfo">
        <template v-slot>
          <ProSpacePanelBlockList :list="metaInfoContent" />
        </template>
      </ProSpacePanelBlock>
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpaceModal,
  ProSpaceVLayout,
  ProSpaceInputText,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceTimeLineCondition,
  ProSpaceAction,
  ProSpaceEmptyGrid
} from "prospace-components-library";
import { required, helpers } from "@vuelidate/validators";
import { CompetenceService } from "../../../services/CompetenceService";
import { CompetenceCommentService } from "../../../services/CompetenceCommentService.js"
import { mapGetters } from "vuex";
import { useStatistic } from "@composes"
import { useMultiSelectRightPanel } from "@composes/multiselect"
import { getAMorPM } from "prospace-components-library/src/lib-assets/mixins/date";
import moment from 'moment';
const _ = require("lodash");
export default {
  name: "CompetencePanel",
  components: {
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpaceModal,
    ProSpaceVLayout,
    ProSpaceInputText,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceTimeLineCondition,
    ProSpaceAction,
    ProSpaceEmptyGrid
  },
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    checkAccess: {
      type: Function,
    },
    filterAccessDots: {
      type: Function,
    },
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    const { selectedData } = useMultiSelectRightPanel()
    return {
      setStatistic, distActions,
      selectedData
    }
  },
  data() {
    return {
      competence: {
        comments: []
      },
      modelTab: {
        name: this.$t("parameters"),
        isActive: true,
        systemName: "parameters",
      },

      modalTitle: "",
      modalCallback: null,
      modalShow: false,
      modalModel: {
        comment: "",
      },

      competenceService: new CompetenceService(),
      competenceCommentService: new CompetenceCommentService(),

      loading: false,
      hasError: false,
    };
  },
  validations() {
    return {
      modalModel: {
        comment: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime",
      getFormatDate: "userinfoStore/getFormatDate",
    }),
    headerMainInformation() {
      return {
        title: this.$t("mainInformation"),
        isExpand: false,
      };
    },
    headerAdditionalInfo() {
      return {
        title: this.$t("additionalInformation"),
        isExpand: false,
      };
    },
    headerComments() {
      return {
        title: this.$t("comments"),
        isExpand: false,
      };
    },
    headerMetaInfo() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    tabs() {
      if (!this.competence) return [];
      const tabs = [
        {
          name: this.$t("parameters"),
          systemName: "parameters",
          isActive: this.modelTab.systemName === "parameters",
        },
        {
          name: this.$t("additionalInfo"),
          systemName: "additionalInfo",
          isActive: this.modelTab.systemName === "additionalInfo",
        },
        {
          name: this.$t("comments"),
          systemName: "comments",
          isActive: this.modelTab.systemName === "comments",
        },
      ];
      if (!tabs.some(i => i.isActive)) {
        tabs[0].isActive = true
        this.modelTab = tabs[0]
      }
      return tabs
    },
    header() {
      if (this.competence) {
        return {
          title: this.competence.competenceDirectoryName,
          id: this.competence.id,
          status: this.getStatus(this.competence.status),
          options: this.getAccessDots(this.options),
          showCounter: false,
        };
      }
      return {
        title: this.$t("itemsSelected"),
        showCounter: false,
        cleareble: true,
      };
    },
    mainInformationContent() {
      if (this.competence) {
        return [
          [
            {
              "title": this.$t("distributorNameTxt"),
              "text": this.competence.distributorName
            }
          ],
          [
            {
              "title": this.$t("competenceLevelDevelopment"),
              "text": this.competence.levelDevelopment
            }
          ],
          [
            {
              "title": this.$t("distributorStructureViewNameTxt"),
              "text": this.competence.distributorStructureViewName
            }
          ],
          [
            {
              "title": this.$t("competenceDirectoryLevel"),
              "text": this.competence.competenceDirectoryLevelName
            }
          ],
          [
            {
              "title": this.$t("competenceDirectoryBlock"),
              "text": this.competence.competenceDirectoryBlockName
            }
          ],
          [
            {
              "title": this.$t("competenceStartAt"),
              "text": this.formatMomentDate(this.competence.startAt)
            }
          ],
          [
            {
              "title": this.$t("competenceEndAt"),
              "text": this.formatMomentDate(this.competence.endAt)
            }
          ]
        ];
      }
      return [];
    },
    additionaInfoContent() {
      if (this.competence) {
        return [
          [
            {
              "title": this.$t("competenceDirectoryDescription"),
              "text": this.competence.competenceDirectoryDescription,
              "isHtml": true
            }
          ],
          [
            {
              "title": this.$t("competenceDevelopmentPercentage"),
              "text": this.competence.developmentPercentage !== null && this.competence.developmentPercentage !== undefined ? String(this.competence.developmentPercentage) : null
            }
          ],
          [
            {
              "title": this.$t("competenceBusinessGoal"),
              "text": this.competence.businessGoal,
              "isHtml": true
            }
          ],
          [
            {
              "title": this.$t("competenceExpectedResult"),
              "text": this.competence.expectedResult,
              "isHtml": true
            }
          ],
          [
            {
              "title": this.$t("competenceMetrics"),
              "text": this.competence.metrics,
              "isHtml": true
            }
          ]
        ];
      }
      return [];
    },
    metaInfoContent() {
      let metaInfo = [];
      if (this.competence) {
        if (this.checkAccess("metainfo-by-block"))
          metaInfo.push([
            {
              title: this.$t("createdByTxt"),
              text: this.competence.createdBy,
            },
            {
              title: this.$t("updatedByTxt"),
              text: this.competence.updatedBy,
            },
          ]);

        metaInfo.push([
          {
            title: this.$t("createdAtTxt"),
            text: this.getFormatDateTime(this.competence.createdAt) + ' ' + getAMorPM(this.competence.createdAt, this.userinfo.timeFormat),
          },
          {
            title: this.$t("lastUpdatedTxt"),
            text: this.getFormatDateTime(this.competence.lastUpdated) + ' ' + getAMorPM(this.competence.lastUpdated, this.userinfo.timeFormat),
          },
        ]);
      }
      return metaInfo;
    },
    commentItem() {
      return {
        comment: this.modalModel.comment || "",
        competenceId: this.competence.id,
        createdAt: new Date(),
      };
    }
  },
  mounted() {
    this.update();
  },
  watch: {
    selectedData: {
      deep: true,
      handler() {
        this.update();
      }
    },
  },
  methods: {
    formatTimelineDate(date) {
      return this.getFormatDateTime(date);
    },
    emptyComment() {
      return {
        comment: ""
      }
    },
    getAccessDots(options) {
      return this.filterAccessDots
        ? this.filterAccessDots(options, this.competence)
        : options;
    },
    showReasonModal(callback, title) {
      this.modalCallback = async () => {
        await callback();
        this.modalCallback = null;
      };
      this.modalTitle = title;
      this.modalShow = true;
    },
    async addComment() {
      await this.competenceCommentService.create(this.commentItem);
      this.modalModel = this.emptyComment();
      this.update();
    },
    saveComment() {
      this.modalCallback()
    },
    cancelComment() {
      this.modalModel = this.emptyComment();
    },
    handlerSelectTab({ selectedTab }) {
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: selectedTab.systemName
      })
    },
    update() {
      this.hasError = false;
      try {
        this.getCompetence();
      } catch (e) {
        this.hasError = true;
      }
    },
    getCompetence() {
      if (this.selectedData) {
        this.loading = true;
        this.competenceService.get(this.selectedData.id).then((res) => {
          this.competence = res.data;
          this.competence.comments = this.competence.comments.map((value) => {
            return {
              comment: value.comment,
              confirmDate: value.createdAt,
              securityUserFirstName: value.securityUserFullName,
              securityUserType: this.$t(value.securityUserType)
            }
          });
          this.loading = false;
        });
      } else {
        this.competence = null;
      }
    },
    getStatus(status) {
      if (!status) return;
      let type = "default";
      switch (status.toLowerCase()) {
        case "draft":
          type = "default";
          break;
        case "new":
          type = "light-blue";
          break;
        case "inprogress":
          type = "warning";
          break;
        case "completed":
          type = "success";
          break;
        case "canceled":
          type = "default";
          break;
        default:
          type = "default";
      }
      return {
        text: this.$t(('competenceStatus' + status).toLowerCase()),
        type,
      };
    },
    handleDotAction(actionName) {
      this.options.find(o => o.name === actionName).method(this.competence)
    },
    formatMomentDate(dateString) {
      return moment(dateString).format(`${this.userinfo.dateFormat}`);
    }
  }
};
</script>

<style lang="scss">
.prospace-timeline-condition {
  width: 269px;
}

.time-line-element__comment-title {
  color: #b0bec5 !important;
}
</style>
