<template>
  <div class="login-error">
    <i class="el-icon-error" />
    <span class="stay_border" v-html="errorText"></span>
  </div>
</template>
<script>
export default {
  props: {
    errorText: {
      type: String,
      required: true,
    },
  },
};
</script>
