<template>
  <ProSpaceModal
    v-model="showModal"
    :title="header"
    :icon="icon"
    width="410"
    @apply="apply"
    @cancel="cancel"
    :smallPadding="true"
    class="grid-modal-form"
    :disabled-apply="disabledModalRule"
    :cancelTxt="$t('cancelInf')"
    :applyTxt="$t('applyTxt')"
  >
    <ProSpaceMainGrid
      ref="grid"
      :service="service"
      @selected-change="selectedHandler"
      @loaded="gridLoaded"
      :getMethod="getMethod"
      :isHeadHide="true"
      :isSelectedPanelHide="true"
      style="height: 300px"
    >
      <template #filter="{ search }">
        <div class="main-grid__filters">
          <ProSpaceInputText v-model="search.value" @submit="submitFilter" />
        </div>
      </template>
      <template #columns>
        <Column
          selectionMode="multiple"
          style="
            width: 20px;
            display: flex;
            justify-content: center;
            max-width: 20px;
            margin-right: 10px;
          "
        >
        </Column>
        <Column :field="colName"> </Column>
        <Column :field="colDesc"> </Column>
      </template>
      <template #empty>
        <ProSpaceEmptyGrid
          icon="error-message"
          :title="$t('noDataTitle')"
          :text="$t('noDataTxt')"
        />
      </template>
    </ProSpaceMainGrid>
  </ProSpaceModal>
</template>
<script>
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  ProSpaceModal,
  ProSpaceMainGrid,
  ProSpaceInputText,
  ProSpaceEmptyGrid,
} from "prospace-components-library";
import Column from "primevue/column";
export default {
  emits: ["apply", "cancel", "selected-emit", "clicked-emit"],
  props: {
    preService: {
      type: Object,
      default: () => {
        return {};
      },
    },
    preGetMethod: {
      type: String,
      default: () => {
        return "";
      },
    },
    mappingField: {
      type: String,
      default: () => {
        return "";
      },
    },
    id: {
      validator: (prop) => typeof prop === "number" || prop === null,
      default: () => {
        return null;
      },
    },
    service: {
      type: Object,
      required: true,
    },
    getMethod: {
      type: String,
      required: true,
    },
    colName: {
      type: String,
      required: true,
    },
    colDesc: {
      type: String,
      default: () => {
        return "";
      },
    },
    header: {
      type: String,
      required: true,
    },
  },
  components: {
    ProSpaceMainGrid,
    ProSpaceModal,
    Column,
    ProSpaceInputText,
    ProSpaceEmptyGrid,
  },
  data() {
    return {
      model: [],
      showModal: false,
    };
  },
  methods: {
    gridLoaded() {
      this.getData();
    },
    getData() {
      if (!this.id) return;
      const me = this;
      this.preService[this.preGetMethod](me.id).then((res) => {
        let model = res.data.records;
        let selectedModel = [];

        model.forEach((record) => {
          me.$refs.grid.records = me.$refs.grid.records.filter(
            (elem) => elem.id != record[me.mappingField]
          );
        });

        me.model = selectedModel;
        me.$refs.grid.selectedModel = me.model;
      });
    },
    show() {
      this.showModal = true;
    },
    apply() {
      this.$emit("apply", this.model);
    },
    cancel() {
      this.$emit("cancel");
    },
    selectedHandler(model) {
      this.model = model;
      this.$emit("selected-emit", model);
    },
    clickedHandler(model) {
      this.$emit("clicked-emit", model);
    },
    submitFilter() {
      this.$refs.grid.getRecords(true);
    },
  },
  computed: {
    icon() {
      return "plus";
    },
    disabledModalRule() {
      return this.model.length == 0;
    },
  },
};
</script>
<style lang="scss">
.grid-modal-form {
  .main-grid.main-grid__inline-style {
    .main-grid__filters {
      border-radius: none;
      border-bottom: var(--prospace-ui-border);
    }
  }
}
</style>
