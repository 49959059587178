<template>
  <ProSpaceGeoLayout class="geo-row">
    <template v-slot:left>
      <ProSpaceTextBlock
        :top="$t('userDistributors')"
        :bottom="$t('userDistributorsTxt')"
        :gap="10"
        type="third"
        break-word
      />
    </template>
    <template v-slot:center>
      <ProSpaceGeoLayout>
        <template v-slot:top>
          <ProSpaceButton
            type="secondary"
            icon="plus"
            :label="this.$t('add')"
            class="margin-bottom-30"
            @click="showDistributorModal"
          />
        </template>
        <template v-slot:center>
          <ProSpaceSimpleGrid
            v-if="distributorCodes && distributorCodes.length > 0"
            ref="distributorGrid"
            getMethod="grid"
            :headers="distributorGridHeaders"
            :service="distributorService"
            :limit="null"
            :btnText="$t('viewMore')"
            :deletable="true"
            :deleteMethod="null"
            :defaultFilter="distributorFilter"
            @delete="deleteFromDistributors"
          />
        </template>
      </ProSpaceGeoLayout>
      <DistributorSelectModal ref="distributorModal"
        v-model="showDistributorSelectModal"
        keyField="code"
        :modalTitle="$t('selectDistributors')"
        :selectedIds="distributorCodes"
        @applyDistributors="applyDistributorSelectModal"
      />
    </template>
  </ProSpaceGeoLayout>
</template>
  
  <script>
import {
  ProSpaceGeoLayout,
  ProSpaceTextBlock,
  ProSpaceButton,
  ProSpaceSimpleGrid,
} from "prospace-components-library";
import DistributorSelectModal from "@modals/DistributorSelectModal";
import { DistributorService } from "@masterdata/services/DistributorService.js";
import { UserDistributorRestrictionService } from "../../../services/UserDistributorRestrictionService.js";
import { mapGetters } from "vuex";
export default {
  name: "UserDistributor",
  components: {
    ProSpaceGeoLayout,
    ProSpaceTextBlock,
    ProSpaceButton,
    ProSpaceSimpleGrid,
    DistributorSelectModal,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    userCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentDistributors: [], // модели Resrtiction, назначеннные на Дистрибьюторов, полученные из БД
      distributorCodes: [], // текущий набор кодов Дистрибьюторов (актуальный для формы)
      showDistributorSelectModal: false,
      distributorService: new DistributorService(),
      userDistributorRestrictionService:
        new UserDistributorRestrictionService(),
      distributorGridHeaders: [
        {
          name: this.$t("distributorCode"),
          value: "code",
          f: (item) => item,
        },
        {
          name: this.$t("distributor"),
          value: "name",
          f: (item) => item,
        },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let me = this;
      let filter = `$filter=objectCode eq '${this.userCode}' and modelName eq 'DistributorDomainModel' and fieldName eq 'Code' and disabled eq false`;
      this.userDistributorRestrictionService
        .getRestrictions(filter)
        .then((res) => {
          if (
            res &&
            res.data &&
            res.data.records &&
            res.data.records.length > 0
          ) {
            me.currentDistributors = res.data.records;
            me.distributorCodes = me.currentDistributors.map((r) => {
              return r.fieldValue;
            });
          } else {
            me.currentDistributors = [];
            me.distributorCodes = [];
          }
        });
    },
    showDistributorModal() {
      this.showDistributorSelectModal = true;
    },
    applyDistributorSelectModal() {
      //актуализация текущего набора кодов Дистрибьюторов
      const items = this.$refs.distributorModal.grid.getSelectedModel()
      this.distributorCodes = items.map(i => i.code);

      //обновление записей в SimpleGrid
      this.$nextTick(() => {
        this.$refs.distributorGrid.records = [];
        this.$refs.distributorGrid.getData();
      });
    },
    deleteFromDistributors(item) {
      //актуализация текущего набора кодов Дистрибьюторов
      this.distributorCodes = this.distributorCodes.filter(
        (r) => r !== item.code
      );

      //обновление записей в SimpleGrid
      this.$refs.distributorGrid.records =
        this.$refs.distributorGrid.records.filter((r) => r.code !== item.code);
    },
    getNewDistributors() {
      let newCodes = this.distributorCodes.filter(
        (c) => !this.currentDistributors.map((d) => d.fieldValue).includes(c)
      );
      let newModels = [];
      newCodes.forEach((c) => {
        let m = {
          ObjectCode: this.userCode,
          ModelType: "DomainModel",
          ModelName: "DistributorDomainModel",
          ParentModelName: "Distributor",
          FieldType: "String",
          FieldName: "Code",
          FieldValue: c,
        };
        newModels.push(m);
      });

      return newModels;
    },
    getDeletedDistributors() {
      let deleted = this.currentDistributors.filter((c) =>
        !this.distributorCodes.includes(c.fieldValue)
      );

      let deletedModels = [];
      deleted.forEach((item) => {
        let m = {
          Id: item.id,
          Code: item.code,
          ObjectCode: item.objectCode,
          Stamp: item.stamp,
          modelType: item.modelType,
          modelName: item.modelName,
          parentModelName: item.parentModelName,
          fieldType: item.fieldType,
          fieldName: item.fieldName,
          fieldValue: item.fieldValue,
          createdBy: item.createdBy,
          createdAt: item.createdAt,
        };
        deletedModels.push(m);
      });

      return deletedModels;
    },
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
    }),
    distributorFilter() {
      return { code: { or: this.distributorCodes } };
    },
  },
};
</script>
  
<style lang="scss"></style>
  