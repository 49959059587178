import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/basetype/basetype/";

export default class BaseTypeApi {
  constructor() {
  }
  async getBaseTypes(params) {
    return await request.post(`${rootRoute}getBaseTypes`, {
      filter: params,
      parameters: {},
    });
  }
  async getBaseType(id) {
    return await request.post(`${rootRoute}getBaseTypes`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createBaseType(model) {
    const data = [model];
    return await request.post(`${rootRoute}createBaseType`, { data });
  }
  async deleteBaseType(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteBaseType`, { data });
  }
  async editBaseType(model) {
    const data = [model];
    return await request.post(`${rootRoute}updateBaseType`, { data });
  }
}
