<template>
  <ProSpaceTree
    ref="tree"
    propKey="id"
    isAll
    bigPaddingChildren
    noExpandCheckChildren
    noMountedSelectNode
    needLocalSearch
    :searchName="searchName"
    :searchable="searchable"
    :service="service"
    :getNodeMethod="getMethod"
    :selectionMode="selectionMode"
    :defaultFilter="defaultFilter"
    :propLabel="propLabel"
    :search-filter-model="['code', 'name', 'type', 'plantCode']"
    :fnCheckHandler="checkHandler"
    :fnDisabledCheckbox="checkboxDisabled"
    :fnVisibleCheckbox="visibleCheckboxHandler"
    :noData="$t('noData')"
    :searchPlaceholder="$t('searchTxt')"
    :style="treeStyles"
    @loaded="handlerLoaded"
    @select="$emit('selectedItem', $event)"
  >
    <template #prefix="{ node }">
      <ProSpaceTag v-bind="getTagInfo(node.type)" />
      <ProSpaceTag v-if="node.plantCode" :text="node.plantCode" type="gray-light" bordered />
      <ProSpaceTag :text="node.code" type="gray-light" bordered />
    </template>
    <template #empty>
      <ProSpaceEmptyGrid style="min-height: 283px;"
        icon="error-message"
        :title="$t('noDataTitle')"
      />
    </template>
  </ProSpaceTree>
</template>

<script>
import {
  ProSpaceTree,
  ProSpaceTag,
  ProSpaceEmptyGrid,
} from "prospace-components-library";
const _ = require("lodash");
export default {
  components: {
    ProSpaceTree,
    ProSpaceTag,
    ProSpaceEmptyGrid,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    selectedUnits: {
      type: Array,
      default: []
    },
    service: {
      type: Object
    },
    getMethod: {
      type: String
    },
    selectionMode: {
      type: String,
      default: "checkbox"
    },
    searchName: {
      type: String,
      default: "name"
    },
    searchable: {
      type: Boolean,
      default: false
    },
    defaultFilter: {
      type: String
    },
    propLabel: {
      type: String,
      default: "name"
    },
    checkedRows: {
      type: Array,
      default: []
    },
    expandAll: {
      type: Boolean,
      default: false
    },
    isInlineHeight: {
      type: Boolean,
      default: false
    },
    isOneFilial: {
      type: Boolean,
      default: false
    },
    isFilial: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localReadonly: false,
      selectedFilialId: null
    }
  },
  computed: {
    treeStyles() {
      const style = {}
      if (this.isInlineHeight) {
        style.height = 'auto'
      }
      return style
    }
  },
  mounted() {
    this.$emit('mounted')
  },
  methods: {
    getTagInfo(type) {
      type = type.toLowerCase()
      const dist = {
        "billto": {type: "blue", text: "Bill-To"},
        "soldto": {type: "light-blue", text: "GRD"},
        "virtualgrd": {type: "light-blue", text: "Virtual GRD"},
        "filial": {type: "purple", text: "Filial"},
        "office": {type: "teal", text: "Office"},
        "shipto": {type: "teal", text: "Склад доставки"}
      }
      return dist[type]
    },
    refresh() {
      this.$refs.tree.getData();
    },
    getNodesList() {
      return this.$refs.tree.list
    },
    handlerLoaded() {
      this.setCheckedRows()
      if (this.expandAll) {
        this.expandAllNodes()
      }
      if (this.selectLastNodes) {
        this.selectedLastNodes()
      }

      if(this.service.rawTreeRecords)
        this.$emit("treeLoaded", this.service.rawTreeRecords);
    },
    expandAllNodes() {
      const tree = this.$refs.tree
      tree.expandAll()
    },
    selectedLastNodes() {
      const tree = this.$refs.tree
      const selectedKey = {}

      const selectNode = (node) => {
        if (node.children && node.children.length) {
          for (let child of node.children) {
            selectNode(child);
          }
        } else {
          selectedKey[node.key] = true
        }
      }

      for (let node of tree.nodes) {
        selectNode(node);
      }

      tree.selectedKey = { ...selectedKey };
    },
    clearCheckedNodes() {
      this.$refs.tree.checkedNodes = {}
    },
    setCheckedRows() {
      if(this.selectionMode === "")
        return;
      
      let units = []
      if (units.length === 0 && this.checkedRows && this.checkedRows.length > 0) {
        if(this.isOneFilial) {
         this.selectedFilialId = this.checkedRows[0].distributorStructureViewId
        } 
        units = [...this.checkedRows];
      }

      this.$refs.tree.checkedNodes = [];
      let checkedNodes = this.$refs.tree.checkedNodes;
      units.forEach(x => {
        if(!checkedNodes[x.distributorStructureViewId || x.id])
          checkedNodes[x.distributorStructureViewId || x.id] = { checked: true, partialChecked: false };
      });

      this.$emit('update:selectedUnits', units)
    },
    checkHandler(node, checkedNodes, list, action) {
      let units = _.cloneDeep(this.selectedUnits)
      if(action === "uncheck") {
        units = units.filter(x => x.distributorStructureViewId ? x.distributorStructureViewId !== node.id : x.id !== node.id);
        if(this.isOneFilial) {
          this.selectedFilialId = null
        }
      } else {
        if(this.isOneFilial) {
          this.selectedFilialId = node.id
          this.$refs.tree.checkedNodes = {}
          this.$refs.tree.checkedNodes[node.id] = {
            checked: true,
            partialChecked: false
          }
          units = units.filter(x => x.distributorStructureViewId === node.id);
        }
        units.push({
          id: node.id, // для фильтра "Филиал"
          name: node.name, // для фильтра "Филиал"
          billToName: node.billToName, // для нормального отображения в правой панели
          distributorStructureViewId: node.id,
          distributorStructureViewName: node.name,
          distributorStructureViewCode: node.code,
          distributorStructureViewType: node.type,
          distributorStructureViewDistributorId: node.distributorId,
          distributorStructureViewDistributorName: node.billToName
        });
        
      }

      this.$emit("changed", units);
      this.$emit('update:selectedUnits', units)
    },
    checkboxDisabled(node) {
      if(this.isOneFilial) {
        if(node.type != 'Filial')
          return true;
      }

      return this.readOnly || this.localReadonly;
    },
    visibleCheckboxHandler(node) {
      return this.isFilial ? node.type === 'Filial' : true;
    }
  }
};
</script>

<style lang="scss">
</style>
