import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/reserveservice/reserveproduct/";

export default class ReserveProductApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getReserveProducts(params) {
    return await request.post(`${rootRoute}getReserveProducts`, {
      filter: params,
      parameters: {},
    });
  }
  async getReserveProduct(id) {
    return await request.post(`${rootRoute}getReserveProducts`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createReserveProduct(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createReserveProduct`, { data });
  }
  async deleteReserveProduct(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteReserveProduct`, { data });
  }
  async editReserveProduct(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateReserveProduct`, { data });
  }
}
