import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/NewsService/NewsItemApi";
import store from "../../../../Frontends/Common/store";
export class NewsItemService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
    this.statuses = [
      { text: "Черновик", value: "newsDraft", type: "default" },
      { text: "На модерации", value: "newsModer", type: "default" },
      { text: "Ожидает публикации", value: "newsWaiting", type: "orange" },
      { text: "Опубликовано", value: "newsPublished", type: "success" },
    ];
    this.newsItemId = null;
    this.assignOptions = {
      onPosition: false,
      onSlave: false,
      onFilial: false,
      onDistributor: false
    }
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(this.Api, "editNewsItem", fields);
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editNewsItem");
  }
  async grid(args) {
    let role = JSON.parse(localStorage.getItem("userRole")).systemName;
    return await this.transformer.getGrid(this.Api, "getNewsItems", { args, role, assignOptions: this.assignOptions });
  }
  async get(id) {
    let role = JSON.parse(localStorage.getItem("userRole")).systemName;
    return await this.transformer.getGridPanel(this.Api, "getNewsItem", null, {id, role});
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createNewsItem", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteNewsItem", model);
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "editNewsItem", model);
  }
  async getDistributors(filter) {
    return await this.transformer.getGrid(this.Api, "getDistributors", filter);
  }
  async getPositions() {
    return await this.transformer.toTree(this.Api, "getPositions", `$filter=newsItemId eq ${this.newsItemId} and disabled eq false`, "distributorOrgId");
  }
  async nextStep(model) {
    return await this.transformer.createItem(this.Api, "nextStep", model);
  }
  async rejectStep(model) {
    return await this.transformer.createItem(this.Api, "rejectStep", model);
  }
  async validateUsersExists(model) {
    return await this.transformer.getItem(this.Api, "validateUsersExists", model);
  }
  async validateAuthorizedPersonsExists(model) {
    return await this.transformer.getItem(this.Api, "validateAuthorizedPersonsExists", model);
  }
  async addComment(model) {
    return await this.transformer.createItem(this.Api, "addComment", model);
  }
  async export(filter, format, assignOptions) {
    let role = JSON.parse(localStorage.getItem("userRole")).systemName;
    const res = this.Api.export(filter, role, format, assignOptions);
    await store.dispatch('notifications/handleResponse', {
      promiseResponse: res,
    })
    return res;
  }
  async getStatuses() {
    const data = { records: this.statuses }
    return Promise.resolve({ data })
  }
  getStatusByValue(status) {
    return this.statuses.find(s => s.value == status)
  }
  async getAssignOptions() {
    const options = [
      { label: "На мою должность", value: "onPosition" },
      { label: "На подчиненных", value: "onSlave" },
      { label: "На филиал", value: "onFilial" },
      { label: "На Дистрибьютора", value: "onDistributor" },
    ]
    const data = {records: options}
    return Promise.resolve( { data })
  }
  setAssignOptions(options) {
    for (let key of Object.keys(this.assignOptions)) {
      if (options.includes(key)) 
        this.assignOptions[key] = true;
      else
        this.assignOptions[key] = false;
    }
  }
  clearAssignOption() {
    for (let key of Object.keys(this.assignOptions))
      this.assignOptions[key] = false;
  }
}
