import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/Report/ReportApi";

export class ReportService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(this.Api, "editReport", fields);
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editReport");
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getReportsView", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getReport", null, id);
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createReport", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteReport", model);
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "editReport", model);
  }
  async instructionGFIDOptions() {
    return Promise.resolve({
      data: {
        records: [
          {
            text: "Содержит инструкцию",
            value: true
          },
          {
            text: "Не содержит инструкцию",
            value: false
          },
        ]
      } 
    });
  }
}
