import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/RestrictionApi.js";

export class RestrictionService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
    this.removeModels = [];
    this.records = [];
    this.userId = null
    this.userCode = null
  }

  setUserId(id) {
    this.userId = id
  }

  setUserCode(code){
    this.userCode = code
  }

  async save() {
    const createModels = this.getCreateModels(this.userCode)
    const deleteModels = this.removeModels
    const updateModels = this.getUpdateModels(this.userCode)
    if (createModels.length > 0) {
      await this.createRestrictions(createModels)
    }
    if (deleteModels.length > 0) {
      this.clearDeleteModels()
      await this.deleteRestrictions(deleteModels)
    }
    if (updateModels.length > 0) {
      await this.updateRestrictions(updateModels)
    }
    return Promise.resolve()
  }

  async getRestrictions(args) {
    args += `&$filter=objectCode eq '${this.userCode}' and disabled eq false`
    return await this.transformer.getGrid(this.Api, "getRestrictions", args);
  }
  
  async createRestrictions(models) {
    return await this.transformer.createItem(this.Api, "createRestrictions", models);
  }

  async deleteRestrictions(models) {
    return await this.transformer.deleteItem(this.Api, "deleteRestrictions", models);
  }

  async updateRestrictions(models) {
    return await this.transformer.editItem(this.Api, "updateRestrictions", models)
  }

  getCreateModels(objectCode) {
    const gridData = this.getGrid()
    const models = []
    this.actionModels(gridData, (v, data, field) => {
      if (!v.id && v.value !== null) {
        const item = {
          "ObjectCode": objectCode,
          "ModelType": data.typeModel,
          "ModelName": data.name,
          "ParentModelName": data.parentModelName,
          "FieldType": field.type,
          "FieldName": field.name,
          "FieldValue": v.value.toString()
        }
        models.push(item)
      }
    })
    return models
  }

  getUpdateModels(objectCode) {
    const gridData = this.getGrid()
    const models = []
    this.actionModels(gridData, (v, data, field) => {
      const record = this.records.find(i => i.id === v.id)
      if (record && String(record.fieldValue) !== v.value) {
        const item = {
          "Id": v.id,
          "Code": v.code,
          "Stamp": v.stamp,
          "ObjectCode": objectCode,
          "ModelType": data.typeModel,
          "ModelName": data.name,
          "ParentModelName": data.parentModelName,
          "FieldType": field.type,
          "FieldName": field.name,
          "FieldValue": v.value.toString()
        }
        models.push(item)
      }  
    })
    return models
  }

  actionModels(gridData, fn) {
    gridData.forEach(data => {
      data.fields.forEach(field => {
        field.values.forEach(v => {
          fn(v, data, field)
        })
      })
    })
  }

  setRecords(records) {
    this.records = records
  }

  addToGrid(model) {
    const gridData = this.transformer.getGridData()
    let item = gridData.find(i => i.name === model.item.name)
    model.field.values = []
    if (item) {
      const hasField = item.fields.find(f => f.name === model.field.name)
      if (hasField) {
        return
      } else {
        item.fields.push(model.field)
      }
    } else {
      item = model.item
      item.fields = []
      item.fields.push(model.field)
      this.transformer.addToGridData(item)
    }
  }

  removeValue(item, i) {
    const gridData = this.getGrid()
    const value = item.values.splice(i, 1)[0]
    const restriction = gridData.find(i => i.name === item.parentModelName)
    this.addToRemoveModels(value, item, restriction)
  }

  removeField(field) {
    const gridData = this.transformer.getGridData()
    const item = gridData.find(i => i.name === field.parentModelName)
    const index = item.fields.findIndex(i => i.name === field.name)
    const values = item.fields.splice(index, 1)[0].values
    values.forEach(v => this.addToRemoveModels(v, field, item))
    if (item.fields.length === 0) {
      this.removeRestriction(field)
    }
  }

  removeRestriction(field) {
    const findProp = field.objectCode ? 'name' : 'parentModelName'
    const gridData = this.transformer.getGridData()
    const index = gridData.findIndex(i => i.name === field[findProp])
    const deleteData = gridData.splice(index, 1)
    this.actionModels(deleteData, (v, restriction, item) => {
      this.addToRemoveModels(v, item, restriction)
    })
  }

  addToRemoveModels(value, field, restriction) {
    if (value.id) {
      const item = {
        "Id": value.id,
        "Code": value.code,
        "Stamp": value.stamp,
        "ObjectCode": restriction.objectCode,
        "ModelType": restriction.typeModel,
        "ModelName": restriction.name,
        "ParentModelName": restriction.parentModelName,
        "FieldType": field.type,
        "FieldName": field.name,
        "FieldValue": value.value.toString()
      }
      this.removeModels.push(item)
    }
  }

  clearDeleteModels() {
    this.removeModels = []
  }

  setNewGridData(grid) {
    this.transformer.setNewGridData(grid)
  }

  getGrid() {
    return this.transformer.getGridData()
  }
  
  async getModel(model) {
    return Promise.resolve({data: model})
  }
}
