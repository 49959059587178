import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/newsservice/newsitemattachment/";

export default class NewsItemAttachmentApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getNewsItemAttachments(params) {
    return await request.post(`${rootRoute}getNewsItemAttachments`, {
      filter: params,
      parameters: {},
    });
  }
  async getNewsItemAttachment(id) {
    return await request.post(`${rootRoute}getNewsItemAttachments`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createNewsItemAttachment(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createNewsItemAttachment`, { data });
  }
  async deleteNewsItemAttachment(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteNewsItemAttachment`, { data });
  }
  async editNewsItemAttachment(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateNewsItemAttachment`, { data });
  }
  async setStatusNewsItemAttachment(models) {
    const data = models;
    return await request.post(`${rootRoute}setStatusNewsItemAttachment`, { data });
  }
}
