import { Filters } from "prospace-components-library"

const state = {
  userinfo: {},
  filters: Filters
};

const mutations = {
  SET_USERINFO: (state, data) => {
    state.userinfo = data;
  },
  SET_THEME: (state, theme) => {
    state.theme = theme
  }
};

const actions = {
  setUserinfo(context, data) {
    data.dateFormat = localStorage.getItem("dateFormat");
    if (data.dateFormat) {
      data.dateFormat = data.dateFormat
        .replaceAll("y", "Y")
        .replaceAll("m", "M")
        .replaceAll("d", "D");
    } else {
      data.dateFormat = 'DD.MM.YYYY'
      localStorage.setItem("dateFormat",  data.dateFormat);
    }
    if (!data.timeFormat) {
      data.timeFormat = 'hh:mm'
    }
    if (!data.timeZoneId) {
      data.timeZoneId = 'Greenwich Standard Time'
    }
    context.commit("SET_USERINFO", data);
  },
  setTheme(context, data) {
    context.commit("SET_THEME", data);
  }
};

var getters = {
  getUserinfo: (state) => {
    return state.userinfo;
  },
  getFormatDate: (state) => (date) => {
    return state.filters.dateFormat(date, state.userinfo.dateFormat)
  },
  getFormatDateTime: (state) => (date) => {
    return state.filters.dateFormat(date, `${state.userinfo.dateFormat} ${state.userinfo.timeFormat}`)
  },
  getFormatDateTimeSeconds: (state) => (date) => {
    return state.filters.dateFormat(date, `${state.userinfo.dateFormat} ${state.userinfo.timeFormat}`)
  },
  getTheme: (state) => {
    return state.theme
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
