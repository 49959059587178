import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/MenuItemsApi";

export class MenuItemsService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getMenuItems", args);
  }
  async getPaths(model) {
    const args = "$filter=disabled eq false&$top=100&$skip=0";
    let records = [];
    const res = await this.grid(args);
    const hasChild = res.data.records.find((r) =>
      r.parentId === model && model.id ? model.id : null
    );
    if (!hasChild) {
      records = res.data.records
        .filter((r) => {
          return r.parentId === null && r.id !== model.id;
        })
        .map((i) => {
          return {
            label: `${i.title}`,
            value: i.id,
          };
        });
    }
    records.unshift({ label: "/", value: null });
    const data = { records };
    return Promise.resolve({ data });
  }
  async getIcons() {
    const icons = [
      { label: "dashboard", value: "dashboard" },
      { label: "promo", value: "promo" },
      { label: "marketing", value: "marketing" },
      { label: "chart", value: "chart" },
      { label: "coins", value: "coins" },
      { label: "gears", value: "gears" },
      { label: "boxes", value: "boxes" },
      { label: "house", value: "house" },
      { label: "newspaper", value: "newspaper" },
      { label: "inbox", value: "inbox" },
      { label: "bell", value: "bell" },
      { label: "list-check", value: "list-check" },
      { label: "gear", value: "gear" },
      { label: "cube", value: "cube" },
      { label: "layer-group", value: "layer-group" },
      { label: "paste", value: "paste" },
      { label: "folder-tree", value: "folder-tree" },
      { label: "cart-shop", value: "cart-shop" },
    ];
    const data = { records: icons };
    return Promise.resolve({ data });
  }
  async getItem(id) {
    return await this.transformer.getItem(this.Api, "getMenuItem", id);
  }
  async createItem(model) {
    return await this.transformer.createItem(this.Api, "createMenuItem", model);
  }
  async editItem(model) {
    return await this.transformer.editItem(this.Api, "updateMenuItem", model);
  }
  async deleteItem(model) {
    return await this.transformer.deleteItem(this.Api, "deleteMenuItem", model);
  }
}
