import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/DocumentService/DocumentRecipientApi";
import i18n from "../../../../Frontends/Common/utils/i18n.js";

export class DocumentRecipientService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
    this.filter = "";
    this.fromView = false;
    this.needFilter = true;
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(this.Api, "editDocument", fields);
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editDocument");
  }
  async grid() {
    let method = this.fromView
      ? "getDocumentsFromView"
      : "getDocuments";
    return await this.transformer.getGrid(this.Api, method, this.filter);
  }
  async simple() {
    if (!this.needFilter) 
      return new Promise((resolve, reject) => {
        resolve({ data: { records: [] } });
      });
    let method = this.fromView
      ? "getDocumentsFromView"
      : "getDocuments";
    const res = await this.transformer.getGrid(this.Api, method, this.filter);
    res.data.records = res.data.records.map(r => {
      return {
        id: r.securityUserId || r.mailingGroupId,
        type: r.recordType,
        name: this.getRecipientName(r),
        email: this.getRecipientEmail(r),
        recordType: r.recordType,
        recordTypeLocale: i18n.global.t(r.recordType),
        fullPath: r.fullPath
      }
    })
    return res
  }
  getRecipientName(record) {
    return (
      record.mailingGroupName ||
      "" +
        `${record.securityUserFirstName || ""} ${
          record.securityUserLastName || ""
        }`
    );
  }
  getRecipientEmail(record) {
    return record.mailingGroupEmail || "" + record.securityUserEmail || "";
  }
}
