import request from "../Frontends/Common/utils/request/requestMain";
const rootRoute = "scheduler/taskqueueitem/";

export default class TaskApi {
  constructor() {
    this.defaultModel = {
      id: 0
    };
  }
  async getSystemTasks(params) {
    return await request.post(`${rootRoute}gettaskqueueitem`, {
      filter: params,
      parameters: {},
    });
  }
  async getUserTasks(params) {
    return await request.post(`${rootRoute}getusertaskqueueitem`, {
      filter: params,
      parameters: {},
    });
  }
  async getUserTask(id) {
    return await request.post(`${rootRoute}getusertaskqueueitem`, {
      filter: `$filter=id eq ${id}`,
      parameters: {},
    });
  }
  async getSystemTask(id) {
    return await request.post(`${rootRoute}gettaskqueueitem`, {
      filter: `$filter=id eq ${id}`,
      parameters: {},
    });
  }
  async createTask(model) {
    const data = [_.merge(this.defaultModel, model)];
    return await request.post(`${rootRoute}createtaskqueueitem`, { data });
  }
  async runAgainTask(model) {
    return await request.post(`${rootRoute}runagaintask`, {
      id: model.id,
    });
  }
}
