<template>
  <ProSpaceRightPanel
    :header="header"
    :loading="loading"
    :has-error="hasError"
    @clear="clearPanel"
    @showHistory="showHistory"
    @errors="showErrors"
  >
    <template v-if="selectedCount === 1" v-slot:center>
      <ProSpaceTabs :tabs="tabs" @select-tab="handlerSelectTab">
        <template v-slot:tab-1>
          <ProSpacePanelBlock :header="headerNotificationLogs">
            <ProSpacePanelBlockList :list="content" />
          </ProSpacePanelBlock>
        </template>
        <template v-slot:tab-2>
          <ProSpacePanelBlock
            :header="headerTabResipients"
          >
            <ProSpaceTabs :tabs="tabsResipients" @select-tab="handlerSelectTab">
              <template v-slot:tab-1>
                <ProSpaceSimpleGrid
                  v-if="notificationLog?.target === 'Mail'"
                  :items="recipients.to"
                  :headers="headersSimpleGrid"
                  :defaultModal="false"
                  @openModal="showRecipientsModal(recipients.to)"
                  :btnText="$t('viewMore')"
                />
                <ProSpaceSimpleGrid
                  v-else
                  :items="allRecipients"
                  :headers="headersSimpleGrid"
                  :defaultModal="false"
                  @openModal="showRecipientsModal(allRecipients)"
                  :btnText="$t('viewMore')"
                />
              </template>
              <template v-slot:tab-2>
                <ProSpaceSimpleGrid
                  :items="recipients.cc"
                  :headers="headersSimpleGrid"
                  :defaultModal="false"
                  @openModal="showRecipientsModal(recipients.cc)"
                  :btnText="$t('viewMore')"
                />
              </template>
              <template v-slot:tab-3>
                <ProSpaceSimpleGrid
                  :items="recipients.bcc"
                  :headers="headersSimpleGrid"
                  :defaultModal="false"
                  @openModal="showRecipientsModal(recipients.bcc)"
                  :btnText="$t('viewMore')"
                />
              </template>
            </ProSpaceTabs>
            <ProSpaceModal
              v-model="modalRecipients"
              icon="email"
              withoutPadding
              iconWidth="20"
              iconHeight="20"
              height="500"
              width="300"
              :title="$t('recipients')"
              :cancelTxt="$t('cancelInf')"
              applyTxt=""
            >
              <ProSpaceGeoLayout>
                <template #top>
                  <ProSpaceFilterPanel
                    :searchPlaceholder="$t('searchTxt')"
                    :txtResetFilters="$t('txtResetFilters')"
                    @apply="submitFilter"
                  />
                </template>
                <template #center>
                  <ProSpaceMainGrid ref="modal-grid" :clean-records="recipientsModal">
                    <template #columns>
                      <Column
                        field="name"
                        :header="$t('name')"
                        sortable
                      >
                        <template #body="field">
                          <ProSpaceDefaultTextColumn>
                            {{ field.data.login }}
                          </ProSpaceDefaultTextColumn>
                        </template>
                      </Column>
                      <Column
                        field="email"
                        :header="$t('notificationLogEmail')"
                        sortable
                      >
                        <template #body="field">
                          <ProSpaceDefaultTextColumn>
                            {{ field.data.email }}
                          </ProSpaceDefaultTextColumn>
                        </template>
                      </Column>
                    </template>
                  </ProSpaceMainGrid>
                </template>
              </ProSpaceGeoLayout>
            </ProSpaceModal>
          </ProSpacePanelBlock>
        </template>
      </ProSpaceTabs>
    </template>
    <template v-if="selectedCount === 1" v-slot:bottom>
      <ProSpacePanelBlock :header="headerBottom">
        <template v-slot>
          <ProSpacePanelBlockList :list="contentBottom" />
        </template>
      </ProSpacePanelBlock>
    </template>
    <template v-if="isMultipleView()" v-slot:center>
      <ProSpaceVLayout :gap="30">
        <PanelBlockSelectedItems :items="[
            { name: $t('code'), value: 'code', f: (v) => v },
            { name: $t('nameL'), value: 'eventName', f: (v) => v },
          ]"
        />
        <ProSpacePanelBlock :header="headerMultiple">
          <ProSpaceVLayout :gap="15">
            <ProSpaceAction :text="$t('notificationActionRepeat')" @click="repeatItems" />
          </ProSpaceVLayout>
        </ProSpacePanelBlock>
      </ProSpaceVLayout>
      <ProSpaceFlashMessage
        v-if="showRepeatConfirm"
        v-model="showRepeatConfirm"
        type="default"
        icon="flash-warning"
        :title="$t('repeatConfirmTitle')"
        :applyTxt="$t('accept')"
        :cancelTxt="$t('cancelInf')"
        @apply="applyRepeat"
      >
        {{ $t('repeatConfirmText') }}
      </ProSpaceFlashMessage>
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceAction,
  ProSpaceVLayout,
  ProSpaceSimpleGrid,
  ProSpaceModal,
  ProSpaceMainGrid,
  ProSpaceGeoLayout,
  ProSpaceFilterPanel,
  ProSpaceFlashMessage
} from "prospace-components-library";
import { NotificationLogService } from "../../../../../Notification/ProSpace.Notification.Frontend/services/NotificationLogService";
import { mapGetters } from "vuex";
import Column from 'primevue/column';
import {
  useStatistic
} from "@composes"
import {
  useMultiSelectRightPanel,
} from "@composes/multiselect"
import PanelBlockSelectedItems from "@multiselect/PanelBlockSelectedItems"
export default {
  name: "NotificationLogPanel",
  components: {
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceAction,
    ProSpaceVLayout,
    ProSpaceSimpleGrid,
    ProSpaceModal,
    ProSpaceMainGrid,
    ProSpaceGeoLayout,
    Column,
    ProSpaceFilterPanel,
    PanelBlockSelectedItems,
    ProSpaceFlashMessage
  },
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    unrepeatableTypes: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  setup() {
    const { selectedData, selectedCount, isMultipleView } = useMultiSelectRightPanel()
    const { setStatistic, distActions } = useStatistic()
    return {
      setStatistic, distActions,
      selectedData, selectedCount, isMultipleView
    }
  },
  data() {
    return {
      notificationLog: null,
      notificationLogService: new NotificationLogService(),
      loading: false,
      hasError: false,
      showRepeatConfirm: false,
      recipients: {to: [], cc: [], bcc: []},
      modalRecipients: false,
      recipientsModal: [],
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime"
    }),
    headersSimpleGrid() {
      return [
        { name: this.$t("email"), value: "email" },
        { name: this.$t("login"), value: "login" },
      ]
    },
    headerNotificationLogs() {
      return {
        title: this.$t("overview"),
        isExpand: false,
      };
    },
    headerMultiple() {
      return {
        title: this.$t("actions"),
        isExpand: false,
      };
    },
    headerTabResipients() {
      return {
        title: this.$t("recipients"),
        isExpand: false,
      };
    },
    headerBottom() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    tabs() {
      return [
        { name: this.$t("mainInformation"), isActive: true, systemName: "mainInformation" },
        { name: this.$t("recipients"), systemName: "mainInformation" },
      ];
    },
    allRecipients() {
      let all = this.recipients.to
                  .concat(this.recipients.cc)
                  .concat(this.recipients.bcc)
                  .concat(this.recipients.other);
      return [...new Map(all.map(item => [item.Login, item])).values()];
    },
    tabsResipients() {
      if (this.notificationLog?.target === "Mail") {
        return [
          { name: this.$t('to'), isActive: true, counter: this.recipients.to.length, systemName: "to" },
          { name: this.$t('copy'), counter: this.recipients.cc.length, systemName: "copy" },
          { name: this.$t('BCC'), counter: this.recipients.bcc.length, systemName: "bcc" },
        ];
      }
      const counter = this.recipients.to.length
                      + this.recipients.cc.length
                      + this.recipients.bcc.length
                      + this.recipients.other.length;
      return [
          { name: this.$t('to'), isActive: true, counter: counter, systemName: "to" },
        ];
    },
    header() {
      if (this.notificationLog) {
        let options = this.options
        if (!this.notificationLog.errorCode) {
          options = options.filter(i => i.name !== "errors")
        }
        if (this.unrepeatableTypes.includes(this.notificationLog.target)) {
          options = options.filter(i => i.name !== "repeat")
        }
        return {
          title: this.notificationLog.eventName,
          id: this.selectedData.id,
          status: this.getStatus(this.notificationLog.status),
          options: options,
          showCounter: false,
        };
      }
      return {
        title: this.$t("itemsSelected"),
        counter: this.selectedCount,
        showCounter: true,
      };
    },
    content() {
      if (this.notificationLog) {
        return [
          [
            {
              title: this.$t("notificationLogNotificationName"),
              text: this.notificationLog.eventName,
            },
          ],
          [
            {
              title: this.$t("notificationLogNotificationMessage"),
              text: this.handlerMessage(this.notificationLog.body),
              isHtml: true,
              whiteSpace: "normal !important"
            },
          ]
        ];
      }
      return [];
    },
    contentBottom() {
      if (this.notificationLog) {
        return [
          [
            {
              title: this.$t("createdByTxt"),
              text: this.notificationLog.createdBy,
            },
            {
              title: this.$t("updatedByTxt"),
              text: this.notificationLog.updatedBy,
            },
          ],
          [
            {
              title: this.$t("createdAtTxt"),
              text: this.getFormatDateTime(this.notificationLog.createdAt),
            },
            {
              title: this.$t("lastUpdatedTxt"),
              text: this.getFormatDateTime(this.notificationLog.lastUpdated),
            },
          ],
        ];
      }
      return [];
    },
  },
  mounted() {
    this.update();
  },
  watch: {
    selectedCount() {
      this.update()
    },
    selectedData: {
      deep: true,
      handler() {
        this.update();
      }
    },
  },
  methods: {
    handlerSelectTab({ selectedTab }) {
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: selectedTab.systemName
      })
    },
    handlerMessage(msg) {
      const regexDescription = /(<h3)(.*?)(?=<\/table>)/g;
      const arrDescriptionRgx = msg.match(regexDescription);
      const regexAction = /(<span)(.*?)(<\/span>)/g;
      const arrActionRgx = msg.match(regexAction);
      const regexDeleteColor = /(background-color|line-height|color|font-family)\:\#?\d.\w+(\;)?/g;
      if (arrActionRgx && arrActionRgx[0].includes('Необходимое')) {
        const formatAction = [`<h3 style="font-weight: 600;">${arrActionRgx[0]}</h3>`, `<p style="padding: 0; line-height: 1.3; margin-bottom: 10px;">${arrActionRgx[1]}</p>`];
        return formatAction.concat(arrDescriptionRgx).join(' ').replaceAll(regexDeleteColor, '');
      }
      return arrDescriptionRgx.join('').replaceAll(regexDeleteColor, '');
    },
    update() {
      this.hasError = false;
      try {
        this.getNotificationLog();
      } catch (e) {
        this.hasError = true;
      }
    },
    showRecipientsModal(recipients) {
      this.recipientsModal = recipients
      this.modalRecipients = true
    },
    submitFilter({ search }) {
      const grid = this.$refs['modal-grid']
      const values = Object.values(search)
      const searchStr = values.length > 0 ? values[0].contains : ""
      const filteredRecipients = this.recipientsModal.filter(i => i.email?.includes(searchStr) || i.login?.includes(searchStr))
      grid.setRecords(filteredRecipients)
    },
    getNotificationLog() {
      if (this.selectedCount === 1) {
        this.loading = true;
        this.notificationLogService.get(this.selectedData.id).then((res) => {
          this.notificationLog = res.data;
          this.getRecipients()
          this.loading = false;
        });
      }
      this.notificationLog = null;
    },
    getStatus(status) {
      if (!status) return;
      const dist = {
        "Created": "blue",
        "Sending": "orange",
        "Error": "danger",
        "Sent": "success",
        "Skipped": "info",
      }
      const type = dist[status] || "default"
      return {
        text: this.$t(`notificationStatus${status}`),
        type,
      };
    },
    getRecipients() {
      this.recipients = this.notificationLog.recipients.reduce((acc, i) => {
        if (i.parameters) {
          const params = Object.entries(i.parameters)
          params.forEach(([key, value]) => {
              if(value && acc[key.toLowerCase()]) {
                  acc[key.toLowerCase()].push(i);
              }
          });
        } else {
          acc.other.push(i);
        }
        return acc
      }, {to: [], bcc: [], cc: [], other: []})
    },
    applyRepeat() {
      this.$emit("repeat", this.notificationLog);
    },
    clearPanel() {
      this.$emit("clear");
    },
    repeatItems() {
      this.showRepeatConfirm = true;
    },
    showErrors() {
      this.$emit("errors", this.notificationLog);
    },
    showHistory() {
      this.$emit("showHistory", this.notificationLog);
    }
  },
};
</script>

<style scoped></style>
