export class TargetService {
  constructor() {
    this.dataStatuses = [
      { text: "email", value: 1 },
      { text: "browser", value: 2 },
    ];
  }
  async get() {
    return Promise.resolve({ data: { records: this.dataStatuses } });
  }
}
