import request from "../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/securityadmin/securityuserrole/";

export default class UserRoleApi {
  constructor() {
  }
  async getUserRoles(params) {
    return await request.post(`${rootRoute}getsecurityuserroles`, {
      filter: params,
      parameters: {},
    });
  }
  async getUserRole(id) {
    return await request.post(`${rootRoute}getsecurityuserroles`, {
      filter: `$filter=id eq ${id}`,
      parameters: {},
    });
  }
  async createUserRole(data) {
    return await request.post(`${rootRoute}createsecurityuserrole`, { data });
  }
  async deleteUserRole(data) {
    return await request.post(`${rootRoute}deletesecurityuserrole`, { data });
  }
  async editUserRole(data) {
    return await request.post(`${rootRoute}updatesecurityuserrole`, { data });
  }
}
