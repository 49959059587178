<template>
  <ProSpaceGeoLayout>
    <template #top>
      <ProSpaceTopPanel :header="this.$t('usefulLinks')">
        <template #right>
          <ProSpaceIconButton
            icon="update"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
            @click="actionUpdate"
          />
          <ProSpaceButton
            v-if="checkAccess('add-button')"
            style="margin-left: 10px"
            type="primary"
            icon="plus"
            :label="this.$t('createItemTxt')"
            ref="add-button"
            @click="
              $router.push({
                path: `/information/usefullink`,
                query: {
                  code: this.selectedNode ? this.selectedNode.code : null,
                },
              })
            "
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #left>
      <ProSpaceLeftPanel
        class="basetype-tree__left-panel"
        :panelName="$t('topics')"
        searchable
        :searchPlaceholder="$t('searchTxt')"
        @search="handlerSearch"
      >
        <template #center>
          <ProSpaceTree
            ref="tree"
            :searchPlaceholder="$t('searchTxt')"
            :service="usefulLinkService"
            searchName="name"
            getNodeMethod="tree"
            propKey="id"
            propLabel="name"
            counterField="usefulLinkCount"
            :prop-parent-id="null"
            @select="nodeSelect"
            :defaultFilter="{ disabled: { and: [false] } }"
            defaultSortField="name"
          />
        </template>
      </ProSpaceLeftPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpaceFilterPanel
            :searchPlaceholder="$t('searchTxt')"
            :closeTxt="$t('closeTxt')"
            :txtResetFilters="$t('txtResetFilters')"
            :modelFastFilter="modelFastFilter"
            @update:modelFastFilter="(value) => updateFilterValue(value)"
            :noMountedRequest="true"
            :fastFilters="fastFilters"
            :unicKeyStorage="usefulLinkService.constructor.name + 'grid'"
            @apply="submitFilter"
            ref="filterPanel"
            filter-model="name"
            :ignore-clear-keys="['usefulLinkTypeCode']"
          >
          </ProSpaceFilterPanel>
        </template>
        <template #center>
          <ProSpaceMainGrid
            ref="grid"
            getMethod="grid"
            selectionMode="single"
            defaultSortField="createdAt"
            :defaultSortOrder="-1"
            :default-filter="defaultFilters.disabled"
            :service="usefulLinkService"
            :noMountedRequest="true"
            @selected-change="selectedHandler"
            @db-click="editItem"
          >
            <template #columns>
              <Column
                field="Name"
                :header="this.$t('usefulLinkName')"
                sortable
                headerClass="move-sort-head"
              >
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    v-if="field.data"
                    :id="field.data.id"
                    :status="getStatus(field.data.status)"
                    :title="field.data.name"
                    :description="field.data.materialDescription"
                    :img-src="field.data.picFileContent"
                    :noHover="!checkFormAccess(field.data)"
                    width-image="60px"
                    height-image="60px"
                    align-items="center"
                    show-image
                    @click-on-title="editItem(field.data)"
                  />
                </template>
              </Column>
              <Column field="createdAt" sortable :header="$t('createdDate')">
                <template #body="field">
                  <ProSpaceDateTimeColumn
                    :date="field.data.createdAt"
                    :dateFormat="userinfo.dateFormat"
                    :timeFormat="userinfo.timeFormat"
                  />
                </template>
              </Column>
              <Column field="actions" class="column-end column-end-60">
                <template #body="field">
                  <ProSpaceHLayout justify-content="flex-end" :gap="10">
                    <ProSpaceLinkIconColumn v-if="isVisibleIcon(field.data.link)" :link="field.data.link" />
                    <ProSpaceDots
                      :options="wrapperCheckRoles(dotsOptions, field.data)"
                      @action="(action) => handleDots(action, field.data)"
                    ></ProSpaceDots>
                  </ProSpaceHLayout>
                </template>
              </Column>
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                icon="error-message"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
              />
            </template>
          </ProSpaceMainGrid>
        </template>
      </ProSpaceGeoLayout>
      <ProSpaceHistoryModal
        ref="history"
        v-model="showHistoryModal"
        modelName="UsefulLinkApiModel"
        @close="closeHistory"
      />
    </template>
    <template #right>
      <UsefulLinkPanel
        v-if="hasSelect"
        ref="right-panel"
        :service="usefulLinkService"
        :options="dotsOptions"
        :filterAccessDots="wrapperCheckRoles"
        :checkEditAccess="checkFormAccess"
        :checkAccess="checkAccess"
        @clear="selectedMultipleClear"
        @edit="editItem"
        @delete="deleteItem"
        @history="showHistory"
      />
    </template>
  </ProSpaceGeoLayout>
  <ProSpaceFlashMessage
    v-if="showDeleteModal"
    v-model="showDeleteModal"
    type="default"
    icon="flash-warning"
    :title="$t('deletingContactUser')"
    :applyTxt="$t('confirmTxt')"
    :cancelTxt="$t('reject')"
    @apply="applyDelete"
  >
    <ProSpaceVLayout :gap="10">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
        v-html="$t('deleteUsefulLink', { name:  deletingModel.name })"
      ></div>
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
</template>
<script>
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import {
  ProSpaceFilterPanel,
  ProSpaceCustomFilterPanel,
  ProSpaceMultiSelect,
  ProSpaceMainGrid,
  ProSpaceDots,
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceIconButton,
  ProSpaceButton,
  ProSpaceTableHeaderCell,
  ProSpaceDefaultTextColumn,
  ProSpaceEmptyGrid,
  ProSpaceLeftPanel,
  ProSpaceTree,
  ProSpaceVLayout,
  ProSpaceHLayout,
  ProSpaceFilterContainsChips,
  ProSpaceFilterDatesRange,
  ProSpaceDropdown,
  ProSpaceInputText,
  ProSpaceLinkIconColumn,
  Filters,
  ProSpaceDateTimeColumn,
  ProSpaceFlashMessage
} from "prospace-components-library";
import { UsefulLinkService } from "../../services/UsefulLinkService.js";
import { BaseTypeService } from "../../../../BaseType/BaseTypeService.Frontend/services/BaseTypeService.js";
import Column from "primevue/column";
import UsefulLinkPanel from "./components/UsefulLinkPanel";
import ProSpaceHistoryModal from "@modals/ProSpaceHistoryModal.vue";
const _ = require("lodash");
import { mapGetters } from "vuex";
import {
  useStatistic
} from "@composes"
import {
  useMultiSelectGrid,
} from "@composes/multiselect";
import { ref } from "vue"
export default {
  mixins: [accessMixin],
  components: {
    ProSpaceFilterPanel,
    ProSpaceCustomFilterPanel,
    ProSpaceMultiSelect,
    ProSpaceMainGrid,
    ProSpaceDots,
    Column,
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceIconButton,
    ProSpaceButton,
    ProSpaceTableHeaderCell,
    ProSpaceDefaultTextColumn,
    ProSpaceEmptyGrid,
    UsefulLinkPanel,
    ProSpaceHistoryModal,
    ProSpaceLeftPanel,
    ProSpaceTree,
    ProSpaceVLayout,
    ProSpaceHLayout,
    ProSpaceFilterContainsChips,
    ProSpaceFilterDatesRange,
    ProSpaceDropdown,
    ProSpaceInputText,
    ProSpaceLinkIconColumn,
    ProSpaceDateTimeColumn,
    ProSpaceFlashMessage
  },
  setup() {
    const grid = ref(null)
    const filterPanel = ref(null)
    const { setStatistic, distActions } = useStatistic()
    const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      hasSelect,
    } = useMultiSelectGrid(grid, filterPanel)
    return {
      grid, filterPanel,
      setStatistic, distActions,
      selectedMultipleGridHandler,
      selectedMultipleClear,
      hasSelect,
    }
  },
  data() {
    return {
      showModal: false,
      showHistoryModal: false,

      gridDefaultFilter: {},

      // Service
      usefulLinkService: new UsefulLinkService(),
      baseTypeService: new BaseTypeService(),

      //InlinePanel
      selectedNode: null,

      // Multi filter
      modelFastFilter: {
        label: this.$t("allTxt"),
        field: "disabled",
        value: false,
      },

      defaultFilters: Filters.getDefaultsFilters(),

      deletingModel: null,
      showDeleteModal: false,
    };
  },
  mounted() {
    this.loadRoleAccess();
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      access: "securityRoleAccess/access",
    }),
    customServices() {
      return [];
    },
    dotsOptions() {
      let res = [
        {
          name: "edit",
          text: this.$t("editItemTxt"),
          icon: "edit",
          method: this.editItem,
          liteRef: "edit-option",
        },
        {
          name: "delete",
          text: this.$t("delete"),
          icon: "delete",
          method: this.deleteItem,
          liteRef: "delete-option",
        },
        {
          name: "showHistory",
          text: this.$t("history"),
          icon: "history",
          method: this.showHistory,
          liteRef: "history-option",
        },
      ];
      if (this.modelFastFilter.label === this.$t("deleted")) {
        res = res.filter(
          (option) => option.name !== "edit" && option.name !== "delete"
        );
      }
      return res;
    },
    fastFilters() {
      let filters = [
        { label: this.$t("allTxt"), field: "disabled", value: false },
      ];

      if(this.checkDeleteAccess())
        filters.push({ label: this.$t("deleted"), field: "disabled", value: true });

      return filters;
    },
  },
  methods: {
    isVisibleIcon(url) {
      return /^(https:|http:|www\.)\S*/gm.test(url);
    },
    handlerSearch(value) {
      this.$refs.tree.handlerSearch(value);
    },
    closeHistory() {
      this.showHistoryModal = false;
    },
    showHistory(record) {
      this.$refs.history.tabs[0].masterId = record.id;
      this.$refs.history.masterName = record.name;
      this.$nextTick(() => {
        this.showHistoryModal = true;
      });
    },
    editItem(data) {
      if (!data || !this.checkFormAccess(data)) return;
      const url = `/information/usefullink/${data.id}`
      this.setStatistic({
        actionTemp: this.distActions.form,
        requestUrl: url
      })
      this.$router.push(url);
    },
    submitFilter({ filter, search }) {
      this.grid.submitFilter(filter, search);
    },
    emitFilter() {
      this.filterPanel.submitFilter();
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.filterPanel.submitFilter();
    },
    actionUpdate() {
      this.$refs.tree.getData();
      this.grid.getRecords(true);
      if (this.$refs["right-panel"]) {
        this.$refs["right-panel"].update();
      }
    },
    actionUpload() {
      this.usefulLinkService.export(
        this.grid.getRecords(false, true, true)
      );
      this.grid.loading = false;
    },
    selectedHandler(val) {
      this.selectedMultipleGridHandler(val)
    },
    deleteItem(model) {
      if (!model) return;
      this.deletingModel = model;
      this.showDeleteModal = true;
    },
    applyDelete() {
      this.usefulLinkService.delete(this.deletingModel).then(() => {
        this.actionUpdate();
        this.selectedMultipleClear();
        this.deletingModel = null;
      });
    },
    getStatus(status) {
      if (!status) return;
      let type = "default";
      return {
        text: status[0].toUpperCase() + status.toLowerCase().slice(1),
        type,
      };
    },
    handleDots(action, item) {
      var option = this.dotsOptions.find((o) => o.name === action);
      option.method(item);
    },
    nodeSelect(value) {
      if (this.selectedNode && this.selectedNode.key === value.key) return;
      this.selectedMultipleClear();
      var filter = value.id === 0 ? { and: [] } : { and: [value.code] };
      this.filterPanel.filter["usefulLinkTypeCode"] = filter;
      this.filterPanel.submitFilter();
      this.selectedNode = value.id !== 0 ? _.cloneDeep(value) : {};
    },
    updateFilterValue(value) {
      if (this.modelFastFilter !== value) {
        this.modelFastFilter = value;
        this.$refs.tree.defaultFilter.disabled = {
          and: [this.modelFastFilter.label === this.$t("deleted")],
        };
        this.$refs.tree.getData();
      }
      this.selectedMultipleClear();
    },
  },
};
</script>
<style lang="scss">
@import "../../assets/scss/main.scss";
.basetype-tree {
  .radio-tabs {
    margin-top: 1px;
    .radio-tab:nth-child(2) {
      display: none !important;
    }
    &-body {
      margin: 10px;
    }
  }

  &__left-panel {
    border: 1px solid var(--libBorder);
  }
}
</style>
