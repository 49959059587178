import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/masterdata/marsdistributororgusersview/";

export default class MarsDistributorOrgUsersViewApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getMarsDistributorOrgUsersViews(params) {
    return await request.post(`${rootRoute}getMarsDistributorOrgUsersViews`, {
      filter: params,
      parameters: {},
    });
  }
  async getMarsDistributorOrgUsersView(id) {
    return await request.post(`${rootRoute}getMarsDistributorOrgUsersViews`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createMarsDistributorOrgUsersView(model) {
    const data = [_.merge(this.defaultModel, model)];
    return await request.post(`${rootRoute}createMarsDistributorOrgUsersView`, { data });
  }
  async deleteMarsDistributorOrgUsersView(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteMarsDistributorOrgUsersView`, { data });
  }
  async editMarsDistributorOrgUsersView(model) {
    const data = [_.merge(this.defaultModel, model)];
    return await request.post(`${rootRoute}updateMarsDistributorOrgUsersView`, { data });
  }
}
