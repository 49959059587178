import axios from "axios";
import {
  getToken,
  getRefreshToken,
  setToken,
  setRefreshToken,
} from "../../utils/auth";
import { getUrl, handleRefreshTokensError } from "./requestFunctions";
import store from "../../store/index"
const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || config.VUE_APP_API_BASE_URL,
  withCredentials: true,
  timeout: 50000,
});

service.interceptors.request.use(
  (config) => {
    if (config.headers["Authorization"])
      return config;
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    // add current role
    let role = JSON.parse(localStorage.getItem("userRole"));
    if (role && config.data && !config.data['role']) {
      config.data['role'] = role.systemName;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
service.interceptors.response.use(
  (response) => {
    const err = response.data.errors;
    if (err && Array.isArray(err))
      store.dispatch("errorStore/handleBuisnessErrorError", err);

    return Promise.resolve(response);
  },
  (error) => {
    if (error.response) error.response.success = false;
    let url = getUrl(error);
    if (error.response && error.response.data && error.response.data.errors) {
      console.log([url, error.response.data]);
      store.dispatch("errorStore/handleError", error.response.data);
    } else if (error.response && error.response.status == 401) {
      let refreshToken = getRefreshToken();
      if (!refreshToken) {
        return handleRefreshTokensError(
          error.response,
          "Error on get tokens request: Refresh token is empty"
        );
      } else {
        var bodyFormData = new URLSearchParams();
        bodyFormData.append("grant_type", "refresh_token");
        bodyFormData.append("refresh_token", refreshToken);
        bodyFormData.append("client_id", "jsapp");

        return service
          .request({
            url: `authorization/token`,
            method: "POST",
            data: bodyFormData,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          })
          .then((response) => {
            if (!response.data.access_token || !response.data.refresh_token) {
              return handleRefreshTokensError(
                response,
                "Empty tokens. response: " + JSON.stringify(response)
              );
            }
            setToken(response.data.access_token);
            setRefreshToken(response.data.refresh_token);
            let method = error.config.method;
            let body = error.config.data;
            let headers = {
              Accept: error.config.headers["Accept"],
              "Content-Type": error.config.headers["Content-Type"],
              "Authorization": "Bearer " + response.data.access_token
            };
            return service
              .request({
                url: url,
                method: method,
                headers: headers,
                data: body,
              })
              .then((ret) => {
                return Promise.resolve(ret);
              })
              .catch((e) => {
                return handleRefreshTokensError(
                  error.response,
                  "Error on repeat request:" + e
                );
              });
          })
          .catch((e) => {
            return handleRefreshTokensError(
              error.response,
              "Error on get tokens request:" + e
            );
          });
      }
    } else {
      return Promise.resolve(error.response);
    }
  }
);

export default service;
