import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/MethadataApi.js";
const _ = require("lodash");

export class MethadataService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async getGrid(args) {
    return await this.transformer.getGrid(this.Api, "getModelhierarchy", args);
  }
  async getModel(model) {
    return Promise.resolve({data: model})
  }
  setNewGridData(newGridData) {
    this.transformer.setNewGridData(newGridData)
  }
  findItem(model) {
    const item = _.cloneDeep(this.transformer.findToGridData(i => i.name === model.parentModelName))
    const field = _.cloneDeep(model)
    return {
        item,
        field
    }
  }
  getGridData() {
    return this.transformer.getGridData()
  }
  async getFilterFieldType() {
    const data = [
      { label: 'Int64', value: 'Int64'},
      { label: 'String', value: 'String'},
      { label: 'Boolean', value: 'Boolean'},
      { label: 'DateTimeOffset', value: 'DateTimeOffset'},
      { label: 'DateTime', value: 'DateTime'},
      { label: 'Int32', value: 'Int32'},
      { label: 'Double', value: 'Double'}
    ]
    return new Promise( (res, rej) => res({
      data: {
        records: data
      }
    })) 
  }
  filter(obj) {
    let grid = _.cloneDeep(this.getGridData())
    grid = this.filterTypeModel(obj.model, grid)
    grid = this.filterTypeField(obj.field, grid)
    return grid
  }
  filterTypeModel(type) {
    let grid = _.cloneDeep(this.getGridData())
    if (type !== "All") {
      const keys = { 
        "Api models": "ApiModel", 
        'Domain Models': "DomainModel"
      }
      grid = grid.filter(i => i.typeModel === keys[type])
    }
    return grid
  }
  filterTypeField(types) {
    let grid = _.cloneDeep(this.getGridData())
    if (types && types.length > 0) {
      grid = grid.filter(i => {
        i.fields = i.fields.filter(f => types.includes(f.type))
        if (i.fields.length === 0) {
          return false
        }
        return true
      })
    }
    return grid
  }
}