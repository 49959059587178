import request from "../Frontends/Common/utils/request/requestMain";
const rootRoute = "securityadmin/menuitemrole/"

export default class MenuItemRoleApi {
  async get(id) {
    return await request.post(`${rootRoute}getmenuitemroles`, {
      filter: `$filter=securityRoleId eq ${id} and disabled eq false`,
    });
  }
  async create(models) {
    return await request.post(`${rootRoute}createmenuitemrole`, {
      data: models,
    });
  }
  async update(model) {
    return await request.post(`${rootRoute}updatemenuitemrole`, {
      data: model
    })
  }
  async delete(model) {
    return await request.post(`${rootRoute}deletemenuitemrole`, {
      data: model,
    });
  }
}
