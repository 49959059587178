<template>
  <ProSpaceModal
    :key="`validate-modal-${show}`"
    v-model="show"
    :restoreResize="true"
    :title="isEdit ? $t('editItemTxt') : `${$t('newTxt')} ${$t('userLower')}`"
    :icon="isEdit ? 'edit' : 'plus'"
    class="modal-user"
    :loading="loading"
    @apply="sendRequest"
    :content-style="{
      width: '530px',
      height: '655px'
    }"
    :validate-local="$t"
    :validate-model="model"
    :validate-config="{
      firstName: { required },
      lastName: { required },
      secondName: { required },
      email: {
        required,
        email: {
          fn: Validations.isEmail,
          message: $t('notValidEmail'),
        },
      },
      login: {
        required,
        validLogin: {
          fn: Validations.custom('^(?=.*[a-z])[A-Za-z0-9_.]+$'),
          message: $t('notValidLogin'),
        },
      },
      securityLoginAuthenticateProvider: { required },
      orgLinkType: { required },
      orgLinkMarsOrgId: {
        mars: {
          message: $t('requiredFieldErrorMessage'),
          fn: marsValidate,
        },
      },
      orgLinkDistributorId: {
        distr: {
          message: $t('requiredFieldErrorMessage'),
          fn: distrValidate,
        },
      },
      orgLinkDistributorOrgId: {
        distr: {
          message: $t('requiredFieldErrorMessage'),
          fn: distrValidate,
        },
      },
      distributorStructures: {
        distr: {
          message: $t('requiredFieldErrorMessage'),
          fn: distrStructureValidate,
        },
      },
    }"
  >
    <template #default="{ v }">
      <ProSpaceTabs v-model="modelTab" ref="tabs" :tabs="tabs" is-full-width>
        <template v-slot:tab-1>
          <ProSpaceVLayout
            :gap="20"
            style="margin-top: 20px; padding-bottom: 20px"
          >
            <ProSpaceDropdown
              ref="userTypeDropdown"
              getMethod="grid"
              v-model="model.orgLinkType"
              propValue="code"
              propLabel="name"
              argMethod="$filter=systemDirectoryCode eq 'User type' and disabled eq false"
              :label="$t('userType')"
              :placeholder="$t('userType')"
              :service="baseTypeService"
              :invalid="v.orgLinkType.$error"
              :invalid-messages="v.orgLinkType.$errors"
              @change="userTypeChanged"
              @blur="v.orgLinkType.$touch"
            />
            <ProSpaceSearchfield
              v-if="model.orgLinkType === 'Distributor'"
              ref="distributor"
              v-model="model.orgLinkDistributorId"
              optionLabel="name"
              optionValue="id"
              :filterModel="['name', 'code']"
              icon="parameters"
              getMethod="grid"
              :label="$t('distributor')"
              :searchPlaceholder="$t('searchTxt')"
              :cancelTxt="$t('cancelInf')"
              :applyTxt="$t('applyTxt')"
              :txtTitleModal="$t('listDistributors')"
              :displayValue="model.orgLinkDistributorName"
              :service="distributorService"
              :contentStyle="contentStyle"
              :fastFilters="[]"
              :defaultFilter="{ disabled: { and: [false] } }"
              :invalid="v.orgLinkDistributorId.$error"
              :invalid-messages="v.orgLinkDistributorId.$errors"
              @update:modelValue="updateModelField($event, 'distributor')"
              @blur="v.orgLinkDistributorId.$touch"
            >
              <template #filters="{ filter }">
                <ProSpaceCustomFilterPanel
                  :customTxt="$t('custom')"
                  :customFilterTxt="$t('customFilterTxt')"
                  :clearFilterTxt="$t('clearFilterTxt')"
                  :selectAllTxt="$t('selectAllTxt')"
                  :closeTxt="$t('closeTxt')"
                  :applyTxt="$t('applyTxt')"
                  :fromTxt="$t('fromTxt')"
                  :toTxt="$t('toTxt')"
                  :services="customServices"
                  @apply="(model) => customFilterApply(filter, model)"
                />
              </template>
              <template #columns>
                <Column
                  field="name"
                  :header="$t('distributor')"
                  sortable
                  headerClass="move-sort-head"
                >
                  <template #body="field">
                    <ProSpaceTableHeaderCell
                      v-if="field.data"
                      :id="field.data.id"
                      :status="{ text: $t('distributor'), type: 'blue' }"
                      :title="field.data.name"
                      :description="`${field.data.mailAddress || ''}`"
                      :img-src="field.data.logoFileContent"
                      show-image
                      is-inline-w
                    />
                  </template>
                </Column>
                <Column
                  field="phoneNumber"
                  :header="$t('phoneNumber')"
                  sortable
                />
                <Column field="faxNumber" :header="$t('faxNumber')" sortable />
                <Column
                  field="emailAddress"
                  :header="$t('email')"
                  sortable
                />
              </template>
              <template #panel="{ item }">
                <ProSpaceRightPanel :header="headerDistributor(item)">
                  <template #center>
                    <ProSpacePanelBlock
                      :header="{ title: $t('detailTxt'), isExpand: true }"
                    >
                      <ProSpacePanelBlockList
                        :itemsPerPage="5"
                        :list="contentDistributor(item)"
                      />
                    </ProSpacePanelBlock>
                  </template>
                </ProSpaceRightPanel>
              </template>
            </ProSpaceSearchfield>
            <ProSpaceSearchfield
              v-if="model.orgLinkType === 'Distributor'"
              ref="distributorOrg"
              v-model="model.orgLinkDistributorOrgId"
              mode="tree"
              optionLabel="name"
              optionValue="id"
              defaultSortField="name"
              :label="$t('position')"
              :searchPlaceholder="$t('searchTxt')"
              :cancelTxt="$t('cancelInf')"
              :applyTxt="$t('applyTxt')"
              :placeholder="$t('position')"
              :txtTitleModal="$t('listDistributorOrgStructure')"
              :displayValue="model.orgLinkDistributorOrgName"
              :service="distributorOrgService"
              :defaultFilter="{ disabled: { and: [false] } }"
              :invalid="v.orgLinkDistributorOrgId.$error"
              :invalid-messages="v.orgLinkDistributorOrgId.$errors"
              @update:modelValue="updateModelField($event, 'distributorOrg')"
              @blur="v.orgLinkDistributorOrgId.$touch"
            >
              <template #panel="{ item }">
                <ProSpaceRightPanel>
                  <template #center>
                    <ProSpacePanelBlock
                      :header="{ title: $t('parameters'), isExpand: false }"
                    >
                      <ProSpacePanelBlockList
                        :list="getDistrOrgPanelInfo(item)"
                      />
                    </ProSpacePanelBlock>
                  </template>
                </ProSpaceRightPanel>
              </template>
            </ProSpaceSearchfield>
            <ProSpaceSearchfield
              v-if="model.orgLinkType === 'Mars'"
              ref="marsOrg"
              v-model="model.orgLinkMarsOrgId"
              mode="tree"
              optionLabel="name"
              optionValue="id"
              defaultSortField="name"
              :label="$t('position')"
              :searchPlaceholder="$t('searchTxt')"
              :cancelTxt="$t('cancelInf')"
              :applyTxt="$t('applyTxt')"
              :txtTitleModal="$t('listMarsOrgStructure')"
              :displayValue="model.orgLinkMarsOrgName"
              :service="marsOrgService"
              :defaultFilter="{ disabled: { and: [false] } }"
              :invalid="v.orgLinkMarsOrgId.$error"
              :invalid-messages="v.orgLinkMarsOrgId.$errors"
              @update:modelValue="updateModelField($event, 'marsOrg')"
              @blur="v.orgLinkMarsOrgId.$touch"
            >
              <template #panel="{ item }">
                <ProSpaceRightPanel>
                  <template #center>
                    <ProSpacePanelBlock
                      :header="{ title: $t('parameters'), isExpand: false }"
                    >
                      <ProSpacePanelBlockList
                        :list="getDistrOrgPanelInfo(item)"
                      />
                    </ProSpacePanelBlock>
                  </template>
                </ProSpaceRightPanel>
              </template>
            </ProSpaceSearchfield>
          </ProSpaceVLayout>
        </template>
        <template v-slot:tab-2>
          <ProSpaceVLayout :gap="20" style="margin-top: 20px">
            <ProSpaceInputText
              v-model="model.lastName"
              :label="$t('lastName')"
              :placeholder="$t('lastName')"
              :invalid="v.lastName.$error"
              :invalid-messages="v.lastName.$errors"
              @blur="v.lastName.$touch"
            />
            <ProSpaceInputText
              v-model="model.firstName"
              :label="$t('firstName')"
              :placeholder="$t('firstName')"
              :invalid="v.firstName.$error"
              :invalid-messages="v.firstName.$errors"
              @blur="v.firstName.$touch"
            />
            <ProSpaceInputText
              v-model="model.secondName"
              :label="$t('secondName')"
              :placeholder="$t('secondName')"
              :invalid="v.secondName.$error"
              :invalid-messages="v.secondName.$errors"
              @blur="v.secondName.$touch"
            />
            <ProSpaceInputText
              v-model="model.email"
              :label="$t('email')"
              :placeholder="$t('email')"
              :invalid="v.email.$error"
              :invalid-messages="v.email.$errors"
              @blur="v.email.$touch"
            />
            <ProSpaceInputText
              v-if="isCreate"
              v-model="model.login"
              :label="$t('login')"
              :placeholder="$t('login')"
              :invalid="v.login.$error"
              :invalid-messages="v.login.$errors"
              @blur="v.login.$touch"
            />
            <ProSpaceDropdown
              v-if="isCreate"
              v-model="model.securityLoginAuthenticateProvider"
              getMethod="get"
              :label="$t('loginType')"
              :placeholder="$t('loginType')"
              :service="loginTypeService"
              :invalid="v.securityLoginAuthenticateProvider.$error"
              :invalid-messages="v.securityLoginAuthenticateProvider.$errors"
              @blur="v.securityLoginAuthenticateProvider.$touch"
            />
            <ProSpaceInputSwitch
              v-model="model.status"
              falseValue="Inactive"
              trueValue="Active"
              :label="$t('active')"
              :noBorder="true"
            />
          </ProSpaceVLayout>
        </template>
        <template v-slot:tab-3>
          <DistributorHierarchySelectTree
            getMethod="allTree"
            searchName="name"
            searchable
            expandAll
            :service="distributorStructureService"
            :checkedRows="model.distributorStructures"
            :selectedUnits="model.distributorStructures"
            :invalid="v.distributorStructures.$errors.length > 0"
            @changed="units => setDistributorStructure(units, v)"
          />
        </template>
      </ProSpaceTabs>
    </template>
    <template #footer="{ v }">
      <ProSpaceHLayout :gap="5" justify-content="flex-end" is-inline-w>
        <ProSpaceButton
          type="secondary"
          :label="$t('cancelInf')"
          @click="show = false"
        />
        <ProSpaceButton
          type="primary"
          :label="btnText"
          :icon="btnIcon"
          :disabled="isDisabledBtn(v) && isLastTab"
          @click="() => primaryBtnHandler(v)"
        />
      </ProSpaceHLayout>
    </template>
  </ProSpaceModal>
</template>

<script>
import {
  ProSpaceModal,
  ProSpaceInputText,
  ProSpaceDropdown,
  ProSpaceInputSwitch,
  ProSpaceVLayout,
  ProSpaceSearchfield,
  ProSpaceTabs,
  ProSpaceRightPanel,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceTableHeaderCell,
  ProSpaceCustomFilterPanel,
  ProSpaceButton,
  ProSpaceHLayout,
  ProSpaceGeoLayout,
  Validations,
} from "prospace-components-library";
import { UserService } from "../../../services/UserService.js";
import { LoginTypeService } from "../../../services/LoginTypeService.js";
import { DistributorService } from "@masterdata/services/DistributorService";
import { DistributorOrgService } from "@masterdata/services/DistributorOrgService";
import { DistributorStructureService } from "@masterdata/services/DistributorStructureService.js";
import { MarsOrgService } from "@masterdata/services/MarsOrgService";
import { BaseTypeService } from "../../../../../BaseType/BaseTypeService.Frontend/services/BaseTypeService";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import DistributorHierarchySelectTree from "@trees/DistributorHierarchySelectTree";
import Column from "primevue/column";
import _ from "lodash";
import { mapGetters } from "vuex";
export default {
  name: "ModalUser",
  components: {
    ProSpaceModal,
    ProSpaceInputText,
    ProSpaceDropdown,
    ProSpaceInputSwitch,
    ProSpaceVLayout,
    ProSpaceSearchfield,
    ProSpaceTabs,
    Column,
    ProSpaceRightPanel,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceTableHeaderCell,
    ProSpaceCustomFilterPanel,
    ProSpaceButton,
    ProSpaceHLayout,
    ProSpaceGeoLayout,
    DistributorHierarchySelectTree,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    const marsValidate = (v) => {
      const model = this.model;
      if (model.orgLinkType === "Distributor") return true;
      return !!v;
    };
    const distrValidate = (v) => {
      const model = this.model;
      if (model.orgLinkType === "Mars") return true;
      return !!v;
    };
    const distrStructureValidate = (v) => {
      const model = this.model;
      if (model.orgLinkType === "Mars") return true;
      return v && v.length > 0;
    };
    return {
      model: {
        firstName: { required },
        lastName: { required },
        secondName: { required },
        orgLinkType: { required },
        email: { required },
        login: { required },
        securityLoginAuthenticateProvider: { required },
        orgLinkMarsOrgId: { marsValidate },
        orgLinkDistributorId: { distrValidate },
        orgLinkDistributorOrgId: { distrValidate },
        distributorStructures: { distrStructureValidate },
      },
    };
  },
  data() {
    return {
      Validations,
      show: false,
      model: null,
      modelTab: {
        name: this.$t("userType"),
        systemName: "userType",
        isActive: true,
      },
      mode: "create",
      userService: new UserService(),
      loginTypeService: new LoginTypeService(),
      baseTypeService: new BaseTypeService(),
      distributorService: new DistributorService(),
      distributorOrgService: new DistributorOrgService(),
      distributorStructureService: new DistributorStructureService(),
      marsOrgService: new MarsOrgService(),
      modalBtnTxt: this.$t("next"),
      modalBtnIcon: null,
      loading: false,
      defaultDatetimeFormats: {
        dateFormat: "dd.MM.yyyy",
        timeFormat: "HH:mm:ss",
        timeZoneId: "Greenwich Standard Time",
      },
    };
  },
  methods: {
    isDisabledBtn(valid) {
      return valid.$errors.length > 0;
    },
    marsValidate(v) {
      const model = this.model;
      if (model.orgLinkType === "Distributor") return true;
      return !!v;
    },
    distrValidate(v) {
      const model = this.model;
      if (model.orgLinkType === "Mars") return true;
      return !!v;
    },
    distrStructureValidate(v) {
      const model = this.model;
      if (model.orgLinkType === "Mars") return true;
      return v && v.length > 0;
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.$refs["distributor"].$refs["filter-panel"].submitFilter();
    },
    contentDistributor(distributor) {
      if (distributor) {
        return [
          [
            {
              title: this.$t("legalAddress"),
              text: distributor.legalAddress,
            },
          ],
          [
            {
              title: this.$t("mailAddress"),
              text: distributor.mailAddress,
            },
          ],
          [
            {
              title: this.$t("phoneNumber"),
              text: distributor.phoneNumber,
            },
          ],
          [
            {
              title: this.$t("faxNumber"),
              text: distributor.faxNumber,
            },
          ],
          [
            {
              title: this.$t("email"),
              text: distributor.emailAddress,
            },
          ],
        ];
      }
      return [];
    },
    headerDistributor(distributor) {
      if (distributor) {
        return {
          title: distributor.name,
          id: distributor.id,
          status: { text: this.$t("distributor"), type: "info" },
          showCounter: false,
        };
      }
    },
    userTypeChanged(option) {
      this.model.orgLinkMarsOrgId = null;
      this.model.orgLinkDistributorId = null;
      this.model.orgLinkDistributorOrgId = null;
      this.model.orgLinkDistributorName = "";
      this.model.orgLinkDistributorCode = "";
      this.model.orgLinkDistributorOrgName = "";
      this.model.orgLinkMarsOrgName = "";
    },
    getDistrOrgPanelInfo(record) {
      const pos = [
        {
          title: this.$t("position"),
          text: record.name,
        },
      ];
      const parentPos = [
        {
          title: this.$t("parentPosition"),
          text: record.parentName,
        },
      ];
      return record.parentName ? [parentPos, pos] : [pos];
    },
    updateModelField(data, field) {
      this.model[`orgLink${field[0].toUpperCase() + field.slice(1)}Name`] =
        this.$refs[field].selectedItem.name;
      if (field === "distributor") {
        this.model.orgLinkDistributorCode = this.$refs[field].selectedItem.code;
        this.distributorStructureService.setBillToCodes([this.model.orgLinkDistributorCode]);
        this.model.distributorStructures = [];
      }
    },
    init(model) {
      this.show = true;
      if (model) {
        this.mode = "edit";
        this.model = _.cloneDeep(model);
        this.distributorStructureService.setBillToCodes([this.model.orgLinkDistributorCode]);
      } else {
        this.mode = "create";
        this.model = {
          firstName: "",
          secondName: "",
          lastName: "",
          email: "",
          jobTitle: "",
          status: "Active",
          orgLinkType: null,
          orgLinkMarsOrgId: null,
          orgLinkDistributorId: null,
          orgLinkDistributorOrgId: null,
          orgLinkDistributorName: "",
          orgLinkDistributorCode: "",
          orgLinkDistributorOrgName: "",
          orgLinkMarsOrgName: "",
          distributorStructures: [],
        };
      }
      this.$nextTick(this.moveFirstTab);
    },
    sendRequest(v) {
      if(this.model.orgLinkType === "Distributor") {
        let existingDSIds = this.distributorStructureService.rawTreeRecords.map(s => s.id);
        this.model.distributorStructures = this.model.distributorStructures.filter(s => existingDSIds.includes(s.distributorStructureViewId));
      }
      if (!v.$dirty) {
        v.$touch();
      }
      if (v.distributorStructures.$invalid) {
        return;
      }
      if (v.$invalid) {
        this.checkValidFirstTab(v);
        return;
      }
      this.loading = true;
      if (this.isCreate) {
        this.model.dateFormat = this.defaultDatetimeFormats.dateFormat;
        this.model.timeFormat = this.defaultDatetimeFormats.timeFormat;
        this.model.timeZoneId = this.defaultDatetimeFormats.timeZoneId;
      }
      this.userService[this.mode](this.model)
        .then((res) => {
          this.loading = false;
          if (res.data === true) this.close();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    checkValidFirstTab(v) {
      if (
        (v.orgLinkMarsOrgId.$invalid ||
          v.orgLinkDistributorId.$invalid ||
          v.orgLinkDistributorOrgId.$invalid) &&
        !this.tabs[0].isActive
      ) {
        this.moveFirstTab();
      }
    },
    moveFirstTab() {
      this.$refs.tabs.select(this.tabs[0], 0);
    },
    close() {
      this.$emit("close");
      this.$emit("update:modalValue", false);
    },
    next() {
      let nextIndex = this.tabs.findIndex((x) => x.isActive) + 1;
      this.$refs.tabs.select(this.tabs[nextIndex], nextIndex);
    },
    setDistributorStructure(units, v) {
      if (!v.distributorStructures.$dirty) {
        v.distributorStructures.$touch()
      }
      units = units.map(i => {
        return {
          distributorStructureViewId: i.distributorStructureViewId,
          distributorStructureViewName: i.distributorStructureViewName || "",
          distributorStructureViewCode: i.distributorStructureViewCode,
          distributorStructureViewType: i.distributorStructureViewType,
          distributorStructureViewDistributorId: i.distributorStructureViewDistributorId
        }
      })
      this.model.distributorStructures = _.cloneDeep(units);
    },
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
    }),
    contentStyle() {
      return {
        height: "70vh",
        width: "95vw",
      };
    },
    isCreate() {
      return this.mode === "create";
    },
    isEdit() {
      return this.mode === "edit";
    },
    isLastTab() {
      return this.model.orgLinkType === "Distributor"
        ? this.modelTab.systemName === "distributorStructureBinding"
        : this.modelTab.systemName === "mainInfo";
    },
    primaryBtnHandler() {
      return !this.isLastTab ? this.next : this.sendRequest;
    },
    tabs() {
      return [
        {
          name: this.$t("userType"),
          systemName: "userType",
          isActive: this.modelTab.systemName === "userType",
        },
        {
          name: this.$t("mainInfo"),
          systemName: "mainInfo",
          isActive: this.modelTab.systemName === "mainInfo",
        },
        {
          name: this.$t("distributorStructureBinding"),
          systemName: "distributorStructureBinding",
          isActive: this.modelTab.systemName === "distributorStructureBinding",
          isDisabled: !this.model.orgLinkDistributorId,
          isHidden: this.model.orgLinkType !== "Distributor",
        },
      ];
    },
    customServices() {
      return [
        {
          type: "chips",
          model: "Name",
          label: this.$t("name"),
          selected: {},
        },
        {
          type: "chips",
          model: "Code",
          label: this.$t("distributorCode"),
          selected: {},
        },
        {
          type: "chips",
          model: "LegalAddress",
          label: this.$t("distributorLegalAddress"),
          selected: {},
        },
        {
          type: "chips",
          model: "MailAddress",
          label: this.$t("distributorMailAddress"),
          selected: {},
        },
        {
          type: "chips",
          model: "PhoneNumber",
          label: this.$t("distributorPhoneNumber"),
          selected: {},
        },
        {
          type: "chips",
          model: "FaxNumber",
          label: this.$t("distributorFaxNumber"),
          selected: {},
        },
        {
          type: "chips",
          model: "emailAddress",
          label: this.$t("email"),
          selected: {},
        },
        {
          type: "checkboxes",
          service: new DistributorService(),
          getMethod: "getEDOs",
          model: "EDOProviderName",
          label: this.$t("distributorEDO"),
          optionValue: "value",
          optionLabel: "label",
          selected: {},
        },
        {
          type: "chips",
          model: "BankName",
          label: this.$t("distributorBankName"),
          selected: {},
        },
        {
          type: "chips",
          model: "BankPaymentAccount",
          label: this.$t("distributorBankPaymentAccount"),
          selected: {},
        },
        {
          type: "chips",
          model: "BankCorrespondentAccount",
          label: this.$t("distributorBankCorrespondentAccount"),
          selected: {},
        },
        {
          type: "chips",
          model: "BankBIC",
          label: this.$t("distributorBankBIC"),
          selected: {},
        },
        {
          type: "chips",
          model: "BankINN",
          label: this.$t("distributorBankINN"),
          selected: {},
        },
        {
          type: "chips",
          model: "BankKPP",
          label: this.$t("distributorBankKPP"),
          selected: {},
        },
        {
          type: "chips",
          model: "BankOGRN",
          label: this.$t("distributorBankOGRN"),
          selected: {},
        },
        {
          type: "chips",
          model: "createdBy",
          label: this.$t("createdByTxt"),
          selected: {},
        },
        {
          type: "chips",
          model: "updatedBy",
          label: this.$t("updatedByTxt"),
          selected: {},
        },
        {
          type: "dates-range",
          model: "createdAt",
          label: this.$t("createDate"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range",
          model: "lastUpdated",
          label: this.$t("lastUpdatedTxt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
      ];
    },
    btnText() {
      return this.modalBtnTxt = !this.isLastTab
          ? this.$t("next")
          : this.isEdit
          ? this.$t("save")
          : this.$t("add");
    },
    btnIcon() {
      return this.modalBtnIcon = !this.isLastTab ? null : "check";
    }
  },
};
</script>
