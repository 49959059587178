import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/MasterData/AnotherContactPersonApi";
import store from "../../../../Frontends/Common/store";

export class AnotherContactPersonService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(this.Api, "editAnotherContactPerson", fields);
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editAnotherContactPerson");
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getAnotherContactPersons", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getAnotherContactPerson", null, id);
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createAnotherContactPerson", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteAnotherContactPerson", model);
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "editAnotherContactPerson", model);
  }
  async export(filter, format) {
    const res = this.Api.export(filter, format);
    await store.dispatch('notifications/handleResponse', {
      promiseResponse: res,
    })
  }
}
