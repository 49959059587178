import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/UseCaseClaimApi";

export class UseCaseClaimService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
    this.item = null
  }
  setItem(item) {
    this.item = item
  }
  async get(id) {
    return await this.transformer.getGrid(this.Api, "getClaim", id);
  }
  async delete(models) {
    const deleteModels = this.getModels(models)
    return await this.transformer.deleteItem(this.Api, "deleteClaim", models);
  }
  async deleteItem(item) {
    return await this.transformer.deleteItem(this.Api, "deleteClaim", [item]);
  }
  async create(models) {
    const createModels = this.getModels(models)
    return await this.transformer.createItem(this.Api, "createClaim", createModels);
  }
  async grid(args, id) {
    return await this.transformer.getGrid(this.Api, "getClaimGrid", args, id);
  }
  getModels(models) {
    return models.map(m => {
      return {
        securityClaimId: m.id,
        securityClaimServiceName: m.serviceName,
        securityClaimValue: m.value,
        securityUseCaseDescription: this.item.description,
        securityUseCaseId: this.item.id,
        securityUseCaseTitle: this.item.title,
      }
    })
  }
}
