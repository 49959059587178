import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/newsservice/newsitemuserimportant/";

export default class NewsItemUserImportantApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getNewsItemUserImportants(params) {
    return await request.post(`${rootRoute}getNewsItemUserImportants`, {
      filter: params,
      parameters: {},
    });
  }
  async getNewsItemUserImportant(id) {
    return await request.post(`${rootRoute}getNewsItemUserImportants`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createNewsItemUserImportant(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createNewsItemUserImportant`, { data });
  }
  async createNewsItemUserImportants(model) {
    const data = model;
    return await request.post(`${rootRoute}createNewsItemUserImportant`, { data });
  }
  async deleteNewsItemUserImportant(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteNewsItemUserImportant`, { data });
  }
  async deleteNewsItemUserImportants(model) {
    const data = model;
    return await request.post(`${rootRoute}deleteNewsItemUserImportant`, { data });
  }
  async editNewsItemUserImportant(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateNewsItemUserImportant`, { data });
  }
}
