import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/Report/ReportImportantApi";

export class ReportImportantService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(this.Api, "editReportImportant", fields);
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editReportImportant");
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getReportImportants", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getReportImportant", null, id);
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createReportImportant", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteReportImportant", model);
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "editReportImportant", model);
  }
}
