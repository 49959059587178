import request from "../Frontends/Common/utils/request/requestMain";
const rootRoute = "history/";

export default class HistoryApi {
  async get(data) {
    return await request.post(`${rootRoute}gethistory`, {
      modelName: data.modelName,
      filter: data.filter,
    });
  }
  async getModelInfo(data) {
    return await request.post(`${rootRoute}getmodelinfo`, {
      modelName: data.modelName,
    });
  }
}
