import "indexeddb-getall-shim";

const indexedDB =
  window.indexedDB ||
  window.mozIndexedDB ||
  window.webkitIndexedDB ||
  window.msIndexedDB ||
  window.shimIndexedDB;
const open = indexedDB.open("MDPSecurityRoleAccess", 10);
open.onupgradeneeded = function () {
  let db = open.result;
  db.createObjectStore("SecurityRoleAccess");
};

async function put(key, value) {
  const promise = new Promise((resolve, reject) => {
    let result;
    const lindexedDB =
      window.indexedDB ||
      window.mozIndexedDB ||
      window.webkitIndexedDB ||
      window.msIndexedDB ||
      window.shimIndexedDB;
    const lopen = lindexedDB.open("MDPSecurityRoleAccess", 10);

    lopen.onsuccess = (_) => {
      let db = lopen.result;
      let etx = db.transaction("SecurityRoleAccess", "readwrite");

      etx.oncomplete = (_) => resolve(result);
      etx.onerror = (o) => reject(o.target.error);

      let objectStore = etx.objectStore("SecurityRoleAccess");
      const request = objectStore.put(value, key);
      request.onsuccess = (_) => {
        result = request.result;
        db.close();
      };
    };
  });

  return await promise;
}

async function clear() {
  const promise = new Promise((resolve, reject) => {
    const lindexedDB =
      window.indexedDB ||
      window.mozIndexedDB ||
      window.webkitIndexedDB ||
      window.msIndexedDB ||
      window.shimIndexedDB;
    const lopen = lindexedDB.open("MDPSecurityRoleAccess", 10);

    lopen.onsuccess = (_) => {
      let db = lopen.result;
      let etx = db.transaction("SecurityRoleAccess", "readwrite");

      etx.oncomplete = (_) => resolve();
      etx.onerror = (o) => reject(o.target.error);

      let objectStore = etx.objectStore("SecurityRoleAccess");
      const request = objectStore.clear();
      request.onsuccess = (_) => {
        db.close();
      };
    };
  });

  return await promise;
}

async function getAsync(key) {
  const promise = new Promise((resolve, reject) => {
    let result;
    const lindexedDB =
      window.indexedDB ||
      window.mozIndexedDB ||
      window.webkitIndexedDB ||
      window.msIndexedDB ||
      window.shimIndexedDB;
    const lopen = lindexedDB.open("MDPSecurityRoleAccess", 10);

    lopen.onsuccess = (_) => {
      let db = lopen.result;
      let etx = db.transaction("SecurityRoleAccess", "readwrite");

      etx.oncomplete = (_) => resolve(result);
      etx.onerror = (o) => reject(o.target.error);

      let objectStore = etx.objectStore("SecurityRoleAccess");
      const request = objectStore.get(key);
      request.onsuccess = (_) => {
        result = request.result;
        db.close();
      };
    };
  });

  return await promise;
}

const state = {
  accesses: [],
};

const mutations = {
  ADD_ACCESS: (state, access) => {
    state.accesses.push(access);
  },
  CLEAR_ACCESS: (state) => {
    state.accesses = [];
  },
};

const actions = {
  async clear() {
    await clear();
  },
  async addAccess(_, [key, value]) {
    await put(key, JSON.parse(JSON.stringify(value)));
  },
  async getAccess(_, key) {
    return getAsync(key);
  },
  load(context, key) {
    const lindexedDB =
      window.indexedDB ||
      window.mozIndexedDB ||
      window.webkitIndexedDB ||
      window.msIndexedDB ||
      window.shimIndexedDB;
    const lopen = lindexedDB.open("MDPSecurityRoleAccess", 10);
    const promise = new Promise((resolve) => {
      lopen.onsuccess = (_) => {
        let db = open.result;
        let etx = db.transaction("SecurityRoleAccess", "readwrite");
        let store = etx.objectStore("SecurityRoleAccess");
        let request = store.get(key);
        request.onsuccess = function () {
          context.commit("CLEAR_ACCESS");
          context.commit("ADD_ACCESS", request.result);
          resolve()
        };
      };
    })
    return promise
  },
};

const getters = {
  access: (state) => {
    return state.accesses;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
