<template>
  <ProSpaceModal
    v-model="localModelValue"
    class="competence-task-modal-form"
    width="900"
    height="570"
    :disabledApply="v$.model.$invalid || isSaving"
    :disabledCancel="isSaving"
    :withoutPadding="true"
    :needHideApply="false"
    :isDataLoading="isSaving"
    :title="id ? $t('updateCompetenceTaskModalTxt') : $t('createCompetenceTaskModalTxt')"
    :icon="id ? 'pen' : 'plus'"
    :cancelTxt="$t('reject')"
    :applyTxt="id ? $t('save') : $t('createItemTxt')"
    @apply="handleApply"
  >
    <ProSpaceGeoLayout>
      <template #center>
        <ProSpaceVLayout :gap="20" class="competence-task-modal-form__center">
          <ProSpaceDropdown
            style="width: calc(50% - 5px)"
            v-model="model.status"
            :label="$t('competenceTaskStatusTxt')"
            :placeholder="$t('competenceTaskStatusTxt')"
            :invalid="v$.model.status.$error"
            :invalid-messages="v$.model.status.$errors"
            @blur="v$.model.status.$touch"
            :service="baseTypeService"
            getMethod="grid"
            propValue="code"
            propLabel="name"
            :argMethod="statusDropdownArgMethod"
            @change="(item) => (model.status = item && item.code ? item.code : null)"
          />
          <ProSpaceInputText
            v-model="model.name"
            :label="$t('competenceTaskName')"
            :placeholder="$t('competenceTaskName')"
            :invalid="v$.model.name.$error"
            :invalid-messages="v$.model.name.$errors"
            @blur="v$.model.name.$touch"
          />
          <ProSpaceHLayout align-items="flex-start" :gap="10" isInlineH>
            <ProSpaceDropdown
              v-model="model.assignmentTo"
              :label="$t('competenceTaskAssignmentTo')"
              :placeholder="$t('competenceTaskAssignmentTo')"
              :invalid="v$.model.assignmentTo.$error"
              :invalid-messages="v$.model.assignmentTo.$errors"
              @blur="v$.model.assignmentTo.$touch"
              :service="baseTypeService"
              getMethod="grid"
              propValue="code"
              propLabel="name"
              argMethod="$filter=systemDirectoryCode eq 'User type' and disabled eq false"
              @change="
                (item) => (model.assignmentTo = item && item.code ? item.code : null)
              "
            />
            <ProSpaceInputNumber
              v-model="model.priority"
              likeText
              integeronly
              :min="0"
              :max="10"
              :label="$t('competenceTaskPriorityTxtZeroTen')"
              :placeholder="$t('competenceTaskPriorityTxtZeroTenPlaceholder')"
              :invalid="v$.model.priority.$error"
              :invalid-messages="v$.model.priority.$errors"
              @blur="v$.model.priority.$touch"
            />
          </ProSpaceHLayout>
          <ProSpaceInputText
            isTextarea
            v-model="model.actionPlan"
            :label="$t('competenceTaskActionPlan')"
            :placeholder="$t('competenceTaskActionPlanTxtPlaceholder')"
            :invalid="v$.model.actionPlan.$error"
            :invalid-messages="v$.model.actionPlan.$errors"
            @blur="v$.model.actionPlan.$touch"
          />
          <ProSpaceInputLayout :label="$t('taskAssignmentTemplateDateInfo')">
            <ProSpaceHLayout
              justify-content="space-between"
              align-items="flex-start"
              :gap="10"
            >
              <ProSpaceInputCalendar
                ref="startAt-ProSpaceInputCalendar"
                v-model="model.startAt"
                :minDate="minStartAt"
                :maxDate="model.endAt? new Date(model.endAt) : maxEndAt"
                :disabled="false"
                :label="$t('competenceStartAt')"
                :placeholder="$t('selectDate')"
                :invalid="v$.model.startAt.$error"
                :invalid-messages="v$.model.startAt.$errors"
                @blur="v$.model.startAt.$touch"
              />
              <ProSpaceInputCalendar
                ref="endAt-ProSpaceInputCalendar"
                v-model="model.endAt"
                :minDate="model.startAt? new Date(model.startAt): minStartAt"
                :maxDate="maxEndAt"
                :disabled="false"
                :label="$t('competenceEndAt')"
                :placeholder="$t('selectDate')"
                :invalid="v$.model.endAt.$error"
                :invalid-messages="v$.model.endAt.$errors"
                @blur="v$.model.endAt.$touch"
              />
            </ProSpaceHLayout>
          </ProSpaceInputLayout>
        </ProSpaceVLayout>
      </template>
      <template #right>
        <ProSpaceRightPanel>
          <template #center>
            <ProSpaceTabs v-model="modelTab" :tabs="tabs">
              <template v-slot:tab-1>
                <ProSpaceVLayout :gap="10">
                  <ProSpaceVLayout>
                    <ProSpaceHLayout
                      :gap="10"
                      is-inline-h
                      justify-content="space-between"
                    >
                      <ProSpaceInputText
                        v-model="newSubtaskText"
                        :placeholder="$t('enterSubtaskText')"
                        @submit="handleAddSubtaskButtonClick"
                        :disabled="subtasks.length >= 30"
                      />
                      <ProSpaceButton
                        :label="$t('add')"
                        type="secondary"
                        @click="handleAddSubtaskButtonClick"
                        :disabled="subtasks.length >= 30"
                      />
                    </ProSpaceHLayout>
                  </ProSpaceVLayout>
                  <ProSpaceVLayout :gap="10">
                    <ProSpaceHLayout
                      v-for="(subtask, index) in subtasks"
                      :key="subtask.text"
                      is-inline-h
                      justify-content="space-between"
                    >
                      <ProSpaceCheckbox
                        style="width: calc(100% - 20px);"
                        class="competence-task-modal-form__checkbox"
                        v-model="subtaskCheckbox[subtask.text]"
                        :changeObj="subtask"
                        :fiveLinesLabel="true"
                      />
                      <ProSpaceIconButton
                        icon="circle-close"
                        @click="() => handleDeleteSubtask(index)"
                      />
                    </ProSpaceHLayout>
                  </ProSpaceVLayout>
                </ProSpaceVLayout>
              </template>
              <template v-slot:tab-2>
                <ProSpacePanelBlock
                  :header="commentsHeaderOverview"
                  class="padding-bottom-10"
                >
                  <template v-slot:header>
                    <ProSpaceAction
                      :text="$t('addComment')"
                      @click="handleAddCommentClick"
                    />
                  </template>
                  <template #default>
                    <ProSpaceEmptyGrid
                      v-if="comments.length === 0"
                      icon="error-message"
                      :title="$t('noDataTitle')"
                      :text="$t('noDataTxt')"
                    />
                    <ProSpaceVLayout :gap="20" is-inline-h>
                      <div
                        class="competence-task-modal-form__comment w-100 ui-shadow padding-10"
                        v-for="(comment, index) in comments"
                        :key="index"
                      >
                        <ProSpaceVLayout :gap="10">
                          <ProSpaceHLayout
                            justify-content="space-between"
                            :gap="10"
                            is-inline-h
                          >
                            <div class="text-size-11 text-color-icon-gray">
                              {{ getFormatDateTime(comment.createdAt) }}
                            </div>
                            <ProSpaceTag
                              type="gray-light"
                              bordered
                              regular
                              :text="
                                comment.id
                                  ? $t(comment.securityUserType)
                                  : $t(`${userinfo.orgLinkType || 'Mars'}`)
                              "
                            />
                          </ProSpaceHLayout>
                          <div class="text-size-12 text-color-main text-bold">
                            {{
                              comment.id
                                ? comment.securityUserFullName
                                : `${userinfo.lastName} ${userinfo.firstName} ${userinfo.secondName ? userinfo.secondName : ''}`
                            }}
                          </div>
                          <div>
                            <div class="text-size-12 text-color-icon-gray">
                              {{ $t("comment") }}
                            </div>
                            <div class="text-size-12 text-color-gray">
                              {{ comment.comment }}
                            </div>
                          </div>
                        </ProSpaceVLayout>
                      </div>
                    </ProSpaceVLayout>
                  </template>
                </ProSpacePanelBlock>
              </template>
              <template v-slot:tab-3>
                <ProSpaceFile
                  v-model="attachments"
                  ref="fileUploadField"
                  name="tst"
                  accept=".jpeg, .jpg, .png, .doc, .docx, .docm, .rtf, .txt, .csv, .xml, .pdf, .gif, .ppt, .pptx, .potx, .potm, .pptm, .ppsx, .ppsm, .xls, .xlsx, .xlsm, .xlsb, .zip, .mp4, .avi, .mkv, .wmv"
                  :fileLimit="1000"
                  :maxFileSize="209715200"
                  :service="fileService"
                  :txtBtnUpload="$t('uploadFile')"
                />
              </template>
            </ProSpaceTabs>
          </template>
        </ProSpaceRightPanel>
      </template>
    </ProSpaceGeoLayout>

    <ProSpaceModal
      v-model="showAddCommentModal"
      v-if="showAddCommentModal"
      width="410"
      :disabledApply="false"
      :title="$t('addComment')"
      icon="plus"
      @apply="handleApplyAddComment"
      :cancelTxt="$t('reject')"
      :applyTxt="$t('send')"
    >
      <ProSpaceInputText
        isTextarea
        v-model="newCommentText"
        :label="$t('comment')"
        :placeholder="$t('competenceTaskCommentText')"
      />
    </ProSpaceModal>
  </ProSpaceModal>
</template>

<script>
import {
  ProSpaceModal,
  ProSpaceFile,
  ProSpaceGeoLayout,
  ProSpaceIconButton,
  ProSpaceButton,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceLabel,
  ProSpaceAction,
  ProSpaceIcon,
  ProSpaceDropdown,
  ProSpaceTabs,
  ProSpaceInputText,
  ProSpaceInputNumber,
  ProSpaceInputCalendar,
  ProSpaceCheckbox,
  ProSpaceEmptyGrid,
  ProSpacePanelBlock,
  ProSpaceRightPanel,
  ProSpaceTag,
  ProSpaceInputLayout,
} from "prospace-components-library";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { mapGetters } from "vuex";
import { required, maxLength, helpers, minValue, maxValue } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { BaseTypeService } from "../../../../../BaseType/BaseTypeService.Frontend/services/BaseTypeService";
import { FileService } from "../../../../../../Frontends/ProSpace/services/FileService.js";
import { CompetenceTaskService } from "../../../services/CompetenceTaskService.js";
import { CompetenceTaskCommentService } from "../../../services/CompetenceTaskCommentService.js";
import { CompetenceTaskAttachmentService } from "../../../services/CompetenceTaskAttachmentService";
import moment from "moment";

export default {
  name: "ModalForm",
  components: {
    ProSpaceModal,
    ProSpaceFile,
    ProSpaceGeoLayout,
    ProSpaceIconButton,
    ProSpaceButton,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceLabel,
    ProSpaceAction,
    ProSpaceIcon,
    ProSpaceDropdown,
    ProSpaceTabs,
    ProSpaceInputText,
    ProSpaceInputNumber,
    ProSpaceInputCalendar,
    ProSpaceCheckbox,
    ProSpaceEmptyGrid,
    ProSpacePanelBlock,
    ProSpaceRightPanel,
    ProSpaceTag,
    ProSpaceInputLayout,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: () => {
        return null;
      },
    },
    competence: {
      type: Object,
      default: () => {
        return null;
      },
    },
    id: {
      type: Number,
      default: () => {
        return null;
      },
    },
    minStartAt: {
      type: Date,
      default: () => {
        return null;
      },
    },
    maxEndAt: {
      type: Date,
      default: () => {
        return null;
      },
    },
    afterSaveHandler: {
      type: Function,
      default: () => null,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      moment,
      model: null,
      subtasks: [],
      comments: [],
      attachments: [],
      attachmentsToCreate: [],
      subtaskCheckbox: {},
      newSubtaskText: "",
      newCommentText: "",
      showAddCommentModal: false,
      modelTab: {
        name: this.$t("parameters"),
        isActive: true,
        systemName: "parameters",
      },
      filesCount: 0,
      baseTypeService: new BaseTypeService(),
      fileService: new FileService(),
      competenceTaskService: new CompetenceTaskService(),
      competenceTaskCommentService: new CompetenceTaskCommentService(),
      competenceTaskAttachmentService: new CompetenceTaskAttachmentService(),

      isSaving: false,
    };
  },
  created() {
    this.model = this.emptyModel();
  },
  validations() {
    return {
      model: {
        name: {
          required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), required),
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", { maxLength: 100 }),
            maxLength(100)
          ),
        },
        actionPlan: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", { maxLength: 3000 }),
            maxLength(3000)
          ),
        },
        status: {
          required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), required),
          maxLength: helpers.withMessage(this.$t("maxLengthErrorMessage", { maxLength: 50 }), maxLength(50)),
        },
        assignmentTo: {
          required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), required),
          maxLength: helpers.withMessage(this.$t("maxLengthErrorMessage", { maxLength: 50 }), maxLength(50)),
        },
        priority: {
          minValue: helpers.withMessage(this.$t("minValueErrorMessage") + '0', minValue(0)),
          maxValue: helpers.withMessage(this.$t("maxValueErrorMessage")+ '10', maxValue(10)),
        },
        startAt: {
          required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), required),
        },
        endAt: {
          required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), required),
        },
      },
    };
  },
  methods: {
    emptyModel() {
      return {
        name: null,
        status: "New",
        assignmentTo: null,
        priority: null,
        actionPlan: null,
        startAt: null,
        endAt: null,
        competenceId: null,
        competenceCompetenceDirectoryName: null,
        competenceStatus: null,
        attachments: [],
        subtasks: "[]",
      };
    },
    handleAddSubtaskButtonClick() {
      if (typeof this.newSubtaskText !== "string" || this.newSubtaskText.length === 0)
        return;

      if (this.subtasks.some((e) => e.text === this.newSubtaskText)) return;
      this.subtasks.push({ text: this.newSubtaskText, checked: false });
      this.subtaskCheckbox[this.newSubtaskText] = [
        { label: this.newSubtaskText, field: "checked" },
      ];
      this.newSubtaskText = "";
    },
    handleApply() {
      this.isSaving = true;
      const saveMethod = this.id ? "update" : "create";
      this.startDateUpdate();
      this.endDateUpdate();

      this.createFilesRoot()
        .then(async (response) => {
          let newFiles = this.setNewAttachments(response.data.data);

          this.model.subtasks = JSON.stringify(this.subtasks);
          this.competenceTaskService[saveMethod](this.model)
            .then(async (result) => {
              this.model.id = result.data[0].id;
              if (saveMethod === "create") {
                for (const comment of this.comments) {
                  comment.competenceTaskId = result.data[0].id;
                }
                if (this.comments.length > 0) {
                  await this.competenceTaskCommentService.create(this.comments);
                }
              }

              if (newFiles.length > 0) {
                await this.upload(
                  newFiles,
                  this.attachmentsToCreate,
                  this.uploadSuccess,
                  this.uploadError
                );
              }

              this.clearModel();
              if (this.afterSaveHandler) this.afterSaveHandler(result.data[0]);
              this.isSaving = false;
              this.localModelValue = false;
            })
            .catch(() => {
              this.isSaving = false;
            });
        })
        .catch(() => {
          this.isSaving = false;
        });
    },
    async upload(newFiles, toCreate, successCallback, errorCallback) {
      await this.fileService.uploadChunks(
        newFiles,
        toCreate,
        successCallback,
        errorCallback
      );
    },
    async uploadSuccess(gfids) {
      await this.competenceTaskAttachmentService.acceptFiles(gfids, this.model.id);
    },
    async uploadError(gfids) {
      await this.competenceTaskAttachmentService.errorFiles(gfids, this.model.id);
    },
    setNewAttachments(attachments) {
      let newFiles = attachments.map((f, i) => {
        return {
          fileName: f.fileName,
          gfid: f.gfid,
          fileLength: this.attachmentsToCreate[i].size,
        };
      });
      if (!this.model.attachments) {
        this.model.attachments = [];
      }
      this.model.attachments = this.model.attachments.concat(newFiles);
      return newFiles;
    },
    createFilesRoot() {
      this.attachmentsToCreate = this.attachments.filter((f) => !f.gfid);
      const actualFiles = this.attachments.filter((f) => f.gfid).map((f) => f.gfid);
      if (this.model.attachments)
        this.model.attachments = this.model.attachments.filter((f) =>
          actualFiles.includes(f.gfid)
        );
      if (this.attachmentsToCreate.length > 0) {
        let fileInfos = this.attachmentsToCreate
          .filter((f) => !f.id)
          .map((f) => {
            return {
              fileName: f.name,
              fileVisibleFlag: 0,
            };
          });
        return this.fileService.createFilesRoot(fileInfos);
      }
      return new Promise((resolve, reject) => {
        resolve({ data: { data: [] } });
      });
    },
    handleDeleteSubtask(index) {
      this.subtasks.splice(index, 1);
    },
    handleAddCommentClick() {
      this.showAddCommentModal = true;
    },
    async handleApplyAddComment() {
      let comment = {
        comment: this.newCommentText,
        createdAt: new Date(),
        competenceTaskId: this.model.id ?? null,
        securityUserFullName: "",
        securityUserType: "",
      };
      if (this.id) {
        comment = (await this.competenceTaskCommentService.create(comment)).data[0];
      }
      this.comments.push(comment);
      this.newCommentText = "";
    },
    closeModal() {
      this.clearModel();
      this.$emit("update:modelValue", false);
    },
    clearModel() {
      this.model = this.emptyModel();
      this.subtasks = [];
      this.subtaskCheckbox = {};
      this.newSubtaskText = "";
      this.attachmentsToCreate = [];
      this.attachments = [];
      this.comments = [];
      this.v$.$reset();
    },
    createFilesRoot() {
      this.attachmentsToCreate = this.attachments.filter((f) => !f.gfid);
      const actualFiles = this.attachments.filter((f) => f.gfid).map((f) => f.gfid);
      if (this.model.attachments)
        this.model.attachments = this.model.attachments.filter((f) =>
          actualFiles.includes(f.gfid)
        );
      if (this.attachmentsToCreate.length > 0) {
        let fileInfos = this.attachmentsToCreate
          .filter((f) => !f.id)
          .map((f) => {
            return {
              fileName: f.name,
              fileVisibleFlag: 0,
            };
          });
        return this.fileService.createFilesRoot(fileInfos);
      }
      return new Promise((resolve, reject) => {
        resolve({ data: { data: [] } });
      });
    },
    handleDeleteSubtask(index) {
      this.subtasks.splice(index, 1);
    },
    handleAddCommentClick() {
      this.showAddCommentModal = true;
    },
    async handleApplyAddComment() {
      let comment = {
        comment: this.newCommentText,
        createdAt: new Date(),
        competenceTaskId: this.model.id ?? null,
        securityUserFullName: "",
        securityUserType: "",
      };
      if (this.id) {
        comment = (await this.competenceTaskCommentService.create(comment)).data[0];
      }
      this.comments.push(comment);
      this.newCommentText = "";
    },
    startDateUpdate() {
      if (this.model.startAt !== null) {
        let momentDate = this.moment(this.model.startAt);
        if (
          momentDate.hours() == 0 &&
          momentDate.minutes() == 0 &&
          momentDate.seconds() == 0
        ) {
          this.model.startAt = momentDate.format("YYYY-MM-DDTHH:mm:ss");
          return;
        }

        let startMomentDate = momentDate.startOf("day");
        this.model.startAt = startMomentDate.format("YYYY-MM-DDTHH:mm:ss");
      }
    },
    endDateUpdate() {
      if (this.model.endAt !== null) {
        let momentDate = this.moment(this.model.endAt);
        if (
          momentDate.hours() == 23 &&
          momentDate.minutes() == 59 &&
          momentDate.seconds() == 59
        ) {
          this.model.endAt = momentDate.format("YYYY-MM-DDTHH:mm:ss");
          return;
        }

        let endMomentDate = momentDate.endOf("day");
        this.model.endAt = endMomentDate.format("YYYY-MM-DDTHH:mm:ss");
      }
    },
  },
  watch: {
    localModelValue: {
      handler(value) {
        if (!value && !this.isSaving) {
          this.clearModel();
          this.modelTab = this.tabs[0];
        } else if (this.isSaving) {
          this.isSaving = false;
        }

        if (value) {
          this.modelTab = this.tabs[0];
          if (this.id) {
            this.competenceTaskService.get(this.id).then((response) => {
              this.model = response.data;
              this.subtasks = JSON.parse(this.model.subtasks);
              this.subtasks.forEach(
                (s) =>
                  (this.subtaskCheckbox[s.text] = [{ label: s.text, field: "checked" }])
              );
              this.attachments = this.model.attachments.map((f) => {
                let r = new File([], f.fileName);
                r.gfid = f.gfid;
                r.fileLength = f.fileLength;
                r.statusFlag = f.statusFlag;
                return r;
              });
              this.competenceTaskCommentService
                .grid(`$filter=CompetenceTaskId eq ${this.id}`)
                .then((commentReponse) => {
                  this.comments = commentReponse.data.records;
                });
            });
          } else if (this.competence) {
            this.model = this.emptyModel();
            this.v$.$reset();
            this.model.competenceId = this.competence.Id;
            this.model.competenceCompetenceDirectoryName = this.competence.CompetenceDirectoryName;
            this.model.competenceStatus = this.competence.Status;
          }
        }
      },
    },
    showAddCommentModal: {
      handler(value) {
        if (!value) this.newCommentText = null;
      },
    },
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime",
    }),
    commentsHeaderOverview() {
      return {
        title: this.$t("competenceTaskComments"),
        isExpand: false,
      };
    },
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        this.$emit("update:modelValue", newVal);
      },
    },
    icon() {
      return { faFileUpload };
    },
    tabs() {
      if (!this.model) return [];
      const tabs = [
        {
          name: this.$t("competenceTaskSubtasks"),
          systemName: "competenceTaskSubtasks",
          isActive: this.modelTab.systemName === "competenceTaskSubtasks",
        },
        {
          name: this.$t("competenceTaskComments"),
          systemName: "competenceTaskComments",
          isActive: this.modelTab.systemName === "competenceTaskComments",
        },
        {
          name: this.$t("competenceAttachments"),
          systemName: "competenceAttachments",
          isActive: this.modelTab.systemName === "competenceAttachments",
        },
      ];
      if (!tabs.some((i) => i.isActive)) {
        tabs[0].isActive = true;
        this.modelTab = tabs[0];
      }
      return tabs;
    },
    statusDropdownArgMethod() {
      const userTypeFilter =
        this.userinfo.orgLinkType == "Mars"
          ? "code ne 'RejectedByDistributor'"
          : "code ne 'RejectedByMars' and code ne 'Completed'";
      return `$filter=systemDirectoryCode eq 'CompetenceTaskStatus' and ${userTypeFilter} and disabled eq false`;
    },
  },
};
</script>

<style lang="scss">
.competence-task-modal-form {
  &__center {
    padding: 20px 50px;
  }
  &__checkbox {
    .prospace-checkbox__label {
      white-space: normal !important;
    }
  }
  .prospace-right-panel {
    min-width: 300px;
  }
}
</style>
