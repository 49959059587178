/*
Обработчики ошибок (исключения\бизнессовые)
вызываются в Application\Frontends\Common\utils\request\requestMain.js
задаются в Application\Frontends\ProSpace\components\BaseLayout.vue
*/

const state = {
    errorCodes: [],
    handler: () => {},
    buisnessErrorHandler: () => {},
  };
  
  const mutations = {
    SET_ERRORCODES: (state, data) => {
      state.errorCodes = data;
    },
    SET_HANDLER: (state, handler) => {
      state.handler = handler;
    },
    HANDLE: (state, error) => {
      state.handler(error);
    },
    SET_BUISNESS_ERROR_HANDLER: (state, handler) => {
      state.buisnessErrorHandler = handler;
    },
    BUISNESS_ERROR_HANDLE: (state, error) => {
      state.buisnessErrorHandler(error);
    }
  };
  
  const actions = {
    setErrorCodes(context, data) {
      context.commit("SET_ERRORCODES", data);
    },
    setHandler(context, handler) {
      context.commit("SET_HANDLER", handler);
    },
    handleError(context, error) {
      context.commit("HANDLE", error);
    },
    setBuisnessErrorHandler(context, handler) {
      context.commit("SET_BUISNESS_ERROR_HANDLER", handler);
    },
    handleBuisnessErrorError(context, error) {
      context.commit("BUISNESS_ERROR_HANDLE", error);
    }
  };
  
  var getters = {
    getErrorCodes: (state) => {
      return state.errorCodes;
    },
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };
  