import request from "../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/workflow/workflowsettingitem/";

export default class WorkflowItemApi {
  constructor() {
    this.defaultModel = {
      id: 0,
      name: "",
      stepNo:0,
      submitStep:0,
      rejectStep:0,
    };
  }
  async get(params) {
    return await request.post(`${rootRoute}get`, {
      filter: params,
      parameters: {},
    });
  }
  async getById(id) {
    return await request.post(`${rootRoute}get`, {
      filter: `$filter=id eq ${id}`,
      parameters: {},
    });
  }
  async getByWorkflowId(params) {
    return await request.post(`${rootRoute}get`, {
      filter: params,
      parameters: {},
    });
  }
  async getMainGridByWorkflowId(params, id) {
    return await request.post(`${rootRoute}get`, {
      filter: `${params}`,
      parameters: {},
    });
  }
  async create(model) {
    const data = Array.isArray(model)? model : [_.merge(this.defaultModel, model)];
    return await request.post(`${rootRoute}create`, { data });
  }
  async delete(model) {
    const data = [model];
    return await request.post(`${rootRoute}delete`, { data });
  }
  async update(model) {
    const data = Array.isArray(model)? model : [_.merge(this.defaultModel, model)];
    return await request.post(`${rootRoute}update`, { data });
  }
}
