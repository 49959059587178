<template>
  <ProSpaceRightPanel
    :header="header"
    :has-error="hasError"
    :loading="loading"
    @edit="editPanel"
    @delete="deletePanel"
    @clear="clearPanel"
    @show-history="showHistoryPanel"
  >
    <template v-slot:center>
      <ProSpacePanelBlock :header="headerRoles">
        <template #header>
          <ProSpaceAction :text="$t('editItemTxt')" @click="onClickEdit" />
        </template>
        <ProSpacePanelBlockList :list="content" />
      </ProSpacePanelBlock>
    </template>
    <template v-slot:bottom>
      <ProSpacePanelBlock :header="headerBottom">
        <template v-slot>
          <ProSpacePanelBlockList :list="contentBottom" />
        </template>
      </ProSpacePanelBlock>
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceRightPanel,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceAction,
  ProSpacePanelBlockHeader,
} from "prospace-components-library";
import { mapGetters } from "vuex";
import {
  useMultiSelectRightPanel,
} from "@composes/multiselect"
export default {
  name: "RolePanel",
  components: {
    ProSpaceRightPanel,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceAction,
    ProSpacePanelBlockHeader
  },
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    service: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { selectedData } = useMultiSelectRightPanel()
    return {
      selectedData
    }
  },
  data() {
    return {
      role: null,
      header: {},
      loading: false,
      hasError: false,
    };
  },
  computed: {
    ...mapGetters({
      getFormatDateTime: "userinfoStore/getFormatDateTime"
    }),
    headerRoles() {
      return {
        title: this.$t("role"),
        isExpand: false,
      };
    },
    headerBottom() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    header() {
      if (this.role) {
        return {
          title: this.role.systemName,
          id: this.role.id,
          status: this.getStatus(this.role.status),
          options: this.options,
          showCounter: false,
        };
      }
      return {
        title: this.$t("itemsSelected"),
        showCounter: false,
      };
    },
    content() {
      if (this.role) {
        return [
          [
            {
              title: this.$t("name"),
              text: this.role.systemName,
            },
          ],
        ];
      }
      return [];
    },
    contentBottom() {
      if (this.role) {
        return [
          [
            {
              title: this.$t("createdByTxt"),
              text: this.role.createdBy,
            },
            {
              title: this.$t("updatedByTxt"),
              text: this.role.updatedBy,
            },
          ],
          [
            {
              title: this.$t("createdAtTxt"),
              text: this.getFormatDateTime(this.role.createdAt),
            },
            {
              title: this.$t("lastUpdatedTxt"),
              text: this.getFormatDateTime(this.role.lastUpdated),
            },
          ],
        ];
      }
      return [];
    },
  },
  mounted() {
    this.update();
  },
  watch: {
    selectedData() {
      this.update();
    },
  },
  methods: {
    editPanel() {
      this.$emit("edit", this.role);
    },
    deletePanel() {
      this.$emit("delete-item", this.role);
    },
    clearPanel() {
      this.$emit("clear");
    },
    showHistoryPanel() {
      this.$emit("history-item", this.role);
    },
    async update() {
      this.hasError = false;
      try {
        this.role = await this.getRole();
      } catch (e) {
        this.hasError = true;
      }
    },
    async getRole() {
      if (this.selectedData) {
        this.loading = true;
        const res = await this.service.get(this.selectedData.id);
        this.loading = false;
        return res.data;
      }
      return null;
    },
    getStatus(status) {
      return {
        type: status ? (status.toLowerCase() === "active" ? "success" : "danger") : 'no-data',
        text: status ? (status[0].toUpperCase() + status.toLowerCase().slice(1)) : 'No data',
      };
    },
    onClickEdit() {
      this.$emit("edit", this.role);
    },
  },
};
</script>
