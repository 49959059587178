import moment from "moment"

const useLocalFilter = () => {
    const getLocalFilteredItems = (items, {filter, search}) => {
        if (!Array.isArray(items)) {
            throw new Error('need array')
        }
        const entriesFilter = Object.entries(filter)
        const searchObj = getSearchObj(search)
        const filterItems = items.filter(i => {
            let isInclude = true
            for (const [field, filter] of entriesFilter) {
                // получить значение фильтра
                const valueFilter = getValue(filter)
                if (valueFilter === null) {
                    continue
                }
                // проверить значение
                const valueField = i[field]
                if (Array.isArray(valueFilter)) {
                    isInclude = checkArray(valueField, valueFilter)
                } else if (typeof valueFilter === "object" && (valueFilter.ge || valueFilter.le)) {
                    isInclude = checkRange(valueField, valueFilter)
                } else {
                    isInclude = checkSimpleValue(valueField, valueFilter)
                }
                // если не прошла проверка, то выйти из цикла
                if (!isInclude) {
                    break
                }
            }
            if (!isInclude || !searchObj) {
                return isInclude
            }
            // проверка поисковой строки
            isInclude = searchObj.keys.some((field) => i[field] && i[field].toLowerCase().includes(searchObj.value))
            return isInclude
        })
        return filterItems
    }
    return {
        getLocalFilteredItems
    }
}

const getSearchObj = (search) => {
    if (Object.keys(search).length === 0) {
        return null
    }
    const keys = search.or.map(i => Object.keys(i)[0])
    const value = Object.values(search.or[0])[0].contains.toLowerCase()
    return {keys, value}
}

const getValue = (filter) => {
    if (!filter) {
        return null
    }
    if (filter.and) {
        return filter.and[0]
    } else if (filter.or) {
        return filter.or.map(i => i.contains)
    }
}

const checkArray = (value, arr) => {
    value = value.toLowerCase()
    return arr.some(i => value.includes(i.toLowerCase()))
}

const checkRange = (value, filter) => {
    const isDate = typeof (filter.ge || filter.le) === "object"
    if (isDate) {
        return checkRangeDates(value, filter)
    } else {
        return checkRangeNumbers(value, filter)
    }
}

const checkRangeDates = (value, filter) => {
    return moment(value).isBetween(filter.ge, filter.le)
}

const checkRangeNumbers = (value, filter) => {
    let isGe = true
    let isLe = true
    if (filter.ge) {
        isGe = filter.ge <= value
    }
    if (filter.le) {
        isLe = filter.le >= value
    }
    return isGe && isLe
}

const checkSimpleValue = (value, filter) => {
    return value === filter
}

export default useLocalFilter