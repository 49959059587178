<template>
  <ProSpaceGeoLayout class="basetype" v-if="model">
    <template #top>
      <ProSpaceTopPanel :header="layoutName" back @back="backHandler">
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout :is-templated="true" :template-center="true">
        <template #center>
          <ProSpaceTabs
            :tabs="overviewTabs"
            :loading="loading"
            :refLoading="mixinLoadingAccess"
            :withoutMarginBottom="true"
            @select-tab="handlerSelectTab"
          >
            <ProSpaceAction
              ref="action-save"
              icon="check"
              :disabled="v$.model.$invalid"
              :text="$t('save')"
              @click="savePart(saveFields.Overview)"
            />
            <template v-slot:tab-1>
              <ProSpaceVLayout>
                <ProSpaceGeoLayout class="geo-row">
                  <template
                    v-if="prefixTopDict || prefixBottomDict"
                    v-slot:left
                  >
                    <ProSpaceTextBlock
                      :top="prefixTopDict"
                      :bottom="prefixBottomDict"
                      :gap="10"
                      break-word
                      type="third"
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceHLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="10"
                    >
                      <ProSpaceDropdown
                        ref="systemDirectory-ProSpaceDropdown"
                        v-model="model.systemDirectoryId"
                        getMethod="grid"
                        propValue="id"
                        propLabel="name"
                        argMethod="$filter=(disabled eq false)&$orderby=name asc"
                        :label="$t('systemDirectory')"
                        :placeholder="$t('systemDirectory')"
                        :invalid="v$.model.systemDirectoryId.$error"
                        :invalid-messages="v$.model.systemDirectoryId.$errors"
                        :service="systemDirectoryService"
                        @blur="v$.model.systemDirectoryId.$touch"
                        @change="
                          (item) => (model.systemDirectoryName = item?.name)
                        "
                      />
                    </ProSpaceHLayout>
                  </template>
                  <template v-if="v$.model.systemDirectoryId.$error" v-slot:right>
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>
                <ProSpaceGeoLayout class="geo-row geo-row--without-scroll">
                  <template
                    v-if="prefixTopType || prefixBottomType"
                    v-slot:left
                  >
                    <ProSpaceTextBlock
                      :top="prefixTopType"
                      :bottom="prefixBottomType"
                      :gap="10"
                      break-word
                      type="third"
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceVLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="20"
                    >
                      <ProSpaceInputText
                        ref="name-ProSpaceInputText"
                        v-model="model.name"
                        :label="$t('baseTypeName')"
                        :placeholder="$t('baseTypeName')"
                        :invalid="v$.model.name.$error"
                        :invalid-messages="v$.model.name.$errors"
                        @blur="v$.model.name.$touch"
                      />
                      <ProSpaceDropdown
                        ref="icon-ProSpaceDropdown"
                        v-model="model.icon"
                        getMethod="getIcons"
                        :label="$t('icon')"
                        :service="baseTypeService"
                        :invalid="v$.model.icon.$error"
                        :invalid-messages="v$.model.icon.$errors"
                        @blur="v$.model.icon.$touch"
                      >
                        <template #value="slotProps">
                          <ProSpaceHLayout :gap="5">
                            <ProSpaceIcon :icon="slotProps.value" />
                            {{ slotProps.value }}
                          </ProSpaceHLayout>
                        </template>
                        <template #option="slotProps">
                          <ProSpaceHLayout :gap="5">
                            <ProSpaceIcon :icon="slotProps.option.label" />
                            <span class="text-size-12">{{
                              slotProps.option.label
                            }}</span>
                          </ProSpaceHLayout>
                        </template>
                      </ProSpaceDropdown>
                      <ProSpaceInputText
                        ref="description-ProSpaceInputText"
                        v-model="model.description"
                        :label="$t('baseTypeDescription')"
                        :placeholder="$t('baseTypeDescription')"
                        :invalid="v$.model.description.$error"
                        :invalid-messages="v$.model.description.$errors"
                        :isTextarea="true"
                        @blur="v$.model.description.$touch"
                      />
                    </ProSpaceVLayout>
                  </template>
                  <template
                    v-if="
                      v$.model.name.$error ||
                      v$.model.description.$error ||
                      v$.model.icon.$error
                    "
                    v-slot:right
                  >
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>
              </ProSpaceVLayout>
            </template>
          </ProSpaceTabs>
        </template>
      </ProSpaceGeoLayout>
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import {
  ProSpaceTopPanel,
  ProSpaceInputText,
  ProSpaceAction,
  ProSpaceRadioButtonSecondary,
  ProSpaceGeoLayout,
  ProSpaceTabs,
  ProSpaceTextBlock,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceDropdown,
  ProSpaceInputCalendar,
  ProSpaceInputNumber,
  ProSpaceMultiSelect,
  ProSpaceCustomFilterPanel,
  ProSpaceIcon,
  ProSpaceButton,
  ProSpaceWarning,
} from "prospace-components-library";
import { BaseTypeService } from "../../services/BaseTypeService.js";
import { SystemDirectoryService } from "../../services/SystemDirectoryService.js";
import {
  required,
  maxLength,
  helpers,
  minValue,
  maxValue,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {
  useStatistic
} from "@composes"
export default {
  mixins: [accessMixin],
  components: {
    ProSpaceTopPanel,
    ProSpaceGeoLayout,
    ProSpaceInputText,
    ProSpaceAction,
    ProSpaceRadioButtonSecondary,
    ProSpaceTabs,
    ProSpaceTextBlock,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceDropdown,
    ProSpaceInputCalendar,
    ProSpaceInputNumber,
    ProSpaceMultiSelect,
    ProSpaceCustomFilterPanel,
    ProSpaceIcon,
    ProSpaceButton,
    ProSpaceWarning,
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    return { v$: useVuelidate(), setStatistic, distActions };
  },
  data() {
    this.tabs = [{ label: "Overview", value: "Overview" }];
    return {
      // Tabs
      modelTab: this.tabs[0], // Первое предустановленное значение

      // Common
      layoutName: "",
      model: null,
      loading: false,

      // Service
      baseTypeService: new BaseTypeService(),
      systemDirectoryService: new SystemDirectoryService(),

      getMethod: "get",
      masterId: null,

      // SaveFields
      saveFields: {
        Overview: [
          "name",
          "description",
          "icon",
          "systemDirectorySystemDirectory",
          "systemDirectoryId",
        ],
      },
    };
  },
  validations() {
    return {
      model: {
        name: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '100'}),
            maxLength(100)
          ),
        },
        description: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '255'}),
            maxLength(255)
          ),
        },
        icon: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '50'}),
            maxLength(50)
          ),
        },
        systemDirectoryId: {},
      },
    };
  },
  mounted() {
    this.mountAccess();
    this.getData();
  },
  methods: {
    gridLoaded() {},
    handlerSelectTab({ selectedTab }) {
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: selectedTab.systemName
      })
    },
    async getData() {
      this.masterId = this.$route.params.id;
      if (this.masterId === undefined) {
        // redirect to 404
        return;
      }
      const result = await this.baseTypeService[this.getMethod](this.masterId);
      this.model = result.data;
      this.layoutName = `${this.model.name}`;
    },
    saveAll() {
      this.loading = true;
      this.baseTypeService.set().then(() => {
        this.getData();
        this.loading = false;
      });
    },
    savePart(fields) {
      this.loading = true;
      this.baseTypeService.setPart(fields).then(() => {
        this.getData();
        this.loading = false;
      });
    },
    submitFilter(nameRef = "grid") {
      if (!this.$refs[nameRef]) return;
      this.$refs[nameRef].getRecords(true);
    },
    backHandler() {
      this.$router.go(-1);
    },
  },
  computed: {
    refsForCheckInMixin() {
      let refs = [
        "action-save",
        "systemDirectory-ProSpaceDropdown",
        "name-ProSpaceInputText",
        "icon-ProSpaceDropdown",
        "description-ProSpaceInputText",
      ];

      return refs;
    },
    overviewTabs() {
      return [{ name: this.$t("overview"), systemName: "overview" }];
    },
    prefixTopDict() {
      return this.$t("systemDirectory");
    },
    prefixBottomDict() {
      return this.$t("systemDirectoryInfo");
    },
    prefixTopType() {
      return this.$t("baseType");
    },
    prefixBottomType() {
      return this.$t("baseTypeInfo");
    },
  },
};
</script>
<style lang="scss">
.geo-row--without-scroll {
  .prospace-geo-layout__center {
    overflow: inherit;
  }
}
</style>
