import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/WorkflowApi";

export class WorkflowService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "get", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getById", null, id);
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "create", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "delete", model);
  }
  async edit(model) {
    return await this.transformer.editItem(this.Api, "update", model);
  }
  async localGet(id) {
    return await this.transformer.localGetById(id);
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(
      this.Api,
      "update",
      fields
    );
  }
  async createDraft(model) {
    return await this.transformer.createItem(this.Api, "createDraft", model);
  }
  async setActive(model) {
    return await this.transformer.editItem(this.Api, "setActive", model);
  }
}
