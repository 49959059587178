import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/masterdata/distributorstructureview/";

export default class DistributorStructureApi {
  constructor() {
  }
  async getDistributorStructures(params) {
    return await request.post(`${rootRoute}getDistributorStructureViews`, {
      filter: params,
      parameters: {},
    });
  }
  async getFilialStructures(params) {
    return await request.post(`${rootRoute}getFilialStructureViews`, {
      filter: params,
      parameters: {},
    });
  }
  async getDistributorStructure(id) {
    return await request.post(`${rootRoute}getDistributorStructureViews`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async getDistributorStructuresPositions(filter) {
    return await request.post(`${rootRoute}getDistributorStructuresPositions`, {
      filter: filter
    });
  }
  async getAssignedBranch(nodeIds) {
    return await request.post(`${rootRoute}getAssignedBranch`, { data: nodeIds });
  }
  async getAssignedBranchByDocId(docId) {
    return await request.post(`${rootRoute}getAssignedBranchByDocId`, { docId: docId });
  }
  async getAssignedBranchByNewsId(newsId) {
    return await request.post(`${rootRoute}getAssignedBranchByNewsId`, { newsId: newsId });
  }
  async getAssignedBranchByUserId(userId) {
    return await request.post(`${rootRoute}getAssignedBranchByUserId`, { userId: userId });
  }
  async getAssignedBranchByTaskAssignmentId(taskAssignmentId) {
    return await request.post(`${rootRoute}getAssignedBranchByTaskAssignmentId`, { taskAssignmentId: taskAssignmentId });
  }
  async get(filter) {
    return await request.post(`${rootRoute}getDistributorStructureViews`, {
      filter
    });
  }
}
