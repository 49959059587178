import request from "../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/systemsetting/";

export default class SystemSettingApi {
  async getSystemSetting(params) {
    return await request.post(`${rootRoute}systemsettingtree/getsystemsettingnodes`, {
      filter: params,
      parameters: {},
    });
  }

  async getSystemSettingItems(params) {
    return await request.post(`${rootRoute}systemsettingitem/getsystemsettingitems`, {
      filter: `$filter=Path eq '${params}'`,
    });
  }

  async updateSystemSettingItems(models) {
    return await request.post(`${rootRoute}systemsettingitem/updatesystemsettingitem`, {
      data: models
    });
  }

  async getSystemSettingNode(params) {
    return await request.post(`${rootRoute}systemsettingtree/getsystemsettingnodes`, {
      filter: params
    })
  }
}
