<template>
  <ProSpaceGeoLayout>
    <template #top>
      <ProSpaceFilterPanel
        :closeTxt="$t('closeTxt')"
        :searchPlaceholder="$t('searchTxt')"
        :txtResetFilters="$t('txtResetFilters')"
        :unicKeyStorage="false"
        @apply="submitFilter"
        ref="filter-panel"
        filter-model="ActualModel/Title"
      >
        <template #hint> {{ $t("filterBy") }}</template>
        <template #filters="{ filter }">
          <ProSpaceMultiSelect
            :localization="$t"
            v-model="filter['ActualModel/Status']"
            :service="statusService"
            getMethod="get"
            optionLabel="text"
            optionValue="status"
            :selectAllTxt="$t('selectAllTxt')"
            :closeTxt="$t('closeTxt')"
            :placeholderValue="$t('status')"
            :applyTxt="$t('applyTxt')"
            :clearFilterTxt="$t('clearFilterTxt')"
            :searchPlaceholder="$t('searchTxt')"
            @apply="emitFilter"
          />
          <ProSpaceCustomFilterPanel
            :customTxt="$t('custom')"
            :customFilterTxt="$t('customFilterTxt')"
            :clearFilterTxt="$t('clearFilterTxt')"
            :selectAllTxt="$t('selectAllTxt')"
            :closeTxt="$t('closeTxt')"
            :applyTxt="$t('applyTxt')"
            :fromTxt="$t('fromTxt')"
            :toTxt="$t('toTxt')"
            :services="allCustomFilters"
            :unicKeyStorage="false"
            @apply="(model) => customFilterApply(filter, model)"
          />
        </template>
      </ProSpaceFilterPanel>
    </template>
    <template #center>
      <ProSpaceMainGrid
        ref="grid"
        :service="historyService"
        @selected-change="selectedHandler"
        :noMountedRequest="true"
        getMethod="grid"
        selectionMode="single"
        defaultSortField="editDate"
        defaultSortOrder="-1"
        :defaultFilter="defaultFilter"
      >
        <template #columns>
          <Column
            field="ActualModel/Name"
            :header="$t('taskName')"
            sortable
            style="min-width: 23.5%"
          >
            <template #body="field">
              <ProSpaceTableHeaderCell
                v-if="field.data"
                :id="field.data.actualModel.Id"
                :status="getStatus(field.data.actualModel.Status)"
                :title="field.data.actualModel.Name"
                :description="`${field.data.actualModel.Description || ''}`"
              />
            </template>
          </Column>
          <Column field="editDate" :header="$t('changeDateTime')" sortable>
            <template #body="field">
              <ProSpaceDateTimeColumn
                :date="field.data.editDate"
                :dateFormat="userinfo.dateFormat"
                :timeFormat="userinfo.timeFormat"
              />
            </template>
          </Column>
          <Column field="operationType" :header="$t('operation')" sortable>
            <template #body="field">
              <ProSpaceDefaultTextColumn>
                 {{ $t(field.data.operationType) }}
              </ProSpaceDefaultTextColumn>
            </template>
          </Column>
          <Column field="userLogin" :header="$t('user')" sortable>
            <template #body="field">
              <ProSpaceDefaultTextColumn>
                {{ field.data.userLogin }}
              </ProSpaceDefaultTextColumn>
            </template>
          </Column>
          <Column
            field="ActualModel/ItemType"
            :header="$t('itemType')"
            sortable
          >
            <template #body="field">
              <ProSpaceDefaultTextColumn>
                {{ field.data.actualModel.ItemType }}
              </ProSpaceDefaultTextColumn>
            </template>
          </Column>
          <Column :header="$t('Repeat')" style="max-width: 24.5%">
            <template #body="slotProps">
              <ProSpaceDefaultTextColumn>
                {{ getRepeatText(slotProps.data.actualModel) }}
              </ProSpaceDefaultTextColumn>
            </template>
          </Column>
        </template>
      </ProSpaceMainGrid>
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import {
  ProSpaceMainGrid,
  ProSpaceGeoLayout,
  ProSpaceTableHeaderCell,
  ProSpaceFilterPanel,
  ProSpaceMultiSelect,
  ProSpaceCustomFilterPanel,
  ProSpaceDateTimeColumn,
  ProSpaceDefaultTextColumn,
} from "prospace-components-library";
import Column from "primevue/column";
import { JobTriggerService } from "../../../services/JobTriggerService.js";
const _ = require("lodash");
import { mapGetters } from "vuex";
export default {
  components: {
    ProSpaceMainGrid,
    Column,
    ProSpaceGeoLayout,
    ProSpaceTableHeaderCell,
    ProSpaceFilterPanel,
    ProSpaceMultiSelect,
    ProSpaceCustomFilterPanel,
    ProSpaceDateTimeColumn,
    ProSpaceDefaultTextColumn,
  },
  props: {
    historyService: {
      type: Object,
      required: true,
    },
    defaultFilter: {
      type: Object,
      required: true,
    },
    historyCustomServices: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      jobTriggerService: new JobTriggerService(),
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
    }),
    customServices() {
      return [];
    },
    allCustomFilters() {
      return this.historyCustomServices.concat(this.customServices);
    },
  },
  methods: {
    getRepeatText(trigger) {
      return this.jobTriggerService.getRepeatText(trigger);
    },
    getActualName(record) {
      return record.Name;
    },
    getActualId(record) {
      return record.Id;
    },
    getActualStatus(record) {
      return this.getStatus(record.Status);
    },
    emitFilter() {
      this.$refs["filter-panel"].submitFilter();
    },
    selectedHandler(val) {
      this.$emit("selectedHandler", val);
    },
    submitFilter({ filter, search }) {
      this.$refs.grid.submitFilter(filter, search);
    },
    getStatus(status) {
      if (!status) return;
      let type = "default";
      switch (status) {
        case "ACTIVE":
          type = "success";
          break;
        case "NEW":
          type = "info";
          break;
        default:
          type = "danger";
      }
      return {
        text: status[0].toUpperCase() + status.toLowerCase().slice(1),
        type,
      };
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model, this.defaultFilter);
      this.$refs["filter-panel"].submitFilter();
    },
  },
};
</script>
<style lang="scss"></style>
