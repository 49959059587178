import request from "../Frontends/Common/utils/request/requestMain";
const rootRoute = "statisticsreport/statisticsraw/";

export default class StatisticApi {
  async createStatisticRaw(data) {
    return await request.post(`${rootRoute}createstatisticsraw`, {
        data
    });
  }
}
