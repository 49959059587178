import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/BaseType/SystemDirectoryApi";

export class SystemDirectoryService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(this.Api, "editSystemDirectory", fields);
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editSystemDirectory");
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getSystemDirectories", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getSystemDirectory", null, id);
  }
  async tree(args) {
    let result = await this.transformer.getTree(this.Api, "getSystemDirectories", args);
    const data = result.data.map(i => {
      i.leaf = true
      return i
    })
    if (result.data) {
      let all = {
          id: 0,
          leaf: true,
          name: "Все",
          code: "all",
          unreadCounter: 0,
      }
      data.unshift(all)
    }
    return Promise.resolve({ data });
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createSystemDirectory", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteSystemDirectory", model);
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "editSystemDirectory", model);
  }
}
