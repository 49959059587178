import request from "../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/notification/";

export default class NotificationApi {
    constructor() {
    }
    async getNotifications(params) {
        return await request.post(`${rootRoute}getnotifications`, {
            filter: params,
            parameters: {},
        });
    }
    async getNotifyEvents(params) {
        return await request.post(`${rootRoute}getnotifyevents`, {
            filter: params,
            parameters: {},
        });
    }
    async getNotification(id) {
        return await request.post(`${rootRoute}getnotifications`, {
            filter: `$filter=id eq ${id}`,
            parameters: {},
        });
    }
    async updateNotifications(model) {
        const data = [model];
        return await request.post(`${rootRoute}updatenotifications`, { data });
    }
    async createNotifications(model) {
        const data = [model];
        return await request.post(`${rootRoute}createnotifications`, { data });
    }
    async deleteNotifications(model) {
        const data = [model];
        return await request.post(`${rootRoute}deletenotifications`, { data });
    }
}