<template>
  <use
    v-if="name !== undefined"
    v-html="getSvg"
    :class="getClass"
    :style="getStyle"
  ></use>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    width: {
      type: [String, Number],
      default() {
        return "16";
      },
    },
    height: {
      type: [String, Number],
      default() {
        return "16";
      },
    },
  },
  computed: {
    getSvg() {
      return require(`../assets/img/svg/${this.name}.svg`).default.content.replace(
        "symbol",
        "svg"
      );
    },
    getClass() {
      return ["svg-icon", `icon-${this.name}`];
    },
    getStyle() {
      return {
        width: this.width + "px",
        height: this.height + "px",
      };
    },
  },
};
</script>
