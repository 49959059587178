<template>
  <ProSpaceGeoLayout class="newsitemtemplate" v-if="model">
    <template #top>
      <ProSpaceTopPanel :header="layoutName" back @back="backHandler">
        <template #right> </template>
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout
        :is-templated="true"
        :template-center="true"
        :template-right="true"
      >
        <template #center>
          <ProSpaceTabs
            v-model="modelTab"
            :tabs="overviewTabs"
            :loading="loading"
            :refLoading="mixinLoadingAccess"
            without-margin-bottom
            @select-tab="handlerSelectTab"
          >
            <ProSpaceAction
              ref="action-save"
              :disabled="v$.model.$invalid"
              icon="check"
              :text="$t('save')"
              @click="setModelAndSave()"
            />
            <template v-slot:tab-1>
              <ProSpaceVLayout :gap="20">
                <ProSpaceGeoLayout class="geo-row geo-row--leftlly">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('newsItemTemplateNameInfo')"
                      :bottom="$t('newsItemTemplateNameInfoAdditional')"
                      :gap="10"
                      break-word
                      type="third"
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceHLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="10"
                    >
                      <ProSpaceInputText
                        ref="templateName-ProSpaceInputText"
                        v-model="model.templateName"
                        :label="$t('newsItemTemplateTemplateName')"
                        :placeholder="$t('newsItemTemplateTemplateName')"
                        :invalid="v$.model.templateName.$error"
                        :invalid-messages="v$.model.templateName.$errors"
                        @blur="v$.model.templateName.$touch"
                      />
                    </ProSpaceHLayout>
                  </template>
                  <template v-if="v$.model.templateName.$error" v-slot:right>
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>
                <ProSpaceGeoLayout class="geo-row geo-row--leftlly">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('newsItemTypeInfo')"
                      :bottom="$t('newsItemTypeInfoAdditional')"
                      :gap="10"
                      break-word
                      type="third"
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceHLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="60"
                    >
                      <ProSpaceDropdown
                        ref="type-ProSpaceDropdown"
                        v-model="model.type"
                        :label="$t('newsItemTemplateType')"
                        :placeholder="$t('newsItemTemplateType')"
                        :invalid="v$.model.type.$error"
                        :invalid-messages="v$.model.type.$errors"
                        @blur="v$.model.type.$touch"
                        :service="baseTypeService"
                        getMethod="grid"
                        propValue="code"
                        propLabel="name"
                        argMethod="$filter=(systemDirectoryCode eq 'News Type' and disabled eq false)&$orderby=name asc"
                        @change="(item) => (model.type = item?.code)"
                      />
                      <ProSpaceDropdown
                        ref="editFieldModel-action-type-ProSpaceDropdown"
                        v-model="editFieldModel.type"
                        :service="templateEditableService"
                        :label="' '"
                        getMethod="get"
                        propValue="value"
                        propLabel="text"
                        style="max-width: 190px"
                      />
                    </ProSpaceHLayout>
                  </template>
                  <template v-if="v$.model.type.$error" v-slot:right>
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>
                <ProSpaceGeoLayout class="geo-row geo-row--leftlly">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('newsItemInfo')"
                      :bottom="$t('newsItemInfoAdditional')"
                      :gap="10"
                      break-word
                      type="third"
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceVLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="10"
                    >
                      <ProSpaceHLayout
                        justify-content="space-between"
                        align-items="flex-start"
                        :gap="60"
                      >
                        <ProSpaceInputText
                          ref="name-ProSpaceInputText"
                          v-model="model.name"
                          :label="$t('newsItemTemplateName')"
                          :placeholder="$t('newsItemTemplateName')"
                          :invalid="v$.model.name.$error"
                          :invalid-messages="v$.model.name.$errors"
                          @blur="v$.model.name.$touch"
                        />
                        <ProSpaceDropdown
                          ref="editFieldModel-action-name-ProSpaceDropdown"
                          v-model="editFieldModel.name"
                          :service="templateEditableService"
                          getMethod="get"
                          propValue="value"
                          propLabel="text"
                          style="max-width: 190px; margin-top: 20px"
                        />
                      </ProSpaceHLayout>
                      <ProSpaceHLayout
                        justify-content="space-between"
                        align-items="flex-start"
                        :gap="60"
                      >
                        <ProSpaceExtendedTextArea
                          ref="message-ProSpaceInputText"
                          v-model="model.message"
                          :label="$t('newsItemTemplateMessage')"
                          :placeholder="$t('newsItemTemplateMessage')"
                          :invalid="v$.model.message.$error"
                          :invalid-messages="v$.model.message.$errors"
                          :t="$t"
                          :locale="$i18n.locale"
                          @blur="v$.model.message.$touch"
                        />
                        <ProSpaceDropdown
                          ref="editFieldModel-action-message-ProSpaceDropdown"
                          v-model="editFieldModel.message"
                          :service="templateEditableService"
                          getMethod="get"
                          propValue="value"
                          propLabel="text"
                          style="max-width: 190px; margin-top: 20px"
                        />
                      </ProSpaceHLayout>
                    </ProSpaceVLayout>
                  </template>
                  <template
                    v-if="v$.model.name.$error || v$.model.message.$error"
                    v-slot:right
                  >
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>
                <ProSpaceGeoLayout class="geo-row geo-row--leftlly">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('newsItemExtraInfo')"
                      :bottom="$t('newsItemExtraInfoAdditional')"
                      :gap="10"
                      break-word
                      type="third"
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceVLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="10"
                    >
                      <ProSpaceHLayout
                        justify-content="space-between"
                        align-items="flex-end"
                        :gap="60"
                      >
                        <ProSpaceInputChips
                          v-model="tags"
                          ref="tags-ProSpaceInputChips"
                          placeholder="+"
                          :label="$t('newsItemTemplateTags')"
                          :invalid="v$.model.tags.$error"
                          :invalid-messages="v$.model.tags.$errors"
                          @blur="v$.model.tags.$touch"
                        />
                        <ProSpaceDropdown
                          ref="editFieldModel-action-tags-ProSpaceDropdown"
                          v-model="editFieldModel.tags"
                          :service="templateEditableService"
                          getMethod="get"
                          propValue="value"
                          propLabel="text"
                          style="max-width: 190px"
                        />
                      </ProSpaceHLayout>
                      <ProSpaceHLayout
                        justify-content="space-between"
                        align-items="flex-end"
                        :gap="60"
                      >
                        <ProSpaceHLayout
                          justify-content="flex-start"
                          align-items="center"
                          :gap="5"
                        >
                          <ProSpaceInputSwitch
                            ref="highImportant-ProSpaceInputSwitch"
                            v-model="model.highImportant"
                            :label="$t('newsItemTemplateHighImportant')"
                          />
                          <ProSpaceIcon
                            v-hint="$t('newsItemExtraInfoAdditionalImportantHint')"
                            icon="question"
                          />
                        </ProSpaceHLayout>

                        <ProSpaceDropdown
                          ref="editFieldModel-action-highImportant-ProSpaceDropdown"
                          v-model="editFieldModel.highImportant"
                          :service="templateEditableService"
                          getMethod="get"
                          propValue="value"
                          propLabel="text"
                          style="max-width: 190px"
                        />
                      </ProSpaceHLayout>
                      <ProSpaceHLayout
                        justify-content="space-between"
                        align-items="flex-end"
                        :gap="60"
                      >
                        <ProSpaceHLayout
                          justify-content="flex-start"
                          align-items="center"
                          :gap="5"
                        >
                          <ProSpaceInputSwitch
                            ref="isPersonal-ProSpaceInputSwitch"
                            v-model="model.isPersonal"
                            :label="$t('newsItemIsPersonal')"
                          />
                          <ProSpaceIcon
                            v-hint="$t('newsItemExtraInfoAdditionalPersonalHint')"
                            icon="question"
                          />
                        </ProSpaceHLayout>
                        <ProSpaceDropdown
                          ref="editFieldModel-action-isPersonal-ProSpaceDropdown"
                          v-model="editFieldModel.isPersonal"
                          :service="templateEditableService"
                          getMethod="get"
                          propValue="value"
                          propLabel="text"
                          style="max-width: 190px"
                        />
                      </ProSpaceHLayout>
                      <ProSpaceHLayout
                        justify-content="space-between"
                        align-items="flex-end"
                        :gap="60"
                      >
                        <ProSpaceHLayout
                          justify-content="flex-start"
                          align-items="center"
                          :gap="5"
                        >
                          <ProSpaceInputSwitch
                            ref="isDistributorStructureRestricted-ProSpaceInputSwitch"
                            v-model="model.isDistributorStructureRestricted"
                            :label="$t('newsItemIsDistributorStructureRestricted')"
                          />
                          <ProSpaceIcon
                            v-hint="$t('newsItemIsDistributorStructureRestrictedHint')"
                            icon="question"
                          />
                        </ProSpaceHLayout>

                        <ProSpaceDropdown
                          ref="editFieldModel-action-isDistributorStructureRestricted-ProSpaceDropdown"
                          v-model="editFieldModel.isDistributorStructureRestricted"
                          :service="templateEditableService"
                          getMethod="get"
                          propValue="value"
                          propLabel="text"
                          style="max-width: 190px"
                        />
                      </ProSpaceHLayout>
                      <ProSpaceHLayout
                        justify-content="space-between"
                        align-items="flex-end"
                        :gap="60"
                      >
                        <ProSpaceHLayout
                          justify-content="flex-start"
                          align-items="center"
                          :gap="5"
                        >
                          <ProSpaceInputSwitch
                            ref="readConfirmationRequired-ProSpaceInputSwitch"
                            v-model="model.readConfirmationRequired"
                            :label="
                              $t('newsItemTemplateReadConfirmationRequired')
                            "
                          />
                          <ProSpaceIcon
                            v-hint="
                              $t('newsItemReadConfirmationRequiredHint')
                            "
                            icon="question"
                          />
                        </ProSpaceHLayout>

                        <ProSpaceDropdown
                          ref="editFieldModel-action-readConfirmationRequired-ProSpaceDropdown"
                          v-model="editFieldModel.readConfirmationRequired"
                          :service="templateEditableService"
                          getMethod="get"
                          propValue="value"
                          propLabel="text"
                          style="max-width: 190px"
                        />
                      </ProSpaceHLayout>
                      <ProSpaceHLayout
                        justify-content="space-between"
                        align-items="flex-end"
                        :gap="60"
                      >
                        <ProSpaceHLayout
                          justify-content="space-between"
                          align-items="flex-start"
                          :gap="10"
                        >
                          <ProSpaceInputNumber
                            ref="daysToDueDate-ProSpaceInputNumber"
                            :label="$t('newsItemDaysToDueDate')"
                            v-model="model.daysToDueDate"
                            @input="(value) => setDueDate(value)"
                            style="width: calc(50% - 5px)"
                          />
                        </ProSpaceHLayout>
                        <ProSpaceDropdown
                          ref="editFieldModel-action-daysToDueDate-ProSpaceDropdown"
                          v-model="editFieldModel.daysToDueDate"
                          :service="templateEditableService"
                          getMethod="get"
                          propValue="value"
                          propLabel="text"
                          style="max-width: 190px"
                        />
                      </ProSpaceHLayout>
                    </ProSpaceVLayout>
                  </template>
                  <template
                    v-if="
                      v$.model.tags.$error ||
                      v$.model.highImportant.$error ||
                      v$.model.readConfirmationRequired.$error
                    "
                    v-slot:right
                  >
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>
                <ProSpaceGeoLayout class="geo-row geo-row--leftlly">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('newsItemAttachmentsInfo')"
                      :bottom="$t('newsItemAttachmentsInfoAdditional')"
                      :gap="10"
                      break-word
                      type="third"
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceHLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="60"
                    >
                      <ProSpaceFile
                        v-model="attachments"
                        ref="attachments-ProSpaceFile"
                        accept=".doc, .docx, .docm, .rtf, .txt, .csv, .xml, .pdf, .png, .gif, .jpg, .jpeg, .ppt, .pptx, .potx, .potm, .pptm, .ppsx, .ppsm, .xls, .xlsx, .xlsm, .xlsb, .zip, .7z, .rar, .mp4, .avi, .mkv, .wmv"
                        :service="fileService"
                        :fileLimit="1000"
                        :maxFileSize="209715200"
                        :txtBtnUpload="$t('uploadFile')"
                        :txtEmptyFiles="$t('uploadFileDragAndDropText')"
                        :txtErrorStatus="$t('uploadStatusError')"
                        :invalidFileLimitMessage="$t('invalidFileLimitMessage')"
                        :invalidFileSizeMessage="$t('invalidFileSizeMessage')"
                        :invalidFileTypeMessage="$t('invalidFileTypeMessage')"
                      />
                      <ProSpaceDropdown
                        ref="editFieldModel-action-attachments-ProSpaceDropdown"
                        v-model="editFieldModel.attachments"
                        :service="templateEditableService"
                        getMethod="get"
                        propValue="value"
                        propLabel="text"
                        style="max-width: 190px"
                      />
                    </ProSpaceHLayout>
                  </template>
                </ProSpaceGeoLayout>
              </ProSpaceVLayout>
            </template>
            <template v-slot:tab-2>
              <ProSpaceGeoLayout class="geo-row">
                <template v-slot:left>
                  <ProSpaceTextBlock
                    :top="$t('positions')"
                    :bottom="$t('newsPositionsInfo')"
                    :gap="10"
                    type="third"
                    break-word
                  />
                </template>
                <template v-slot:center>
                  <ProSpaceGeoLayout>
                    <template v-slot:center>
                      <ProSpaceHLayout
                        justify-content="space-between"
                        align-items="flex-start"
                        :gap="60"
                      >
                        <PositionSelectTree
                          ref="tree"
                          expandAll
                          :positionService="positionOptions.positionService"
                          :positionGetMethod="positionOptions.positionGetMethod"
                          :positionSelectionMode="positionOptions.positionSelectionMode"
                          :positionFilter="positionOptions.positionFilter"
                          :propLabel="positionOptions.propLabel"
                          :checkedPositions="positions"
                          @changed="
                            (newPositions) => {
                              this.positions = [...newPositions];
                            }
                          "
                        />
                        <ProSpaceDropdown
                          v-model="editFieldModel.positions"
                          :service="templateEditableService"
                          getMethod="get"
                          propValue="value"
                          propLabel="text"
                          style="max-width: 190px"
                        />
                      </ProSpaceHLayout>
                    </template>
                  </ProSpaceGeoLayout>
                </template>
              </ProSpaceGeoLayout>
            </template>
          </ProSpaceTabs>
        </template>
      </ProSpaceGeoLayout>
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import {
  ProSpaceTopPanel,
  ProSpaceInputText,
  ProSpaceAction,
  ProSpaceRadioButtonSecondary,
  ProSpaceGeoLayout,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpaceTextBlock,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceDropdown,
  ProSpaceFile,
  ProSpaceInputCalendar,
  ProSpaceInputNumber,
  ProSpaceSearchfield,
  ProSpaceInputSwitch,
  ProSpaceMultiSelect,
  ProSpaceCustomFilterPanel,
  ProSpaceIcon,
  ProSpaceWarning,
  ProSpaceInputChips,
  ProSpaceExtendedTextArea,
  Hint,
} from "prospace-components-library";
import moment from "moment";
import { NewsItemTemplateService } from "../../services/NewsItemTemplateService.js";
import { NewsItemTemplateAttachmentService } from "../../services/NewsItemTemplateAttachmentService.js";
import { BaseTypeService } from "../../../../BaseType/BaseTypeService.Frontend/services/BaseTypeService";
import { FileService } from "../../../../../Frontends/ProSpace/services/FileService.js";
import { NewsItemService } from "../../services/NewsItemService.js";
import { TemplateEditableService } from "../../../../../../Application/Frontends/ProSpace/services/TemplateEditableService.js";
import { DistributorOrgService } from "@masterdata/services/DistributorOrgService";
import editFieldMixin from "../../../../../Frontends/Common/utils/elementAccess/editFieldMixin";
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import PositionSelectTree from "@trees/PositionSelectTree";

import { mapGetters } from "vuex";
import {
  setAlive,
  setDisable,
} from "../../../../../Frontends/Common/utils/elementAccess/elementAccessFunctions";
import {
  required,
  maxLength,
  helpers,
  minValue,
  maxValue,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
const _ = require("lodash");
import {
  useStatistic
} from "@composes"
export default {
  mixins: [editFieldMixin, accessMixin],
  directives: {
    hint: Hint,
  },
  components: {
    ProSpaceTopPanel,
    ProSpaceGeoLayout,
    ProSpaceInputText,
    ProSpaceAction,
    ProSpaceRadioButtonSecondary,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpaceTextBlock,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceDropdown,
    ProSpaceFile,
    ProSpaceInputCalendar,
    ProSpaceInputNumber,
    ProSpaceSearchfield,
    ProSpaceInputSwitch,
    ProSpaceMultiSelect,
    ProSpaceCustomFilterPanel,
    ProSpaceIcon,
    ProSpaceWarning,
    ProSpaceInputChips,
    ProSpaceExtendedTextArea,
    PositionSelectTree,
  },
  directives: {
    hint: Hint,
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    return { v$: useVuelidate(), setStatistic, distActions };
  },
  data() {
    this.tabs = [
      { name: this.$t("overview"), fields: "Overview", isActive: true },
    ];
    return {
      // Tabs
      modelTab: {
        name: this.$t("parameters"),
        isActive: true,
        systemName: "parameters",
      },

      // Common
      model: null,
      loading: false,
      mixinLoadingAccess: false,
      attachmentsToCreate: [],
      editFieldModel: {
        type: true,
        name: true,
        message: true,
        tags: true,
        highImportant: true,
        isPersonal: true,
        isDistributorStructureRestricted: true,
        readConfirmationRequired: true,
        daysToDueDate: true,
        attachments: true,
        positions: true,
      },
      isCreate: false,
      attachments: [],
      tags: [],
      positions: [],

      // Service
      newsItemTemplateService: new NewsItemTemplateService(),
      newsItemTemplateAttachmentService: new NewsItemTemplateAttachmentService(),
      newsItemService: new NewsItemService(),
      templateEditableService: new TemplateEditableService(),
      baseTypeService: new BaseTypeService(),
      fileService: new FileService(),

      getMethod: "get",
      masterId: null,
    };
  },
  validations() {
    return {
      model: {
        templateName: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '255'}),
            maxLength(255)
          ),
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        type: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '255'}),
            maxLength(255)
          ),
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        name: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '255'}),
            maxLength(255)
          ),
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        message: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        tags: {},
        highImportant: {},
        readConfirmationRequired: {},
        attachments: {},
      },
    };
  },
  mounted() {
    this.masterId = this.$route.params.id;
    this.mountAccess();
    this.loadRoleAccess();
    this.getData();
  },
  watch: {
    "model.readConfirmationRequired"(value) {
      if (this.$refs["daysToDueDate-ProSpaceInputNumber"]) {
        if (!value) {
          setDisable(this.$refs["daysToDueDate-ProSpaceInputNumber"]);
        } else {
          setAlive(this.$refs["daysToDueDate-ProSpaceInputNumber"]);
        }
      }
    },
  },
  methods: {
    handlerSelectTab({ selectedTab }) {
      this.$nextTick(() => {
        this.mountAccess();
      });
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: selectedTab.systemName
      })
    },
    gridLoaded() {},
    setDueDate(value) {
      this.model.dueDate = moment(this.model.publishDate).add(value, "days");
    },
    async getData() {
      let fromNews = false;
      if (this.masterId !== undefined) {
        const result = await this.newsItemTemplateService[this.getMethod](
          this.masterId
        );
        this.model = result.data;
        this.positions = [...this.model.positions];
        if (this.model.attachments)
          this.attachments = this.model.attachments.map((f) => {
            let r = new File([], f.fileName);
            r.gfid = f.gfid;
            r.fileLength = f.fileLength;
            r.statusFlag = f.statusFlag;
            return r;
          });
      } else if (this.$route.query && this.$route.query.newsItemId) {
        //создание шаблона из новости по кнопке 'Создать шаблон'
        fromNews = true;
        const result = await this.newsItemService[this.getMethod](
          this.$route.query.newsItemId
        );
        //this.model = result.data;
        this.model = {};
        this.model.type = result.data.type;
        this.model.name = result.data.name;
        this.model.message = result.data.message;
        this.model.highImportant = result.data.highImportant;
        this.model.isPersonal = result.data.isPersonal;
        this.model.isDistributorStructureRestricted = result.data.isDistributorStructureRestricted;
        this.model.readConfirmationRequired =
          result.data.readConfirmationRequired;
        this.model.daysToDueDate = result.data.daysToDueDate;
        this.model.editFieldsSetting = result.data.editFieldsSetting;
        this.positions = [...result.data.positions];
        this.attachments = result.data.attachments.map((f) => {
          let r = new File([], f.fileName);
          r.gfid = f.gfid;
          r.fileLength = f.fileLength;
          r.statusFlag = f.statusFlag;
          return r;
        });
        this.model.attachments = result.data.attachments.map((f) => {
          let r = {};
          r.gfid = f.gfid;
          r.fileName = f.fileName;
          r.fileLength = f.fileLength;
          r.statusFlag = f.statusFlag;
          return r;
        });
      } else {
        let daysToDueDate = 5;
        this.model = {
          type:
            this.$route.query && this.$route.query.code
              ? this.$route.query.code
              : null,
          name: "",
          message: "",
          tags: [],
          highImportant: false,
          isPersonal: false,
          isDistributorStructureRestricted: false,
          readConfirmationRequired: false,
          daysToDueDate: daysToDueDate,
          attachments: [],
        };
        return;
      }

      if (this.model) {
        this.tags = this.model.tags
          ? this.model.tags.split("#").filter((t) => !!t)
          : [];
        try {
          if (!fromNews && this.model.editFieldsSetting) {
            this.editFieldModel = Object.assign(this.editFieldModel, JSON.parse(this.model.editFieldsSetting));
          }
        } catch {}
      }
    },
    setModelAndSave(loadModel = true) {
      this.model.tags = this.tags.join("#");
      this.loading = true;
      return this.setModel().then((response) => {
        let newFiles = this.setNewAttachments(response.data.data);
        return this.save(newFiles, loadModel);
      });
    },
    setModel() {
      this.model.tags = this.tags.join("#");
      this.model.editFieldsSetting = JSON.stringify(this.editFieldModel);

      if (this.model.isPersonal) {
        this.positions.forEach((x) => {
          x["newsItemTemplateId"] = this.model.id;
          x["leaf"] = !this.positions.some(
            (p) => p.parentId === x.distributorOrgId
          );
        });
        this.model.positions = this.positions;
      } else {
        this.model.positions = [];
      }

      this.attachmentsToCreate = this.attachments.filter((f) => !f.gfid);
      const actualFiles = this.attachments
        .filter((f) => f.gfid)
        .map((f) => f.gfid);
      if (this.model.attachments)
        this.model.attachments = this.model.attachments.filter((f) =>
          actualFiles.includes(f.gfid)
        );
      if (this.attachmentsToCreate.length > 0) {
        let fileInfos = this.attachmentsToCreate
          .filter((f) => !f.id)
          .map((f) => {
            return {
              fileName: f.name,
              fileVisibleFlag: 0,
            };
          });
        return this.fileService.createFilesRoot(fileInfos);
      }
      return new Promise((resolve, reject) => {
        resolve({ data: { data: [] } });
      });
    },
    setNewAttachments(attachments) {
      let newFiles = attachments.map((f, i) => {
        return {
          fileName: f.fileName,
          gfid: f.gfid,
          fileLength: this.attachmentsToCreate[i].size,
        };
      });
      if (!this.model.attachments) {
        this.model.attachments = [];
      }
      this.model.attachments = this.model.attachments.concat(newFiles);
      return newFiles;
    },
    save(newFiles, loadModel) {
      const isCreate = !this.masterId;
      let saveMethod = isCreate ? "create" : "update";
      return this.newsItemTemplateService[saveMethod](this.model)
        .then((result) => {
          this.model.id = result.data[0].id;
          if (newFiles.length > 0) {
            this.upload(newFiles).then(() => {
                if (loadModel) this.afterSave(result, isCreate);
              })
              .catch(() => {
                this.loading = false;
              });
          } else if (loadModel) {
            this.afterSave(result, isCreate);
          }
          return result;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async upload(newFiles) {
      await this.fileService.uploadChunks(newFiles, this.attachmentsToCreate, this.uploadSuccess, this.uploadError);
      this.attachmentsToCreate = [];
    },
    async uploadSuccess(gfids) {
      await this.newsItemTemplateAttachmentService.acceptFiles(gfids, this.model.id);
    },
    async uploadError(gfids) {
      await this.newsItemTemplateAttachmentService.errorFiles(gfids, this.model.id);
    },
    afterSave(result, isCreate) {
      if (isCreate) {
        this.masterId = result.data[0].id;
      }
      this.getData();
      this.loading = false;
    },
    submitFilter(nameRef = "grid") {
      if (!this.$refs[nameRef]) return;
      this.$refs[nameRef].getRecords(true);
    },
    backHandler() {
      this.$router.go(-1);
    },
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      access: "securityRoleAccess/access",
    }),
    refsForCheckInMixin() {
      let refs = [];

      if (this.modelTab.systemName === "parameters")
        refs.push(
          "editFieldModel-action-type-ProSpaceDropdown",
          "editFieldModel-action-name-ProSpaceDropdown",
          "editFieldModel-action-message-ProSpaceDropdown",
          "editFieldModel-action-tags-ProSpaceDropdown",
          "editFieldModel-action-highImportant-ProSpaceDropdown",
          "editFieldModel-action-isPersonal-ProSpaceDropdown",
          "editFieldModel-action-isDistributorStructureRestricted-ProSpaceDropdown",
          "editFieldModel-action-readConfirmationRequired-ProSpaceDropdown",
          "editFieldModel-action-daysToDueDate-ProSpaceDropdown",
          "editFieldModel-action-attachments-ProSpaceDropdown",
          "templateName-ProSpaceInputText",
          "type-ProSpaceDropdown",
          "name-ProSpaceInputText",
          "message-ProSpaceInputText",
          "tags-ProSpaceInputChips",
          "highImportant-ProSpaceInputSwitch",
          "isPersonal-ProSpaceInputSwitch",
          "isDistributorStructureRestricted-ProSpaceInputSwitch",
          "readConfirmationRequired-ProSpaceInputSwitch",
          "daysToDueDate-ProSpaceInputNumber",
          "attachments-ProSpaceFile",
          "action-save"
        );

      return refs;
    },
    overviewTabs() {
      return [
        {
          name: this.$t("parameters"),
          systemName: "parameters",
          isActive: this.modelTab.systemName === "parameters",
        },
        {
          name: this.$t("positions"),
          systemName: "positions",
          isHidden: !this.model.isPersonal,
          isActive: this.modelTab.systemName === "positions",
        },
      ];
    },
    layoutName() {
      return this.masterId
        ? this.model.templateName
        : this.$t("newTxt") + " " + this.$t("newsItemTemplate");
    },
    positionOptions() {
      let options = {
        positionService: null,
        positionGetMethod: null,
        positionSelectionMode: null,
        positionFilter: null,
        propLabel: null,
      };

      if (this.checkAccess("position-PositionSelectTree", this.model)) {
        options.positionService = new DistributorOrgService();
        options.positionGetMethod = "allTree";
        options.positionSelectionMode = "checkbox";
        options.propLabel = "name";
      } else {
        let positionService = new NewsItemTemplateService();
        positionService.newsItemTemplateId = this.model.id;
        options.positionService = positionService;
        options.positionGetMethod = "getPositions";
        options.positionSelectionMode = "single";
        options.propLabel = "distributorOrgName";
        options.positionFilter = {
          disabled: { and: [false] },
          newsItemTemplateId: this.model.id,
        };
      }

      return options;
    },
  },
};
</script>
<style lang="scss"></style>
