import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/UserUseCaseApi";

export class UserUseCaseService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async grid(args, id) {
    return await this.transformer.getGrid(this.Api, "GetUseCaseByUser", args, id);
  }
  async get(args) {
    return await this.transformer.localGetById(args);
  }
}
