<template>
  <div>
    <ProSpaceMainLayout>
      <template #nav>
        <template v-if="roleCorrect && Object.keys(user).length > 0">
          <ProSpaceNotificationPanel
            :notifications="unreaded"
            :localization="$t"
            isAlert
            @hide="handlerHideNotification"
          >
            <template #body="{ notification }">
              <ProSpaceHLayout :gap="notification.type === 'load-error' ? 5 : 10">
                <template v-if="notification.type === 'load-inprog'">
                  <ProSpaceIcon icon="dot-loader" class="anime-rotate" style="background-color: var(--prospace-ui-main);" icon-width="16px" icon-height="16px" />
                </template>
                <template v-else-if="notification.type === 'load-done'">
                  <ProSpaceIcon icon="timeline-check" style="background-color: var(--prospace-tag-published);" icon-width="16px" icon-height="16px" />
                </template>
                <template v-else-if="notification.type === 'load-error'">
                  <ProSpaceIcon icon="close" style="background-color: var(--prospace-ui-red);" icon-width="20px" icon-height="20px" />
                </template>
                <ProSpaceLabel :text="notification.title" :size="11" :line="notification.type?.includes('load') ? 16 : 13" color="gray" />
              </ProSpaceHLayout>
            </template>
            <template #buttons="{ notification }">
              <ProSpaceHLayout v-if="notification.type?.includes('load')">
              </ProSpaceHLayout>
              <ProSpaceHLayout :gap="5" v-else>
                <ProSpaceAction
                  :text="$t('open')"
                  @click="openNotification(notification)"
                />
                <ProSpaceAction
                  v-if="downloadable(notification)"
                  :text="$t('download')"
                  @click="downloadFile(notification.messageData.Result[0].GFID)"
                />
              </ProSpaceHLayout>
            </template>
          </ProSpaceNotificationPanel>
          <ProSpaceAlertMessage
            v-if="showErrorMessageTop"
            style="z-index: 1005"
            :text="errorText"
            :label="errorLabel"
            :rightTextContent="errorRightTextContent"
            @closeHandler="closeHandler"
          />
          <ProSpaceSidebar
            :service="menuService"
            getMethod="getMenu"
            @redirectTo="toLink"
            @logout="logout"
            @clear-storage="handlerClearStorage"
            @changeLocale="updateLocale"
            @changeTheme="updateTheme"
            @avatar="showUserProfile"
            @name="showUserProfile"
            @loading="onSidebarLoading"
            :supportItem="supportItem"
            :user="user"
            :current-locale="i18nLocale"
            :current-theme="currentTheme"
            :locale-options="localeOptions"
            :currentPath="currentPath"
            :theme-txt="$t('sideBarThemeTxt')"
            :language-txt="$t('sideBarLanguageTxt')"
            :logout-txt="$t('sideBarLogoutTxt')"
            :storage-txt="$t('sideBarStorageTxt')"
            hide-default-logo
            ref="sidebar"
            hideDefaultLogo
            showBurgerOnCollapsed
          >
            <template #logo="{ collapsed }">
              <svg-icon
                v-if="!collapsed"
                name="sidebar-logo-full"
                :width="135"
                :height="28"
              />
            </template>
          </ProSpaceSidebar>
        </template>
      </template>
      <template #center>
        <div style="height: 100vh" v-if="roleCorrect">
          <slot />
        </div>
        <div v-else class="loading-screen">
          <img class="loading-screen__img-nets" src="./assets/nets.png" />
        </div>
      </template>
      <ProSpaceConfirmDialog :ConfirmationEventBus="ConfirmationEventBus" />
    </ProSpaceMainLayout>
    <ProSpaceUserProfileModal
      v-if="roleCorrect && Object.keys(user).length > 0"
      ref="modalUserProfile"
      v-model="visibleUserProfileModal"
      :confirmTimezone="confirmTimezone"
      @close="closeUserProfileModal"
      @logout="logout"
      @clear-storage="handlerClearStorage"
      @changeLocale="updateLocale"
      @update:timezone="alertTimezoneModal = true"
    />
    <ProSpaceSupportModal v-model="visibleSupportModal" />
    <ProSpaceReqCreateUserModal v-model="visibleReqCreateUserModal" />
    <ProSpaceModal
      v-model="showMessageError"
      :cancelTxt="`eswdqwedewdwed`"
      is-custom
      transparent
    >
      <ProSpaceMessage
        type="error"
        icon="info"
        :title="$t('businessErrorsMessage')"
        @close="onCloseError"
      >
        <template #body>
          <ProSpaceError
            grid-type="single"
            :body="buisnessErrorMessage"
            :details="buisnessErrors"
            :translates="modalTranslates"
            :titleModal="$t('errors')"
            :title="$t('errors')"
            :cancelText="$t('closeTxt')"
            :showDetailsLabel="$t('showErrorDetails')"
            @close="onCloseError"
          >
          </ProSpaceError>
        </template>
        <template #controls>
          <ProSpaceHLayout justify-content="flex-end">
            <ProSpaceButton
              type="tertiary"
              :label="$t('closeTxt')"
              @click="onCloseError"
            />
          </ProSpaceHLayout>
        </template>
      </ProSpaceMessage>
    </ProSpaceModal>
    <ProSpaceModal
      v-if="!roleCorrect && user.roles"
      v-model="showRoleSelection"
      icon="user"
      :title="$t('selectRoleForEnter')"
      :width="400"
      :height="235"
      :withoutClose="true"
      :cancelTxt="false"
      :applyTxt="$t('applyTxt')"
      :disabledApply="!selectRoleModel"
      class="loading-select-role-modal"
      @apply="applyCurrentRoleHandler"
    >
      <ProSpaceDropdown
        v-if="user.roles.length > 0"
        v-model="selectRoleModel"
        :options="user.roles"
        propLabel="displayName"
        propValue="id"
        :placeholder="$t('selectRole')"
        :label="$t('currentRole')"
      />
      <div v-else class="loading-select-role-modal__text">
        {{ $t("rolesEmpty") }}
      </div>
    </ProSpaceModal>
    <ProSpaceFlashMessage
      v-if="alertTimezoneModal"
      v-model="alertTimezoneModal"
      icon="flash-warning"
      :title="$t('signOut')"
      :cancelTxt="$t('cancelTxt')"
      :applyTxt="$t('applyTxt')"
      :needHideApply="false"
      @apply="confirmTimezone = true"
      @update:modelValue="(val) => { if(!val) this.cancelChangeTimezone() }"

    >
      {{ $t('alertChangeTimezone') }}
    </ProSpaceFlashMessage>
  </div>
</template>
<style lang="scss">
.loading-select-role-modal {
  margin: auto !important;
  height: 100%;
  display: flex;
  justify-content: center;
  box-shadow: none;
  &__text {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
  }
  .p-dialog-content {
    height: 135px;
    padding-top: 30px;
  }
  .prospace-dropdown {
    .prospace-input-layout__label {
      color: var(--prospace-text-main);
    }
  }
}
.loading-screen {
  height: 100vh;
  width: 100vw;
  background: linear-gradient(180deg, #2c3e50 0%, #4f5c75 100%);
  position: relative;
  .loading-screen__img-nets {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
// перенести в либу
@supports (scrollbar-width: auto) {
  .prospace-scrollbar, .p-tree, .prospace-sidebar .prospace-scrollbar,
  .p-datatable-wrapper .p-virtualscroller,
  .p-multiselect-panel .p-multiselect-items-wrapper,
  .p-dropdown-panel .p-dropdown-items-wrapper,
  .prospace-modal-wrapper, .fc.fc-theme-standard .fc-toolbar-ltr,
  .fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-body,
  .prospace-eta .p-editor-container .ql-snow.p-editor-content .ql-editor {
    scrollbar-width: auto;
    scrollbar-color: auto;

    @-moz-document url-prefix() {
      scrollbar-width: thin;
      scrollbar-color: var(--prospace-ui-main-menu-element) transparent;
    }
  }
}
</style>
<script>
import accessMixin from "../../Common/utils/elementAccess/accessMixin";
import primeVueLocales from "../../Common/utils/primevue-locales/locales.json";
import ProSpaceUserProfileModal from "@modals/ProSpaceUserProfileModal";
import ProSpaceSupportModal from "@modals/ProSpaceSupportModal";
import ProSpaceReqCreateUserModal from "@modals/ProSpaceReqCreateUserModal";
import { mapGetters } from "vuex";
import UserInfoApi from "../../../Api/UserInfoApi";
import UserService from "../../../../Application/Services/Security/Security.Frontend/services/UserService";
import { UserService as UserSecService } from "../../../../Application/Services/SecurityAdmin/ProSpace.SecurityAdmin.Frontend/services/UserService";
import ErrorListApi from "../../../Api/ErrorListApi";
import LocalesApi from "../../../Api/LocalesApi";
import SecurityRoleAccessApi from "../../../Api/SecurityRoleAccessApi";
import { notificationsInit, checkDisconnect, loadNotifications } from "../../Common/utils/notifications.js";
import {
  removeToken,
  removeRefreshToken,
  removeRedirectUrl,
} from "../../Common/utils/auth";
import {
  useStatistic
} from "@composes"
import { computed } from "vue";
import moment from "moment";
import { nextTick } from "vue";
import {
  ProSpaceAlertMessage,
  ProSpaceFlashMessage,
  ProSpaceSidebar,
  ProSpaceMainLayout,
  ProSpaceMessage,
  ProSpaceModal,
  ProSpaceError,
  ProSpaceHLayout,
  ProSpaceButton,
  ProSpaceNotificationPanel,
  ProSpaceDropdown,
  ProSpaceAction,
  ProSpaceLabel,
  ProSpaceIcon
} from "prospace-components-library";
import MenuService from "../services/MenuService";
import { FileService } from "../services/FileService.js";
import _ from "lodash";
export default {
  mixins: [accessMixin],
  components: {
    ProSpaceAlertMessage,
    ProSpaceFlashMessage,
    ProSpaceSidebar,
    ProSpaceMainLayout,
    ProSpaceUserProfileModal,
    ProSpaceMessage,
    ProSpaceModal,
    ProSpaceError,
    ProSpaceHLayout,
    ProSpaceButton,
    ProSpaceNotificationPanel,
    ProSpaceDropdown,
    ProSpaceSupportModal,
    ProSpaceReqCreateUserModal,
    ProSpaceAction,
    ProSpaceLabel,
    ProSpaceIcon
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    return {
      setStatistic, distActions
    }
  },
  data() {
    return {
      currentPath: window.location.pathname,
      showErrorMessageTop: false,
      showRoleSelection: true,
      pageLoaded: false,
      errorText: "",
      errorLabel: "Error",
      errorRightTextContent: "Close",
      user: {},
      menuService: new MenuService(),
      userService: new UserService(),
      userInfoApi: new UserInfoApi(),
      errorListApi: new ErrorListApi(),
      securityRoleAccessApi: new SecurityRoleAccessApi(),
      localesApi: new LocalesApi(),
      usersSecService: new UserSecService(),
      fileService: new FileService(),
      localeOptions: [],
      i18nLocale:
        localStorage.getItem("locale") != null
          ? localStorage.getItem("locale").toUpperCase()
          : this.$i18n.locale.toUpperCase(),
      visibleUserProfileModal: false,
      visibleSupportModal: false,
      visibleReqCreateUserModal: false,
      showMessageError: false,
      buisnessErrors: [],
      buisnessErrorMessage: "",
      excludeNotificationTitles: ["Tasks", "Documents", "News"],
      currentRole: false,
      selectRoleModel: false,
      primeVueLocales,
      showedNotification: [],
      localAccess: null,
      timeZoneId: null,
      lastSendPending: moment(),
      moSupport: null,
      hasAnswerSupport: false,
      visibleSupportFrame: false,
      alertTimezoneModal: false,
      confirmTimezone: false
    };
  },
  created() {
    this.$router.beforeEach(this.handlerRouterBeforeEach);
  },
  mounted() {
    this.$store.dispatch("errorStore/setBuisnessErrorHandler", (errors) => {
      let errorCount = errors.filter((e) => e.type === "Error").length;
      let warningCount = errors.filter((e) => e.type === "Warning").length;
      let infoCount = errors.filter((e) => e.type === "Info").length;
      this.buisnessErrorMessage = `${this.$t("errors")} - ${errorCount}; ${this.$t("warnings")} - ${warningCount}; ${this.$t("infos")} - ${infoCount};`;
      this.buisnessErrors = errors;
      this.showMessageError = true;
    });
    this.getUserInfo();
    this.getLocales();
    this.setPrimeVueLocale();

    document.addEventListener("visibilitychange", this.visibilityChanged);
  },
  provide() {
    return {
      injectedUserRole: computed(() => this.user?.position?.systemName)
    }
  },
  beforeUnmount() {
    document.removeEventListener("visibilitychange", this.visibilityChanged);
    this.unbindMutationObserverSupport();
  },
  methods: {
    callbackMutationObserverSupport(mutationList) {
      if (this.visibleSupportFrame) {
        return;
      }
      const arrayMutations = Array.from(mutationList);
      const hasAnswerOperator = arrayMutations.find(i => {
        const arrAddedNodes = Array.from(i.addedNodes)
        const hasOperatorClass = arrAddedNodes.find(i => i.classList?.contains('operator'))
        return hasOperatorClass
      });
      if (hasAnswerOperator) {
        this.hasAnswerSupport = true
        this.$store.dispatch('notifications/addFrontNotification', {
          title: this.$t('notificationSupportAnswer'),
          type: 'load-done'
        })
      }
    },
    bindMutationObserverSupport() {
      const targetNode = document.querySelector('#intraChat .ic-body');
      if (targetNode instanceof Node) {
        const config = { attributes: true, childList: true, subtree: true };

        this.moSupport = new MutationObserver(this.callbackMutationObserverSupport);

        this.moSupport.observe(targetNode, config);
      }
    },
    unbindMutationObserverSupport() {
      if (!this.moSupport) {
        return
      }
      this.moSupport.disconnect();
    },
    visibilityChanged() {
      if (!document.hidden){
        if(checkDisconnect())
          this.initNotifications();
        else {
          let nextSend = moment(this.lastSendPending).add(5, 'm');
          if(moment().isAfter(nextSend)) {
            loadNotifications().then((loadedNotifications) => {
              this.getPastNotifications(loadedNotifications)
            })
            this.lastSendPending = moment();
          }
        }
      }
    },
    handlerClearStorage() {
      const ignoredKeys = ['SecurityAdmin-Refresh-Token', 'SecurityAdmin-Redirect-Url', 'SecurityAdmin-Access-Token']
      Object.keys(localStorage)
        .filter(key => !ignoredKeys.includes(key))
        .forEach(key => localStorage.removeItem(key))
      window.location.reload()
    },
    handlerRouterBeforeEach(to) {
      this.getSecurityRoleAccess();
      this.setActiveMenuitem(to);
      this.setDocumentTitle(to);
    },
    setActiveMenuitem(to) {
      this.currentPath = to.meta.menuitem || to.path;
    },
    onSidebarLoading(to) {
      //this.bindMutationObserverSupport();
      this.setDocumentTitle(to)
    },
    setDocumentTitle(to) {
      if (!this.$refs.sidebar) {
        return;
      }
      let menuitem;
      if (to) {
        menuitem = to.query.menuitem || btoa(to.path);
      } else {
        const search = new URLSearchParams(document.location.search);
        menuitem = search.get("menuitem") || btoa(document.location.pathname);
      }
      const array = [];
      this.$refs.sidebar.menuList.forEach((i) => {
        if (i.path !== "") {
          array.push(i);
        }
        if (i.children) {
          array.push(...i.children);
        }
      });
      const item = array.find((i) => btoa(i.path) === menuitem);
      if (!item) {
        document.title = "MDP";
        return;
      }
      const language = JSON.parse(item.language);
      const title = language[this.i18nLocale] || "MDP";
      document.title = `MDP - ${title}`;
    },
    downloadable(notification) {
      if (notification && notification.messageData) {
        if (Array.isArray(notification.messageData.Result)) {
          if (notification.messageData.Result[0].ResultType == "File") {
            return true;
          }
        }
      }
      return false;
    },
    downloadFile(gfid) {
      //Первый запрос получает токен необходимый для скачивания файла.
      this.fileService.getFileToken(gfid).then((response) => {
        if (response != null) {
          //второй запрос скачивает файл
          this.fileService
            .downloadFile(response.data)
            .then((fileResponse) => {
              if(!fileResponse)
                return;

              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(fileResponse.data);
              let name = fileResponse.fileName;
              link.download = decodeURI(name).replace("%2b", "+");
              link.click();
            });
        } else {
          //TODO: ERROR
        }
      });
    },
    openNotification(notification) {
      if (notification && notification.messageData) {
        if (notification.messageData.Title == "Background tasks") {
          if (
            notification.messageData.Result &&
            notification.messageData.Result.Type == "TaskPublished"
          ) {
            this.toLink("/taskservice/taskassignments", null, false);
            return;
          }
          let path = "/scheduler/userTasks";
          let parameters = null;
          const id = notification.messageData.Record.Id;
          if (id && id !== "userTaskNewStaticId") {
            parameters = {
              openResult: notification.messageData.Record.Id,
            };
          }
          this.toLink(path, parameters, false);
          return;
        }
        if (
          ["Tasks", "Documents", "News"].includes(notification.messageData.Title) &&
          notification.messageData.Record.Id &&
          !isNaN(notification.messageData.Record.Id)
        ) {
          let path = "/taskservice/taskassignment/";
          if (notification.messageData.Title == "Documents")
            path = "/documentservice/document/";
          else if (notification.messageData.Title == "News")
            path = "/newsservice/newsItem/";
          path = path + notification.messageData.Record.Id;
          this.toLink(path, null, false);
          return;
        }
      } else if (notification && notification.groupType) {
        const distTypes = {
          documentPublished: "/documentservice/documents/",
          documentNewStaticId: "/documentservice/documents/",
          newsNewStaticId: "/newsservice/newsitems",
          newsPublished: "/newsservice/newsitems",
          backgroundTask: "/scheduler/userTasks",
          taskNewStaticId: "/taskservice/taskassignments",
          taskPublished: "/taskservice/taskassignments",
          taskApproving: "/taskservice/taskassignments"
        };
        const path = distTypes[notification.groupType];
        this.toLink(path, null, false);
        return;
      } else if (["Tasks", "Documents", "News"].includes(notification.messageData.Title) && isNaN(notification.messageData.Record.Id)){
        const dist = {
          "Tasks": "/taskservice/taskassignments",
          "Documents": "/documentservice/documents/",
          "News": "/newsservice/newsitems"
        }
        const path = dist[notification.messageData.Title];
        this.toLink(path, null, false);
      }
    },
    applyCurrentRoleHandler() {
      const role = this.user.roles.find(
        (role) => role.id == this.selectRoleModel
      );
      localStorage.setItem("userRole", JSON.stringify(role));
      this.currentRole = role;
      this.user.position = this.currentRole;
      this.initNotifications(true);
    },
    onCloseError() {
      this.showMessageError = false;
      this.buisnessErrorMessage = "";
      this.buisnessErrors = [];
    },
    closeUserProfileModal() {
      this.visibleUserProfileModal = false;
    },
    showUserProfile() {
      // TODO поменять обращение через ref на пропы - это выглядит крайне запутанно
      this.getUserInfo().then((res) => {
        // res - equal this.user object
        this.$refs.modalUserProfile.user = res;
        this.$refs.modalUserProfile.currentLocale = this.i18nLocale;
        this.$refs.modalUserProfile.localeOptions = this.localeOptions;
        this.visibleUserProfileModal = true;
      });
    },
    updateLocale(locale) {
      localStorage.setItem("locale", locale.toLowerCase());
      this.$i18n.locale = locale.toLowerCase();
      this.i18nLocale = this.$i18n.locale.toUpperCase();
      if (this.$refs.modalUserProfile) {
        this.$refs.modalUserProfile.currentLocale = this.i18nLocale;
      }
      this.setPrimeVueLocale();
      this.setDocumentTitle();
      this.loadErrorCodes(this.$i18n.locale, this);
    },
    updateTheme(theme) {
      const secondTheme = theme === 'light' ? 'dark' : 'light'
      document.body.classList.remove(`theme-${secondTheme}`)
      document.body.classList.add(`theme-${theme}`)
      this.$store.dispatch("userinfoStore/setTheme", theme);
    },
    setPrimeVueLocale() {
      const locale =
        localStorage.getItem("locale") != null
          ? localStorage.getItem("locale").toUpperCase()
          : this.$i18n.locale.toUpperCase();
      this.$primevue.config.locale = this.primeVueLocales[locale];
    },
    logout() {
      this.userService.logout().then((res) => {
        removeToken();
        removeRefreshToken();
        removeRedirectUrl();
        if (res.data) window.location.href = res.data;
        else location.href = "/security/login";
      });
    },
    closeHandler() {
      this.showErrorMessageTop = false;
    },
    toLink(path, parameters, needStatictic=true) {
      const {menuitem, child} = { ...this.getItem(path) };
      if (needStatictic) {
        this.setStatistic({
          actionTemp: this.distActions.menuitem,
          actionStr: child ? child.title : menuitem.title
        })
      }

      const routerPush = () => {
        this.currentPath = path;
        this.$router.push({
          path: path,
          query: parameters,
        }).then(() => {
          this.currentPath = this.$router.currentRoute?.value?.path || window.location.pathname;
        });
      };
      if (menuitem && !this.excludeNotificationTitles.includes(menuitem.title)) {
        menuitem.readed = true;
        this.$store
          .dispatch("notifications/updateReaded", menuitem)
          .then(() => routerPush());
      } else routerPush();
    },
    getItem(path, root) {
      root = root || this.$refs.sidebar.menuList;
      for (const menuitem of root) {
        if (menuitem.path === path) {
          return {menuitem};
        }
        if (menuitem.children.length > 0) {
          const child = this.getItem(path, menuitem.children);
          if (child) return {menuitem, child: child.menuitem};
        }
      }
    },
    getLocales() {
      this.localesApi.get().then((res) => {
        this.localeOptions = res.data;
      });
    },
    initNotifications(withoutDate = false) {
      notificationsInit().then((loadedNotifications) => {
        this.getPastNotifications(loadedNotifications, withoutDate);
      });
    },
    getPastNotifications(loadedNotifications, withoutDate = null) {
      let lastDate = null;
        if(!withoutDate) {
          if (loadedNotifications && loadedNotifications.length > 0) {
            lastDate = loadedNotifications.sort(function (a, b) {
              return new Date(b.date) - new Date(a.date);
            })[0].date;
          }
          lastDate = lastDate ? moment(lastDate).format('MM/DD/yyyy HH:mm:ss') : null
        }
        this.userInfoApi.sendPending(lastDate);
    },
    getUserInfo() {
      this.pageLoaded = false;
      const me = this;
      return me.userInfoApi
        .get()
        .then((res) => {
          if (Object.keys(res.data).length > 0) {
            me.$store.dispatch("userinfoStore/setUserinfo", res.data.data);
            for (const k in res.data.data) me.user[k] = res.data.data[k];
            me.user.name =
              res.data.data.firstName + " " + res.data.data.lastName;

            me.$i18n.locale =
              localStorage.getItem("locale") || this.$i18n.locale.toUpperCase();
            if (me.$i18n.locale) {
              me.loadErrorCodes(me.$i18n.locale.toLowerCase(), me, true);
            }

            const role = JSON.parse(localStorage.getItem("userRole"));
            me.currentRole = role;
            me.user.position = me.currentRole;
            if (!me.currentRole && me.user.roles && me.user.roles.length == 1) {
              me.currentRole = Object.values(me.user.roles)[0];
              localStorage.setItem("userRole", JSON.stringify(me.currentRole));
              me.user.position = me.currentRole;
            }
            const timeZoneId = JSON.parse(localStorage.getItem("timeZoneId"));
            me.timeZoneId = timeZoneId;
            if (!me.timeZoneId || (me.user.timeZoneId !== me.timeZoneId)) {
              me.timeZoneId = me.user.timeZoneId;
              localStorage.setItem("timeZoneId", JSON.stringify(me.timeZoneId));
            }
            this.$nextTick(() => {
              if(this.roleCorrect)
                this.initNotifications();
            })
          }
          return this.user;
        })
        .finally(() => {
          me.pageLoaded = true;
          return this.user;
        });
    },
    setLocalMessage(me, e) {
      let errorInfo = me.errorCodes.find((c) => c.code == e.code);
      if (errorInfo) e.message = errorInfo.message;
      if (e.innerException) me.setLocalMessage(me, e.innerException);
    },
    setExceptionText(me, e) {
      me.errorText += `${e.code} - ${e.message}; `;
      if (e.innerException) me.setExceptionText(me, e.innerException);
    },
    loadErrorCodes(locale, me, rw = false) {
      me.errorListApi.get(locale).then((res) => {
        if (rw) {
          me.$store.dispatch("errorStore/setHandler", (error) => {
            me.errorText = "";
            if (me.errorCodes) {
              error.errors.request.forEach((e) => {
                me.setLocalMessage(me, e);
              });
            }
            error.errors.request.forEach((e) => {
              me.setExceptionText(me, e);
              if (e.traceId) me.errorText += `TraceId: ${e.traceId}; `;
              if (e.spanId) me.errorText += `SpanId: ${e.spanId}`;
            });
            me.showErrorMessageTop = true;
          });
        }
        me.$store.dispatch("errorStore/setErrorCodes", [...res.data.data]);
      });
    },
    setSecurityRoleAccess(res) {
      const me = this;
      const accesses = JSON.parse(res.data.data);
      Object.entries(accesses).forEach(([key, value]) => {
        me.$store.dispatch("securityRoleAccess/addAccess", [key, value]);
      });
      this.loadLocalRoleAccess("menu");
    },
    async getSecurityRoleAccess() {
      try {
        const res = await this.securityRoleAccessApi.getSecurityRoleAccess();
        this.$store.dispatch("securityRoleAccess/clear");
        if (res?.data && Object.keys(res.data).length > 0) {
          this.setSecurityRoleAccess(res);
        }
      } catch (e) {
        console.error(e);
      }
    },
    handlerHideNotification(notification) {
      this.showedNotification.push(notification.messageId);
      if (notification.isFront) {
        this.$store.dispatch(
          "notifications/deleteFrontNotification",
          notification
        );
      }
    },
    groupNotifications(notifications) {
      const unreadNotifications = notifications.filter(
        (n) => !this.showedNotification.includes(this.getUnicIdNotification(n))
      );
      const unreadTypes = [];
      unreadNotifications.forEach((n) => {
        const type = n.messageData.NotificationType;
        if (!unreadTypes.includes(type)) {
          unreadTypes.push(type);
        }
      });

      const groupType = {};
      notifications.forEach((n) => {
        const type = n.messageData.NotificationType;
        if (!unreadTypes.includes(type)) {
          return;
        }
        if (!groupType[type]) {
          groupType[type] = [];
        }
        if (type) {
          this.showedNotification.push(this.getUnicIdNotification(n));
        }
        groupType[type].push(n);
      });
      let undefinedType = [];
      const strUndefined = "undefined";
      if (groupType[strUndefined]) {
        undefinedType = groupType[strUndefined];
        delete groupType[strUndefined];
      }

      let result = [];
      Object.entries(groupType).forEach(([type, values]) => {
        const count = this.getCounter(values);
        if (count) {
          let notification = null;
          const unreadInfo = values[0].messageData.UnreadInfo;
          const hasUnread = Array.isArray(unreadInfo) && unreadInfo.length > 0;
          if (count > 1 || hasUnread) {
            const id = result.length + type;
            const title = this.$t(type) + " - " + count;
            const messageId = title;
            const groupType = type;
            notification = { id, title, groupType, messageId };
          } else if (values.length > 1) {
            notification =
              values.find((i) => i.messageData.UnreadCount > 0) || null;
          } else {
            notification = values[0];
          }
          if (notification) {
            const unicId = this.getUnicIdNotification(notification);
            this.showedNotification.push(unicId);
            result.push(notification);
          }
        }
      });
      undefinedType.forEach((n) => {
        const unicId = this.getUnicIdNotification(n);
        if (!this.showedNotification.includes(unicId)) {
          result.push(n);
          this.showedNotification.push(unicId);
        }
      });

      const ids = this.unreaded?.map((i) => i.id) || [];
      result = result.filter((i) => !ids.includes(i.id));
      return result;
    },
    getLoadNotifications(notifications) {
      const loadTypes = ['load-inprog', 'load-done', 'load-error']
      const result = _.cloneDeep(notifications)
        .filter(n => loadTypes.includes(n.type) && !this.showedNotification.includes(this.getUnicIdNotification(n)))
        .map(n => {
          n.static =  n.type === 'load-inprog' ? true : false
          return n
        })
      result.forEach(n => {
        const unicId = this.getUnicIdNotification(n);
        this.showedNotification.push(unicId);
      })
      return result
    },
    getUnicIdNotification(n) {
      return `${n.id}-${n.messageId}-${n.type}`;
    },
    async showSupportFrame() {
      const intraChat = document.getElementById('intraChat');
      const headerChatClose = intraChat.querySelector('.ic-header-closed');
      const bodyChat = intraChat.querySelector('.ic-body');
      const inputMessageChat = intraChat.querySelector('.ic-message-input');
      const className = 'prospace-scrollbar'
      headerChatClose.click()
      if (!bodyChat.classList.contains(className)) {
        bodyChat.classList.add(className)
      }
      if (!inputMessageChat.classList.contains(className)) {
        inputMessageChat.classList.add(className)
      }
      this.hasAnswerSupport = false;
      this.visibleSupportFrame = true;
      intraChat.style.display = 'initial'

      await nextTick()
      const closeBtn = intraChat.querySelector('.ic-close');
      const onClose = () => {
        intraChat.style.display = 'none';
        this.visibleSupportFrame = false;
        closeBtn.removeEventListener('click', onClose)
      }
      closeBtn.addEventListener('click', onClose)
    },
    cancelChangeTimezone() {
      this.confirmTimezone = {};
      this.alertTimezoneModal = false;
    }
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      errorCodes: "errorStore/getErrorCodes",
      currentTheme: "userinfoStore/getTheme",
      lastChangedNotification: "notifications/lastChanged",
      notificationMessages: "notifications/notificationMessages",
      getCounter: "notifications/getCounter",
    }),
    modalTranslates() {
      const me = this
      return {
        type: me.$t('type'),
        date: me.$t('date'),
        description: me.$t('description'),
      }
    },
    unreaded() {
      let unread = this.notificationMessages.filter(
        (n) => n.readed === false || n.isFront
      );
      if (this.userinfo.orgLinkType === "Mars") {
        unread = unread.filter((n) => !n.messageData.DistrShow);
      }
      unread = unread.filter(
        (n) =>
          ![
            "newsPublishDeleted",
            "taskPublishDeleted",
            "documentPublishDeleted",
          ].includes(n.messageData.NotificationType)
      );
      const staticNotifications = this.getLoadNotifications(unread)
      unread = this.groupNotifications(unread).map(i => {
        if (i.groupType) {
          return i
        }
        const regex = /'(.*?)'/g
        if (i.title.toLowerCase().includes('created')) {
          const matchRegex = i.title.match(regex)
          i.title = this.$t('notificationTaskExportCreateTemplate', { title: matchRegex[0] })
        } else if (i.title.toLowerCase().includes('accepted')) {
          const matchRegex = i.title.match(regex)
          i.title = this.$t('notificationTaskExportAcceptedTemplate', {title: matchRegex[0], id: matchRegex[1]})
        }
        return i
      });
      const result = unread.concat(staticNotifications)
      return result;
    },
    supportItem() {
      let supportItems = [];

      // supportItems.push({
      //   icon: 'chat',
      //   title: this.$t('supportFrame'),
      //   notificationCount: this.hasAnswerSupport,
      //   f: this.showSupportFrame
      // });

      if (this.checkLocalAccess("requestAccess-MenuItem"))
        supportItems.push({
          icon: "user-plus",
          title: this.$t("requestAccess"),
          f: () => {
            this.visibleReqCreateUserModal = true;
          },
        });

      supportItems.push({
        icon: "support",
        title: this.$t("support"),
        f: () => {
          this.visibleSupportModal = true;
        },
      });

      return supportItems;
    },
    roleCorrect() {
      if (!this.currentRole || Object.keys(this.user).length == 0) {
        return false;
      }
      return this.user.roles
        .map((elem) => elem.id)
        .includes(this.currentRole.id);
    },
  },
  watch: {
    lastChangedNotification: {
      handler(val) {
        if (val) {
          if (!this.$refs.sidebar) return;
          let mi = this.$refs.sidebar.menuList.find(
            (i) => val.messageData && i.title === val.messageData.Title
          );
          if (mi) mi.notificationCount = val.counter;
        }
      },
    },
    "$i18n.locale": {
      handler(val) {
        this.updateLocale(val.toUpperCase());
      },
    },
  },
};
</script>
