import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/masterdata/marscontactperson/";

export default class MarsContactPersonApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getMarsContactPersons(params) {
    return await request.post(`${rootRoute}getMarsContactPersons`, {
      filter: params,
      parameters: {},
    });
  }
  async getMarsContactPerson(id) {
    return await request.post(`${rootRoute}getMarsContactPersons`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createMarsContactPerson(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createMarsContactPerson`, { data });
  }
  async deleteMarsContactPerson(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteMarsContactPerson`, { data });
  }
  async editMarsContactPerson(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateMarsContactPerson`, { data });
  }
  async export(filter, format) {
    return await request.post(`${rootRoute}exportMarsContactPerson`, {
      filter: filter,
      format: format
    });
  }
}
