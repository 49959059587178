import userTasks from "../../../../Services/Scheduler/ProSpace.Scheduler.Frontend/views/UserTasks/UserTasks";
import usersTasks from "../../../../Services/Scheduler/ProSpace.Scheduler.Frontend/views/UserTasks/UsersTasks";
import systemTasks from "../../../../Services/Scheduler/ProSpace.Scheduler.Frontend/views/SystemTasks/SystemTasks";
import systemLog from "../../../../Services/Scheduler/ProSpace.Scheduler.Frontend/views/SystemTasks/SystemLogs";

export const schedulerRoutes = [
  {
    path: "/scheduler/userTasks",
    name: "userTasks",
    component: userTasks,
  },
  {
    path: "/scheduler/usersTasks",
    name: "usersTasks",
    component: usersTasks,
  },
  {
    path: "/scheduler/systemTasks",
    name: "systemTasks",
    component: systemTasks,
  },
  {
    path: "/scheduler/systemLog",
    name: "systemLog",
    component: systemLog,
  },
];

export default schedulerRoutes;
