import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/documentservice/document/";

export default class DocumentApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async createDocument(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createDocument`, { data });
  }
  async deleteDocument(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteDocument`, { data });
  }
  async editDocument(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateDocument`, { data });
  }
  async validateUsersExists(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}validateUsersExists`, { data });
  }
  async export(filter, role, format, assignOptions) {
    return await request.post(`${rootRoute}exportDocument`, {
      filter: filter,
      role: role,
      data: assignOptions,
      format: format
    });
  }
}
