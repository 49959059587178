import { setReadOnly, setAlive, setCondition } from "./elementAccessFunctions";

function workCondition(element) {
  switch (element.stateStatus) {
    case "ALIVE":
      return setAlive(element);
    case "READONLY":
      return setReadOnly(element);
  }
}

const editFieldMixin = {
  data() {
    return {
      editFieldMixinLoadingAccess: false
    }
  },
  methods: {
    mountEditFieldAccess(needLoading = true) {
      this.editFieldMixinLoadingAccess = true;
      if (this.editFieldModel 
          && Object.keys(this.editFieldModel).length > 0 
          && this.refsForCheckInMixin 
          && this.refsForCheckInMixin.length > 0) {
        this.startMountEditFieldAccess();
      } else {
        this.editFieldMixinLoadingAccess = false;
      }
    },
    startMountEditFieldAccess() {
      let checked = 0;
      const limitLoader = 10
      const interval = setInterval(() => {
        let allRefsReady = this.refsForCheckInMixin.every((elem) => Boolean(this.$refs[elem])
        );
        if (allRefsReady && checked >= limitLoader) {
          this.setEditFieldAccess();
          clearInterval(interval);
        }
        checked++;
      }, 100);
    },
    setEditFieldAccess() {
      let refs = this.$refs;
      Object.keys(this.$refs).forEach((elem) => {
        if (typeof this.editFieldModel[elem.split("-")[0]] !== "boolean") return;

        let element = refs[elem];
        let isElementReadonly = element && element.readonly
        let condition = !this.model.disabled && !isElementReadonly && this.editFieldModel[elem.split("-")[0]]
          ? "ALIVE"
          : "READONLY";
        if(element) {
          setCondition(element, condition);
          workCondition(element);
        }
      });
      this.editFieldMixinLoadingAccess = false;
    },
    checkEditFieldAccess(elem) {
      if (typeof this.editFieldModel[elem.split("-")[0]] !== "boolean") return true;

      return this.editFieldModel[elem.split("-")[0]];
    },
  },
};

export default editFieldMixin;
