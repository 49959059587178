import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/documentservice/documentattachment/";

export default class DocumentAttachmentApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getDocumentAttachments(params) {
    return await request.post(`${rootRoute}getDocumentAttachments`, {
      filter: params,
      parameters: {},
    });
  }
  async getDocumentAttachment(id) {
    return await request.post(`${rootRoute}getDocumentAttachments`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createDocumentAttachment(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createDocumentAttachment`, { data });
  }
  async deleteDocumentAttachment(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteDocumentAttachment`, { data });
  }
  async editDocumentAttachment(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateDocumentAttachment`, { data });
  }
  async setStatusDocumentAttachment(models) {
    const data = models;
    return await request.post(`${rootRoute}setstatusdocumentattachment`, { data });
  }
}
