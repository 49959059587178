<template>
  <ProSpaceGeoLayout>
    <template #top>
      <ProSpaceTopPanel :header="$t('systemTasks')">
        <template #right>
          <ProSpaceIconButton
            icon="update"
            @click="refresh(false)"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
          />
          <ProSpaceButton
            style="margin-left: 10px"
            type="primary"
            icon="plus"
            :label="$t('newTxtF')"
            @click="showTaskEditor()"
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpaceFilterPanel
            :closeTxt="$t('closeTxt')"
            :searchPlaceholder="$t('searchTxt')"
            :txtResetFilters="$t('txtResetFilters')"
            :modelFastFilter="modelFastFilter"
            :fastFilters="fastFilters"
            :unicKeyStorage="jobTriggerService.constructor.name + 'grid'"
            @apply="submitFilter"
            ref="filterPanel"
            filter-model="name"
            @update:modelFastFilter="
              (val) => {
                modelFastFilter = val;
              }
            "
          >
            <template #hint> {{ $t("filterBy") }}</template>
            <template #filters="{ filter }">
              <ProSpaceCustomFilterPanel
                :customTxt="$t('custom')"
                :customFilterTxt="$t('customFilterTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                :services="customFilters"
                :unicKeyStorage="jobTriggerService.constructor.name + 'grid'"
                @apply="(model) => customFilterApply(filter, model)"
              />
            </template>
          </ProSpaceFilterPanel>
        </template>
        <template #center>
          <ProSpaceMainGrid
            ref="grid"
            type="expand"
            getMethod="grid"
            selection-mode="single"
            defaultSortField="createdAt"
            :defaultSortOrder="-1"
            :service="jobTriggerService"
            :defaultFilter="defaultFilter"
            @selected-change="selectedHandler"
            :noMountedRequest="true"
            @expand-change="expandHandler"
            :defaultTreeFilter="defaultChildFilter"
          >
            <template #columns>
              <Column field="name" :header="$t('taskName')" sortable>
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    v-if="field.data"
                    :id="field.data.id"
                    :status="getStatus(field.data.status)"
                    :title="field.data.name"
                    :description="`${field.data.description || ''}`"
                    :no-hover="true"
                  />
                </template>
              </Column>
              <Column field="createdAt" sortable :header="$t('createdDate')">
                <template #body="field">
                  <ProSpaceDateTimeColumn
                    :date="field.data.createdAt"
                    :dateFormat="userinfo.dateFormat"
                    :timeFormat="userinfo.timeFormat"
                  />
                </template>
              </Column>
              <Column field="createdBy" :header="$t('createdByTxt')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    {{ field.data.createdBy }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column
                field="lastExecution"
                :header="$t('lastExecution')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    {{ field.data.lastExecution }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column
                field="actions"
                class="column-end column-end-30"
              >
                <template #body="field">
                  <ProSpaceDots
                    :options="mainOptions"
                    @action="
                      (action) => handleDots(mainOptions, action, field.data)
                    "
                  />
                </template>
              </Column>
            </template>
            <template #expansion>
              <Column field="name" :header="$t('taskName')" sortable>
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    v-if="field.data"
                    :id="field.data.id"
                    :status="getStatus(field.data.status)"
                    :title="field.data.name"
                    :description="`${field.data.description || ''}`"
                    :no-hover="true"
                  />
                </template>
              </Column>
              <Column style="max-width: 24.5%">
                <template #body="slotProps">
                  <ProSpaceTableHeaderCell
                    :title="$t('Repeat')"
                    :description="getRepeatText(slotProps.data)"
                  />
                </template>
              </Column>
              <Column style="max-width: 19%">
                <template #body="slotProps">
                  <ProSpaceTextBlock
                    type="secondary"
                    :top="$t('lastStart')"
                    :bottom="getFormatDateTime(slotProps.data.lastExecution)"
                  />
                </template>
              </Column>
              <Column>
                <template #body="slotProps">
                  <ProSpaceTextBlock
                    type="secondary"
                    :top="$t('nextStart')"
                    :bottom="getFormatDateTime(slotProps.data.nextExecution)"
                  />
                </template>
              </Column>
              <Column
                field="actions"
                class="column-end column-end-30"
              >
                <template #body="slotProps">
                  <ProSpaceDots
                    :options="expandOptions"
                    @action="
                      (action) =>
                        handleDots(expandOptions, action, slotProps.data)
                    "
                  />
                </template>
              </Column>
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                icon="error-message"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
              />
            </template>
          </ProSpaceMainGrid>
        </template>
      </ProSpaceGeoLayout>
      <TaskEditor
        ref="taskEditor"
        @saved="refresh(false)"
        @deleted="refresh"
        @closedAfterCreate="refresh(false)"
      />
      <TriggerEditor 
        ref="triggerEditor" 
        :activeTab="triggerModalActiveTab" 
        @saved="refresh(false)" 
      />
      <TaskResult ref="taskResult" />
      <TaskLog ref="taskLog" />
      <SystemLogModal ref="systemLog" />
    </template>
    <template #right>
      <SystemTaskPanel
        v-if="hasSelect && isTaskSelected"
        ref="right-panel"
        :service="jobTriggerService"
        :id="selectedData.id"
        :options="mainOptions"
        @action="(action, task) => handleDots(mainOptions, action, task)"
        @editClick="showTaskEditor"
      />
      <SystemTriggerPanel
        v-else-if="hasSelect && isTriggerSelected"
        ref="right-panel"
        :service="jobTriggerService"
        :id="selectedData.id"
        :options="expandOptions"
        @action="
          (action, trigger) => handleDots(expandOptions, action, trigger)
        "
        @editClick="(trigger) => showTriggerEditor(null, trigger)"
      />
    </template>
  </ProSpaceGeoLayout>
  <ProSpaceHistoryModal
    ref="history"
    v-model="showHistoryModal"
    modelName="JobTriggerConfigurationApiModel"
    @close="closeHistory"
  />
  <ProSpaceFlashMessage
    v-if="showDeleteTaskModal"
    v-model="showDeleteTaskModal"
    type="default"
    icon="flash-warning"
    :title="$t('deletingContactUser')"
    :applyTxt="$t('confirmTxt')"
    :cancelTxt="$t('reject')"
    @apply="applyDeleteTask"
  >
    <ProSpaceVLayout :gap="10">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
        v-html="$t('deleteSystemTask', { name:  deletingTask.name })"
      ></div>
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
  <ProSpaceFlashMessage
    v-if="showDeleteTriggerModal"
    v-model="showDeleteTriggerModal"
    type="default"
    icon="flash-warning"
    :title="$t('deletingContactUser')"
    :applyTxt="$t('confirmTxt')"
    :cancelTxt="$t('reject')"
    @apply="applyDeleteTrigger"
  >
    <ProSpaceVLayout :gap="10">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
        v-html="$t('deleteTrigger', { name:  deletingTrigger.name })"
      ></div>
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
</template>
<script>
import {
  ProSpaceGeoLayout,
  ProSpaceTableHeaderCell,
  ProSpaceMainGrid,
  ProSpaceCustomFilterPanel,
  ProSpaceFilterPanel,
  ProSpaceIconButton,
  ProSpaceTopPanel,
  ProSpaceButton,
  ProSpaceDots,
  ProSpaceEmptyGrid,
  ProSpaceTextBlock,
  ProSpaceDefaultTextColumn,
  ProSpaceFilterContainsChips,
  ProSpaceDateTimeColumn,
  ProSpaceFlashMessage
} from "prospace-components-library";
import Column from "primevue/column";
import SystemTaskPanel from "./components/SystemTaskPanel";
import SystemTriggerPanel from "./components/SystemTriggerPanel";
import SystemLogModal from "./components/SystemLogModal";
import TriggerEditor from "./components/TriggerEditor";
import TaskEditor from "./components/TaskEditor";
import TaskResult from "../components/TaskResult";
import TaskLog from "../components/TaskLog";
import { JobTriggerService } from "../../services/JobTriggerService.js";
import _ from "lodash";
import ProSpaceHistoryModal from "@modals/ProSpaceHistoryModal.vue";
import { mapGetters } from "vuex";
import {
  useMultiSelectGrid,
} from "@composes/multiselect";
import { ref } from "vue"
export default {
  components: {
    ProSpaceGeoLayout,
    ProSpaceEmptyGrid,
    ProSpaceTableHeaderCell,
    Column,
    ProSpaceMainGrid,
    ProSpaceCustomFilterPanel,
    ProSpaceFilterPanel,
    ProSpaceIconButton,
    ProSpaceTopPanel,
    ProSpaceButton,
    ProSpaceDots,
    SystemTaskPanel,
    SystemTriggerPanel,
    TaskResult,
    TaskLog,
    SystemLogModal,
    TriggerEditor,
    TaskEditor,
    ProSpaceTextBlock,
    ProSpaceHistoryModal,
    ProSpaceDefaultTextColumn,
    ProSpaceFilterContainsChips,
    ProSpaceDateTimeColumn,
    ProSpaceFlashMessage
  },
  setup() {
    const grid = ref(null)
    const filterPanel = ref(null)
    const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      selectedData,
      hasSelect,
      selectedMultipleBlockFilterHandler
    } = useMultiSelectGrid(grid, filterPanel)
    return {
      grid, filterPanel,
      selectedMultipleGridHandler, selectedData,
      hasSelect, selectedMultipleClear,
      selectedMultipleBlockFilterHandler
    }
  },
  data() {
    return {
      showHistoryModal: false,

      jobTriggerService: new JobTriggerService(),

      isTaskSelected: false,
      isTriggerSelected: false,
      defaultChildFilter: { disabled: { and: [false] } },

      deletingTask: null,
      showDeleteTaskModal: false,
      deletingTrigger: null,
      showDeleteTriggerModal: false,
      triggerModalActiveTab: ""
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime",
    }),
    defaultFilter() {
      return {
        itemType: { and: ["Job"] },
      };
    },
    modelFastFilter() {
      return {
        label: this.$t("allTxt"),
        value: false,
        field: "disabled",
      };
    },
    fastFilters() {
      return [
        {
          label: this.$t("allTxt"),
          value: false,
          field: "disabled",
        },
        {
          label: this.$t("active"),
          field: "status",
          value: "COMPLETE",
        },
      ];
    },
    mainOptions() {
      return [
        {
          name: "editTask",
          text: this.$t("editItemTxt"),
          icon: "edit",
          method: this.showTaskEditor,
        },
        {
          name: "addTrigger",
          text: this.$t("addItemTxt") + " " + this.$t("trigger"),
          icon: "edit",
          method: this.showTriggerEditor,
        },
        {
          name: "systemLog",
          text: this.$t("taskLog"),
          icon: "edit",
          method: this.showSystemLog,
        },
        {
          name: "deleteTask",
          text: this.$t("delete") + " " + this.$t("taskY"),
          icon: "edit",
          method: this.deleteTask,
        },
        {
          name: "showHistory",
          text: this.$t("history"),
          icon: "history",
          method: this.showHistory,
        },
      ];
    },
    expandOptions() {
      return [
        {
          name: "editTrigger",
          text: this.$t("editItemTxt"),
          icon: "edit",
          method: this.showTriggerEditor,
        },
        {
          name: "deleteTrigger",
          text: this.$t("delete") + " " + this.$t("trigger"),
          icon: "edit",
          method: this.deleteTrigger,
        },
        {
          name: "showHistory",
          text: this.$t("history"),
          icon: "history",
          method: this.showHistory,
        },
      ];
    },
    customFilters() {
      return [
        {
          type: "dates-range",
          model: "nextExecution",
          label: this.$t("nextExecution"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range",
          model: "lastExecution",
          label: this.$t("lastExecution"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "chips",
          model: "createdBy",
          label: this.$t("createdByTxt"),
          selected: {},
        },
        {
          type: "dates-range",
          model: "createdAt",
          label: this.$t("createDate"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
      ];
    },
  },
  methods: {
    closeHistory() {
      this.showHistoryModal = false;
    },
    showHistory(record) {
      this.$refs.history.tabs[0].masterId = record.id;
      this.$refs.history.masterName = record.name;
      this.$nextTick(() => {
        this.showHistoryModal = true;
      });
    },
    handleDots(dotsOptions, action, item) {
      var option = dotsOptions.find((o) => o.name === action);
      if (action !== "editTrigger") option.method(item);
      else option.method(null, item);
    },
    submitFilter({ filter, search }) {
      this.$refs.grid.submitFilter(filter, search);
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.filterPanel.submitFilter();
    },
    refresh(needCloseRightPanel) {
      const me = this;
      this.$nextTick(() => {
        me.$refs.grid.getRecords(true);

        if (needCloseRightPanel) {
          me.selectionChanged(null);
        } else {
          if (me.$refs["right-panel"]) me.$refs["right-panel"].update();
        }
      });
    },
    selectedHandler(val) {
      this.selectionChanged(val, false);
    },
    expandHandler(val) {
      this.selectionChanged(val, true);
    },
    selectionChanged(val, isTriggerSelected) {
      this.selectedMultipleGridHandler(val)
      if (!val.hasSelect) {
        this.isTriggerSelected = false;
        this.isTaskSelected = false;
      } else {
        this.isTriggerSelected = isTriggerSelected;
        this.isTaskSelected = !isTriggerSelected;
      }
    },
    fnSetTaskRecords(response) {
      const records = response.data.records;
      const tasks = [];
      records.forEach((i) => {
        if (!i.parentId) {
          i.children = [];
          tasks.push(i);
        } else {
          const item = tasks.find((mi) => mi.id === i.parentId);
          item?.children?.push(i);
        }
      });
      return tasks;
    },
    getStatus(status) {
      if (!status) return;
      let type = "default";
      switch (status.toLowerCase()) {
        case "complete":
          type = "success";
          break;
        case "process":
          type = "warning";
          break;
        case "error":
          type = "danger";
          break;
        default:
          type = "default";
      }
      return {
        text: status[0].toUpperCase() + status.toLowerCase().slice(1),
        type,
      };
    },
    getRepeatText(trigger) {
      return this.jobTriggerService.getRepeatText(trigger);
    },
    showTaskEditor(task) {
      let model = task ? _.cloneDeep(task) : {};
      this.showModalForm("taskEditor", model);
    },
    showModalForm(name, item) {
      this.$refs[name].show(item);
    },
    showTriggerEditor(task, trigger) {
      let model = trigger
        ? _.cloneDeep(trigger)
        : {
            name: "Trigger",
            itemType: "Trigger",
            parentId: task.id,
          };

      this.triggerModalActiveTab = this.$refs['right-panel']
        ? this.$refs['right-panel'].tabs.find(t => t.isActive).systemName
        : "";
      this.showModalForm("triggerEditor", model);
    },
    showSystemLog(item) {
      this.showModalForm("systemLog", item);
    },
    showTaskResult(item) {
      this.showModalForm("taskResult", item);
    },
    showTaskLog(item) {
      this.showModalForm("taskLog", item);
    },
    deleteTask(item) {
      this.deletingTask = item;
      this.showDeleteTaskModal = true;
    },
    applyDeleteTask() {
      this.delete(this.deletingTask);
    },
    deleteTrigger(item) {
      this.deletingTrigger = item;
      this.showDeleteTriggerModal = true;
    },
    applyDeleteTrigger() {
      this.delete(this.deletingTrigger);
    },
    delete(item) {
      this.jobTriggerService.delete(item).then((response) => {
        this.refresh(this.selectedData?.id == item.id);
        this.deletingTrigger = null;
        this.deletingTask = null;
      });
    },
    pauseTask(item) {},
    pauseTrigger(item) {},
  },
};
</script>
<style lang="scss"></style>
