<template>
    <ProSpaceVLayout class="home-dashboard" :class="{'home-dashboard--loading': loading}" :gap="10" is-inline-h>
        <template v-if="loading">
            <ProSpaceVLayout :gap="40">
                <Skeleton height="15px" />
                <ProSpaceVLayout :gap="10">
                    <Skeleton height="15px" />
                    <Skeleton height="15px" />
                </ProSpaceVLayout>
            </ProSpaceVLayout>
        </template>
        <template v-else>
            <ProSpaceHLayout class="home-dashboard__header" :gap="10" is-inline-h>
                <div class="home-dashboard__icon" :class="{'home-dashboard__icon--hide-badge': !count}"
                    v-badge.notification.big="count > 99 ? '99+' : count"
                    :key="count"
                >
                    <ProSpaceIcon icon-width="24px" icon-height="24px" :icon="icon" type="secondary" />
                </div>
                <div class="text-color-main text-bold text-size-16 text-ellipsis">
                    {{ title }}
                </div>
                <ProSpaceIcon class="home-dashboard__hint" icon="question" v-hint="hint" />
            </ProSpaceHLayout>
            <slot />
        </template>
    </ProSpaceVLayout>
</template>

<script>
import {
  ProSpaceVLayout,
  ProSpaceHLayout,
  ProSpaceIcon,
  Hint
} from "prospace-components-library";
import BadgeDirective from "primevue/badgedirective";
import Skeleton from 'primevue/skeleton';
export default {
    name: "HomeDashboard",
    components: {
        ProSpaceVLayout,
        ProSpaceHLayout,
        ProSpaceIcon,
        Skeleton
    },
    props: {
        icon: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        count: {
            type: Number,
            default: 0
        },
        hint: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    directives: {
        badge: BadgeDirective,
        hint: Hint
    },
}
</script>

<style lang="scss">
.home-dashboard {
    position: relative;
    padding: 10px;
    background: var(--prospace-ui-gray-card-bg);
    border-radius: 5px;
    padding: 15px 10px 10px;
    &--loading {
        background: transparent;
        .p-skeleton {
            max-width: 150px;
        }
    }
    &__header {
        width: calc(100% - 25px) !important;
    }
    &__icon {
        height: 24px;
        &--hide-badge {
            .p-badge {
                display: none;
            }
        }
    }
    &__hint {
        position: absolute;
        right: 10px;
        top: 10px;
        background: var(--prospace-text-light-gray);
    }
}
</style>
