import statisticsreports from "../../../../Services/StatisticsReport/StatisticsReportService.Frontend/views/StatisticsReports/StatisticsReports";

export const statisticsreportRoutes = [
  {
    path: "/statisticsreport/statisticsreports",
    name: "statisticsreports",
    component: statisticsreports,
  },
];

export default statisticsreportRoutes;
