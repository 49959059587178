import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/masterdata/filialstructureusersview/";

export default class FilialStructureUsersViewApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getFilialStructureUsersViews(params) {
    return await request.post(`${rootRoute}getFilialStructureUsersViews`, {
      filter: params,
      parameters: {},
    });
  }
  async getFilialStructureUsersView(id) {
    return await request.post(`${rootRoute}getFilialStructureUsersViews`, {
      filter: `$filter=Id eq '${id}'`,
      parameters: {},
    });
  }
  async export(filter, role, format) {
    return await request.post(`${rootRoute}exportfilialstructureusersview`, {
      filter: filter,
      role: role,
      format: format
    });
  }
}
