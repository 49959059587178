import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/competence/competencecomment/";

export default class CompetenceCommentApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getCompetenceComments(params) {
    return await request.post(`${rootRoute}getCompetenceComments`, {
      filter: params,
      parameters: {},
    });
  }
  async getCompetenceComment(id) {
    return await request.post(`${rootRoute}getCompetenceComments`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createCompetenceComment(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createCompetenceComment`, { data });
  }
  async deleteCompetenceComment(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteCompetenceComment`, { data });
  }
  async editCompetenceComment(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateCompetenceComment`, { data });
  }
}
