import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/WorkflowItemApi";

export class WorkflowItemService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "get", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getById", null, id);
  }
  async getByWorkflowId(params, id) {
    return await this.transformer.getGrid(this.Api, "getByWorkflowId", params);
  }
  async getMainGridByWorkflowId(args) {
    return await this.transformer.getGrid(this.Api, "getMainGridByWorkflowId", args);
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "create", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "delete", model);
  }
  async edit(model) {
    return await this.transformer.editItem(this.Api, "update", model);
  }
  async localGet(model) {
    return await this.transformer.localGetById(model.id);
  }
}
