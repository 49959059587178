import {Transformer} from "../../../../Transformer/index.js";
import Api from "../../../../Api/MenuItemRoleApi";

export class MenuItemRoleService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
    this.item = null
  }
  setItem(item) {
    this.item = item
  }
  getCreateModels(models) {
    return models.map(m => {
      return {
        menuItemComponent: m.component,
        menuItemId: m.id,
        menuItemOrder: m.order,
        menuItemParentId: m.parentId,
        menuItemPath: m.path,
        menuItemTitle: m.title,
        securityRoleDisplayName: this.item.displayName,
        securityRoleId: this.item.id,
        securityRoleSystemName: this.item.systemName
      }
    })
  }
  async get(id) {
    return await this.transformer.getItem(this.Api, "get", id);
  }
  async create(models) {
    return await this.transformer.getItem(this.Api, "create", this.getCreateModels(models));
  }
  async delete(models) {
    return await this.transformer.getItem(this.Api, "delete", models);
  }
}
