/*
Пример конфига
{
  "ref": {
    "Role": "Condition",
    "Role 2": {
      "status 1": "Condition",
      "status 2": "Condition",
      "status 3": "Condition"
    }
    "Role 3": {
      "status 1": "Condition",
      "other": "Condition"
    }
  },
  "ref 2": { ... }
}
*/

import { setReadOnly, setAlive, setDisable, setNone, setCondition } from "./elementAccessFunctions";

function workCondition(element) {
  switch (element.stateStatus) {
    case "ALIVE":
      return setAlive(element);
    case "DISABLE":
      return setDisable(element);
    case "NONE":
      return setNone(element);
    case "READONLY":
      return setReadOnly(element);
  }
}

const statusAccessMixin = {
  data() {
    return {
      statusMixinLoadingAccess: false
    }
  },
  methods: {
    mountStatusAccess() {
      this.statusMixinLoadingAccess = true;
      if (this.statusAccessConfig 
          && Object.keys(this.statusAccessConfig).length > 0 
          && this.refsForCheckInMixin 
          && this.refsForCheckInMixin.length > 0) {
        this.startMountStatusAccess();
      } else {
        this.statusMixinLoadingAccess = false;
      }
    },
    startMountStatusAccess() {
      let checked = 0;
      const limitLoader = 10
      const interval = setInterval(() => {
        let allRefsReady = this.refsForCheckInMixin.every((elem) => Boolean(this.$refs[elem])
        );
        if (allRefsReady && checked >= limitLoader) {
          this.setStatusAccess();
          clearInterval(interval);
        }
        checked++;
      }, 100);
    },
    checkStatusCreator(model) {
      return model && this.userinfo && (!model.id || model.createdBy === this.userinfo.code);
    },
    wrapperStatusCheckRoles(elemsList, model) {
      let res = elemsList.filter((elem) => {
        if (this.statusAccessConfig) {
          let element = this.statusAccessConfig ? this.statusAccessConfig[elem.liteRef] : null;
          let exclude = false;
          if (element) {
            let item = JSON.parse(localStorage.getItem("userRole"));
            let role = item && item.systemName ? item.systemName : null;

            let tmpCond = element[role];
            if (typeof tmpCond === 'object' && !Array.isArray(tmpCond) && tmpCond !== null) {
              tmpCond = tmpCond[model.status] ? tmpCond[model.status] : tmpCond.other;
            }

            if (
              tmpCond == "NONE" ||
              (tmpCond == "CREATOR_OR_NONE" && !this.checkStatusCreator(model))
            ) {
              exclude = true;
              return false;
            }
          }
          return !exclude;
        }
      });
      return res;
    },
    checkStatusAccess(elem, model) {
      let isAccess = false;
      
      if(model && model.disabled && !elem.includes("tab-")) 
        return isAccess;

      if (model && model.status && this.statusAccessConfig) {
        let element = this.statusAccessConfig ? this.statusAccessConfig[elem] : null;
        if (element) {
          let roleModel = JSON.parse(localStorage.getItem("userRole"));
          let role =
            roleModel && roleModel.systemName ? roleModel.systemName : null;
          if (this.getStatusCondition(element, role, model) === "ALIVE")
            isAccess = true;
        }
      }
      return isAccess;
    },
    setStatusAccess() {
      if (!this.model || !this.model.status) {    
        this.statusMixinLoadingAccess = false;
        return;
      }

      let item = JSON.parse(localStorage.getItem("userRole"));
      let role = item && item.systemName ? item.systemName : null;
      let refs = this.$refs;
      Object.keys(this.statusAccessConfig).forEach((elem) => {
        if (refs[elem]) {
          let condition = this.getStatusCondition(this.statusAccessConfig[elem], role, null, elem);
          let element = refs[elem];
          if(element) {
            setCondition(element, condition);
            workCondition(element);
          }
        }
      });

      this.statusMixinLoadingAccess = false;  
    },
    getStatusCondition(elemAccesses, role, model, elem) {
      let condition = "ALIVE";
      let record = model ? model : this.model;
      
      if(record.disabled) {
        const buttons = ["action-", "-button"];
        condition = buttons.some(b => elem.includes(b)) ? "NONE" : "READONLY";
        return condition;
      }

      if (elemAccesses && elemAccesses[role]) {
        let tmpCond = elemAccesses[role];
        if (typeof tmpCond === 'object' && !Array.isArray(tmpCond) && tmpCond !== null) {
          tmpCond = tmpCond[record.status] ? tmpCond[record.status] : tmpCond.other;
        }
        if (!(tmpCond.includes("CREATOR") && this.checkStatusCreator(record))) {
          condition = tmpCond.includes("CREATOR")
            ? tmpCond.split("_OR_")[1]
            : tmpCond;
        }
      }

      return condition;
    },
  },
};

export default statusAccessMixin;
