<template>
  <ProSpaceGeoLayout class="role">
    <template #top>
      <ProSpaceTopPanel :header="layoutName" back @back="backHandler" />
    </template>
    <template #center>
      <ProSpaceRadioButtonSecondary
        v-if="model"
        :tabs="tabs"
        v-model="modelTab"
        @select="handlerSelectTab"
      >
        <ProSpaceAction
          icon="double-check"
          :text="$t('saveAll')"
          :disabled="v$.model.$invalid || loading || useCasesLoading"
          @click="saveAll"
        />
        <template #Overview>
          <ProSpaceGeoLayout
            is-template
            :template-center="true"
            :template-right="true"
          >
            <template #center>
              <ProSpaceTabs
                :tabs="overviewTabs"
                :loading="loading"
                :withoutMarginBottom="true"
                @select-tab="handlerSelectTab"
              >
                <ProSpaceAction
                  icon="check"
                  :text="$t('save')"
                  :disabled="v$.model.$invalid"
                  @click="savePart(saveFields.Overview)"
                />
                <template v-slot:tab-1>
                  <ProSpaceVLayout :gap="10">
                    <ProSpaceGeoLayout class="geo-row">
                      <template v-slot:left>
                        <ProSpaceTextBlock
                          :top="$t('identification')"
                          :bottom="$t('identificationInformation')"
                          :gap="10"
                          type="third"
                          break-word
                        />
                      </template>
                      <template v-slot:center>
                        <ProSpaceInputText
                          v-model="model.systemName"
                          :label="$t('name')"
                          :placeholder="$t('name')"
                          :invalid="v$.model.systemName.$error"
                          :invalid-messages="v$.model.systemName.$errors"
                          @blur="v$.model.systemName.$touch"
                        />
                      </template>
                    </ProSpaceGeoLayout>
                  </ProSpaceVLayout>
                </template>
              </ProSpaceTabs>
            </template>
            <template #right>
              <ProSpaceRightPanel no-border>
                <template v-slot:center>
                  <ProSpacePanelBlock :header="rolePanelHeader">
                    <template v-slot>
                      <ProSpacePanelBlockList :list="rolePanelContent" />
                    </template>
                  </ProSpacePanelBlock>
                </template>
                <template v-slot:bottom>
                  <ProSpacePanelBlock :header="rolePanelMeta">
                    <template v-slot>
                      <ProSpacePanelBlockList :list="rolePanelMetaContent" />
                    </template>
                  </ProSpacePanelBlock>
                </template>
              </ProSpaceRightPanel>
            </template>
          </ProSpaceGeoLayout>
        </template>
        <template #UseCases>
          <ProSpaceGeoLayout
            is-template
            :template-center="true"
            :template-right="true"
          >
            <template #center>
              <ProSpaceTabs :tabs="useCasesTabs" :loading="loading" @select-tab="handlerSelectTab">
                <ProSpaceHLayout :is-inline-w="true" :gap="10">
                  <ProSpaceAddInputButton
                    ref="add-btn-usecases"
                    searchValue="title"
                    getMethod="grid"
                    colName="title"
                    colDesc="description"
                    filterField="securityUseCaseId"
                    second-get-method="grid"
                    second-create-method="create"
                    second-delete-method="delete"
                    :id="masterId"
                    :service="useCasesService"
                    :defaultFilter="{ disabled: { and: [false] } }"
                    :second-service="useCaseRoleService"
                    :addTxt="$t('add')"
                    :secondData="useCaseGridData"
                    :cancelTxt="$t('cancelInf')"
                    :applyTxt="$t('applyTxt')"
                    :disabled="useCasesLoading"
                    @apply="reloadGrid('grid-usecases')"
                  />
                  <ProSpaceAction
                    icon="check"
                    :text="$t('save')"
                    @click="savePart(saveFields.UseCases)"
                    :disabled="loading || useCasesLoading"
                  />
                </ProSpaceHLayout>
                <template v-slot:tab-1>
                  <ProSpaceMainGrid
                    v-if="masterId"
                    :key="masterId"
                    :id="masterId"
                    class="in-block-grid"
                    ref="grid-usecases"
                    :isInline="true"
                    :service="useCaseRoleService"
                    :fnSetRecords="setRecords"
                    @loaded="useCaseGridLoaded()"
                    getMethod="grid"
                    selection-mode="single"
                    style="height: calc(100vh - 180px)"
                  >
                    <template #columns>
                      <Column
                        field="securityUseCaseTitle"
                        :header="$t('title')"
                        sortable
                      >
                        <template #body="field">
                          {{ field.data.securityUseCaseTitle }}
                        </template>
                      </Column>
                      <Column
                        field="securityUseCaseDescription"
                        :header="$t('value')"
                        sortable
                      >
                        <template #body="field">
                          <ProSpaceDefaultTextColumn>
                            {{ field.data.securityUseCaseDescription }}
                          </ProSpaceDefaultTextColumn>
                        </template>
                      </Column>
                      <Column
                        field="actions"
                        style="display: flex; justify-content: flex-end"
                      >
                        <template #body="field">
                          <ProSpaceIconButton
                            icon="circle-close"
                            @click="
                              deleteRecord(
                                field.data,
                                'grid-usecases',
                                useCaseRoleService
                              )
                            "
                          />
                        </template>
                      </Column>
                    </template>
                    <template #empty>
                      <ProSpaceEmptyGrid
                        icon="error-message"
                        :title="$t('noDataTitle')"
                        :text="$t('noDataTxt')"
                      />
                    </template>
                  </ProSpaceMainGrid>
                </template>
              </ProSpaceTabs>
            </template>
            <template #right>
              <ProSpaceRightPanel no-border>
                <template v-slot:center>
                  <ProSpacePanelBlock :header="rolePanelHeader">
                    <template v-slot>
                      <ProSpacePanelBlockList :list="rolePanelContent" />
                    </template>
                  </ProSpacePanelBlock>
                </template>
                <template v-slot:bottom>
                  <ProSpacePanelBlock :header="rolePanelMeta">
                    <template v-slot>
                      <ProSpacePanelBlockList :list="rolePanelMetaContent" />
                    </template>
                  </ProSpacePanelBlock>
                </template>
              </ProSpaceRightPanel>
            </template>
          </ProSpaceGeoLayout>
        </template>
        <template #MenuItems>
          <ProSpaceGeoLayout
            is-template
            :template-center="true"
            :template-right="true"
          >
            <template #center>
              <ProSpaceTabs :tabs="menuitemsTabs" :loading="loading" @select-tab="handlerSelectTab">
                <ProSpaceHLayout :is-inline-w="true">
                  <ProSpaceAction
                    icon="check"
                    :text="$t('save')"
                    @click="savePart(saveFields.UseCases)"
                  />
                </ProSpaceHLayout>
                <template v-slot:tab-1>
                  <ProSpaceMainGrid
                    ref="grid"
                    type="expand"
                    :isHeadHide="true"
                    :service="menuItemsService"
                    :fnSetRecords="fnSetMenuItemsRecords"
                    getMethod="grid"
                    selection-mode="single"
                    style="height: calc(100% - 51px)"
                    :default-filter="defaultFilters.disabled"
                  >
                    <template #columns>
                      <Column
                        class="text-bold text-size-14"
                        field="title"
                        style="width: 33%"
                      >
                        <template #body="slotProps">
                          <ProSpaceDefaultTextColumn>
                            <ProSpaceHLayout :gap="23" style="cursor: pointer">
                              <span>{{ slotProps.data.title }}</span>
                            </ProSpaceHLayout>
                          </ProSpaceDefaultTextColumn>
                        </template>
                      </Column>
                      <Column style="overflow: visible">
                        <template #body="slotProps">
                          <ProSpaceHLayout justify-content="flex-end">
                            <ProSpaceInputSwitch
                              v-model="slotProps.data.active"
                              @update:modelValue="
                                switchHandler(slotProps.data, $event)
                              "
                              :noBorder="true"
                            />
                          </ProSpaceHLayout>
                        </template>
                      </Column>
                    </template>
                    <template #expansion>
                      <Column class="text-bold text-size-12">
                        <template #body="slotProps">
                          <ProSpaceHLayout
                            justify-content="flex-start"
                            :gap="23"
                          >
                            <span>{{ slotProps.data.title }}</span>
                          </ProSpaceHLayout>
                        </template>
                      </Column>
                      <Column style="overflow: visible">
                        <template #body="slotProps">
                          <ProSpaceHLayout justify-content="flex-end">
                            <ProSpaceInputSwitch
                              v-model="slotProps.data.active"
                              @update:modelValue="
                                switchHandler(slotProps.data, $event)
                              "
                              :noBorder="true"
                            />
                          </ProSpaceHLayout>
                        </template>
                      </Column>
                    </template>
                  </ProSpaceMainGrid>
                </template>
              </ProSpaceTabs>
            </template>
            <template #right>
              <ProSpaceRightPanel no-border>
                <template v-slot:center>
                  <ProSpacePanelBlock :header="rolePanelHeader">
                    <template v-slot>
                      <ProSpacePanelBlockList :list="rolePanelContent" />
                    </template>
                  </ProSpacePanelBlock>
                </template>
                <template v-slot:bottom>
                  <ProSpacePanelBlock :header="rolePanelMeta">
                    <template v-slot>
                      <ProSpacePanelBlockList :list="rolePanelMetaContent" />
                    </template>
                  </ProSpacePanelBlock>
                </template>
              </ProSpaceRightPanel>
            </template>
          </ProSpaceGeoLayout>
        </template>
      </ProSpaceRadioButtonSecondary>
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import {
  ProSpaceInputText,
  ProSpaceAddInputButton,
  ProSpaceRadioButtonSecondary,
  ProSpaceInputSwitch,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceIconButton,
  ProSpaceTextBlock,
  ProSpaceMainGrid,
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceAction,
  ProSpaceTag,
  ProSpaceTabs,
  ProSpaceRightPanel,
  ProSpacePanelBlock,
  ProSpaceEmptyGrid,
  ProSpacePanelBlockList,
  ProSpaceDefaultTextColumn,
  Filters,
} from "prospace-components-library";
import { RoleService } from "../../services/RoleService.js";
import { UseCaseRoleService } from "../../services/UseCaseRoleService.js";
import { UseCasesService } from "../../services/UseCasesService.js";
import { MenuItemsService } from "../../services/MenuItemsService.js";
import { MenuItemRoleService } from "../../services/MenuItemRoleService.js";
import { mapGetters } from "vuex";
import Column from "primevue/column";
import {
  required,
  maxLength,
  helpers,
  minValue,
  maxValue,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {
  useStatistic
} from "@composes"
export default {
  components: {
    ProSpaceInputText,
    ProSpaceAddInputButton,
    ProSpaceRadioButtonSecondary,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceIconButton,
    ProSpaceInputSwitch,
    ProSpaceTextBlock,
    ProSpaceMainGrid,
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    Column,
    ProSpaceEmptyGrid,
    ProSpaceAction,
    ProSpaceTag,
    ProSpaceTabs,
    ProSpaceRightPanel,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceDefaultTextColumn,
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    return { v$: useVuelidate(), setStatistic, distActions };
  },
  data() {
    return {
      switchModel: {},

      // Tabs
      modelTab: null, // Перовое предустановленное значение

      // Common
      layoutName: "",
      loayoutNameProp: "systemName",
      model: null,
      modelMenuItem: null,
      loading: false,
      useCasesLoading: false,

      // Service
      roleService: new RoleService(),
      useCaseRoleService: new UseCaseRoleService(),
      useCasesService: new UseCasesService(),
      menuItemsService: new MenuItemsService(),
      menuItemRoleService: new MenuItemRoleService(),
      getMethod: "get",
      masterId: null,
      recordCounted: null,

      // Modal
      modalModel: { name: "", description: "", startDate: "", endDate: "" },
      isEdit: false,
      isCreate: false,

      // SaveFields
      saveFields: {
        Overview: ["systemName"],
        UseCases: ["useCases"],
      },
      useCaseRoleRecords: [],
      defaultFilters: Filters.getDefaultsFilters(),
    };
  },
  validations() {
    return {
      model: {
        systemName: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
      },
    };
  },
  mounted() {
    this.modelTab = this.tabs[0];
    this.getData();
  },
  computed: {
    ...mapGetters({
      getFormatDateTime: "userinfoStore/getFormatDateTime"
    }),
    useCaseGridData() {
      return this.useCaseRoleRecords;
    },
    tabs() {
      return [
        { label: this.$t("overview"), value: "Overview", systemName: "overview" },
        { label: this.$t("useCases"), value: "UseCases", systemName: "useCases" },
        { label: this.$t("menuItems"), value: "MenuItems", systemName: "menuItems" },
      ];
    },
    rolePanelHeader() {
      return {
        title: this.$t("role"),
      };
    },
    rolePanelMeta() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    overviewTabs() {
      return [{ name: this.$t("roleProfile"), systemName: "roleProfile" }];
    },
    useCasesTabs() {
      return [{ name: this.$t("useCases"), counter: this.recordCounted, systemName: "useCases" }];
    },
    menuitemsTabs() {
      return [{ name: this.$t("menuItems"), counter: this.recordCounted, systemName: "menuItems" }];
    },
    rolePanelContent() {
      return [
        [
          {
            title: this.$t("name"),
            text: this.model.systemName,
          },
        ],
      ];
    },
    rolePanelMetaContent() {
      return [
        [
          {
            title: this.$t("createdByTxt"),
            text: this.model.createdBy,
          },
        ],
        [
          {
            title: this.$t("createdAtTxt"),
            text: this.getFormatDateTime(this.model.createdAt),
          },
          {
            title: this.$t("lastUpdatedTxt"),
            text: this.getFormatDateTime(this.model.lastUpdated),
          },
        ],
      ];
    },
    disabledModalRule() {
      return (
        this.modalModel.name.length == 0 ||
        this.modalModel.description.length == 0
      );
    },
  },
  methods: {
    handlerSelectTab(value) {
      const tab = value.selectedTab || value
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: tab.systemName
      })
    },
    useCaseGridLoaded() {
      this.useCaseRoleRecords = this.$refs["grid-usecases"].records;
      this.gridLoaded("grid-usecases");
      this.useCasesLoading = false;
    },
    async switchHandler(el, event) {
      if (event) {
        await this.menuItemRoleService.create([el]);
      } else {
        await this.getMenuItemData();
        const deleteItem = this.modelMenuItem.find(
          (mi) => mi.menuItemPath === el.path
        );
        await this.menuItemRoleService.delete([deleteItem]);
      }

      if (el.children.length > 0) {
        el.children.forEach((i) => {
          i.active = event;

          if (event) {
            this.menuItemRoleService.create([i]);
          } else {
            const deleteItem = this.modelMenuItem.find(
              (mi) => mi.menuItemPath === i.path
            );
            this.menuItemRoleService.delete([deleteItem]);
          }
        });
      }
    },
    savePart(fields) {
      this.loading = true;
      this.roleService.setPart(fields).then(() => {
        this.getData();
        this.loading = false;
      });
    },
    gridLoaded(ref) {
      this.recordCounted = this.$refs[ref].records.length;
    },
    async getMenuItemData() {
      const resultMenuItem = await this.menuItemRoleService[this.getMethod](
        this.masterId
      );
      this.modelMenuItem = resultMenuItem.data;
    },
    async getData() {
      this.masterId = this.$route.params.id;
      if (this.masterId === undefined) {
        // redirect to 404
        return;
      }
      const result = await this.roleService[this.getMethod](this.masterId);
      this.model = result.data;
      const item = this.model;
      item.masterId = this.masterId;
      this.useCaseRoleService.setItem(item);
      this.menuItemRoleService.setItem(item);
      await this.getMenuItemData();
      this.layoutName = this.model[this.loayoutNameProp];
    },
    saveAll() {
      this.loading = true;
      this.roleService.set().then(() => {
        this.getData();
        this.loading = false;
      });
    },
    createItem() {
      this.roleService.create(this.modalModel);
    },
    deleteRecord(item, gridName, service) {
      this.useCasesLoading = true;
      const grid = this.$refs[gridName];
      grid.setLoading(true);
      service.delete([item]).finally(() => {
        grid.getRecords();
        this.useCasesLoading = false;
      });
    },
    reloadGrid(gridName) {
      this.useCasesLoading = true;
      const grid = this.$refs[gridName];
      grid.getRecords();
    },
    setRecords(response) {
      return response.data.records;
    },
    sendGridModal(model) {
      console.log(model);
    },
    backHandler() {
      this.$router.go(-1);
    },
    fnSetMenuItemsRecords(response) {
      const records = response.data.records;
      const menuitems = [];
      records.forEach((i) => {
        i.children = [];
        i.active = false;

        this.modelMenuItem.forEach((mi) => {
          if (mi.menuItemTitle === i.title) {
            i.active = true;
          }
        });

        if (!i.parentId) {
          menuitems.push(i);
        } else {
          const item = menuitems.find((mi) => mi.id === i.parentId);
          item?.children?.push(i);
        }
      });

      return menuitems;
    },
  },
};
</script>
<style lang="scss">
.radio-tabs-body {
  .main-grid.in-block-grid {
    .p-datatable.p-datatable-scrollable {
      background: none;
      height: 100%;
      .p-datatable-table {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        .p-datatable-thead {
          margin-left: 5px;
          border-radius: 5px;
          box-shadow: none;
          tr {
            box-shadow: none;
          }
        }
        .p-datatable-tbody {
          tr {
            box-shadow: none;
            position: relative;
            box-shadow: 0px 0px 6px rgba(51, 51, 51, 0.07);
          }
        }
      }
    }
  }
}
</style>
