<template>
  <ProSpaceRightPanel
    v-if="id"
    :header="header"
    :loading="loading"
    :has-error="hasError"
    @editTask="actionHandler('editTask')"
    @addTrigger="actionHandler('addTrigger')"
    @systemLog="actionHandler('systemLog')"
    @deleteTask="actionHandler('deleteTask')"
    @pauseTask="actionHandler('pauseTask')"
    @showHistory="actionHandler('showHistory')"
  >
    <template v-slot:center>
      <ProSpacePanelBlock :header="headerOverview">
        <template #header>
          <ProSpaceAction :text="$t('editItemTxt')" @click="onClickEdit" />
        </template>
        <ProSpacePanelBlockList :list="overviewContent" />
      </ProSpacePanelBlock>
    </template>
    <template v-slot:bottom>
      <ProSpacePanelBlock :header="headerMetaInfo">
        <template #header> </template>
        <ProSpacePanelBlockList :list="metaInfoContent" />
      </ProSpacePanelBlock>
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpacePanelBlockItem,
  ProSpaceAction,
} from "prospace-components-library";

import { JobTriggerService } from "../../../services/JobTriggerService.js";
import { mapGetters } from "vuex";
export default {
  name: "SystemTaskPanel",
  components: {
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpacePanelBlockItem,
    ProSpaceAction,
  },
  props: {
    id: {
      type: Array,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      task: null,
      jobTriggerService: new JobTriggerService(),
      loading: false,
      hasError: false,
    };
  },
  computed: {
    ...mapGetters({
      getFormatDateTime: "userinfoStore/getFormatDateTime"
    }),
    headerOverview() {
      return {
        title: this.$t("overview"),
        isExpand: false,
      };
    },
    headerMetaInfo() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    header() {
      if (this.task) {
        return {
          title: this.task.name,
          id: this.id,
          status: this.getStatus(this.task.status),
          options: this.options,
          showCounter: false,
        };
      }
      return {};
    },
    overviewContent() {
      if (this.task) {
        return [
          [
            {
              title: this.$t("description"),
              text: this.task.description,
            },
          ],
        ];
      }
      return [];
    },
    metaInfoContent() {
      if (this.task) {
        return [
          [
            {
              title: this.$t("createdByTxt"),
              text: this.task.createdBy,
            },
          ],
          [
            {
              title: this.$t("createdAtTxt"),
              text: this.getFormatDateTime(this.task.createdAt),
            },
            {
              title: this.$t("lastUpdatedTxt"),
              text: this.getFormatDateTime(this.task.lastUpdated),
            },
          ],
        ];
      }
      return [];
    },
  },
  mounted() {
    this.update();
  },
  methods: {
    onClickEdit() {
      this.$emit("editClick", this.task);
    },
    update() {
      this.hasError = false;
      try {
        this.getTask();
      } catch (e) {
        this.hasError = true;
      }
    },
    getTask() {
      if (this.id) {
        this.loading = true;
        this.jobTriggerService.get(this.id).then((res) => {
          this.task = res.data[0];
          this.loading = false;
        });
      } else {
        this.task = null;
      }
    },
    actionHandler(action) {
      this.$emit("action", action, this.task);
    },
    getStatus(status) {
      if (!status) return;
      let type = "default";
      switch (status.toLowerCase()) {
        case "complete":
          type = "success";
          break;
        case "process":
          type = "warning";
          break;
        case "error":
          type = "danger";
          break;
        default:
          type = "default";
      }
      return {
        text: status[0].toUpperCase() + status.toLowerCase().slice(1),
        type,
      };
    },
  },
  watch: {
    id() {
      this.update();
    },
  },
};
</script>

<style scoped></style>
