<template>
  <ProSpaceVLayout
    :gap="10"
    isInlineH
  >
    <ProSpaceHLayout
      v-for="(item, index) in localContext"
      align-items="flex-end"
      :key="index"
      :gap="10"
    >
      <ProSpaceInputText
        v-model="item.key"
        :label="$t('key')"
      />
      <ProSpaceInputText
        v-model="item.value"
        :label="$t('value')"
      />
      <ProSpaceIconButton
        icon="circle-close"
        style="margin-bottom: 5px"
        @click="removeValue(index)"
      />
    </ProSpaceHLayout>
    <ProSpaceAction
      :text="$t('addValue')"
      @click="addValue('', '')"
    />
  </ProSpaceVLayout>
</template>

<script>
import {
  ProSpaceIconButton,
  ProSpaceVLayout,
  ProSpaceHLayout,
  ProSpaceAction,
  ProSpaceInputText
} from "prospace-components-library";
export default {
  components: {
    ProSpaceIconButton,
    ProSpaceVLayout,
    ProSpaceHLayout,
    ProSpaceAction,
    ProSpaceInputText,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localContext: [],
    };
  },
  mounted() {
    for (const key in this.context) {
      this.addValue(key, this.context[key]);
    }
  },
  methods: {
    buildContext() {
      for (var variableKey in this.context) {
        delete this.context[variableKey];
      }

      for (const item of this.localContext) {
        this.context[item.key] = item.value;
      }
    },
    addValue(key, value) {
      this.localContext.push({
        key: key || "",
        value: value || "",
      });
    },
    removeValue(index) {
      this.localContext.splice(index, 1);
    },
  },
};
</script>

<style></style>
