<template>
  <div class="layout-wrapper">
    <div class="login-loading-page">
      <div class="login-loading-page__text">
        <template v-if="hasError">
          <ErrorBlock :errorText="errorText" />
          <a href="/login.html">{{ $t('returnToLoginPage') }}</a>
        </template>
        <div v-else>
          <Fa :icon="appIcon" spin />
          {{ $t('loading') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import ErrorBlock from "../../components/ErrorBlock";
import UserService from '../../services/UserService'
import {
  setRefreshToken,
  setToken,
  getRedirectUrl,
} from "../../../../../Frontends/Common/utils/auth";
import { AUTHORIZATION_INFO } from "../../../../../Frontends/Common/utils/consts";
export default {
  components: {
    ErrorBlock,
  },
  data() {
    return {
      userService: new UserService()
    };
  },
  methods: {
    getQueryParameters() {
      const searchParams = new URLSearchParams(window.location.search);
      const code = searchParams.get("code");
      const state = searchParams.get("state");
      const result = {
        grant_type: "authorization_code",
        code,
        state,
        code_verifier: AUTHORIZATION_INFO.code_verifier,
        client_id: AUTHORIZATION_INFO.client_id,
        redirect_uri: AUTHORIZATION_INFO.redirect_uri,
      };
      return result;
    },
    saveTokens(accessToken, refreshToken) {
      setToken(accessToken);
      setRefreshToken(refreshToken);
      let redirectConfig = getRedirectUrl();
      let url = "/";
      
      if (redirectConfig && redirectConfig.redirect) {
        url += redirectConfig.redirect;
      }
      window.location.href = url;
    },
    async makeSignIn() {
      const authCodeParams = this.getQueryParameters();
      await this.sendAuthRequest(authCodeParams);
    },
    async sendAuthRequest(authCodeParams) {
      const tokenResult = await this.userService.getToken(authCodeParams);
      if (tokenResult.data.access_token) {
        const token = tokenResult.data.access_token;
        const refreshToken = tokenResult.data.refresh_token;
        this.saveTokens(token, refreshToken);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.makeSignIn();
    });
  },
  computed: {
    hasError() {
      return window.hasError;
    },
    errorText() {
      return window.errorText;
    },
    appIcon() {
      return faSpinner;
    },
  },
};
</script>
<style lang="scss">
.login-loading-page {
  height: 100vh;
  width: 100vw;

  font-size: 20px;
  font-weight: 500;
  background: linear-gradient(180deg, #2c3e50 0%, #687284 100%);

  .login-loading-page__text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
    min-width: 300px;
    text-align: center;

    a {
      opacity: 0.8;
      text-decoration: underline;
    }
  }
}
</style>
