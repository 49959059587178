<template>
  <ProSpaceGeoLayout class="use-cases">
    <template #top>
      <ProSpaceTopPanel :header="layoutName">
        <template #right>
          <ProSpaceIconButton
            icon="update"
            @click="actionUpdate"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
          />
          <ProSpaceButton
            style="margin-left: 10px"
            type="primary"
            icon="plus"
            :label="$t('newTxt')"
            @click="showChangeModal"
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpaceFilterPanel
            :closeTxt="$t('closeTxt')"
            :searchPlaceholder="$t('searchTxt')"
            :txtResetFilters="$t('txtResetFilters')"
            :modelFastFilter="modelFastFilter"
            :fastFilters="fastFilters"
            :unicKeyStorage="useCasesService.constructor.name + 'grid'"
            @apply="submitFilter"
            @update:modelFastFilter="
              (val) => {
                modelFastFilter = val;
              }
            "
            ref="filterPanel"
            :filter-model="['title', 'description']"
          >
            <template #hint> {{ $t("filterBy") }}</template>
            <template #filters="{ filter }">
              <ProSpaceFilterContainsChips
                v-model="filter.title"
                :placeholder-value="$t('title')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                @apply="emitFilter"
              />
              <ProSpaceCustomFilterPanel
                :customTxt="$t('custom')"
                :customFilterTxt="$t('customFilterTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                :services="customServices"
                :unicKeyStorage="useCasesService.constructor.name + 'grid'"
                @apply="(model) => customFilterApply(filter, model)"
              />
            </template>
          </ProSpaceFilterPanel>
        </template>
        <template #center>
          <ProSpaceMainGrid
            ref="grid"
            getMethod="grid"
            defaultSortField="createdAt"
            :defaultSortOrder="-1"
            :service="useCasesService"
            :noMountedRequest="true"
            selectionMode="single"
            @selected-change="selectedHandler"
            @db-click="toItem"
          >
            <template #columns>
              <Column
                field="title"
                :header="$t('title')"
                sortable
                style="cursor: pointer"
              >
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    v-if="field.data"
                    :id="field.data.id"
                    :title="field.data.title"
                    @click-on-title="toItem(field.data)"
                  />
                </template>
              </Column>
              <Column field="description" :header="$t('description')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    {{ field.data.description }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column :header="$t('activityPeriod')" sortable field="startDate">
                <template #body="field">
                  <ProSpaceDatesColumn
                    :start-date="field.data.startDate"
                    :end-date="field.data.endDate"
                    :format="userinfo.dateFormat"
                  />
                </template>
              </Column>
              <Column field="createdAt" sortable :header="$t('createdDate')">
                <template #body="field">
                  <ProSpaceDateTimeColumn
                    :date="field.data.createdAt"
                    :dateFormat="userinfo.dateFormat"
                    :timeFormat="userinfo.timeFormat"
                  />
                </template>
              </Column>
              <Column field="actions" class="column-end column-end-30">
                <template #body="field">
                  <ProSpaceDots
                    :options="dotsOptions"
                    @action="(action) => handleDots(action, field.data)"
                  ></ProSpaceDots>
                </template>
              </Column>
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                icon="empty-grid"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
              />
            </template>
          </ProSpaceMainGrid>
        </template>
      </ProSpaceGeoLayout>
    </template>
    <template #right>
      <CasePanel
        ref="right-panel"
        v-if="hasSelect"
        :service="useCasesService"
        :secondService="useCaseClaimService"
        :options="dotsOptions"
        @edit-item="editItem"
        @delete-item="deleteItem"
        @add="showUseCaseModal = true"
        @history-item="showHistory"
        @clear="selectedMultipleClear"
      />
      <ProSpaceModal
        :key="`validate-modal-${showModal}`"
        v-model="showModal"
        width="410"
        :title="isEdit ? $t('editItemTxt') : $t('createItemTxt')"
        :icon="isEdit ? 'edit' : 'plus'"
        @apply="changeItem"
        :cancelTxt="$t('cancelInf')"
        :applyTxt="$t('applyTxt')"
        :validate-local="$t"
        :validate-model="modalModel"
        :validate-config="{
          title: { required },
        }"
      >
        <template #default="{ v }">
          <ProSpaceVLayout :gap="20">
            <ProSpaceInputText
              :label="$t('title')"
              v-model="modalModel.title"
              :placeholder="$t('usecaseTitle')"
              :invalid="v.title.$error"
              :invalid-messages="v.title.$errors"
              @blur="v.title.$touch"
            ></ProSpaceInputText>
            <ProSpaceInputText
              :label="$t('description')"
              v-model="modalModel.description"
              :placeholder="$t('usecaseSampleDescription')"
            ></ProSpaceInputText>
            <ProSpaceDatePeriod
              :label="$t('activityPeriodF')"
              :model="modalModel"
              startDate="startDate"
              endDate="endDate"
              :dateFormat="userinfo.dateFormat"
            >
            </ProSpaceDatePeriod>
          </ProSpaceVLayout>
        </template>
      </ProSpaceModal>
      <ProSpaceGridModalForm
        v-model="showUseCaseModal"
        ref="add claim"
        width="410"
        @apply="(mappingModel) => sendGridModal(mappingModel)"
        :service="claimService"
        :preService="useCaseClaimService"
        :id="selectedData?.id"
        :key="selectedData?.id"
        preGetMethod="get"
        mappingField="securityClaimId"
        getMethod="grid"
        colName="serviceName"
        colDesc="value"
        :headName="$t('taskType')"
        :headDesc="$t('value')"
        :header="$t('claim')"
        :applyTxt="$t('applyTxt')"
        :cancelTxt="$t('cancelInf')"
        :searchTxt="$t('searchTxt')"
      >
      </ProSpaceGridModalForm>
    </template>
  </ProSpaceGeoLayout>
  <ProSpaceHistoryModal
    ref="history"
    v-model="showHistoryModal"
    modelName="SecurityUseCaseApiModel"
    @close="closeHistory"
  />
  <ProSpaceFlashMessage
    v-if="showDeleteModal"
    v-model="showDeleteModal"
    type="default"
    icon="flash-warning"
    :title="$t('deletingContactUser')"
    :applyTxt="$t('confirmTxt')"
    :cancelTxt="$t('reject')"
    @apply="applyDelete"
  >
    <ProSpaceVLayout :gap="10">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
        v-html="$t('deleteUseCase', { name:  deletingModel.title })"
      ></div>
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
</template>
<script>
import {
  ProSpaceFilterPanel,
  ProSpaceInputText,
  ProSpaceGridModalForm,
  ProSpaceDatePeriod,
  ProSpaceDatesColumn,
  event_bus,
  ProSpaceModal,
  ProSpaceMainGrid,
  ProSpaceDots,
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceIconButton,
  ProSpaceButton,
  ProSpaceTableHeaderCell,
  ProSpaceCustomFilterPanel,
  ProSpaceEmptyGrid,
  ProSpaceVLayout,
  ProSpaceDefaultTextColumn,
  ProSpaceFilterContainsChips,
  ProSpaceDateTimeColumn,
  ProSpaceFlashMessage
} from "prospace-components-library";
import Column from "primevue/column";
import CasePanel from "./components/CasePanel.vue";
import { ClaimService } from "../../services/ClaimService.js";
import { UseCasesService } from "../../services/UseCasesService.js";
import { UseCaseClaimService } from "../../services/UseCaseClaimService.js";
import ProSpaceHistoryModal from "@modals/ProSpaceHistoryModal.vue";
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {
  useStatistic
} from "@composes"
import {
  useMultiSelectGrid,
} from "@composes/multiselect";
import { ref } from "vue"
export default {
  components: {
    ProSpaceFilterPanel,
    ProSpaceInputText,
    ProSpaceGridModalForm,
    ProSpaceDatePeriod,
    ProSpaceDatesColumn,
    ProSpaceModal,
    ProSpaceMainGrid,
    ProSpaceDots,
    Column,
    ProSpaceEmptyGrid,
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceIconButton,
    ProSpaceButton,
    ProSpaceTableHeaderCell,
    ProSpaceCustomFilterPanel,
    CasePanel,
    ProSpaceVLayout,
    ProSpaceHistoryModal,
    ProSpaceDefaultTextColumn,
    ProSpaceFilterContainsChips,
    ProSpaceDateTimeColumn,
    ProSpaceFlashMessage
  },
  setup() {
    const grid = ref(null)
    const filterPanel = ref(null)
    const { setStatistic, distActions } = useStatistic()
    const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      selectedData,
      hasSelect,
    } = useMultiSelectGrid(grid, filterPanel)
    return {
      grid, filterPanel, v$: useVuelidate(),
      setStatistic, distActions, selectedMultipleGridHandler,
      selectedData, hasSelect, selectedMultipleClear,
    };
  },
  validations() {
    return {
      modalModel: {
        title: { required },
      },
    };
  },
  data() {
    return {
      showHistoryModal: false,
      showModal: false,
      showUseCaseModal: false,
      // Grid Panel
      selectedModel: {},
      emitter: event_bus,

      // Service
      claimService: new ClaimService(),
      useCasesService: new UseCasesService(),
      useCaseClaimService: new UseCaseClaimService(),

      // Filter Panel Model
      modelFastFilter: {
        label: this.$t("allTxt"),
        value: false,
        field: "disabled",
      },

      // Modal
      modalModel: {
        title: "",
        description: "",
        startDate: null,
        endDate: null,
      },
      isEdit: false,
      isCreate: false,

      deletingModel: null,
      showDeleteModal: false
    };
  },
  methods: {
    closeHistory() {
      this.showHistoryModal = false;
    },
    showHistory(record) {
      this.$refs.history.tabs[0].masterId = record.id;
      this.$refs.history.masterName = record.title;
      this.$nextTick(() => {
        this.showHistoryModal = true;
      });
    },
    actionUpdate() {
      this.$refs.grid.getRecords(true);
      if (this.$refs["right-panel"]) {
        this.$refs["right-panel"].refresh();
      }
    },
    viewMoreClick() {
      this.showModalForm("view claim");
    },
    toItem(data) {
      const url = `/securityadmin/usecase/${data.id}`
      this.setStatistic({
        actionTemp: this.distActions.form,
        requestUrl: url
      })
      this.$router.push(url);
    },
    selectedHandler(val) {
      this.selectedMultipleGridHandler(val)
    },
    submitFilter({ filter, search }) {
      this.$refs.grid.submitFilter(filter, search);
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.emitFilter();
    },
    emitFilter() {
      this.filterPanel.submitFilter();
    },
    showChangeModal() {
      this.isEdit = false;
      this.isCreate = true;
      this.modalModel = {
        title: "",
        description: "",
        startDate: null,
        endDate: null,
      };
      this.showModal = true;
    },
    changeItem() {
      let method;
      if (this.isCreate) {
        method = "create";
      }
      if (this.isEdit) {
        method = "edit";
      }
      this.useCasesService[method](this.modalModel).then(() => {
        this.actionUpdate();
      });
      if (this.$refs["right-panel"])
        this.$refs["right-panel"].update();
    },
    deleteItem(model) {
      if (!model) return;
      this.deletingModel = model;
      this.showDeleteModal = true;
    },
    applyDelete() {
      this.useCasesService.delete(this.deletingModel).then(() => {
        this.actionUpdate();
        this.selectedModel = this.selectedModel.filter(i => i.id !== this.deletingModel.id);
        this.$refs.grid.selectedModel = this.$refs.grid.selectedModel.filter(i => i.id !== this.deletingModel.id)
        this.deletingModel = null;
      });
    },
    editItem(model) {
      if (!model) return;
      this.isEdit = true;
      this.isCreate = false;
      this.modalModel = _.cloneDeep(model);
      this.showModal = true;
    },
    showModalForm(name) {
      this.$refs[name].show();
    },
    sendGridModal(mappingModel) {
      const usecase = this.$refs["right-panel"].useCase;
      this.useCaseClaimService.setItem(usecase);
      this.useCaseClaimService.create(mappingModel).then(() => {
        this.actionUpdate();
      });
    },
    handleDots(action, item) {
      var option = this.dotsOptions.find((o) => o.name === action);
      option.method(item);
    },
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
    }),
    dotsOptions() {
      let res = [
        {
          name: "edit",
          text: this.$t("editItemTxt"),
          icon: "edit",
          method: this.editItem,
        },
        {
          name: "delete",
          text: this.$t("delete"),
          icon: "delete",
          method: this.deleteItem,
        },
        {
          name: "showHistory",
          text: this.$t("history"),
          icon: "history",
          method: this.showHistory,
        },
      ];
      if (this.modelFastFilter.label === this.$t("deleted")) {
        return res.filter(
          (option) => option.name !== "edit" && option.name !== "delete"
        );
      }
      return res;
    },
    fastFilters() {
      return [
        {
          label: this.$t("allTxt"),
          value: false,
          field: "disabled",
        },
        { label: this.$t("deleted"), field: "disabled", value: true },
      ];
    },
    layoutName() {
      return this.$t("useCases");
    },
    customServices() {
      return [
        {
          type: "number",
          model: "id",
          label: this.$t("id"),
          selected: {},
        },
        {
          type: "dates-range",
          model: "startDate",
          label: this.$t("startedAt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range",
          model: "endDate",
          label: this.$t("finishedAt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "chips",
          model: "createdBy",
          label: this.$t("createdByTxt"),
          selected: {},
        },
        {
          type: "chips",
          model: "updatedBy",
          label: this.$t("updatedByTxt"),
          selected: {},
        },
        {
          type: "dates-range",
          model: "createdAt",
          label: this.$t("createDate"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range",
          model: "lastUpdated",
          label: this.$t("lastUpdatedTxt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
      ];
    },
  },
};
</script>

.
<style lang="scss">
.use-cases {
  .prospace-table-header-cell__description {
    display: none;
  }
}
</style>
