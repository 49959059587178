<template>
  <ProSpaceRightPanel
    v-if="id"
    :header="header"
    :loading="loading"
    class="menuitems-panel"
  >
    <template #center>
      <ProSpacePanelBlock :header="headerOverview">
        <template #header>
          <ProSpaceAction
            :text="$t('editItemTxt')"
            @click="$emit('edit', this.menuitem)"
          />
        </template>
        <template #default>
          <ProSpacePanelBlockList :list="content" />
        </template>
      </ProSpacePanelBlock>
      <ProSpacePanelBlock :header="headerLocalization">
        <template #default>
          <ProSpaceSimpleGrid
            v-if="contentList.length > 0"
            :headers="listHeaders"
            :items="contentList"
            :bordered="true"
            :btnText="$t('viewMore')"
          />
        </template>
      </ProSpacePanelBlock>
    </template>
    <template #bottom>
      <ProSpacePanelBlock :header="headerBottom">
        <template v-slot>
          <ProSpacePanelBlockList :list="contentBottom" />
        </template>
      </ProSpacePanelBlock>
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceRightPanel,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceAction,
  ProSpaceSimpleGrid,
} from "prospace-components-library";
import { mapGetters } from "vuex";
export default {
  name: "MenuItemsPanel",
  components: {
    ProSpaceRightPanel,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceAction,
    ProSpaceSimpleGrid,
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
    id: {
      type: Number,
      default: null,
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    ...mapGetters({
      getFormatDateTime: "userinfoStore/getFormatDateTime"
    }),
    listHeaders() {
      return [
        {
          name: this.$t("language"),
          value: "language",
        },
        {
          name: this.$t("name"),
          value: "name",
        },
      ];
    },
    headerOverview() {
      return {
        title: this.$t("overview"),
        isExpand: false,
      };
    },
    headerLocalization() {
      return {
        title: this.$t("localization"),
        isExpand: false,
      };
    },
    headerBottom() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    header() {
      if (this.menuitem) {
        return {
          title: this.menuitem.title,
          id: this.id,
          options: this.options,
          showCounter: false,
        };
      }
      return [];
    },
    content() {
      if (this.menuitem) {
        return [
          [
            {
              title: this.$t("path"),
              text: this.menuitem.path,
            },
          ],
          [
            {
              title: this.$t("title"),
              text: this.menuitem.title,
            },
          ],
          [
            {
              title: this.$t("order"),
              text: this.menuitem.order,
            },
          ],
        ];
      }
      return [];
    },
    contentBottom() {
      if (this.menuitem) {
        return [
          [
            {
              title: this.$t("createdByTxt"),
              text: this.menuitem.createdBy,
            },
            {
              title: this.$t("updatedByTxt"),
              text: this.menuitem.updatedBy,
            },
          ],
          [
            {
              title: this.$t("createdAtTxt"),
              text: this.getFormatDateTime(this.menuitem.createdAt),
            },
            {
              title: this.$t("lastUpdatedTxt"),
              text: this.getFormatDateTime(this.menuitem.lastUpdated),
            },
          ],
        ];
      }
      return [];
    },
    contentList() {
      if (this.menuitem) {
        const langs = JSON.parse(this.menuitem.language);
        return [
          {
            language: "Russian",
            name: langs.RU,
          },
          {
            language: "English",
            name: langs.EN,
          },
          {
            language: "France",
            name: langs.FR,
          },
        ];
      }
      return [];
    },
  },
  data() {
    return {
      menuitem: null,
      loading: false,
    };
  },
  created() {
    this.update();
  },
  methods: {
    async update() {
      if (this.id) {
        this.loading = true;
        const res = await this.service.getItem(this.id);
        this.menuitem = res.data[0];
        this.loading = false;
      }
    },
  },
  watch: {
    id() {
      this.update();
    },
  },
};
</script>

<style lang="scss">
.menuitems-panel {
  border: var(--prospace-ui-border);
  .prospace-list-items__header_title {
    width: 50%;
  }
  .prospace-list-items__row {
    height: 34px !important;
    &_item {
      width: 50% !important;
    }
  }
}
</style>
