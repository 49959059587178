import i18n from "../../Common/utils/i18n.js";
export class TemplateEditableService {
  constructor() {
    this.editSettings = [
      { text: i18n.global.t("Edited"), value: true },
      { text: i18n.global.t("NotEdited"), value: false },
    ];
  }
  async get() {
    return Promise.resolve({ data: { records: this.editSettings } });
  }
}
