import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/ReserveService/BBDProductApi";

export class BBDProductService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getBBDProducts", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getBBDProduct", null, id);
  }
  async getShipFrom(filter) {
    return await this.transformer.getGridPanel(this.Api, "getShipFrom", filter);
  }
  async getShipFromFilter(filter) {
    return await this.transformer.getGrid(this.Api, "getShipFrom", filter);
  }
  async getShipFromTree(args) {
    let result = await this.transformer.getTree(this.Api, "getShipFrom", args);
    const data = result.data.map(i => {
      i.leaf = true
      return i
    })
    return Promise.resolve({ data });
  }
}
