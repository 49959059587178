import request from "../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/securityadmin/securityuserusecase/";

export default class UserUseCaseApi {
  constructor() {
    this.defaultModel = {
      // id: 0,
      // status: "NEW",
      // firstName: "",
      // lastName: "",
      // email: "test@email.com",
      // createdBy: "test",
      // createdAt: new Date(),
      // lastUpdated: new Date(),
      // roles: [],
      // additionalfields: [],
    };
  }
  async GetUseCaseByUser(params, id) {
    params = params.replace("&$top=100&$skip=0", "")
    return await request.post(`${rootRoute}getsecurityuserusecase`, {
      filter: `$filter=(((securityUserId eq ${id})))&${params}`,
    });
  }
}
