import request from "../Frontends/Common/utils/request/requestMain";

export default class UserInfoApi {
  constructor() {
    this.data = {
      locale: "en"
    };
  }
  async get() {
    return await request.post(`/securityadmin/securityuser/userprofile`);
  }
  async sendPending(date) {
    return await request.post(`/securityadmin/securityuser/sendpending`, {
      date
    });
  }
}
