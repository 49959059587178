export class FieldService {
    constructor() {
      this.data = [
        { label: 'Int64', value: 'Int64'},
        { label: 'String', value: 'String'},
        { label: 'Boolean', value: 'Boolean'},
        { label: 'DateTimeOffset', value: 'DateTimeOffset'},
        { label: 'DateTime', value: 'DateTime'},
        { label: 'Int32', value: 'Int32'},
        { label: 'Double', value: 'Double'}
      ];
    }
    search(value) {
      return this.data.filter((elem) => elem.text.indexOf(value) !== -1);
    }
    async get() {
      return Promise.resolve({ data: { records: this.data } });
    }
  }
  