import { Transformer } from "../../../Transformer/index.js";
import i18n from "@common/utils/i18n.js";

export class YesNoService {
  constructor() {
    this.transformer = new Transformer();
  }
  async get() {
    return Promise.resolve(this.getData())
  }

  getData() {
    return {
      data: {
        records: [
          {label: i18n.global.t("yes"), value: true},
          {label: i18n.global.t("no"), value: false},
        ]
      }
    };
  }
}
