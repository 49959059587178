import request from "../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/securityadmin/securityuser/";

export default class UserApi {
  constructor() {
    this.defaultModel = {
      id: 0,
      status: "NEW",
      firstName: "",
      secondName: "",
      lastName: "",
      email: "test@email.com",
      createdBy: "test",
      createdAt: new Date(),
      lastUpdated: new Date(),
      roles: [],
      additionalfields: [],
    };
  }
  async getUsers(params) {
    return await request.post(`${rootRoute}getsecurityusers`, {
      filter: params,
      parameters: {},
    });
  }
  async getUser(id) {
    return await request.post(`${rootRoute}getsecurityusers`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createUser(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createsecurityuser`, { data });
  }
  async deleteUser(data) {
    return await request.post(`${rootRoute}deletesecurityuser`, data);
  }
  async editUser(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updatesecurityuser`, { data });
  }
  async export(filter, format) {
    return await request.post(`${rootRoute}exportsecurityuser`, {
      filter: filter,
      format: format
    });
  }
  async resetPasswordBackground(data) {
    return await request.post(`${rootRoute}resetPasswordBackground`, data);
  }
  async changePassword(data) {
    return await request.post(`${rootRoute}changepassword`, data);
  }
  async resetPassword(data) {
    return await request.post(`${rootRoute}resetpassword`, data);
  }
  async updateLocale(data) {
    return await request.post(`${rootRoute}updatesecurityuserlanguage`, {
      filter: `$filter=id eq ${data.userId}`,
      newLanguage: data.locale
    });
  }
  async updateDateTimeFormats(data) {
    return await request.post(`${rootRoute}updatedatetimeformats`, data);
  }
}
