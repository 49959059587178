import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/Information/HomeApi";

export class HomeService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async getDashboard(tz) {
    let role = JSON.parse(localStorage.getItem("userRole")).systemName;
    return await this.transformer.getItem(this.Api, "getDashboard", {role, tz});
  }
}
