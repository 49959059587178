import { getToken } from "../../Common/utils/auth";
import MenuItemUserApi from "../../../Api/MenuItemUserApi";
import store from "../../Common/store";
export default class MenuService {
  async getMenu(user) {
    let menu = [];
    if (getToken()) {
      this.MenuItemUserApi = new MenuItemUserApi();

      let filer =
        user.position && user.position.id
          ? `(securityUserId eq ${user.id}) and (securityRoleId eq ${user.position.id} or securityRoleId eq null)`
          : `securityUserId eq ${user.id}`;
      let filter = `$filter=${filer}&$orderby=menuItemOrder asc`;
      await this.MenuItemUserApi.getMenuItemUser(filter).then((response) => {
        let notes = store.getters["notifications/notificationMessages"];
        let list = response.data.data;

        let map = {};
        let node;
        let roots = [];

        for (let i = 0; i < list.length; i++) {
          map[list[i].menuItemId] = i;
          list[i].children = [];
        }
        for (let i = 0; i < list.length; i++) {
          node = {
            id: list[i].menuItemId,
            title: list[i].menuItemTitle,
            parentId: list[i].menuItemParentId,
            order: list[i].menuItemOrder,
            path: list[i].menuItemPath,
            language: list[i].menuItemLanguage,
            icon: list[i].menuItemIcon,
            children: list[i].children,
            notificationCount: store.getters["notifications/getCounter"](notes.filter(
              (n) =>
                n.messageData.Title === list[i].menuItemTitle &&
                n.readed === false
            )),
          };
          if (node.parentId !== null) {
            if (
              map[node.parentId] != null &&
              list[map[node.parentId]] != null
            ) {
              if (
                !list[map[node.parentId]].children.find(
                  (elem) => elem.id == node.id
                )
              ) {
                list[map[node.parentId]].children.push(node);
              }
            }
          } else {
            if (!roots.find((elem) => elem.id == node.id)) {
              roots.push(node);
            }
          }
        }
        menu = roots;
      });
    }
    return Promise.resolve({ data: menu });
  }
}
