import request from "../Frontends/Common/utils/request/requestMain";
import _ from "lodash";
const rootRoute = "scheduler/jobtriggerconfiguration/";

export default class JobTriggerApi {
  constructor() {
    this.defaultModel = {};
  }
  async getJobTriggers(params) {
    return await request.post(`${rootRoute}getjobtriggerconfiguration`, {
      filter: params,
      parameters: {},
    });
  }
  async getJobTrigger(id) {
    return await request.post(`${rootRoute}getjobtriggerconfiguration`, {
      filter: `$filter=id eq ${id}`,
      parameters: {},
    });
  }
  async createJobTrigger(model) {
    const tmpDefault = _.cloneDeep(this.defaultModel);
    const data = Array.isArray(model)? model : [_.merge(tmpDefault, model)];
    return await request.post(`${rootRoute}createjobtriggerconfiguration`, { data });
  }
  async updateJobTrigger(model) {
    const tmpDefault = _.cloneDeep(this.defaultModel);
    const data = Array.isArray(model)? model : [_.merge(tmpDefault, model)];
    return await request.post(`${rootRoute}updatejobtriggerconfiguration`, { data });
  }
  async deleteJobTrigger(model) {
    const data = [model];
    return await request.post(`${rootRoute}deletejobtriggerconfiguration`, { data });
  }
}
