import taskassignments from "../../../../Services/TaskService/TaskService.Frontend/views/TaskAssignments/TaskAssignments";
import taskassignment from "../../../../Services/TaskService/TaskService.Frontend/views/TaskAssignments/TaskAssignment";
import taskassignmenttemplates from "../../../../Services/TaskService/TaskService.Frontend/views/TaskAssignmentTemplates/TaskAssignmentTemplates";
import taskassignmenttemplate from "../../../../Services/TaskService/TaskService.Frontend/views/TaskAssignmentTemplates/TaskAssignmentTemplate";

export const taskserviceRoutes = [
  {
    path: "/taskservice/taskassignments",
    name: "taskassignments",
    component: taskassignments,
  },
  {
    path: "/taskservice/taskassignment",
    name: "taskassignment-create",
    component: taskassignment,
    meta: {
      menuitem: "/taskservice/taskassignments",
    },
  },
  {
    path: "/taskservice/taskassignment/:id",
    name: "taskassignment",
    component: taskassignment,
    meta: {
      menuitem: "/taskservice/taskassignments",
    },
  },
  {
    path: "/taskservice/taskassignmenttemplates",
    name: "taskassignmenttemplates",
    component: taskassignmenttemplates,
  },
  {
    path: "/taskservice/taskassignmenttemplate",
    name: "taskassignmenttemplate-create",
    component: taskassignmenttemplate,
    meta: {
      menuitem: "/taskservice/taskassignmenttemplates",
    },
  },
  {
    path: "/taskservice/taskassignmenttemplate/:id",
    name: "taskassignmenttemplate",
    component: taskassignmenttemplate,
    meta: {
      menuitem: "/taskservice/taskassignmenttemplates",
    },
  },
];

export default taskserviceRoutes;
