import reports from "../../../../Services/Report/ReportService.Frontend/views/Reports/Reports";
import report from "../../../../Services/Report/ReportService.Frontend/views/Reports/Report";

export const reportRoutes = [
  { path: "/report/reports", name: "reports", component: reports },
  { path: "/report/report/:id", name: "report", component: report },
  { path: "/report/report", name: "report-create", component: report },

];

export default reportRoutes;
