import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/MasterData/MarsDistributorOrgViewApi";

export class MarsDistributorOrgViewService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(this.Api, "editMarsDistributorOrgView", fields);
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editMarsDistributorOrgView");
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getMarsDistributorOrgViews", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getMarsDistributorOrgView", null, id);
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createMarsDistributorOrgView", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteMarsDistributorOrgView", model);
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "editMarsDistributorOrgView", model);
  }
  async tree() {
    return await this.transformer.toTree(this.Api, "getMarsDistributorOrgViews");
  }
  async treeWithAll(args) {
    var r = await this.transformer.getTree(this.Api, "getMarsDistributorOrgCountViews", args);
    if (r.data.length > 0) {
      r.data.forEach((n) => {
        n.realType = n.type === "RealDistributor" ? "Distributor" : n.type;
      })
      if (r.data.length === r.data.filter(e => e.parentId === null).length) {
        r.data.forEach(e => e.key = e.treeId);
        let allNode = {
          name: "All",
          treeId: "all",
          leaf: false,
          children: [...r.data],
          count: r.data.reduce((total, x) => (total + x.count), 0)
        };
        r.data = [allNode];
      }
    }
    return r;
  }
  async allTree() {
    const res = await this.transformer.toTree(this.Api, "getMarsDistributorOrgCountViews", "");
    const setRealType = (node) => {
      node.realType = node.type === "RealDistributor" ? "Distributor" : node.type;
      if (node.children) {
        node.children.forEach(setRealType)
      }
    }
    res.data.forEach(setRealType)
    const allNode = {
      name: "All",
      treeId: "all",
      leaf: false,
      children: [...res.data],
      count: res.data.reduce((total, x) => (total + x.count), 0)
    }
    res.data = [allNode];
    return res;
  }
  async onlyMars() {
    const res = await this.transformer.toTree(this.Api, "getMarsDistributorOrgCountViews", "");
    res.data = [res.data.find(n => n.type === "Mars")];
    const setRealType = (node) => {
      node.realType = node.type === "RealDistributor" ? "Distributor" : node.type;
      if (node.children) {
        node.children.forEach(setRealType)
      }
    }
    res.data.forEach(setRealType)
    return res
  }
}
