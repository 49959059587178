<template>
  <ProSpaceGeoLayout>
    <template #top>
      <ProSpaceTopPanel :header="$t('reservesList')">
        <template #right>
          <ProSpaceIconButton
            icon="update"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
            @click="actionUpdate"
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpaceFilterPanel
            ref="filter-panel"
            :fastFilters="fastFilters"
            :modelFastFilter="modelFastFilter"
            :noMountedRequest="true"
            :searchPlaceholder="$t('searchTxt')"
            :closeTxt="$t('closeTxt')"
            :filter-model="['code', 'distributorName']"
            @apply="submitFilter"
            @update:modelFastFilter="
              (val) => {
                modelFastFilter = val;
                selectedMultipleClear();
              }
            "
          >
            <template #hint>
              {{ $t("filterBy") }}
            </template>
            <template #filters="{ filter }">
              <ProSpaceFilterSearchfield
                v-if="checkAccess('distributors-ProSpaceFilterSearchfield')"
                v-model="filter.distributorId"
                :closeTxt="$t('closeTxt')"
                :cancelTxt="$t('cancelInf')"
                :placeholderValue="$t('distributor')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :service="distributorService"
                :txtTitleModal="$t('selectDistributors')"
                :txtNameColumn="$t('distributor')"
                get-method="grid"
                optionLabel="name"
                optionValue="id"
                :hint="$t('hintFilterDistrResesrved')"
                @apply="emitFilter"
              />
              <ProSpaceFilterDateTimeRange
                v-model="filter.createdAt"
                :placeholder-value="$t('reserveDate')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                @apply="emitFilter"
              />
              <ProSpaceMultiSelect
                v-model="filter.shipFrom"
                :localization="$t"
                :service="BBDProductService"
                get-method="getShipFromFilter"
                option-label="shipFrom"
                option-value="shipFrom"
                :placeholderValue="$t('reserveShipping')"
                isAddSearch
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :searchPlaceholder="$t('searchTxt')"
                @apply="(model) => customFilterApply(filter, model)"
              />
              <ProSpaceMultiSelect
                v-model="filter.shipToName"
                :localization="$t"
                :service="reserveService"
                get-method="getShipToFilter"
                option-label="name"
                option-value="name"
                :placeholderValue="$t('reserveDelivery')"
                isAddSearch
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :searchPlaceholder="$t('searchTxt')"
                @apply="(model) => customFilterApply(filter, model)"
              />
              <ProSpaceCustomFilterPanel
                :customTxt="$t('custom')"
                :customFilterTxt="$t('customFilterTxt')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                :services="customServices"
                @apply="(model) => customFilterApply(filter, model)"
              />
            </template>

          </ProSpaceFilterPanel>
        </template>
        <template #center>
          <ProSpaceMainGrid
            ref="grid"
            getMethod="grid"
            selectionMode="single"
            defaultSortField="createdAt"
            :service="reserveService"
            :defaultSortOrder="-1"
            defaultColumnWidths="300"
            @selected-change="selectedHandler"
            @db-click="toItem"
            @loaded="setLoadedReserves"
          >
            <template #columns>
              <Column field="distributorName" :header="$t('reserveTxt')" sortable>
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    :title="field.data.distributorName"
                    :status="getStatus(field.data.status)"
                    :id="field.data.id"
                    @click-on-title="toItem(field.data)"
                  />
                </template>
              </Column>
              <Column
                field="createdAt"
                sortable
                :header="$t('reserveDate')"
                style="min-width: 100px"
              >
                <template #body="field">
                  <ProSpaceDateTimeColumn
                    :date="field.data.createdAt"
                    :dateFormat="userinfo.dateFormat"
                    :timeFormat="userinfo.timeFormat"
                  />
                </template>
              </Column>
              <Column field="shipFrom" :header="$t('reserveShipping')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="field.data.shipFrom">
                    {{ field.data.shipFrom }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column field="shipToName" :header="$t('reserveDelivery')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="field.data.shipToName">
                    {{ field.data.shipToName }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column field="shipToCode" :header="$t('reserveShipTo')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn :title="field.data.shipToCode">
                    {{ field.data.shipToCode }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column field="actions" class="column-end column-end-90">
                <template #body="field">
                  <ProSpaceHLayout justify-content="flex-end" :gap="10">
                    <ProSpaceDots
                      :options="dotsOptions"
                      @action="(action) => handleDots(action, field.data)"
                    />
                  </ProSpaceHLayout>
                </template>
              </Column>
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                v-if="isEmptyGrid"
                icon="error-message"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
              />
            </template>
          </ProSpaceMainGrid>
        </template>
      </ProSpaceGeoLayout>
    </template>
    <template #right>
      <ReservesRightPanel
        v-if="hasSelect"
        ref="right-panel"
        :reserve="selectedData"
        :status="getStatus(selectedData.status)"
        :checkAccess="checkAccess"
      />
    </template>
  </ProSpaceGeoLayout>
</template>

<script>
import {
  ProSpaceGeoLayout,
  ProSpaceHLayout,
  ProSpaceTopPanel,
  ProSpaceButton,
  ProSpaceIconButton,
  ProSpaceFilterPanel,
  ProSpaceFilterSearchfield,
  ProSpaceMainGrid,
  ProSpaceTableHeaderCell,
  ProSpaceDefaultTextColumn,
  ProSpaceDateTimeColumn,
  ProSpaceFilterDateTimeRange,
  ProSpaceCustomFilterPanel,
  ProSpaceMultiSelect,
  ProSpaceDots,
  ProSpaceEmptyGrid
} from "prospace-components-library";
import { mapGetters } from "vuex";
import ReservesRightPanel from "./components/ReservesRightPanel.vue";
import { ReserveService } from "../../services/ReserveService.js";
import { DistributorService } from "@masterdata/services/DistributorService.js";
import Column from "primevue/column";
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import { BBDProductService } from "../../services/BBDProductService";
import {
  useMultiSelectGrid,
} from "@composes/multiselect";
import { ref } from "vue"
export default {
  name: 'Reserves',
  mixins: [accessMixin],
  components: {
    ProSpaceGeoLayout,
    ProSpaceHLayout,
    ProSpaceTopPanel,
    ProSpaceButton,
    ProSpaceIconButton,
    ProSpaceFilterPanel,
    ProSpaceFilterSearchfield,
    ReservesRightPanel,
    ProSpaceMainGrid,
    Column,
    ProSpaceTableHeaderCell,
    ProSpaceDefaultTextColumn,
    ProSpaceDateTimeColumn,
    ProSpaceFilterDateTimeRange,
    ProSpaceCustomFilterPanel,
    ProSpaceMultiSelect,
    ProSpaceDots,
    ProSpaceEmptyGrid
  },
  data() {
    return {
      reserveService: new ReserveService(),
      distributorService: new DistributorService(),
      BBDProductService: new BBDProductService(),
      modelFastFilter: {
        label: this.$t("allTxt"),
        value: [
          { field: "disabled", value: false }
        ]
      },
      fastFilters: [
        {
          label: this.$t("allTxt"),
          value: [
            { field: "disabled", value: false },
          ]
        },
        {
          label: this.$t("reserveCreated"),
          value: [
            { field: "disabled", value: false },
            { field: "status", value: "reserveCreated" }
          ]
        },
        {
          label: this.$t("reserveSent"),
          value: [
            { field: "disabled", value: false },
            { field: "status", value: "reserveSent" }
          ]
        },
        {
          label: this.$t("reserveArchive"),
          value: [
            { field: "disabled", value: false },
            { field: "status", value: "reserveArchived" }
          ]
        },
      ],
      activeReserve: null,
      loaded: false
    }
  },
  setup() {
    const grid = ref(null)
    const filterPanel = ref(null)
     const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      hasSelect, selectedData
    } = useMultiSelectGrid(grid, filterPanel)
    return {
      grid, filterPanel,
      selectedMultipleGridHandler,
      hasSelect, selectedMultipleClear,
      selectedData
    }
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      access: "securityRoleAccess/access",
    }),
    customServices() {
      return [
        {
          type: "chips",
          model: "reserveProductCode",
          label: this.$t("reserveProduct"),
          selected: {},
        },
        {
          type: "chips",
          model: "reserveProductGRD",
          label: this.$t("bbdProductGRD"),
          selected: {},
        },
        {
          type: "chips",
          model: "shipToCode",
          label: this.$t("reserveShipTo"),
          selected: {},
        },
      ]
    },
    dotsOptions() {
      return [
        {
          name: "open",
          text: this.$t("open"),
          icon: "file-lines",
          method: this.openItem,
          liteRef: "open-option",
        },
      ];
    },
    isEmptyGrid() {
      return !this.isLoaded;
    }
  },
  methods: {
    getStatus(status) {
      let text, type, description;
      if (status === 'reserveCreated') {
        text = this.$t('reserveCreated');
        type = 'warning';
        description = this.$t("reserveStatusCreated");
      } else if (status === 'reserveSent') {
        text = this.$t('reserveSent');
        type = 'success';
        description = this.$t("reserveStatusSent");;
      } else if (status === 'reserveArchived') {
        text = this.$t('reserveArchive');
        type = 'draft';
        description = this.$t("reserveStatusArchived");;
      }
      return {
       type, text, description
      }
    },
    setLoadedReserves(e) {
      if (e?.length === 0) {
        this.isLoaded = false;
      }
      this.isLoaded = true;
    },
    openItem(model) {
      if (!model) return;
      this.toItem(model);
    },
    toItem(data) {
      const url = `/reserveservice/reserve/${data.id}`
      // this.setStatistic({
      //   actionTemp: this.distActions.form,
      //   requestUrl: url
      // })
      this.$router.push(url);
    },
    handleDots(action, item) {
      const option = this.dotsOptions.find((o) => o.name === action);
      option.method(item);
    },
    clear() {
      this.selectedModel = {};
      this.$refs.grid.selectedModel = [];
    },
    selectedHandler(val) {
      this.selectedMultipleGridHandler(val)
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.emitFilter();
    },
    emitFilter() {
      this.$refs["filter-panel"].submitFilter();
    },
    submitFilter({ filter, search }) {
      this.$refs.grid.submitFilter(filter, search);
    },
    actionUpdate() {
      this.$refs.grid.getRecords(true);
    },
  },
  mounted() {
    this.loadRoleAccess();
  }
}
</script>

<style>

</style>
