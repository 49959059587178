import competencedirectories from "../../../../Services/Competence/CompetenceService.Frontend/views/CompetenceDirectories/CompetenceDirectories";
import competencedirectory from "../../../../Services/Competence/CompetenceService.Frontend/views/CompetenceDirectories/CompetenceDirectory";

import competences from "../../../../Services/Competence/CompetenceService.Frontend/views/Competences/Competences";
import competence from "../../../../Services/Competence/CompetenceService.Frontend/views/Competences/Competence";

import competencetask from "../../../../Services/Competence/CompetenceService.Frontend/views/CompetenceTasks/CompetenceTask";

export const competenceRoutes = [
  { 
    path: "/competence/competencedirectories", 
    name: "competencedirectories", 
    component: competencedirectories 
  },
  { 
    path: "/competence/competencedirectory", 
    name: "competencedirectory-create", 
    component: competencedirectory 
  },
  { 
    path: "/competence/competencedirectory/:id", 
    name: "competencedirectory", 
    component: competencedirectory 
  },
  { 
    path: "/competence/competences", 
    name: "competences", 
    component: competences },
  { 
    path: "/competence/competence", 
    name: "competence-create", 
    component: competence,
    meta: {
      menuitem: "/competence/competences"
    }
  },
  { 
    path: "/competence/competence/:id", 
    name: "competence", 
    component: competence,
    meta: {
      menuitem: "/competence/competences"
    }
  },
  { 
    path: "/competence/competencetask/:id", 
    name: "competencetask", 
    component: competencetask 
  },
];

export default competenceRoutes;
