import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/StatisticsReport/StatisticsReportApi";
import store from "../../../../Frontends/Common/store";
export class StatisticsReportService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(this.Api, "editStatisticsReport", fields);
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editStatisticsReport");
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getStatisticsReports", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getStatisticsReport", null, id);
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createStatisticsReport", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteStatisticsReport", model);
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "editStatisticsReport", model);
  }
  async export(filter, format) {
    const res = this.Api.export(filter, format);
    await store.dispatch('notifications/handleResponse', {
      promiseResponse: res,
    })
    return res;
  }
}
