// CONDITION
// [ALIVE, DISABLE, READONLY, NONE]
const priorities = { ALIVE: 0, DISABLE: 1, READONLY: 2, NONE: 3 };

export function setCondition(element, condition) {
  if (
    element && 
    !element.stateStatus ||
    (element.stateStatus &&
      priorities[element.stateStatus] < priorities[condition])
  ) {
    // SETTED CONDITION
    element.stateStatus = condition;
  }
}

export function resetCondition(element, condition) {
  if (!condition) {
    if(element && element.stateStatus)
      delete element.stateStatus;
    return;
  }
  element.stateStatus = condition;
}

export function setReadOnly(element) {
  if (!element.$el) {
    return;
  }
  let el = element;
  el.localReadonly = true;
}

export function setAlive(element) {
  if (element.$el && (element.$el.disabled || element.$el.disable)) {
    return;
  }
  let el = element.$el || element;
  if (el.style) {
    el.style["cursor"] = "pointer";
    el.style["pointer-events"] = "auto";
    el.removeAttribute("disabled");
    el.style["opacity"] = 1;
  }
  if ('localReadonly' in element) {
    element.localReadonly = false;
  }
  if ('localDisabled' in element) {
    element.localDisabled = false
  }
}

export function setDisable(element) {
  let el = element.$el || element;
  if (el.style) {
    el.readonly = true;
    el.style["cursor"] = "not-allowed";
    el.style["pointer-events"] = "none";
    el.setAttribute("disabled", "");
  }
  if ('localDisabled' in element) {
    element.localDisabled = true
  }
}

export function setNone(element) {
  let el = element.$el || element;
  if(el.style)
    el.style["display"] = "none";
}
