import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/competence/competencerecipient/";

export default class CompetenceRecipientApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getCompetences(params) {
    return await request.post(`${rootRoute}getcompetencerecipients`, {
      filter: params,
    });
  }
  async getCompetencesFromView(params) {
    return await request.post(`${rootRoute}getcompetencerecipientsfromview`, {
      filter: params,
    });
  }
}
