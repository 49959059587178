import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/newsservice/newsitemrecipient/";

export default class NewsItemRecipientApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getNewsItems(params) {
    return await request.post(`${rootRoute}getnewsitemrecipients`, {
      filter: params,
    });
  }
  async getNewsItemsFromView(params) {
    return await request.post(`${rootRoute}getnewsitemrecipientsfromview`, {
      filter: params,
    });
  }
}
